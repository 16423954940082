import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { AngularFirestoreCollection, QueryDocumentSnapshot, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { HomeService } from '../home.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MultimediaRoutesService } from '@app/multimedia/services/multimedia-routes.service';

@Component({
  selector: 'app-featured-posts',
  templateUrl: './featured-posts.component.html',
  styleUrls: ['./featured-posts.component.scss'],
})
export class FeaturedPostsComponent implements OnInit {

  public featured: any[] = [];
  private featuredColl: AngularFirestoreCollection<any>;

  constructor(
    private sanitizer: DomSanitizer,
    public router: Router,
    public credService: CredentialsService,
    public utilities: UtilitiesService,
    private analyticsFB: AnalyticsService,
    private homeService: HomeService,
    private fireService: FirebaseService,
    private conectimedRoutes: MultimediaRoutesService
  ) { }

  ngOnInit() {
    this.initFeatured();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  public share(item: any) {
    this.utilities.share(item.routing.route);
    this.analyticsClickEvent('share', {
      content_type: item && item.title ? item.title : ''
    });
  }

  public loginAlert() {
    this.homeService.alertLoginSining();
  }

  async initFeatured(): Promise<void> {
    this.featuredColl = this.fireService.afs.collection('posts', ref =>
      ref
        .where('is_outstanding', '==', true)
        .where('status', '==', 'active')
        .orderBy('publication_date', 'desc')
        .limit(10)
    );
    this.featuredColl.snapshotChanges().subscribe(snap => {
      this.mapDocs(snap.map(e => e.payload.doc));
    });
  }

  private mapDocs(docs: QueryDocumentSnapshot<any>[]) {
    if (docs.length > 0) {
      const DOCS = docs.map(e => {
        let data: any = e.data();
        data.content = this.sanitizer.bypassSecurityTrustHtml(data.content);
        data.excerpt = data.excerpt;
        data.imageBg = this.sanitizer.bypassSecurityTrustStyle(`url(${data.image})`);
        data.routing = this.conectimedRoutes.mapRoute(data.category, e.id, data.title);
        return { id: e.id, ...data };
      });
      this.featured = DOCS;
    }
  }

  identify(index: number, item: any) {
    index;
    return item.id;
  }
}
