import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { AlertController, ActionSheetController, ModalController, NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { CameraSource } from '@capacitor/camera';
import { FileFormatService } from '@app/shared/services/file-format/file-format.service';
import { Device } from '@capacitor/device';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { DocumentData, DocumentReference, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { Browser } from '@capacitor/browser';
import { FilesService } from '@app/shared/services/files/files.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
const log = new Logger('Add Product');
import * as moment from 'moment';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';

@Component({
  selector: 'app-i-add-product',
  templateUrl: './i-add-product.component.html',
  styleUrls: ['./i-add-product.component.scss'],
})
export class IAddProductComponent implements OnInit {
  public dataForm: FormGroup;
  @ViewChild('file1', { read: ElementRef, static: true }) file1: ElementRef;
  @ViewChild('file2', { read: ElementRef, static: true }) file2: ElementRef;
  type: string = 'Medicamento';
  id: string;
  companyName: string;
  item: any;
  image: any;
  isUpdate: boolean = false;
  fileURL: any;
  fileToUpload1: any;
  fileToUpload2: any;
  fileObj: any;
  fileName: string;
  imageProfile: any;
  isLoading: boolean = false;
  public idProductModal = this.navParams.get('id');
  path = 'products';
  placeHolder = 'Sustancia Activa 1 *';

  constructor(
    private formBuilder: FormBuilder,

    private utilities: UtilitiesService,
    private alertCtrl: AlertController,
    private actionSheetController: ActionSheetController,
    private loader: IonLoaderService,
    private sanitizer: DomSanitizer,
    private modalCtrl: ModalController,
    private fileFormat: FileFormatService,
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private navParams: NavParams,
    private filesService: FilesService,
    private analyticsFB: AnalyticsService,
    private router: Router
  ) {
    if (this.idProductModal) {
      this.id = this.idProductModal;
    }
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  changePlaceHolder(ev: any) {
    log.debug(ev);
    this.formChange(this.dataForm.value.type);
  }

  formChange(type: 'Medicamento' | 'Dispositivo' | 'Servicio') {
    switch (type) {
      case 'Medicamento':
        // Sustancia Activa secundaria
        this.dataForm.controls['sustancia2'].setValidators([]);
        this.dataForm.controls['sustancia2'].updateValueAndValidity();
        // Línea terapéutica 1 *
        this.dataForm.controls['linea1'].setValidators([Validators.required]);
        this.dataForm.controls['linea1'].updateValueAndValidity();
        // Presentación 1 *
        this.dataForm.controls['presentacion1'].setValidators([Validators.required]);
        this.dataForm.controls['presentacion1'].updateValueAndValidity();
        break;

      case 'Dispositivo':
        // Sustancia Activa secundaria
        this.dataForm.controls['sustancia2'].setValidators([Validators.required]);
        this.dataForm.controls['sustancia2'].updateValueAndValidity();
        // Línea terapéutica 1 *
        this.dataForm.controls['linea1'].setValidators([Validators.required]);
        this.dataForm.controls['linea1'].updateValueAndValidity();
        // Presentación 1 *
        this.dataForm.controls['presentacion1'].setValidators([Validators.required]);
        this.dataForm.controls['presentacion1'].updateValueAndValidity();
        break;

      case 'Servicio':
        // Sustancia Activa secundaria
        this.dataForm.controls['sustancia2'].setValidators([]);
        this.dataForm.controls['sustancia2'].updateValueAndValidity();
        // Línea terapéutica 1 *
        this.dataForm.controls['linea1'].setValidators([]);
        this.dataForm.controls['linea1'].updateValueAndValidity();
        // Presentación 1 *
        this.dataForm.controls['presentacion1'].setValidators([]);
        this.dataForm.controls['presentacion1'].updateValueAndValidity();
        break;
    }
  }

  public async save(): Promise<void> {
    try {
      const PLAN = await this.fireService.myPlan(this.credService.credentials.uid);

      if (!(PLAN && PLAN.max_products_limit === false)) {
        let products_size: DocumentData;
        this.isLoading = true;
        await this.loader.ionLoaderPresent();
        try {
          products_size = await this.fireService.afs
            .collection('products')
            .ref.where('uid', '==', this.credService.credentials.uid)
            .get();
        } catch (e) {
          log.error(e);
          return;
        }
        await this.loader.ionLoaderDismiss();
        this.isLoading = false;
        if (!(Number(products_size.size) < Number(PLAN.max_products))) {
          this.showLimitAlert();
          return;
        }
      }
      await this.saveHandler();
    } catch (e) {
      log.error(e);
    }
    return;
  }

  async showLimitAlert() {
    const alert = await this.alertCtrl.create({
      header: '¡Límite superado!',
      message: 'El límite de productos ha sido alcanzado. Por lo tanto no se puede editar o agregar un productos',
      buttons: [
        {
          text: 'Enterado'
        }
      ]
    });
    alert.present();
  }

  public async saveHandler(): Promise<void> {
    this.isLoading = true;
    var refCompany = null;
    var FilesArray: any[] = [];
    const repData = await this.fireService.getRepData(this.credService.credentials.uid);
    if (repData.company && repData.company.uid) {
      refCompany = this.fireService.afs.collection('companies').doc(repData.company.uid).ref;
    }
    let data: any = {
      uid: this.credService.credentials.uid,
      title: this.dataForm.get('marca').value,
      marca: this.dataForm.get('marca').value,
      producto_sustancia_activa_1: this.dataForm.get('sustancia1').value,
      producto_sustancia_activa_2: this.dataForm.get('sustancia2').value,
      linea_terapeutica_1: this.dataForm.get('linea1').value,
      linea_terapeutica_2: this.dataForm.get('linea2').value,
      presentacion_1: this.dataForm.get('presentacion1').value,
      presentacion_2: this.dataForm.get('presentacion2').value,
      se_cuenta_con_muestra: this.dataForm.get('disponibilidad').value == true ? 1 : 0,
      type: this.dataForm.get('type').value,
      company: refCompany,
      status: 'active'
    };
    data.nameStr = this.utilities.stringSearch(data.title);

    if (!(this.item && this.item.allowMedicalSample && this.item.allowMedicalSample === true)) {
      data.se_cuenta_con_muestra = 0;
    }
    await this.loader.ionLoaderPresent();
    var postId = '';
    try {
      let post: DocumentReference | void;
      if (this.id) {
        /*** codigo pablo ****/
        const postDB = await this.fireService.getProduct(this.id);
        if (postDB.data()) {
          FilesArray = postDB.data().documents || [];
          postId = this.id;
        }
        try {
          await this.fireService.afs
            .collection('products')
            .doc(this.id)
            .update(data);
        } catch (error) {
          this.utilities.toast(
            'Error, no se pudo actulizar la información del producto, inténta nuevamente.',
            'Atención'
          );
        }
      } else {
        data.date = moment().toDate();
        post = await this.fireService.afs.collection('products').add(data);
        postId = post['id'];
      }
      if (postId) {
        try {
          /*** codigo pablo ****/
          if (this.fileToUpload1) {
            const file: any = await this.filesService.uploadImage(this.fileToUpload1, `products/images/${postId}`);
            if (file.main.url && file.main.url !== '') {
              if (this.image) {
                await this.filesService.deleteFile(this.image);
              }
              await this.fireService.afs
                .collection('products')
                .doc(postId)
                .update({ image: file.main.url });
            }
          }
          /*** codigo pablo ****/
          if (this.fileToUpload2) {
            const file2: any = await this.filesService.selectAnyFile(this.fileToUpload2, `products/docs/${postId}`, ['pdf']);
            if (file2.main.url && file2.main.url !== '') {
              if (this.fileURL) {
                await this.filesService.deleteFile(this.fileURL);
              }
              FilesArray[0] = file2.main.url;
              await this.fireService.afs
                .collection('products')
                .doc(postId)
                .update({ documents: FilesArray });
            }
          }
          await this.utilities.toast('Producto guardado correctamente.', 'Correcto');
          this.modalCtrl.dismiss({ modification: true });
        } catch (error) {
          log.error(error);
          this.utilities.toast(
            'La información de tu producto se guardó correctamente, pero ocurrió un error al subir tus archivos, inténta subirlos nuevamente.',
            'Atención'
          );
          this.modalCtrl.dismiss({ modification: true });
        }
      }
    } catch (error) {
      log.error(error);
      this.closeModal();
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async initializeApp() {
    this.initForm();
    await this.getCompanyName();
    if (this.id) {
      this.isUpdate = true;
      await this.getDetail();
    }
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.initializeApp();
    this.isLoading = false;
  }

  refillData(item: any) {
    this.dataForm.setValue({
      marca: item.marca,
      sustancia1: item.producto_sustancia_activa_1,
      sustancia2: item.producto_sustancia_activa_2,
      linea1: item.linea_terapeutica_1,
      linea2: item.linea_terapeutica_2,
      presentacion1: item.presentacion_1,
      presentacion2: item.presentacion_2,
      disponibilidad: Number(item.se_cuenta_con_muestra) == 1 ? true : false,
      type: item.type ? item.type : 'Medicamento'
    });
    this.formChange(item.type ? item.type : 'Medicamento');
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      marca: ['', Validators.required],
      sustancia1: ['', Validators.required],
      sustancia2: [''],
      linea1: ['', Validators.required],
      linea2: [''],
      presentacion1: ['', Validators.required],
      presentacion2: [''],
      disponibilidad: [false],
      type: ['Medicamento']
    });
  }

  async getCompanyName() {
    try {
      const response = await this.fireService.getRepData(this.credService.credentials.uid);
      const data = response.company;
      if (data) {
        this.companyName = data.name ? data.name : '';
      }
    } catch (error) {
      log.error(error);
    }
  }

  async getDetail() {
    try {
      const response = await this.fireService.getProduct(this.id);
      if (response.data().marca) {
        this.item = response.data();
        this.image = this.item && this.item.image ? this.item.image : undefined;
        if (this.image) {
          this.imageProfile = this.sanitizer.bypassSecurityTrustResourceUrl(this.image);
        }
        this.fileURL = this.item.documents && this.item.documents[0] ? this.item.documents[0] : undefined;
        this.refillData(this.item);
      }
    } catch (error) {
      log.error(error);
    }
  }

  async confirmDelete(url: string) {
    const alert = await this.alertCtrl.create({
      header: 'Eliminar archivo',
      message: '¿Estás seguro de eliminar este archivo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.deleteMedia(url);
          }
        }
      ]
    });
    alert.present();
  }

  async deleteMedia(url: string) {
    try {
      await this.filesService.deleteFile(url);
      await this.fireService.afs
        .collection('products')
        .doc(this.id)
        .update({ image: firebase.firestore.FieldValue.delete() });
      this.imageProfile = undefined;
      this.utilities.toast('Archivo eliminado correctamente.', 'Correcto');
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
  }

  async alert(message: string) {
    const alert = await this.alertCtrl.create({
      message: message,
      buttons: [
        {
          text: 'Aceptar',
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });
    alert.present();
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  async beforeCloseModal() {
    this.alertCtrl.create();
    const alert = await this.alertCtrl.create({
      header: '¡Confirmación de salida!',
      message: '¿Estás seguro de salir?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Salir',
          handler: () => {
            this.closeModal();
          }
        }
      ]
    });
    alert.present();
  }

  async open() {
    await Browser.open({ url: this.fileURL });
  }

  async presentActionSheet1() {
    let buttons = [
      {
        text: 'Galería',
        icon: 'images',
        handler: () => {
          this.imageFromGallery();
        }
      },
      {
        text: 'Cámara',
        icon: 'camera',
        handler: () => {
          this.takePicture(CameraSource.Camera);
        }
      },
      {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel'
      }
    ];
    if (this.image) {
      buttons.push({
        text: 'Eliminar',
        icon: 'trash',
        handler: () => {
          this.confirmDelete(this.image);
        }
      });
    }
    const actionSheet = await this.actionSheetController.create({
      header: 'Reemplazar imagen actual',
      buttons: buttons
    });
    actionSheet.present();
  }

  triggerClick1() {
    try {
      this.file1.nativeElement.click();
    } catch (error) {
      log.error(error);
    }
  }

  triggerClick2() {
    try {
      this.file2.nativeElement.click();
    } catch (error) {
      log.error(error);
    }
  }

  async selectFile1(data: any) {
    const file: any = data.files as FileList;
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response: any = await this.filesService.selectImageFile(file, this.path, 'preview');
      this.imageProfile = this.sanitizer.bypassSecurityTrustResourceUrl(response.main.path);
      this.fileToUpload1 = response.main.path;
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  selectFile2(data: any) {
    const file: any = data.files as FileList;
    try {
      if (this.fileFormat.validatePDFType(file.item(0).type)) {
        this.fileToUpload2 = file;
        this.fileName = file.item(0).name;
      } else {
        this.utilities.toast('Formato de archivo no válido.', 'Error');
      }
    } catch (error) {
      log.error(error);
    }
  }

  async takePicture(image?: CameraSource) {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response = await this.filesService.takePicture(image, this.path, 'preview');
      this.imageProfile = this.sanitizer.bypassSecurityTrustResourceUrl(response.main.path);
      this.fileToUpload1 = response.main.path;
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async imageFromGallery() {
    try {
      const info = await Device.getInfo();
      if (info && info.platform == 'web') {
        this.triggerClick1();
      } else {
        this.takePicture(CameraSource.Photos);
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
  }
}
