import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, IonBackButton } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { IAddProductComponent } from '@app/industry/components/i-add-product/i-add-product.component';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { FirebaseStorageService } from '@app/shared/services/firebase-storage/firebase-storage.service';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { Title } from '@angular/platform-browser';
import { SampleRequestService } from '@app/industry/services/sample-request.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('IProductComponent');

@Component({
  selector: 'app-i-product',
  templateUrl: './i-product.component.html',
  styleUrls: ['./i-product.component.scss'],
})
export class IProductComponent implements OnInit {
  @ViewChild(IonBackButton, { static: true }) backButton: IonBackButton;

  id: string;
  item: any;
  itemArr: any[] = [];
  companyName: string;
  urlFiles: string;
  fileURL: string;
  image: string;
  isMyProduct = false;
  isLoading = false;
  ////////////////
  user: any;
  userRaw: any;
  isMyFriend: boolean;
  relation: any;
  requestFalse: boolean;
  sentFalse: boolean;
  availability = false;
  r_per_page = 100;
  r_page = 1;
  canView = false;
  mainSubscribe: Subscription;
  company: any;
  public openConections: boolean = false;
  idProductModal = '';
  nedBack: boolean = false;

  constructor(
    public credService: CredentialsService,
    private router: Router,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private utilities: UtilitiesService,
    private fireService: FirebaseService,
    private firebaseStorage: FirebaseStorageService,
    private aRoute: ActivatedRoute,
    private analyticsFB: AnalyticsService,
    private titleService: Title,
    public sampleRequestService: SampleRequestService
  ) {
    this.constants();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  back() {
    window.history.back();
  }

  async ngOnInit() {
    if (window.history.state.navigationId !== 1) {
      this.nedBack = true;
    }
    this.idProductModal = await this.aRoute.snapshot.paramMap.get('id');
    this.isLoading = true;
    await this.initializeApp();
    this.isLoading = false;
  }

  resetButtons() {
    this.requestFalse = false;
    this.sentFalse = false;
  }

  async initializeApp() {
    try {
      if (this.mainSubscribe) {
        this.mainSubscribe.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
    this.resetButtons();
    await this.getDetail();
  }

  async doRefresh(event: any) {
    await this.initializeApp();
    try {
      event.target.complete();
    } catch (error) {
      log.error(error);
    }
  }

  async getDetail() {
    this.id = this.idProductModal;
    // suscription
    try {
      const response = this.fireService.getProductSubcription(this.id);
      this.mainSubscribe = response.subscribe(async (querySnapshot: any) => {
        querySnapshot.payload.data();
        if (
          querySnapshot &&
          querySnapshot.payload &&
          querySnapshot.payload.data() &&
          querySnapshot.payload.data().marca
        ) {
          this.item = querySnapshot.payload.data();
        }
        if (!(this.item && this.item.status === 'active')) {
          this.back();
        }
        if (this.item) {
          this.titleService.setTitle(`${this.item.type} | ${this.item.title}`);
          this.company = await this.fireService.getFullCompanyById(this.item.company.id);
          if (!this.company.logo) {
            const name: string = this.company.name;
            const split = name.split(' ');
            if (split.length > 1) {
              this.company.nameLogo = split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase();
            } else {
              this.company.nameLogo = name.charAt(0).toUpperCase();
            }
          }
          if (this.item && this.item.se_cuenta_con_muestra) {
            this.availability = Number(this.item.se_cuenta_con_muestra) === 1 ? true : false;
          }
          const authorProduct = String(this.item.uid);
          const userMe = String(this.credService.credentials.uid);
          this.isMyProduct = authorProduct === userMe;

          if (!this.isMyProduct) {
            const isMyFriendDoc = await this.fireService.isMyFriend(userMe, authorProduct);
            this.isMyFriend = isMyFriendDoc.exists;
            const relation = await this.fireService.getRelationShipByPaticipants(authorProduct);
            if (relation) {
              this.relation = (await this.fireService.getRelation(relation)).data();
              this.relation.id = relation;
              if (this.relation.initiator === userMe) {
                this.sentFalse = true;
              }
            }
          }

          const image = this.item && this.item.image ? this.item.image : undefined;
          this.urlFiles = this.item.documents && this.item.documents[0] ? this.item.documents[0] : undefined;
          if (authorProduct) {
            if (this.urlFiles) {
              this.fileURL = this.urlFiles;
            }
            if (image) {
              this.image = image;
            }
            this.userRaw = await this.fireService.getUserData(authorProduct);
            const metaData = await this.fireService.getRepData(authorProduct);
            this.user = this.userRaw;
            this.user.uid = this.item.uid;
            if (metaData) {
              this.user.empresa = metaData.company && metaData.company.name ? metaData.company.name : '';
            }
            if (String(this.user.uid) === String(this.credService.credentials.uid)) {
              this.canView = false;
            } else {
              this.canView = true;
            }
          }
        }
      });
    } catch (error) {
      log.error(error);
    }
  }

  async open() {
    this.analyticsClickEvent('click', {
      brand: this.item.marca,
      product_brand_type: this.item.type,
      content_type: 'Ficha tecnica/ IPP',
      section_name: 'Productos'
    })
    await Browser.open({ url: this.fileURL });
  }

  async sampleRequest(){    
    this.sampleRequestService.request(this.id);
    this.analyticsClickEvent('click', {
      content_type: 'Solicitud de muestra',
      object_id: this.item.uid,
      brand: this.item.marca,
      product_brand_type: this.item.type,
      click_text: 'Solicitar muestra',
      section_name: 'Producto'
    })
  }

  async deleteProduct(id: string) {
    this.isLoading = true;
    try {
      try {
        await this.fireService.deleteSingle(id, 'products');
      } catch (error) {
        log.error(error);
      }
      if (this.fileURL) {
        try {
          await this.firebaseStorage.deleteFile(this.fileURL);
        } catch (error) {
          log.error(error);
        }
      }
      if (this.image) {
        try {
          await this.firebaseStorage.deleteFile(this.image);
        } catch (error) {
          log.error(error);
        }
      }
      this.utilities.toast('Producto eliminado correctamente.', 'Correcto');
    } catch (error) {
      log.error(error);
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
    this.isLoading = false;
  }

  public share() {
    const route = `/multimedia/pharmaceutical-industry/products/${this.id}`;
    this.utilities.share(route);
    this.analyticsClickEvent('share', {
      content_type: this.item && this.item.marca ? this.item.marca : ''
    });
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  async update() {
    const modal = await this.modalCtrl.create({
      component: IAddProductComponent,
      componentProps: { id: this.id }
    });
    modal.onDidDismiss().then(() => {
      this.initializeApp();
    });
    modal.present();
  }

  // <confirmation alerts>
  async confirmCancel() {
    const alert = await this.alertCtrl.create({
      header: 'Cancelar solicitud',
      message: '¿Estás seguro de cancelar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: async () => {
            this.isLoading = true;
            await this.fireService.cancelRequest(this.relation.id);
            this.initializeApp();
            this.isLoading = false;
          }
        }
      ]
    });
    alert.present();
  }

  async confirmSend() {
    this.analyticsClickEvent('click', {
      content_type: 'Solicitud de muestra',
      brand: this.item.marca,
      product_brand_type: this.item.type,
      click_text: 'Solicitar muestra',
      section_name: 'Productos'
    })
    const alert = await this.alertCtrl.create({
      header: 'Para solicitar producto, este representante debe ser un contacto activo.',
      message: '¿Estás seguro de enviar solicitud de contacto?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: async () => {
            this.isLoading = true;
            this.analyticsClickEvent('send_request', {
              product_brand: this.item.marca,
              section_name: 'Productos',
              product_type: this.item.type,
              product_availability: this.item.se_cuenta_con_muestra,
              content_type: 'Confirmación de muestra'
            });
            await this.fireService.checkUserPoints(this.user.uid, true);
            this.initializeApp();
            this.isLoading = false;
          }
        }
      ]
    });
    alert.present();
  }

  async confirmDeleteProduct(id: string) {
    const alert = await this.alertCtrl.create({
      header: '¿Eliminar producto?',
      message: 'El producto se eliminará permanentemente.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.deleteProduct(id);
            this.analyticsClickEvent('delete_product', {
              content_type: 'Producto',
              content_title: this.item.name
            });
            this.router.navigate(['/product']);
          }
        }
      ]
    });
    alert.present();
  }
  // <//confirmation alerts>

  ionViewWillLeave() {
    try {
      if (this.mainSubscribe) {
        this.mainSubscribe.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
  }

  async constants() {
    (await this.fireService.getConstants()).subscribe((response: any) => {
      this.openConections = response.openConections;
    });
  }

  chat() {
    this.fireService.createChat(this.user.uid, ChatComponent);
    this.analyticsClickEvent('open_chat', {
      section_name: 'Industria'
    });
  }

  repreProducts() {
    this.router.navigateByUrl(`/products/user/${this.user.uid}`);
    this.analyticsClickEvent('view_products', {
      section_name: 'Products',
      company_owner: this.user.name,
      product_owner: this.user.data.name + ' ' + this.user.data.lastName1 + ' ' + this.user.data.lastName2
    });
  }

  companyDetail() {
    this.router.navigateByUrl(`multimedia/pharmaceutical-industry/company/${this.company.id}`);
    this.analyticsClickEvent('view_company', {
      section_name: 'Company',
      company_name: this.company.name
    });
  }
}
