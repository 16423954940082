import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Inicio',
      searchRoute: '/multimedia/posts/all',
      menuItem: '/home',
      hideBackButton: true
    }
  },
  {
    path: 'actions/:action',
    component: HomeComponent,
    data: {
      title: 'Inicio', searchRoute: '/multimedia/posts/all',
      menuItem: '/home',
      hideBackButton: true
    }
  },
  {
    path: 'survey/:dataSurvey',
    component: HomeComponent,
    data: {
      title: 'Inicio', searchRoute: '/multimedia/posts/all',
      menuItem: '/home',
      hideBackButton: true
    }
  },
  {
    path: 'chat/:chatID',
    component: HomeComponent,
    data: {
      title: 'Inicio', searchRoute: '/multimedia/posts/all',
      menuItem: '/home',
      hideBackButton: true
    }
  },
  {
    path: 'chat/user/:userID',
    component: HomeComponent,
    data: {
      title: 'Inicio', searchRoute: '/multimedia/posts/all',
      menuItem: '/home',
      hideBackButton: true
    }
  },
  {
    path: 'chat/user/:userID/:message',
    component: HomeComponent,
    data: {
      title: 'Inicio', searchRoute: '/multimedia/posts/all',
      menuItem: '/home',
      hideBackButton: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule { }
