<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> Seleciona los miembros del grupo </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding ion-no-margin" *ngIf="step === 2">
  <ion-grid>
    <ion-row>
      <ion-col size="4">
        <div class="full-height" *ngIf="itemsExcluded && itemsExcluded[0]; else massage1">
          <ion-list>
            <ion-item
              *ngFor="let item of itemsExcluded; let i = index"
              [color]="item?.selected === true ? 'primary' : 'light'"
              (click)="item.selected = !item.selected"
              class="ion-no-padding ion-no-margin"
              lines="full"
            >
              <ion-label class="ion-no-padding ion-no-margin">
                <div class="ion-text-wrap">
                  <strong> {{ i + 1 }}.- {{ item?.lastName1 }} {{ item?.lastName2 }} {{ item?.name }} </strong>
                  <br />
                  <small>
                    {{ item?.metaData?.specialty1 }}
                    {{ item?.metaData?.specialty2 }}
                    {{ item?.metaData?.state1 }}
                    {{ item?.metaData?.state2 }}
                  </small>
                  <ion-badge size="small" color="success" *ngIf="item?.inGroup === true">
                    <ion-icon name="people-outline"></ion-icon>
                  </ion-badge>
                </div>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
        <ng-template #massage1>
          <div class="ion-text-center" color="medium">
            <p>Sin usuarios</p>
          </div>
        </ng-template>
      </ion-col>
      <ion-col size="4">
        <ion-button (click)="addItem()" expand="block" color="light">
          Agregar
          <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="addAllItems()" expand="block" color="light">
          Agregar todos
          <ion-icon
            slot="end"
            name="chevron-forward-outline"
            class="ion-no-margin-vertical ion-no-padding-vertical"
          ></ion-icon>
          <ion-icon
            slot="end"
            name="chevron-forward-outline"
            class="ion-no-margin-vertical ion-no-padding-vertical"
          ></ion-icon>
        </ion-button>
        <ion-button (click)="removeItem()" expand="block" color="medium">
          <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
          Quitar
        </ion-button>
        <ion-button (click)="removeAllItems()" expand="block" color="medium">
          <ion-icon
            slot="start"
            name="chevron-back-outline"
            class="ion-no-margin ion-no-padding"
          ></ion-icon>
          <ion-icon
            slot="start"
            name="chevron-back-outline"
            class="ion-no-margin ion-no-padding"
          ></ion-icon>
          Quitar todos
        </ion-button>
        <div class="ion-text-center">
          <p>
            <strong>{{ itemsIncluded.length }} / {{ listLimit }}</strong>
          </p>
        </div>
      </ion-col>
      <ion-col size="4">
        <div class="full-height" *ngIf="itemsIncluded && itemsIncluded[0]; else massage2">
          <ion-list>
            <ion-item
              *ngFor="let item of itemsIncluded; let i = index"
              [color]="item?.selected === true ? 'primary' : 'light'"
              (click)="item.selected = !item.selected"
              class="ion-no-padding ion-no-margin"
              lines="full"
            >
              <ion-label class="ion-no-padding ion-no-margin">
                <div class="ion-text-wrap">
                  <strong> {{ i + 1 }}.- {{ item?.lastName1 }} {{ item?.lastName2 }} {{ item?.name }} </strong>
                  <br />
                  <small>
                    {{ item?.metaData?.specialty1 }}
                    {{ item?.metaData?.specialty2 }}
                    {{ item?.metaData?.state1 }}
                    {{ item?.metaData?.state2 }}
                  </small>
                </div>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
        <ng-template #massage2>
          <div class="ion-text-center" color="medium">
            <p>Sin usuarios</p>
          </div>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="step === 1">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-searchbar
          class="ion-margin-top"
          type="text"
          debounce="2000"
          (ionChange)="search($event)"
          enterkeyhint="send"
          placeholder="Buscar"
          animated
          [disabled]="isLoading"
        >
        </ion-searchbar>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-no-margin ion-no-padding">
        <ion-segment style="margin-top: 5px; margin-bottom: 5px;" [scrollable]="true" [(ngModel)]="currentFilter">
          <ion-segment-button
            *ngFor="let item of filterabc"
            (click)="filterList(item?.filter)"
            mode="md"
            class="segment-abc"
            [value]="item?.filter"
            [disabled]="!item?.see || isLoading == true"
          >
            <h6 style="margin: 0px;">{{ item.title }}</h6>
          </ion-segment-button>
        </ion-segment>
        <p style="margin: 0px; font-size: 14px; color: var(--ion-color-medium);" class="ion-hide-md-up ion-text-center">
          Para ver las demas letras haga scroll
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col sizeXs="12" sizeMd="6">
        <ion-button
          fill="solid"
          *ngIf="newMembers !== true"
          expand="block"
          color="tertiary"
          (click)="specialtiesFilter()"
          [disabled]="isLoading"
          class="ion-text-wrap"
        >
          <ion-icon name="school-outline" slot="start"></ion-icon>
          Segmentar por especialidad
        </ion-button>
        <ion-list class="ion-margin-top" *ngIf="filter2 && filter2[0]; else massage3">
          <ion-item *ngFor="let item of filter2; let i = index" lines="none">
            <ion-label>{{ i + 1 }}.- {{ item?.label }}</ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="removeFilterItem2(i)">
                <ion-icon slot="start" name="close-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
        <ng-template #massage3>
          <ion-text class="ion-text-center" color="medium">
            <p>Sin filtros</p>
          </ion-text>
        </ng-template>
      </ion-col>
      <ion-col sizeXs="12" sizeMd="6">
        <ion-button
          fill="solid"
          *ngIf="newMembers !== true"
          expand="block"
          color="tertiary"
          (click)="locationsFilter()"
          [disabled]="isLoading"
          class="ion-text-wrap"
        >
          <ion-icon name="location-outline" slot="start"></ion-icon>
          Segmentar por zona geográfica
        </ion-button>
        <ion-list class="ion-margin-top" *ngIf="filter1 && filter1[0]; else massage4">
          <ion-item *ngFor="let item of filter1; let i = index" lines="none">
            <ion-label>{{ i + 1 }}.- {{ item?.id }}</ion-label>
            <ion-buttons slot="end">
              <ion-button (click)="removeFilterItem1(i)">
                <ion-icon slot="start" name="close-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
        <ng-template #massage4>
          <ion-text class="ion-text-center" color="medium">
            <p>Sin filtros</p>
          </ion-text>
        </ng-template>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button
          *ngIf="memberType === 'medico'"
          shape="round"
          color="secondary"
          size="small"
          (click)="resetSearch()"
          [disabled]="isLoading"
        >
          Médicos
          <ion-icon slot="end" name="close-outline"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData2; let i = index"
          (click)="removeEspecialtiesFilter()"
          [disabled]="isLoading"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close-outline"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData1; let i = index"
          (click)="removeStatesFilter()"
          [disabled]="isLoading"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="6">
        <ion-button
          (click)="clear()"
          expand="block"
          color="danger"
          shape="outline"
          [disabled]="isLoading"
          *ngIf="step === 1"
        >
          <ion-icon slot="start" name="refresh-outline"></ion-icon>
          Limpiar
        </ion-button>
        <ion-button
          (click)="step = 1"
          expand="block"
          color="danger"
          shape="outline"
          [disabled]="isLoading"
          *ngIf="step === 2"
        >
          <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
          Regresar
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button
          (click)="filterUsers()"
          expand="block"
          color="secondary"
          [disabled]="isLoading || (!(filter1 && filter1[0]) && !(filter2 && filter2[0]))"
          *ngIf="step === 1"
        >
          <ion-icon slot="start" name="add-outline"></ion-icon>
          Filtrar ({{ filter1.length + filter2.length }})
        </ion-button>

        <ion-button
          (click)="save()"
          expand="block"
          color="secondary"
          [disabled]="isLoading || !(itemsIncluded && itemsIncluded[0])"
          *ngIf="step === 2"
        >
          <ion-icon slot="start" name="save-outline"></ion-icon>
          {{ idGroup ? 'Actualizar' : 'Guardar' }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
