import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-i-refresh-web',
  templateUrl: './i-refresh-web.component.html',
  styleUrls: ['./i-refresh-web.component.scss'],
})
export class IRefreshWebComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
