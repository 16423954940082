import { Component, Input, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-shared-files-pop-over',
  templateUrl: './shared-files-pop-over.component.html',
  styleUrls: ['./shared-files-pop-over.component.scss']
})
export class SharedFilesPopOverComponent implements OnInit {
  @Input() files: any[] = [];

  constructor(
    private analyticsFB: AnalyticsService,
    public credService: CredentialsService,
    private router: Router
  ) { }

  ngOnInit() { }

  async open(url: string, file: any) {
    await Browser.open({ url: url });
    this.analyticsClickEvent('file_download', {
      file_extension: file.mime_type,
      link_id: file.link_id,
      file_name: file.name,
      link_text: file.name,
      link_url: url
    });
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }
}
