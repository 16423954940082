import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { FileTransfer, FileTransferObject, FileUploadOptions, FileUploadResult } from '@awesome-cordova-plugins/file-transfer/ngx';
//import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { FileChooser } from '@awesome-cordova-plugins/file-chooser/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { UtilitiesService } from '../utilities/utilities.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { Camera, Photo, CameraSource, CameraResultType, ImageOptions } from '@capacitor/camera';
import { Logger } from '@app/core/logger.service';
const log = new Logger('App');

@Injectable({
  providedIn: 'root'
})
export class FileTranferService {
  private documentTypes: string[] = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf'
  ];
  private pdfTypes: string[] = ['application/pdf'];
  private imageTypes: string[] = ['image/jpeg', 'image/png'];
  private maxFileSize: number = 8388608; //8 Mb aprox
  private api: string;
  private XFile: any;

  constructor(
    private file: File,
    private webview: WebView,
    private actionSheetController: ActionSheetController,
    private transfer: FileTransfer,
    //private iosFile: IOSFilePicker,
    private fileChooser: FileChooser,
    private filePath: FilePath,
    private utilities: UtilitiesService,
    private loader: IonLoaderService
  ) { }

  ngOnInit() { }

  public setApi(api: string) {
    this.api = api;
  }

  public getObjFile(): any {
    return this.XFile;
  }

  public async selectFile(platform: 'android' | 'ios', type: 'image' | 'document' | 'pdf' | 'video', token?: string) {
    log.debug(platform, type, token);
    try {
      let path;
      let pathP;
      if (platform === 'ios') {
        path = await this.fileChooser.open();
        pathP = 'file://' + path;
      } else {
        try {
          path = await this.fileChooser.open();
          pathP = await this.filePath.resolveNativePath(path);
          path = pathP.replace('file://', '');
        } catch (error) {
          log.error(error);
        }
      }
      const entry = await this.file.resolveLocalFilesystemUrl(pathP);

      let encodeURIPath = '';

      try {
        encodeURIPath = entry.filesystem.encodeURIPath(pathP);
      } catch (e) {
        log.error(e);
      }

      let filePath = path;
      let resPath = this.pathForFile(filePath);
      let newEntry = {
        encodeURIPath: encodeURIPath,
        fullPath: entry.fullPath,
        nativeURL: entry.nativeURL,
        name: entry.name,
        path: resPath,
        filePath: filePath
      };
      var _fileEntry = await this.getFile(<FileEntry>entry);
      const newType = type ? type : 'document';
      this.XFile = this.readFile(_fileEntry, newEntry, newType);
      if (token) {
        if (this.XFile && this.XFile.filePath) {
          try {
            const response = await this.upload(this.XFile.filePath, token);
            if (response.responseCode == Number(200) || response.responseCode == Number(201)) {
              this.XFile = JSON.parse(response.response);
              return this.XFile;
            } else {
              this.utilities.toast('Intentalo mas tarde', 'Error');
            }
          } catch (error) {
            this.utilities.toast('Intentalo mas tarde', 'Error');
          }
        } else {
          this.utilities.toast('Intentalo mas tarde', 'Error');
        }
      }
      return this.XFile;
    } catch (error) {
      log.error(error);
    }
    return;
  }

  private async takePicture(sourceType: CameraSource, resultType: CameraResultType, token?: string) {
    await this.loader.ionLoaderPresent();
    try {
      if (!sourceType) {
        sourceType = CameraSource.Camera;
      }
      let options: ImageOptions = {
        quality: 40,
        allowEditing: false,
        resultType: resultType,
        source: sourceType
      };
      if (sourceType == 'CAMERA') {
        options.height = 900;
        options.width = 650;
      }
      const image: Photo = await Camera.getPhoto(options);
      const imagePath = image.path;
      var currentExt = imagePath.substr(imagePath.lastIndexOf('.') + 1);
      var currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
      var correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
      this.XFile = await this.copyFileToLocalDir(correctPath, currentName, this.createFileName(currentExt));
      if (token) {
        if (this.XFile && this.XFile.filePath) {
          try {
            const response: any = await this.upload(this.XFile.filePath, token);
            if (response.responseCode == Number(200) || response.responseCode == Number(201)) {
              this.utilities.toast('', 'Correcto');
              this.XFile = JSON.parse(response.response);

              return this.XFile;
            } else {
              this.utilities.toast('Intentalo mas tarde 3', 'Error');
            }
          } catch (error) {
            this.utilities.toast('Intentalo mas tarde 2', 'Error');
          }
        } else {
          this.utilities.toast('Intentalo mas tarde 1', 'Error');
        }
      }

      return this.XFile;
    } catch (error) {

      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    return;
  }

  public async selectImage(resultType: CameraResultType, token: string) {
    let op: CameraSource;
    const actionSheet = await this.actionSheetController.create({
      header: 'Seleccione una imagen',
      buttons: [
        {
          text: 'Galería',
          handler: async () => {
            op = CameraSource.Photos;
            actionSheet.dismiss();
            return false;
          }
        },
        {
          text: 'Cámara',
          handler: async () => {
            op = CameraSource.Camera;
            actionSheet.dismiss();
            return false;
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });
    try {
      actionSheet.present();
      await actionSheet.onDidDismiss();
      return await this.takePicture(op, resultType, token);
    } catch (error) {
      this.utilities.toast('Intentalo mas tarde', 'Error');
    }
  }

  public async fromGallery(resultType: CameraResultType, token?: string) {
    return await this.takePicture(CameraSource.Photos, resultType, token);
  }

  public async fromCamera(resultType: CameraResultType, token?: string) {
    return await this.takePicture(CameraSource.Camera, resultType, token);
  }

  private createFileName(ext: string) {
    var d = new Date(),
      n = d.getTime(),
      newFileName = n + '.' + ext;
    return newFileName;
  }

  private async copyFileToLocalDir(namePath: string, currentName: string, newFileName: string): Promise<any> {
    log.debug(namePath, currentName, newFileName);
    try {
      await this.file.copyFile(namePath, currentName, this.file.dataDirectory, newFileName);
      let filePath = this.file.dataDirectory + newFileName;
      let resPath = this.pathForFile(filePath);
      let newEntry = {
        name: newFileName,
        path: resPath,
        filePath: filePath
      };
      return await this.startUpload(newEntry);
    } catch (error) {
      log.error(error);
    }
    return;
  }

  private async startUpload(fileEntry: any): Promise<any> {
    log.debug(fileEntry);
    try {
      const entry = await this.file.resolveLocalFilesystemUrl(fileEntry.filePath);
      var _fileEntry = await this.getFile(<FileEntry>entry);
      return this.readFile(_fileEntry, fileEntry, 'image');
    } catch (error) {
      log.error(error);
    }
    return;
  }

  private async getFile(fileEntry: any/*FileEntry*/) {
    log.debug(fileEntry);
    try {
      return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      log.error(err);
      return false;
    }
  }

  private readFile(file: any, fileEntry: any, type: 'image' | 'document' | 'pdf' | 'video'): any {
    if (Number(file.size) <= Number(this.maxFileSize)) {
      if (type === 'document') {
        const index = this.documentTypes.indexOf(String(file.type));
        if (index > -1) {
          return fileEntry;
        } else {
          this.utilities.toast('Formato de Archivo no valido', 'Error');
        }
      } else if (type === 'pdf') {
        const index = this.pdfTypes.indexOf(String(file.type));
        if (index > -1) {
          return fileEntry;
        } else {
          this.utilities.toast('Formato de Archivo no valido', 'Error');
        }
      } else if (type === 'video') {
        return;
      } else if (type === 'image') {
        const index = this.imageTypes.indexOf(String(file.type));
        if (index > -1) {
          return fileEntry;
        } else {
          this.utilities.toast('Formato de Archivo no valido', 'Error');
        }
      } else {
        this.utilities.toast('Formato de Archivo no valido', 'Error');
        return;
      }
    } else {
      this.utilities.toast('8 Mb maximo.', 'Error');
    }
    return;
  }

  public upload(path: string, token: string): Promise<FileUploadResult> | any{
    log.debug(path);
    log.debug(token);
    try {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const fileTransfer: FileTransferObject = this.transfer.create();
      let options: FileUploadOptions = {
        fileName: path.substr(path.lastIndexOf('/') + 1),
        chunkedMode: false,
        httpMethod: 'POST',
        headers: headers
      };
      return fileTransfer.upload(path, this.api, options);
    } catch (error) {
      log.error(error);
    }
  }

  private pathForFile(img: string) {
    log.debug(img);
    if (img === null) {
      return '';
    } else {
      let converted = this.webview.convertFileSrc(img);
      return converted;
    }
  }
}
