import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@app/shared/shared.module';
import { RegisterRoutingModule } from './register-routing.module';
import { RegisterComponent } from './register.component';
import { WaitingRoomComponent } from './waiting-room/waiting-room.component';
import { UpdatePhoneComponent } from './update-phone/update-phone.component';
import { RepresentanteComponent } from './representante/representante.component';
import { SpecilatySelectComponent } from './specilaty-select/specilaty-select.component';
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    IonicModule,
    RegisterRoutingModule,
    SharedModule,
    CodeInputModule.forRoot({
      codeLength: 6,
      isCharsCode: true
    })
  ],
  declarations: [
    RegisterComponent,
    WaitingRoomComponent,
    UpdatePhoneComponent,
    RepresentanteComponent,
    SpecilatySelectComponent
  ]
})
export class RegisterModule { }
