<ion-grid fixed class="ion-no-padding">
  <ion-row>
    <ion-col size="12">
      <ion-card color="secondary" (click)="detail()">
        <ion-item color="tertiary" lines="none" detail="true">
          <ion-icon slot="start" name="eye-outline"></ion-icon>
          <ion-label>
            <h2>
              Detalles
            </h2>
          </ion-label>
        </ion-item>
      </ion-card>
      <ion-card color="secondary" (click)="members()">
        <ion-item color="tertiary" lines="none" detail="true">
          <ion-icon slot="start" name="people-outline"></ion-icon>
          <ion-label>
            <h2>
              Miembros
            </h2>
          </ion-label>
        </ion-item>
      </ion-card>
      <ion-card color="secondary" (click)="chatGroup()">
        <ion-item color="tertiary" lines="none" detail="true">
          <ion-icon slot="start" name="chatbox-outline"></ion-icon>
          <ion-label>
            <h2>
              Mensaje
            </h2>
          </ion-label>
        </ion-item>
      </ion-card>
      <ion-card color="danger" (click)="close()">
        <ion-item color="danger" lines="none">
          <ion-icon slot="start" name="close-outline"></ion-icon>
          <ion-label>
            <h2>
              Cancelar
            </h2>
          </ion-label>
        </ion-item>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
