import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotificationCenterComponent } from './notification-center.component';

const routes: Routes = [
  {
    path: ':type/:id',
    component: NotificationCenterComponent,
    data: {
      title: 'Centro de Notificaciones'
    }
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class NotificationCenterRoutingModule { }
