import { Injectable } from '@angular/core';
import { Logger } from '@app/core/logger.service';
import { AlertController } from '@ionic/angular';
import { EmailService } from '../email/email.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('DeleteMyAccountService');

@Injectable({
  providedIn: 'root'
})
export class DeleteMyAccountService {
  constructor(
    public credService: CredentialsService,
    private alertCtrl: AlertController,
    private loader: IonLoaderService,
    private email: EmailService
  ) { }

  public async deleteAccountAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Eliminar mi cuenta de Conectimed',
      message: `Al termino de este proceso sus datos serán eliminados totalmente y de forma permanente, esta acción es irreversible y no podremos recuperar su información si en un futuro desea recuperarla.<br/>
      Si esta totalmente seguro de realizar la eliminación de su cuenta de click en el botón "Eliminar".`,
      buttons: [
        {
          text: 'Eliminar',
          handler: () => {
            this.deleteAccount();
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        }
      ]
    });
    alert.present();
  }

  private async deleteAccountSuccessAlert() {
    const email: string = this.credService.credentials.email;
    const alert = await this.alertCtrl.create({
      header: 'Proceso de eliminacion de mi cuenta de Conectimed',
      message: `Se ha iniciado el proceso de eliminación de cuenta de Conectimed, en un lapso de 24 a 48 horas le enviaremos un correo electrónico a <strong>${email}</strong> con la confirmación de la eliminación de su cuenta de Conectimed.`,
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }
      ]
    });
    alert.present();
  }

  private async deleteAccount() {
    await this.loader.ionLoaderPresent();
    try {
      await this.email.deleteMyAccount();
      await this.deleteAccountSuccessAlert();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
  }
}
