import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { SafeHtml } from '@angular/platform-browser';
const log = new Logger('ItemListComponent');
import { FavoriteService } from '@app/multimedia/services/favorite.service';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent implements OnInit {
  @Input() item: any;
  @Input() toolbar: boolean;
  isLoading: boolean = false;

  constructor(
    private utilities: UtilitiesService,
    public credService: CredentialsService,
    public router: Router,
    private analyticsFB: AnalyticsService,
    private favoriteS: FavoriteService
  ) { }

  ngOnInit() {
    try {
      this.item.excerpt = this.stripHtml(this.item && this.item.excerpt ? this.item.excerpt : '');
    } catch (e) { }
  }


  stripHtml(html: string) {
    var div = document.createElement("DIV");

    div.innerHTML = html;

    let cleanText = div.innerText;

    div = null; // prevent mem leaks

    return cleanText;
  }

  public isSafeHtml(value: string | SafeHtml) {
    if (typeof value === 'string') {
      return false;
    }
    return true;
  }

  public share(item: any) {
    this.utilities.share(item.routing.route);
    this.analyticsClickEvent('share', {
      content_type: item && item.title ? item.title : '',
      section_name: 'Publicaciones'
    });
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }


  public async favorite() {
    this.isLoading = true;
    try {
      this.item.favorite = await this.favoriteS.favorite(this.item.id);
    } catch (error) {
      log.error('Transaction failed: ', error);
    }
    this.isLoading = false;
  }
}
