import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatListComponent } from './chat-list.component';

const routes: Routes = [
  {
    path: '',
    component: ChatListComponent,
    data: {
      title: 'Chat',
      icon: {
        name: "chatbox-outline"
      },
      root: "/chat-list",
      menuItem: '/chat-list'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ChatListRoutingModule { }
