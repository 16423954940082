<ion-content>
  <ion-grid [fixed]="false">
    <ion-row>
      <ion-col size="12">
        <!-- Company Card -->
        <ion-card *ngIf="company" class="animate__animated animate__fadeIn">
          <ion-grid [fixed]="false">
            <ion-row class="ion-align-items-center ion-justify-content-center">
              <ion-col size-xl="6" size-lg="6" size-md="12" size-sm="12" size-xs="12">
                <app-i-company-card [company]="company" [outstanding]="true"></app-i-company-card>
              </ion-col>
              <ion-col size-xl="6" size-lg="6" size-md="12" size-sm="12" size-xs="12">
                <app-i-rep-card [user]="rep"></app-i-rep-card>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-card-content>
            <div class="separador-h"></div>
          </ion-card-content>
          <ion-card-content>
            <ion-card-subtitle> {{ company?.description }}</ion-card-subtitle>
          </ion-card-content>
          <ion-card-content class="ion-text-center">
            <ion-button (click)="share()" shape="round">
              Compartir empresa
              <ion-icon slot="end" name="share-social-outline"></ion-icon>
            </ion-button>
          </ion-card-content>
          <ion-card-content>
            <ion-grid [fixed]="false">
              <ion-row>
                <ion-col *ngFor="let item of products; let i = index" sizeXs="12" sizeMd="6">
                  <app-i-item [item]="item"></app-i-item>
                </ion-col>
                <!-- Skeleton loading -->
                <ion-col size="12" *ngIf="isLoading === true">
                  <app-i-skeleton></app-i-skeleton>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <!-- Products -->
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- Infinite scroll (get next elements) -->
  <ion-infinite-scroll threshold="100px" (ionInfinite)="getMoreItems($event)" [disabled]="isLoading === true">
    <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Cargando información...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>