<form [formGroup]="dataForm" (ngSubmit)="submit()">
  <ion-list>
    <ion-list-header>
      <ion-label color="secondary">Información personal</ion-label>
    </ion-list-header>
    <ion-item [lines]="dataForm.get('name').value === '' ? 'true' : 'none'">
      <ion-input label="Nombre (s) *" type="text" formControlName="name" autofocus="true"></ion-input>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('name').touched && dataForm.get('name').getError('required')"
    >
      <span class="ion-padding" >El nombre es requerido</span>
    </ion-text>
    <ion-item [lines]="dataForm.get('lastName1').value === '' ? 'true' : 'none'">
      <ion-input label="Apellido paterno *" type="text" formControlName="lastName1"></ion-input>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('lastName1').touched && dataForm.get('lastName1').getError('required')"
    >
      <span class="ion-padding" >El apellido paterno es requerido</span>
    </ion-text>
    <ion-item [lines]="dataForm.get('lastName2').value === '' ? 'true' : 'none'">
      <ion-input label="Apellido materno" type="text" formControlName="lastName2"></ion-input>
    </ion-item>
    <!-- Mobile Phone -->
    <div class="i-padding">
      <ion-item lines="none" class="item-round" color="light">
        <ion-select label="Teléfono móvil" slot="start" formControlName="mobileLada" placeholder="Selecione el país">
          <ion-select-option *ngFor="let item of lada?.countries" [value]="item?.lada">
            {{ item?.country }} ({{ item?.lada }})
          </ion-select-option>
        </ion-select>
        <ion-input formControlName="mobile" placeholder="Teléfono móvil" type="tel" inputmode="tel"></ion-input>
      </ion-item>
    </div>
    <!-- Phone -->
    <div class="i-padding">
      <ion-item lines="none" class="item-round" color="light">
        <ion-select label="Teléfono fijo" slot="start" formControlName="phoneLada" placeholder="Selecione el país">
          <ion-select-option *ngFor="let item of lada?.countries" [value]="item?.lada">
            {{ item?.country }} ({{ item?.lada }})
          </ion-select-option>
        </ion-select>
        <ion-input formControlName="phone" placeholder="Teléfono fijo" type="tel" inputmode="tel"></ion-input>
      </ion-item>
    </div>
  </ion-list>
  <ion-text color="danger">
    <p>* Datos obligatorios</p>
  </ion-text>
  <div class="ion-padding">
    <ion-button
      type="submit"
      color="tertiary"
      expand="block"
      [disabled]="
        !dataForm.valid || dataForm.value.termsAndConditions == false || isLoading || phone.error || mobile.error
      "
    >
      {{ button }}
    </ion-button>
  </div>
</form>
