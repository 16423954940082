<form [formGroup]="dataForm" (ngSubmit)="submit()">
  <ion-list>
    <ion-list-header>
      <ion-label color="secondary">Información profesional</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-select label="Tipo de profesional de salud *" placeholder="Tipo de profesional de salud" formControlName="healthProfessionalType"
        autofocus="true">
        <ion-select-option *ngFor="let item of typesOfHealthProfessionals" [value]="item.id">{{ item.name }} </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-text class="item-with-error" color="danger"
      *ngIf="dataForm.get('healthProfessionalType').touched && dataForm.get('healthProfessionalType').getError('required')">
      <span class="ion-padding">Tipo de profesional de salud es requerido</span>
    </ion-text>
    <ion-item>
      <ion-input label="Cédula profesional (1) *" type="text" formControlName="professionalID1"></ion-input>
    </ion-item>
    <ion-text class="item-with-error" color="danger"
      *ngIf="dataForm.get('professionalID1').touched && dataForm.get('professionalID1').getError('required')">
      <span class="ion-padding">La primer cédula profesional es requerida</span>
    </ion-text>
    <ion-text class="item-with-error" color="danger"
      *ngIf="dataForm.get('professionalID1').touched && dataForm.get('professionalID1').getError('whitespace')">
      <span class="ion-padding">La primer cédula profesional inválida, no usar espacios en blanco</span>
    </ion-text>
  </ion-list>
  <ion-text *ngIf="dataForm.value.specialty2 > 0 && dataForm.value.healthProfessionalType === dataForm.value.specialty2"
    color="danger">
    <p>* Las especialidades deben ser diferentes.</p>
  </ion-text>
  <ion-text *ngIf="
      dataForm.value.specialty2 > 0 &&
      dataForm.value.professionalID2 != '' &&
      dataForm.value.professionalID1 === dataForm.value.professionalID2
    " color="danger">
    <p>* Las cédulas deben ser diferentes.</p>
  </ion-text>
  <ion-text color="danger">
    <p>* Datos obligatorios</p>
  </ion-text>
  <div class="ion-padding">
    <ion-button type="submit" color="tertiary" expand="block" [disabled]="
        !dataForm.valid ||
        isLoading
      ">
      {{ button }}
    </ion-button>
  </div>
</form>