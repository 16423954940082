import { Component, OnInit, ViewChild } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ModalController, IonContent, Platform } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { SearchService } from '@app/shared/services/search/search.service';
import { IndustryService } from '../../services/industry.service';
const log = new Logger('IMedicinesComponent');
const iType: string = 'Medicamento';

@Component({
  selector: 'app-i-medicines',
  templateUrl: './i-medicines.component.html',
  styleUrls: ['./i-medicines.component.scss'],
})
export class IMedicinesComponent implements OnInit {
  @ViewChild(IonContent, { static: true }) content: IonContent;
  public products: any[] = [];
  public searchStr: string;
  public isLoading: boolean = false;
  public scrollButton = false;
  public yScroll = 0;

  constructor(
    public modalCtrl: ModalController,
    private credService: CredentialsService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private analyticsFB: AnalyticsService,
    private platform: Platform,
    private searchService: SearchService,
    private industryService: IndustryService
  ) {
    this.initializeItems();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  public async getMoreItems(event: any): Promise<void> {
    await this.paginationPosts('next');
    if (event && event.target && event.target.complete) {
      event.target.complete();
    }
  }

  ngOnInit() { }

  private initializeItems() {
    this.aRoute.params.subscribe(params => {
      this.searchStr = this.searchService.decodeString(params && params.search ? params.search : undefined);
      this.industryService.where = [
        { fieldPath: 'type', opStr: '==', value: iType },
        { fieldPath: 'status', opStr: '==', value: 'active' },
        { fieldPath: 'search', opStr: 'array-contains', value: this.searchStr }
      ];
      this.industryService.limit = 20;
      this.industryService.clearItems();
      this.paginationPosts();
    });
  }

  async paginationPosts(direction?: "next" | "back") {
    this.isLoading = true;
    try {
      await this.industryService.pagination(direction);
      this.products = this.industryService.items;
    } catch (e) {
      log.error(e);
    }
    this.isLoading = false;
  }

  analyticsLogs() {
    if (!!this.searchStr) {
      this.analyticsClickEvent('search', {
        search_term: this.searchStr,
        content_type: 'Búsqueda multimedia',
        section_name: "Algoritmos"
      });
    }
  }

  private onTop(ev: any) {
    if (ev.detail.scrollTop === 0) {
      this.scrollButton = false;
    } else {
      this.scrollButton = true;
    }
  }

  async doRefresh(event?: any) {
    this.industryService.clearItems();
    await this.paginationPosts();
    if (event) {
      event.target.complete();
    }
  }

  public onScroll(event: any) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logScrolling(
      event,
      { content_type: `Multimedia Algoritmos` },
      { credentials: cred, page_data: { route: route_page_url, title: document.title } }
    ); // Analytics LOGS
    this.onTop(event);
    if (!this.platform.is('ios') || !this.platform.is('ipad') || !this.platform.is('iphone')) {
      this.yScroll = event.detail.scrollTop;
    }
  }
}
