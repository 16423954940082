import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Logger } from '@app/core/logger.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { Storage } from '@ionic/storage-angular';
import { App } from '@capacitor/app';
import { UrlToRedirectService } from '@app/shared/services/url-to-redirect/url-to-redirect.service';
import { RegisterToWebinarService } from '@app/shared/services/register-to-webinar/register-to-webinar.service';
const log = new Logger('LoginComponent');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm!: FormGroup;
  isLoading = false;
  subscription: Subscription;
  counter: number = 0;
  loginAttempts: number = 0;
  showPassword: boolean = false;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private platform: Platform,
    private authServe: AuthenticationService,
    private credService: CredentialsService,
    private utilities: UtilitiesService,
    private toastCtrl: ToastController,
    private analyticsFB: AnalyticsService,
    private storage: Storage,
    private modalCtrl: ModalController,
    private loader: IonLoaderService,
    private urlToRedirect: UrlToRedirectService,
    private registerToWebinarS: RegisterToWebinarService
  ) {
    this.storage.clear();
    this.createForm();
    this.aRoute.params.subscribe(params => {
      if (params && params.user) {
        this.loginForm.get('email').setValue(String(params.user).toLowerCase().trim());
      }
    });
  }

  async ionViewDidEnter() {
    try {
      await this.authServe.userInAccountActivationProcces();
    } catch (error) {
      log.error(error);
    }
    this.subscription = this.platform.backButton.subscribe(() => {
      if (this.counter == 0) {
        this.counter++;
        this.presentToast();
        setTimeout(() => {
          this.counter = 0;
        }, 3000);
      } else {
        App.exitApp();
      }
    });
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }


  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Presione nuevamente para salir.',
      duration: 3000
    });
    toast.present();
  }

  async login() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const resp = await this.authServe.signInWithEmailAndPassword(
        String(this.loginForm.get('email').value)
          .toLocaleLowerCase()
          .trim(),
        this.loginForm.get('password').value
      );
      if (resp && resp.success && resp.success === true) {
        await this.closeModal();
        this.router.navigate([this.urlToRedirect.getData().url || (this.aRoute.snapshot as any)._routerState.url || this.aRoute.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
        if (this.urlToRedirect && this.urlToRedirect.getData() && this.urlToRedirect.getData().id && this.urlToRedirect.getData().url) {
          setTimeout(() => {
            this.registerToWebinarS.register(this.urlToRedirect.getData().id, this.urlToRedirect.getData().item, true);
          }, 2000);
        }
      } else {
        this.loginErrorHandling(resp.data);
      }
    } catch (error: any) {
      const code = error && error.code ? String(error.code) : '';
      this.loginErrorHandling(code);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  private loginErrorHandling(code: string) {
    switch (code) {
      case 'auth/user-not-found':
        this.utilities.presentAlert(
          'La cuenta no existe, por favor verifique que su email sea correcto.',
          'La cuenta no existe'
        );
        break;
      case 'auth/wrong-password':
        this.loginAttempts++;
        if (this.loginAttempts > 1) {
          this.utilities.presentAlert(
            'Contraseña incorrecta, por favor intente de nuevo.  Si no recuerda su contraseña solicite una recuperación para evitar que su cuenta sea bloqueada.',
            'Contraseña incorrecta'
          );
        } else {
          this.utilities.presentAlert('Contraseña incorrecta, por favor intente de nuevo.', 'Contraseña incorrecta');
        }
        break;
      case 'auth/invalid-email':
        this.utilities.presentAlert(
          "Parece que el  e-mail que intenta registrar no cumple con el formato 'micorreo@dominio.com', por favor verifique e intente de nuevo.",
          'Email inválido'
        );
        break;
      case 'auth/user-disabled':
        this.utilities.presentAlert(
          'Su cuenta está desactivada, contacte al soporte técnico de Conectimed.',
          'Cuenta desactivada'
        );
        break;
      case 'auth/too-many-requests':
        this.utilities.presentAlert(
          'Ha tenido muchos intentos fallidos de inicio de sesión, por seguridad su cuenta se ha bloqueado. Espere 15 minutos e intente de nuevo.',
          'Limite intentos superados'
        );
        break;
      default:
        this.utilities.presentAlert(
          'Ha ocurrido un error, revise la conexión a internet e intente de nuevo, contacte a soporte si el error persiste.',
          'Error al ingresar'
        );
        break;
    }
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      password: ['', [Validators.required, Validators.minLength(6)]],
      remember: true
    });
  }

  public noWhiteSpaces(ev: any, field: string) {
    const white = new RegExp(/\s/g);
    if (white.test(String(ev.target.value || '')) === true) {
      const newString = String(ev.target.value || '').replace(/\s/g, '');
      this.loginForm.get(field).setValue(newString);
    }
  }
}
