<ion-content [scrollEvents]="true">
  <ion-grid [fixed]="false">
    <ion-col size="12">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Cancelar Afiliación</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p>Antes de proceder con la cancelación de su afiliación, le invitamos a llenar una breve encuesta. Una vez
            completada, se iniciará automáticamente el proceso de cancelación. Haga clic en el botón 'Cancelar mi
            afiliación' para empezar.</p>
          <p>¡Gracias!</p>
          <ion-button (click)="deleteAccountAlert()" color="secondary" size="small">
            Cancelar mi afiliación
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-grid>
</ion-content>