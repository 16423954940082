import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@app/shared/shared.module';
import { AdvertisingModule } from '@app/shared/modules/advertising/advertising.module';
import { RouterModule } from '@angular/router';
import { ItemListComponent } from './item-list/item-list.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { IRefreshWebComponent } from './i-refresh-web/i-refresh-web.component';
import { VirtualSessionSuggestionsComponent } from './virtual-session-suggestions/virtual-session-suggestions.component';
import { UserSocialMediaOptionsComponent } from './user-social-media-options/user-social-media-options.component';
import { CarouselComponent } from './carousel/carousel.component';
@NgModule({
  exports: [
    SidebarComponent,
    ItemListComponent,
    NoResultsComponent,
    SkeletonComponent,
    VirtualSessionSuggestionsComponent,
    IRefreshWebComponent,
    UserSocialMediaOptionsComponent,
    CarouselComponent
  ],
  declarations: [
    SidebarComponent,
    ItemListComponent,
    NoResultsComponent,
    SkeletonComponent,
    VirtualSessionSuggestionsComponent,
    IRefreshWebComponent,
    UserSocialMediaOptionsComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    RouterModule,
    AdvertisingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContentSharedComponentsModule { }
