import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { SpecialitesComponent } from '@app/shared/components/specialites/specialites.component';
import { LocationComponent } from '@app/shared/components/location/location.component';
import { LocationHealthProfessionalComponent } from '@app/shared/components/location-health-professional/location-health-professional.component';
import { ProfessionalTypeComponent } from '@app/shared/components/professional-type/professional-type.component';
import { SearchCompanyComponent } from '@app/shared/search-company/search-company.component';
import { ModalController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
import { CollectionReference, Query, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
const log = new Logger('SEARCH');
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '@app/shared/services/search/search.service';

@Component({
  selector: 'app-red-conectimed',
  templateUrl: './red-conectimed.component.html',
  styleUrls: ['./red-conectimed.component.scss'],
})
export class RedConectimedComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { read: {}, static: true }) infiniteScroll: IonInfiniteScroll;
  filterData1: any[] = [];
  filterData2: any[] = [];
  filterData3: any[] = [];
  filter1: string = '';
  filter2: string = '';
  filter3: string = '';
  finalFilter: string = '';
  private mainCollection: string = 'users';
  public orderBy: string = 'nameStr';
  public orderByDirection: any = 'asc';
  private startAfter: any;
  private subscription: Subscription;
  public arrayDocs: any[] = [];
  public forward: boolean = false;
  private searchStr: string;
  searchMemberType: string = 'medico';
  isLoading: boolean = false;
  private perPage: number = 50;
  default_user = false;
  public viewType: 'modal' | 'component' | 'chat' = 'component';
  public grupdID: string;
  selectedUsers: any[] = [];
  nameEvent: string = 'select_user';
  firstCharacter: string;
  filter: any[] = [
    { title: 'A', filter: 'a', see: false },
    { title: 'B', filter: 'b', see: false },
    { title: 'C', filter: 'c', see: false },
    { title: 'D', filter: 'd', see: false },
    { title: 'E', filter: 'e', see: false },
    { title: 'F', filter: 'f', see: false },
    { title: 'G', filter: 'g', see: false },
    { title: 'H', filter: 'h', see: false },
    { title: 'I', filter: 'i', see: false },
    { title: 'J', filter: 'j', see: false },
    { title: 'K', filter: 'k', see: false },
    { title: 'L', filter: 'l', see: false },
    { title: 'M', filter: 'm', see: false },
    { title: 'N', filter: 'n', see: false },
    { title: 'Ñ', filter: 'ñ', see: false },
    { title: 'O', filter: 'o', see: false },
    { title: 'P', filter: 'p', see: false },
    { title: 'Q', filter: 'q', see: false },
    { title: 'R', filter: 'r', see: false },
    { title: 'S', filter: 's', see: false },
    { title: 'T', filter: 't', see: false },
    { title: 'U', filter: 'u', see: false },
    { title: 'V', filter: 'v', see: false },
    { title: 'W', filter: 'w', see: false },
    { title: 'X', filter: 'x', see: false },
    { title: 'Y', filter: 'y', see: false },
    { title: 'Z', filter: 'z', see: false }
  ];
  currentFilter: string = null;
  filterLoading: boolean = false;
  filter_type = {
    search: '',
    state: '',
    specialty: '',
    company: '',
    alphabetic: ''
  };
  newMembers: any;

  constructor(
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private modalCtrl: ModalController,
    private analyticsFB: AnalyticsService,
    private router: Router,
    private searchService: SearchService,
    private aRoute: ActivatedRoute
  ) { }

  async getAlphabet(type: string) {
    this.filterLoading = true;
    try {
      let filterArray: any[] = [];
      const resp = await this.fireService.afs
        .collection(`information/counters/alphabet/${type}/letters`)
        .ref.where('count', '>', 0)
        .get();
      filterArray = resp.docs;
      if (this.finalFilter !== '') {
        let array: any[] = [];
        for (const element of resp.docs) {
          const resp = await element.ref
            .collection('users')
            .where('filter-meta-data', 'array-contains', this.finalFilter)
            .get();
          if (!resp.empty) {
            array.push(element);
          }
        }
        filterArray = array;
      }
      const alphabet: any[] = filterArray.map(e => e.id);
      this.filter = this.filter.map(element => {
        let data: any = element;
        const index: number = alphabet.indexOf(element.filter);
        if (index > -1) {
          data.see = true;
        } else {
          data.see = false;
        }
        return data;
      });
    } catch (error) {
      log.error(error);
    }
    this.filterLoading = false;
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      this.searchStr = this.searchService.decodeString(params && params.search ? params.search : undefined);
      if (this.searchStr) {
        this.search(this.searchStr);
      } else {
        this.loadData();
      }
    });
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  resetSearch() {
    this.arrayDocs = [];
    this.filterData1 = [];
    this.filterData2 = [];
    this.filterData3 = [];
    this.filter1 = '';
    this.filter2 = '';
    this.filter3 = '';
    this.finalFilter = '';
  }

  async removeEspecialtiesFilter(loadData?: boolean) {
    this.analyticsClickEvent('remove_filter_specialties', {});
    this.isLoading = true;
    this.filterData2 = [];
    const search: string = this.filterData2.map(e => String(e.id))[0];
    this.filter2 = search ? search : '';
    this.filter_type.specialty = this.filter2;
    this.finalFilter = this.filter1 + this.filter2;
    if (!(loadData === false)) {
      this.arrayDocs = [];
      await this.loadData();
    }
    this.isLoading = false;
  }

  async removeStatesFilter(loadData?: boolean) {
    this.analyticsClickEvent('remove_filter_states', {});
    this.isLoading = true;
    this.filterData1 = [];
    const search: string = this.filterData1.map(e => String(e.id))[0];
    this.filter1 = search ? search : '';
    this.filter_type.state = this.filter1;
    this.finalFilter = this.filter1 + this.filter2;
    if (!(loadData === false)) {
      this.arrayDocs = [];
      await this.loadData();
    }
    this.isLoading = false;
  }

  specialtiesFilter() {
    if (this.credService.credentials.type === 'profesional-de-la-salud') {
      this.specialtiesHealthProfessionalsFilterHandler();
    } else {
      this.specialtiesFilterHandler();
    }
  }

  async specialtiesFilterHandler() {
    this.analyticsClickEvent('filter_specialties', {});
    const finalFilter = this.filter1 + '';
    const modal = await this.modalCtrl.create({
      component: SpecialitesComponent,
      componentProps: { arrayData: this.filterData2, filter: finalFilter }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        if (data && data.role && data.role === 'data') {
          this.filterData2 = Array.from(data.data.arrayData);
          const search: string = this.filterData2.map(e => String(e.id))[0];
          this.filter2 = search ? search : '';
          this.filter_type.specialty = this.filter2;
          this.finalFilter = this.filter1 + this.filter2;
          if (this.finalFilter === '') {
            this.arrayDocs = [];
          } else {
            this.arrayDocs = [];
            await this.loadData();
          }
        } else if (data && data.role && data.role === 'clear') {
          if (this.finalFilter && this.finalFilter !== '') {
            await this.removeEspecialtiesFilter(true);
          }
        } else {
          log.debug('specialtiesFilter closed');
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    modal.present();
  }

  async specialtiesHealthProfessionalsFilterHandler() {
    this.analyticsClickEvent('filter_specialties', {});
    const finalFilter = this.filter1 + '';
    const modal = await this.modalCtrl.create({
      component: ProfessionalTypeComponent,
      componentProps: { arrayData: this.filterData2, filter: finalFilter }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        if (data && data.role && data.role === 'data') {
          this.filterData2 = Array.from(data.data.arrayData);
          const search: string = this.filterData2.map(e => String(e.id))[0];
          this.filter2 = search ? search : '';
          this.filter_type.specialty = this.filter2;
          this.finalFilter = this.filter1 + this.filter2;
          if (this.finalFilter === '') {
            this.arrayDocs = [];
          } else {
            this.arrayDocs = [];
            await this.loadData();
          }
        } else if (data && data.role && data.role === 'clear') {
          if (this.finalFilter && this.finalFilter !== '') {
            await this.removeEspecialtiesFilter(true);
          }
        } else {
          log.debug('specialtiesFilter closed');
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    modal.present();
  }

  statesFilter() {
    if (this.credService.credentials.type === 'profesional-de-la-salud') {
      this.statesHealthProfessionalsFilterHandler();
    } else {
      this.statesFilterHandler();
    }
  }

  async statesFilterHandler() {
    this.analyticsClickEvent('filter_states', {});
    const finalFilter = '' + this.filter2;
    const modal = await this.modalCtrl.create({
      component: LocationComponent,
      componentProps: { arrayData: this.filterData1, filter: finalFilter }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        if (data && data.role && data.role === 'data') {
          this.filterData1 = Array.from(data.data.arrayData);
          const search: string = this.filterData1.map(e => String(e.id))[0];
          this.filter1 = search ? search : '';
          this.filter_type.state = this.filter1;
          this.finalFilter = this.filter1 + this.filter2;
          if (this.finalFilter === '') {
            this.arrayDocs = [];
          } else {
            this.arrayDocs = [];
            await this.loadData();
          }
        } else if (data && data.role && data.role === 'clear') {
          if (this.finalFilter && this.finalFilter !== '') {
            await this.removeStatesFilter(true);
          }
        } else {
          log.debug('statesFilter closed');
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    modal.present();
  }

  async statesHealthProfessionalsFilterHandler() {
    this.analyticsClickEvent('filter_states', {});
    const finalFilter = '' + this.filter2;
    const modal = await this.modalCtrl.create({
      component: LocationHealthProfessionalComponent,
      componentProps: { arrayData: this.filterData1, filter: finalFilter }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        if (data && data.role && data.role === 'data') {
          this.filterData1 = Array.from(data.data.arrayData);
          const search: string = this.filterData1.map(e => String(e.id))[0];
          this.filter1 = search ? search : '';
          this.filter_type.state = this.filter1;
          this.finalFilter = this.filter1 + this.filter2;
          if (this.finalFilter === '') {
            this.arrayDocs = [];
          } else {
            this.arrayDocs = [];
            await this.loadData();
          }
        } else if (data && data.role && data.role === 'clear') {
          if (this.finalFilter && this.finalFilter !== '') {
            await this.removeStatesFilter(true);
          }
        } else {
          log.debug('statesFilter closed');
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    modal.present();
  }

  goChat(id: any) {
    if (this.viewType === 'chat') {
      this.modalCtrl.dismiss({ users: [id] });
    }
  }

  async doSearch(ev: any) {
    try {
      this.search(ev.target.value);
    } catch (error) {
      log.error(error);
    }
  }

  ionViewDidLeave() {
    this.closeSubscriptions();
  }

  closeSubscriptions() {
    try {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
  }

  async search(search: string) {
    this.isLoading = true;
    const searchStr: string = this.searchService.clearSearch(decodeURI(search), false);
    this.nameEvent = 'view_search_results';
    this.analyticsClickEvent('search', {
      search_term: searchStr
    });
    if (searchStr.length >= 3) {
      try {
        this.removeEspecialtiesFilter(false);
        this.removeStatesFilter(false);
        this.searchStr = searchStr;
        this.filter_type.search = this.searchStr;
        await this.initData();
      } catch (error) {
        log.error(error);
      }
    } else {
      this.filter_type.search = '';
    }
    this.isLoading = false;
  }

  async loadData() {
    this.searchStr = '';
    this.isLoading = true;
    try {
      await this.initData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async nextPage() {
    this.searchStr = '';
    this.isLoading = true;
    try {
      await this.initData(true);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  clearSearch() {
    this.searchStr = '';
    this.filter_type.search = '';
    this.arrayDocs = [];
  }

  async filterList(filter: string) {
    this.analyticsClickEvent('search', {
      search_term: filter,
      search_user_type: this.searchMemberType
    });
    this.searchStr = '';
    if (filter === this.currentFilter) {
      await setTimeout(() => {
        this.currentFilter = null;
        this.firstCharacter = undefined;
      }, 50);
      this.currentFilter = null;
      this.firstCharacter = undefined;
    } else {
      this.firstCharacter = filter;
      this.currentFilter = filter;
    }
    this.filter_type.alphabetic = this.firstCharacter;
    this.isLoading = true;
    try {
      await this.initData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async initData(next?: boolean) {
    try {
      this.getAlphabet(this.searchMemberType);
      let collRef: CollectionReference = this.fireService.afs.collection(this.mainCollection).ref;
      let query: Query;
      query = collRef.where('status', '==', 'active').orderBy(this.orderBy, this.orderByDirection);
      if (this.searchMemberType) {
        query = query.where('type', '==', this.searchMemberType);
      }
      // if (this.credService.credentials.type === 'profesional-de-la-salud' && this.searchMemberType == 'representante-medico') {
      //   query = query.where('openToHealthProfessionals', '==', true);
      // }
      if (this.firstCharacter) {
        query = query.where('firstCharacter', '==', this.firstCharacter);
      }
      if (this.searchStr) {
        query = query.where('search', 'array-contains', this.searchStr);
      }
      if (this.finalFilter !== '') {
        query = query.where('filter-meta-data', 'array-contains', this.finalFilter);
      }
      if (this.startAfter && next === true) {
        query = query.startAfter(this.startAfter);
      }
      const snap = await query.limit(this.perPage).get();
      this.startAfter = snap.empty == false ? snap.docs[snap.docs.length - 1] : undefined;
      if (this.startAfter) {
        query = collRef.where('status', '==', 'active').orderBy(this.orderBy, this.orderByDirection);
        if (this.searchMemberType) {
          query = query.where('type', '==', this.searchMemberType);
        }
        if (this.firstCharacter) {
          query = query.where('firstCharacter', '==', this.firstCharacter);
        }
        if (this.searchStr) {
          query = query.where('search', 'array-contains', this.searchStr);
        }
        if (this.finalFilter !== '') {
          query = query.where('filter-meta-data', 'array-contains', this.finalFilter);
        }
        if (this.startAfter) {
          query = query.startAfter(this.startAfter);
        }
        const snap2 = await query.limit(this.perPage).get();
        this.forward = !snap2.empty;
      } else {
        this.forward = false;
      }
      let myArrayData: any[] = [];
      myArrayData = snap.docs.map(element => {
        const data: any = element.data();
        const id: string = element.id;
        if (data.type === 'medico') {
          data.metaData = this.fireService.getDoctorData(id);
        }
        else if (data.type === 'profesional-de-la-salud') {
          data.metaData = this.fireService.getProfessionalHealthData(id);
        }
        else {
          data.metaData = this.fireService.getRepData(id);
        }
        //data.isFriend = this.fireService.relatedLikeFriend(id);
        return { id, ...data };
      });
      const index: number = myArrayData.map(e => e.id).indexOf(this.credService.credentials.uid);
      if (index > -1) {
        myArrayData.splice(index, 1);
      }
      myArrayData = myArrayData.map(element => {
        const mapped = this.selectedUsers.map(e => e.id);
        const index = mapped.indexOf(element.id);
        if (index > -1) {
          element.isChecked = true;
        }
        return element;
      });
      if (!(next === true)) {
        this.arrayDocs = [];
      }
      myArrayData = myArrayData.map(element => {
        let data = element;
        var str: string = String(
          element && element.avatar && element.avatar.list && element.avatar.list.url ? element.avatar.list.url : ''
        );
        var n = str.search('www.gravatar.com');
        if (n > -1) {
          element.avatar = undefined;
        }
        return data;
      });
      myArrayData = myArrayData.filter(element => {
        if (element && element.type && (element.type === 'medico' || element.type === 'representante-medico' || element.type === 'profesional-de-la-salud')) {
          return true;
        } else {
          return false;
        }
      });
      this.arrayDocs = this.arrayDocs.concat(myArrayData);
      return true;
    } catch (error) {
      log.error(error);
      return false
    }
  }

  goChatGroup() {
    this.modalCtrl.dismiss({ users: this.selectedUsers });
  }

  clear() {
    this.selectedUsers = [];
  }

  selectUser(item: any) {
    const index: number = this.selectedUsers.map(e => e.id).indexOf(item.id);
    if (index > -1) {
      this.selectedUsers.splice(index, 1);
    } else {
      this.selectedUsers.push(item);
    }
  }

  async changeMemberType(type: string, force?: boolean) {
    if (!(this.searchMemberType === type)) {
      this.searchMemberType = type;
      this.isLoading = true;
      try {
        await this.removeEspecialtiesFilter(false);
        await this.removeStatesFilter(false);
        await this.loadData();
      } catch (error) {
        log.error(error);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.resetSearch();
      if (force === true) {
        await this.loadData();
      }
    }
  }

  async companiesFilter() {
    this.analyticsClickEvent('filter_companies', {});
    const modal = await this.modalCtrl.create({
      component: SearchCompanyComponent,
      componentProps: { arrayData: this.filterData3, currentFilter: this.finalFilter }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        if (data && data.role && data.role === 'data') {
          this.filterData3 = Array.from(data.data.arrayData);
          const search: string = this.filterData3.map(e => String(e.id))[0];
          this.filter3 = search ? search : '';
          this.filter_type.company = this.filter3;
          this.finalFilter = this.filter3;
          if (this.finalFilter === '') {
            this.arrayDocs = [];
          } else {
            this.arrayDocs = [];
            await this.loadData();
          }
        } else if (data && data.role && data.role === 'clear') {
          if (this.finalFilter && this.finalFilter !== '') {
            await this.removeCompaniesFilter(true);
          }
        } else {
          log.debug('companiesFilter closed');
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    modal.present();
  }

  async removeCompaniesFilter(loadData?: boolean) {
    this.analyticsClickEvent('remove_filter_companies', {});
    this.isLoading = true;
    this.filterData3 = [];
    const search: string = this.filterData3.map(e => String(e.id))[0];
    this.filter3 = search ? search : '';
    this.filter_type.company = this.filter3;
    this.finalFilter = this.filter3;
    if (!(loadData === false)) {
      this.arrayDocs = [];
      await this.loadData();
    }
    this.isLoading = false;
  }
}
