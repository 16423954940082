<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> Mensaje </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding" *ngIf="isLoad === true">
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="dataForm" (ngSubmit)="send()">
          <textarea formControlName="msg" style="visibility: hidden; height: 0;"></textarea>
          <rich-text
            [elementId]="'my-editor-chat-group'"
            (onEditorContentChange)="keyupHandler($event)"
            [contentHtml]="htmlContent"
            [resetEditor]="resetEditor"
          ></rich-text>
          <div *ngIf="userDefault === true">
            <ion-item>
              <ion-label>¿Enviar etiqueta de analytics?</ion-label>
              <ion-checkbox slot="end" formControlName="sendAnalytics" (ionChange)="sendAnalytics($event)">
              </ion-checkbox>
            </ion-item>
            <ion-item *ngIf="dataForm?.value?.sendAnalytics === true">
              <ion-label>Etiqueta de analytics</ion-label>
              <ion-input
                type="text"
                formControlName="analyticsLabel"
                placeholder="Nombre de etiqueta"
                class="ion-text-end"
              >
              </ion-input>
            </ion-item>
          </div>
          <ion-list>
            <ion-item *ngFor="let item of files; let i = index">
              <div [innerHTML]="item"></div>
              <ion-button slot="end" fill="clear" (click)="deleteItem(i)">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-list>
          <ion-button
            type="submit"
            color="tertiary"
            expand="block"
            [disabled]="!(dataForm.valid === true || files[0])"
            (click)="analyticsClickEvent('send_message', {})"
          >
            Enviar
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
  <input
    id="file1"
    class="hide"
    #file1
    name="file1"
    accept="image/*"
    type="file"
    name="file"
    (change)="selectFile1($event.target)"
  />

  <input
    id="file2"
    class="hide"
    #file2
    name="file2"
    type="file"
    name="file"
    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
    (change)="selectFile2($event.target)"
  />

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="tertiary" [disabled]="isLoading == true">
      <ion-icon name="attach-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button (click)="imageFromGallery(); analyticsClickEvent('upload_image', {})" title="Imagen">
        <ion-icon name="images-outline"></ion-icon>
      </ion-fab-button>
      <ion-label>Imagen</ion-label>
      <ion-fab-button (click)="takePicture(); analyticsClickEvent('upload_photo', {})" title="Cámara">
        <ion-icon name="camera-outline"></ion-icon>
      </ion-fab-button>
      <ion-label>Cámara</ion-label>
      <ion-fab-button (click)="triggerClick2(); analyticsClickEvent('upload_doc', {})" title="Documento">
        <ion-icon name="document-outline"></ion-icon>
      </ion-fab-button>
      <ion-label>Doc</ion-label>
    </ion-fab-list>
  </ion-fab>
</ion-content>
