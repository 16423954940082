import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeleteAccountComponent } from './delete-account.component';

const routes: Routes = [
  {
    path: '',
    component: DeleteAccountComponent,
    data: {
      title: 'Cancelar Afiliación',
      icon: {
        name: "person-remove-outline"
      },
      root: "/delete-account",
      menuItem: '/delete-account'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DeleteAccountRoutingModule { }
