<div>
  <div class="ion-hide-lg-down">
    <ion-card class="transparent">
      <div class="limit-add">
        <app-advertising *ngIf="homeAdvertisings.length !== 0 && showAdds" [advertising]="homeAdvertisings[addCounter]"
          [inHome]="true" (closeAdd)="showAdds = false"></app-advertising>
      </div>
    </ion-card>
    <app-virtual-session-suggestions [futureSessions]="futureSessions"></app-virtual-session-suggestions>
  </div>
  <div class="ion-hide-lg-up ion-margin">
    <ion-card class="transparent">
      <div class="limit-add">
        <app-advertising *ngIf="homeAdvertisings.length !== 0 && showAdds" [advertising]="homeAdvertisings[addCounter]"
          [inHome]="true" (closeAdd)="showAdds = false"></app-advertising>
      </div>
    </ion-card>
  </div>
</div>