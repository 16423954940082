import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChatModule } from './chat/chat.module';
import { CardsModule } from './cards/cards.module';
import { UserDetailViewModule } from './user-detail-view/user-detail-view.module';
import { LoaderComponent } from './loader/loader.component';
import { AccountDataFormComponent } from './forms/account-data-form/account-data-form.component';
import { PersonalDataFormComponent } from './forms/personal-data-form/personal-data-form.component';
import { ProfessionalDataFormComponent } from './forms/professional-data-form/professional-data-form.component';
import { AddressFormComponent } from './forms/address-form/address-form.component';
import { CompanyDataFormComponent } from './forms/company-data-form/company-data-form.component';
import { DoctorFormComponent } from './forms/doctor-form/doctor-form.component';
import { AddLocationComponent } from './forms/add-location/add-location.component';
import { AddressesComponent } from './forms/addresses/addresses.component';
import { ProfileImageComponent } from './forms/profile-image/profile-image.component';
import { PagesComponent } from './components/pages/pages.component';
import { PullToRefreshModule } from './components/pull-to-refresh/pull-to-refresh.module';
import { FormTermOfUseComponent } from './forms/account-data-form/form-term-of-use/form-term-of-use.component';
import { LightboxModule } from './components/lightbox/lightbox.module';
import { SearchCompanyModule } from './search-company/search-company.module';
import { MyContactsModule } from './my-contacts/my-contacts.module';
import { SpecialitesComponent } from './components/specialites/specialites.component';
import { LocationComponent } from './components/location/location.component';
import { LocationHealthProfessionalComponent } from './components/location-health-professional/location-health-professional.component';
import { ProfessionalTypeComponent } from './components/professional-type/professional-type.component';
import { UploadfilesComponent } from './uploadfiles/uploadfiles.component';
import { ReportcommentComponent } from './reportcomment/reportcomment.component';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { PopoverCommentPostComponent } from './popover-comment-post/popover-comment-post.component';
import { CopyPasteDirective } from './directives/copy-paste.directive';
import { SharedFilesPopOverComponent } from './components/shared-files-pop-over/shared-files-pop-over.component';
import { ImgModalComponent } from './components/img-modal/img-modal.component';
import { InfoModalImageComponent } from './components/info-modal-image/info-modal-image.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { UserSelectorModule } from './components/user-selector/user-selector.module';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ProfessionalHealthDataFormComponent } from './forms/professional-health-data-form/professional-health-data-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ChatModule,
    CardsModule,
    UserDetailViewModule,
    PullToRefreshModule,
    LightboxModule,
    SearchCompanyModule,
    MyContactsModule,
    MomentModule,
    NgxPaginationModule,
    UserSelectorModule,
  ],
  declarations: [
    LoaderComponent,
    AccountDataFormComponent,
    PersonalDataFormComponent,
    ProfessionalDataFormComponent,
    ProfessionalHealthDataFormComponent,
    AddressFormComponent,
    CompanyDataFormComponent,
    AddressesComponent,
    ProfileImageComponent,
    PagesComponent,
    FormTermOfUseComponent,
    SpecialitesComponent,
    LocationComponent,
    LocationHealthProfessionalComponent,
    ProfessionalTypeComponent,
    DoctorFormComponent,
    AddLocationComponent,
    UploadfilesComponent,
    ReportcommentComponent,
    PopoverCommentPostComponent,
    CopyPasteDirective,
    SharedFilesPopOverComponent,
    ImgModalComponent,
    InfoModalImageComponent,
    NotificationsListComponent  
  ],
  exports: [
    LoaderComponent,
    AccountDataFormComponent,
    PersonalDataFormComponent,
    ProfessionalDataFormComponent,
    ProfessionalHealthDataFormComponent,
    AddressFormComponent,
    CompanyDataFormComponent,
    AddressesComponent,
    ProfileImageComponent,
    PagesComponent,
    FormTermOfUseComponent,
    SpecialitesComponent,
    LocationComponent,
    LocationHealthProfessionalComponent,
    ProfessionalTypeComponent,
    DoctorFormComponent,
    AddLocationComponent,
    UploadfilesComponent,
    ReportcommentComponent,
    MomentModule,
    NgxPaginationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
