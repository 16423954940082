import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VerificationCodeComponent } from './verification-code.component';

const routes: Routes = [
  { path: 'verification-code', component: VerificationCodeComponent, data: { title: 'Verification Code' } },
  {
    path: 'verification-code/:email',
    component: VerificationCodeComponent,
    data: { title: 'Verification Code' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class VerificationCodeRoutingModule {}
