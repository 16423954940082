<ion-grid [fixed]="false" *ngIf="outstanding === true" class="ion-text-center">
  <ion-row class="ion-align-items-center ion-justify-content-center">
    <ion-col size="auto" class="ion-text-center">
      <ion-thumbnail class="outstanding" [routerLink]="[company?.routing?.route]" (click)="analyticsClickEvent('view_company', {
        section_name: 'Company',
        company_name: this.company.name
      })">
        <img *ngIf="company?.logo" [src]="company?.logo" />
        <svg *ngIf="!company?.logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          xml:space="preserve" style="
        shape-rendering: geometricPrecision;
        text-rendering: geometricPrecision;
        image-rendering: optimizeQuality;
        fill-rule: evenodd;
        clip-rule: evenodd;
      " viewBox="0 0 500 500">
          <g id="UrTavla">
            <circle style="fill: #d9d9d9" cx="250" cy="250" r="245"></circle>
            <text x="50%" y="50%" text-anchor="middle" dy=".3em" style="font-size: 180px; font-family: Arial">
              {{ company?.nameLogo }}
            </text>
          </g>
        </svg>
      </ion-thumbnail>
    </ion-col>
    <ion-col size="auto" class="ion-text-center">
      <div>
        <ion-text color="dark">
          <h2> {{ company?.name }}</h2>
          <p>{{ company?.businessType }}</p>
        </ion-text>
      </div>
      <ion-button (click)="navToPage()" *ngIf="company?.companyWebsite" shape="round">
        <ion-icon slot="end" name="globe-outline"></ion-icon>
        Página Web
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-item lines="none" *ngIf="outstanding !== true">
  <ion-avatar slot="start" [routerLink]="[company?.routing?.route]" (click)="analyticsClickEvent('view_company', {
    section_name: 'Company',
    company_name: this.company.name
  })">
    <img *ngIf="company?.logo" [src]="company?.logo" />
    <svg *ngIf="!company?.logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve" style="
        shape-rendering: geometricPrecision;
        text-rendering: geometricPrecision;
        image-rendering: optimizeQuality;
        fill-rule: evenodd;
        clip-rule: evenodd;
      " viewBox="0 0 500 500">
      <g id="UrTavla">
        <circle style="fill: #d9d9d9" cx="250" cy="250" r="245"></circle>
        <text x="50%" y="50%" text-anchor="middle" dy=".3em" style="font-size: 180px; font-family: Arial">
          {{ company?.nameLogo }}
        </text>
      </g>
    </svg>
  </ion-avatar>
  <ion-label>
    <h2> {{ company?.name }}</h2>
    <p>{{ company?.businessType }}</p>
  </ion-label>
  <ion-buttons slot="end">
    <ion-button (click)="navToPage()" *ngIf="company?.companyWebsite" fill="solid" shape="round" color="primary">
      <ion-icon slot="end" name="globe-outline"></ion-icon>
      Página Web
    </ion-button>
  </ion-buttons>
</ion-item>