import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { CameraSource } from '@capacitor/camera';
import { Device } from '@capacitor/device';
import { FileTranferService } from '../../services/file-transfer/file-transfer.service';
import { FilesService } from '../../services/files/files.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('Add Company');

export interface Company {
  uid: string;
  name: string;
  businessType: string;
  companyWebsite: string;
  logo: string | File;
  description: string;
}

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
})
export class AddCompanyComponent implements OnInit {
  public isLoading: boolean;
  public company: Company = {
    name: '',
    logo: null,
    description: null,
    businessType: '',
    companyWebsite: '',
    uid: null
  };
  checkCompany: any = {
    name: {
      message: null,
      ok: false
    },
    logo: {
      message: null,
      ok: false
    },
    businessType: {
      message: null,
      ok: false
    },
    companyWebsite: {
      message: null,
      ok: true
    }
  };
  isValidCompany: boolean = false;
  logo: any;
  // private companyData: any[];

  constructor(
    private loader: IonLoaderService,
    private fireService: FirebaseService,
    private modalCtrl: ModalController,
    private utilities: UtilitiesService,
    private toastCtrl: ToastController,
    private analyticsFB: AnalyticsService,
    public fileTransfer: FileTranferService,
    private filesService: FilesService,
    private sanitizer: DomSanitizer,
    public credService: CredentialsService,
    private router: Router
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async presentAlertConfirm() {
    const alert = await this.toastCtrl.create({
      header: 'Error al crear empresa',
      message: 'La empresa ya ha sido creada anteriormente, para continuar, usa el buscador y selecciona esta empresa.',
      buttons: [
        {
          text: 'ok'
        }
      ]
    });

    alert.present();
  }

  ngOnInit() { }

  async createCompany() {
    this.fullCheck();
    if (!this.isValidCompany) {
      return;
    }

    // Analytics
    this.analyticsClickEvent('add_company', {
      content_type: 'Información de company',
      section_name: 'Company',
      business_name: this.company.name,
      business_type: this.company.businessType,
      click_text: 'Crear Empresa'
    });

    // Task
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const company: any = { ...this.company };
      company.nameStr = '';
      company.status = 'active';
      const response = await this.fireService.createCompany(company);
      if (response && response.id) {
        this.closeModal(response);
      } else {
        this.presentAlertConfirm();
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  closeModal(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  loadImg(data: any) {
    const files = data.files;
    if (files.length !== 0) {
      this.company.logo = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.logo = reader.result;
      };
    }
    this.check('logo');
  }

  check(key: 'name' | 'logo' | 'businessType' | 'companyWebsite') {
    if (key === 'name') {
      if (this.company.name.length < 3) {
        this.checkCompany.name.message = 'El nombre debe contener al menos 3 caracteres';
        this.checkCompany.name.ok = false;
      } else {
        this.checkCompany.name.message = null;
        this.checkCompany.name.ok = true;
      }
    } else if (key === 'businessType') {
      if (this.company.businessType === '' || !this.company.businessType) {
        this.checkCompany.businessType.message = 'Debe contener un giro de empresa';
        this.checkCompany.businessType.ok = false;
      } else {
        this.checkCompany.businessType.message = null;
        this.checkCompany.businessType.ok = true;
      }
    } else if (key === 'logo') {
      if (!this.company.logo) {
        this.checkCompany.logo.message = 'Debe contener un logo la empresa';
        this.checkCompany.logo.ok = false;
      } else {
        this.checkCompany.logo.message = null;
        this.checkCompany.logo.ok = true;
      }
    } else if (key === 'companyWebsite') {
      if (!this.company.companyWebsite || this.company.companyWebsite === '') {
        this.checkCompany.companyWebsite.message = null;
        this.checkCompany.companyWebsite.ok = true;
      } else {
        if (this.utilities.validateUrl(this.company.companyWebsite)) {
          this.checkCompany.companyWebsite.message = null;
          this.checkCompany.companyWebsite.ok = true;
        } else {
          if (this.utilities.validateUrl('http://' + this.company.companyWebsite)) {
            this.company.companyWebsite = 'http://' + this.company.companyWebsite;
            this.checkCompany.companyWebsite.message = null;
            this.checkCompany.companyWebsite.ok = true;
          } else {
            this.checkCompany.companyWebsite.message = `No es una pagina valida`;
            this.checkCompany.companyWebsite.ok = false;
          }
        }
      }
    }
    this.isValid();
  }

  fullCheck() {
    for (const key in this.checkCompany) {
      this.check(key as any);
    }
  }

  isValid() {
    let valid = true;
    for (const key in this.checkCompany) {
      if (!this.checkCompany[key].ok) {
        valid = false;
      }
    }
    this.isValidCompany = valid;
  }

  async getImage() {
    const info = await Device.getInfo();
    if (info && (info.platform === 'ios' || info.platform === 'android')) {
      this.iosAndAndroidImage();
    } else {
      const element = await document.getElementById('imgInput');
      element.click();
    }
  }

  async iosAndAndroidImage() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response = await this.filesService.takePicture(CameraSource.Photos, '', 'preview');
      if (response && response.main && response.main.path) {
        this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(response.main.path);
        this.company.logo = response.main.path;
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    this.check('logo');
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }
}
