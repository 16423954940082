import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public image: Subject<string> = new Subject<string>();

  constructor() {}

  public update(image: string) {
    this.image.next(image);
  }
}