import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() futureSessions: boolean;
  public homeAdvertisings: any[] = [];
  onHome: boolean = true;
  public showAdds: boolean = true;
  addCounter: number = 0;
  
  constructor(
    private fireService: FirebaseService,
    public router: Router
  ) { }

  ngOnInit() {
    this.routerAdvertising();
   }

  async routerAdvertising() {
    const RESP = await this.fireService.afs
      .collection('advertising')
      .ref.orderBy('order', 'asc')
      .get();
    this.homeAdvertisings = RESP.docs.map(element => {
      const DATA: any = element.data();
      const ID: string = element.id;
      return { id: ID, ...DATA };
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === '/home' && !this.onHome) {
          if (this.homeAdvertisings.length !== 0) {
            this.showAdds = true;
            this.addCounter = this.addCounter + 1;
            if (this.addCounter >= this.homeAdvertisings.length) {
              this.addCounter = 0;
            }
          }
        }
        if (this.router.url !== '/home') {
          this.onHome = false;
        } else {
          this.onHome = true;
        }
      }
    });
  }
}
