import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@app/core/logger.service';
import { MultimediaRoutesService } from '@app/multimedia/services/multimedia-routes.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
const log = new Logger('AlgorithmsComponent');

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  @Input() ref: string;
  public items: any[] = [];
  config = {
    autoplay: true,
    loop: true,
  }
  constructor(
    private fireService: FirebaseService,
    private conectimedRoutes: MultimediaRoutesService
  ) { }

  ngOnInit() {
    this.carousel();
  }

  async carousel() {
    try {
      let resp: any;
      if (this.ref) {
        resp = await this.fireService.afs.collection('posts').ref
          .where('is_outstanding', '==', true)
          .where('category', '==', this.fireService.afs.doc(this.ref).ref)
          .where('status', '==', 'active')
          .orderBy('publication_date', 'desc')
          .get();
      } else {
        resp = await this.fireService.afs.collection('posts').ref
          .where('is_outstanding', '==', true)
          .where('status', '==', 'active')
          .orderBy('publication_date', 'desc')
          .get();
      }
      this.items = resp.docs.map((item: any) => {
        let data: any = item.data();
        const id: string = item.id;
        data.routing = this.conectimedRoutes.mapRoute(data.category, id, data.title) || {};
        return { id, ...data }
      });
    } catch (error) {
      log.error(error);
    }
  }
}
