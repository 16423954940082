<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center">
      Agregar empresa
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col>
        <!-- Card Form -->
        <ion-card color="light">
          <h3>
            Nombre de la empresa
            <span style="color: red;"> * </span>
            <span style="color: red; font-size: 12px;" *ngIf="!checkCompany.name.ok && checkCompany.name.message">
              {{ checkCompany.name.message }}
            </span>
          </h3>
          <ion-item>
            <ion-input [(ngModel)]="company.name" (ionChange)="check('name')"></ion-input>
          </ion-item>

          <h3>
            Giro de la empresa
            <span style="color: red;"> * </span>
            <span
              style="color: red; font-size: 12px;"
              *ngIf="!checkCompany.businessType.ok && checkCompany.businessType.message"
            >
              {{ checkCompany.businessType.message }}
            </span>
          </h3>
          <ion-item>
            <ion-input [(ngModel)]="company.businessType" (ionChange)="check('businessType')"></ion-input>
          </ion-item>

          <h3>
            Pagina web de la empresa
            <span
              style="color: red; font-size: 12px;"
              *ngIf="!checkCompany.companyWebsite.ok && checkCompany.companyWebsite.message"
            >
              {{ checkCompany.companyWebsite.message }}
            </span>
          </h3>
          <ion-item>
            <ion-input [(ngModel)]="company.companyWebsite" (ionChange)="check('companyWebsite')"></ion-input>
          </ion-item>

          <h3>Descripción de la empresa</h3>
          <ion-item>
            <ion-textarea [autoGrow]="true" [rows]="2" [(ngModel)]="company.description"></ion-textarea>
          </ion-item>

          <input
            #imgInput
            id="imgInput"
            type="file"
            accept="image/*"
            class="ion-hide"
            [multiple]="false"
            (change)="loadImg(imgInput)"
          />
          <h3>
            Logo de la empresa
            <span style="color: red;"> * </span>
            <span style="color: red; font-size: 12px;" *ngIf="!checkCompany.logo.ok && checkCompany.logo.message">
              {{ checkCompany.logo.message }}
            </span>
          </h3>
          <div (click)="getImage()" style="border: 1px dashed black;">
            <img *ngIf="logo" [src]="logo" class="logo-img" />
            <img *ngIf="!logo" src="assets/img/no-image.jpg" class="logo-img" />
          </div>

          <br />
          <div class="separador-h"></div>
          <h5 style="margin-left: 10px; margin-top: 10px;">
            Los campos marcados con
            <span style="color: red;"> * </span>
            son requeridos
          </h5>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-button (click)="createCompany()" expand="block" [disabled]="!isValidCompany || isLoading">
    Crear Empresa
  </ion-button>
</ion-footer>
