<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> Registro de producto / servicio</ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button
        (click)="
          beforeCloseModal();
          analyticsClickEvent('click', {
            content_type: 'Cerrar registro de productos',
            section_name: 'Productos'
          })
        "
      >
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <h2 ion-text class="ion-text-center">
          <b>{{ companyName }}</b>
        </h2>
        <form #form [formGroup]="dataForm" (ngSubmit)="save()">
          <ion-item>
            <ion-input
              [label]="dataForm?.value?.type === 'Servicio' ? 'Tipo de Servicio' : 'Marca' + ' *'"
              type="text"
              formControlName="marca"
              [placeholder]="dataForm?.value?.type === 'Servicio' ? 'Tipo de Servicio' : 'Nombre comercial'"
            >
            </ion-input>
          </ion-item>
          <ion-item>
            <ion-select label="Tipo de producto/servicio *" formControlName="type" (ionChange)="changePlaceHolder($event)">
              <ion-select-option value="Medicamento">Medicamento</ion-select-option>
              <ion-select-option value="Dispositivo">Dispositivo</ion-select-option>
              <ion-select-option value="Servicio">Servicio</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-list *ngIf="dataForm?.value?.type === 'Medicamento'">
            <ion-item lines="none">
              <ion-label position="stacked">Sustancia activa </ion-label>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="sustancia1" placeholder="Sustancia activa principal *">
              </ion-input>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="sustancia2" placeholder="Sustancia activa secundaria"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-label position="stacked">Línea terapéutica </ion-label>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="linea1" placeholder="Línea terapéutica 1 *"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="linea2" placeholder="Línea terapéutica 2"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-label position="stacked">Presentación </ion-label>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="presentacion1" placeholder="Presentación 1 *"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="presentacion2" placeholder="Presentación 2"></ion-input>
            </ion-item>
          </ion-list>

          <ion-list *ngIf="dataForm?.value?.type === 'Dispositivo'">
            <ion-item>
              <ion-input type="text" formControlName="sustancia1" placeholder="Producto *"> </ion-input>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="sustancia2" placeholder="Uso *"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-label position="stacked">Línea terapéutica </ion-label>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="linea1" placeholder="Línea terapéutica 1 *"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="linea2" placeholder="Línea terapéutica 2"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-label position="stacked">Presentación </ion-label>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="presentacion1" placeholder="Presentación 1 *"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input type="text" formControlName="presentacion2" placeholder="Presentación 2"></ion-input>
            </ion-item>
          </ion-list>

          <ion-list *ngIf="dataForm?.value?.type === 'Servicio'">
            <ion-item>
              <ion-textarea
                rows="3"
                formControlName="sustancia1"
                placeholder="Descripción del servicio 1 *"
              ></ion-textarea>
            </ion-item>
            <ion-item>
              <ion-textarea
                rows="3"
                formControlName="sustancia2"
                placeholder="Descripción del servicio 2"
              ></ion-textarea>
            </ion-item>
            <ion-item>
              <ion-textarea rows="3" formControlName="linea1" placeholder="Descripción del servicio 3"></ion-textarea>
            </ion-item>
          </ion-list>

          <ion-item *ngIf="item?.allowMedicalSample === true && dataForm?.value?.type !== 'Servicio'">
            <ion-label>¿Se cuenta con muestras?</ion-label>
            <ion-toggle formControlName="disponibilidad"></ion-toggle>
          </ion-item>
          <ion-item lines="none">
            <p *ngIf="dataForm?.value?.type === 'Medicamento'">Imágen del medicamento</p>
            <p *ngIf="dataForm?.value?.type === 'Dispositivo'">Imágen del producto</p>
            <p *ngIf="dataForm?.value?.type === 'Servicio'">Imágen del servicio</p>
          </ion-item>
          <ion-item lines="none">
            <ion-thumbnail slot="start">
              <img [src]="imageProfile ? imageProfile : 'assets/img/no-image.jpg'" />
            </ion-thumbnail>
            <ion-button
              (click)="
                presentActionSheet1();
                analyticsClickEvent('upload_image', {
                  product_brand: dataForm.get('marca').value,
                  product_type: dataForm.get('type').value,
                  content_type: 'Upload imagen producto',
                  section_name: 'Productos'
                })
              "
              shape="outline"
              color="tertiary"
              expand="block"
            >
              Seleccionar imagen
            </ion-button>
            <input
              id="file1"
              class="hide"
              #file1
              name="file1"
              accept="image/*"
              type="file"
              name="file"
              (change)="selectFile1($event.target)"
            />
          </ion-item>
          <ion-item lines="none">
            <p *ngIf="dataForm?.value?.type === 'Medicamento'">IPP (Instrucción para prescripción)</p>
            <p *ngIf="dataForm?.value?.type === 'Dispositivo'">Ficha Técnica</p>
            <p *ngIf="dataForm?.value?.type === 'Servicio'">Documentación detallada</p>
          </ion-item>
          <ion-item lines="none">
            <ion-button
              (click)="
                triggerClick2();
                analyticsClickEvent('click', {
                  product_brand: dataForm.get('marca').value,
                  product_type: dataForm.get('type').value,
                  content_type: 'Upload IPP / Ficha técnica',
                  section_name: 'Productos'
                })
              "
              shape="outline"
              color="tertiary"
              expand="block"
            >
              Seleccionar archivo
            </ion-button>
            <small ion-text color="danger"
              >Sólo se aceptan archivos PDF (Preferentemente en versión web y 10 Mb de tamaño máximo).</small
            >
            <input
              id="file2"
              class="hide"
              #file2
              name="file2"
              accept="application/pdf"
              type="file"
              name="file"
              (change)="
                selectFile2($event.target);
                analyticsClickEvent('upload_PDF', {
                  product_brand: dataForm.get('marca').value,
                  product_type: dataForm.get('type').value,
                  content_type: 'Upload archivos',
                  section_name: 'Productos'
                })
              "
            />
          </ion-item>
          <ion-item *ngIf="fileName || fileURL" (click)="open()">
            <ion-text color="tertiary">
              <ion-icon name="attach-outline"></ion-icon>
              {{ fileName ? fileName : 'Ver Ficha' }}
            </ion-text>
          </ion-item>
          <ion-text color="danger">
            <p>* Datos obligatorios</p>
          </ion-text>
          <ion-button
            (click)="
              analyticsClickEvent('save_product', {
                product_brand: dataForm.get('marca').value,
                product_type: dataForm.get('type').value,
                product_availability: dataForm.get('disponibilidad').value == true ? 1 : 0,
                content_type: 'Producto guardado',
                section_name: 'Productos'
              })
            "
            type="submit"
            color="tertiary"
            expand="block"
            [disabled]="!dataForm.valid || isLoading"
          >
            Cargar producto / servicio
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
