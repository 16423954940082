import { Component, OnInit } from '@angular/core';
import { DocumentData, Query, QuerySnapshot, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { from } from 'rxjs';
import { environment } from '@env/environment';
import { SearchService } from '@app/shared/services/search/search.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('USER SELECTOR');

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {
  newMembers: any;
  memberType: any;
  filterData1: any;
  filterData2: any;
  itemsIncluded: any[] = [];
  itemsExcluded: any[] = [];
  filter1: any[] = [];
  filter2: any[] = [];
  originalArray: any[] = [];
  query: Query;
  idGroup: string;
  allUsers: boolean = false;
  locations: any[];
  specialties: any[];
  isLoading: boolean = false;
  myContacts: boolean = true;
  filter: string = '';
  finalFilter: string[] = [];
  step: number = 1;
  listLimit: number = 100;
  membersInOtherGroups: string[] = [];
  filterabc: any[] = [
    { title: 'A', filter: 'a', see: false },
    { title: 'B', filter: 'b', see: false },
    { title: 'C', filter: 'c', see: false },
    { title: 'D', filter: 'd', see: false },
    { title: 'E', filter: 'e', see: false },
    { title: 'F', filter: 'f', see: false },
    { title: 'G', filter: 'g', see: false },
    { title: 'H', filter: 'h', see: false },
    { title: 'I', filter: 'i', see: false },
    { title: 'J', filter: 'j', see: false },
    { title: 'K', filter: 'k', see: false },
    { title: 'L', filter: 'l', see: false },
    { title: 'M', filter: 'm', see: false },
    { title: 'N', filter: 'n', see: false },
    { title: 'Ñ', filter: 'ñ', see: false },
    { title: 'O', filter: 'o', see: false },
    { title: 'P', filter: 'p', see: false },
    { title: 'Q', filter: 'q', see: false },
    { title: 'R', filter: 'r', see: false },
    { title: 'S', filter: 's', see: false },
    { title: 'T', filter: 't', see: false },
    { title: 'U', filter: 'u', see: false },
    { title: 'V', filter: 'v', see: false },
    { title: 'W', filter: 'w', see: false },
    { title: 'X', filter: 'x', see: false },
    { title: 'Y', filter: 'y', see: false },
    { title: 'Z', filter: 'z', see: false }
  ];
  firstCharacter: string;
  currentFilter: string;

  constructor(
    private modalCtrl: ModalController,
    private fireService: FirebaseService,
    private alertCtrl: AlertController,
    public credService: CredentialsService,
    private loader: IonLoaderService,
    private specialtiesService: SpecialtiesService,
    private searchService: SearchService
  ) { }

  async ngOnInit() {
    this.init();
  }

  private async getMoreUsers(): Promise<void> {
    let query: Query;
    if (this.myContacts === true) {
      query = this.fireService.afs
        .collection(`friends/${this.credService.credentials.uid}/friends`)
        .ref.where('status', '==', 'active');
    } else {
      query = this.fireService.afs.collection('users').ref.where('status', '==', 'active');
    }
    //
    if (this.finalFilter && this.finalFilter.length > 0) {
      if (this.finalFilter.length > 10) {
        this.finalFilter = Array.from(this.finalFilter.slice(0, 10));
      }
      query = query.where('filter-meta-data', 'array-contains-any', this.finalFilter);
    }
    if (this.firstCharacter) {
      query = query.where('firstCharacter', '==', this.firstCharacter);
    }
    query = query.where('type', '==', 'medico').orderBy('nameStr', 'asc');
    const response = await query.get();
    if (response.empty === false) {
      const rep = response.docs.map(element => {
        return this.mapping(element);
      });
      this.itemsExcluded = rep;
      this.itemsExcluded = this.itemsExcluded.filter(e => {
        const index: number = this.itemsIncluded.map(e => String(e.id)).indexOf(String(e.id));
        if (!(index > -1)) {
          return e;
        }
      });
      this.itemsExcluded = this.itemsExcluded.map(element => {
        let data: any = element;
        const index: number = this.membersInOtherGroups.indexOf(data.id);
        if (index > -1) {
          data.inGroup = true;
        }
        return data;
      });
      this.step = 2;
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Mensaje',
        message: 'Los filtros no arrojan resultados, por favor intente con otro filtro.',
        buttons: ['OK']
      });
      alert.present();
    }
    return;
  }

  private async init(): Promise<void> {
    this.isLoading = true;
    try {
      this.myContacts = this.credService.credentials.defaultUser;
      await this.getAlphabet();
      await this.getUsersFromotherGroups();
      await this.initLocations();
      await this.initSpecialties();
      if (this.idGroup) {
        await this.getUserList();
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  private async getUsersFromotherGroups(): Promise<void> {
    const groups = await this.fireService.afs
      .collection('broadcast-list')
      .ref.where('uid', '==', this.credService.credentials.uid)
      .get();
    let idGroups: string[] = groups.docs.map(e => e.id);
    if (this.idGroup) {
      const index: number = idGroups.indexOf(this.idGroup);
      if (index > -1) {
        idGroups = idGroups.splice(index, 1);
      }
    }
    for (const item of idGroups) {
      const members = await this.fireService.afs
        .collection('broadcast-list')
        .doc(item)
        .collection('contacts')
        .ref.get();
      this.membersInOtherGroups = this.membersInOtherGroups.concat(members.docs.map(e => String(e.id)));
    }
    this.membersInOtherGroups = this.membersInOtherGroups.filter(this.onlyUnique);
    return;
  }

  private async initLocations(): Promise<void> {
    try {
      try {
        const resp = await this.fireService.afs
          .collection('states-in-use')
          .ref.orderBy('name', 'asc')
          .get();
        this.locations = resp.docs.map(e => e.get('name'));
      } catch (error) {
        log.error(error);
      }
      let resp: QuerySnapshot<DocumentData>;
      if (this.myContacts === true) {
        resp = await this.fireService.afs
          .collection(`friends/${this.credService.credentials.uid}/information/counters/filters/type/medico`)
          .ref.where('count', '>', 0)
          .get();
      } else {
        resp = await this.fireService.afs
          .collection(`information/counters/filters/type/medico`)
          .ref.where('count', '>', 0)
          .get();
      }
      let myArray = resp.docs.map(e => e.id);
      let filterArray: { id: string; filter: string }[] = [];
      if (this.filter && this.filter !== '') {
        for (const element of myArray) {
          filterArray.push({ id: element, filter: `${element}${this.filter}` });
        }
      }
      if (!(this.filter && this.filter !== '')) {
        this.locations = this.locations
          .filter(element => {
            const index: number = myArray.indexOf(String(element));
            if (index > -1) {
              return element;
            }
          })
          .map(element => {
            let data = {};
            data = {
              name: element,
              id: element,
              filter: element
            };
            return data;
          });
      } else {
        filterArray = filterArray.filter(element => {
          const index: number = myArray.indexOf(String(element.filter));
          if (index > -1) {
            return element;
          } else {
            return false;
          }
        });
        this.locations = this.locations
          .filter(element => {
            const index: number = filterArray.map(e => String(e.id)).indexOf(String(element));
            if (index > -1) {
              return element;
            }
          })
          .map(element => {
            let data: any = {};
            data = {
              name: element,
              id: element,
              filter: element
            };
            const index: number = filterArray.map(e => String(e.id)).indexOf(String(element));
            if (index > -1) {
              data.filter = filterArray[index].filter;
            }
            return data;
          });
      }
      this.locations = this.locations.map(element => {
        return { id: element.id, label: element.name };
      });
    } catch (error) {
      log.error(error);
    }
    return;
  }

  private async initSpecialties(): Promise<void> {
    try {
      try {
        this.specialties = await this.specialtiesService.getSpecialties();
      } catch (error) {
        log.error(error);
      }
      let resp: QuerySnapshot<DocumentData>;
      if (this.myContacts === true) {
        resp = await this.fireService.afs
          .collection(`friends/${this.credService.credentials.uid}/information/counters/filters/type/medico`)
          .ref.where('count', '>', 0)
          .get();
      } else {
        resp = await this.fireService.afs
          .collection(`information/counters/filters/type/medico`)
          .ref.where('count', '>', 0)
          .get();
      }
      let myArray = resp.docs.map(e => e.id);
      let filterArray: { id: string; filter: string }[] = [];
      if (this.filter && this.filter !== '') {
        for (const element of myArray) {
          filterArray.push({ id: element, filter: `${this.filter}${element}` });
        }
      }
      if (!(this.filter && this.filter !== '')) {
        this.specialties = this.specialties
          .filter(element => {
            const index: number = myArray.indexOf(String(element.id));
            if (index > -1) {
              return element;
            }
          })
          .map(element => {
            let data = element;
            data.filter = data.id;
            return data;
          });
      } else {
        filterArray = filterArray.filter(element => {
          const index: number = myArray.indexOf(String(element.filter));
          if (index > -1) {
            return element;
          } else {
            return false;
          }
        });
        this.specialties = this.specialties
          .filter(element => {
            const index: number = filterArray.map(e => String(e.id)).indexOf(String(element.id));
            if (index > -1) {
              return element;
            }
          })
          .map(element => {
            let data = element;
            const index: number = filterArray.map(e => String(e.id)).indexOf(String(element.id));
            if (index > -1) {
              data.filter = filterArray[index].filter;
            }
            return data;
          });
      }
      this.specialties = this.specialties.map(element => {
        return { id: element.id, label: element.name };
      });
    } catch (error) {
      log.error(error);
    }
    return;
  }

  private async getUserList(): Promise<void> {
    try {
      const response1 = await this.fireService.afs
        .collection('broadcast-list')
        .doc(this.idGroup)
        .collection('contacts')
        .ref.orderBy('nameStr', 'asc')
        .get();
      this.itemsIncluded = response1.docs.map(element => {
        return this.mapping(element);
      });
      this.itemsIncluded = this.itemsIncluded.map(element => {
        let data: any = element;
        const index: number = this.membersInOtherGroups.indexOf(data.id);
        if (index > -1) {
          data.inGroup = true;
        }
        return data;
      });
      this.originalArray = Array.from(this.itemsIncluded) || [];
    } catch (error) {
      log.error(error);
    }
    return;
  }

  public closeModal(): void {
    this.modalCtrl.dismiss();
    return;
  }

  async locationsFilter() {
    this.firstCharacter = undefined;
    this.currentFilter = undefined;
    let alert: any;
    let array: any[] = this.locations.map(e => {
      const id: string = e.id;
      let data: any = e;
      data.name = id;
      data.label = id;
      data.value = id;
      data.type = 'checkbox';
      data.checked = false;
      return { id, ...data };
    });
    if (this.filter1.length > 0) {
      array = array.map(element => {
        let data = element;
        const index: number = this.filter1.map(e => e.id).indexOf(element.id);
        if (index > -1) {
          data.checked = true;
        }
        return data;
      });
    }
    alert = await this.alertCtrl.create({
      header: 'Zona geográfica',
      inputs: array,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Filtrar',
          handler: values => {
            this.filter1 = this.locations.filter(element => {
              const index: number = Array.from(values).indexOf(element.id);
              if (index > -1) {
                return element;
              }
            });
          }
        }
      ]
    });
    alert.present();
  }

  async specialtiesFilter() {
    this.firstCharacter = undefined;
    this.currentFilter = undefined;
    let alert: any;
    let array: any[] = this.specialties.map(e => {
      const id: string = e.id;
      let data: any = e;
      data.name = id;
      data.label = data.label;
      data.value = id;
      data.type = 'checkbox';
      data.checked = false;
      return { id, ...data };
    });
    if (this.filter2.length > 0) {
      array = array.map(element => {
        let data = element;
        const index: number = this.filter2.map(e => e.id).indexOf(element.id);
        if (index > -1) {
          data.checked = true;
        }
        return data;
      });
    }
    alert = await this.alertCtrl.create({
      header: 'Especialidades',
      inputs: array,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Filtrar',
          handler: values => {
            this.filter2 = this.specialties.filter(element => {
              const index: number = Array.from(values).indexOf(element.id);
              if (index > -1) {
                return element;
              }
            });
          }
        }
      ]
    });
    alert.present();
  }

  public async addItem(): Promise<void> {
    const items: any[] = this.itemsExcluded.filter(e => {
      if (e.selected === true) {
        return e;
      }
    });
    if (items.length > 0) {
      for (let i = 0; i < this.itemsExcluded.length; i++) {
        if (this.itemsExcluded[i].selected === true) {
          if (this.itemsIncluded && this.itemsIncluded.length < this.listLimit) {
            let data = Array.from(this.itemsExcluded)[i];
            data.selected = false;
            this.itemsIncluded.push(data);
            this.itemsExcluded.splice(i, 1);
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Alerta',
              message: 'Has superado el límite máximo de usuarios por grupo.',
              buttons: ['OK']
            });
            alert.present();
          }
        }
      }
    } else {
      if (this.itemsExcluded.length > 0) {
        this.itemsExcluded[0].selected = true;
      }
    }
    return;
  }

  public async addAllItems(): Promise<void> {
    if (this.itemsIncluded.length + this.itemsExcluded.length <= this.listLimit) {
      this.itemsIncluded = this.itemsIncluded.concat(Array.from(this.itemsExcluded));
      this.itemsExcluded = [];
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Alerta',
        message:
          'Has superado el límite máximo de usuarios por grupo.<br> Se agregaran médicos hasta completar el listado.',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel'
          },
          {
            text: 'Ok',
            handler: () => {
              const left: number = this.listLimit - this.itemsIncluded.length;
              this.itemsIncluded = this.itemsIncluded.concat(Array.from(this.itemsExcluded.slice(0, left)));
              this.itemsExcluded = Array.from(this.itemsExcluded.slice(left));
            }
          }
        ]
      });
      alert.present();
    }
    return;
  }

  public removeItem() {
    const items: any[] = this.itemsIncluded.filter(e => {
      if (e.selected === true) {
        return e;
      }
    });
    if (items.length > 0) {
      for (let i = 0; i < this.itemsIncluded.length; i++) {
        if (this.itemsIncluded[i].selected === true) {
          let data = Array.from(this.itemsIncluded)[i];
          data.selected = false;
          this.itemsExcluded.push(data);
          this.itemsIncluded.splice(i, 1);
        }
      }
      this.itemsIncluded = this.itemsIncluded.filter(e => {
        if (!e.selected === true) {
          return e;
        }
      });
    } else {
      if (this.itemsIncluded.length > 0) {
        this.itemsIncluded[0].selected = true;
      }
    }
    return;
  }

  public removeAllItems() {
    this.itemsExcluded = this.itemsExcluded.concat(Array.from(this.itemsIncluded));
    this.itemsIncluded = [];
    return;
  }

  public removeFilterItem2(index: number): void {
    this.filter2.splice(index, 1);
    return;
  }

  public removeFilterItem1(index: number): void {
    this.filter1.splice(index, 1);
    return;
  }

  public clear(): void {
    this.filter1 = [];
    this.filter2 = [];
    return;
  }

  public filterUsers(): void {
    let final: string[] = [];
    if (this.filter1 && this.filter1.length > 0 && this.filter2 && this.filter2.length > 0) {
      const final1: string[] = this.filter1.map(e => String(e.id));
      const final2: string[] = this.filter2.map(e => String(e.id));
      final = [];
      final1.forEach(e => {
        final2.forEach(ee => {
          final.push(`${e}${ee}`);
        });
      });
    } else if (this.filter1 && this.filter1.length > 0) {
      final = this.filter1.map(e => String(e.id));
    } else if (this.filter2 && this.filter2.length > 0) {
      final = this.filter2.map(e => String(e.id));
    }
    this.finalFilter = final;
    this.getMoreUsers();
  }

  private async create(): Promise<void> {
    if (this.itemsIncluded && this.itemsIncluded.length <= this.listLimit) {
      this.isLoading = true;
      this.isLoading = false;
      this.modalCtrl.dismiss({ users: this.itemsIncluded });
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Alerta',
        message: 'Has superado el límite máximo de usuarios por grupo.',
        buttons: ['OK']
      });
      alert.present();
    }
    return;
  }

  private async update(): Promise<void> {
    if (this.itemsIncluded && this.itemsIncluded.length <= this.listLimit) {
      this.isLoading = true;
      await this.loader.ionLoaderPresent();
      let deletedItems: any[] = [];
      let createdItems: any[] = [];
      deletedItems = Array.from(this.originalArray).filter(element => {
        const index: number = this.itemsIncluded.map(e => String(e.id)).indexOf(String(element.id));
        if (!(index > -1)) {
          return element;
        }
      });
      createdItems = Array.from(this.itemsIncluded).filter(element => {
        const index: number = this.originalArray.map(e => String(e.id)).indexOf(String(element.id));
        if (!(index > -1)) {
          return element;
        }
      });
      try {
        const batch = this.fireService.afs.firestore.batch();
        deletedItems.forEach(element => {
          batch.delete(
            this.fireService.afs
              .collection('broadcast-list')
              .doc(this.idGroup)
              .collection('contacts')
              .doc(element.id).ref
          );
        });
        await batch.commit();
      } catch (error) {
        log.error(error);
      }
      try {
        let promises: Promise<any>[] = [];
        createdItems.forEach(element => {
          promises.push(
            this.fireService.afs
              .collection('broadcast-list')
              .doc(this.idGroup)
              .collection('contacts')
              .doc(element.id)
              .set(element)
          );
        });
        await Promise.all(promises);
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
      await this.loader.ionLoaderDismiss();
      this.modalCtrl.dismiss();
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Alerta',
        message: 'Has superado el límite máximo de usuarios por grupo.',
        buttons: ['OK']
      });
      alert.present();
    }
    return;
  }

  public async search(ev: any): Promise<void> {
    if (ev && ev.target && ev.target.value && String(ev.target.value).length > 3) {
      const searchStr: string = this.searchService.clearSearch(ev.target.value, false);
      let query: Query;
      if (this.myContacts === true) {
        query = this.fireService.afs
          .collection(`friends/${this.credService.credentials.uid}/friends`)
          .ref.where('status', '==', 'active')
          .where('search', 'array-contains', searchStr)
          .where('type', '==', 'medico')
          .orderBy('nameStr', 'asc');
      } else {
        query = this.fireService.afs
          .collection('users')
          .ref.where('status', '==', 'active')
          .where('search', 'array-contains', searchStr)
          .where('type', '==', 'medico')
          .orderBy('nameStr', 'asc');
      }
      const response = await query.get();
      if (response.empty === false) {
        const rep = response.docs.map(element => {
          return this.mapping(element);
        });
        this.itemsExcluded = rep;
        this.itemsExcluded = this.itemsExcluded.filter(e => {
          const index: number = this.itemsIncluded.map(e => String(e.id)).indexOf(String(e.id));
          if (!(index > -1)) {
            return e;
          }
        });
        this.step = 2;
      } else {
        const alert = await this.alertCtrl.create({
          header: 'Mensaje',
          message: 'La búsqueda no arroja resultados, por favor intente con otra búsqueda.',
          buttons: ['OK']
        });
        alert.present();
      }
    }
    return;
  }

  private mapping(element: any): any {
    let data: any = element.data();
    if (data['filter-meta-data']) {
      let initialArray: any[];
      try {
        initialArray = Array.from(data['filter-meta-data']).map(e => String(e));
      } catch (e) {
        log.error(e);
      }
      const locationsOriginal = Array.from(this.locations);
      const locationsMapped = Array.from(locationsOriginal).map(e => String(e.id));
      const specialtiesOriginal = Array.from(this.specialties);
      const specialtiesMapped = Array.from(specialtiesOriginal).map(e => String(e.id));
      let metaData = {
        state1: '',
        state2: '',
        specialty1: '',
        specialty2: ''
      };
      let locations: string[] = [];
      let specialties: string[] = [];
      for (const e of initialArray) {
        const index: number = locationsMapped.indexOf(e);
        if (index > -1) {
          locations.push(locationsOriginal[index].id);
        }
      }
      for (const e of initialArray) {
        const index: number = specialtiesMapped.indexOf(e);
        if (index > -1) {
          specialties.push(specialtiesOriginal[index].label);
        }
      }
      locations.forEach((e, index) => {
        if (index === 0) {
          metaData.state1 = e;
        }
        if (index === 1) {
          metaData.state2 = e;
        }
      });
      specialties.forEach((e, index) => {
        if (index === 0) {
          metaData.specialty1 = e;
        }
        if (index === 1) {
          metaData.specialty2 = e;
        }
      });
      data.metaData = metaData;
    }
    data.selected = false;
    const id: string = element.id;
    return { id, ...data };
  }

  public async save(): Promise<void> {
    if (this.idGroup) {
      this.update();
    } else {
      this.create();
    }
    return;
  }

  public onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }

  async filterList(filter: string) {
    this.firstCharacter = filter;
    this.filter1 = [];
    this.filter2 = [];
    this.filterUsers();
  }

  private async getAlphabet(): Promise<void> {
    try {
      let filterArray: any[] = [];
      let resp: QuerySnapshot<DocumentData>;
      if (this.myContacts === true) {
        resp = await this.fireService.afs
          .collection(`friends/${this.credService.credentials.uid}/information/counters/alphabet/medico/letters`)
          .ref.where('count', '>', 0)
          .get();
      } else {
        resp = await this.fireService.afs
          .collection('information/counters/alphabet/medico/letters')
          .ref.where('count', '>', 0)
          .get();
      }
      filterArray = resp.docs;
      const alphabet: any[] = filterArray.map(e => e.id);
      this.filterabc = this.filterabc.map(element => {
        let data: any = element;
        const index: number = alphabet.indexOf(element.filter);
        if (index > -1) {
          data.see = true;
        } else {
          data.see = false;
        }
        return data;
      });
    } catch (error) {
      log.error(error);
    }
    return;
  }

  resetSearch() { }

  removeEspecialtiesFilter() { }

  removeStatesFilter() { }
}
