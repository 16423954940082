import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyContactsComponent } from './my-contacts.component';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { CardsModule } from '../cards/cards.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MyContactsComponent],
  imports: [CommonModule, HttpClientModule, IonicModule, CardsModule, RouterModule, FormsModule],
  exports: [MyContactsComponent]
})
export class MyContactsModule {}
