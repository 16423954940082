<ion-card color="light" mode="md" class="card-post" [button]="false">
  <ion-row style="padding: 0px;">
    <ion-col sizeXs="12" sizeLg="3" sizeMd="6" style="padding: 0px;">
      <ion-skeleton-text animated class="video-img" style="height: 200px;"></ion-skeleton-text>
    </ion-col>
    <ion-col sizeXs="" style="padding: 0px;">
      <ion-toolbar color="primary" style="height: auto;">
        <ion-row class="ion-align-items-center">
          <ion-col sizeXs="12" sizeSm="">
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-col>
  </ion-row>
</ion-card>