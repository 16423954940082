import { Component, NgZone } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ToastController, Platform, ModalController, AlertController, PopoverController } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { firstValueFrom, merge, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { register } from 'swiper/element/bundle';
import { untilDestroyed } from '@app/core/until-destroyed';
import { environment } from '@env/environment';
import { Logger } from '@app/core/logger.service';
import { NotificationService } from '@app/shared/services/notification/notification.service';
import { CatchNotificationService } from '@app/shared/services/notification/catch-notification.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CredentialsService } from './core/authentication/credentials.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { MoodleService } from '@app/shared/services/moodle/moodle.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { ImgModalComponent } from '@app/shared/components/img-modal/img-modal.component';
import { NotificationsListComponent } from '@app/shared/components/notifications-list/notifications-list.component';
import { Capacitor } from "@capacitor/core";
import { App } from '@capacitor/app';
import { PushNotifications } from '@capacitor/push-notifications';
import { Badge } from '@capawesome/capacitor-badge';
import { Browser } from '@capacitor/browser';
import { NativeMarket } from '@capacitor-community/native-market';
import * as _menuData from './sideMenu.json';
import * as moment from 'moment';
import { HomeService } from './home/home.service';
import { SearchService } from './shared/services/search/search.service';
import { LoginComponent } from './login/login.component';
import { Location } from '@angular/common';
import { FormTermOfUseComponent } from './shared/forms/account-data-form/form-term-of-use/form-term-of-use.component';
const log = new Logger('AppComponent');
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private moodleUserId: any;
  private courseDetail: any = {};
  private courses: any[] = [];
  private courseId: any;
  private userCourses: any[] = [];
  private completionModules: any[] = [];
  public appPages: any[] = (_menuData as any).default;
  public pagesInfo: any[] = [];
  public notificationsCount: number = 0;
  public highlight: any = {};
  public hideHeader: boolean = false;
  public removeNew: 'yes' | 'no';
  public searchStr: string = '';
  public pathDataRoute: string = '';
  public searchRoute: string = '';
  public searchRouteCancel: string = '';
  public additionalSearchParams: string = '';
  public searchFunction: string = '';
  public searchFunctionClear: string = '';
  public placeholder: string = '';
  icon: any = {};
  root: string = "";
  searchBar: boolean = false;
  hideBackButton = false;
  constants: any = {};

  constructor(
    public router: Router,
    public credService: CredentialsService,
    private aRoute: ActivatedRoute,
    private platform: Platform,
    private zone: NgZone,
    private storage: Storage,
    private fireService: FirebaseService,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private loader: IonLoaderService,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private analyticsFB: AnalyticsService,
    private moodle: MoodleService,
    private titleService: Title,
    private notificationService: NotificationService,
    private catchNotification: CatchNotificationService,
    private authServe: AuthenticationService,
    private homeService: HomeService,
    private searchService: SearchService,
    private location: Location
  ) {
    this.initializeApp();
  }

  back() {
    this.location.back();
  }

  private initRoutes() {
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.aRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }), filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      ).subscribe((nav) => {
        // Title
        if (nav && nav.title) {
          this.titleService.setTitle(nav.title);
        } else {
          this.titleService.setTitle("");
        }
        // Icon
        if (nav && nav.icon) {
          this.icon = nav.icon;
        } else {
          this.icon = undefined;
        }
        // Root route
        if (nav && nav.root && (!nav.hiddenItem === true)) {
          this.root = nav.root;
        } else {
          this.root = undefined;
        }
        // Search route
        if (nav && nav.searchRoute) {
          this.searchRoute = nav.searchRoute;
        } else {
          this.searchRoute = undefined;
        }
        // Search route
        if (nav && nav.hideHeader) {
          this.hideHeader = nav.hideHeader;
        } else {
          this.hideHeader = false;
        }
        // Item menu
        if (nav && nav.menuItem) {
          this.mapItems(nav.menuItem);
        } else {
          this.mapItems(undefined);
        }
        // Item back button
        if (nav && nav.hideBackButton && nav.hideBackButton === true) {
          this.hideBackButton = true;
        } else {
          this.hideBackButton = false;
        }
      });
  }

  private resetItems() {
    for (let item1 of this.appPages) {
      item1.selected = false;
      if (item1 && item1.items && item1.items[0]) {
        for (let item2 of item1.items) {
          item2.selected = false;
          item1.collapsed = false;
        }
      }
    }
  }

  private mapItems(menuItem: string) {
    this.resetItems();
    for (let item1 of this.appPages) {
      if (item1.url === menuItem) {
        item1.selected = true;
      } else {
        if (item1 && item1.items && item1.items[0]) {
          for (let item2 of item1.items) {
            if (item2.url === menuItem) {
              item2.selected = true;
              item1.collapsed = true;
            }
          }
        }
      }
    }
  }

  // SEARCH FUNCTIONS

  public keypressEvent(event: any): void {
    if (event && event.key === 'Enter') {
      this.doSearch();
    }
  }

  public doSearch() {
    const search: string = this.searchService.clearSearch(this.searchStr, true);
    if (search && search.length >= 3) {
      this.router.navigate([`${this.searchRoute}/${search}`])
    }
  }

  public ionCancel() {
    this.searchStr = '';
  }

  public ionClear() {
    this.searchStr = '';
  }

  async initializeApp(): Promise<void> {
    try {
      this.initRoutes();
      this.platform.ready().then(() => {
        if (this.platform.is('capacitor')) {
          this.initializeDeepLinks();
        }
      });
      this.constants = await this.initConstants();
      this.addListenerClickToOpenFile(this.constants['out-of-inappbrowser'] || []);
      await this.storage.create();
      await this.analyticsFB.initFb();
      await this.platform.ready();
      if (environment.production) {
        Logger.enableProductionMode();
      }
      this.initializeCredentials();
    } catch (error) {
      log.error("%cError", 'color:white; background-color:red;', error);
    }
  }

  async initConstants(): Promise<any> {
    try {
      return await firstValueFrom(this.fireService.getConstants())
    } catch (error) {
      log.error(error);
      return {}
    }
  }

  /**
  * Initialize methods
  */
  public initializeCredentials(): Subscription {
    return this.authServe.authState().subscribe(async (user) => {
      await this.setUserDataAndCredentials(user);
      if (this.platform.is('capacitor')) {
        await PushNotifications.removeAllDeliveredNotifications();
        await Badge.clear();
      } else {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const analytics_label = urlParams.get('analytics_label');
        if (analytics_label) {
          this.analyticsClickEvent('notification_open', { label: analytics_label });
          let URL: string = String(window.location.pathname).split('?')[0];
          this.router.navigate([URL]);
        }
        this.initializeWebUpdateAvailable();
      }
      await this.initializeMoodleUser();
      if (this.credService && this.credService.credentials && this.credService.credentials.type === 'medico') {
        await this.initializeMoodleChecks();
      }
      await this.fireService.updateUserBages();
      if (user && user.uid && this.platform.is('capacitor') === true) {
        const today = moment();
        const creationTime = moment(user.metadata.creationTime);
        const days: number = today.diff(creationTime, 'days');
        if (Number(days) >= 30) {
          this.promptRateUsInStore();
        }
      }
    });
  }

  promptRateUsInStore() { }

  private initializeDeepLinks() {
    App.addListener('appUrlOpen', async (data: any) => {
      this.zone
        .run(async () => {
          const slug = String(data.url).replace(environment.APP_URL, '');
          if (slug) {
            await this.router.navigateByUrl(slug);
          }
        })
        .catch(error => {
          log.error(error);
        });
    });
  }

  private async initializeAppUpdateAvailable() {
    try {
      if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
        const appVersionStoreIOS: string = this.constants.appVersionStoreIOS;
        const appVersionStoreAndroid: string = this.constants.appVersionStoreAndroid;
        if (this.platform.is('capacitor')) {
          const appInfo = await App.getInfo();
          const version = appInfo.version;
          const build = appInfo.build;

          if (this.platform.is('android')) {
            if (!(Number(build) >= Number(appVersionStoreAndroid))) {
              this.alertUpdateAppAvailable();
            }
            this.fireService.afs
              .collection('users')
              .doc(this.credService.credentials.uid)
              .update({ appVersionStoreAndroid: String(version) });
          } else {
            if (!(Number(build) >= Number(appVersionStoreIOS))) {
              this.alertUpdateAppAvailable();
            }
            this.fireService.afs
              .collection('users')
              .doc(this.credService.credentials.uid)
              .update({ appVersionStoreIOS: String(version) });
          }
        }
      }
    } catch (error) {
      log.error(error);
    }
  }

  private async initializeWebUpdateAvailable() {
    try {
      if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
        const pageVersion: string = this.constants.pageVersion;
        const version = await this.storage.get('pageVersion');
        if (
          this.credService &&
          this.credService.credentials &&
          this.credService.credentials.uid
        ) {
          if (!version) {
            this.storage.set('pageVersion', pageVersion);
            this.fireService.afs
              .collection('users')
              .doc(this.credService.credentials.uid)
              .update({ pageVersion: String(pageVersion) });
          } else if (version !== pageVersion) {
            this.alertUpdateWebAppAvailable(pageVersion);
            this.fireService.afs
              .collection('users')
              .doc(this.credService.credentials.uid)
              .update({ pageVersion: String(version) });
          }
        }
      }
    } catch (error) {
      log.error(error);
    }
  }

  private async initializeMoodleChecks() {
    try {
      this.completionModules = [];
      for (const courseId of this.userCourses) {
        const resp = await this.moodle.coreCompletionGetActivitiesCompletionStatus(courseId, this.moodleUserId);
        const percentage: number[] = Array.from(resp.statuses).map((e: any) => {
          return Number(e.state);
        });
        let sum: number = 0;
        let perc = 0;
        percentage.forEach(e => {
          sum += e;
        });
        perc = sum / percentage.length;
        this.completionModules.push({ course_id: courseId, percentage: perc });
      }
      ///get course info
      this.completionModules = this.completionModules.map((e: any) => {
        let data = e;
        const courseId = e.course_id;
        const index: number = this.courses.map(e => Number(e.id)).indexOf(Number(courseId));
        if (index > -1) {
          data.title = this.courses[index].fullname;
        }
        return data;
      });
      this.prepareAlert(this.completionModules);
    } catch (error) {
      log.error(error);
    }
  }

  private async initializeMoodleUser() {
    if (this.credService && this.credService.credentials && this.credService.credentials.email) {
      const user = await this.moodle.userGetUsersByEmail(this.credService.credentials.email);
      if (user && user.users && user.users.length > 0) {
        const respToken = await this.moodle.getToken(this.credService.credentials.email, this.credService.credentials.uid);
        if (respToken && respToken.token) {
          const response1 = await this.moodle.coreCourseGetEnrolledCoursesByTimelineClassification(respToken.token, 'all');
          this.courses = Array.from(response1.courses);
          const index = this.courses.map((e: any) => Number(e.id)).indexOf(Number(this.courseId));
          if (index > -1) {
            this.courseDetail.image = this.courses[index] && this.courses[index].courseimage ? this.courses[index].courseimage : undefined;
          }
        }
        this.moodleUserId = user.users[0]['id'];
        this.userCourses = await this.moodle.enrolGetUsersCourses(String(this.moodleUserId));
        this.userCourses = this.userCourses.map((e: any) => {
          return Number(e.id);
        });
      }
    } else {
      return;
    }
  }

  private initializeNotifications(): Subscription | void {
    try {
      return this.fireService.afs
        .collection('notifications', ref =>
          ref
            .where('uid', '==', this.credService.credentials.uid)
            .where('viewed', '==', false)
            .orderBy('date', 'desc')
        )
        .valueChanges()
        .subscribe(snap => {
          this.highlight.chatsCount = snap.filter((e: any) => {
            if (e && e.type && e.type === 'chat') {
              return e;
            } else {
              return false;
            }
          }).length;
          this.notificationsCount = snap.length;
        });
    } catch (error) {
      log.error(error);
    }
  }

  private initializeBadgesCertificates(): Subscription | void {
    try {
      return this.fireService.afs
        .collection('constancias-webinar', ref =>
          ref
            .where('uuid', '==', this.credService.credentials.uid)
            .where('viewed', '==', false)
        )
        .valueChanges()
        .subscribe(snap => {
          this.highlight.certificatesCount = snap.length;
        });
    } catch (error) {
      log.error(error);
    }
  }

  /**
  * Main methods
  */

  public async openNotifications(ev: any) {
    this.analyticsClickEvent('click', {
      content_type: 'Mostrar notificaciones'
    });
    const popover = await this.popoverCtrl.create({
      component: NotificationsListComponent,
      event: ev,
      translucent: true
    });
    popover.present();
  }

  private addListenerClickToOpenFile(outOfInappBrowser: any[]) {
    document.addEventListener('click', (event: any) => {
      const className = event.target.className;
      if (className.search('iab-disabled') > -1 || className === 'iab-disabled') {
        event.preventDefault();
        const url: string = event.target.href || event.target.src;
        const ext = String(String(url).substr(String(url).lastIndexOf('.') + 1)).toLowerCase();
        let coincidence: boolean = false;
        for (let item of outOfInappBrowser) {
          var index: number = url.indexOf(item);
          if (index >= 0) {
            coincidence = true;
            break;
          }
        }
        if (
          ext === 'pdf' ||
          ext === 'doc' ||
          ext === 'docx' ||
          ext === 'ppt' ||
          ext === 'pptx' ||
          ext === 'xls' ||
          ext === 'xlsx' ||
          coincidence === true
        ) {
          try {
            Browser.open({ url: url });
          } catch (error) {
            this.toastInAppBrowserError();
          }
        } else {
          if (
            (this.platform.is('android') && this.platform.is('capacitor')) ||
            (this.platform.is('ios') && this.platform.is('capacitor'))
          ) {
            Browser.open({ url: url });
          } else {
            Browser.open({ url: url });
          }
        }
      } else {
        let url: string = event.target.href;
        if (url !== '' && url !== undefined && url !== null) {
          const tmpUrl = url.toLowerCase();
          for (const domain of environment.APP_DOMAINS) {
            if (tmpUrl.includes(domain) === true) {
              event.preventDefault();
              if (this.platform.is('capacitor') === true) {
                url = url.replace(domain, '');
                this.router.navigateByUrl(String(url));
                try {
                  this.modalCtrl.dismiss();
                } catch (e) {
                  log.error(e);
                }
              } else {
                window.location.replace(String(url));
              }
            }
          }
        }
      }
    });
  }

  private async logout() {
    await this.loader.ionLoaderPresent();
    this.analyticsClickEvent('log_out', {});
    await this.authServe.logout();
    await this.router.navigate(['/home'], { replaceUrl: true });
    await this.loader.ionLoaderDismiss();
    await location.reload();
  }

  /**
  * Other methods
  */

  private prepareAlert(array: any[]): void {
    let text: string = '';
    let count: number = 1;
    array.forEach(e => {
      if (e.percentage < 1) {
        text = text + `<p>${count++}.- <strong>${e.title}<strong></p>`;
      }
    });
    if (text !== '') {
      this.alertPendingCourses(text);
    }
  }

  public analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  removeBaseUrl(url: string) {
    var baseUrlPattern = /^https?:\/\/[a-z\:0-9.]+/;
    var result = "";
    var match = baseUrlPattern.exec(url);
    if (match != null) {
      result = match[0];
    }
    if (result.length > 0) {
      url = url.replace(result, "");
    }
    return url;
  }

  /**
  * Shared methods
  */

  public async setUserDataAndCredentials(user: firebase.default.User) {
    if (user && user.uid && user.email && user.isAnonymous === false) {
      const userResp = await this.authServe.updateCredentials(user);
      if (userResp) {
        try {
          this.removeNew = await this.storage.get('removeNew');
          if (!(this.removeNew === 'no' || this.removeNew === 'yes')) {
            await this.storage.set('removeNew', 'no');
            this.removeNew = await this.storage.get('removeNew');
          }
          const complete: boolean = await this.authServe.registrationComplete(user.uid);
          log.debug('%c=== LOGGED ===', 'background-color: green; font-size: larger; color: white');
          log.debug(`%c${userResp && userResp.uid ? userResp.uid : undefined}`, 'background-color: green; font-size: larger; color: white');
          log.debug(`%c${userResp && userResp.email ? userResp.email : undefined}`, 'background-color: green; font-size: larger; color: white');
          log.debug(`%c${userResp && userResp.type ? userResp.type : undefined}`, 'background-color: green; font-size: larger; color: white');
          log.debug(`%cActive: ${userResp && userResp.active && userResp.active === true ? userResp.active : false}`, `background-color: ${userResp && userResp.active && userResp.active === true ? 'green' : 'orange'}; font-size: larger; color: white`);
          log.debug(`%cRegistration Complete: ${complete}`, `background-color: ${complete === true ? 'green' : 'orange'}; font-size: larger; color: white`);
          log.debug(`%cNuevas condiciones de uso: ${userResp && userResp.newConditionsOfUseAccepted && userResp.newConditionsOfUseAccepted === true ? true : false}`, `background-color: ${userResp && userResp.newConditionsOfUseAccepted && userResp.newConditionsOfUseAccepted === true ? 'green' : 'orange'}; font-size: larger; color: white`);
          log.debug(`%cDefault User: ${userResp && userResp.defaultUser ? userResp.defaultUser : false}`, 'background-color: green; font-size: larger; color: white');
          if (!!!(userResp && userResp.newConditionsOfUseAccepted && userResp.newConditionsOfUseAccepted === true)) {
            // Alert nuevas condiciones
            this.termOfUseAlert();
          }
          if (userResp.active === true && complete === true) {
            // await this.isCompleteProfile(user.uid);
            await this.initializeAppUpdateAvailable();
          }
          this.analyticsFB.setUser(this.credService.credentials.uid);
          const cond1: boolean = String(this.credService.credentials.email)
            .toLocaleLowerCase()
            .includes('@yopmail.com');
          const cond2: boolean = String(this.credService.credentials.email)
            .toLocaleLowerCase()
            .includes('@enacment.com');
          const cond3: boolean = String(this.credService.credentials.email)
            .toLocaleLowerCase()
            .includes('@conectimed.com');
          if (cond1 === true || cond2 === true || cond3 === true) {
            await this.analyticsFB.setUserProperties(this.credService.credentials.uid, true);
          } else {
            await this.analyticsFB.setUserProperties(this.credService.credentials.uid, false);
          }
        } catch (error) {
          log.error(error);
        }
        try {
          this.initializeNotifications();
          this.initializeBadgesCertificates();
          this.notificationService.notificationInit();
          this.catchNotification.catchNotificationInit();
          this.checkSurvery();
        } catch (error) {
          log.error(error);
        }
      } else {
        await this.authServe.logout();
        log.debug('%c=== ACCOUNT INCOMPLETE  ===', 'background-color: red; font-size: larger; color: white');
      }
    } else {
      if (user && (user.isAnonymous === true || !user.email)) {
        log.debug('%c=== ANONYMOUS USER ===', 'background-color: orange; font-size: larger; color: white');
        await user.delete();
      }
      await this.authServe.logout();
      log.debug('%c=== NOT LOGGED ===', 'background-color: red; font-size: larger; color: white');
    }
  }

  /**
   * Alert methods
   */

  private async alertPendingCourses(text: string) {
    const alert = await this.alertCtrl.create({
      header: '¡Cursos pendientes!',
      subHeader: 'Hemos detectado que tienes los siguientes cursos pendientes de terminar:',
      message: text,
      htmlAttributes: {},
      inputs: [
        {
          name: 'doNotShowMoodleAlertAgain',
          type: 'checkbox',
          label: 'No volver a mostrar este mensaje',
          value: true,
          checked: false
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          cssClass: 'secondary',
          handler: data => {
            if (data && data[0] && data[0] === true) {
              this.storage.set('doNotShowMoodleAlertAgain', true);
            }
          }
        },
        {
          text: 'Ir a Cursos Conectimed',
          handler: data => {
            if (data && data[0] && data[0] === true) {
              this.storage.set('doNotShowMoodleAlertAgain', true);
            }
            this.router.navigate(['/multimedia/courses-and-congresses/tab/cursos-conectimed']);
          }
        }
      ]
    });
    const value = await this.storage.get('doNotShowMoodleAlertAgain');
    if (!(value === true)) {
      alert.present();
    }
  }

  private async alertUpdateAppAvailable() {
    const alert = await this.alertCtrl.create({
      header: 'Nueva Versión en tiendas',
      message:
        'Actualmente hay una nueva versión disponible de la aplicación, le recomendamos actualizarla desde la tienda.',
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel'
        },
        {
          text: 'Ir a la tienda',
          handler: () => {
            let appId = "1488809696";
            if (Capacitor.getPlatform() === 'android') {
              appId = "com.enacment.conectimed";
            }
            NativeMarket.openStoreListing({
              appId: appId,
            });
          }
        }
      ]
    });
    alert.present();
  }

  private async alertUpdateWebAppAvailable(version: string) {
    const alert = await this.alertCtrl.create({
      header: 'Nueva Versión',
      message: 'Actualmente hay una nueva versión disponible de la aplicación, le recomendamos actualice la aplicación',
      buttons: [
        {
          text: 'Cerrar',
          role: 'cancel'
        },
        {
          text: 'Actualizar',
          handler: () => {
            this.storage.set('pageVersion', version);
            location.reload();
          }
        }
      ]
    });
    alert.present();
  }

  public async alertConfirmLogout() {
    const alert = await this.alertCtrl.create({
      header: 'Cerrar la sesión',
      message: '¿Está seguro de cerrar la sesión?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Cerrar',
          handler: () => {
            this.logout();
          }
        }
      ]
    });

    alert.present();
  }

  /**
   * Toast methods
   */

  private async toastInAppBrowserError() {
    const toast = await this.toastCtrl.create({
      header: 'Puede que no dispongas de una aplicación compatiable para abrir este archivo.',
      message: '',
      position: 'top',
      buttons: [
        {
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  /**
  * Modal methods
  */

  public async modalPointsFeature() {
    const modal = await this.modalCtrl.create({
      component: ImgModalComponent,
      componentProps: {
        img: '/assets/img/avisoPuntosConectimed.jpeg'
      },
      cssClass: 'img-modal-class'
    });
    modal.present();
  }

  /**
  * Prompt methods
  */

  async actionMenu(action: string) {
    if (action) {
      switch (action) {
        case 'remove-new':
          await this.storage.set('removeNew', 'yes');
          this.removeNew = await this.storage.get('removeNew');
          break;
      }
    }
  }

  public loginAlert() {
    this.homeService.alertLoginSining();
  }

  public async modalLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent
    });
    modal.present();
  }

  private async termOfUseAlert() {
    const toast = await this.alertCtrl.create({
      header: "Términos y condiciones",
      message: "Acepto las nuevas condiciones de uso de Conectimed.",
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ver términos y condiciones',
          handler: () => {
            this.termOfUseComponent();
          }
        },
        {
          text: 'Acepto',
          handler: async () => {
            try {
              const ref = this.fireService.afs.doc(`users/${this.credService.credentials.uid}`).ref;
              await ref.update({ newConditionsOfUseAccepted: true });
            } catch (error) {
              log.error(error);
            }
          }
        }
      ]
    });
    toast.present();
  }

  private async termOfUseComponent(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: FormTermOfUseComponent,
      backdropDismiss: false,
      componentProps: { footer: true }
    });
    modal.onDidDismiss().then(async (ret) => {
      if (!(ret && ret.data && ret.data.acceptedTermsOfUse && ret.data.acceptedTermsOfUse === true)) {
        this.termOfUseComponent();
      } else {
        try {
          const ref = this.fireService.afs.doc(`users/${this.credService.credentials.uid}`).ref;
          await ref.update({ newConditionsOfUseAccepted: true });
        } catch (error) {
          log.error(error);
        }
      }
    });
    modal.present();
  }

  private async checkSurvery() {
    try {
      let topics = ['allDevices', this.credService.credentials.type];
      let surveys: any[] = [];
      const DATA = (await this.fireService.afs.doc(`users/${this.credService.credentials.uid}`).ref.get()).get(
        'filter-meta-data'
      );
      if (DATA) {
        topics = topics.concat(Array.from(DATA));
      }
      topics = topics.slice(0, 10);
      const COLL = this.fireService.afs.collection('surveys', ref =>
        ref.where('status', '==', 'active').where('topics', 'array-contains-any', topics)
      );
      COLL.snapshotChanges().subscribe(async snap => {
        const _surveys: any[] = snap.map(doc => {
          const DATA: any = doc.payload.doc.data();
          const ID: string = doc.payload.doc.id;
          return { id: ID, ...DATA };
        });
        for (let survey of _surveys) {
          if (survey && survey.id) {
            const exists: boolean = (
              await this.fireService.afs.doc(`surveys/${survey.id}/surveyed/${this.credService.credentials.uid}`).ref.get()
            ).exists;
            if (!exists === true) {
              surveys.push(survey);
            }
          }
        }
        for (let survey of surveys) {
          var find = '\n';
          var re = new RegExp(find, 'g');
          survey.text = survey.text.replace(re, '<br/>');
          this.presentAlert({
            text: String(survey.text),
            title: String(survey.title),
            url: String(survey.url),
            buttons: survey.buttons,
            id: survey.id
          });
        }
      });
    } catch (error) {
      log.error(error);
    }
  }

  private async presentAlert(survey: {
    url: string;
    id: string;
    text: string;
    title: string;
    buttons: {
      acept: { show: boolean; text: string };
      cancel: { show: boolean; text: string };
      'remember-later': { show: boolean; text: string };
    };
  }) {
    let buttons = [];

    //BUTTON_ACEPT
    if (survey && survey.buttons && survey.buttons.acept && survey.buttons.acept.show === true) {
      const BUTTON_ACEPT = {
        text:
          survey && survey.buttons && survey.buttons.acept && survey.buttons.acept.text
            ? String(survey.buttons.acept.text)
            : '',
        handler: () => {
          this.openBrowser(survey.url, survey.id);
        }
      };
      buttons.push(BUTTON_ACEPT);
    }

    //BUTTON_REMEMBER_LATER
    if (
      survey &&
      survey.buttons &&
      survey.buttons['remember-later'] &&
      survey.buttons['remember-later'].show === true
    ) {
      const BUTTON_REMEMBER_LATER = {
        text:
          survey && survey.buttons && survey.buttons['remember-later'] && survey.buttons['remember-later'].text
            ? String(survey.buttons['remember-later'].text)
            : '',
        role: 'cancel',
        handler: () => {
          log.debug(`remember-later(${survey.id})`);
        }
      };
      buttons.push(BUTTON_REMEMBER_LATER);
    }

    //BUTTON_CANCEL
    if (survey && survey.buttons && survey.buttons.cancel && survey.buttons.cancel.show === true) {
      const BUTTON_CANCEL = {
        text:
          survey && survey.buttons && survey.buttons.cancel && survey.buttons.cancel.text
            ? String(survey.buttons.cancel.text)
            : '',
        role: 'cancel-survey',
        cssClass: 'medium-cancel',
        handler: () => {
          this.answerSurvey(survey.id, false);
        }
      };
      buttons.push(BUTTON_CANCEL);
    }

    const alert = await this.alertCtrl.create({
      header: survey.title,
      message: survey.text,
      buttons: buttons
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    log.debug(`=== alert.onDidDismiss(${role}) ===`);
  }

  private answerSurvey(surveyId: string, answered: boolean): Promise<any> {
    try {
      return this.fireService.afs.doc(`surveys/${surveyId}/surveyed/${this.credService.credentials.uid}`).set({
        uid: this.credService.credentials.uid,
        answered: answered,
        date: moment().toDate()
      });
    } catch (error) {
      log.error(error);
      return null;
    }
  }

  openBrowser(url: string, surveyId: string) {
    try {
      this.platform
        .ready()
        .then(() => {
          if (this.platform.is('cordova') === true) {
            Browser.open({ url: url })
          } else {
            window.open(url, '_blank');
          }
          this.answerSurvey(surveyId, true);
        })
        .catch(error => {
          log.error(error);
        });
    } catch (error) {
      log.error(error);
    }
  }

}