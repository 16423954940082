import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MustMatch } from '@app/shared/helpers/must-match.validator';
import { FormTermOfUseComponent } from './form-term-of-use/form-term-of-use.component';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Router } from '@angular/router';
import { HiddenDataService } from '@app/shared/services/hidden-data/hidden-data.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('AccountDataFormComponent');

export interface AccountData {
  username: string;
  email: string;
  email2: string;
  password1: string;
  password2: string;
  termsAndConditions: boolean;
  type: string;
}

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-account-data-form',
  templateUrl: './account-data-form.component.html',
  styleUrls: ['./account-data-form.component.scss']
})
export class AccountDataFormComponent implements OnInit {
  @Input() data: AccountData;
  @Input() defaultUserSelection: string;
  @Output() response = new EventEmitter<UserData>();
  @Output() changeMemberType = new EventEmitter<string>();
  @ViewChild('passwordEyeRegister1', { static: false, read: ElementRef }) passwordEye1: ElementRef;
  @ViewChild('passwordEyeRegister2', { static: false, read: ElementRef }) passwordEye2: ElementRef;
  passwordTypeInput1 = 'password';
  passwordTypeInput2 = 'password';

  public dataForm: FormGroup;
  public isLoading: boolean;
  private userData: UserData;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private modalCtrl: ModalController,
    private utilities: UtilitiesService,
    private hiddenDataService: HiddenDataService,
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    public credService: CredentialsService
  ) { }

  togglePasswordMode1() {
    //cambiar tipo input
    this.passwordTypeInput1 = this.passwordTypeInput1 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye1.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  togglePasswordMode2() {
    //cambiar tipo input
    this.passwordTypeInput2 = this.passwordTypeInput2 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye2.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  ngOnInit() {
    this.initForm();
    this.initData();
    if (this.defaultUserSelection && this.defaultUserSelection === 'representante-medico') {
      this.dataForm.get('type').setValue('representante-medico');
      this.dataForm.updateValueAndValidity();
      this.changeType();
    }
  }

  initData() {
    this.dataForm.setValue({
      type: this.data && this.data.type ? this.data.type : 'medico',
      email: this.data && this.data.email ? this.data.email : '',
      email2: this.data && this.data.email2 ? this.data.email2 : '',
      password1: this.data && this.data.password1 ? this.data.password1 : '',
      password2: this.data && this.data.password2 ? this.data.password2 : '',
      termsAndConditions: false
    });
    this.changeType();
  }

  async termOfUseComponent(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: FormTermOfUseComponent
    });
    modal.present();
  }

  public initForm() {
    this.dataForm = this.formBuilder.group(
      {
        type: ['medico', [Validators.required]],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ]
        ],
        email2: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ]
        ],
        password1: ['', [Validators.required, Validators.minLength(6)]],
        password2: ['', Validators.required],
        termsAndConditions: [false, Validators.required]
      },
      {
        validator: [MustMatch('password1', 'password2'), MustMatch('email', 'email2')]
      }
    );
  }

  public async submit(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const email: string = String(this.dataForm.get('email').value);
      const password: string = String(this.dataForm.get('password1').value);

      const type: string = String(this.dataForm.get('type').value);

      const response = await this.fireService.createUser(email, password, type);
      if (response && response.user && response.user.uid) {
        this.hiddenDataService.setData('login', { email: email, pass: password });
        this.userData = {
          uid: response.user.uid,
          email: response.user.email
        };
        this.analyticsClickEvent('click', {
          content_type: 'Información de acceso',
          section_name: 'Registro de usuario',
          click_text: 'Siguiente',
          user_type: type
        });
        this.response.emit(this.userData);
      } else {
        if (response && response['code']) {
          const code = response['code'];
          switch (code) {
            case 'auth/invalid-email': {
              this.utilities.presentAlert(
                "El Email que intenta registrar no cumple con el formato: 'micorreo@dominio.com', por favor revise ésta información.",
                'Email inválido'
              );
              break;
            }
            case 'auth/weak-password': {
              this.utilities.presentAlert(
                'La contraseña que intenta ingresar es débil, debe contar con un mínimo de 6 caracteres.',
                'Contraseña débil'
              );
              break;
            }
            case 'auth/operation-not-allowed': {
              this.utilities.presentAlert(
                'Por el momento el método de autenticación esta desactivado, por favor contacte al soporte técnico de Conectimed.',
                'Contacte a soporte técnico'
              );
              break;
            }
            case 'auth/email-already-in-use': {
              this.utilities.presentAlert(
                'Ya existe una cuenta registrada con el correo (<strong>' +
                String(this.dataForm.get('email').value).toLowerCase() +
                "</strong>), si está seguro de que se trata de su correo o su proceso de registro no pudo ser completado, por favor, dirígase al login para completar su registro, si no recuerda su contraseña, favor de dar click en el botón '¿Olvidó su contraseña?'.",
                'La cuenta ya existe'
              );
              this.router.navigate([
                '/home/actions/open-login/' + String(this.dataForm.get('email').value).toLowerCase()
              ]);
              break;
            }
          }
        } else {
          this.utilities.presentAlert(
            'Ha ocurrido un error al intentar registrarse, por favor contacte al soporte técnico de Conectimed, código de error: ' +
            response['code'] +
            '.',
            'Contacte a soporte técnico'
          );
        }
      }
    } catch (error) {
      log.error('Catch error:', error);
      this.utilities.presentAlert(
        'Ha ocurrido un error al intentar registrarse, por favor contacte al soporte técnico de Conectimed o intentelo más tarde.',
        'Contacte a soporte técnico'
      );
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  changeType() {
    this.changeMemberType.emit(String(this.dataForm.get('type').value));
  }

  // async existInWP(email: string, password: string): Promise<Number> {
  //   try {
  //     const response = await this.wp.token(email, password);
  //     if (response.body.token) {
  //       return 1;
  //     }
  //   } catch (error: any) {
  //     if (error.error.code === '[jwt_auth] incorrect_password') {
  //       return 1;
  //     }
  //     //else {
  //     //   return 2;
  //     // }
  //   }
  //   return 3;
  // }

  public noWhiteSpaces(ev: any, field: string) {
    const white = new RegExp(/\s/g);
    if (white.test(String(ev.target.value || '')) === true) {
      const newString = String(ev.target.value || '').replace(/\s/g, '');
      this.dataForm.get(field).setValue(newString);
    }
  }
}
