import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Logger } from '@app/core/logger.service';
import { NoWhiteSpaceValidator } from '../../validators/noWhiteSpace.validator';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('ProfessionalDataFormComponent');

export interface PersonalData {
  name: string;
  lastName1: string;
  lastName2: string;
  cellPhone: string;
  phone: string;
}

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-professional-data-form',
  templateUrl: './professional-data-form.component.html',
  styleUrls: ['./professional-data-form.component.scss']
})
export class ProfessionalDataFormComponent implements OnInit {
  @Input() user: UserData;
  @Output() response = new EventEmitter<void>();
  @Input() button = 'Siguiente';
  public dataForm: FormGroup;
  public isLoading: boolean;
  specialties: any[];

  constructor(
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private specialtiesService: SpecialtiesService,
    private fireService: FirebaseService
  ) { }

  ngOnInit() { this.initializeApp(); }

  async initializeApp() {
    this.isLoading = true;
    this.loader.ionLoaderPresent();
    try {
      this.initForm();
      await this.initData();
      await this.initSpecialties();
    } catch (error) {
      log.error(error);
    }
    this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async initSpecialties(): Promise<void> {
    try {
      this.specialties = await this.specialtiesService.getSpecialties();
    } catch (error) {
      log.error(error);
    }
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      specialty1: ['', Validators.required],
      professionalID1: ['', [Validators.required, NoWhiteSpaceValidator.isValid]],
      specialty2: [''],
      professionalID2: ['', [NoWhiteSpaceValidator.isValid]]
    });
  }

  refillForm(data: any) {
    this.dataForm.setValue({
      specialty1: data && data.specialty1 && data.specialty1.id ? data.specialty1.id : 0,
      specialty2: data && data.specialty2 && data.specialty2.id ? data.specialty2.id : 0,
      professionalID1: data && data.specialty1 && data.specialty1.cedula ? data.specialty1.cedula : '',
      professionalID2: data && data.specialty2 && data.specialty2.cedula ? data.specialty2.cedula : ''
    });
  }

  especialty(ev: any) {
    if (ev.target.value > 0) {
      this.dataForm.get('specialty2').setValidators([Validators.required]);
      this.dataForm.get('professionalID2').setValidators([Validators.required]);
    } else {
      this.dataForm.get('specialty2').clearValidators();
      this.dataForm.get('professionalID2').clearValidators();
      this.dataForm.get('professionalID2').setValue('');
    }
  }

  public async initData(): Promise<void> {
    try {
      const data = await this.fireService.getDoctorData(this.user.uid);
      this.refillForm(data);
    } catch (error) {
      log.error(error);
    }
  }

  public async submit(): Promise<void> {
    try {
      await this.fireService.updateDoctor(this.user.uid, {
        specialty1: {
          id: Number(this.dataForm.get('specialty1').value),
          cedula: this.dataForm.get('professionalID1').value
        },
        specialty2: {
          id: Number(this.dataForm.get('specialty2').value),
          cedula: this.dataForm.get('professionalID2').value
        }
      });
      this.response.emit();
    } catch (error) {
      log.error(error);
    }
  }
}
