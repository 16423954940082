import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {
  constructor(private fireStorage: AngularFireStorage) { }

  async uploadImage(file: File, thumbnailSrc: any, middleSrc: any) {
    let image = {
      main: {
        url: '',
        path: ''
      },
      thumbnail: {
        url: '',
        path: ''
      },
      list: {
        url: '',
        path: ''
      }
    };
    const random = moment()
      .toDate()
      .getMilliseconds();
    const name = random + file.name;
    var storageRef1 = this.fireStorage.ref('avatars/' + name);
    const imageResponse1 = await storageRef1.put(file);
    const downloadURL1 = await imageResponse1.ref.getDownloadURL();
    image.main.url = downloadURL1;
    image.main.path = 'avatars/' + name;
    var storageRef2 = this.fireStorage.ref('avatars/thumbnail/' + name);
    const imageResponse2 = await storageRef2.putString(thumbnailSrc, 'data_url');
    const downloadURL2 = await imageResponse2.ref.getDownloadURL();
    image.thumbnail.url = downloadURL2;
    image.thumbnail.path = 'avatars/thumbnail/' + name;
    var storageRef3 = this.fireStorage.ref('avatars/list/' + name);
    const imageResponse3 = await storageRef3.putString(middleSrc, 'data_url');
    const downloadURL3 = await imageResponse3.ref.getDownloadURL();
    image.list.url = downloadURL3;
    image.list.path = 'avatars/list/' + name;
    return image;
  }

  async readURL(event: any, imageSrc: any, thumbnailSrc: any, middleSrc: any) {
    if (event) {
      const file = event;
      const reader = new FileReader();
      reader.onload = () => (imageSrc = reader.result);
      reader.readAsDataURL(file);
      const toBase64 = (file: any) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      const result: string = String(await toBase64(file).catch(e => Error(e)));
      thumbnailSrc = await this.thumbnailify(result, 100, 100);
      middleSrc = await this.thumbnailify(result, 320, 170);
      return { imageSrc, thumbnailSrc, middleSrc, file };
    } else {
      return false;
    }
  }

  async readURLFromDataUrl(event: any, imageSrc: any, thumbnailSrc: any, middleSrc: any) {
    if (event) {
      const result: any = event;
      thumbnailSrc = await this.thumbnailify(result.dataUrl, 100, 100);
      middleSrc = await this.thumbnailify(result.dataUrl, 320, 320);
      return { imageSrc, thumbnailSrc, middleSrc, file: event };
    } else {
      return false;
    }
  }

  async thumbnailify(base64Image: string, targetWidth: number, targetHeight: number) {
    var img = new Image();
    const newImage = () =>
      new Promise((resolve, reject) => {
        img.onload = () => {
          var width = img.width,
            height = img.height,
            canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          ctx.drawImage(
            img,
            width > height ? (width - height) / 2 : 0,
            height > width ? (height - width) / 2 : 0,
            width > height ? height : width,
            width > height ? height : width,
            0,
            0,
            targetWidth,
            targetHeight
          );
          resolve(canvas.toDataURL());
        };
        img.onerror = error => reject(error);
      });
    img.src = base64Image;
    return newImage();
  }

  async chatFBUpload(file: File, chatId: string) {
    let image = {
      main: {
        url: '',
        path: ''
      }
    };
    var storageRef1 = this.fireStorage.ref('chats/' + chatId + '/' + file.name);
    const imageResponse1 = await storageRef1.put(file);
    const downloadURL1 = await imageResponse1.ref.getDownloadURL();
    image.main.url = downloadURL1;
    image.main.path = 'chats/' + chatId + '/' + file.name;
    return image;
  }

  async productFBFileUpload(file: File, productId: string, isImage: boolean) {
    let storagePath = isImage === true ? 'products/images/' : 'products/docs/';
    let doc = {
      main: {
        url: '',
        path: ''
      }
    };
    var storageRef1 = this.fireStorage.ref(storagePath + productId + '/' + file.name);
    const docResponse = await storageRef1.put(file);
    const downloadURL1 = await docResponse.ref.getDownloadURL();
    doc.main.url = downloadURL1;
    doc.main.path = storagePath + productId + '/' + file.name;
    return doc;
  }

  async deleteFile(url: string): Promise<any> {
    return await this.fireStorage.storage.refFromURL(url).delete();
  }
}
