import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileFormatService {
  public maxFileSize: number = 8388608;

  private documentFormats: string[] = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf'];

  private documentTypes: string[] = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf'
  ];

  private imageTypes: string[] = ['image/jpeg', 'image/png'];

  private imageFormats: string[] = ['png', 'jpg', 'jpeg'];

  constructor() {}

  validateAll(format: string): boolean {
    const validate1: number = this.documentFormats.indexOf(String(format).toLowerCase());
    const validate2: number = this.imageFormats.indexOf(String(format).toLowerCase());
    if (validate1 > -1 || validate2 > -1) {
      return true;
    }
    return false;
  }

  validateAllTypes(format: string): boolean {
    const validate1: number = this.documentTypes.indexOf(String(format).toLowerCase());
    const validate2: number = this.imageTypes.indexOf(String(format).toLowerCase());
    if (validate1 > -1 || validate2 > -1) {
      return true;
    }
    return false;
  }

  validateDocument(format: string): boolean {
    const validate: number = this.documentFormats.indexOf(String(format).toLowerCase());
    if (validate > -1) {
      return true;
    }
    return false;
  }

  validateDocumentType(format: string): boolean {
    const validate: number = this.documentTypes.indexOf(String(format).toLowerCase());
    if (validate > -1) {
      return true;
    }
    return false;
  }

  validateImage(format: string): boolean {
    const validate: number = this.imageFormats.indexOf(String(format).toLowerCase());
    if (validate > -1) {
      return true;
    }
    return false;
  }

  validateImageType(format: string): boolean {
    const validate: number = this.imageTypes.indexOf(String(format).toLowerCase());
    if (validate > -1) {
      return true;
    }
    return false;
  }

  validatePDFType(format: string): boolean {
    if (format === 'application/pdf') {
      return true;
    }
    return false;
  }
}
