import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { Logger } from '@app/core/logger.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
const log = new Logger('FormTermOfUseComponent');

@Component({
  selector: 'app-form-term-of-use',
  templateUrl: './form-term-of-use.component.html',
  styleUrls: ['./form-term-of-use.component.scss']
})
export class FormTermOfUseComponent implements OnInit {
  public item: any;
  footer: boolean = false;

  constructor(
    private fireService: FirebaseService,
    private utilities: UtilitiesService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.getDetail();
  }

  async getDetail() {
    try {
      const response1 = await this.fireService.afs
        .collection('pages').ref.where('slug', '==', 'terminos-y-condiciones').get();
      if (response1 && response1.empty === false) {
        const data: any = response1.docs[0].data();
        let newHtml = this.utilities.replaceInHtml(data.content);
        this.item = {
          title: data.title,
          content: this.utilities.trustHtml(newHtml)
        }
      }
    } catch (error) {
      log.error(error);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  accept() {
    try {
      this.modalCtrl.dismiss({ acceptedTermsOfUse: true });
    } catch (error) {
      log.error;
    }
  }
}
