import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { UserSelectorComponent } from './user-selector.component';

@NgModule({
  declarations: [UserSelectorComponent],
  exports: [UserSelectorComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule, IonicModule, RouterModule]
})
export class UserSelectorModule {}
