import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';

export interface UserSessionData {
  userId: number;
  token: string;
}

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit {
  @Input() user: UserSessionData | any;
  @Input() button: string = 'Siguiente';
  @Output() response = new EventEmitter<void>();
  public form1: boolean;
  public form2: boolean;
  public saved1: boolean;
  public saved2: boolean;

  public submit: Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit() { }

  emitEventToChild() {
    this.submit.next();
  }

  onResponse1() {
    this.saved1 = true;
    this.emitEvent();
  }

  onResponse2() {
    this.saved2 = true;
    this.emitEvent();
  }

  emitEvent() {
    if (this.saved1 == true && this.saved2 == true) {
      this.response.emit();
    }
  }
}
