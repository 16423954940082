<ion-content>
  <ion-grid [fixed]="false">
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <ion-card-content>
            <!-- Elements -->
            <ion-item *ngFor="let item of companies" [detail]="true" [button]="true"
              [routerLink]="[item?.routing?.route]">
              <ion-avatar slot="start" *ngIf="item?.logo">
                <img [src]="item?.logo" />
              </ion-avatar>
              <ion-avatar class="name-logo" *ngIf="!item?.logo" slot="start">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
                  style="
                        shape-rendering: geometricPrecision;
                        text-rendering: geometricPrecision;
                        image-rendering: optimizeQuality;
                        fill-rule: evenodd;
                        clip-rule: evenodd;
                      " viewBox="0 0 500 500">
                  <g id="UrTavla">
                    <circle style="fill: #d9d9d9" cx="250" cy="250" r="245"></circle>
                    <text x="50%" y="50%" text-anchor="middle" dy=".3em" style="font-size: 180px; font-family: Arial">
                      {{ item?.nameLogo }}
                    </text>
                  </g>
                </svg>
              </ion-avatar>
              <ion-label>
                <h1>{{ item?.name }}</h1>
                <p>{{ item?.businessType }}</p>
              </ion-label>
            </ion-item>
            <!-- No results -->
            <div *ngIf="!isLoading === true && !companies[0]">
              <app-i-no-results></app-i-no-results>
            </div>
            <!-- Skeleton loading -->
            <div *ngIf="isLoading === true">
              <ion-item *ngFor="let o of [0, 0, 0]" class="animate__animated animate__fadeIn" [button]="true"
                [detail]="true">
                <ion-avatar slot="start">
                  <ion-skeleton-text [animated]="true"></ion-skeleton-text>
                </ion-avatar>
                <ion-label>
                  <h1><ion-skeleton-text [animated]="true" style="width: 35%;"></ion-skeleton-text></h1>
                  <p><ion-skeleton-text [animated]="true" style="width: 90%;"></ion-skeleton-text></p>
                </ion-label>
              </ion-item>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- Infinite scroll (get next elements) -->
  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)" [disabled]="isLoading === true">
    <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Cargando información...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>