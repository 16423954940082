import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { ActivatedRoute } from '@angular/router';
import { MustMatch } from '@app/shared/helpers/must-match.validator';
import { Logger } from '@app/core/logger.service';
const log = new Logger('VerificationCodeComponent');
import { App } from '@capacitor/app';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss']
})
export class VerificationCodeComponent implements OnInit {
  @ViewChild('passwordEyeRegister1', { static: false, read: ElementRef }) passwordEye1: ElementRef;
  @ViewChild('passwordEyeRegister2', { static: false, read: ElementRef }) passwordEye2: ElementRef;
  passwordTypeInput1 = 'password';
  passwordTypeInput2 = 'password';
  verificationCodeForm!: FormGroup;
  isLoading = false;
  subscription: Subscription;
  counter: number = 0;
  randomChain: string;
  canGoLogin: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private platform: Platform,
    private toastCtrl: ToastController,
    private utilities: UtilitiesService,
    private aRoute: ActivatedRoute
  ) {
    this.initializeApp();
  }

  togglePasswordMode1() {
    //cambiar tipo input
    this.passwordTypeInput1 = this.passwordTypeInput1 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye1.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  togglePasswordMode2() {
    //cambiar tipo input
    this.passwordTypeInput2 = this.passwordTypeInput2 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye2.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  initializeApp() {
    this.aRoute.params.subscribe(params => {
      if (params && params.email && params.email !== '') {
        this.verificationCodeForm.get('username').setValue(params.email);
      }
    });
    this.createForm();
    this.randomChain = this.utilities.randomChain(6);
  }

  private createForm() {
    this.verificationCodeForm = this.formBuilder.group(
      {
        username: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ]
        ],
        code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
        password1: ['', [Validators.required, Validators.minLength(6)]],
        password2: ['', Validators.required]
      },
      {
        validator: MustMatch('password1', 'password2')
      }
    );
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe(() => {
      if (this.counter == 0) {
        this.counter++;
        this.presentToast();
        setTimeout(() => {
          this.counter = 0;
        }, 3000);
      } else {
        App.exitApp();
      }
    });
  }

  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Presione nuevamente para salir.',
      duration: 3000
    });
    toast.present();
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ngOnInit() { }

  ngOnDestroy() { }

  async changePassword() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    await this.loader.ionLoaderDismiss();
  }
}
