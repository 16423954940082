<ion-list>
  <ion-button
    color="danger"
    (click)="clear()"
    fill="outline"
    expand="block"
    shape="round"
    *ngIf="item"
    class="ion-margin"
  >
    <ion-icon slot="start" name="close-outline"></ion-icon>
    Remover filtro
  </ion-button>
  <ion-item *ngFor="let item of items" lines="none" button (click)="filter(item)" mode="md">
    <ion-icon slot="start" [name]="item?.icon"></ion-icon>
    <ion-label>{{ item?.label }}</ion-label>
    <ion-icon slot="end" name="checkmark-outline" *ngIf="item?.checked === true"></ion-icon>
  </ion-item>
  <ion-item-group *ngIf="isDate === true">
    <ion-item-divider>
      <ion-label>Establece el rango de fechas:</ion-label>
    </ion-item-divider>
    <ion-item>
      <ion-label>Inicio</ion-label>
      <ion-datetime pickerFormat="YYYY-MM-DD" displayFormat="YYYY-MM-DD" [max]="fin?.date" [(ngModel)]="inicio.date">
      </ion-datetime>
      <ion-datetime pickerFormat="HH:mm" displayFormat="HH:mm:ss" [(ngModel)]="inicio.time"></ion-datetime>
    </ion-item>
    <ion-item lines="no">
      <ion-label>Fin</ion-label>
      <ion-datetime
        pickerFormat="YYYY-MM-DD"
        displayFormat="YYYY-MM-DD"
        [min]="inicio?.date"
        [max]="fin?.date"
        [(ngModel)]="fin.date"
      ></ion-datetime>
      <ion-datetime pickerFormat="HH:mm" displayFormat="HH:mm:ss" [(ngModel)]="fin.time"></ion-datetime>
    </ion-item>
    <ion-button (click)="filterDate()" expand="block" shape="round" class="ion-margin">
      Filtrar
    </ion-button>
  </ion-item-group>
</ion-list>
