<ion-card class="card-post" [button]="true" class="ion-text-wrap">
  <ion-toolbar color="primary" *ngIf="!!toolbar === true" class="ion-text-wrap">
    <ion-title class="ion-text-wrap">{{ item?.title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="favorite()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-card-header *ngIf="!!!toolbar === true" color="primary" [routerLink]="[item?.routing?.route]">
    <ion-card-title>{{ item?.title }}</ion-card-title>
  </ion-card-header>
  <ion-card-content class="ion-no-padding" [routerLink]="[item?.routing?.route]">
    <ion-grid [fixed]="false" class="ion-no-padding">
      <ion-row class="ion-align-items-center ion-no-padding">
        <ion-col sizeXs="12" sizeMd="5" sizeLg="5" sizeXl="5" class="ion-no-padding">
          <img [src]="item?.image" />
        </ion-col>
        <ion-col sizeXs="12" sizeMd="7" sizeLg="7" sizeXl="7" class="ion-padding ion-text-justify">
          {{ item?.excerpt | slice : 0 : 250 }}...
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
  <ion-toolbar color="light">
    <app-user-social-media-options *ngIf="credService?.credentials?.email" [item]="item" [favoriteCheck]="false" [view]="2"
      slot="end"></app-user-social-media-options>
  </ion-toolbar>
</ion-card>