import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { AlertController, ModalController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
import { LadaService } from '@app/shared/services/lada/lada.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('UpdatePhoneComponent');

@Component({
  selector: 'app-update-phone',
  templateUrl: './update-phone.component.html',
  styleUrls: ['./update-phone.component.scss']
})
export class UpdatePhoneComponent implements OnInit {
  public isLoading: boolean = false;
  public phone = {
    lada: '52',
    color: 'light',
    country: 'MEXICO',
    error: false
  };
  public dataForm: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private loader: IonLoaderService,
    private formBuilder: FormBuilder,
    private authServe: AuthenticationService,
    private fireService: FirebaseService,
    private utilities: UtilitiesService,
    private alertCtrl: AlertController,
    public lada: LadaService
  ) {
    this.initForm();
  }

  ngOnInit() { }

  public closeModal(role?: string) {
    this.modalCtrl.dismiss({}, role);
  }

  phoneHasError(ev: boolean) {
    if (ev) {
      this.phone.color = 'light';
      this.phone.error = false;
    } else {
      this.phone.color = 'danger';
      this.phone.error = true;
    }
  }

  private initForm() {
    this.dataForm = this.formBuilder.group({
      mobile: ['', [Validators.required]],
      mobileLada: ['52', [Validators.required]]
    });
  }

  public async submit() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const mobile: string = String(`${String(this.dataForm.get('mobileLada').value).trim()}${String(this.dataForm.get('mobile').value).trim()}`);
      let data = {
        mobile: mobile,
        mobileLada: {
          number: String(this.dataForm.get('mobile').value).trim(),
          lada: String(this.dataForm.get('mobileLada').value).trim(),
          country: this.phone.country
        }
      };
      if (this.dataForm && this.dataForm.get('mobile') && this.dataForm.get('mobile').value !== '') {
        const authState = await this.authServe.getUser();
        await this.fireService.afs
          .collection('users')
          .doc(authState.uid)
          .update(data);
        this.closeModal('updated');
        this.utilities.toast('Teléfono actualizado correctamente.', 'Actualizar número telefónico');
      } else {
        this.alertError('El nuevo teléfono es incorrecto.');
      }
    } catch (error) {
      log.error(error);
      this.alertError('Ha ocurrido un error, por favor intentalo mas tarde.');
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async alertError(msg: string) {
    const alert = await this.alertCtrl.create({
      header: 'Ha ocurrido un error',
      message: msg,
      buttons: [
        {
          text: 'Ok'
        }
      ]
    });
    alert.present();
  }
}
