import { HttpService } from '@app/shared/services/http/http.service';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class MoodleService {

  constructor(
    private http: HttpService
  ) { }

  courseGetContents(courseid: string) {
    const url = `${environment.MOODLE_JSON_URL}&wsfunction=core_course_get_contents&courseid=${courseid}`;
    return this.http.get(url);
  }

  courseGetCoursesByField(field: any, value: any) {
    const url = `${environment.MOODLE_JSON_URL}&wsfunction=core_course_get_courses_by_field&field=${field}&value=${value}`;
    return this.http.get(url);
  }

  courseGetCourses() {
    const url = `${environment.MOODLE_JSON_URL}&wsfunction=core_course_get_courses`;
    return this.http.get(url);
  }

  userGetUsersByEmail(email: string) {
    const url = `${environment.MOODLE_JSON_URL}&wsfunction=core_user_get_users&criteria[0][key]=email&criteria[0][value]=${email}`;
    return this.http.get(url);
  }

  userCreateUsers(username: string, email: string, password: string, firstname: string, lastname: string) {
    const url = `${environment.MOODLE_JSON_URL}&wsfunction=core_user_create_users&users[0][username]=${username}&users[0][password]=${password}&users[0][firstname]=${firstname}&users[0][lastname]=${lastname}&users[0][email]=${email}`;
    return this.http.get(url);
  }

  manualEnrolUsers(userid: string, courseid: string, roleid: string) {
    const url = `${environment.MOODLE_JSON_URL}&wsfunction=enrol_manual_enrol_users&enrolments[0][roleid]=${roleid}&enrolments[0][userid]=${userid}&enrolments[0][courseid]=${courseid}`;
    return this.http.get(url);
  }

  enrolGetUsersCourses(userid: string) {
    const url = `${environment.MOODLE_JSON_URL}&wsfunction=core_enrol_get_users_courses&userid=${userid}`;
    return this.http.get(url);
  }

  getToken(username: string, password: string) {
    const url = `${environment.MOODLE_URL}&username=${username}&password=${password}`;
    return this.http.get(url);
  }

  getKey(email: string) {
    const url = `${environment.MOODLE_JSON_URL_TWO}&wsfunction=auth_userkey_request_login_url&user[email]=${email}`;
    return this.http.get(url);
  }

  coreCompletionGetActivitiesCompletionStatus(courseid: any, userid: any) {
    const url = `${environment.MOODLE_JSON_URL}&wsfunction=core_completion_get_activities_completion_status&courseid=${courseid}&userid=${userid}`;
    return this.http.get(url);
  }

  coreCourseGetEnrolledCoursesByTimelineClassification(token: any, classification: any) {
    const url = `${environment.MOODLE_JSON_URL_SINGLE}${token}&wsfunction=core_course_get_enrolled_courses_by_timeline_classification&classification=${classification}`;
    return this.http.get(url);
  }
}
