import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';

@Component({
  selector: 'app-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss']
})
export class SharingComponent implements OnInit {
  public route: string;
  public url: string;
  public urlSanitized: SafeUrl;

  constructor(private ARoute: ActivatedRoute, private platform: Platform, private sanitizer: DomSanitizer) {
    this.ARoute.params.subscribe(params => {
      this.route = undefined;
      this.url = undefined;
      if (params && params.url) {
        this.route = atob(params.url);
        this.generateURL(this.route);
      }
    });
  }

  generateURL(route: string) {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        let url = route.replace(environment.DEEPLINK_URL, '');
        window.location.replace(url);
      } else {
        if (this.platform.is('android') && this.platform.is('mobileweb')) {
          this.url = `intent://${route}#Intent;scheme=https;package=com.enacment.conectimed;end`;
        } else {
          this.url = `https://${route}`;
        }
        this.urlSanitized = this.sanitizer.bypassSecurityTrustUrl(this.url);
        let element: HTMLElement = document.getElementById('conectimedButton') as HTMLElement;
        setTimeout(() => {
          element.click();
        }, 1500);
      }
    });
  }

  ngOnInit() {}
}
