<ion-header>
  <ion-toolbar *ngIf="step === 1">
    <ion-title>Mis productos</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="generate()" color="primary" [disabled]="!canContinue === true || isLoading === true">
        Continuar
        <ion-icon slot="end" name="arrow-forward-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar *ngIf="step === 2">
    <ion-title>Mis productos</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="refresh()" color="danger" [disabled]="isLoading === true">
        <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
        Regresar
      </ion-button>
      <ion-button (click)="download()" color="tertiary" [disabled]="isLoading === true">
        Descargar
        <ion-icon slot="end" name="download-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <div *ngIf="step === 1">
          <ion-list>
            <ion-item>
              <ion-text color="primary">
                <p><strong>Seleccionar todos</strong></p>
              </ion-text>
              <ion-checkbox slot="end" [(ngModel)]="masterCheck" [indeterminate]="isIndeterminate"
                (click)="checkMaster()"></ion-checkbox>
            </ion-item>
          </ion-list>
          <ion-list>
            <ion-item *ngFor="let item of products">
              <ion-thumbnail>
                <img [src]="item.image" />
              </ion-thumbnail>
              <ion-label class="ion-padding">
                <h3>
                  <strong>{{ item?.title }}</strong>
                </h3>
                <div>
                  <p *ngIf="item?.producto_sustancia_activa_1 || item?.producto_sustancia_activa_2">
                    Sustancias activas:
                    <span *ngIf="item?.producto_sustancia_activa_1 as active">{{ active }}</span>
                    <span *ngIf="item?.producto_sustancia_activa_2 as active">, {{ active }}</span>
                  </p>
                  <p *ngIf="item?.linea_terapeutica_1 || item?.linea_terapeutica_2">
                    Línea:
                    <span *ngIf="item?.linea_terapeutica_1">{{ item?.linea_terapeutica_1 }}</span>
                    <span *ngIf="item?.linea_terapeutica_2">, {{ item?.linea_terapeutica_2 }}</span>
                  </p>
                  <p *ngIf="item?.presentacion_1 || item?.presentacion_2">
                    Presentaciónes:
                    <span *ngIf="item?.presentacion_1">{{ item?.presentacion_1 }}</span>
                    <span *ngIf="item?.presentacion_2">, {{ item?.presentacion_2 }}</span>
                  </p>
                </div>
              </ion-label>
              <ion-checkbox (click)="canContinueFunc($event)" [(ngModel)]="item.isChecked" slot="end"></ion-checkbox>
            </ion-item>
          </ion-list>
        </div>
        <div *ngIf="step === 2"
          style="width: 100%; margin: 0 auto; display: block; position: relative; overflow-x: scroll;">
          <div
            style="width: 1240px; position: relative; display: block; padding: 20px;background-color: white; border: 1px solid black;">
            <div #header1 id="header1" style="background: url('/assets/img/gray-bg.png');" *ngIf="isWeb === true">
              <table width="100%" border="0" cellspacing="0" cellpadding="0" style="width: 100%;">
                <tbody>
                  <tr>
                    <th colspan="2">
                      <table width="100%" border="0" cellspacing="0" cellpadding="0" style="width: 100%;">
                        <tbody>
                          <tr>
                            <td style="width:55%">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                  <tr>
                                    <td colspan="2" style="text-align: left;">
                                      <div class="cstm-header1">
                                        MANTENGA CONTACTO CON SU REPRESENTANTE DANDO
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="text-align: left;">
                                      <div class="cstm-header2">
                                        <a href="{{ APP_URL }}/user/details/{{ credService.credentials.uid }}">
                                          CLICK AQUI</a>
                                      </div>
                                    </td>
                                    <td>
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style="width:45%">
                              <h2 class="cstm-title">{{ COMPANY_NAME }}</h2>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </th>
                    <th>
                      <div class="cstm-logo-wrapper">
                        <img crossorigin="anonymous" #logo id="logo" src="{{ COMPANY_LOGO }}" alt="{{ COMPANY_NAME }}"
                          class="cstm-img-fluid" />
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>

            <div id="contentToConvert" [innerHTML]="sanitizedHtml" style=" position: relative;width: 100%;"></div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>