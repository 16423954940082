import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Platform } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { NotificationsInterface } from '../../models/notifications';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/FCM';
import { Storage } from '@ionic/storage-angular';
import * as moment from 'moment';
import { Observable, lastValueFrom } from 'rxjs';
import { Logger } from '@app/core/logger.service';
const log = new Logger('NotificationService');

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private fireService: FirebaseService,
    private credService: CredentialsService,
    private platform: Platform,
    private afm: AngularFireMessaging,
    private utilities: UtilitiesService,
    private storage: Storage,
    private func: AngularFireFunctions
  ) { }

  public notificationInit(): void {
    if (this.platform.is('capacitor')) {
      this.notificationToken();
    } else {
      this.requestPermission();
    }
  }

  public async sendNotification(id: string, type: | 'chat' | 'sample-request' | 'add-click-repre' | 'add-click-product', data?: any) {
    try {
      const myData: any = (await this.fireService.afs.collection('users').doc(this.credService.credentials.uid).ref.get()).data() || undefined;
      const otherData: any = (await this.fireService.afs.collection('users').doc(id).ref.get()).data() || undefined;

      let title: string;
      let title2: string = null;
      let object_id = null;
      let notUser1: boolean = false;
      let notUser2: boolean = false;

      switch (type) {
        case 'chat':
          title = `${myData.name} le ha enviado un mensaje`;
          title2 = `Usted le ha enviado un mensaje a ${otherData.name}`;
          notUser1 = true;
          notUser2 = false;
          break;
        case 'sample-request':
          title = `${myData.name} le ha enviado una solicitud de muestra`;
          title2 = `Usted le ha enviado una solicitud de muestra a ${otherData.name}`;
          object_id = data.productId;
          notUser1 = true;
          notUser2 = true;
          break;
        case 'add-click-repre':
          title = `${myData.name} se interesó en contactarle`;
          break;
        case 'add-click-product':
          title = `${myData.name} se interesó en el producto: ${data.name}`;
          object_id = data.id;
          break;
      }

      if (type === 'sample-request') {
        if (notUser1 === true) {
          this.notificationList(title, type, data.chatId, id);
        }
        if (notUser2 === true) {
          this.notificationList(title2, type, data.chatId, this.credService.credentials.uid);
        }
      } else {
        if (notUser1 === true) {
          this.notificationList(title, type, data, id);
        }
        if (notUser2 === true) {
          this.notificationList(title2, type, data, this.credService.credentials.uid);
        }
      }

      if (myData && otherData) {
        if (!data) {
          data = {};
        }

        if (otherData.deviceMobileToken) {
          if (type === 'sample-request') {
            this.sendPushNotificationHandler(title, '', otherData.deviceMobileToken, type, data.chatId, 'mobile', id);
          } else if (type === 'add-click-repre') {
            this.sendPushNotificationHandler(title, '', otherData.deviceMobileToken, type, this.credService.credentials.uid, 'mobile', id);
          } else if (type === 'add-click-product') {
            this.sendPushNotificationHandler(title, '', otherData.deviceMobileToken, type, this.credService.credentials.uid, 'mobile', id);
          }
        }
        if (otherData.deviceWebToken) {
          if (type === 'sample-request') {
            this.sendPushNotificationHandler(title, '', otherData.deviceWebToken, type, data.chatId, 'web', id);
          } else if (type === 'add-click-repre') {
            this.sendPushNotificationHandler(title, '', otherData.deviceWebToken, type, this.credService.credentials.uid, 'web', id);
          } else if (type === 'add-click-product') {
            this.sendPushNotificationHandler(title, '', otherData.deviceWebToken, type, this.credService.credentials.uid, 'web', id);
          }
        }

        if (title2) {
          // for me
          await this.fireService.afs.collection('activity').add({
            date: moment().toDate(),
            title: title2,
            user_id: this.credService.credentials.uid,
            other_id: id,
            viewed: false,
            type,
            object_id
          });
        }
        // for the other
        await this.fireService.afs.collection('activity').add({
          date: moment().toDate(),
          title,
          user_id: id,
          other_id: this.credService.credentials.uid,
          viewed: false,
          type,
          object_id
        });
      }
    } catch (error) {
      log.error(error);
    }
  }

  private requestPermission() {
    if (this.credService.credentials && this.credService.credentials.uid) {
      this.afm.requestPermission.subscribe((permission) => {
        if (permission === 'granted') {
          this.afm.tokenChanges.subscribe(async (token) => {
            try {
              const deviceToken = token;
              await this.deleteOtherTokens('deviceWebToken', deviceToken);
              const userId = this.credService.credentials.uid;
              await this.fireService.afs
                .collection('users')
                .doc(userId)
                .update({ deviceWebToken: deviceToken });
              const resp = await this.storage.get('fcmTopics');
              const fcmTopics: string[] = resp ? resp : [];
              for (const topic of fcmTopics) {
                this.unsubscribeToTopicWeb(topic, token);
              }
              this.storage.set('fcmTopics', []);
              if (
                this.credService &&
                this.credService.credentials &&
                this.credService.credentials.uid
              ) {
                const data = await this.fireService.getUserData(this.credService.credentials.uid);
                const topics: string[] = data && data['filter-meta-data'] ? Array.from(data['filter-meta-data']) : [];
                //topics subscribe
                let storageTopics: String[] = [];
                for (const topic of topics) {
                  if (topic && topic !== '') {
                    const finalTopic: string = this.utilities.removeAccents(String(topic)).replace(/\s/g, '');
                    this.subscribeToTopicWeb(finalTopic, token);
                    storageTopics.push(finalTopic);
                    // log.debug(finalTopic);
                  }
                }
                this.subscribeToTopicWeb('allWebDevices', token);
                if (this.userTest()) {
                  this.subscribeToTopicWeb('allWebDevicesForTest', token);
                }
                this.storage.set('fcmTopics', storageTopics);
              }
            } catch (error) {
              log.error(error);
            }
          });
        }
      });
    }
  }

  private subscribeToTopicWeb(topic: any, token: any) {
    this.func
      .httpsCallable('subscribeToTopic')({ topic: topic, token: token })
      .pipe(
        tap(_ => {
          log.debug(`Subscribed to ${topic}`);
        })
      )
      .subscribe();
  }

  private unsubscribeToTopicWeb(topic: any, token: any) {
    this.func
      .httpsCallable('unsubscribeFromTopic')({ topic: topic, token: token })
      .pipe(
        tap(_ => {
          log.debug(`Unsubscribed to ${topic}`);
        })
      )
      .subscribe();
  }

  public async sendPushNotificationHandler(
    title: string,
    body: string,
    token: string,
    type: string,
    data: any,
    device: string,
    uid: string
  ): Promise<any> {
    let pushNotification: any;
    let newCount: number = 1;
    try {
      const count: number = await this.fireService.getUserBages(uid);
      if (count) {
        if (device === 'web') {
          newCount = count;
        } else {
          newCount = count + 1;
        }
      }
      this.fireService.updateUserBages(uid, newCount);
    } catch (error) {
      log.error(error);
    }
    const base64 = btoa(data);
    const LINK_WEB = environment.APP_URL + '/notifications/' + type + '/' + base64;

    pushNotification = {
      message: {
        token: token,
        notification: {
          title: title,
          body: body,
          image: 'https://app.conectimed.site/assets/img/logo.png'
        },
        android: {
          notification: {
            title: title,
            body: body
          },
          data: {
            type: type,
            data: data
          },
          fcm_options: {
            analytics_label: 'push_notification_conectimed_test_label_one'
          }
        },
        webpush: {
          notification: {
            title: title,
            body: body,
            click_action: LINK_WEB
          },
          fcm_options: {
            link: LINK_WEB,
            analytics_label: 'push_notification_conectimed_test_label_one'
          }
        },
        apns: {
          fcm_options: {
            image: 'https://app.conectimed.site/assets/img/logo.png',
            analytics_label: 'push_notification_conectimed_test_label_one'
          }
        },
        fcm_options: {
          analytics_label: 'push_notification_conectimed_test_label_one'
        },
        data: {
          type: type,
          data: data
        }
      }
    };
    try {
      log.debug(`========== pushNotification(${type}) (${device}) ==========`, JSON.stringify(pushNotification));
      const resp: Observable<any> = this.func.httpsCallable('sendNotificationCall')(pushNotification);
      return await lastValueFrom(resp).catch(error => error);
    } catch (error) {
      log.error(error);
      return error;
    }
  }

  private async updateData(deviceToken: any) {
    try {
      await this.deleteOtherTokens('deviceMobileToken', deviceToken);
      const userId = this.credService.credentials.uid;
      await this.fireService.afs
        .collection('users')
        .doc(userId)
        .update({ deviceMobileToken: deviceToken });
    } catch (error) {
      log.error(error);
    }
  }

  private async notificationToken(): Promise<void> {
    let permStatus = await PushNotifications.checkPermissions();

    if (!(permStatus && permStatus.receive && permStatus.receive === 'granted')) {
      permStatus = await PushNotifications.requestPermissions();
    }

    await PushNotifications.register();

    await PushNotifications.addListener('registration', async (token: Token) => {
      return await this.updateData(token.value);
    });

    const resp = await this.storage.get('fcmTopics');
    const fcmTopics: string[] = resp ? resp : [];
    for (const topic of fcmTopics) {
      FCM.unsubscribeFrom({ topic: topic });
    }
    this.storage.set('fcmTopics', []);
    if (
      this.credService &&
      this.credService.credentials &&
      this.credService.credentials.uid
    ) {
      const data = await this.fireService.getUserData(this.credService.credentials.uid);
      const topics: string[] = data && data['filter-meta-data'] ? Array.from(data['filter-meta-data']) : [];
      //topics subscribe
      let storageTopics: String[] = [];
      for (const topic of topics) {
        if (topic && topic !== '') {
          const finalTopic: string = this.utilities.removeAccents(String(topic)).replace(/\s/g, '');
          FCM
            .subscribeTo({ topic: finalTopic })
            .then(r => {
              r = r;
              storageTopics.push(finalTopic);
              // log.debug(r.message);
            })
            .catch(err => {
              log.error(err);
            });
        }
      }
      FCM.subscribeTo({ topic: 'allMovilDevices' });
      if (this.userTest()) {
        FCM.subscribeTo({ topic: 'allMovilDevicesForTest' });
      }
      this.storage.set('fcmTopics', storageTopics);
    }
  }

  private async notificationList(title: string, type: string, data: any, uid: string) {
    let route: string = '';
    let dataDoc: NotificationsInterface;
    let description: string = '';
    let elementId: string = '';
    const date = moment().toDate();
    switch (type) {
      case 'event-deleted':
        route = '/schedule';
        break;
      case 'event-updated':
        elementId = data;
        break;
      case 'member-in-videocall':
        elementId = data;
        break;
      case 'sample-request':
        elementId = data;
        break;
      //New Notifications
      case 'chat':
        elementId = data;
        break;
      case 'friend-request-send':
        route = '/user/details/' + data;
        break;
      case 'friend-request-acepted':
        route = '/user/details/' + data;
        break;
      case 'event-send':
        elementId = data;
        break;
      case 'event-acepted':
        elementId = data;
        break;
    }
    dataDoc = {
      date: date,
      uid: uid,
      title: title,
      type: type,
      viewed: false,
      route: route,
      description: description,
      elementId: elementId
    };
    try {
      await this.fireService.afs.collection('notifications').add(dataDoc);
    } catch (error) {
      log.error(error);
    }
  }

  private async deleteOtherTokens(key: string, token: string) {
    try {
      const resp = await this.fireService.afs
        .collection('users')
        .ref.where(key, '==', token)
        .get();
      const docs: any[] =
        resp.docs.map((e: any) => {
          return { id: e.id, ...e.data() };
        }) || [];

      const batch = this.fireService.afs.firestore.batch();

      for (let item of docs) {
        const data: any = {};
        data[key] = null;
        batch.update(this.fireService.afs.doc(`users/${item.id}`).ref, data);
      }

      await batch.commit();
    } catch (e) {
      log.error(e);
    }
  }

  private userTest(): boolean {
    const index: number = (environment.testForPushNotifiactions as string[]).indexOf(this.credService.credentials.email);
    if (index > -1) {
      return true;
    }
    return false;
  }
}
