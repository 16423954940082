<ion-header *ngIf="viewType === 'modal'">
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center">
      Seleccione a los contactos
    </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="viewType === 'modal'">
  <ion-grid fixed *ngIf="!searchMemberType">
    <ion-row>
      <ion-col size-xl="6" size-lg="6" size-md="12" size-xs="12">
        <ion-card
          button="true"
          (click)="changeMemberType('medico')"
          [disabled]="isLoading"
          class="menu-item menu-item-big card-button-1"
        >
          <ion-icon slot="start" name="medkit-outline"></ion-icon>
          <h2>
            Buscar {{ memberType === 'profesional-de-la-salud' ?  'profesionales de la salud' : 'médicos'}}
          </h2>
        </ion-card>
      </ion-col>
      <ion-col size-xl="6" size-lg="6" size-md="12" size-xs="12">
        <ion-card
          button="true"
          (click)="changeMemberType('representante-medico')"
          [disabled]="isLoading"
          class="menu-item menu-item-big card-button-1"
        >
          <ion-icon slot="start" name="briefcase-outline"></ion-icon>
          <h2>
            Buscar representante médicos
          </h2>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid fixed *ngIf="searchMemberType === 'representante-medico'">
    <ion-row>
      <ion-col>
        <ion-button
          fill="solid"
          expand="block"
          color="tertiary"
          (click)="companiesFilter()"
          [disabled]="isLoading"
          class="ion-text-wrap"
        >
          <ion-icon name="briefcase-outline" slot="start"></ion-icon>
          Empresas
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button shape="round" color="secondary" size="small" (click)="resetSearch(true)" [disabled]="isLoading">
          Representantes médicos
          <ion-icon slot="end" name="close-outline"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData3; let i = index"
          (click)="removeCompaniesFilter(true)"
          [disabled]="isLoading"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid fixed *ngIf="searchMemberType === 'medico'">
    <ion-row>
      <ion-col sizeXs="12" sizeMd="6">
        <ion-button
          fill="solid"
          *ngIf="newMembers !== true"
          expand="block"
          color="tertiary"
          (click)="specialtiesFilter()"
          [disabled]="isLoading"
          class="ion-text-wrap"
        >
          <ion-icon name="school-outline" slot="start"></ion-icon>
          Segmentar por especialidad
        </ion-button>
      </ion-col>
      <ion-col sizeXs="12" sizeMd="6">
        <ion-button
          fill="solid"
          *ngIf="newMembers !== true"
          expand="block"
          color="tertiary"
          (click)="statesFilter()"
          [disabled]="isLoading"
          class="ion-text-wrap"
        >
          <ion-icon name="location-outline" slot="start"></ion-icon>
          Segmentar por zona geográfica
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button
          *ngIf="memberType === 'medico'"
          shape="round"
          color="secondary"
          size="small"
          (click)="resetSearch(true)"
          [disabled]="isLoading"
        >
          Médicos
          <ion-icon slot="end" name="close-outline"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData2; let i = index"
          (click)="removeEspecialtiesFilter(true)"
          [disabled]="isLoading"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close-outline"></ion-icon>
        </ion-button>
        <ion-button
          shape="round"
          color="primary"
          size="small"
          *ngFor="let item of filterData1; let i = index"
          (click)="removeStatesFilter(true)"
          [disabled]="isLoading"
        >
          {{ item.label }}
          <ion-icon slot="end" name="close-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid fixed>
    <ion-row>
      <!-- Sreachbar -->
      <ion-col size="12">
        <ion-item>
          <ion-toolbar>
            <ion-searchbar
              type="text"
              debounce="1000"
              (ionChange)="doSearch($event)"
              (ionCancel)="clearSearch()"
              (ionClear)="clearSearch()"
              enterkeyhint="send"
              placeholder="Buscar"
              animated
              [disabled]="isLoading"
            >
            </ion-searchbar>
          </ion-toolbar>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <div *ngIf="members.length !== 0">
            <ion-item>
              <ion-label class="ion-text-center">
                <h2>
                  {{ members.length + ' contactos pertenecen al grupo' }}
                </h2>
              </ion-label>
            </ion-item>

            <div *ngFor="let item of arrayDocs; let i = index">
              <ion-item *ngIf="item.isMember" lines="none">
                <ion-avatar slot="start">
                  <img
                    [src]="item?.avatar?.list?.url ? item?.avatar.list.url : 'assets/img/default-' + item.type + '.jpg'"
                  />
                </ion-avatar>
                <ion-label slot="start">
                  <app-select-user-card [item]="item"></app-select-user-card>
                </ion-label>
                <ion-checkbox slot="end" [(ngModel)]="item.isChecked" (click)="selectUser(item)"></ion-checkbox>
              </ion-item>
            </div>
          </div>
          <ion-item>
            <!-- Nota si es que no hay contactos -->
            <div class="ion-text-center" *ngIf="!(arrayDocs && arrayDocs[0] && arrayDocs.length > 0)">
              <h3>En este momento no tienes contactos, para buscar nuevos contactos, ingresa a Red ConectiMED</h3>
              <br />
              <ion-button [routerLink]="'/profile/friends/search-friends'" fill="outline">
                <ion-icon slot="start" src="/assets/icons/red_conectimed.svg"></ion-icon>
                Ir a Red ConectiMED
              </ion-button>
            </div>
            <!-- Label con la cantidad de contactos -->
            <ion-label class="ion-text-wrap" *ngIf="arrayDocs[0] && !searchStr">
              <div class="ion-text-center">
                <h2>
                  {{
                    contador > 0
                      ? contador + ' contactos totales'
                      : 'En este momento no tienes contactos, para buscar nuevos contactos, ingresa a Red ConectiMED.'
                  }}
                </h2>
              </div>
            </ion-label>
            <ion-label class="ion-text-wrap" *ngIf="searchStr">
              <div class="ion-text-center">
                <h2>
                  Resultados de: <i>"{{ searchStr }}"</i>
                </h2>
              </div>
            </ion-label>
          </ion-item>
          <div *ngFor="let item of arrayDocs; let i = index">
            <ion-item *ngIf="!item.isMember" lines="none">
              <ion-avatar slot="start">
                <img
                  [src]="item?.avatar?.list?.url ? item?.avatar.list.url : 'assets/img/default-' + item.type + '.jpg'"
                />
              </ion-avatar>
              <ion-label slot="start">
                <app-select-user-card [item]="item"></app-select-user-card>
              </ion-label>
              <ion-checkbox slot="end" [(ngModel)]="item.isChecked" (click)="selectUser(item)"></ion-checkbox>
            </ion-item>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-grid class="ion-no-margin ion-no-padding ion-margin-top">
          <ion-row>
            <ion-col size="6" class="ion-no-margin ion-no-padding">
              <ion-button
                (click)="paginate('back')"
                expand="block"
                color="light"
                [disabled]="!cursorBack || isLoading == true"
              >
                <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col size="6  " class="ion-no-margin ion-no-padding">
              <ion-button
                (click)="paginate('next')"
                expand="block"
                color="light"
                [disabled]="!cursorNext || isLoading == true"
              >
                <ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer *ngIf="viewType === 'modal'">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="6">
        <ion-button
          (click)="clear(); analyticsClickEvent('clear', {})"
          expand="block"
          color="danger"
          shape="outline"
          [disabled]="isLoading"
        >
          <ion-icon slot="start" name="refresh-outline"></ion-icon>
          Limpiar
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button
          (click)="goChatGroup(); analyticsClickEvent('open_chatgroup', {})"
          expand="block"
          color="secondary"
          [disabled]="isLoading || !(selectedUsers.length > 0)"
        >
          <ion-icon slot="start" name="add-outline"></ion-icon>
          Seleccionar ({{ selectedUsers.length }})
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<div *ngIf="viewType === 'component'">
  <ion-grid fixed class="ion-no-margin ion-no-padding ion-margin-bottom">
    <ion-row class="ion-no-margin ion-no-padding">
      <ion-col size="12" class="ion-no-margin ion-no-padding">
        <!-- Change Member Type Buttons -->
        <ion-buttons class="switch">
          <ion-button
            expand="block"
            [ngClass]="{
              'btn-switch': true,
              'btn-representante': true,
              active: searchMemberType === 'representante-medico'
            }"
            (click)="
              changeMemberType('representante-medico');
              analyticsClickEvent('change_member', {
                type: 'Representante-Medico'
              })
            "
            [disabled]="isLoading"
            *ngIf="memberType === 'medico'"
          >
            Representantes
          </ion-button>
          <ion-button
            expand="block"
            [ngClass]="{ 'btn-switch': true, active: searchMemberType === 'medico' }"
            (click)="
              changeMemberType('medico');
              analyticsClickEvent('change_member', {
                type: 'Medico'
              })
            "
            [disabled]="isLoading || memberType === 'representante-medico'"
          >
          {{ memberType === 'profesional-de-la-salud' ?  'Profesionales de la salud' : 'Médicos'}}            
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid
    fixed
    *ngIf="searchMemberType === 'representante-medico'"
    class="ion-no-margin ion-no-padding ion-margin-bottom"
  >
    <ion-row class="ion-no-margin ion-no-padding">
      <ion-col class="ion-no-margin ion-no-padding">
        <ion-badge
          mode="ios"
          [color]="filterData3[0]?.label ? 'tertiary' : 'secondary'"
          *ngIf="newMembers !== true"
          class="conectimed-badge"
        >
          <ion-icon name="briefcase-outline"></ion-icon>
          <ion-label (click)="companiesFilter()" class="ion-text-wrap">
            {{ filterData3[0]?.label ? filterData3[0]?.label : 'Segmentar por Empresas' }}
          </ion-label>
          <ion-icon *ngIf="filterData3[0]?.label" name="close-outline" (click)="removeCompaniesFilter()"></ion-icon>
        </ion-badge>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid fixed *ngIf="searchMemberType === 'medico'" class="ion-no-margin ion-no-padding ion-margin-bottom">
    <ion-row class="ion-no-margin ion-no-padding">
      <ion-col sizeXs="12" sizeMd="6">
        <ion-badge
          mode="ios"
          [color]="filterData2[0]?.label ? 'tertiary' : 'secondary'"
          *ngIf="newMembers !== true"
          class="conectimed-badge"
        >
          <ion-icon name="school-outline"></ion-icon>
          <ion-label (click)="specialtiesFilter()" class="ion-text-wrap">
            {{ filterData2[0]?.label ? filterData2[0]?.label : 'Segmentar por especialidad' }}
          </ion-label>
          <ion-icon *ngIf="filterData2[0]?.label" name="close-outline" (click)="removeEspecialtiesFilter()"></ion-icon>
        </ion-badge>
      </ion-col>
      <ion-col sizeXs="12" sizeMd="6">
        <ion-badge
          mode="ios"
          [color]="filterData1[0]?.label ? 'tertiary' : 'secondary'"
          *ngIf="newMembers !== true"
          class="conectimed-badge"
        >
          <ion-icon name="location-outline"></ion-icon>
          <ion-label (click)="statesFilter()" class="ion-text-wrap">
            {{ filterData1[0]?.label ? filterData1[0]?.label : 'Segmentar por zona geográfica' }}
          </ion-label>
          <ion-icon *ngIf="filterData1[0]?.label" name="close-outline" (click)="removeStatesFilter()"></ion-icon>
        </ion-badge>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid fixed class="ion-no-margin ion-no-padding">
    <ion-row class="ion-no-margin ion-no-padding">
      <!-- Columna con el label de cantidad de contactos -->
      <ion-col size="12" class="ion-no-margin ion-no-padding">
        <ion-list>
          <ion-item lines="none" class="ion-no-margin ion-no-padding">
            <ion-label class="ion-text-wrap">
              <!-- If no contacts label -->
              <div *ngIf="arrayDocs.length === 0" class="ion-text-center">
                <h2>En este momento no tienes contactos, para buscar nuevos contactos, ingresa a Red ConectiMED</h2>
                <br />
                <ion-button [routerLink]="'/profile/friends/search-friends'" fill="outline">
                  <ion-icon slot="start" src="/assets/icons/red_conectimed.svg"></ion-icon>
                  Ir a Red ConectiMED
                </ion-button>
              </div>

              <!-- contacts count label -->
              <div class="ion-text-center" *ngIf="arrayDocs.length !== 0">
                <h2 *ngIf="!searchStr">
                  {{
                    contador > 0
                      ? contador + ' contactos totales'
                      : 'En este momento no tienes contactos, para buscar nuevos contactos, ingresa a Red ConectiMED.'
                  }}
                </h2>
                <h2 *ngIf="searchStr as search">
                  Resultados de: <i>"{{ search }}"</i>
                </h2>
              </div>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>

      <ion-col size="12" class="ion-no-margin ion-no-padding">
        <!-- ABC segment button -->
        <ion-segment style="margin-top: 5px; margin-bottom: 5px;" [scrollable]="true" [(ngModel)]="currentFilter">
          <ion-segment-button
            *ngFor="let item of filter"
            (click)="filterList(item?.filter)"
            mode="md"
            class="segment-abc"
            [value]="item.filter"
            [disabled]="!item.see || abc === false"
          >
            <h6 style="margin: 0px;">{{ item.title }}</h6>
          </ion-segment-button>
        </ion-segment>
        <p style="margin: 0px; font-size: 14px; color: var(--ion-color-medium);" class="ion-hide-md-up ion-text-center">
          Para ver las demas letras haga scroll
        </p>
      </ion-col>
    </ion-row>

    <!-- Users are here -->
    <ion-row>
      <ion-col *ngIf="arrayDocs && arrayDocs[0]" class="ion-no-margin ion-no-padding">
        <app-user-card
          *ngFor="let item of arrayDocs; let i = index"
          [index]="i + 1"
          [item]="item"
          [viewOptionsButtons]="true"
          (click)="
            analyticsClickEvent('select_content', {
              content_type: item?.name + ' ' + item?.lastName1 + ' ' + item?.lastName2,
              section_name: 'Contactos'
            })
          "
        >
        </app-user-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-grid class="ion-no-margin ion-no-padding ion-margin-top">
          <ion-row>
            <ion-col size="6" class="ion-no-margin ion-no-padding">
              <ion-button
                (click)="paginate('back')"
                expand="block"
                color="light"
                [disabled]="!cursorBack || isLoading == true"
              >
                <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col size="6  " class="ion-no-margin ion-no-padding">
              <ion-button
                (click)="paginate('next')"
                expand="block"
                color="light"
                [disabled]="!cursorNext || isLoading == true"
              >
                <ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
