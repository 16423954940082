import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Credentials, CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { Storage } from '@ionic/storage-angular';
import { AlertController, Platform } from '@ionic/angular';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { DocumentReference, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { TypesOfHealthProfessionalsService } from '@app/shared/services/types-of-health-professionals/types-of-health-professionals.service';
import { environment } from '@env/environment';
const log = new Logger('AuthenticationService');

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export interface LoginResponse {
  success: boolean,
  data?: any
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  isRegister: boolean;

  constructor(
    public credService: CredentialsService,
    public fireAuth: AngularFireAuth,
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    private storage: Storage,
    private platform: Platform,
    private specialtiesService: SpecialtiesService,
    private typesOfHealthProfessionalsService: TypesOfHealthProfessionalsService,
    private router: Router,
    private alertCtrl: AlertController
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials;
    this.analyticsFB.logEvent(eventName, params, { credentials: cred });
  }

  async logout() {
    try {
      await this.platform.ready();
      if (this.platform.is('capacitor') === true) {
        if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
          await this.fireService.afs.doc(`users/${this.credService.credentials.uid}`).update({ deviceMobileToken: null });
        }
      } else {
        if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
          await this.fireService.afs.doc(`users/${this.credService.credentials.uid}`).update({ deviceWebToken: null });
        }
      }
      await this.logoutHandler();
    } catch (error) {
      log.error(error);
      await this.logoutHandler();
    }
  }

  async logoutHandler() {
    await this.storage.clear();
    await this.fireAuth.signOut();
    await this.credService.setCredentials();
    await this.analyticsClearProperties();
    log.debug('*** logoutHandler ***');
  }

  loginUser(newEmail: string, newPassword: string, isRegister: boolean): Promise<firebase.default.auth.UserCredential> {
    this.isRegister = isRegister;
    return this.fireAuth.signInWithEmailAndPassword(newEmail, newPassword);
  }

  resetPassword(email: string): Promise<void> {
    return this.fireAuth.sendPasswordResetEmail(email);
  }

  async deleteAccount(): Promise<void> {
    try {
      const user: any = await this.getUpdatedCurrentUser();
      return user.delete();
    } catch (error) {
      log.error(error);
    }
  }

  logoutUser(): Promise<void> {
    this.storage.clear();
    log.debug('======= Storage Cleared =======');
    return this.fireAuth.signOut();
  }

  public async getUpdatedCurrentUser(): Promise<firebase.default.User | void> {
    if (this.fireAuth && this.fireAuth.currentUser) {
      const currentUser = await this.fireAuth.currentUser;
      if (!!currentUser) {
        currentUser.reload().then(() => {
          return currentUser;
        });
      }
    }
  }

  getUser(): Promise<firebase.default.User> {
    return this.fireAuth.currentUser;
  }

  async userInAccountActivationProcces() {
    try {
      const user = await this.getUser();
      if (user && user.uid && user.isAnonymous !== true) {
        log.debug('%cuserInAccountActivationProcces', "background-color:pink; color:white;")
        this.router.navigate(['/register/account-activation'], { replaceUrl: true });
      }
    } catch (error) {
      log.error(error);
    }
  }

  private async analyticsClearProperties() {
    try {
      await this.analyticsFB.reset();
      return true;
    } catch (error) {
      alert(error);
      return false;
    }
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<LoginResponse> {
    try {
      const credential = await this.fireAuth.signInWithEmailAndPassword(email, password);
      if (credential && credential.user && credential.user.uid) {
        const doc = await this.fireService.afs.collection('users').doc(credential.user.uid).ref.get();
        if (doc.exists === true) {
          const documentData: any = doc.data();
          const data: Credentials = {
            email: credential.user.email,
            type: documentData.type,
            uid: credential.user.uid,
            status: documentData.status
          }
          return { success: true };
        } else {
          return { success: false };
        }
      } else {
        return { success: false };
      }
    } catch (error: any) {
      log.error(error);
      return { success: false, data: error.code };
    }
  }

  authState() {
    return this.fireAuth.authState;
  }

  async currentUser(): Promise<firebase.default.User> {
    return await this.fireAuth.currentUser;
  }

  isAuthenticated(): Observable<boolean> {
    return this.fireAuth.authState.pipe(map(e => e && e.uid && e.isAnonymous === false ? true : false));
  }

  isNotAuthenticated(): Observable<boolean> {
    return this.fireAuth.authState.pipe(map(e => e && e.uid && e.isAnonymous === false ? false : true));
  }

  async deleteAnonymous(): Promise<void> {
    try {
      const user = await this.currentUser();
      if (user && user.isAnonymous === true) {
        await user.delete();
        log.debug("=== Usuario anónimo eliminado ===");
      }
    } catch (error) {
      log.error(error);
    }
  }

  async updateCredentials(user: firebase.default.User): Promise<Credentials> {
    try {
      if (user && user.uid && user.email && user.isAnonymous === false) {
        const dataUsr: DocumentSnapshot<any> = await this.fireService.afs.collection('users').doc<any>(user.uid).ref.get();
        if (dataUsr.exists === true) {
          let data: Credentials;
          let myprofile = {
            img: dataUsr.data() && dataUsr.data().avatar && dataUsr.data().avatar.main && dataUsr.data().avatar.main.url ? dataUsr.data().avatar.main.url : undefined,
            name: dataUsr.data() && dataUsr.data().name ? String(dataUsr.data().name).toUpperCase() : '',
            lastName1: dataUsr.data() && dataUsr.data().lastName1 ? String(dataUsr.data().lastName1).toUpperCase() : '',
            lastName2: dataUsr.data() && dataUsr.data().lastName2 ? String(dataUsr.data().lastName2).toUpperCase() : ''
          };
          let userMeta: DocumentSnapshot<any>;
          const DEFAULT_USER: boolean = environment.DEFAULT_USERS_CONNECTIONS.map(e => e.email).indexOf(user.email) > -1 ? true : false;
          if (dataUsr.data().type === 'medico') {
            userMeta = await this.fireService.afs.collection('medico-meta').doc<any>(user.uid).ref.get();
            const specialtyId =
              userMeta && userMeta.data() && userMeta.data().specialty1 && userMeta.data().specialty1.id
                ? userMeta.data().specialty1.id
                : undefined;
            let specialtyData: any;
            try {
              if (specialtyId) {
                specialtyData = await this.specialtiesService.getSpecialty(Number(specialtyId));
              }
            } catch (error) {
              log.warn(error);
            }
            data = {
              email: dataUsr && dataUsr.data() && dataUsr.data().email ? dataUsr.data().email : '',
              type: dataUsr && dataUsr.data() && dataUsr.data().type ? dataUsr.data().type : '',
              active: dataUsr && dataUsr.data() && dataUsr.data().status && dataUsr.data().status === 'active' ? true : false,
              uid: dataUsr && dataUsr.id ? dataUsr && dataUsr.id : '',
              state: userMeta && userMeta.data() && userMeta.data().address1 && userMeta.data().address1.state ? userMeta.data().address1.state : '',
              speciality: specialtyData && specialtyData.name ? specialtyData.name : '',
              newConditionsOfUseAccepted: dataUsr.data() && dataUsr.data().newConditionsOfUseAccepted && dataUsr.data().newConditionsOfUseAccepted === true ? true : false,
              defaultUser: DEFAULT_USER
            };
          } else if (dataUsr.data().type === 'representante-medico') {
            userMeta = await this.fireService.afs.collection('representante-meta').doc<any>(user.uid).ref.get();
            const _company: DocumentReference<any> = userMeta.data() && userMeta.data().company;
            const companyData = _company ? await _company.get() : undefined;
            data = {
              email: dataUsr && dataUsr.data() && dataUsr.data().email ? dataUsr.data().email : '',
              type: dataUsr && dataUsr.data() && dataUsr.data().type ? dataUsr.data().type : '',
              active: dataUsr && dataUsr.data() && dataUsr.data().status && (dataUsr.data().status === 'active' || dataUsr.data().status === 'activation-pending') ? true : false,
              uid: dataUsr && dataUsr.id ? dataUsr && dataUsr.id : '',
              company: companyData && companyData.data() && companyData.data().name ? companyData.data().name : '',
              newConditionsOfUseAccepted: dataUsr.data() && dataUsr.data().newConditionsOfUseAccepted && dataUsr.data().newConditionsOfUseAccepted === true ? true : false,
              defaultUser: DEFAULT_USER
            };

            const planId: string = dataUsr && dataUsr.data() && dataUsr.data().plan ? dataUsr.data().plan : undefined;
            let userPlan: any = {};

            if (planId) {
              const planResp: DocumentSnapshot<any> = await this.fireService.afs.doc<any>(`plans/${planId}`).ref.get();
              const planData = planResp && planResp.exists === true ? { id: planResp.id, ...planResp.data() } : undefined;
              if (planData) {
                userPlan = this.mapPlan(planData);
              } else {
                userPlan = await this.setRepToDefaultPlan();
              }
            } else {
              userPlan = await this.setRepToDefaultPlan();
            }
            data.plan = userPlan;
          } else if (dataUsr.data().type === 'profesional-de-la-salud') {
            userMeta = await this.fireService.afs.collection('profesional-de-la-salud-meta').doc<any>(user.uid).ref.get();
            const profesionalId =
              userMeta && userMeta.data() && userMeta.data().healthProfessionalType && userMeta.data().healthProfessionalType.id
                ? userMeta.data().healthProfessionalType.id
                : undefined;
            let profesionalData: any;
            try {
              if (profesionalId) {
                profesionalData = await this.typesOfHealthProfessionalsService.getHealthProfessional(profesionalId);
              }
            } catch (error) {
              log.warn(error);
            }
            data = {
              email: dataUsr && dataUsr.data() && dataUsr.data().email ? dataUsr.data().email : '',
              type: dataUsr && dataUsr.data() && dataUsr.data().type ? dataUsr.data().type : '',
              active: dataUsr && dataUsr.data() && dataUsr.data().status && dataUsr.data().status === 'active' ? true : false,
              uid: dataUsr && dataUsr.id ? dataUsr && dataUsr.id : '',
              state: userMeta && userMeta.data() && userMeta.data().address1 && userMeta.data().address1.state ? userMeta.data().address1.state : '',
              typeOfHealthcareProfessional: profesionalData && profesionalData.name ? profesionalData.name : '',
              newConditionsOfUseAccepted: dataUsr.data() && dataUsr.data().newConditionsOfUseAccepted && dataUsr.data().newConditionsOfUseAccepted === true ? true : false,
              defaultUser: DEFAULT_USER
            };
          } else {
            this.unauthorizedUsersAlert();
          }
          if (myprofile && (myprofile.img || myprofile.name || myprofile.lastName1 || myprofile.lastName2)) { data.profile = myprofile; }
          this.credService.setCredentials(data);
          if (!this.isRegister) {
            this.analyticsClickEvent('login', {
              method: 'email',
            });
          } else {
            this.analyticsClickEvent('sign_up', {
              method: 'email',
            });
          }
          return data;
        }
        else {
          return undefined
        }
      } else {
        return undefined
      }
    } catch (e) {
      log.error(e);
      return undefined
    }
  }

  async unauthorizedUsersAlert() {
    const alert = await this.alertCtrl.create({
      backdropDismiss: false,
      header: 'Usuario no autorizado',
      message:
        'Usuarios del tipo administradores, editores ..etc. No cuentan con acceso a la aplicación Conectimed, en un momento se recargara la aplicación'
    });
    alert.present();
    setTimeout(() => {
      this.logout().then(() => {
        alert.dismiss();
        this.router.navigate(['/home']);
      });
    }, 6000);
  }

  mapPlan(planData: any) {
    return {
      id: planData && planData.id ? planData.id : undefined,
      name: planData && planData.name ? planData.name : undefined,
      max_contacts: planData && planData.max_contacts ? planData.max_contacts : undefined,
      max_contacts_limit: planData && planData.max_contacts_limit ? planData.max_contacts_limit : undefined,
      max_products: planData && planData.max_products ? planData.max_products : undefined,
      max_products_limit: planData && planData.max_products_limit ? planData.max_products_limit : undefined
    }
  }

  private async setRepToDefaultPlan(): Promise<any> {
    // Set rep to default plan
    log.warn('========================= Set rep to default plan =========================');
    let _plan: any;
    try {
      const RESP = await this.fireService.afs
        .collection('plans')
        .ref.where('default', '==', true)
        .get();
      const plans: any[] = RESP.docs.map((element: any) => {
        return { id: element.id, ...element.data() };
      });
      if (plans && plans.length > 0) {
        _plan = plans[0];
      }
      try {
        if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
          await this.fireService.afs.doc(`users/${this.credService.credentials.uid}`).update({
            plan: _plan && _plan.id ? String(_plan.id) : null
          });
        }
      } catch (e) {
        log.error(e);
      }
      ///update my products
      try {
        if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
          const QUEUE = await this.fireService.afs.collection('queues-of-representatives').add({
            status: 'pending'
          });

          const REF2: DocumentReference = this.fireService.afs.doc(
            `queues-of-representatives/${QUEUE.id}/representatives/${this.credService.credentials.uid}`
          ).ref;

          REF2.set({ 'user-ref': REF2 });

          await this.fireService.afs.doc(`queues-of-representatives/${QUEUE.id}`).update({
            status: 'ready'
          });
        }
      } catch (e) {
        log.error(e);
      }
      return this.mapPlan(_plan);
    } catch (e) {
      log.error(e);
    }
  }

  public async registrationComplete(uid: string): Promise<boolean> {
    try {
      const data: any = await this.fireService.getUserData(uid);
      const type: string = data && data.type ? String(data.type) : undefined;
      if (type === 'medico') {
        if (
          data.name &&
          data.name !== '' &&
          data.lastName1 &&
          data.lastName1 !== '' &&
          data.lastName1 &&
          data.lastName1 !== '' &&
          data.mobile &&
          data.mobile !== '' &&
          data.mobileLada &&
          data.mobileLada.lada &&
          data.mobileLada.lada !== '' &&
          data.email &&
          data.email !== ''
        ) {
          const doctorData: any = await this.fireService.getDoctorData(uid);
          if (
            doctorData &&
            doctorData.specialty1 &&
            doctorData.specialty1.cedula &&
            doctorData.specialty1.cedula !== '' &&
            doctorData.specialty1.id &&
            doctorData.specialty1.id !== '' &&
            doctorData.address1.city &&
            doctorData.address1.city !== '' &&
            doctorData.address1.colony &&
            doctorData.address1.colony !== '' &&
            doctorData.address1.delegation &&
            doctorData.address1.delegation !== '' &&
            doctorData.address1.state &&
            doctorData.address1.state !== '' &&
            doctorData.address1.postalCode &&
            doctorData.address1.postalCcode !== ''
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 'representante-medico') {
        if (
          data.name &&
          data.name !== '' &&
          data.lastName1 &&
          data.lastName1 !== '' &&
          data.lastName1 &&
          data.lastName1 !== '' &&
          data.mobile &&
          data.mobile !== '' &&
          data.mobileLada &&
          data.mobileLada.lada &&
          data.mobileLada.lada !== '' &&
          data.email &&
          data.email !== ''
        ) {
          return true;
        } else {
          return false;
        }
      } else if (type === 'profesional-de-la-salud') {
        if (
          data.name &&
          data.name !== '' &&
          data.lastName1 &&
          data.lastName1 !== '' &&
          data.lastName1 &&
          data.lastName1 !== '' &&
          data.mobile &&
          data.mobile !== '' &&
          data.mobileLada &&
          data.mobileLada.lada &&
          data.mobileLada.lada !== '' &&
          data.email &&
          data.email !== ''
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    } catch (error) {
      log.error(error);
      return false;
    }
  }
}
