<div class="wrapper ion-no-margin" *ngIf="item && item.status === 'active'">
  <div class="body">
    <ion-item lines="full" [disabled]="item.status !== 'active'" class="item-card" detail [button]="true">
      <ion-thumbnail slot="start" (click)="viewDetail(item.id)">
        <img *ngIf="item?.avatar?.thumbnail?.url" [src]="item?.avatar?.thumbnail?.url" style="border-radius: 50%" />
        <svg *ngIf="!item?.avatar?.thumbnail?.url" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="
            shape-rendering: geometricPrecision;
            text-rendering: geometricPrecision;
            image-rendering: optimizeQuality;
            fill-rule: evenodd;
            clip-rule: evenodd;
          " viewBox="0 0 500 500">
          <g id="UrTavla">
            <circle style="fill: #d9d9d9" cx="250" cy="250" r="245"></circle>
            <text x="50%" y="50%" text-anchor="middle" dy=".3em" style="font-size: 180px; font-family: Arial">
              {{ item.nameProfile }}
            </text>
          </g>
        </svg>
      </ion-thumbnail>

      <ion-row class="ion-align-items-center" style="width: 100%;">
        <ion-col sizeXs="12" sizeMd="" style="padding: 0px;">
          <ion-label class="ion-text-wrap" (click)="viewDetail(item.id)">
            <h2>
              {{
              (
              ' ' +
              (item?.lastName1 ? item?.lastName1 : '') +
              ' ' +
              (item?.lastName2 ? item?.lastName2 : '') +
              ' ' +
              (item?.name ? item?.name : '')
              ).toUpperCase()
              }}
            </h2>
            <div *ngIf="item?.metaData | async | any as meta" class="ion-text-wrap">
              <p *ngIf="item.type === 'medico'">
                <b><span *ngIf="meta?.specialty1 as data">{{ data?.name }}</span>
                  <span *ngIf="meta?.specialty2?.name">&nbsp;&nbsp;&bull;&nbsp;&nbsp;{{ meta?.specialty2?.name }}
                  </span>
                </b>
              </p>
              <p *ngIf="item.type === 'representante-medico'">
                <b>
                  {{ meta?.company?.name | uppercase }}
                </b>
              </p>
              <p *ngIf="item.type === 'profesional-de-la-salud'">
                <b>
                  {{ meta?.healthProfessionalType?.name | uppercase }}
                </b>
              </p>
              <div *ngIf="item?.isFriend | async as isFriend">
                <ion-chip *ngIf="isFriend === 1" color="primary" class="ion-chip">
                  <ion-label>Mi contacto</ion-label>
                  <ion-icon name="checkmark-circle-outline" color="success"></ion-icon>
                </ion-chip>
                <ion-chip *ngIf="isFriend === 2" color="primary" class="ion-chip">
                  <ion-label>Pendiente</ion-label>
                  <ion-icon name="information-circle-outline"></ion-icon>
                </ion-chip>
              </div>
            </div>
          </ion-label>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="auto" style="padding: 0px;" class="ion-text-center">
          <ion-buttons>
            <ion-button (click)="goToMedicines($event, item)" *ngIf="
                (viewOptionsButtons && item.type === 'representante-medico') ||
                credService?.credentials?.type === TYPE_3_USER
              " color="secondary" style="margin-left: auto; margin-right: auto;">
              <ion-icon slot="icon-only" src="/assets/icons/pastillas.svg"></ion-icon>
            </ion-button>
            <ion-button (click)="chat(item)"
              *ngIf="viewOptionsButtons || credService?.credentials?.type === TYPE_3_USER" color="secondary"
              style="margin-left: auto; margin-right: auto;">
              <ion-icon slot="icon-only" name="chatbox-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
    </ion-item>
    <div style="background-color: white; padding: 0px 5px" *ngIf="item.status !== 'active'">
      <ion-row class="ion-align-items-center">
        <ion-col size="" style="padding: 0px">
          <span>Este usuario actualmente se encuentra inactivo, ¿desea eliminarlo de su lista de amigos?</span>
        </ion-col>
        <ion-col size="auto" style="padding: 0px">
          <ion-button (click)="onClick()" size="small">
            <ion-icon slot="start" name="trash-outline"></ion-icon>
            Borrar Contacto
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>

<ion-grid fixed *ngIf="buttons === 'sent'" class="ion-no-margin">
  <ion-row>
    <ion-col>
      <ion-button expand="block" fill="outline" color="danger" (click)="confirmCancel()" [disabled]="isLoading">
        <ion-icon name="close-outline" slot="start"></ion-icon>
        Cancelar solicitud
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-grid fixed *ngIf="buttons === 'received'" class="ion-no-margin">
  <ion-row>
    <ion-col size="6">
      <ion-button expand="block" fill="outline" color="tertiary" (click)="confirmAccept()" [disabled]="isLoading">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        Aceptar
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button expand="block" fill="outline" color="danger" (click)="confirmReject()" [disabled]="isLoading">
        <ion-icon name="close-outline" slot="start"></ion-icon>
        Rechazar
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>