import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Platform, ToastController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Logger } from '@app/core/logger.service';
import { App } from '@capacitor/app';
const log = new Logger('ChangePasswordComponent');

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  @ViewChild('passwordEyeRegister1', { static: false, read: ElementRef }) passwordEye1: ElementRef;
  @ViewChild('passwordEyeRegister2', { static: false, read: ElementRef }) passwordEye2: ElementRef;
  passwordTypeInput1 = 'password';
  passwordTypeInput2 = 'password';
  dataForm!: FormGroup;
  isLoading = false;
  subscription: Subscription;
  counter: number = 0;
  public value = this.navParams.get('username');

  constructor(
    private formBuilder: FormBuilder,
    private platform: Platform,
    private toastController: ToastController,
    private navParams: NavParams,
    private modalController: ModalController
  ) {
    log.debug('username' + this.value);
    this.initializeApp();
  }

  togglePasswordMode1() {
    //cambiar tipo input
    this.passwordTypeInput1 = this.passwordTypeInput1 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye1.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  togglePasswordMode2() {
    //cambiar tipo input
    this.passwordTypeInput2 = this.passwordTypeInput2 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye2.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  initializeApp() {
    this.createForm();
  }

  private createForm() {
    this.dataForm = this.formBuilder.group({
      password1: ['', [Validators.required, Validators.minLength(6)]],
      password2: ['', Validators.required]
    });
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe(() => {
      if (this.counter == 0) {
        this.counter++;
        this.presentToast();
        setTimeout(() => {
          this.counter = 0;
        }, 3000);
      } else {
        App.exitApp();
      }
    });
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'Presione nuevamente para salir.',
      duration: 3000
    });
    toast.present();
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  ngOnInit() { }

  ngOnDestroy() { }

  async submit() {
    await this.modalController.dismiss({ newpassword: String(this.dataForm.get('password1').value) });
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
