import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger } from '@app/core/logger.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { Storage } from '@ionic/storage-angular';
import { timer } from 'rxjs';
import moment from 'moment';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { HttpService, HttpHeaders } from '@app/shared/services/http/http.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { MustMatch } from '@app/shared/helpers/must-match.validator';
import { environment } from '@env/environment';
const log = new Logger('ResetPasswordComponent');
const COUNT: number = 180;
const TICK: number = 1000;
const OBSCURE_EMAIL = (email: string) => {
  const [name, domain] = email.split('@');
  return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
};
const OBSCURE_NAME = (name: string) => {
  const ARRAY = name.split(' ');
  let text: string = '';

  const new_array = ARRAY.map(item => {
    return `${item[0]}${new Array(item.length).join('*')}`;
  });

  for (let item of new_array) {
    text += `${item} `;
  }

  return text.trim();
};
const OBSCURE_PHONE = (phone: string) => {
  return `${new Array(String(phone).length).join('*')}${String(phone).substring(String(phone).length - 4)}`;
};
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public isLoading: boolean = false;
  public emailForm: FormGroup;
  public contactForm: FormGroup;
  public codeForm: FormGroup;
  public resetForm: FormGroup;
  private countDown: Subscription;
  public counter = 0;
  public displayPhone: string = '';
  public displayEmail: string = '';
  public userFullName: string = '';
  public displayName: string = '';
  public user: any;
  public step: number = 1;
  private mainSub: Subscription;

  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    private analyticsFB: AnalyticsService,
    private storage: Storage,
    private credService: CredentialsService,
    private loader: IonLoaderService,
    private http: HttpService,
    private utilities: UtilitiesService
  ) {
    this.initForm();
  }


  private async checkTimer() {
    try {
      const currentDate = moment();
      const mobileCounterDate = await this.storage.get('mobile_counter_date_r_password');
      if (mobileCounterDate) {
        let duration = moment.duration(currentDate.diff(moment(mobileCounterDate)));
        let seconds = duration.asSeconds();
        if (seconds < COUNT) {
          this.startCounter(COUNT - seconds);
        } else {
          this.storage.set('mobile_counter_date_r_password', undefined);
        }
      }
    } catch (error) {
      log.error(error);
    }
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials;
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  private initForm() {
    this.emailForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ]
    });

    this.contactForm = this.formBuilder.group({
      type: ['email', [Validators.required]]
    });

    this.codeForm = this.formBuilder.group({
      code: ['', [Validators.required]]
    });

    this.resetForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        repeatPassword: ['', [Validators.required]]
      },
      {
        validator: [MustMatch('password', 'repeatPassword')]
      }
    );
  }

  async ngOnInit() {
    this.checkTimer();
    this.mainSub = this.aRoute.params.subscribe(async (par) => {
      if (par && par.email && par.code) {
        this.emailForm.get('email').patchValue(String(atob(par.email)).toLocaleLowerCase())
        await this.continue();
        this.lastStep();
        this.codeForm.get('code').patchValue(atob(par.code));
        await this.checkUserCode();
      }
    });
  }

  ngOnDestroy(): void {
    try {
      this.mainSub.unsubscribe();
    } catch (error) {
      log.error(error);
    }
  }

  async startCounter(counter?: number) {
    this.counter = counter ? counter : COUNT;
    this.countDown = timer(0, TICK).subscribe(() => {
      if (this.counter <= 0) {
        this.countDown.unsubscribe();
        this.storage.set('mobile_counter_date_r_password', undefined);
        return 0;
      }
      return --this.counter;
    });
    if (!!!counter) {
      const date: number = moment().valueOf();
      await this.storage.set('mobile_counter_date_r_password', date);
    }
  }

  public async presentAlert(msg: string, title: string) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Ok'
        }
      ]
    });
    alert.present();
  }

  public async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Correcto',
      message: 'Contraseña cambiada correctamente, ahora ingrese con su email y su nueva contraseña.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.router.navigate(['/home/actions/open-login']);
          }
        }
      ]
    });
    alert.present();
  }

  change(ev: any) {
    if (ev && ev.target && ev.target.value) {
      if (ev.target.value === 'sms') {
        this.emailForm.get('mobile').setValidators([Validators.required]);
        this.emailForm.get('email').clearValidators();
      } else if (ev.target.value === 'email') {
        this.emailForm
          .get('email')
          .setValidators([
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ]);
        this.emailForm.get('mobile').clearValidators();
      }
      this.emailForm.get('mobile').updateValueAndValidity();
      this.emailForm.get('email').updateValueAndValidity();
    }
  }

  public async continue() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const email: string = String(this.emailForm.get('email').value)
        .toLocaleLowerCase()
        .trim();
      const resp = await this.getUserByEmail(email);
      if (resp && resp.authInformation && resp.authInformation.uid) {
        this.user = resp;

        const phone: string =
          this.user && this.user.userInformation && this.user.userInformation.mobile
            ? String(this.user.userInformation.mobile)
            : undefined;

        const respLada: string =
          this.user &&
            this.user.userInformation &&
            this.user.userInformation.mobileLada &&
            this.user.userInformation.mobileLada.lada
            ? String(this.user.userInformation.mobileLada.lada)
            : undefined;

        this.userFullName = `${this.user && this.user.userInformation && this.user.userInformation.name
          ? String(this.user.userInformation.name)
          : ''
          } ${this.user && this.user.userInformation && this.user.userInformation.lastName1
            ? String(this.user.userInformation.lastName1)
            : ''
          } ${this.user && this.user.userInformation && this.user.userInformation.lastName2
            ? String(this.user.userInformation.lastName2)
            : ''
          }`;

        this.displayName = OBSCURE_NAME(this.userFullName);

        this.displayPhone = OBSCURE_PHONE(this.utilities.getPhone(phone, respLada) || '');

        this.displayEmail =
          this.user && this.user.authInformation && this.user.authInformation.email
            ? String(this.user.authInformation.email)
            : '';

        this.displayEmail = OBSCURE_EMAIL(this.displayEmail);

        this.step = 2;
      } else {
        this.presentAlert(
          ' El correo electrónico de usuario que ha escrito no existe. Compruebe que lo ha escrito correctamente.',
          'Ha ocurrido un error'
        );
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async getUserByEmail(email: string): Promise<any> {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json',
        'private-key': environment.CUSTOM_SECURITY.private_key,
        'public-key': environment.CUSTOM_SECURITY.public_key
      };
      const resp: any = await this.http
        .post(
          `${environment.FIREBASE_API}getAuthUserByEmailRequest`,
          {
            email: email,
            info: true
          },
          { headers: headers }
        );
      if (resp) {
        return resp;
      } else {
        return undefined;
      }
    } catch (error) {
      log.error(error);
      return {};
    }
  }

  send() {
    this.startCounter();
    this._send();
  }

  public async _send() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const type: 'email' | 'sms' | null =
        this.contactForm.get('type') && this.contactForm.get('type').value ? this.contactForm.get('type').value : null;
      switch (type) {
        case 'email':
          const email: string =
            this.user && this.user.authInformation && this.user.authInformation.email
              ? String(this.user.authInformation.email)
                .toLocaleLowerCase()
                .trim()
              : undefined;
          if (email) {
            await this.sendRecoveryCode({
              email: email,
              name: this.userFullName,
              uid: this.user.authInformation.uid,
              token: this.user.token.idToken
            });
            this.step = 3;
          }

          break;
        case 'sms':
          const mobile: string =
            this.user && this.user.userInformation && this.user.userInformation.mobile
              ? String(this.user.userInformation.mobile)
                .toLocaleLowerCase()
                .trim()
              : undefined;
          if (mobile) {
            await this.sendRecoveryCode({
              number: mobile,
              uid: this.user.authInformation.uid,
              token: this.user.token.idToken
            });
            this.step = 3;
          }

          break;
        default:
          log.debug('========= DEFAULT =========');
          break;
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async checkUserCode() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const resp = await this.checkCode(this.user.token.idToken);
      if (resp && resp.uid) {
        this.step = 4;
      } else {
        const error: any = resp && resp.error && resp.error.code ? resp.error.code : undefined;
        let messageError: { message: string; title: string } = { message: '', title: '' };
        switch (error) {
          case 'invalid-argument':
            messageError.message =
              'El código que intenta ingresar no es válido, por favor inténtelo nuevamente, gracias.';
            messageError.title = 'Verifique el código ingresado';
            break;
          case 'missing-parameters':
            messageError.message = 'Uno o varios datos ingresados están vacíos, revise esta información.';
            messageError.title = 'Campos vacíos';
            break;
          case 'missing-user-id':
            messageError.message = 'ID de usuario faltante, revise esta información.';
            messageError.title = 'ID de usuario faltante';
            break;
          default:
            messageError.message = 'Lo sentimos, ha ocurrido un error, inténtelo mas tarde.';
            messageError.title = 'Error desconocido';
            break;
        }
        this.presentAlert(messageError.message, messageError.title);
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async finalize() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const resp = await this.updatePassword(this.user.token.idToken);
      if (resp && resp.uid) {
        this.reset();
        this.presentAlertConfirm();
      } else {
        const error: any = resp && resp.error && resp.error.code ? resp.error.code : undefined;
        let messageError: { message: string; title: string } = { message: '', title: '' };
        switch (error) {
          case 'invalid-argument':
            messageError.message =
              'El código que intenta ingresar no es válido, por favor inténtelo nuevamente, gracias.';
            messageError.title = 'Verifique el código ingresado';
            break;
          case 'must-match-password':
            messageError.message = 'Por favor revise las contraseñas ingresadas ambas deben coincidir.';
            messageError.title = 'Las contraseñas no coinciden';
            break;
          case 'password-length':
            messageError.message = 'La nueva contraseña debe tener al menos 6 caracteres.';
            messageError.title = 'Contraseña incorrecta';
            break;
          case 'missing-parameters':
            messageError.message = 'Uno o varios datos ingresados están vacíos, revise esta información.';
            messageError.title = 'Campos vacíos';
            break;
          case 'missing-user-id':
            messageError.message = 'ID de usuario faltante, revise esta información.';
            messageError.title = 'ID de usuario faltante';
            break;
          default:
            messageError.message = 'Lo sentimos, ha ocurrido un error, inténtelo mas tarde.';
            messageError.title = 'Error desconocido';
            break;
        }
        this.presentAlert(messageError.message, messageError.title);
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  private async updatePassword(token: string) {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const resp: any = await this.http
        .post(
          `${environment.FIREBASE_API}changePassword`,
          {
            uid: this.user.authInformation.uid,
            data: {
              password: this.resetForm.value.password,
              repeatPassword: this.resetForm.value.repeatPassword,
              code: this.codeForm.value.code
            }
          },
          { headers: headers }
        );
      return resp;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async checkCode(token: string) {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const resp: any = await this.http
        .post(
          `${environment.FIREBASE_API}checkCode`,
          {
            uid: this.user.authInformation.uid,
            data: {
              code: this.codeForm.value.code
            }
          },
          { headers: headers }
        );
      return resp;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  private async sendRecoveryCode(request: {
    name?: string;
    uid: string;
    token: string;
    email?: string;
    number?: string;
  }) {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${request.token}`
      };
      const resp: any = await this.http
        .post(
          `${environment.FIREBASE_API}generateCode`,
          {
            uid: request.uid,
            name: request.name,
            email: request.email,
            number: request.number
          },
          { headers: headers }
        );
      return resp;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  public reset() {
    this.codeForm.reset();
    this.emailForm.reset();
    this.resetForm.reset();
    this.contactForm.reset();
    this.user = undefined;
    this.step = 1;
    this.initForm();
  }

  public lastStep() {
    this.step = 3;
  }

  public step2() {
    this.step = 2;
  }

  public noWhiteSpaces(event: any, onlyTrim?: boolean) {
    try {
      if (onlyTrim === true) {
        event.target.value = String(event.target.value).trim();
      } else {
        event.target.value = String(event.target.value)
          .replace(/\s/g, '')
          .trim();
      }
    } catch (error) {
      log.error(error);
    }
  }
}
