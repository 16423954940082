import { FormsModule } from '@angular/forms';
import { CoreModule } from '@app/core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Angulartics2Module } from 'angulartics2';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { environment } from '@env/environment';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { HomeModule } from './home/home.module';
import { DeleteAccountModule } from './delete-account/delete-account.module';
import { ChatListModule } from './chat-list/chat-list.module';
import { AboutModule } from './about/about.module';
import { LoginModule } from '@app/login/login.module';
import { UserDetailsModule } from './user-details/user-details.module';
import { RegisterModule } from '@app/register/register.module';
import { SharingModule } from './sharing/sharing.module';
import { PreregistroModule } from '@app/login/preregistro/preregistro.module';
import { ProfileModule } from '@app/profile/profile.module';
import { ResetPasswordModule } from '@app/profile/reset-password/reset-password.module';
import { VerificationCodeModule } from './register/verification-code/verification-code.module';
import { IndustryModule } from './industry/industry.module';
import { NotificationCenterModule } from './notification-center/notification-center.module';
import { SurveysModule } from './surveys/surveys.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import localeEsMX from '@angular/common/locales/es-MX';
registerLocaleData(localeEsMX, 'es-Mx');
// Cordova Plugins
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileChooser } from '@awesome-cordova-plugins/file-chooser/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx/index';
import { PDFGenerator } from '@awesome-cordova-plugins/pdf-generator/ngx';
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    BrowserModule,
    TranslateModule.forRoot(),
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    Angulartics2Module.forRoot(),
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    HomeModule,
    DeleteAccountModule,
    AboutModule,
    LoginModule,
    ChatListModule,
    UserDetailsModule,
    RegisterModule,
    SharingModule,
    PreregistroModule,
    ProfileModule,
    ResetPasswordModule,
    VerificationCodeModule,
    IndustryModule,
    NotificationCenterModule,
    SurveysModule,
    FormsModule,
    CodeInputModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es-MX' },
    Diagnostic,
    File,
    FileChooser,
    FilePath,
    WebView,
    FileTransfer,
    PDFGenerator
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }