import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ICompaniesComponent } from './sections/i-companies/i-companies.component';
import { IMedicinesComponent } from './sections/i-medicines/i-medicines.component';
import { IDevicesComponent } from './sections/i-devices/i-devices.component';
import { IServicesComponent } from './sections/i-services/i-services.component'
import { IMyProductsComponent } from './sections/i-my-products/i-my-products.component';
import { IRepProductsComponent } from './sections/i-rep-products/i-rep-products.component';
import { ICompanyComponent } from './components/i-company/i-company.component';
import { IProductComponent } from './components/i-product/i-product.component';
import { IPdfProductsComponent } from './components/i-pdf-products/i-pdf-products.component';
import { RepresentanteGuard } from '@app/core/authentication/guards/representante.guard';
import { NoRepGuard } from '@app/core/authentication/guards/no-rep.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'companies',
    pathMatch: 'full'
  },
  // Empresas
  {
    path: 'companies',
    component: ICompaniesComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Empresas',
      icon: {
        src: "/assets/icons/farma-empresas.svg"
      },
      searchRoute: '/industry/companies',
      root: '/industry/companies',
      menuItem: '/industry/companies'
    }
  },
  {
    path: 'companies/detail/:id',
    component: ICompanyComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Detalle de Empresa',
      icon: {
        src: "/assets/icons/farma-empresas.svg"
      },
      searchRoute: '/industry/companies',
      root: '/industry/companies',
      menuItem: '/industry/companies'
    }
  },
  {
    path: 'companies/:search',
    component: ICompaniesComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Búsqueda de Empresas',
      icon: {
        src: "/assets/icons/farma-empresas.svg"
      },
      searchRoute: '/industry/companies',
      root: '/industry/companies',
      menuItem: '/industry/companies'
    }
  },
  // Medicamentos
  {
    path: 'medicines',
    component: IMedicinesComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Medicamentos',
      icon: {
        src: "/assets/icons/farma-med.svg"
      },
      searchRoute: '/industry/medicines',
      root: '/industry/medicines',
      menuItem: '/industry/medicines'
    }
  },
  {
    path: 'medicines/detail/:id',
    component: IProductComponent,
    data: {
      title: 'Detalle de Medicamento',
      icon: {
        src: "/assets/icons/farma-med.svg"
      },
      searchRoute: '/industry/medicines',
      root: '/industry/medicines',
      menuItem: '/industry/medicines'
    }
  },
  {
    path: 'medicines/:search',
    component: IMedicinesComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Búsqueda de Medicamentos',
      icon: {
        src: "/assets/icons/farma-med.svg"
      },
      searchRoute: '/industry/medicines',
      root: '/industry/medicines',
      menuItem: '/industry/medicines'
    }
  },
  // Dispositivos
  {
    path: 'devices',
    component: IDevicesComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Dispositivos',
      icon: {
        src: "/assets/icons/farma-dis.svg"
      },
      searchRoute: '/industry/devices',
      root: '/industry/devices',
      menuItem: '/industry/devices'

    }
  },
  {
    path: 'devices/detail/:id',
    component: IProductComponent,
    data: {
      title: 'Detalle de Dispositivo',
      icon: {
        src: "/assets/icons/farma-dis.svg"
      },
      searchRoute: '/industry/devices',
      root: '/industry/devices',
      menuItem: '/industry/devices'
    }
  },
  {
    path: 'devices/:search',
    component: IDevicesComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Búsqueda de Dispositivos',
      icon: {
        src: "/assets/icons/farma-dis.svg"
      },
      searchRoute: '/industry/devices',
      root: '/industry/devices',
      menuItem: '/industry/devices'
    }
  },
  // Servicios
  {
    path: 'services',
    component: IServicesComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Servicios',
      icon: {
        name: "ribbon-outline"
      },
      searchRoute: '/industry/services',
      root: '/industry/services',
      menuItem: '/industry/services'
    }
  },
  {
    path: 'services/detail/:id',
    component: IProductComponent,
    data: {
      title: 'Detalle de Servicio',
      icon: {
        name: "ribbon-outline"
      },
      searchRoute: '/industry/services',
      root: '/industry/services',
      menuItem: '/industry/services'
    }
  },
  {
    path: 'services/:search',
    component: IServicesComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Búsqueda de Servicios',
      icon: {
        name: "ribbon-outline"
      },
      searchRoute: '/industry/services',
      root: '/industry/services',
      menuItem: '/industry/services'
    }
  },
  // Mis productos
  {
    path: 'my-products',
    component: IMyProductsComponent,
    canActivate: [RepresentanteGuard],
    data: {
      title: 'Mis productos',
      icon: {
        "src": "/assets/icons/medicine.svg"
      },
      root: '/industry/my-products',
      menuItem: '/industry/my-products'
    }
  },
  {
    path: 'my-products/:search',
    component: IMyProductsComponent,
    canActivate: [RepresentanteGuard],
    data: {
      title: 'Búsqueda en Mis productos',
      icon: {
        "src": "/assets/icons/medicine.svg"
      },
      root: '/industry/my-products',
      menuItem: '/industry/my-products'
    }
  },
  {
    path: 'my-products/save/pdf',
    component: IPdfProductsComponent,
    canActivate: [RepresentanteGuard],
    data: {
      title: 'Guardar Mis productos',
      icon: {
        "src": "/assets/icons/medicine.svg"
      },
      root: '/industry/my-products',
      menuItem: '/industry/my-products'
    }
  },
  // User productos
  {
    path: 'products/user/:uid',
    component: IRepProductsComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Productos de Representante',
      icon: {
        "src": "/assets/icons/medicine.svg"
      },
      root: '/industry/companies',
      menuItem: '/industry/companies'
    }
  },
  {
    path: 'products/user/:uid/:search',
    component: IRepProductsComponent,
    canActivate: [NoRepGuard],
    data: {
      title: 'Búsqueda de Productos de Representante',
      icon: {
        "src": "/assets/icons/medicine.svg"
      },
      root: '/industry/companies',
      menuItem: '/industry/companies'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IndustryRoutingModule { }
