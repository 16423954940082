import { Injectable } from '@angular/core';

export interface Credentials {
  uid: any;
  email: string;
  type: string;
  status?: string;
  token?: any;
  active?: any;
  state?: string;
  company?: string;
  speciality?: string;
  typeOfHealthcareProfessional?: string,
  profile?: {
    name?: string;
    lastName1?: string;
    lastName2?: string;
    img?: string;
  },
  plan?: {
    id: string;
    name: string;
    max_contacts: number | null;
    max_contacts_limit: boolean;
    max_products: number | null;
    max_products_limit: boolean;
  };
  newConditionsOfUseAccepted?: boolean;
  defaultUser?: boolean;
}

const credentialsKey = 'credentials';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: Credentials | null = null;

  constructor() {
    this.start();
  }

  start() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  get credentials(): Credentials | null {
    return this._credentials;
  }

  public setCredentials(credentials?: Credentials) {
    this._credentials = credentials || null;
    if (credentials) {
      localStorage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
    this.start();
  }
}
