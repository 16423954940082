<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> Detalle de grupo </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <div style="text-align: center;">
          <h2>
            <div class="ion-text-uppercase">
              {{ data?.name }}
            </div>
          </h2>
          <p>{{ data.description }}</p>
          <p><strong>Total de miembros: </strong>{{ members.length }}</p>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item *ngFor="let item of members; let i = index" lines="full">
            {{ item?.name + ' ' + item?.lastName1 + ' ' + item?.lastName2 }}
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="6">
        <ion-button (click)="membersGo()" expand="block" color="danger" shape="outline" [disabled]="isLoading">
          <ion-icon slot="start" name="people-outline"></ion-icon>
          Editar Miembros
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button (click)="chatGroup()" expand="block" color="secondary" [disabled]="isLoading">
          <ion-icon slot="start" name="chatbox-outline"></ion-icon>
          Enviar Mensaje
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
