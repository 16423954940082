import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Share } from '@capacitor/share';
import { AlertController, Platform, ToastController, PopoverController } from '@ionic/angular';
import { Logger } from '../../../core/logger.service';
import { environment } from '@env/environment';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { HttpService, HttpHeaders } from '@app/shared/services/http/http.service';
import { Converter } from 'showdown';
import * as moment from 'moment';
const log = new Logger('Utilities');

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  removeAccents = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  unique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  excerpt = (str: string) => {
    return str.replace(/^(.{5}[^\s]*).*/, '$1');
  };

  public mimeType: any = {
    '.abw': 'application/x-abiword',
    '.arc': 'application/octet-stream',
    '.avi': 'video/x-msvideo',
    '.azw': 'application/vnd.amazon.ebook',
    '.bin': 'application/octet-stream',
    '.bz': 'application/x-bzip',
    '.bz2': 'application/x-bzip2',
    '.csh': 'application/x-csh',
    '.css': 'text/css',
    '.csv': 'text/csv',
    '.doc': 'application/msword',
    '.epub': 'application/epub+zip',
    '.gif': 'image/gif',
    '.htm': 'text/html',
    '.html': 'text/html',
    '.ico': 'image/x-icon',
    '.ics': 'text/calendar',
    '.jar': 'application/java-archive',
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.js': 'application/javascript',
    '.json': 'application/json',
    '.mid': 'audio/midi',
    '.midi': 'audio/midi',
    '.mpeg': 'video/mpeg',
    '.mpkg': 'application/vnd.apple.installer+xml',
    '.odp': 'application/vnd.oasis.opendocument.presentation',
    '.ods': 'application/vnd.oasis.opendocument.spreadsheet',
    '.odt': 'application/vnd.oasis.opendocument.text',
    '.oga': 'audio/ogg',
    '.ogv': 'video/ogg',
    '.ogx': 'application/ogg',
    '.pdf': 'application/pdf',
    '.ppt': 'application/vnd.ms-powerpoint',
    '.rar': 'application/x-rar-compressed',
    '.rtf': 'application/rtf',
    '.sh': 'application/x-sh',
    '.svg': 'image/svg+xml',
    '.swf': 'application/x-shockwave-flash',
    '.tar': 'application/x-tar',
    '.tif': 'image/tiff',
    '.tiff': 'image/tiff',
    '.ttf': 'font/ttf',
    '.vsd': 'application/vnd.visio',
    '.wav': 'audio/x-wav',
    '.weba': 'audio/webm',
    '.webm': 'video/webm',
    '.webp': 'image/webp',
    '.woff': 'font/woff',
    '.woff2': 'font/woff2',
    '.xhtml': 'application/xhtml+xml',
    '.xls': 'application/vnd.ms-excel',
    '.xml': 'application/xml',
    '.xul': 'application/vnd.mozilla.xul+xml',
    '.zip': 'application/zip',
    '.3gp': 'video/3gpp',
    '.3g2': 'video/3gpp2',
    '.7z': 'application/x-7z-compressed'
  };

  public slugify = (text: string) =>
    text
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-');

  isWeb: boolean = true;

  constructor(
    private sanitizer: DomSanitizer,
    public toastCtrl: ToastController,
    private alertCtrl: AlertController,
    public platform: Platform,
    public credService: CredentialsService,
    private popoverCtrl: PopoverController,
    private http: HttpService
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.isWeb = false;
      }
    });
  }

  public checkVisibility(date: any): boolean {
    var dates = moment(date.toDate());
    var now = moment();
    if (now > dates) {
      return true;
    } else {
      return false;
    }
  }

  cleanAndLimit(str: string, limit: number) {
    str = String(str).replace(/(<([^>]+)>)/gi, '');
    str = `${String(str).slice(0, limit)}...`;
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }

  async presentAlert(message?: string, header?: string, subHeader?: string) {
    const alert = await this.alertCtrl.create({
      header: header ? header : '',
      subHeader: subHeader ? subHeader : '',
      message: message ? message : '',
      buttons: ['Ok']
    });
    alert.present();
  }

  public trustHtml(html: string): SafeHtml {
    return html ? this.sanitizer.bypassSecurityTrustHtml(html) : null;
  }

  public async toast(message: string, title?: string, position: 'top' | 'bottom' | 'middle' = 'top') {
    title = title ? title : '';
    const toast = await this.toastCtrl.create({
      header: title,
      message: message,
      position: position,
      duration: 10000,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  public urlify(text: string, noIab?: boolean) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      if (noIab === true) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
      } else {
        return '<a href="' + url + '" class="iab-disabled">' + url + '</a>';
      }
    });
  }

  async striplinks(text: string): Promise<string> {
    const re = /<a\s.*?href=[\"\'](.*?)[\"\']*?>(.*?)<\/a>/g;
    const str = text;
    const subst = '$2';
    const result = str.replace(re, subst);
    return result;
  }

  public setUrlParameter(url: any, key: any, value: any): string {
    let baseUrl = url.split('?')[0],
      urlQueryString = url.split('?')[1] ? '?' + url.split('?')[1] : '',
      newParam = value ? key + '=' + value : '',
      params = `?${newParam}`;
    if (urlQueryString) {
      const updateRegex = new RegExp('([?&])' + key + '[^&]*');
      const removeRegex = new RegExp('([?&])' + key + '=[^&;]+[&;]?');
      if (typeof value === 'undefined' || value === null || value === '') {
        params = urlQueryString.replace(removeRegex, '$1');
        params = params.replace(/[&;]$/, '');
      } else if (urlQueryString.match(updateRegex) !== null) {
        params = urlQueryString.replace(updateRegex, '$1' + newParam);
      } else {
        params = `${urlQueryString}&${newParam}`;
      }
    }
    params = params === '?' ? '' : params;
    return `${baseUrl}${params}`;
  }

  public mapMemberData(item: any): any {
    let data: any;
    const name =
      item &&
        item.xprofile &&
        item.xprofile.groups &&
        item.xprofile.groups[1] &&
        item.xprofile.groups[1].fields &&
        item.xprofile.groups[1].fields[33] &&
        item.xprofile.groups[1].fields[33].value &&
        item.xprofile.groups[1].fields[33].value.raw
        ? item.xprofile.groups[1].fields[33].value.raw
        : '';
    const firstName =
      item &&
        item.xprofile &&
        item.xprofile.groups &&
        item.xprofile.groups[1] &&
        item.xprofile.groups[1].fields &&
        item.xprofile.groups[1].fields[34] &&
        item.xprofile.groups[1].fields[34].value &&
        item.xprofile.groups[1].fields[34].value.raw
        ? item.xprofile.groups[1].fields[34].value.raw
        : '';
    const lastName =
      item &&
        item.xprofile &&
        item.xprofile.groups &&
        item.xprofile.groups[1] &&
        item.xprofile.groups[1].fields &&
        item.xprofile.groups[1].fields[35] &&
        item.xprofile.groups[1].fields[35].value &&
        item.xprofile.groups[1].fields[35].value.raw
        ? item.xprofile.groups[1].fields[35].value.raw
        : '';
    data = {
      avatar: item && item.avatar_urls && item.avatar_urls.full ? item.avatar_urls.full : '',
      mobile:
        item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[1] &&
          item.xprofile.groups[1].fields &&
          item.xprofile.groups[1].fields[36] &&
          item.xprofile.groups[1].fields[36].value &&
          item.xprofile.groups[1].fields[36].value.raw
          ? item.xprofile.groups[1].fields[36].value.raw
          : '',
      phone:
        item.xprofile &&
          item.xprofile.groups &&
          item.xprofile.groups[1] &&
          item.xprofile.groups[1].fields &&
          item.xprofile.groups[1].fields[70] &&
          item.xprofile.groups[1].fields[70].value &&
          item.xprofile.groups[1].fields[70].value.raw
          ? item.xprofile.groups[1].fields[70].value.raw
          : '',
      address1: {
        street:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[43] &&
            item.xprofile.groups[4].fields[43].value &&
            item.xprofile.groups[4].fields[43].value.raw
            ? item.xprofile.groups[4].fields[43].value.raw
            : '',
        outsideNumber:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[44] &&
            item.xprofile.groups[4].fields[44].value &&
            item.xprofile.groups[4].fields[44].value.raw
            ? item.xprofile.groups[4].fields[44].value.raw
            : '',
        interiorNumber:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[45] &&
            item.xprofile.groups[4].fields[45].value &&
            item.xprofile.groups[4].fields[45].value.raw
            ? item.xprofile.groups[4].fields[45].value.raw
            : '',
        postalCode:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[46] &&
            item.xprofile.groups[4].fields[46].value &&
            item.xprofile.groups[4].fields[46].value.raw
            ? item.xprofile.groups[4].fields[46].value.raw
            : '',

        city:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[48] &&
            item.xprofile.groups[4].fields[48].value &&
            item.xprofile.groups[4].fields[48].value.raw
            ? item.xprofile.groups[4].fields[48].value.raw
            : '',
        delegation:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[48] &&
            item.xprofile.groups[4].fields[48].value &&
            item.xprofile.groups[4].fields[48].value.raw
            ? item.xprofile.groups[4].fields[48].value.raw
            : '',
        state:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[49] &&
            item.xprofile.groups[4].fields[49].value &&
            item.xprofile.groups[4].fields[49].value.raw
            ? item.xprofile.groups[4].fields[49].value.raw
            : '',
        colony:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[50] &&
            item.xprofile.groups[4].fields[50].value &&
            item.xprofile.groups[4].fields[50].value.raw
            ? item.xprofile.groups[4].fields[50].value.raw
            : '',
        hospital:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[51] &&
            item.xprofile.groups[4].fields[51].value &&
            item.xprofile.groups[4].fields[51].value.raw
            ? item.xprofile.groups[4].fields[51].value.raw
            : '',
        tower:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[4] &&
            item.xprofile.groups[4].fields &&
            item.xprofile.groups[4].fields[52] &&
            item.xprofile.groups[4].fields[52].value &&
            item.xprofile.groups[4].fields[52].value.raw
            ? item.xprofile.groups[4].fields[52].value.raw
            : ''
      },
      address2: {
        street:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[53] &&
            item.xprofile.groups[5].fields[53].value &&
            item.xprofile.groups[5].fields[53].value.raw
            ? item.xprofile.groups[5].fields[53].value.raw
            : '',
        outsideNumber:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[54] &&
            item.xprofile.groups[5].fields[54].value &&
            item.xprofile.groups[5].fields[54].value.raw
            ? item.xprofile.groups[5].fields[54].value.raw
            : '',
        interiorNumber:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[55] &&
            item.xprofile.groups[5].fields[55].value &&
            item.xprofile.groups[5].fields[55].value.raw
            ? item.xprofile.groups[5].fields[55].value.raw
            : '',
        postalCode:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[56] &&
            item.xprofile.groups[5].fields[56].value &&
            item.xprofile.groups[5].fields[56].value.raw
            ? item.xprofile.groups[5].fields[56].value.raw
            : '',

        city:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[58] &&
            item.xprofile.groups[5].fields[58].value &&
            item.xprofile.groups[5].fields[58].value.raw
            ? item.xprofile.groups[5].fields[58].value.raw
            : '',
        delegation:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[58] &&
            item.xprofile.groups[5].fields[58].value &&
            item.xprofile.groups[5].fields[58].value.raw
            ? item.xprofile.groups[5].fields[58].value.raw
            : '',
        state:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[59] &&
            item.xprofile.groups[5].fields[59].value &&
            item.xprofile.groups[5].fields[59].value.raw
            ? item.xprofile.groups[5].fields[59].value.raw
            : '',
        colony:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[60] &&
            item.xprofile.groups[5].fields[60].value &&
            item.xprofile.groups[5].fields[60].value.raw
            ? item.xprofile.groups[5].fields[60].value.raw
            : '',
        hospital:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[61] &&
            item.xprofile.groups[5].fields[61].value &&
            item.xprofile.groups[5].fields[61].value.raw
            ? item.xprofile.groups[5].fields[61].value.raw
            : '',
        tower:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[5] &&
            item.xprofile.groups[5].fields &&
            item.xprofile.groups[5].fields[62] &&
            item.xprofile.groups[5].fields[62].value &&
            item.xprofile.groups[5].fields[62].value.raw
            ? item.xprofile.groups[5].fields[62].value.raw
            : ''
      },
      company: {
        name:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[6] &&
            item.xprofile.groups[6].fields &&
            item.xprofile.groups[6].fields[63] &&
            item.xprofile.groups[6].fields[63].value &&
            item.xprofile.groups[6].fields[63].value.raw
            ? item.xprofile.groups[6].fields[63].value.raw
            : '',
        businessType:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[6] &&
            item.xprofile.groups[6].fields &&
            item.xprofile.groups[6].fields[65] &&
            item.xprofile.groups[6].fields[65].value &&
            item.xprofile.groups[6].fields[65].value.raw
            ? item.xprofile.groups[6].fields[65].value.raw
            : '',
        companyWebsite:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[6] &&
            item.xprofile.groups[6].fields &&
            item.xprofile.groups[6].fields[64] &&
            item.xprofile.groups[6].fields[64].value &&
            item.xprofile.groups[6].fields[64].value.raw
            ? item.xprofile.groups[6].fields[64].value.raw
            : ''
      },
      specialty1: {
        id:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[3] &&
            item.xprofile.groups[3].fields &&
            item.xprofile.groups[3].fields[39] &&
            item.xprofile.groups[3].fields[39].value &&
            item.xprofile.groups[3].fields[39].value.raw
            ? Number(item.xprofile.groups[3].fields[39].value.raw)
            : 0,
        cedula:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[3] &&
            item.xprofile.groups[3].fields &&
            item.xprofile.groups[3].fields[40] &&
            item.xprofile.groups[3].fields[40].value &&
            item.xprofile.groups[3].fields[40].value.raw
            ? item.xprofile.groups[3].fields[40].value.raw
            : ''
      },
      specialty2: {
        id:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[3] &&
            item.xprofile.groups[3].fields &&
            item.xprofile.groups[3].fields[41] &&
            item.xprofile.groups[3].fields[41].value &&
            item.xprofile.groups[3].fields[41].value.raw
            ? Number(item.xprofile.groups[3].fields[41].value.raw)
            : 0,
        cedula:
          item &&
            item.xprofile &&
            item.xprofile.groups &&
            item.xprofile.groups[3] &&
            item.xprofile.groups[3].fields &&
            item.xprofile.groups[3].fields[42] &&
            item.xprofile.groups[3].fields[42].value &&
            item.xprofile.groups[3].fields[42].value.raw
            ? item.xprofile.groups[3].fields[42].value.raw
            : ''
      },
      id: Number(item.id),
      name: name,
      lastName1: firstName,
      lastName2: lastName,
      type: item && item.member_types && item.member_types[0] ? item.member_types[0] : ''
    };
    return data;
  }

  async takePicture(): Promise<File | any> {
    try {
      const image = await Camera.getPhoto({
        quality: 25,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera
      });
      const url = image.webPath;
      const Fetch = await fetch(url);
      const Blob = await Fetch.blob();
      const file = new File([Blob], 'filename.' + image.format, { type: 'image/' + image.format });
      return file;
    } catch (error) {
      log.error(error);
    }
  }

  tConvert(time: any) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  randomChain(length: number): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  initEqualsObject() {
    // Object['equals'] = function (x: any, y: any) {
    //   if (x === y) {
    //     return true;
    //   }
    //   if (!(x instanceof Object) || !(y instanceof Object)) {
    //     return false;
    //   }
    //   if (x.constructor !== y.constructor) {
    //     return false;
    //   }
    //   for (var p in x) {
    //     if (!x.hasOwnProperty(p)) {
    //       continue;
    //     }
    //     if (!y.hasOwnProperty(p)) {
    //       return false;
    //     }
    //     if (x[p] === y[p]) {
    //       continue;
    //     }
    //     if (typeof x[p] !== 'object') {
    //       return false;
    //     }
    //     if (!Object['equals'](x[p], y[p])) {
    //       return false;
    //     }
    //   }
    //   for (p in y) {
    //     if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
    //       return false;
    //     }
    //   }
    //   return true;
    // };
  }

  mapAdress(address1: any, address2: any): any[] {
    const adress = [];
    let adress2 = '';
    let adress1 = '';
    let cp1 = '';
    let cp2 = '';

    if (address1) {
      const calle1 = address1.street;
      const nExt1 = address1.outsideNumber;
      const nInt1 = address1.interiorNuumber;
      cp1 = address1.postalCode;
      const col1 = address1.colony;
      const estado1 = address1.state;
      const delegacion1 = address1.delegation;
      const hospital1 = address1.hospital;
      const torre1 = address1.tower;

      adress1 = `${calle1 ? calle1 : ''} ${nExt1 ? nExt1 : ''} ${nInt1 ? ', No. Int. ' + nInt1 : ''} ${col1 ? ', Col. ' + col1 : ''
        } ${delegacion1 ? ',' + delegacion1 : ''} ${estado1 ? ',' + estado1 : ''} ${cp1 ? ', C.P. ' + cp1 : ''} ${hospital1 ? ', Hospital: ' + hospital1 : ''
        } ${torre1 ? ', Torre: ' + torre1 : ''}`;
    }

    if (address2) {
      const calle2 = address2.street;
      const nExt2 = address2.outsideNumber;
      const nInt2 = address2.interiorNuumber;
      cp2 = address2.postalCode;
      const col2 = address2.colony;
      const estado2 = address2.state;
      const delegacion2 = address2.delegation;
      const hospital2 = address2.hospital;
      const torre2 = address2.tower;
      adress2 = `${calle2 ? calle2 : ''} ${nExt2 ? nExt2 : ''} ${nInt2 ? ', No. Int. ' + nInt2 : ''} ${col2 ? ', Col. ' + col2 : ''
        } ${delegacion2 ? ',' + delegacion2 : ''} ${estado2 ? ',' + estado2 : ''} ${cp2 ? ', C.P. ' + cp2 : ''} ${hospital2 ? ', Hospital: ' + hospital2 : ''
        } ${torre2 ? ', Torre: ' + torre2 : ''}`;
    }

    if (cp1 && cp1 !== '' && cp1 !== null && cp1 !== undefined) {
      adress.push({ adress: adress1, id: 4 });
    }
    if (cp2 && cp2 !== '' && cp2 !== null && cp2 !== undefined) {
      adress.push({ adress: adress2, id: 5 });
    }
    return adress;
  }

  replaceInHtml(html: string): string {
    let newHtml = html.replace(/href=/gi, ' class="iab-disabled" target="_blank" href=');
    newHtml = newHtml.replace(/<img/gi, '<img class="iab-disabled" ');
    newHtml = newHtml.replace(/<table/gi, '<div class="scroll-overflow-on-table"><table');
    newHtml = newHtml.replace(/<\/table>/gi, '</table></div>');
    newHtml = newHtml.replace(/<iframe/gi, '<div class="embed-responsive embed-responsive-16by9"><iframe');
    newHtml = newHtml.replace(/<\/iframe>/gi, '</iframe></div>');
    return newHtml;
  }

  stripTags(html: string, allowed_tags?: any) {
    if (allowed_tags) {
      allowed_tags = allowed_tags.trim();
      allowed_tags = allowed_tags.split(/\s+/).map(function (tag: any) {
        return '/?' + tag;
      });
      allowed_tags = '(?!' + allowed_tags.join('|') + ')';
    }
    return html.replace(new RegExp('(<' + allowed_tags + '.*?>)', 'gi'), '');
  }

  public newContent(newContentArray: any[]) {
    const converter = new Converter();
    let newContent: string = '';
    for (const item of newContentArray) {
      const { value, type } = item;

      switch (type) {
        case 'title':
          newContent += `<h1>${value}</h1>`;
          break;

        case 'text':
          const _value = converter.makeHtml(String(value));
          newContent += `<p>${_value}</p>`;
          break;

        case 'image':
          newContent += `<img src="${value}">`;
          break;

        case 'link':
          newContent += `<p><a href="${value}" target="_blank">${value}</a></p>`;
          break;

        case 'video_url':
          // const video = this.embedService.embed(value);
          // newContent += video.changingThisBreaksApplicationSecurity;
          break;
      }
    }
    return newContent;
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  stringSearch(str: string, whiteSpaces?: boolean): string {
    str = str.trim();
    var noTildes: string = this.removeAccents(str).replace(/[^\w\s]/gi, '');
    let regexp: RegExp = /[^a-zA-Z0-9]/g;
    if (whiteSpaces === true) {
      regexp = /[^a-zA-Z0-9 ]/g;
    }
    let search: string = noTildes.replace(regexp, '').toLocaleLowerCase();
    search = search.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
    return search;
  }

  public async arraySearchRequest(text: string | number): Promise<string[]> {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json'
      };
      const resp: any = await this.http
        .post(
          `${environment.FIREBASE_API}getSearchArray`,
          {
            text: text
          },
          { headers: headers }
        );
      if (resp && resp.search_array) {
        return resp.search_array;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  }

  public async share(route: string) {
    let parameter = `${environment.DEEPLINK_URL}${route}`;
    parameter = btoa(parameter);
    let url: string = `${environment.APP_URL}/sharing/${parameter}`;
    if (this.platform.is('capacitor')) {
      await Share.share({
        title: 'Conectimed',
        text: 'Conectimed',
        url: url,
        dialogTitle: '¡Comparte a Conectimed con tus colegas!'
      });
    } else {
      this.presentAlertPrompt(url);
    }
  }

  async presentAlertPrompt(url: string) {
    const alert = await this.alertCtrl.create({
      header: '¡Comparte a Conectimed con tus colegas!',
      subHeader: 'Copie el siguiente link y comparta este contenido con sus colegas.',
      inputs: [
        {
          name: 'url',
          type: 'url',
          value: url,
          placeholder: 'Url a compartir'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'danger'
        },
        {
          text: 'Copiar link',
          handler: (data: any) => {
            if (data && data.url) {
              var text = String(data.url);
              navigator.clipboard
                .writeText(text)
                .then(() => {
                  this.toast('¡Link copiado correctamente!');
                })
                .catch(error => {
                  log.error(error, 'Error al copiar link');
                });
            }
          }
        }
      ]
    });
    alert.present();
  }

  sortObjectArray(array: any[], key: string) {
    array.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > b[key]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  async littleLoading(message: string, duration: number = 1000) {
    message;
    duration;
  }

  validateUrl(url: string) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      url
    );
  }

  public getPhone(mobile: string, lada: string): string {
    if (lada) {
      let _lada = mobile;
      _lada = _lada.substring(0, lada.length);
      if (_lada === lada) {
        mobile = mobile.slice(lada.length);
      } else {
        mobile = mobile;
      }
    }
    return mobile;
  }

  async calendarNative(title: string, startDate: Date, endDate?: Date, location?: string, eventLink?: string) {
    log.debug(title);
    log.debug(startDate);
    log.debug(endDate);
    log.debug(location);
    log.debug(eventLink);
    try {
      if (this.isWeb === false) {
        // let options: CalendarOptions = {
        //   calendarId: this.credService.credentials.uid,
        //   id: String(this.credService.credentials.uid),
        //   firstReminderMinutes: 10
        // };
        // await this.calendar.createEventInteractivelyWithOptions(
        //   title ? String(title) : undefined,
        //   location ? String(location) : undefined,
        //   eventLink ? String(eventLink) : undefined,
        //   startDate ? startDate : undefined,
        //   endDate ? endDate : undefined,
        //   options
        // );
      }
    } catch (error) {
      log.error(error);
    }
  }
}
