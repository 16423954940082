import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { CameraSource } from '@capacitor/camera';
import { SafeResourceUrl } from '@angular/platform-browser';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { FilesService } from '@app/shared/services/files/files.service';
import { Device } from '@capacitor/device';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { Router } from '@angular/router';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { AppComponent } from '@app/app.component';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
const log = new Logger('Profile Image');

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
})
export class ProfileImageComponent implements OnInit {
  @Input() user: UserData;
  @Input() register: boolean = true;
  @Output() response = new EventEmitter<void>();
  @ViewChild('file', { read: ElementRef, static: true }) file: ElementRef;
  imageProfile: any;
  fileToUpload: any;
  isLoading: boolean;
  photo: SafeResourceUrl;
  @Input() submit: Subject<void>;
  thumbnailSrc: any;
  middleSrc: any;
  imageSrc: any;
  mainSubscription: Subscription;
  path = 'avatars';

  constructor(
    private loader: IonLoaderService,
    private actionSheetController: ActionSheetController,
    private utilities: UtilitiesService,
    private fireService: FirebaseService,
    private filesService: FilesService,
    private analyticsFB: AnalyticsService,
    public credService: CredentialsService,
    private router: Router,
    private app: AppComponent,
    private authServe: AuthenticationService,
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Imagen de perfil',
      buttons: [
        {
          text: 'Galería',
          icon: 'images',
          handler: () => {
            this.imageFromGallery();
          }
        },
        {
          text: 'Cámara',
          icon: 'camera',
          handler: () => {
            this.takePicture(CameraSource.Camera);
          }
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    actionSheet.present();
  }

  async ngOnInit() {
    this.getAvatar();
    try {
      if (this.submit) {
        this.submit.subscribe(() => {
          this.presentActionSheet();
        });
      }
    } catch (error) {
      log.error(error);
    }
  }

  private async getAvatar() {
    this.mainSubscription = this.fireService.afs
      .collection('users')
      .doc(this.user.uid)
      .valueChanges()
      .subscribe((snap: any) => {
        if (snap && snap.avatar && snap.avatar.main && snap.avatar.main.url) {
          this.imageSrc = snap.avatar.main.url;
        }
      });
  }

  triggerClick() {
    this.file.nativeElement.click();
  }

  // tslint:disable: member-ordering
  async imageFromGallery() {
    try {
      const info = await Device.getInfo();
      if (info && info.platform == 'web') {
        this.triggerClick();
      } else {
        this.takePicture(CameraSource.Photos);
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
  }

  async selectFile(data: any) {
    const file = data.files;
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.filesService.selectImageFile(file, this.path, 'avatar', this.user.uid);
      this.utilities.toast('Imagen de perfil actualizada correctamente.', 'Correcto');
      this.analyticsClickEvent('click', {
        content_type: 'Actualización imagen perfil',
        section_name: 'Perfil'
      });
      await this.updateCredentials();
      this.response.emit();
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async takePicture(image: CameraSource) {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.filesService.takePicture(image, this.path, 'avatar', this.user.uid);
      this.utilities.toast('Imagen de perfil actualizada correctamente.', 'Correcto');
      this.analyticsClickEvent('click', {
        content_type: 'Actualización imagen perfil',
        section_name: 'Perfil'
      });
      await this.updateCredentials();
      this.response.emit();
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async updateCredentials() {
    try {
      const currentUser = await this.authServe.getUser();
      await this.app.setUserDataAndCredentials(currentUser);
    } catch (error) {
      log.error(error);
    }
  }
}
