<ion-app>
  <ion-split-pane contentId="main">
    <ion-menu contentId="main">
      <ion-toolbar class="ion-no-margin ion-no-padding ion-hide-lg-up">
        <ion-buttons slot="end" class="ion-no-margin ion-no-padding">
          <ion-menu-toggle [autoHide]="false" class="ion-no-margin ion-no-padding">
            <ion-button class="ion-no-margin ion-no-padding">
              <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-menu-toggle>
        </ion-buttons>
      </ion-toolbar>
      <ion-content class="ion-no-padding">
        <div class="custom-content">
          <div class="ion-text-center">
            <ion-menu-toggle [autoHide]="false">
              <img src="/assets/img/logo-horizontal.png" style="display: inline-block;" alt="Logo Conectimed"
                class="logo-horizontal" (click)="router.navigateByUrl('/home')" />
            </ion-menu-toggle>
          </div>
          <hr>
          <ion-menu-toggle [autoHide]="false"
            *ngIf="credService?.credentials?.uid && credService?.credentials?.active === true">
            <ion-item lines="none" [routerLink]="['/my-profile']">
              <ion-avatar class="profile-image" slot="start">
                <img [src]="credService?.credentials?.profile?.img || '/assets/img/default-avatar.svg'" />
              </ion-avatar>
              <ion-label>
                <h2>{{ credService?.credentials?.profile?.name + ' '+ credService?.credentials?.profile?.lastName1 }}
                </h2>
                <p *ngIf="credService?.credentials?.type === 'profesional-de-la-salud'">
                  <small> <strong>Profesional de la salud</strong></small>
                </p>
                <p>{{ credService?.credentials?.type === 'medico' ? credService?.credentials?.speciality :
                  ( credService?.credentials?.type === 'profesional-de-la-salud' ?
                  credService?.credentials?.typeOfHealthcareProfessional: credService?.credentials?.company )}}</p>
                <p *ngIf="credService?.credentials?.type === 'representante-medico'">
                  <small> <strong>Plan:</strong> {{ credService?.credentials?.plan?.name }} </small>
                </p>
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <div *ngIf="!!!credService?.credentials?.uid">
            <hr>
            <hr>
            <hr>
            <ion-menu-toggle [autoHide]="false">
              <ion-button (click)="modalLogin()" color="secondary" expand="block" fill="outline">
                <ion-icon slot="start" name="log-in-outline"></ion-icon>
                Ingresar
              </ion-button>
            </ion-menu-toggle>
            <div class="custom-div"><span>Ó</span></div>
            <ion-menu-toggle [autoHide]="false">
              <ion-button [routerLink]="['/register']" color="secondary" expand="block" fill="outline">
                <ion-icon slot="start" name="person-add-outline"></ion-icon>
                Registrarme
              </ion-button>
            </ion-menu-toggle>
          </div>
          <div
            *ngIf="credService?.credentials?.uid && credService?.credentials?.active !== true && pathDataRoute === 'home'">
            <hr>
            <hr>
            <hr>
            <ion-menu-toggle [autoHide]="false">
              <ion-button [routerLink]="['/register/account-activation']" color="secondary" expand="block"
                fill="outline">
                <ion-icon slot="start" name="person-outline"></ion-icon>
                Activar mi cuenta
              </ion-button>
            </ion-menu-toggle>
          </div>
        </div>
        <div *ngIf="credService?.credentials?.uid && credService?.credentials?.active === true">
          <div class="custom-content custom-float">
            <div class="menu-ctm">
              <div class="menu-item-ctm" *ngFor="let mainItem of appPages">
                <div *ngIf="mainItem?.items?.length > 0; else normalItem1">
                  <ion-accordion-group *ngIf="mainItem?.user?.indexOf(credService?.credentials?.type) > -1"
                    [multiple]="true" [value]="mainItem?.collapsed == true ? ['first']: null">
                    <ion-accordion value="first">
                      <ion-item slot="header" [color]="mainItem?.collapsed === true ? 'stable' : 'light'"
                        class="line-top">
                        <ion-icon color="secondary" slot="start" *ngIf="mainItem?.icon?.src as src"
                          [src]="src"></ion-icon>
                        <ion-icon color="secondary" slot="start" *ngIf="mainItem?.icon?.name as name"
                          [name]="name"></ion-icon>
                        <ion-label color="secondary">{{ mainItem?.title }}</ion-label>
                      </ion-item>
                      <div class="ion-padding" slot="content">
                        <ion-menu-toggle [autoHide]="false">
                          <div *ngFor="let subItem of mainItem?.items" class="ion-margin-start">
                            <ion-button expand="block" [fill]="subItem?.selected === true ? 'solid' : 'clear'"
                              [color]="subItem?.selected === true ? 'stable' :'dark'" size="small"
                              class="ion-no-margin ion-text-start no-shadow" [routerLink]="[subItem?.url]"
                              *ngIf="subItem?.user?.indexOf(credService?.credentials?.type) > -1">
                              <span class="align-left">
                                <ion-icon color="secondary" slot="start" *ngIf="subItem?.icon?.src as src" [src]="src"
                                  size="small" class="ion-margin-end"></ion-icon>
                                <ion-icon color="secondary" slot="start" *ngIf="subItem?.icon?.name as name"
                                  [name]="name" size="small" class="ion-margin-end"></ion-icon>
                                <span class="algin-vertical ion-margin-end">{{subItem?.title}}</span>
                              </span>
                            </ion-button>
                          </div>
                        </ion-menu-toggle>
                      </div>
                    </ion-accordion>
                  </ion-accordion-group>
                </div>
                <ng-template #normalItem1>
                  <div class="menu-item-ctm" *ngIf="mainItem?.user?.indexOf(credService?.credentials?.type) > -1">
                    <ion-menu-toggle [autoHide]="false">
                      <ion-item [color]="mainItem?.selected === true ? 'stable' : 'light'" [button]="true" lines="none"
                        class="line-top" [routerLink]="[mainItem?.url]">
                        <ion-icon color="secondary" slot="start" *ngIf="mainItem?.icon?.src as src"
                          [src]="src"></ion-icon>
                        <ion-icon color="secondary" slot="start" *ngIf="mainItem?.icon?.name as name"
                          [name]="name"></ion-icon>
                        <ion-label color="secondary">{{ mainItem?.title }}</ion-label>
                        <ion-badge color="primary"
                          *ngIf="mainItem?.highlight && highlight[mainItem?.highlight] && highlight[mainItem?.highlight] > 0">{{
                          highlight[mainItem?.highlight] }}</ion-badge>
                      </ion-item>
                    </ion-menu-toggle>
                  </div>
                </ng-template>
              </div>
            </div>
            <hr>
          </div>
          <div class="custom-content">
            <ion-menu-toggle [autoHide]="false">
              <ion-button (click)="alertConfirmLogout()" size="small" color="primary" expand="block" shape="round">
                Cerrar Sesión
              </ion-button>
            </ion-menu-toggle>
          </div>
        </div>
      </ion-content>
    </ion-menu>
    <div class="ion-page" id="main">
      <ion-header *ngIf="hideHeader !== true" mode="ios">
        <ion-toolbar class="animate__animated animate__fadeIn" color="primary" *ngIf="searchBar === false">
          <ion-buttons slot="start" class="custom-searchbar">
            <ion-menu-button color="light"></ion-menu-button>
            <ion-button class="ion-hide-lg-up" [routerLink]="['/home']" color="light" *ngIf="!!!hideBackButton === true">
              <ion-icon slot="icon-only" name="home-outline" style="font-size: 23px"></ion-icon>
            </ion-button>
            <ion-button (click)="back()" *ngIf="!!!hideBackButton === true">
              <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
            </ion-button>
            <!-- <ion-button *ngIf="root" [routerLink]="root ? [root] : null">
              <ion-icon slot="icon-only" *ngIf="icon?.src as src" [src]="src"></ion-icon>
              <ion-icon slot="icon-only" *ngIf="icon?.name as name" [name]="name"></ion-icon>
            </ion-button> -->
            <ion-searchbar class="ion-hide-md-down"
              [disabled]="!!!(credService?.credentials?.uid && credService?.credentials?.active === true) || !!!searchRoute"
              (keypress)="keypressEvent($event)" [(ngModel)]="searchStr" slot="start" search-icon="search-outline"
              [placeholder]="placeholder ? placeholder : 'Buscar'" mode="md" style="margin-top: 3px;"
              (ionCancel)="ionCancel()" (ionClear)="ionClear()" animated="true"
              clear-icon="trash-outline"></ion-searchbar>
            <ion-button class="ion-hide-md-down" (click)="doSearch()"
              *ngIf="!!(credService?.credentials?.uid && credService?.credentials?.active === true)">
              <ion-icon slot="icon-only" name="search-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title class="ion-hide-md-up">
            <img src="/assets/img/logo-icon-only.png" style="display: inline-block;" alt="Logo Conectimed"
              class="logo-horizontal" (click)="router.navigateByUrl('/home')" />
          </ion-title>
          <ion-buttons slot="end" *ngIf="credService?.credentials?.uid && credService?.credentials?.active === true">
            <ion-button class="ion-hide-md-up" (click)="searchBar = !searchBar"
              *ngIf="!!(credService?.credentials?.uid && credService?.credentials?.active === true)">
              <ion-icon slot="icon-only" name="search-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="openNotifications($event)" color="light">
              <ion-icon slot="icon-only" name="notifications-outline" style="font-size: 23px" class="roud"></ion-icon>
              <ion-badge color="danger" size="small" class="badge-class" mode="ios"
                style="top: -4px; margin-right: -22px" *ngIf="notificationsCount > 0">{{ notificationsCount
                }}</ion-badge>
            </ion-button>
            <ion-avatar class="ion-hide-md-up" style="width: 28px !important; height: 28px !important;"
              [routerLink]="['/my-profile']">
              <img [src]="credService?.credentials?.profile?.img || '/assets/img/default-avatar.svg'" />
            </ion-avatar>
          </ion-buttons>
          <ion-buttons class="ion-hide-md-up" slot="end"
            *ngIf="!!!(credService?.credentials?.uid && credService?.credentials?.active === true)"
            (click)="modalLogin()">
            <ion-button fill="outline" size="small" shape="round" style="margin-top: 5px;">
              <strong style="display: inline-block; margin-left: 10px; margin-right: 10px;">Ingresar</strong>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-toolbar class="animate__animated animate__fadeIn" color="primary" *ngIf="searchBar === true">
          <ion-buttons slot="start">
            <ion-searchbar
              [disabled]="!!!(credService?.credentials?.uid && credService?.credentials?.active === true) || !!!searchRoute"
              (keypress)="keypressEvent($event)" [(ngModel)]="searchStr" slot="start" search-icon="search-outline"
              [placeholder]="placeholder ? placeholder : 'Buscar'" mode="md" style="margin-top: 3px;"
              (ionCancel)="ionCancel()" (ionClear)="ionClear()" animated="true"
              clear-icon="trash-outline"></ion-searchbar>
            <ion-button (click)="doSearch()">
              <ion-icon slot="icon-only" name="search-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="searchBar = !searchBar">
              <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-router-outlet id="main"></ion-router-outlet>
      </ion-content>
    </div>
  </ion-split-pane>
</ion-app>