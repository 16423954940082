<ion-card class="transparent item-folder">
  <ion-card-content style="padding: 0 !important;">
    <div class="width-70">
      <swiper-container data-swiper-autoplay="5000" autoplay="true" navigation="false" pagination="true"
        [autoHeight]="true">
        <swiper-slide *ngFor="let item of items">
          <div class="ctm-overlay-main-wrap">
            <img [src]="item?.image" [alt]="item?.title" />
            <div class="ctm-overlay-text-wrap">
              <div class="ctm-overlay-text">
                <ion-text color="light">
                  <div style="padding-left: 2%; padding-right: 2%;">
                    <h2 style="cursor: pointer;" [routerLink]="item?.routing?.route" class="ion-text-center">{{
                      item?.title }}
                    </h2>
                  </div>
                </ion-text>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
  </ion-card-content>
</ion-card>