import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { SharedModule } from '@app/shared/shared.module';
import { PullToRefreshModule } from '@app/shared/components/pull-to-refresh/pull-to-refresh.module';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    ProfileRoutingModule,
    SharedModule,
    PullToRefreshModule
  ],
  declarations: [ProfileComponent, ChangePasswordComponent]
})
export class ProfileModule { }
