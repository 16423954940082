<form [formGroup]="dataForm" (ngSubmit)="submit()">
  <ion-list>
    <ion-list-header>
      <ion-label color="secondary">Información profesional</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-select label="Especialidad (1) *" placeholder="Seleciona especialidad" formControlName="specialty1" autofocus="true">
        <ion-select-option *ngFor="let item of specialties" [value]="item.id">{{ item.name }} </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('specialty1').touched && dataForm.get('specialty1').getError('required')"
    >
      <span class="ion-padding" >La primer especialidad es requerida</span>
    </ion-text>
    <ion-item>
      <ion-input label="Cédula profesional (1) *" type="text" formControlName="professionalID1"></ion-input>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('professionalID1').touched && dataForm.get('professionalID1').getError('required')"
    >
      <span class="ion-padding" >La primer cédula profesional es requerida</span>
    </ion-text>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('professionalID1').touched && dataForm.get('professionalID1').getError('whitespace')"
    >
      <span class="ion-padding" >La primer cédula profesional inválida, no usar espacios en blanco</span>
    </ion-text>
    <ion-item>
      <ion-select label="Especialidad (2)" placeholder="Seleciona especialidad" formControlName="specialty2" (ionChange)="especialty($event)">
        <ion-select-option [value]="0">-- Ninguna --</ion-select-option>
        <ion-select-option *ngFor="let item of specialties" [value]="item.id">{{ item.name }} </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item *ngIf="dataForm.value.specialty2 > 0">
      <ion-input label="Cédula profesional (2) *" type="text" formControlName="professionalID2"></ion-input>
    </ion-item>
  </ion-list>
  <ion-text
    *ngIf="dataForm.value.specialty2 > 0 && dataForm.value.specialty1 === dataForm.value.specialty2"
    color="danger"
  >
    <p>* Las especialidades deben ser diferentes.</p>
  </ion-text>
  <ion-text
    *ngIf="
      dataForm.value.specialty2 > 0 &&
      dataForm.value.professionalID2 != '' &&
      dataForm.value.professionalID1 === dataForm.value.professionalID2
    "
    color="danger"
  >
    <p>* Las cédulas deben ser diferentes.</p>
  </ion-text>
  <ion-text color="danger">
    <p>* Datos obligatorios</p>
  </ion-text>
  <div class="ion-padding">
    <ion-button
      type="submit"
      color="tertiary"
      expand="block"
      [disabled]="
        !dataForm.valid ||
        isLoading ||
        dataForm.value.specialty1 === dataForm.value.specialty2 ||
        dataForm.value.professionalID1 === dataForm.value.professionalID2
      "
    >
      {{ button }}
    </ion-button>
  </div>
</form>
