import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core/logger.service';
import { ModalController } from '@ionic/angular';
import { LoginComponent } from '@app/login/login.component';
import { CredentialsService } from '@app/core/authentication/credentials.service';
const log = new Logger('InviteAlertComponent');
@Component({
  selector: 'app-invite-alert',
  templateUrl: './invite-alert.component.html',
  styleUrls: ['./invite-alert.component.scss'],
})
export class InviteAlertComponent implements OnInit {
 

  constructor(
    private modalCtrl: ModalController,
    public credService: CredentialsService,
  ) { }

  ngOnInit() {}

  closeModal() {
    this.modalCtrl.dismiss();
  }

  openLogin() {
    this.closeModal();
    this.modalLogin();
  }

  public async modalLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent
    });
    modal.present();
  }
}
