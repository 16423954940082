import { Component, OnInit, Input } from '@angular/core';

interface Page {
  color: string;
  index: number;
}

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  @Input() numberPages: number;
  @Input() current: number = 0;
  pages: Page[] = [];

  constructor() {}

  ngOnInit() {
    for (let i = 0; i < this.numberPages; i++) {
      this.pages.push({ index: i, color: '' });
    }
  }
}
