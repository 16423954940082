<ion-header *ngIf="step == 1">
  <ion-toolbar color="primary">
    <ion-buttons slot="start" class="buttons-start">
      <!-- <ion-button
        [routerDirection]="'forward'"
        [routerLink]="['/home/actions/open-login']"
        routerLinkActive="active"
        *ngIf="paso <= 1"
      >
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="paso > 1" (click)="slidePrev()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button> -->
    </ion-buttons>
    <ion-title class="ion-text-center">Validación de registro</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="step == 1">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-list>
          
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-header *ngIf="step == 2">
  <ion-toolbar color="primary">
    <ion-buttons slot="start" class="buttons-start">
      <!-- <ion-button
        [routerDirection]="'forward'"
        [routerLink]="['/home/actions/open-login']"
        routerLinkActive="active"
        *ngIf="paso <= 1"
      >
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button> -->
    </ion-buttons>
    <ion-title class="ion-text-center">Bienvenido!</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="step == 2">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <form [formGroup]="myForm2" (ngSubmit)="submit()">
            <ion-list>
              <ion-list-header>
                <ion-label color="secondary">Información de acceso</ion-label>
              </ion-list-header>
              <div class="item-container">
                <ion-item>
                  <ion-label position="stacked">Email <ion-text color="danger">*</ion-text> </ion-label>
                  <ion-input type="text" formControlName="email" autofocus="true" readonly></ion-input>
                </ion-item>
                <ion-text
                  class="item-with-error"
                  color="danger"
                  *ngIf="myForm2.get('email').touched && myForm2.get('email').getError('required')"
                >
                  <span class="ion-padding" translate>El email es requerido</span>
                </ion-text>
                <ion-text
                  class="item-with-error"
                  color="danger"
                  *ngIf="
                    myForm2.get('email').touched &&
                    myForm2.get('email').getError('pattern') &&
                    !myForm2.get('email').getError('required')
                  "
                >
                  <span class="ion-padding" translate>El correo electrónico es incorrecto</span>
                </ion-text>
              </div>
              <ion-item>
                <ion-label position="stacked">Crear contraseña <ion-text color="danger">*</ion-text> </ion-label>
                <ion-input
                  #passwordEyeRegister1
                  [type]="passwordTypeInput1"
                  clearOnEdit="false"
                  formControlName="password1"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode1()">
                  <ion-icon [name]="passwordTypeInput1 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="myForm2.get('password1').touched && myForm2.get('password1').getError('required')"
              >
                <span class="ion-padding" translate>La contraseña es requerida</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="myForm2.get('password1').touched && myForm2.get('password1').getError('minlength')"
              >
                <span class="ion-padding" translate>La contraseña debe tener al menos 6 carácteres</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="myForm2.get('password1').touched && myForm2.get('password1').getError('whitespace')"
              >
                <span class="ion-padding" translate>Contraseña inválida, no usar espacios en blanco</span>
              </ion-text>
              <ion-item>
                <ion-label position="stacked">Repetir contraseña <ion-text color="danger">*</ion-text> </ion-label>
                <ion-input
                  #passwordEyeRegister2
                  [type]="passwordTypeInput2"
                  clearOnEdit="false"
                  formControlName="password2"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode2()">
                  <ion-icon [name]="passwordTypeInput2 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="myForm2.get('password2').touched && myForm2.get('password2').getError('required')"
              >
                <span class="ion-padding" translate>Debes repetir la contraseña</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="myForm2.get('password2').touched && myForm2.get('password2').getError('mustMatch')"
              >
                <span class="ion-padding" translate>La contraseñas deben de coincidir</span>
              </ion-text>
              <ion-item>
                <ion-grid>
                  <ion-row>
                    <ion-col size="2">
                      <ion-checkbox formControlName="termsAndConditions"></ion-checkbox>
                    </ion-col>
                    <ion-col size="10">
                      <br />Acepto <a>Términos y Condiciones</a>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ion-list>
            <ion-list> </ion-list>
            <ion-text color="danger">
              <p>* Datos obligatorios</p>
            </ion-text>
            <div class="ion-padding">
              <ion-button
                type="submit"
                color="tertiary"
                expand="block"
                [disabled]="
                  !myForm2.valid ||
                  myForm2.value.termsAndConditions == false ||
                  myForm2.value.password1 !== myForm2.value.password2 ||
                  isLoading
                "
              >
                Siguiente
              </ion-button>
            </div>
          </form>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-header *ngIf="step == 3">
  <ion-toolbar color="primary">
    <ion-buttons slot="start" class="buttons-start">
      <!-- <ion-button
        [routerDirection]="'forward'"
        [routerLink]="['/home/actions/open-login']"
        routerLinkActive="active"
        *ngIf="paso <= 1"
      >
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button> -->
    </ion-buttons>
    <ion-title class="ion-text-center">Bienvenido!</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="step == 3">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <h2 class="ion-text-center">Seleccione la foto del perfil</h2>
        <app-profile-image [user]="userData"></app-profile-image>
        <div class="ion-padding">
          <ion-button [routerLink]="['/']" expand="block">Finalizar</ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
