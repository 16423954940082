<ion-card class="ion-no-margin ion-no-padding ion-margin-horizontal fold">
  <div color="primary" class="fold-title">
    <h1 class="ion-no-margin ion-no-padding">{{ futureSessions === true ? 'Sesiones virtuales futuras' : 'Sesiones virtuales' }}</h1>
  </div>
  <span class="fold"></span>
</ion-card>

<div *ngIf="isLoading === true">
  <ion-card color="dark-light" class="border" *ngFor="let o of [0, 0, 0]; let i = index">
    <ion-grid [fixed]="false" class="ion-no-padding">
      <ion-row class="ion-align-items-center ion-no-padding">
        <ion-col size="6" class="ion-no-padding">
          <ion-thumbnail>
            <ion-skeleton-text [animated]="true"></ion-skeleton-text>
          </ion-thumbnail>
        </ion-col>
        <ion-col size="6" class="ion-text-center">
          <p><ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text></p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</div>

<ion-card color="dark-light" class="border ion-no-margin ion-margin-horizontal" [button]="true" *ngFor="let item of posts; let i = index"
  [routerLink]="futureSessions === true ? null : [item?.routing?.route]" [ngClass]="{'ion-hide-md-down': i > 2 }">
  <ion-grid [fixed]="false" class="ion-no-padding">
    <ion-row class="ion-align-items-center ion-no-padding">
      <ion-col size="6" class="ion-no-padding">
        <img [src]="item?.image" class="img-100">
      </ion-col>
      <ion-col size="6" class="ion-text-center">
        <p>{{ item?.title }}</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>

<ion-button expand="block" fill="clear" [routerLink]="['/multimedia/videos/webinars']" class="ion-hide-md-up">
  <ion-icon slot="end" name="add-outline"></ion-icon>
  Más sesiones Virtuales
</ion-button>

<div *ngIf="!isLoading === true && !posts[0]">
  <app-no-results></app-no-results>
</div>