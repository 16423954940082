import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';

@Component({
  selector: 'app-i-rep-card',
  templateUrl: './i-rep-card.component.html',
  styleUrls: ['./i-rep-card.component.scss'],
})
export class IRepCardComponent implements OnInit {
  @Input() user: any;
  @Input() isMyProduct: boolean;

  constructor(
    private router: Router,
    private credService: CredentialsService,
    private analyticsFB: AnalyticsService
  ) { }

  ngOnInit() { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }
}
