<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">Seleccione la locación</ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding ion-no-margin">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="dataForm" (ngSubmit)="save()">
          <ion-list>
            <ion-item>
              <ion-label position="stacked">Estado<ion-text color="danger">*</ion-text> </ion-label>
              <ion-select placeholder="Seleccione el estado..." formControlName="state" (ionChange)="changeEdo($event)">
                <ion-select-option *ngFor="let state of states" [value]="state?.label">
                  {{ state?.label }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Ciudad / Delegación<ion-text color="danger">*</ion-text> </ion-label>
              <ion-select placeholder="Seleccione la ciudad..." formControlName="city">
                <ion-select-option *ngFor="let city of cities" [value]="city">
                  {{ city }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Colonia <ion-text color="danger">*</ion-text> </ion-label>
              <ion-input type="text" formControlName="colony"></ion-input>
            </ion-item>
          </ion-list>
          <ion-button type="submit" color="tertiary" expand="block" [disabled]="!dataForm.valid">
            Agregar
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
