import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoAuthenticatedGuard } from '@app/core/authentication/guards/no-authenticated.guard';
import { AuthenticationGuard } from '@app/core/authentication/guards/authentication.guard';
import { NoActivatedUserGuard } from '@app/core/authentication/guards/no-activated-user.guard';
import { MedicoGuard } from '@app/core/authentication/guards/medico.guard';
import { ProfesionalDeLaSaludGuard } from '@app/core/authentication/guards/profesional-de-la-salud.guard';
import { RepresentanteMedicoGuard } from '@app/core/authentication/guards/representante-medico.guard';
import { RegisterComponent } from './register.component';
import { WaitingRoomComponent } from './waiting-room/waiting-room.component';
import { RepresentanteComponent } from './representante/representante.component';

const routes: Routes = [
  {
    path: '',
    component: RegisterComponent,
    data: {
      title: 'Registro',
      icon: {
        name: "person-add-outline"
      },
      hideHeader: true
    },
    canActivate: [NoAuthenticatedGuard]
  },
  {
    path: 'account-activation',
    component: WaitingRoomComponent,
    data: {
      title: 'Activación de cuenta',
      icon: {
        name: "person-add-outline"
      },
      hideHeader: true
    },
    canActivate: [AuthenticationGuard, NoActivatedUserGuard]
  },
  {
    path: 'complete-registration',
    component: RegisterComponent,
    data: {
      title: 'Activación de cuenta',
      icon: {
        name: "person-add-outline"
      },
      hideHeader: true
    },
    canActivate: [AuthenticationGuard, MedicoGuard, ProfesionalDeLaSaludGuard]
  },
  {
    path: 'representante-medico',
    component: RepresentanteComponent,
    data: {
      title: 'Registro de Médicos',
      icon: {
        name: "person-add-outline"
      },
      hideHeader: true
    },
    canActivate: [NoAuthenticatedGuard]
  },
  {
    path: 'representante-medico/complete-registration',
    component: RepresentanteComponent,
    data: {
      title: 'Registro de Médicos',
      icon: {
        name: "person-add-outline"
      },
      hideHeader: true
    },
    canActivate: [AuthenticationGuard, RepresentanteMedicoGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterRoutingModule { }
