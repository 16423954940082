import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpHeaders, HttpOptions } from '@capacitor/core';
export { HttpHeaders };

export interface HttpResponse {
  body?: any,
  headers?: any
}

interface Options {
  observe?: 'body' | 'response' | 'events';
  headers?: HttpHeaders
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  httpOptionsBlob: HttpHeaders = {
    responseType: "blob",
    observe: "response"
  };

  constructor() { }

  /**
  *
  * @param url
  * @param options
  */

  async get(url: string, options?: Options): Promise<any> {
    let _return: any = {};
    let _options: HttpOptions = { url: url };

    if (options && options.headers) {
      _options.headers = options.headers;
    }

    const resp = await CapacitorHttp.get(_options);
    _return = resp;

    if (options && options.observe && options.observe === 'response') {
      _return.body = _return.data;
    } else if (options && options.observe && options.observe === 'body') {
      _return = _return.data;
    } else {
      _return = _return.data;
    }

    return await _return;
  }

  /**
  *
  * @param url
  * @param body
  * @param options
  */

  async post(url: string, body: any, options?: Options): Promise<any> {
    let _return: any = {};
    let _options: HttpOptions = { url: url };

    if (options && options.headers) {
      _options.headers = options.headers;
    }

    if (body) {
      _options.data = body;
    }

    const resp = await CapacitorHttp.post(_options);
    _return = resp;

    if (options && options.observe && options.observe === 'response') {
      _return.body = _return.data;
    } else if (options && options.observe && options.observe === 'body') {
      _return = _return.data;
    } else {
      _return = _return.data;
    }

    return await _return;
  }

  /**
  *
  * @param url
  * @param options
  */

  async delete(url: string, options?: Options): Promise<any> {
    let _return: any = {};
    let _options: HttpOptions = { url: url };

    if (options && options.headers) {
      _options.headers = options.headers;
    }

    const resp = await CapacitorHttp.delete(_options);
    _return = resp;

    if (options && options.observe && options.observe === 'response') {
      _return.body = _return.data;
    } else if (options && options.observe && options.observe === 'body') {
      _return = _return.data;
    } else {
      _return = _return.data;
    }

    return await _return;
  }

  // /**
  //  *
  //  * @param url
  //  * @param request
  //  */
  // put(url: string, request: any): any {
  //   return this.http.put(url, request).pipe(catchError(this.handleHttpErrors));
  // }

  // /**
  //  * 
  //  * @param url
  //  */
  // getBlob(url: string): any {
  //   return this.http.get(url, { headers: this.httpOptionsBlob }).pipe(catchError(this.handleHttpErrors));
  // }

  // /**
  //  *
  //  * @param url
  //  * @param formData
  //  */
  // multipartPost(url: string, formData: FormData): any {
  //   return this.http
  //     .post(url, formData)
  //     .pipe(catchError(this.handleHttpErrors));
  // }

  // /**
  //  * 
  //  * @param error 
  //  */
  // private handleHttpErrors(error: Response) {
  //   switch (error.status) {
  //     case 503 || 500:
  //       this.routeToErrorView();
  //       return throwError(() => error);
  //     case 401 || 403: // unauthorised
  //       this.routeToUnauthorisedView()
  //       return throwError(() => error);
  //     case 404:
  //       return throwError(() => error);
  //     default:
  //       break;
  //   }
  //   return undefined
  // }

  // routeToErrorView() {
  //   this.router.navigate(["/error"]);
  // }

  // routeToUnauthorisedView() {
  //   this.router.navigate(["/unauthorised"]);
  // }

}
