<form [formGroup]="dataForm" (ngSubmit)="submit()">
  <ion-list>
    <ion-list-header>
      <ion-label color="secondary">Información de acceso</ion-label>
    </ion-list-header>
    <div class="item-container">
      <ion-item>
        <!-- <ion-label position="stacked">Email <ion-text color="danger">*</ion-text> </ion-label> -->
        <ion-input
          label="Email *"
          type="text"
          formControlName="email"
          autofocus="true"
          autocomplete="off"
          placeholder="micorreo@dominio.com"
          (ionChange)="noWhiteSpaces($event, 'email')"
          appCopyPaste
        ></ion-input>
      </ion-item>
      <ion-text
        class="item-with-error"
        color="danger"
        *ngIf="dataForm.get('email').touched && dataForm.get('email').getError('required')"
      >
        <span class="ion-padding" >El email es requerido</span>
      </ion-text>
      <ion-text
        class="item-with-error"
        color="danger"
        *ngIf="
          dataForm.get('email').touched &&
          dataForm.get('email').getError('pattern') &&
          !dataForm.get('email').getError('required')
        "
      >
        <span class="ion-padding" >El correo electrónico es incorrecto</span>
      </ion-text>
      <ion-item>
        <!-- <ion-label position="stacked">Confirma Email <ion-text color="danger">*</ion-text> </ion-label> -->
        <ion-input
          label="Confirma Email *"
          type="text"
          formControlName="email2"
          autofocus="true"
          autocomplete="off"
          placeholder="Repite tu Email"
          (ionChange)="noWhiteSpaces($event, 'email2')"
          appCopyPaste
        ></ion-input>
      </ion-item>
      <ion-text
        class="item-with-error"
        color="danger"
        *ngIf="dataForm.get('email2').touched && dataForm.get('email2').getError('required')"
      >
        <span class="ion-padding" >Confirmar Email es requerido</span>
      </ion-text>
      <ion-text
        class="item-with-error"
        color="danger"
        *ngIf="
          dataForm.get('email2').touched &&
          dataForm.get('email2').getError('pattern') &&
          !dataForm.get('email2').getError('required')
        "
      >
        <span class="ion-padding" >Confirmar Email es incorrecto</span>
      </ion-text>
      <ion-text
        class="item-with-error"
        color="danger"
        *ngIf="dataForm.get('email2').touched && dataForm.get('email2').getError('mustMatch')"
      >
        <span class="ion-padding" >Los Emails deben de coincidir</span>
      </ion-text>
    </div>
    <ion-item>
      <!-- <ion-label position="stacked">Crear contraseña <ion-text color="danger">*</ion-text> </ion-label> -->
      <ion-input
        label="Crear contraseña *"
        #passwordEyeRegister1
        [type]="passwordTypeInput1"
        clearOnEdit="false"
        formControlName="password1"
      ></ion-input>
      <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode1()">
        <ion-icon [name]="passwordTypeInput1 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
      </button>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('password1').touched && dataForm.get('password1').getError('required')"
    >
      <span class="ion-padding" >La contraseña es requerida</span>
    </ion-text>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('password1').touched && dataForm.get('password1').getError('minlength')"
    >
      <span class="ion-padding" >La contraseña debe tener al menos 6 carácteres</span>
    </ion-text>
    <ion-item>
      <!-- <ion-label position="stacked">Repetir contraseña <ion-text color="danger">*</ion-text> </ion-label> -->
      <ion-input
        label="Repetir contraseña *"
        #passwordEyeRegister2
        [type]="passwordTypeInput2"
        clearOnEdit="false"
        formControlName="password2"
      ></ion-input>
      <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode2()">
        <ion-icon [name]="passwordTypeInput2 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
      </button>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('password2').touched && dataForm.get('password2').getError('required')"
    >
      <span class="ion-padding" >Debes repetir la contraseña</span>
    </ion-text>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('password2').touched && dataForm.get('password2').getError('mustMatch')"
    >
      <span class="ion-padding" >La contraseñas deben de coincidir</span>
    </ion-text>
    <ion-item>
      <ion-grid>
        <ion-row>
          <ion-col size="2">
            <ion-checkbox formControlName="termsAndConditions"></ion-checkbox>
          </ion-col>
          <ion-col size="10"> <br />Acepto <a (click)="termOfUseComponent()">Términos y Condiciones</a> </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-list>
  <ion-text color="danger">
    <p>* Datos obligatorios</p>
  </ion-text>
  <div class="ion-padding">
    <ion-button
      type="submit"
      color="tertiary"
      expand="block"
      [disabled]="
        !dataForm.valid ||
        dataForm.value.termsAndConditions == false ||
        dataForm.value.password1 !== dataForm.value.password2 ||
        isLoading
      "
    >
      Siguiente
    </ion-button>
  </div>
</form>
