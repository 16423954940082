import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoAuthenticatedGuard } from '@app/core/authentication/guards/no-authenticated.guard';
import { ResetPasswordComponent } from './reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: ResetPasswordComponent,
    data: { title: 'Reset Password' },
    canActivate: [NoAuthenticatedGuard]
  },
  {
    path: ':email/:code',
    component: ResetPasswordComponent,
    data: { title: 'Reset Password' },
    canActivate: [NoAuthenticatedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ResetPasswordRoutingModule { }
