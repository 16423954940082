import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserCardComponent } from './user-card/user-card.component';
import { SelectUserCardComponent } from './select-user-card/select-user-card.component';
import { PipesModule } from '@app/pipes/pipes.module';
@NgModule({
  declarations: [
    UserCardComponent,
    SelectUserCardComponent
  ],
  exports: [
    UserCardComponent,
    SelectUserCardComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    FormsModule,
    RouterModule,
    PipesModule
  ]
})
export class CardsModule { }
