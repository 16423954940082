import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PreregistroComponent } from './preregistro.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  {
    path: 'validacion-de-registro',
    component: PreregistroComponent,
    data: { title: 'Validación de registro' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class PreregistroRoutingModule {}
