import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Query, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { IndustryRoutesService } from '@app/industry/services/industry-routes.service';
import { SearchService } from '@app/shared/services/search/search.service';

@Component({
  selector: 'app-i-companies',
  templateUrl: './i-companies.component.html',
  styleUrls: ['./i-companies.component.scss'],
})
export class ICompaniesComponent implements OnInit {
  public searchStr: string = null;
  public isLoading: boolean;
  private start: any = null;
  public companies: any[] = [];

  constructor(
    public router: Router,
    private aRoute: ActivatedRoute,
    public analyticsFB: AnalyticsService,
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private industryRoutesService: IndustryRoutesService,
    private searchService: SearchService
  ) { }

  async ngOnInit() {
    this.aRoute.params.subscribe(params => {
      this.searchStr = this.searchService.decodeString(params && params.search ? params.search : undefined);
      this.start = null;
      if (this.searchStr) {
        this.analyticsClickEvent('search', {
          search_term: params.search,
          content_type: 'Búsqueda producto',
          section_name: 'Productos'
        });
      }
      this.getCompanies();
    });
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async loadData(event: any) {
    await this.getCompanies();
    if (event && event.target && event.target.complete) {
      event.target.complete();
    }
  }

  async getCompanies() {
    this.isLoading = true;
    const ref = await this.fireService.afs.collection('companies').ref.orderBy('nameStr','asc');
    let query: Query = ref;
    if (this.searchStr) {
      query = ref.where('search', 'array-contains', this.searchStr);
    }
    if (this.start) {
      query = ref.startAfter(this.start);
    }
    const snapshots = await query
      .limit(20)
      .where('status', '==', 'active')
      .get();
    if (snapshots.docs.length !== 20) {

    }
    if (!snapshots.empty) {
      this.start = snapshots.docs[snapshots.docs.length - 1];
    }
    await snapshots.docs.map(element => {
      let data: any = element.data();
      const id: string = element.id;
      data.routing = this.industryRoutesService.mapRoute('Company', id);
      if (!data.logo) {
        const name: string = data.name;
        const split = name.split(' ');
        if (split.length > 1) {
          data.nameLogo = split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase();
        } else {
          data.nameLogo = name.charAt(0).toUpperCase();
        }
      }
      this.companies.push({ id, ...data });
    });
    this.isLoading = false;
  }
}
