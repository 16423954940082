import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, Platform, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailService } from '@app/shared/services/email/email.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { AppComponent } from '@app/app.component';
import { App } from '@capacitor/app';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('RepresentanteComponent');

@Component({
  selector: 'app-representante',
  templateUrl: './representante.component.html',
  styleUrls: ['./representante.component.scss']
})
export class RepresentanteComponent implements OnInit {
  @ViewChild(IonContent, { read: IonContent, static: true }) content: IonContent;
  error: string | undefined;
  isLoading = false;
  subscription: Subscription;
  counter: number = 0;
  public data1: any;
  public numberPages: number;
  paso: number = 0;
  showWebWarning = false;
  completeYourRegister: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loader: IonLoaderService,
    private platform: Platform,
    private toastCtrl: ToastController,
    private email: EmailService,
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    public credService: CredentialsService,
    private authServe: AuthenticationService,
    private app: AppComponent
  ) { }

  ionViewDidEnter() {
    this.initializeApp();
  }

  ngOnInit() { }

  initializeApp() {
    if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
      this.completeYourRegister = true;
      this.step1();
      this.isLoading = false;
    } else {
      this.completeYourRegister = false;
    }
    this.subscription = this.platform.backButton.subscribe(() => {
      if (this.counter == 0) {
        this.counter++;
        this.presentToast();
        setTimeout(() => {
          this.counter = 0;
        }, 3000);
      } else {
        App.exitApp();
      }
    });
  }

  ngOnDestroy() {
    log.debug("========================= ngOnDestroy =========================");
    this.subscription.unsubscribe();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Presione nuevamente para salir.',
      duration: 3000
    });
    toast.present();
  }

  public async step1(ev?: any): Promise<void> {
    ev;
    this.paso = 1;
    this.scrollToTop();
  }

  public async userActivation(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.fireService.activateUser(this.credService.credentials.uid);
      this.email.registerAndWelcome(this.credService.credentials.uid);
      this.slideNext();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async finalize() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.fireService.activateUser(this.credService.credentials.uid, false);
      const currentUser = await this.authServe.currentUser();
      await this.app.setUserDataAndCredentials(currentUser);
      this.analyticsClickEvent('click', {
        content_type: 'Finalizado',
        section_name: 'Registro de usuario',
        click_text: 'Finalizar',
        user_type: 'representante-medico'
      });
      this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async slidePrev(): Promise<void> {
    if (!(this.paso <= 1)) {
      this.paso--;
    }
  }

  public slideNext() {
    this.paso++;
  }

  private scrollToTop(): void {
    setTimeout(() => {
      this.content.scrollToTop(100);
    }, 1000);
  }
}
