import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { CameraSource } from '@capacitor/camera';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { Device } from '@capacitor/device';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { Message } from '@app/shared/services/firebase/firebase.service';
import { FilesService } from '@app/shared/services/files/files.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { TextToLinkService } from '@app/shared/services/text-to-link/text-to-link.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('Chat Group');

@Component({
  selector: 'app-chat-group',
  templateUrl: './chat-group.component.html',
  styleUrls: ['./chat-group.component.scss']
})
export class ChatGroupComponent implements OnInit {
  @ViewChild('file1', { read: ElementRef, static: true }) file1: ElementRef;
  @ViewChild('file2', { read: ElementRef, static: true }) file2: ElementRef;
  @ViewChild('textAreaChat', { read: ElementRef, static: true }) textAreaChat: ElementRef;
  public message: string;
  fileToUpload1: any;
  fileToUpload2: any;
  public dataForm: FormGroup;
  item: any;
  isLoading: boolean;
  users: number[];
  files: string[] = [];
  rawMessages: Message[] = [];
  idGroup: any;
  path = 'chats';
  userDefault: boolean;
  htmlContent: string;
  resetEditor: number = 0;
  isLoad: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private loader: IonLoaderService,
    private utilities: UtilitiesService,
    private filesService: FilesService,
    private analyticsFB: AnalyticsService,
    public credService: CredentialsService,
    private router: Router,
    public platform: Platform,
    private textToLink: TextToLinkService
  ) {
    this.init();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async init() {
    await this.platform.ready();
    this.userDefault = this.credService.credentials.defaultUser;
    this.initForm();
    this.isLoad = true;
  }

  ngOnInit() { }

  initForm() {
    this.dataForm = this.formBuilder.group({
      msg: ['', Validators.required],
      sendAnalytics: [false],
      analyticsLabel: ['']
    });
  }

  sendAnalytics(event: any) {
    if (event && event.target && event.target.checked === true) {
      this.dataForm.controls['analyticsLabel'].setValidators([Validators.required]);
      this.dataForm.controls['analyticsLabel'].updateValueAndValidity();
    } else {
      this.dataForm.controls['analyticsLabel'].clearValidators();
      this.dataForm.controls['analyticsLabel'].updateValueAndValidity();
    }
  }

  deleteItem(index: number) {
    this.files.splice(index, 1);
    this.rawMessages.splice(index, 1);
  }

  public async send(): Promise<void> {
    if (this.dataForm.get('msg').value && this.dataForm.get('msg').value !== '') {
      this.rawMessages.unshift({
        isFile: false,
        message: this.textToLink.replace(this.dataForm.get('msg').value),
        url: null,
        convert: null
      });
    }
    let data: any = { messages: this.rawMessages };
    if (this.dataForm.get('sendAnalytics').value === true && this.dataForm.get('analyticsLabel').value) {
      data.analytics = {
        send: true,
        label: this.dataForm.get('analyticsLabel').value
      };
    }
    this.modalCtrl.dismiss(data);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  triggerClick1() {
    this.file1.nativeElement.click();
  }

  triggerClick2() {
    this.file2.nativeElement.click();
  }

  async selectFile1(data: any) {
    const file = data.files as FileList;
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response: any = await this.filesService.selectImageFile(file, `${this.path}/${this.idGroup}`, 'chat');
      const url = response.main.url;
      const message = `<img alt="${url}" src="${url}" class="iab-disabled"/>`;
      this.files.push(message);
      this.rawMessages.push({ isFile: false, message: message, url: null, convert: null });
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async selectFile2(data: any) {
    const file = data.files as FileList;
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response = await this.filesService.selectAnyFile(file, `${this.path}/${this.idGroup}`, [
        'doc',
        'docx',
        'ppt',
        'pptx',
        'xls',
        'xlsx',
        'pdf'
      ]);
      if (response && response.main && response.main.url) {
        const url = response.main.url;
        const nameMedia = response.main.path;
        const fullName = nameMedia.split('/')[2];
        const message = `<a href="${url}" class="iab-disabled"><ion-icon name="document"></ion-icon> ${fullName}</a>`;
        this.files.push(message);
        this.rawMessages.push({ isFile: true, message: fullName, url: url, convert: null });
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async takePicture(image?: CameraSource) {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response = await this.filesService.takePicture(image, this.path, 'chat');
      const url = response.main.url;
      const message = `<img alt="${url}" src="${url}" class="iab-disabled"/>`;
      this.files.push(message);
      this.rawMessages.push({ isFile: false, message: message, url: null, convert: null });
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async imageFromGallery() {
    try {
      const info = await Device.getInfo();
      if (info && info.platform == 'web') {
        this.triggerClick1();
      } else {
        this.takePicture(CameraSource.Photos);
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
  }

  keyupHandler(event: any) {
    if (event == '' || event == undefined || event == null) {
      this.dataForm.get('msg').setValue('');
    } else {
      this.dataForm.get('msg').setValue(event);
    }
  }
}
