import { NgModule } from '@angular/core';
import { SanitizeHtmlPipe } from './sanitize-html/sanitize-html';
import { CastToAnyPipe } from './cast-to-any/cast-to-any.pipe';

@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    CastToAnyPipe
  ],
  imports: [],
  exports: [
    SanitizeHtmlPipe,
    CastToAnyPipe
  ]
})
export class PipesModule { }
