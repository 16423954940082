import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ModalController, Platform } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { Logger } from '@app/core/logger.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { IndustryRoutesService } from '@app/industry/services/industry-routes.service';
const log = new Logger('UserDetailsComponent');

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  isLoading: boolean;
  user: any = {};
  mode = 'modal';
  mainSubscribe: Subscription;
  mainSubscribe2: Subscription;
  company: any;

  constructor(
    private fireService: FirebaseService,
    private modalCtrl: ModalController,
    private specialtiesService: SpecialtiesService,
    public credService: CredentialsService,
    private aRoute: ActivatedRoute,
    private analyticsFB: AnalyticsService,
    private industryRoutesService: IndustryRoutesService,
    private router: Router,
    private platform: Platform
  ) {
    this.aRoute.params.subscribe(params => {
      if (params && params.userId) {
        this.user.uid = params.userId;
        this.mode = 'page';
        this.initializeApp();
      }
    });
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async ngOnInit() {
    if (this.mode === 'modal') {
      await this.initializeApp();
    }
  }

  async doRefresh(ev?: any) {
    try {
      await this.initializeApp();
      if (ev && ev.target) {
        ev.target.complete();
      }
    } catch (error) {
      log.error(error);
    }
  }

  closeModal() {
    if (this.mode === 'modal') {
      this.modalCtrl.dismiss();
    }
  }

  async initializeApp() {
    this.removeSusbs();
    this.isLoading = true;
    try {
      await this.getUserDetails();
      this.isLoading = false;
    } catch (error) {
      log.error(error);
    }
  }

  public async chat(): Promise<void> {
    this.fireService.createChat(this.user.data.uid, ChatComponent);
  }

  async getUserDetails() {
    try {
      const data = await this.fireService.getUserData(this.user.uid);
      let meta;
      if (data.type === 'medico') {
        meta = await this.fireService.getDoctorData(this.user.uid);
        this.user = { data, ...meta };
        // get specialties
        if (this.user.specialty1 && this.user.specialty1.id && this.user.specialty1.id !== 0) {
          this.user.specialty1.object = await this.specialtiesService.getSpecialty(this.user.specialty1.id);
        }
        if (this.user.specialty2 && this.user.specialty2.id && this.user.specialty2.id !== 0) {
          this.user.specialty2.object = await this.specialtiesService.getSpecialty(this.user.specialty2.id);
        }
      } else if (data.type === 'profesional-de-la-salud') {
        meta = await this.fireService.getProfessionalHealthData(this.user.uid);
        this.user = { data, ...meta };
      } else {
        meta = await this.fireService.getRepData(this.user.uid);
        if (meta.company.uid) {
          const companyData: any = await this.fireService.getCompany(meta.company.uid);
          const company = companyData.data();
          this.company = await this.fireService.getFullCompanyById(meta.company.uid);
          this.company.routing = this.industryRoutesService.mapRoute('Company', meta.company.uid);
          if (!(this.company && this.company.logo)) {
            const name: string = this.company && this.company.name ? this.company.name : '';
            const split = name.split(' ');
            if (split.length > 1) {
              this.company.nameLogo = split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase();
            } else {
              this.company.nameLogo = name.charAt(0).toUpperCase();
            }
          }
          this.user = { data, ...company };
        }
      }
      this.user.avatar = data && data.avatar && data.avatar.main && data.avatar.main.url ? data.avatar.main.url : '';
      var str: string = this.user.avatar;
      var n = str.search('www.gravatar.com');
      if (n > -1) {
        this.user.avatar = undefined;
      }
    } catch (error) {
      log.error(error);
    }
  }

  removeSusbs() {
    try {
      if (this.mainSubscribe) {
        this.mainSubscribe.unsubscribe();
      }
      if (this.mainSubscribe2) {
        this.mainSubscribe2.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
  }

  ionViewWillLeave() {
    this.removeSusbs();
  }

  mailto() {
    if (this.user && this.user.data && this.user.data.email) {
      this.platform.ready().then(() => {
        window.open('mailto:' + this.user.data.email);
      });
    }
  }

}
