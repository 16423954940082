import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('AuthenticationGuard');
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {

  constructor(
    private router: Router,
    public credService: CredentialsService,
    private authServe: AuthenticationService,
    private analyticsFB: AnalyticsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authServe.isAuthenticated().pipe(map(auth => {
      if (auth === true) {
        if (this.credService && this.credService.credentials && this.credService.credentials.type && (this.credService.credentials.type === 'representante-medico' || this.credService.credentials.type === 'medico' || this.credService.credentials.type === 'profesional-de-la-salud')) {
          return true;
        } else {
          return false;
        }
      }
      log.warn(`%c${state.url} *** route not permitted ***`, 'color: red', route.paramMap);
      this.router.navigate(['/home']);
      const eventName = 'dynamic_link_app_open';
      const params = {};
      const cred: any = this.credService.credentials;
      this.analyticsFB.logEvent(eventName, params, { credentials: cred });
      return false;
    }));
  }
}
