import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Logger } from '@app/core/logger.service';
const log = new Logger('MultimediaRoutesService');

export interface MultimediaRoute {
  /**
   * @param icon The main icon
   */
  icon: string;
  route: string;
  category: string;
  categoryPath: string;
  subCategory: string;
  catRoute: string;
  parent: string;
  type: string;
  searchRoute: string;
  catId: string;
}

@Injectable({
  providedIn: 'root'
})
export class MultimediaRoutesService {

  private routes: any = {
    'conectimed-doctors': {
      icon: {
        src: '/assets/icons/publicaciones-medicas-text.svg'
      },
      category: 'posts',
      subCategory: 'Médicos CONECTIMED',
      parent: '/multimedia/posts/conectimed-doctors',
      type: 'post',
      categoryPath: 'categories/1',
      catRoute: '/multimedia/posts/conectimed-doctors',
      searchRoute: '/multimedia//multimedia/posts/conectimed-doctors/',
      catId: '1',
      route: ''
    },
    'newsletters-and-news': {
      icon: {
        src: '/assets/icons/boletines-noticias-text.svg'
      },
      category: 'posts',
      subCategory: 'Boletines',
      parent: '/multimedia/posts/newsletters-and-news',
      type: 'post',
      categoryPath: 'categories/51',
      catRoute: '/multimedia/posts/newsletters-and-news',
      searchRoute: '/multimedia//multimedia/posts/newsletters-and-news/',
      catId: '51',
      route: ''
    },
    articles: {
      icon: {
        src: '/assets/icons/articulos-text.svg'
      },
      category: 'posts',
      subCategory: 'Artículos',
      parent: '/multimedia/posts/articles',
      type: 'post',
      categoryPath: 'categories/52',
      catRoute: '/multimedia/posts/articles',
      searchRoute: '/multimedia//multimedia/posts/articles/',
      catId: '52',
      route: ''
    },
    'pharmaceutical-industry': {
      icon: {
        src: '/assets/icons/farma-posts.svg'
      },
      category: 'pharmaceutical-industry',
      subCategory: 'I. Farmacéutica',
      parent: '/multimedia/posts/pharmaceutical-industry',
      type: 'post',
      categoryPath: 'categories/54',
      catRoute: '/multimedia/posts/pharmaceutical-industry',
      searchRoute: '/multimedia//multimedia/posts/pharmaceutical-industry/',
      catId: '54',
      route: ''
    },
    'calculators-and-algorithms': {
      icon: {
        src: '/assets/icons/esquema.svg'
      },
      category: 'posts',
      subCategory: 'Algoritmos',
      parent: '/multimedia/posts/calculators-and-algorithms',
      type: 'post',
      categoryPath: 'categories/85',
      catRoute: '/multimedia/posts/calculators-and-algorithms',
      searchRoute: '/multimedia//multimedia/posts/calculators-and-algorithms/',
      catId: '85',
      route: ''
    },
    notimed: {
      icon: {
        src: '/assets/icons/notimed_solo.svg'
      },
      category: 'videos',
      subCategory: 'Notimed',
      parent: '/multimedia/videos/notimed',
      type: 'video',
      categoryPath: 'categories_video/50',
      catRoute: '/multimedia/videos/notimed',
      searchRoute: '/multimedia//multimedia/videos/notimed/',
      catId: '50',
      route: ''
    },
    webinars: {
      icon: {
        name: 'tv-outline'
      },
      category: 'videos',
      subCategory: 'Sesiones virtuales',
      parent: '/multimedia/videos/webinars',
      type: 'video',
      categoryPath: 'categories_video/6748',
      catRoute: '/multimedia/videos/webinars',
      searchRoute: '/multimedia//multimedia/videos/webinars/',
      catId: '6748',
      route: ''
    },
    interviews: {
      icon: {
        src: '/assets/icons/entrevista.svg'
      },
      category: 'videos',
      subCategory: 'Entrevistas',
      parent: '/multimedia/videos/interviews',
      type: 'video',
      categoryPath: 'categories_video/6749',
      catRoute: '/multimedia/videos/interviews',
      searchRoute: '/multimedia//multimedia/videos/interviews/',
      catId: '6749',
      route: ''
    },
    'courses-and-congresses': {
      icon: {
        src: '/assets/icons/cursos_congresos.svg'
      },
      category: 'courses-and-congresses',
      subCategory: 'Cursos',
      parent: '/multimedia/courses-and-congresses',
      type: 'curso_o_congreso',
      categoryPath: '',
      catRoute: '/multimedia/courses-and-congresses',
      searchRoute: '/multimedia//multimedia/courses-and-congresses/',
      catId: '',
      route: ''
    },
    foro: {
      catRoute: '/multimedia/foro'
    },
    outstanding: {
      catRoute: '/multimedia/outstanding',
      subCategory: 'Destacado'
    },
    'conectimed-courses': {
      catRoute: '/multimedia/conectimed-courses'
    }
  };

  constructor() { }

  mapRoute(categoryRef: DocumentReference, documentID: string, title: string): MultimediaRoute | void {
    try {
      return this.mapping(categoryRef, documentID, title);
    } catch (e) {
      log.error(e);
    }
  }

  private mapping(categoryRef: DocumentReference, documentID?: string, title?: string): MultimediaRoute | void {
    try {
      let routing: MultimediaRoute = undefined;
      switch (categoryRef && categoryRef.id ? String(categoryRef.id) : undefined) {
        case '1':
          routing = Object.assign({}, this.routes['conectimed-doctors']);
          break;

        case '51':
          routing = Object.assign({}, this.routes['newsletters-and-news']);
          break;

        case '52':
          routing = Object.assign({}, this.routes['articles']);
          break;

        case '54':
          routing = Object.assign({}, this.routes['pharmaceutical-industry']);
          break;

        case '85':
          routing = Object.assign({}, this.routes['calculators-and-algorithms']);
          break;

        case '50':
          routing = Object.assign({}, this.routes['notimed']);
          break;

        case '6748':
          routing = Object.assign({}, this.routes['webinars']);
          break;

        case '6749':
          routing = Object.assign({}, this.routes['interviews']);
          break;

        default:
          routing = Object.assign({}, this.routes['courses-and-congresses']);
          break;
      }
      const newTitle: string = encodeURIComponent(title);
      routing.route = `${routing.catRoute}/${documentID}/${window.btoa(newTitle)}`;
      return Object.assign({}, routing);
    } catch (e) {
      log.error(e);
    }
  }

  mapFromRoot(categoryName: string): MultimediaRoute {
    return this.routes[categoryName];
  }
}
