import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { UserDetailsComponent } from './user-details.component';
import { PullToRefreshModule } from '@app/shared/components/pull-to-refresh/pull-to-refresh.module';
import { ICompanyCardModule } from '@app/industry/modules/i-company-card/i-company-card.module';
import { UserDetailsRoutingModule } from './user-details-routing.module';

@NgModule({
  declarations: [UserDetailsComponent],
  exports: [UserDetailsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    IonicModule,
    RouterModule,
    PullToRefreshModule,
    UserDetailsRoutingModule,
    ICompanyCardModule
  ]
})
export class UserDetailsModule {}
