import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { DocumentReference, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { SurveysComponent } from '@app/surveys/surveys.component';
import { AlertController, ModalController } from '@ionic/angular';
const log = new Logger('TermsOfUseComponent');

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {

  constructor(
    private fireService: FirebaseService,
    private modalCtrl: ModalController,
    private credService: CredentialsService,
    private authServe: AuthenticationService,
    private loader: IonLoaderService,
    private utilities: UtilitiesService,
    private router: Router,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() { }


  async deleteAccountAlert() {
    const resp = await this.fireService.afs.collection('surveys-and-tests').ref.where('isDeleteAccountForm', '==', true).get();
    if (!resp.empty) {
      const modal = await this.modalCtrl.create({
        component: SurveysComponent,
        componentProps: {
          isDeleteAccountForm: true,
          id: resp.docs[0].id
        }
      });
      modal.onDidDismiss().then((resp) => {
        this.deleteAccountHandler(resp);
      });
      modal.present();
    } else {
      this.alertDelete();
    }
  }

  async deleteAccountHandler(resp: any) {
    await this.loader.ionLoaderPresent();
    if (resp && resp.data && resp.data.deleteAccount && resp.data.deleteAccount === true) {
      try {
        let metaRef: DocumentReference;
        const userRef: DocumentReference = this.fireService.afs.collection('users').doc(this.credService.credentials.uid).ref;
        switch (this.credService.credentials.type) {
          case 'medico':
            metaRef = this.fireService.afs.collection('medico-meta').doc(this.credService.credentials.uid).ref;
            break;
          case 'representante-medico':
            metaRef = this.fireService.afs.collection('representante-meta').doc(this.credService.credentials.uid).ref;
            break;
          case 'profesional-de-la-salud':
            metaRef = this.fireService.afs.collection('profesional-de-la-salud-meta').doc(this.credService.credentials.uid).ref;
            break;
        }
        await metaRef.delete();
        await userRef.delete();
        await this.fireService.callFunctionFB('deleteAuthUser', { uid: this.credService.credentials.uid });
        await this.authServe.logout();
        this.utilities.toast('¡Cuenta eliminada correctamente!');
        this.router.navigate(['/home']);
      } catch (error) {
        log.error(error);
      }
    }
    await this.loader.ionLoaderDismiss();
    location.reload();
  }

  async alertDelete() {
    const alert = await this.alertCtrl.create({
      header: 'Eliminación de cuenta',
      message: `Su cuenta se eliminará de forma permanente, no podrá recuperar su información ya que se perderá de forma permanente. ¿Está de acuerdo con iniciar el proceso de eliminación de su cuenta?`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Quiero pensarlo un poco',
          role: 'cancel',
        },
        {
          text: 'Eliminar mi cuenta',
          handler: async () => {
            this.deleteAccountHandler({ data: { deleteAccount: true } });
          }
        }
      ]
    });
    alert.present();
  }

}
