<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)" class="only-movil">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <!-- <ion-toolbar color="primary">
    <ion-button (click)="back()" slot="start" color="light" fill="clear">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>
    <ion-title class="ion-text-center">Perfil</ion-title>
    <ion-button (click)="doRefresh($event)" slot="end" color="light" fill="clear">
      <ion-icon slot="icon-only" name="refresh-outline"></ion-icon>
    </ion-button>
  </ion-toolbar> -->
  <ion-grid class="ion-no-padding" fixed>
    <ion-row *ngIf="user">
      <ion-col size="12" [class]="'card-user-profile card-post-' + 0"
        [style.backgroundImage]="'url(./assets/img/bg' + 0 + '.jpg)'">
        <div class="floating-button">
          <ion-button (click)="editImg()" fill="clear" size="small" color="light">
            <ion-icon slot="start" name="create-outline"></ion-icon>
            <span class="only-web">Editar imagen</span>
          </ion-button>
        </div>
        <div class="custom-avatar-wrap">
          <img [src]="image ? image : './assets/img/default-avatar.svg'" class="iab-disabled custom-avatar" />
        </div>
        <h1>
          {{ user?.name + ' ' + user?.lastName1 }}
        </h1>
        <h4>{{ user?.type === 'medico' ? 'Médico' : user?.type === 'profesional-de-la-salud' ? 'Profesional de la salud' : 'Representante' }}</h4>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid class="ion-no-padding" fixed>
    <ion-row class="only-movil">
      <ion-col>
        <app-pull-to-refresh *ngIf="!isLoading == true"></app-pull-to-refresh>
      </ion-col>
    </ion-row>
    <app-profile-image *ngIf="userData" [user]="userData" (response)="reload()" [register]="false" [submit]="submit">
    </app-profile-image>
    <ion-row class="ion-no-padding">
      <ion-col size="12" class="ion-no-padding segbuttons">
        <div *ngIf="memberType === 'medico' || memberType === 'profesional-de-la-salud'">
          <ion-segment scrollable (ionChange)="segmentChanged1($event)">
            <ion-segment-button value="1" checked>
              <ion-label>Personales</ion-label>
              <ion-icon name="person-outline"></ion-icon>
            </ion-segment-button>
            <ion-segment-button value="2">
              <ion-label>Profesionales</ion-label>
              <ion-icon name="school-outline"></ion-icon>
            </ion-segment-button>
            <ion-segment-button value="3">
              <ion-label>Direcciones</ion-label>
              <ion-icon name="location-outline"></ion-icon>
            </ion-segment-button>
          </ion-segment>
        </div>
        <div *ngIf="memberType === 'representante-medico'">
          <ion-segment scrollable (ionChange)="segmentChanged2($event)">
            <ion-segment-button value="1" checked>
              <ion-label>Personales</ion-label>
              <ion-icon name="person-outline"></ion-icon>
            </ion-segment-button>
            <ion-segment-button value="2">
              <ion-label>Empresa</ion-label>
              <ion-icon name="briefcase-outline"></ion-icon>
            </ion-segment-button>
          </ion-segment>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="memberType === 'medico'">
      <ion-col size="12">
        <app-personal-data-form
          *ngIf="userData && formsMedico == 1"
          memberType="doctor"
          [user]="userData"
          (response)="presentAlertResp()"
          button="Guardar"
        >
        </app-personal-data-form>
        <app-professional-data-form
          [user]="userData"
          *ngIf="userData && formsMedico == 2"
          (response)="presentAlertResp()"
          button="Guardar"
        >
        </app-professional-data-form>
        <app-addresses
          *ngIf="userData && formsMedico == 3"
          [user]="userData"
          (response)="presentAlertResp()"
          button="Guardar" 
        ></app-addresses>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="memberType === 'profesional-de-la-salud'">
      <ion-col size="12">
        <app-personal-data-form
          *ngIf="userData && formsMedico == 1"
          memberType="doctor"
          [user]="userData"
          (response)="presentAlertResp()"
          button="Guardar"
        >
        </app-personal-data-form>
        <app-professional-health-data-form
          [user]="userData"
          *ngIf="userData && formsMedico == 2"
          (response)="presentAlertResp()"
          button="Guardar"
        >
        </app-professional-health-data-form>
        <app-addresses
          *ngIf="userData && formsMedico == 3"
          [user]="userData"
          (response)="presentAlertResp()"
          button="Guardar" 
        ></app-addresses>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="memberType === 'representante-medico'">
      <ion-col size="12">
        <app-personal-data-form
          *ngIf="userData && formsRM == 1"
          memberType="medical-representative"
          [user]="userData"
          (response)="presentAlertResp()"
          button="Guardar"
        >
        </app-personal-data-form>
        <app-company-data-form
          *ngIf="userData && formsRM == 2"
          [isProfile]="true"
          [user]="userData"
          (response)="presentAlertResp()"
          button="Guardar"
        >
        </app-company-data-form>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-button expand="block" color="secondary" (click)="changePassword()">Cambiar contraseña</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>