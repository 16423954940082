import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { MultimediaRoutesService } from '@app/multimedia/services/multimedia-routes.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { HomeService } from '@app/home/home.service';
import { Router } from '@angular/router';
const log = new Logger('VideosOnDemandComponent');

@Component({
  selector: 'app-virtual-session-suggestions',
  templateUrl: './virtual-session-suggestions.component.html',
  styleUrls: ['./virtual-session-suggestions.component.scss'],
})
export class VirtualSessionSuggestionsComponent implements OnInit {
  @Input() isMovil: boolean;
  @Input() futureSessions: boolean;
  public posts: any[] = [];
  public isLoading: boolean = false;
  public attempts: number = 3;

  constructor(
    private sanitizer: DomSanitizer,
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private conectimedRoutes: MultimediaRoutesService,
    private homeService: HomeService,
    public router: Router,
  ) { }

  ngOnInit() {
    if (this.futureSessions === true) {
      this.initFutureSessions();
    } else {
      this.initOnDemand();
    }
  }

  onlyUnique(value: any, index: number, array: any[]) {
    array = array.map(e => e.id)
    return array.indexOf(value.id) === index;
  }

  async initOnDemand(): Promise<void> {
    this.attempts--;
    this.isLoading = true;
    try {
      let limit: number = this.isMovil === true ? 2 : 10;
      const ref = this.fireService.afs.doc("categories_video/6748").ref;
      const resp = await this.fireService.afs.collection('posts').ref.where('status', '==', 'active').where('category', '==', ref).orderBy('date', 'desc').limit(limit).get();
      let items: any[] = resp.docs.map(e => {
        const data: any = e.data();
        const id: string = e.id;
        data.content = this.sanitizer.bypassSecurityTrustHtml(data.content);
        data.excerpt = data.excerpt;
        data.imageBg = this.sanitizer.bypassSecurityTrustStyle(`url(${data.image})`);
        data.routing = this.conectimedRoutes.mapRoute(data.category, e.id, data.title);
        return { id, ...data }
      });
      this.posts = this.posts.concat(items);
      this.posts = this.posts.filter(this.onlyUnique);
      if (!(this.posts.length >= limit) && this.attempts > 0) {
        await this.initOnDemand();
      }
      this.posts = this.posts.slice(0, limit);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async initFutureSessions(): Promise<void> {
    this.attempts--;
    this.isLoading = true;
    try {
      let limit: number = this.isMovil === true ? 2 : 10;
      const ref = this.fireService.afs.doc("categories_video/6748").ref;
      const resp = await this.fireService.afs.collection('posts').ref.where('status', '==', 'pending').where('category', '==', ref).orderBy('date', 'desc').limit(limit).get();
      let items: any[] = resp.docs.map(e => {
        const data: any = e.data();
        const id: string = e.id;
        data.content = this.sanitizer.bypassSecurityTrustHtml(data.content);
        data.imageBg = this.sanitizer.bypassSecurityTrustStyle(`url(${data.image})`);
        data.routing = this.conectimedRoutes.mapRoute(data.category, e.id, data.title);
        return { id, ...data }
      });
      this.posts = this.posts.concat(items);
      this.posts = this.posts.filter(this.onlyUnique);
      if (!(this.posts.length >= limit) && this.attempts > 0) {
        await this.initFutureSessions();
      }
      this.posts = this.posts.slice(0, limit);
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  public loginAlert() {
    this.homeService.alertLoginSining();
  }



}

