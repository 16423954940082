<ion-content>
  <ion-grid [fixed]="false">
    <ion-row>
      <ion-col size="12">
        <ion-card>
          <ion-toolbar>
            <ion-title>Mis productos</ion-title>
            <ion-buttons slot="end">
              <ion-button class="ion-text-wrap" expand="block" fill="clear" shape="round" (click)="add()"
                style="font-size: 13px;" color="tertiary">
                Agregar productos
              </ion-button>
              <ion-button class="ion-text-wrap" color="tertiary" expand="block" fill="solid" shape="round"
                routerDirection="forward" [routerLink]="'save/pdf'" style="font-size: 13px;" color="primary">
                Generar PDF
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <!-- Elements -->
      <ion-col *ngFor="let item of products; let i = index" sizeXs="12" sizeMd="6">
        <app-i-item [item]="item"></app-i-item>
      </ion-col>
      <ion-col size="12" *ngIf="!isLoading === true && !products[0]">
        <app-i-no-results></app-i-no-results>
      </ion-col>
      <!-- Skeleton loading -->
      <ion-col size="12" *ngIf="isLoading === true">
        <app-i-skeleton></app-i-skeleton>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- Infinite scroll (get next elements) -->
  <ion-infinite-scroll threshold="100px" (ionInfinite)="getMoreItems($event)" [disabled]="isLoading === true">
    <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Cargando información...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>