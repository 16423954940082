<div [class]="register == true ? 'image-profile-register' : 'image-profile-register-hidden'">
  <div class="image-wrapper">
    <img
      *ngIf="register == true"
      [src]="imageSrc ? imageSrc : '/assets/img/default-avatar.svg'"
      class="image-preview"
    />
  </div>
  <input
    style="visibility: hidden; height: 0; padding: 0; margin: 0;"
    id="file"
    #file
    accept="image/*"
    type="file"
    name="file"
    (change)="selectFile($event?.target)"
  />
  <div class="ion-padding">
    <ion-button (click)="presentActionSheet()" expand="block" color="tertiary" *ngIf="register == true">
      <ion-icon name="images-outline" slot="start"></ion-icon>
      Imagen de perfil
    </ion-button>
  </div>
</div>
