<form [formGroup]="fileForm">
  <ion-item>
    <ion-label >Cargar archivo</ion-label>
    <ion-input
      accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
      formControlName="file"
      #photoInput
      id="photo-input"
      type="file"
      (change)="onFileChanged($event)"
    >
    </ion-input>
  </ion-item>

  <ion-item *ngIf="showFile">
    <ion-label position="floating" >Título</ion-label>
    <ion-textarea formControlName="titleFile"></ion-textarea>
  </ion-item>
  <ion-item *ngIf="showFile">
    <ion-label position="floating" >Descripción del archivo</ion-label>
    <ion-textarea formControlName="descriptionFile"></ion-textarea>
  </ion-item>

  <ion-item *ngIf="showFile">
    <ion-button [disabled]="fileForm.invalid" color="primary" (click)="addFile()" >
      Agregar archivo
    </ion-button>
  </ion-item>

  <ion-list-header *ngIf="arrayAchives.length > 0">Listado de arhivos</ion-list-header>
  <ion-list *ngIf="arrayAchives.length > 0">
    <ion-item *ngFor="let archives of arrayAchives"> {{ archives.name }} - {{ archives.titleFile }} </ion-item>
  </ion-list>
</form>
