import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes/pipes.module';
import { MomentModule } from 'ngx-moment';
import { RichTextModule } from '@app/shared/components/rich-text/rich-text.module';

@NgModule({
  declarations: [ChatComponent],
  exports: [ChatComponent],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FormsModule, PipesModule, MomentModule, RichTextModule]
})
export class ChatModule { }
