import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { ChatListRoutingModule } from './chat-list-routing.module';
import { ChatListComponent } from './chat-list.component';
import { CardsModule } from '@app/shared/cards/cards.module';
import { SelectUserComponent } from './select-user/select-user.component';
import { SharedModule } from '@app/shared/shared.module';
import { AddGroupComponent } from './add-group/add-group.component';
import { ChatGroupComponent } from './chat-group/chat-group.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { GroupOptionsComponent } from './group-options/group-options.component';
import { PullToRefreshModule } from '../shared/components/pull-to-refresh/pull-to-refresh.module';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { MyContactsModule } from '@app/shared/my-contacts/my-contacts.module';
import { RedConectimedModule } from '@app/red-conectimed/red-conectimed.module';
import { MomentModule } from 'ngx-moment';
import { FiltersComponent } from './filters/filters.component';
import { RichTextModule } from '@app/shared/components/rich-text/rich-text.module';
import { PipesModule } from '@app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    ChatListRoutingModule,
    CardsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    PullToRefreshModule,
    MyContactsModule,
    RedConectimedModule,
    MomentModule,
    RichTextModule,
    PipesModule
  ],
  declarations: [
    ChatListComponent,
    SelectUserComponent,
    AddGroupComponent,
    ChatGroupComponent,
    GroupOptionsComponent,
    GroupDetailComponent,
    FiltersComponent
  ],
  exports: [ChatListComponent]
})
export class ChatListModule { }
