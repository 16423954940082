import * as moment from 'moment';
moment.locale('en-ES');
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Router } from '@angular/router';
const log = new Logger('RegisterToWebinarService');

@Injectable({
  providedIn: 'root'
})
export class RegisterToWebinarService {

  constructor(
    private alertCtrl: AlertController,
    private credService: CredentialsService,
    private analyticsFB: AnalyticsService,
    private router: Router,
    private fireService: FirebaseService,
  ) { }

  private analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  public async register(id: string, item: any, reaload?: boolean): Promise<boolean> {
    try {
      await this.fireService.afs.doc(`posts/${id}/assistance/${this.credService.credentials.uid}`).set({
        date: moment().toDate(),
        assistance: true
      });
      this.analyticsClickEvent('registroWebinnar', {
        content_title: item.title,
        date: moment().toDate()
      });
      this.showAlert(
        'ASISTENCIA CONFIRMADA',
        `Su asistencia al evento "${item.title}" ha sido confirmada.`,
        'Dentro de las 24hrs previas al evento recibirá un email con los datos del acceso.<br> Le esperamos.',
        reaload
      );
      return true;
    } catch (e) {
      log.error(e);
      return false;
    }
  }

  private async showAlert(title: string, subHeader: string, msg: string, reaload?: boolean) {
    const alert = await this.alertCtrl.create({
      header: title,
      backdropDismiss: false,
      subHeader: subHeader,
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            if (reaload === true) { location.reload(); }
          }
        }
      ]
    });
    alert.present();
  }
}
