import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';

interface Files {
  description: string;
  type: string;
  file: string;
  name?: string;
}

@Component({
  selector: 'app-uploadfiles',
  templateUrl: './uploadfiles.component.html',
  styleUrls: ['./uploadfiles.component.scss']
})
export class UploadfilesComponent implements OnInit {
  @Output() uploadArchives = new EventEmitter<{}>();
  fileForm: FormGroup;
  showFile = false;
  fileData: Files | any;
  arrayAchives: Files[] | any[] = [];
  mimeTypes = [
    {
      name: 'image/png'
    },
    {
      name: 'image/jpg'
    },
    {
      name: 'image/jpeg'
    },
    {
      name: 'application/pdf'
    },
    {
      name: 'application/msword'
    },
    {
      name: ' application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },
    {
      name: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    {
      name: 'application/vnd.ms-excel'
    },
    {
      name: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
    },
    {
      name: 'application/vnd.ms-powerpoint'
    },
    {
      name: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    public alertCtrl: AlertController
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.fileForm = this.formBuilder.group({
      titleFile: ['', Validators.required],
      descriptionFile: [''],
      file: ['']
    });
  }

  onFileChanged(event: any) {
    this.showFile = true;
    const file = event.target.files[0];
    const typeFile = file.type;
    const readerPhoto = new FileReader();
    readerPhoto.onload = readerEvent => {
      const imageData = (readerEvent.target as any).result;

      this.fileData = {
        description: '',
        type: typeFile,
        file: imageData,
        name: file.name
      };
    };
    readerPhoto.readAsDataURL(event.target.files[0]);
  }

  addFile() {
    const found = this.mimeTypes.find(element => element.name === this.fileData.type);
    if (typeof found !== 'undefined') {
      this.fileData['description'] = this.fileForm.value.descriptionFile;
      this.fileData['titleFile'] = this.fileForm.value.titleFile;
      this.arrayAchives.push(this.fileData);
      this.fileForm.patchValue({ descriptionFile: '', file: '', titleFile: '' });
      this.uploadArchives.emit(this.arrayAchives);
    } else {
      this.presentAlert('Error', 'El tipo de archivo no es compatible, por favor intenta con otro formato');
    }
  }

  async presentAlert(_header: string, _message: string) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: _header,
      message: _message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });

    alert.present();
  }
}
