<ion-content [scrollEvents]="true" (ionScroll)="logScrolling($event)" [fullscreen]="true">

  <ion-grid [fixed]="false">
    <div class="float-row">
      <div class="float f-left size-8">
        <div style="max-width: 900px;position: relative;margin: 0 auto;">
          <!-- Banners -->
          <ion-card>
            <ion-card-content>
              <swiper-container autoplay="true" delay="6000" data-swiper-autoplay="6000" navigation="false"
                pagination="true" [autoHeight]="true">
                <swiper-slide *ngFor="let mainBanner of slides"
                  [style.display]="utilities.checkVisibility(mainBanner?.publication_date) === true ? 'block' : 'none'">
                  <div
                    (click)="(credService?.credentials?.uid && credService?.credentials?.active === true) ? option(mainBanner) : loginAlert()">
                    <img [src]="mainBanner?.image" [alt]="mainBanner?.title" style="display: block; width: 100%;" />
                  </div>
                </swiper-slide>
              </swiper-container>
              <div *ngIf="slides.length === 0">
                <ion-skeleton-text animated style="height: 170px; width: 100%;"></ion-skeleton-text>
                <ion-skeleton-text animated style="height: 25px; width: 100%; padding: 5px 15px;"></ion-skeleton-text>
                <ion-skeleton-text animated style="height: 25px; width: 70%; padding: 5px 15px;"></ion-skeleton-text>
              </div>
            </ion-card-content>
          </ion-card>
          <!-- Home Featured Web only-->
          <app-featured-posts *ngIf="platformWidth >= 992"></app-featured-posts>
        </div>
      </div>
      <div class="float f-left size-4">
        <!-- ADDS Web y Móvil -->
        <div>
          <ion-card class="transparent" #postAdd
            *ngIf="!!credService?.credentials?.uid && credService?.credentials?.active === true">
            <div class="limit-add">
              <app-advertising *ngIf="homeAdvertisings.length !== 0 && showAdds"
                [advertising]="homeAdvertisings[addCounter]" [inHome]="true" (closeAdd)="showAdds = false"
                class="animate__animated animate__fadeIn"></app-advertising>
            </div>
          </ion-card>
        </div>
        <!-- videos onDemand -->
        <app-virtual-session-suggestions></app-virtual-session-suggestions>
        <!-- Home Featured Movil only-->
        <app-featured-posts *ngIf="platformWidth < 992"></app-featured-posts>
        <!-- Info Covid Card -->
        <ion-card>
          <div>
            <ion-card (click)="
            analyticsClickEvent('select_content', {
              content_type: 'Banner de Inicio',
              content_title: 'Banner de Inicio',
              click_text: 'Banner de Inicio',
              click_URL: '/multimedia/posts/all/covid'
            })
          " style="margin: 0px;" class="card-info-covid" [button]="true" [routerLink]="'/multimedia/posts/all/covid'"
              [routerDirection]="'forward'"
              *ngIf="credService?.credentials?.uid && credService?.credentials?.active === true;  else anonymousCovidCard">
              <ion-row>
                <ion-col size="" style="padding: 0px;">
                  <img src="../../assets/img/info_covid_home.jpg" class="img-info-covid" />
                </ion-col>
                <ion-col size="auto" style="padding: 0px;">
                  <div class="info-covid ion-text-center">
                    <ion-row style="height: 100%;" class="ion-align-items-center">
                      <ion-col size="12" style="padding: 0px;">
                        <ion-text color="primary">
                          <ion-icon [src]="'./assets/icons/info_covid.svg'" style="font-size: 30px;"></ion-icon>
                          <h5 style="margin-top: 5px;">Info COVID</h5>
                        </ion-text>
                      </ion-col>
                    </ion-row>
                  </div>
                </ion-col>
              </ion-row>
            </ion-card>
            <ng-template #anonymousCovidCard>
              <ion-card style="margin: 0px;" class="card-info-covid" [button]="true" (click)="loginAlert()">
                <ion-row>
                  <ion-col size="" style="padding: 0px;">
                    <img src="../../assets/img/info_covid_home.jpg" class="img-info-covid" />
                  </ion-col>
                  <ion-col size="auto" style="padding: 0px;">
                    <div class="info-covid ion-text-center">
                      <ion-row style="height: 100%;" class="ion-align-items-center">
                        <ion-col size="12" style="padding: 0px;">
                          <ion-text color="primary">
                            <ion-icon [src]="'./assets/icons/info_covid.svg'" style="font-size: 30px;"></ion-icon>
                            <h5 style="margin-top: 5px;">Info COVID</h5>
                          </ion-text>
                        </ion-col>
                      </ion-row>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-card>
            </ng-template>
          </div>
        </ion-card>
      </div>
    </div>
  </ion-grid>
</ion-content>