import { Component, Input, OnInit } from '@angular/core';
import { SpecialitesComponent } from '@app/shared/components/specialites/specialites.component';
import { LocationComponent } from '@app/shared/components/location/location.component';
import { SearchCompanyComponent } from '@app/shared/search-company/search-company.component';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ModalController } from '@ionic/angular';
import { DocumentData, QueryDocumentSnapshot, QuerySnapshot, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SearchService } from '../services/search/search.service';
const log = new Logger('MyContactsComponent');

@Component({
  selector: 'app-my-contacts',
  templateUrl: './my-contacts.component.html',
  styleUrls: ['./my-contacts.component.scss'],
})
export class MyContactsComponent implements OnInit {
  @Input() type: string;
  newMembers: any;
  public members: any[] = [];
  public filterData1: any[] = [];
  public filterData2: any[] = [];
  public filterData3: any[] = [];
  private filter1: string = '';
  private filter2: string = '';
  private filter3: string = '';
  private finalFilter: string = '';
  public arrayDocs: any[] = [];
  public searchStr: string;
  public searchMemberType: string;
  public isLoading: boolean = false;
  public memberType: string;
  public products: any[] = [];
  public viewType: 'modal' | 'component' = 'component';
  public grupdID: string;
  public selectedUsers: any[] = [];
  public filter: any[] = [
    { title: 'A', filter: 'a', see: false },
    { title: 'B', filter: 'b', see: false },
    { title: 'C', filter: 'c', see: false },
    { title: 'D', filter: 'd', see: false },
    { title: 'E', filter: 'e', see: false },
    { title: 'F', filter: 'f', see: false },
    { title: 'G', filter: 'g', see: false },
    { title: 'H', filter: 'h', see: false },
    { title: 'I', filter: 'i', see: false },
    { title: 'J', filter: 'j', see: false },
    { title: 'K', filter: 'k', see: false },
    { title: 'L', filter: 'l', see: false },
    { title: 'M', filter: 'm', see: false },
    { title: 'N', filter: 'n', see: false },
    { title: 'Ñ', filter: 'ñ', see: false },
    { title: 'O', filter: 'o', see: false },
    { title: 'P', filter: 'p', see: false },
    { title: 'Q', filter: 'q', see: false },
    { title: 'R', filter: 'r', see: false },
    { title: 'S', filter: 's', see: false },
    { title: 'T', filter: 't', see: false },
    { title: 'U', filter: 'u', see: false },
    { title: 'V', filter: 'v', see: false },
    { title: 'W', filter: 'w', see: false },
    { title: 'X', filter: 'x', see: false },
    { title: 'Y', filter: 'y', see: false },
    { title: 'Z', filter: 'z', see: false }
  ];
  public currentFilter: string = null;
  private nameEvent: string = 'select_user';
  private firstCharacter: any;
  public orderBy: string = 'nameStr';
  public orderByDirection: any = 'asc';
  public contador: number;
  private perPage: number = 50;
  public cursorNext: QueryDocumentSnapshot<any>;
  public cursorBack: QueryDocumentSnapshot<any>;
  private counterSubscription: Subscription;
  private counterSubscription2: Subscription;
  public abc: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    private router: Router,
    private searchService: SearchService
  ) {
    // Search
   
        // if (data && data.type && data.type === 'search') {
        //   if (data && data.search) {
        //     this.search(data.search);
        //   }
        // }
        // if (data && data.type && data.type === 'clear') {
        //   this.clearSearch();
        // }
      
  }

  async ngOnInit() {
    try {
      this.memberType = this.credService.credentials.type;
      if (this.memberType == 'representante-medico') {
        this.searchMemberType = 'medico';
      }
      await this.initializeApp();
      if (this.viewType === 'modal' && this.grupdID) {
        this.fireService.afs.collection('broadcast-list').doc(this.grupdID);
      }
      await this.changeMemberType(this.type);
    } catch (e) {
      log.error(e);
    }
  }

  ngOnDestroy() {
    try {
      if (this.counterSubscription) {
        this.counterSubscription.unsubscribe();
      }
      if (this.counterSubscription2) {
        this.counterSubscription2.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
  }

  closeModal() {
    this.analyticsClickEvent('close_modal', {});
    this.modalCtrl.dismiss({ modification: false });
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async filterList(filter: string) {
    this.analyticsClickEvent('filter_letter', {
      search_term: filter,
      search_user_type: this.searchMemberType
    });
    this.searchStr = '';
    if (filter === this.currentFilter) {
      await setTimeout(() => {
        this.currentFilter = null;
        this.firstCharacter = undefined;
      }, 50);
      this.currentFilter = null;
      this.firstCharacter = undefined;
    } else {
      this.firstCharacter = filter;
      this.currentFilter = filter;
    }
    this.isLoading = true;
    try {
      await this.paginate();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  private async setAlphabet(): Promise<void> {
    try {
      let alphabet: any[] = [];
      if (this.searchMemberType) {
        const respAlphabet = await this.fireService.afs
          .collection(
            `friends/${this.credService.credentials.uid}/information/counters/alphabet/${this.searchMemberType}/letters`
          )
          .ref.where('count', '>', 0)
          .get();
        alphabet = respAlphabet.docs.map(e => e.id);
      } else {
        const onlyUnique = (value: any, index: number, self: any) => {
          return self.indexOf(value) === index;
        };
        const respAlphabet1 = await this.fireService.afs
          .collection(`friends/${this.credService.credentials.uid}/information/counters/alphabet/medico/letters`)
          .ref.where('count', '>', 0)
          .get();
        const respAlphabet2 = await this.fireService.afs
          .collection(
            `friends/${this.credService.credentials.uid}/information/counters/alphabet/representante-medico/letters`
          )
          .ref.where('count', '>', 0)
          .get();
        alphabet = respAlphabet1.docs.map(e => e.id).concat(respAlphabet2.docs.map(e => e.id));
        alphabet = alphabet.filter(onlyUnique);
      }
      this.filter = this.filter.map(element => {
        let data: any = element;
        const index: number = alphabet.indexOf(element.filter);
        if (index > -1) {
          data.see = true;
        } else {
          data.see = false;
        }
        return data;
      });
    } catch (error) {
      log.error(error);
    }
    return;
  }

  private setQueries() {
    let query = this.fireService.afs
      .collection(`friends/${this.credService.credentials.uid}/friends`)
      .ref.where('status', '==', 'active');

    if (this.searchMemberType) {
      query = query.where('type', '==', this.searchMemberType);
    }

    if (this.searchStr && !this.firstCharacter && !(this.finalFilter && this.finalFilter !== '')) {
      query = query.where('search', 'array-contains', this.searchStr);
      this.abc = false;
    } else if (!this.searchStr && this.firstCharacter && !(this.finalFilter && this.finalFilter !== '')) {
      query = query.where('firstCharacter', '==', this.firstCharacter);
      this.abc = true;
    } else if (!this.searchStr && !this.firstCharacter && this.finalFilter && this.finalFilter !== '') {
      query = query.where('filter-meta-data', 'array-contains', this.finalFilter);
      this.abc = false;
    } else if (this.searchStr && this.firstCharacter && !(this.finalFilter && this.finalFilter !== '')) {
      query = query.where('search', 'array-contains', this.searchStr);
      this.clearQuery(['alphabet', 'filters']);
      this.abc = false;
    } else if (!this.searchStr && this.firstCharacter && this.finalFilter && this.finalFilter !== '') {
      query = query.where('filter-meta-data', 'array-contains', this.finalFilter);
      this.clearQuery(['alphabet', 'search']);
      this.abc = false;
    } else if (this.searchStr && !this.firstCharacter && this.finalFilter && this.finalFilter !== '') {
      query = query.where('search', 'array-contains', this.searchStr);
      this.clearQuery(['alphabet', 'filters']);
      this.abc = false;
    } else if (this.searchStr && this.firstCharacter && this.finalFilter && this.finalFilter !== '') {
      query = query.where('search', 'array-contains', this.searchStr);
      this.clearQuery(['alphabet', 'filters']);
      this.abc = false;
    } else if (!this.searchStr && !this.firstCharacter && !(this.finalFilter && this.finalFilter !== '')) {
      this.clearQuery(['search', 'alphabet', 'filters']);
      this.abc = true;
    } else {
      this.abc = true;
    }

    return query;
  }

  private clearQuery(types: string[]) {
    const search = types.indexOf('search');
    if (search > -1) {
      this.searchStr = undefined;
    }
    const alphabet = types.indexOf('alphabet');
    if (alphabet > -1) {
      this.currentFilter = null;
      this.firstCharacter = undefined;
    }
    const filters = types.indexOf('filters');
    if (filters > -1) {
      this.filterData1 = [];
      this.filterData2 = [];
      this.filterData3 = [];
      this.filter1 = '';
      this.filter2 = '';
      this.filter3 = '';
      this.finalFilter = '';
    }
  }

  private async getCounters(): Promise<void> {
    if (this.finalFilter && this.finalFilter !== '') {
      const path = `friends/${this.credService.credentials.uid}/information/counters/filters/type/${this.searchMemberType}/${this.finalFilter}`;
      this.counterSubscription = this.fireService.afs
        .doc(path)
        .valueChanges()
        .subscribe((value: any) => {
          if (value && value.count) {
            this.contador = Number(value.count);
          } else {
            this.contador = 0;
          }
        });
    } else if (this.firstCharacter) {
      if (this.searchMemberType === 'representante-medico' || this.searchMemberType === 'medico') {
        const path = `friends/${this.credService.credentials.uid}/information/counters/alphabet/${this.searchMemberType}/letters/${this.firstCharacter}`;
        this.counterSubscription = this.fireService.afs
          .doc(path)
          .valueChanges()
          .subscribe((value: any) => {
            if (value && value.count) {
              this.contador = Number(value.count);
            } else {
              this.contador = 0;
            }
          });
      } else {
        const path1 = `friends/${this.credService.credentials.uid}/information/counters/alphabet/representante-medico/letters/${this.firstCharacter}`;
        this.counterSubscription = this.fireService.afs
          .doc(path1)
          .valueChanges()
          .subscribe(async (value: any) => {
            const count = await this.fireService.afs
              .doc(
                `friends/${this.credService.credentials.uid}/information/counters/alphabet/medico/letters/${this.firstCharacter}`
              )
              .ref.get()
              .then(data => {
                return data.get('count') ? data.get('count') : 0;
              })
              .catch(() => {
                return 0;
              });
            if (value && value.count) {
              this.contador = Number(value.count) + Number(count);
            } else {
              this.contador = 0 + Number(count);
            }
          });
        //
        const path2 = `friends/${this.credService.credentials.uid}/information/counters/alphabet/medico/letters/${this.firstCharacter}`;
        this.counterSubscription = this.fireService.afs
          .doc(path2)
          .valueChanges()
          .subscribe(async (value: any) => {
            const count = await this.fireService.afs
              .doc(
                `friends/${this.credService.credentials.uid}/information/counters/alphabet/representante-medico/letters/${this.firstCharacter}`
              )
              .ref.get()
              .then(data => {
                return data.get('count') ? data.get('count') : 0;
              })
              .catch(() => {
                return 0;
              });
            if (value && value.count) {
              this.contador = Number(value.count) + Number(count);
            } else {
              this.contador = 0 + Number(count);
            }
          });
      }
    } else if (this.searchMemberType) {
      const path = `friends/${this.credService.credentials.uid}/friends`;
      this.counterSubscription = this.fireService.afs
        .collection(path, ref => ref.where('type', '==', this.searchMemberType).where('status', '==', 'active'))
        .valueChanges()
        .subscribe((value: any) => {
          if (value && value.length) {
            this.contador = Number(value.length);
          } else {
            this.contador = 0;
          }
        });
    } else {
      const path = `friends/${this.credService.credentials.uid}`;
      this.counterSubscription = this.fireService.afs
        .doc(path)
        .valueChanges()
        .subscribe((value: any) => {
          if (value && value.count) {
            this.contador = Number(value.count);
          } else {
            this.contador = 0;
          }
        });
    }
    return;
  }

  public async paginate(direction?: 'next' | 'back'): Promise<void> {
    let arrayDocs: any[] = [];
    await this.setAlphabet();
    this.getCounters();
    let query = this.setQueries();
    if (this.cursorNext && direction === 'next') {
      query = query
        .orderBy(this.orderBy, 'asc')
        .startAfter(this.cursorNext)
        .limit(this.perPage);
    } else if (this.cursorBack && direction === 'back') {
      query = query
        .orderBy(this.orderBy, 'asc')
        .startAt(this.cursorBack)
        .limit(this.perPage);
    } else {
      query = query.orderBy(this.orderBy, 'asc').limit(this.perPage);
    }
    try {
      const snap = await query.get();
      this.isLoading = true;
      this.cursorNext = snap.docs.length > 0 ? snap.docs[snap.docs.length - 1] : undefined;
      let snapBack: QuerySnapshot<DocumentData>;
      if (snap && snap.docs[0]) {
        const queryBack = this.setQueries();
        snapBack = await queryBack
          .orderBy(this.orderBy, 'desc')
          .startAfter(snap.docs[0])
          .limit(this.perPage)
          .get();
      } else {
        this.isLoading = false;
        // if (this.searchStr) {
        this.arrayDocs = [];
        //}
        return;
      }
      this.cursorBack =
        snapBack && snapBack.docs && snapBack.docs.length > 0 ? snapBack.docs[snapBack.docs.length - 1] : undefined;
      arrayDocs = snap.docs.map((element: any) => {
        let data: any = element.data();
        const id: string = element.id;
        data.isChecked = false;
        data.isMember = false;
        if (data.type === 'medico') {
          data.metaData = this.fireService.getDoctorData(id);
        } else {
          data.metaData = this.fireService.getRepData(id);
        }
        const mapped = this.selectedUsers.map(e => e.id);
        const index = mapped.indexOf(id);
        if (index > -1) {
          data.isChecked = true;
          data.isMember = true;
          this.members.push(data);
        }
        const str: string = String(
          data && data.avatar && data.avatar.list && data.avatar.list.url ? data.avatar.list.url : ''
        );
        const n = str.search('www.gravatar.com');
        if (n > -1) {
          data.avatar = undefined;
        }
        return { id, ...data };
      });
      this.arrayDocs = arrayDocs;
      this.isLoading = false;
    } catch (error) {
      log.error(error);
    }
  }

  goChatGroup() {
    this.analyticsClickEvent('chat_group', {});
    this.modalCtrl.dismiss({ users: this.selectedUsers });
  }

  clear() {
    this.selectedUsers = [];
  }

  selectUser(item: any) {
    this.analyticsClickEvent(this.nameEvent, {});
    const index: number = this.selectedUsers.map(e => e.id).indexOf(item.id);
    if (index > -1) {
      this.selectedUsers.splice(index, 1);
    } else {
      this.selectedUsers.push(item);
    }
  }

  async changeMemberType(type: string) {
    this.analyticsClickEvent('change_member', { content_type: type });
    if (this.searchMemberType) {
      if (String(this.searchMemberType) === String(type)) {
        this.resetSearch();
        this.searchMemberType = undefined;
      } else {
        this.resetSearch();
        this.searchMemberType = type;
      }
    } else {
      this.resetSearch();
      this.searchMemberType = type;
    }
    await this.paginate();
  }

  async initializeApp(): Promise<void> {
    this.isLoading = true;
    try {
      await this.paginate();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async resetSearch(load?: boolean) {
    this.arrayDocs = [];
    this.filterData1 = [];
    this.filterData2 = [];
    this.filterData3 = [];
    this.filter1 = '';
    this.filter2 = '';
    this.filter3 = '';
    this.finalFilter = '';
    this.searchMemberType = undefined;
    this.searchStr = '';
    this.currentFilter = null;
    this.firstCharacter = undefined;
    this.isLoading = true;
    try {
      if (load === true) {
        await this.loadData();
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async removeEspecialtiesFilter(data?: boolean) {
    log.debug(data);
    this.analyticsClickEvent('remove_filter_specialties', {});
    this.filterData2 = [];
    const search: string = this.filterData2.map(e => String(e.id))[0];
    this.filter2 = search ? search : '';
    this.finalFilter = this.filter1 + this.filter2;
    this.isLoading = true;
    try {
      await this.loadData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async removeStatesFilter(data?: boolean) {
    log.debug(data);
    this.analyticsClickEvent('remove_filter_states', {});
    this.filterData1 = [];
    const search: string = this.filterData1.map(e => String(e.id))[0];
    this.filter1 = search ? search : '';
    this.finalFilter = this.filter1 + this.filter2;
    this.isLoading = true;
    try {
      await this.loadData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async removeCompaniesFilter(data?: boolean) {
    log.debug(data);
    this.analyticsClickEvent('remove_filter_companies', {});
    this.filterData3 = [];
    const search: string = this.filterData3.map(e => String(e.id))[0];
    this.filter3 = search ? search : '';
    this.finalFilter = this.filter3;
    this.isLoading = true;
    try {
      await this.loadData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async specialtiesFilter() {
    this.analyticsClickEvent('filter_specialties', {});
    const finalFilter = this.filter1 + '';
    const modal = await this.modalCtrl.create({
      component: SpecialitesComponent,
      componentProps: { arrayData: this.filterData2, myContacts: true, filter: finalFilter }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        if (data && data.role && data.role === 'data') {
          if (data && data.data && data.data.arrayData && data.data.arrayData.length > 0) {
            this.filterData2 = Array.from(data.data.arrayData);
            const search: string = this.filterData2.map(e => String(e.id))[0];
            this.filter2 = search ? search : '';
            this.finalFilter = this.filter1 + this.filter2;
            if (this.finalFilter === '') {
              this.arrayDocs = [];
            } else {
              await this.loadData();
            }
          }
        } else if (data && data.role && data.role === 'clear') {
          this.removeEspecialtiesFilter();
        } else {
          log.debug('specialtiesFilter closed');
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    modal.present();
  }

  async statesFilter() {
    this.analyticsClickEvent('filter_states', {});
    const finalFilter = '' + this.filter2;
    const modal = await this.modalCtrl.create({
      component: LocationComponent,
      componentProps: { arrayData: this.filterData1, myContacts: true, filter: finalFilter }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        if (data && data.role && data.role === 'data') {
          if (data && data.data && data.data.arrayData && data.data.arrayData.length > 0) {
            this.filterData1 = Array.from(data.data.arrayData);
            const search: string = this.filterData1.map(e => String(e.id))[0];
            this.filter1 = search ? search : '';
            this.finalFilter = this.filter1 + this.filter2;
            if (this.finalFilter === '') {
              this.arrayDocs = [];
            } else {
              await this.loadData();
            }
          }
        } else if (data && data.role && data.role === 'clear') {
          this.removeStatesFilter();
        } else {
          log.debug('statesFilter closed');
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    modal.present();
  }

  async companiesFilter() {
    this.analyticsClickEvent('filter_companies', {});
    const modal = await this.modalCtrl.create({
      component: SearchCompanyComponent,
      componentProps: { arrayData: this.filterData3, myContacts: true, currentFilter: this.finalFilter }
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      try {
        if (data && data.role && data.role === 'data') {
          if (data && data.data && data.data.arrayData && data.data.arrayData.length > 0) {
            this.filterData3 = Array.from(data.data.arrayData);
            const search: string = this.filterData3.map(e => String(e.id))[0];
            this.filter3 = search ? search : '';
            this.finalFilter = this.filter3;
            if (this.finalFilter === '') {
              this.arrayDocs = [];
            } else {
              await this.loadData();
            }
          }
        } else if (data && data.role && data.role === 'clear') {
          this.removeCompaniesFilter();
        } else {
          log.debug('companiesFilter closed');
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
    });
    modal.present();
  }

  doSearch(ev: any) {
    try {
      this.search(ev.target.value);
    } catch (error) {
      log.error(error);
    }
  }

  async search(search: string) {
    const searchStr: string = this.searchService.clearSearch(decodeURI(search), false);
    if (searchStr.length >= 3) {
      try {
        this.nameEvent = 'view_search_results';
        this.analyticsClickEvent('search', {
          content_type: 'Búsqueda contactos',
          search_term: searchStr,
          section_name: 'Contactos'
        });
        this.isLoading = true;
        this.finalFilter = undefined;
        this.searchStr = searchStr;
        try {
          await this.paginate();
        } catch (error) {
          log.error(error);
        }
        this.isLoading = false;
      } catch (error) {
        log.error(error);
      }
    }
  }

  async loadData() {
    this.searchStr = '';
    try {
      await this.paginate();
    } catch (error) {
      log.error(error);
    }
  }

  async clearSearch() {
    this.isLoading = true;
    try {
      this.searchStr = '';
      this.arrayDocs = [];
      await this.loadData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }
}
