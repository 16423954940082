import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { environment } from '@env/environment';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Logger } from '@app/core/logger.service';
import { DocumentReference } from '@angular/fire/firestore';
import moment from 'moment';
const log = new Logger('EmailService');

export interface EmailPreferences {
  recipient: string;
  subject?: string;
  text?: string;
  name?: string;
  cc?: Array<any> | null;
  bcc?: Array<string> | null;
  params?: any;
  templateId?: number;
  notSendDevText?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(
    public credService: CredentialsService,
    private fireService: FirebaseService
  ) { }

  async requestCatalog() { }

  async contactMe() {
    try {
      const userData = await this.fireService.getUserData(this.credService.credentials.uid);

      if (userData) {
        const phone1 = userData.phone;
        const phone2 = userData.mobile;

        const type: string = userData.type === 'representante-medico' ? 'Representante médico' : 'Médico';
        const text1 = `
      <h1>El ${type} ${userData.name} desea contactarse.</h1>
      <h2><strong>Datos de contacto:</strong></h2>
      <p><strong>Nombre:</strong>  ${userData.name} ${userData.lastName1} ${userData.lastName2}</p>
      <p><strong>Email:</strong>  ${this.credService.credentials.email}</p>
      <p><strong>Teléfono:</strong>  ${phone1}</p>
      <p><strong>Teléfono (Secundario):</strong>  ${phone2}</p>`;
        const text2 = `
      <h1>¡Hola ${userData.name}!</h1>
      <p>Gracias por entrar en contacto con nosotros.</p>
      <h2><strong>Datos de contacto:</strong></h2>
      <p><strong>Nombre:</strong>  ${userData.name} ${userData.lastName1} ${userData.lastName2}</p>
      <p><strong>Email:</strong>  ${this.credService.credentials.email}</p>
      <p><strong>Teléfono:</strong>  ${phone1}</p>
      <p><strong>Teléfono (Secundario):</strong>  ${phone2}</p>
      <p>A la brevedad un integrante de nuestro equipo se comunicará contigo.</p>`;

        // Para la plataforma
        await this.email({
          recipient: environment.SALES_EMAIL,
          subject: 'Solitud de contacto',
          text: text1,
          name: ''
        });

        // Para el miembro
        await this.email({
          recipient: this.credService.credentials.email,
          subject: 'Solitud de contacto',
          text: text2,
          name: ''
        });
      }
    } catch (error) {
      log.error(error);
    }
  }

  async pendingNote(data: any) {
    try {
      const userData = await this.fireService.getUserData(this.credService.credentials.uid);

      if (userData) {
        const text1 = `
      <h1>El médico ${userData.name} desea publicar una nota.</h1>
      <p><strong>Nombre:</strong>  ${userData.name} ${userData.lastName1} ${userData.lastName2}</p>
      <hr>
      <h1>Nota</h1>
      <p><strong>Id:</strong>  ${data.postId}</p>
      <p><strong>Título:</strong>  ${data.title}</p>
      <p><strong>Artículo:</strong> <a href="${data.article}" target="_blank">${data.article}</a></p>`;
        const text2 = `
      <h1>¡Hola ${userData.name}!</h1>
      <p>Gracias por enviarnos tu artículo, por el momento tu publicación esta pendiente de revisión, te avisaremos cuando esté público.</p>
      <hr>
      <h1>Nota</h1>
      <p><strong>Id:</strong>  ${data.postId}</p>
      <p><strong>Título:</strong>  ${data.title}</p>`;

        // Para la plataforma
        await this.email({
          recipient: environment.CONTENT_EMAIL,
          subject: 'Publicación de nota',
          text: text1,
          name: ''
        });

        // Para el miembro
        await this.email({
          recipient: this.credService.credentials.email,
          subject: 'Publicación de nota',
          text: text2,
          name: ''
        });
      }
    } catch (error) {
      log.error(error);
    }
  }

  async sampleRequest(message: string) {
    try {
      const userData = await this.fireService.getUserData(this.credService.credentials.uid);
      const text1 = `
      <h1>El médico ${userData.name} ${userData.lastName1} ${userData.lastName2} desea una muestra.</h1>
      <hr>
      ${message}`;
      const text2 = `
      <h1>¡Hola ${userData.name}!</h1>
      <p>Tu solicitud de muestra ha sido enviada, en breve te enviaremos la confirmación de esta solicitud.</p>
      <hr>${message}`;

      // Para la plataforma
      await this.email({
        recipient: environment.CONTACT_EMAIL,
        subject: 'Solicitud de muestra',
        text: text1,
        name: ''
      });

      // Para el miembro
      await this.email({
        recipient: this.credService.credentials.email,
        subject: 'Solicitud de muestra',
        text: text2,
        name: ''
      });
    } catch (error) {
      log.error(error);
    }
  }

  async userUpdatedTheirProfile() {
    try {
      const userData = await this.fireService.getUserData(this.credService.credentials.uid);
      const text1 = `
      <h2>Actualizaci&oacute;n de perfil</h2>
      <hr />
      <p>El m&eacute;dico ${userData.name} ${userData.lastName1} ${userData.lastName2} ha actualizado su perfil.</p>
      <p><strong>ID:</strong>&nbsp;${this.credService.credentials.uid}</p>
      <p><strong>Email:</strong>&nbsp;${this.credService.credentials.email}</p>`;

      //Para la plataforma

      await this.email({
        recipient: environment.CONTACT_EMAIL,
        subject: `Actualización de perfil (${this.credService.credentials.email})`,
        text: text1,
        name: ''
      });
    } catch (error) {
      log.error(error);
    }
  }

  async addClickDataMail(data: { repreId?: string; productId?: string; link?: string; advertising: any }) {
    const USER_DATA = await this.fireService.getUserData(this.credService.credentials.uid);

    let repreData: any = null;
    let productData: any = null;
    let params: any = {};
    let templateId: number;
    let type: string;

    params.medic = {};
    params.medic.name = `${USER_DATA.name} ${USER_DATA.lastName1} ${USER_DATA.lastName2}`;
    params.medic.email = String(USER_DATA.email).toLocaleLowerCase();
    params.medic.phone1 = USER_DATA.mobile;
    params.medic.phone2 = USER_DATA.phone;
    params.medic.uid = this.credService.credentials.uid;

    if (data.link) {
      params.link = {};
      params.link.url = data.link;
      params.link.description = data && data.advertising && data.advertising.content ? data.advertising.content : '';
      templateId = 75;
      type = 'link';
    }

    if (data.repreId) {
      repreData = await this.fireService.getUserData(data.repreId);
      let company: string = '';
      try {
        const REP: any = (await this.fireService.afs.doc(`representante-meta/${data.repreId}`).ref.get()).data();
        const ref: any = REP.company as DocumentReference;
        const COMAPANY = await ref.get();
        company = COMAPANY.get('name');
      } catch (error) {
        log.error(error);
      }
      params.rep = {};
      params.rep.name = `${repreData.name} ${repreData.lastName1} ${repreData.lastName2}`;
      params.rep.email = String(repreData.email).toLocaleLowerCase();
      params.rep.phone1 = repreData.mobile;
      params.rep.phone2 = repreData.phone;
      params.rep.company = company;
      templateId = 80;
      type = 'representant';
    }

    if (data.productId) {
      productData = await this.fireService.getProductData(data.productId);
      repreData = await this.fireService.getUserData(productData.uid);
      let company: string = '';
      try {
        const refCompany = productData.company as DocumentReference;
        company = (await this.fireService.afs.doc(`companies/${refCompany.id}`).ref.get()).get('name');
      } catch (error) {
        log.error(error);
      }
      params.product = {};
      params.product.name = productData.marca;
      params.product.type = productData.type;
      params.product.therapeutic_lines = `${productData.linea_terapeutica_1}, ${productData.linea_terapeutica_2}`;
      params.product.presentations = `${productData.presentacion_1}, ${productData.presentacion_2}`;
      params.product.active_substances = `${productData.producto_sustancia_activa_1}, ${productData.producto_sustancia_activa_2}`;
      params.product.company = company;
      params.rep.name = `${repreData.name} ${repreData.lastName1} ${repreData.lastName2}`;
      params.rep.email = String(repreData.email).toLocaleLowerCase();
      templateId = 79;
      type = 'product';
    }

    try {
      const EMAIL = environment.CLICK_BANNERS_EMAIL;
      const EMAIL_DATA = {
        recipient: EMAIL,
        templateId: templateId,
        params: params
      };
      try {
        const date: string = moment().format('YYYY-MM-DD');
        const refString: string = `advertising/${data.advertising.id}/clicks/${date}/users/${this.credService.credentials.uid}`;
        const ref = this.fireService.afs.doc(refString).ref;
        await ref.set({ dateClick: moment().toDate(), type, ...params.medic }, { merge: true });
      } catch (error) {
        log.error(error);
      }
      return await this.email(EMAIL_DATA);
    } catch (error) {
      log.debug(error);
    }
  }

  async registerAndWelcome(uid: string) {
    try {
      const userData = await this.fireService.getUserData(uid);
      await this.email({
        recipient: userData.email,
        templateId: 5,
        params: {
          name: userData & userData.name ? userData.name : '',
          email: userData & userData.email ? userData.email : ''
        }
      });
    } catch (error) {
      log.error(error);
    }
  }

  async userRegistrationWelcome(email: string, name: string) {
    const text = `
            <div style="font-family: Arial, Helvetica, sans-serif; text-align: center;">
                <table border="0" cellspacing="1" cellpadding="10" align="center">
                    <tbody>
                        <tr>
                            <td>
                                <p>CONECTI<strong>MED</strong>&nbsp;le da la m&aacute;s cordial bienvenida.</p>
                                <p>
                                    <br>
                                </p>
                                <p>Como miembro de la comunidad m&eacute;dica de CONECTI<strong>MED</strong>&nbsp;usted ya tiene
                                    acceso
                                    a toda la informaci&oacute;n confiable, actualizada y de gran utilidad para su
                                    pr&aacute;ctica
                                    m&eacute;dica.</p>
                                <p>
                                    <br>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table border="0" cellspacing="0" cellpadding="0" width="100%" style="text-align: center;">
                                    <tbody>
                                        <tr>
                                            <td width="100%" colspan="2">
                                                <p>Para ingresar a la plataforma, utiliza el siguiente email:
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="100%">
                                                <p>USUARIO:&nbsp;<a href="mailto:${email}">${email}</a></p>
                                            </td>
                                           <!--
                                           <td width="50%">
                                           </td>
                                           -->
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Lo invitamos a conocer los usos y beneficios de la plataforma
                                    CONECTI<strong>MED</strong>&nbsp;y
                                    esperamos que su experiencia sea agradable.</p>
                                <p>
                                    <br>
                                </p>
                                <p>Para cualquier duda o comentario escribanos a</p>
                                <p><a href="mailto:contacto@conectimed.com"
                                        data-cke-saved-href="mailto:contacto@conectimed.com">contacto@conectimed.com</a>&nbsp;donde
                                    con
                                    gusto le atenderemos.</p>
                                <p>
                                    <br>
                                </p>
                                    <br>
                                </p>
                                <p>
                                    <a href="https://youtu.be/VoxbcZcy12E">
                                        &iquest;Qu&eacute; es CONECTI<strong>MED</strong>?
                                    </a>
                                </p>
                                <p>
                                    <a href="https://youtu.be/RK0CC6YPEuM">
                                        &iquest;Qu&eacute; es multimedia para m&eacute;dicos?
                                    </a>
                                </p>
                                <p>
                                    <a href="https://youtu.be/TMAeeOTI_xQ">
                                        &iquest;Que son los puntos CONECTI<strong>MED?</strong>
                                    </a>
                                </p>
                                <p>
                                    <br><br>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table border="0" cellspacing="0" cellpadding="0" width="100%" style="text-align: center;">
                                    <tbody>
                                        <tr>
                                            <td width="50%">
                                                <p>Firma</p>
                                                <p><em>Lic. Pablo Gorozpe</em></p>
                                                <p>Director General CONECTI<strong>MED</strong></p>
                                            </td>
                                            <td width="50%">
                                                <p>Firma</p>
                                                <p><em>Dr. Pablo Svarch</em></p>
                                                <p>Director M&eacute;dico CONECTI<strong>MED</strong></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>`;
    await this.email({
      recipient: email,
      subject: '¡Bienvenido a CONECTIMED!',
      text: text,
      name: name
    });
  }

  async email(parameters: EmailPreferences) {
    try {
      if (!environment.production && !parameters.templateId) {
        if (!(parameters && parameters.notSendDevText === true)) {
          parameters.text += '<h1><b>NOTA: ESTE CORREO ES GENERADO DESDE EL AMBIENTE DE PRUEBAS.</b></h1>';
        }
      }

      if (environment.sendEmails) {
        await this.fireService.callFunctionFB('sendMail', {
          recipient: parameters.recipient,
          subject: parameters.subject,
          text: parameters.text,
          name: parameters.name,
          cc: parameters.cc,
          bcc: parameters.bcc,
          templateId: parameters.templateId,
          params: parameters.params
        });
      }
    } catch (err) {
      log.error(err);
    }
  }

  async reportComments(
    username: string,
    useremail: string,
    postTitle: string,
    postUrl: string,
    comment: string,
    commentOtherUsers: string
  ) {
    let textOptional = '';
    if (commentOtherUsers !== '') {
      textOptional = ` <p>Los usuarios mencionan que: </p> <p> ${commentOtherUsers}</p> `;
    }

    const text = `
   <div style="font-family: Arial, Helvetica, sans-serif; text-align: center;">
       <table border="0" cellspacing="1" cellpadding="10" align="center">
           <tbody>
               <tr>
                   <td>
                       <p>Lamentamos reportar un mal comportamiento con el usuario <strong> ${username} </strong> 
                       con correo <a href="mailto:${useremail}">${useremail}</a>  
                       por el comentario que realizó en el post <a href="${postUrl}">
                       <strong>${postTitle}</strong>
                   </a>, comentando lo siguiente:  </p>
                       <p>
                           "${comment}"
                       </p>
                     
                       ${textOptional}
                
                   </td>
               </tr>
            
           </tbody>
       </table>
   </div>`;
    await this.email({
      recipient: environment.REPORTS_EMAIL,
      subject: '¡Comentario Reportado!',
      text: text,
      name: ':'
    });
  }

  async reportUser(username: string, useremail: string, comment: string) {
    const text = `
   <div style="font-family: Arial, Helvetica, sans-serif; text-align: center;">
       <table border="0" cellspacing="1" cellpadding="10" align="center">
           <tbody>
               <tr>
                   <td>
                       <p>Lamentamos reportar un mal comportamiento con el usuario <strong> ${username} </strong> 
                       con correo <a href="mailto:${useremail}">${useremail}</a>  
                       el cual esta incomodando al usuario <a href="${this.credService.credentials.email}">${this.credService.credentials.email}</a>
                       , persona el cual nos hizo llegar el siguiente comentario al respecto:  </p>
                       <p>
                           "${comment}"
                       </p>
                   </td>
               </tr>
            
           </tbody>
       </table>
   </div>`;
    await this.email({
      recipient: environment.REPORTS_EMAIL,
      subject: '¡Comentario Reportado!',
      text: text,
      name: ':'
    });
  }

  async deleteMyAccount() {
    try {
      const userData = await this.fireService.getUserData(this.credService.credentials.uid);

      if (userData) {
        const phone1 = userData.phone;
        const phone2 = userData.mobile;

        const type: string = userData.type === 'representante-medico' ? 'Representante médico' : 'Médico';

        const textToConectimed = `
      <h1>El ${type} ${userData.name} ha solicitado la eliminacion de su cuenta.</h1>
      <h2><strong>Datos de contacto:</strong></h2>
      <p><strong>Nombre:</strong>  ${userData.name} ${userData.lastName1} ${userData.lastName2}</p>
      <p><strong>Email:</strong>  ${this.credService.credentials.email}</p>
      <p><strong>Teléfono:</strong>  ${phone1}</p>
      <p><strong>Teléfono (Secundario):</strong>  ${phone2}</p>`;

        const textToUser = `
        <h1>Estimado(a) ${userData.name}</h1>
        <p>Usted ha solicitado la eliminación de su cuenta de Conectimed.</p>
        <p>En un lapso de 24 a 48 horas le enviaremos un correo electrónico con la confirmación de la eliminación de su cuenta de Conectimed.</p>
        <p>En este lapso de tiempo puede enviar un correo a <strong>${environment.CONTACT_EMAIL}</strong> con el asunto "Cancelar proceso de eliminación de mi cuenta de Conectimed" si desea cancelar la eliminación de su cuenta de Conectimed </p>`;

        // Para la plataforma
        await this.email({
          recipient: environment.CONTACT_EMAIL,
          subject: 'Solicitud de eliminación de cuenta',
          text: textToConectimed,
          name: ''
        });

        // Para el miembro
        await this.email({
          recipient: this.credService.credentials.email,
          subject: 'Proceso de eliminación de mi cuenta de Conectimed',
          text: textToUser,
          name: ''
        });
      }
    } catch (error) {
      log.error(error);
    }
  }
}
