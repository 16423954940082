import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { FavoriteService } from '@app/multimedia/services/favorite.service';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { Subscription } from 'rxjs';
const log = new Logger('UserSocialMediaOptionsComponent');

@Component({
  selector: 'app-user-social-media-options',
  templateUrl: './user-social-media-options.component.html',
  styleUrls: ['./user-social-media-options.component.scss'],
})
export class UserSocialMediaOptionsComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() favoriteCheck: boolean;
  @Input() view: number;
  isLoading: boolean = false;
  likeSubs: Subscription;

  constructor(
    private fireService: FirebaseService,
    private favoriteS: FavoriteService,
    private utilities: UtilitiesService,
    public credService: CredentialsService,
    private router: Router,
    private analyticsFB: AnalyticsService
  ) { }

  async ngOnInit() {
    if (this.favoriteCheck === true && this.item && this.item.id) {
      this.favoriteCheckInit();
    }
    this.likeCheckInit();
  }

  ngOnDestroy(): void {
    if (this.likeSubs) {
      this.likeSubs.unsubscribe();
    }
  }

  async favoriteCheckInit() {
    try {
      if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
        const ref = this.fireService.afs.doc(`users/${this.credService.credentials.uid}/favorites/${this.item.id}`).ref;
        const resp = await ref.get();
        this.item.favorite = resp.exists;
      }
    } catch (e) {
      log.error(e);
    }
  }

  async likeCheckInit() {
    try {
      if (this.credService && this.credService.credentials && this.credService.credentials.uid && this.item && this.item.id) {
        this.likeSubs = this.fireService.afs.doc(`posts/${this.item.id}/likes/${this.credService.credentials.uid}`).valueChanges().subscribe(value => {
          if (value) {
            this.item.like = true;
          } else {
            this.item.like = false;
          }
        });
      }
    } catch (e) {
      log.error(e);
    }
  }

  public async favorite() {
    this.isLoading = true;
    try {
      this.item.favorite = await this.favoriteS.favorite(this.item.id);
    } catch (error) {
      log.error('Transaction failed: ', error);
    }
    this.isLoading = false;
  }

  async like(): Promise<void> {
    this.isLoading = true;
    const postRef = this.fireService.afs.doc(`posts/${this.item.id}`).ref;
    const likeRef = postRef.collection('likes').doc(this.credService.credentials.uid);
    const userLike = this.fireService.afs.doc(`users/${this.credService.credentials.uid}/likes/${this.item.id}`).ref;
    try {
      await this.fireService.afs.firestore.runTransaction(async (transaction) => {
        const postDoc = await transaction.get(postRef);
        if (!postDoc.exists === true) {
          throw new Error("Post does not exist!");
        }
        const likeDoc = await transaction.get(likeRef);
        if (likeDoc.exists === true) {
          transaction.delete(likeRef);
          transaction.delete(userLike);
          transaction.update(postRef, { likeCount: firebase.firestore.FieldValue.increment(-1) });
          const likes: number = this.item && this.item.likeCount ? this.item.likeCount : 0;
          this.item.likeCount = likes > 0 ? likes - 1 : 0;
          this.item.like = false;
        } else {
          transaction.set(likeRef, { date: firebase.firestore.FieldValue.serverTimestamp() });
          transaction.set(userLike, { date: firebase.firestore.FieldValue.serverTimestamp() });
          transaction.update(postRef, { likeCount: firebase.firestore.FieldValue.increment(1) });
          this.item.likeCount = (this.item && this.item.likeCount ? this.item.likeCount : 0) + 1;
          this.item.like = true;
        }
      });
      this.analyticsClickEvent('post_like', { postId: this.item.id });
      log.debug('Transaction successfully committed!');
    } catch (error) {
      log.error('Transaction failed: ', error);
    }
    this.isLoading = false;
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  public share() {
    this.utilities.share(this.item.routing.route);
    this.analyticsClickEvent('share', {
      content_type: this.item && this.item.title ? this.item.title : '',
      content_category:
        this.item && this.item.routing && this.item.routing.subCategory ? this.item.routing.subCategory : ''
    });
  }
}
