import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { Angulartics2Module } from 'angulartics2';
import { SharedModule } from '@app/shared';
import { NotificationCenterRoutingModule } from './notification-center-routing.module';
import { NotificationCenterComponent } from './notification-center.component';
import { ChatModule } from '@app/shared/chat/chat.module';
import { UserDetailViewModule } from '@app/shared/user-detail-view/user-detail-view.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    IonicModule,
    Angulartics2Module,
    NotificationCenterRoutingModule,
    ChatModule,
    UserDetailViewModule
  ],
  declarations: [NotificationCenterComponent]
})
export class NotificationCenterModule {}
