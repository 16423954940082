<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> {{ idGroup ? 'Actualizar información de grupo' : 'Agregar grupo' }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="dataForm" (ngSubmit)="save()">
          <ion-item>
            <ion-input label="Nombre *" type="text" formControlName="name"></ion-input>
          </ion-item>
          <ion-item>
            <ion-textarea label="Descripción *" formControlName="description" rows="10"></ion-textarea>
          </ion-item>
          <ul>
            <li *ngFor="let item of users; let i = index">
              {{ i + 1 }}.- {{ item?.name + ' ' + item?.lastName1 + ' ' + item?.lastName2 }}
            </li>
          </ul>
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <ion-button (click)="updateUsers()" expand="block" *ngIf="idGroup; else elseButton">
                  <ion-icon name="refresh-outline" slot="start"></ion-icon>
                  Actualizar contactos
                </ion-button>
                <ng-template #elseButton>
                  <ion-button (click)="selectUser()" expand="block">
                    <ion-icon name="add-outline" slot="start"></ion-icon>
                    Seleccionar contactos
                  </ion-button>
                </ng-template>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-text color="danger">
            <p>* Datos obligatorios</p>
          </ion-text>
          <ion-button type="submit" color="tertiary" expand="block" [disabled]="!dataForm.valid">
            {{ idGroup ? 'Actualizar información' : 'Agregar' }}
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
