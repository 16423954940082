import { Component, OnInit, ViewChild } from '@angular/core';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { DocumentData, QueryDocumentSnapshot, QuerySnapshot, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { Router } from '@angular/router';
import { SearchService } from '@app/shared/services/search/search.service';
const log = new Logger('SELECT USER COMPONENT');

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss'],
})
export class SelectUserComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { read: {}, static: true }) infiniteScroll: IonInfiniteScroll;
  public users: any[] = [];
  private searchStr: string;
  public isLoading: boolean = false;
  private searchProducts = false;
  private nameEvent: string = 'select_user';
  public orderBy: string = 'nameStr';
  public orderByDirection: any = 'asc';
  private perPage: number = 50;
  public cursorNext: QueryDocumentSnapshot<any>;
  public cursorBack: QueryDocumentSnapshot<any>;

  constructor(
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private modalCtrl: ModalController,

    private analyticsFB: AnalyticsService,
    private router: Router,
    private searchService: SearchService
  ) { }

  async ngOnInit() {
    this.initializeApp();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  private setQueries() {
    let query = this.fireService.afs
      .collection(`friends/${this.credService.credentials.uid}/friends`)
      .ref.where('status', '==', 'active');
    if (this.searchStr) {
      query = query.where('search', 'array-contains', this.searchStr);
    }
    return query;
  }

  public async paginate(direction?: 'next' | 'back'): Promise<void> {
    let users: any[] = [];
    let query = this.setQueries();
    if (this.cursorNext && direction === 'next') {
      query = query
        .orderBy(this.orderBy, 'asc')
        .startAfter(this.cursorNext)
        .limit(this.perPage);
    } else if (this.cursorBack && direction === 'back') {
      query = query
        .orderBy(this.orderBy, 'asc')
        .startAt(this.cursorBack)
        .limit(this.perPage);
    } else {
      query = query.orderBy(this.orderBy, 'asc').limit(this.perPage);
    }
    try {
      const snap = await query.get();
      this.isLoading = true;
      this.cursorNext = snap.docs.length > 0 ? snap.docs[snap.docs.length - 1] : undefined;
      let snapBack: QuerySnapshot<DocumentData>;
      if (snap && snap.docs[0]) {
        const queryBack = this.setQueries();
        snapBack = await queryBack
          .orderBy(this.orderBy, 'desc')
          .startAfter(snap.docs[0])
          .limit(this.perPage)
          .get();
      } else {
        this.isLoading = false;
        if (this.searchStr) {
          this.users = [];
        }
        return;
      }
      this.cursorBack =
        snapBack && snapBack.docs && snapBack.docs.length > 0 ? snapBack.docs[snapBack.docs.length - 1] : undefined;
      users = snap.docs.map((element: any) => {
        let data: any = element.data();
        const id: string = element.id;
        data.isChecked = false;
        data.isMember = false;
        if (data.type === 'medico') {
          data.metaData = this.fireService.getDoctorData(id);
        } else {
          data.metaData = this.fireService.getRepData(id);
        }
        const str: string = String(
          data && data.avatar && data.avatar.list && data.avatar.list.url ? data.avatar.list.url : ''
        );
        const n = str.search('www.gravatar.com');
        if (n > -1) {
          data.avatar = undefined;
        }
        return { id, ...data };
      });
      this.users = users;
      this.isLoading = false;
    } catch (error) {
      log.error(error);
    }
  }

  async initializeApp(): Promise<void> {
    this.isLoading = true;
    try {
      await this.paginate();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async resetSearch() {
    this.users = [];
    this.isLoading = true;
    try {
      await this.loadData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async productos() {
    this.searchProducts = !this.searchProducts;
  }

  doSearch(ev: any) {
    try {
      this.search(ev);
    } catch (error) {
      log.error(error);
    }
  }

  async search(ev: any) {
    const searchStr: string = this.searchService.clearSearch(ev.target.value, false);
    if (searchStr.length >= 3) {
      try {
        this.nameEvent = 'view_search_results';
        this.analyticsClickEvent('search', {
          content_type: 'Búsqueda contactos',
          search_term: searchStr,

          section_name: 'Contactos'
        });
        this.isLoading = true;
        this.searchStr = searchStr;
        try {
          await this.paginate();
        } catch (error) {
          log.error(error);
        }
        this.isLoading = false;
      } catch (error) {
        log.error(error);
      }
    }
  }

  async loadData() {
    this.searchStr = '';
    try {
      await this.paginate();
    } catch (error) {
      log.error(error);
    }
  }

  async clearSearch() {
    this.isLoading = true;
    try {
      this.searchStr = '';
      this.users = [];
      await this.loadData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  goAction(id: number) {
    this.modalCtrl.dismiss({ users: [id] });
    this.analyticsClickEvent(this.nameEvent, {});
  }

  getName(ids: number[]): string[] {
    let names: string[] = [];
    ids.forEach(id => {
      const index: number = this.users.map(e => e.id).indexOf(id);
      if (index > -1) {
        names.push(this.users[index].name);
      }
    });
    return names;
  }

  closeModal() {
    this.analyticsClickEvent('close_modal', {});
    this.modalCtrl.dismiss();
  }
}
