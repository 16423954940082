<div class="read-content">
  <ion-button (click)="callback('eliminar')" expand="block" fill="clear" color="danger" *ngIf="autor">
    <ion-icon slot="start" name="trash-outline"></ion-icon>
    Eliminar
  </ion-button>
  <ion-button (click)="callback('reportar')" expand="block" fill="clear" color="danger" *ngIf="!autor">
    <ion-icon slot="start" name="alert-outline"></ion-icon>
    Reportar
  </ion-button>
</div>
