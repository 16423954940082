import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InviteAlertComponent } from '../home/invite-alert/invite-alert.component';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private modalCtrl: ModalController
  ) {}

  public async alertLoginSining() {
    const modal = await this.modalCtrl.create({
      component: InviteAlertComponent,
      cssClass: 'auto-height',
    });
    modal.present();
  }
}