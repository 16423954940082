import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Router } from '@angular/router';
import { AlertController, PopoverController, ToastController } from '@ionic/angular';
import { environment } from '@env/environment';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() color: string;
  @Input() buttons: string;
  @Input() viewOptionsButtons: boolean = false;
  realIndex: number = 1;
  private userid: string;
  isLoading: boolean = false;
  @Input() doNotRouting: boolean;
  public openConections: boolean = false;
  TYPE_3_USER = environment.TYPE_3_USER;
  onPopOver: boolean = false;

  constructor(
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    private router: Router,
    public credService: CredentialsService,
    public popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    private utilities: UtilitiesService
  ) {
    this.constants();
  }

  viewDetail(id: string) {
    if (!this.doNotRouting && this.item.status === 'active' && !this.onPopOver) {
      this.router.navigateByUrl('/user/details/' + id);
    }
  }

  goToMedicines(event: any, item: any) {
    event.stopPropagation();
    this.analyticsClickEvent('view_products', {
      section_name: 'Red conectimed',
      company_owner: item.name,
      product_owner: item.name + ' ' + item.lastName1 + ' ' + item.lastName2
    });
    this.router.navigateByUrl('products/user/' + item.uid);
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  ngOnInit() {
    this.realIndex = Number(this.index);
    var str: string = String(
      this.item && this.item.avatar && this.item.avatar.thumbnail && this.item.avatar.thumbnail.url
        ? this.item.avatar.thumbnail.url
        : ''
    );
    var n = str.search('www.gravatar.com');
    if (n > -1) {
      this.item.avatar = undefined;
    }
    this.userid = this.item && this.item.id ? this.item.id : this.item && this.item.uid ? this.item.uid : '';

    if (this.item) {
      let first = this.item.name ? this.item.name.charAt(0) : '';
      let last = this.item.lastName1 ? this.item.lastName1.charAt(0) : '';
      this.item.nameProfile = (first + last).toUpperCase();
    }
  }

  async confirmCancel(): Promise<void> {
    this.analyticsClickEvent('cancel_request', {});
    this.isLoading = true;
    const relation = await this.fireService.getRelationShipByPaticipants(this.userid);
    this.isLoading = false;
    if (relation) {
      this.fireService.confirmCancel(relation);
    }
  }

  async confirmAccept() {
    this.analyticsClickEvent('acept_request', {});

    // Generating the contact Id
    let contactId = this.item.uid;

    // Get the relation
    let relationUid = await this.fireService.getRelationShipByPaticipants(contactId);

    if (!relationUid) {
      // Make the frienship
      const RESP = await this.fireService.sendFriendShip({
        initiator: this.credService.credentials.uid,
        requested: contactId,
        notifications: false
      });
      if (!RESP) {
        return;
      }
      relationUid = await this.fireService.getRelationShipByPaticipants(contactId);
    }

    // Acept the relation
    const RESP1 = await this.fireService.acceptFriendships(relationUid);
    if (!(RESP1.code === '001' && RESP1.success === true)) {
      return;
    }

    // We generate the toast
    this.utilities.toast(`Usuario agregado a su lista de contactos`);
  }

  async confirmReject(): Promise<void> {
    this.analyticsClickEvent('reject_request', {});
    this.isLoading = true;
    const relation = await this.fireService.getRelationShipByPaticipants(this.userid);
    this.isLoading = false;
    if (relation) {
      this.fireService.confirmReject(relation, this.userid);
    }
  }

  async constants() {
    (await this.fireService.getConstants()).subscribe((response: any) => {
      this.openConections = response.openConections;
    });
  }

  async chat(item: any) {
    if (this.credService.credentials.type === environment.TYPE_3_USER) {
      this.openChat(item);
    } else {
      if (this.openConections) {
        this.openChat(item);
      } else {
        let isFriend = await item.isFriend;
        if (isFriend) {
          this.openChat(item);
        } else {
          this.openChat(item);
        }
      }
    }
  }

  openChat(item: any) {
    this.fireService.createChat(item.id, ChatComponent);
    this.analyticsClickEvent('open_chat', {
      section_name: 'Red conectimed',
      message_to: item.name + ' ' + item.lastName1 + ' ' + item.lastName2
    });
  }

  async presentAlertConfirm(item: any) {
    let name = item.name + ' ' + item.lastName1 + ' ' + item.lastName2;
    const alert = await this.alertCtrl.create({
      header: 'Solicitud de conexión',
      message: '¿Deseas agregar como tu contacto a <b>' + name + ' </b> ?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        },
        {
          text: 'Ok',
          handler: async () => {
            this.analyticsClickEvent('request_conection', {
              request_conection_to: name
            });
            await this.fireService.checkUserPoints(this.userid, true);
            this.presentToast('Solicitud enviada', 'success');
          }
        }
      ]
    });

    alert.present();
  }

  async presentToast(message: string, color: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000,
      color
    });
    toast.present();
  }

  onClick() { }
}
