import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit {
  url: string;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
