import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor() { }

  public clearSearch(search: string | number, encode: boolean): string {
    if (search) {
      search = String(search).normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      search = String(search).toLowerCase();
      search = String(search).replace(/[^a-z0-9 ]/gi, '');
      search = String(search).replace(/\s\s+/g, ' ');
      if (encode === true) {
        search = encodeURIComponent(search);
      }
      search = String(search).trim();
      return search;
    } else {
      return undefined;
    }
  }

  public decodeString(search: string | number): string {
    if (search) {
      return decodeURIComponent(String(search).toLocaleLowerCase());
    } else {
      return undefined;
    }
  }
}
