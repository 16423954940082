import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { NavController, ModalController } from '@ionic/angular';
import { EmailService } from '@app/shared/services/email/email.service';
import { Subject, Subscription } from 'rxjs';
import { ProfileService } from '@app/profile/profile.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { Logger } from '@app/core/logger.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { DeleteMyAccountService } from '@app/shared/services/delete-my-account/delete-my-account.service';
import { Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
const log = new Logger('ProfileComponent');

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public userData: any;
  public memberType: string;
  formsMedico = 1;
  formsRM = 1;
  user: any;
  public submit: Subject<void> = new Subject<void>();
  image: string;
  isLoading = false;
  mainSubscription: Subscription;

  constructor(
    public router: Router,
    public credService: CredentialsService,
    private profile: ProfileService,
    private navCtrl: NavController,
    private utilities: UtilitiesService,
    private modalCtrl: ModalController,
    private email: EmailService,
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    private deleteMyAccount: DeleteMyAccountService,
    private app: AppComponent,
    private authServe: AuthenticationService,
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async changePassword() {
    this.analyticsClickEvent('change_password', {});
    const modal = await this.modalCtrl.create({
      component: ChangePasswordComponent
    });
    modal.present();
  }

  async doRefresh(event: any) {
    this.analyticsClickEvent('refresh_page', {});
    await this.initializeApp();
    try {
      if (event && event.target) {
        event.target.complete();
      }
    } catch (error) {
      log.error(error);
    }
  }

  public editImg() {
    this.analyticsClickEvent('edit_image', {});
    this.submit.next();
  }

  back() {
    this.navCtrl.back();
  }

  async initializeApp() {
    this.isLoading = true;
    try {
      await this.fireService.getUserData(this.credService.credentials.uid);
      this.userData = {
        token: this.credService.credentials.token,
        uid: this.credService.credentials.uid,
        type: this.credService.credentials.type,
        status: this.credService.credentials.status
      };
      this.memberType = this.userData.type;
      await this.getUserDetails();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  ngOnInit() {
    this.initializeApp();
    this.getAvatar();
  }

  segmentChanged1(ev: any) {
    this.formsMedico = ev.target.value;
    let title: String;
    switch (String(this.formsMedico)) {
      case '1':
        title = 'Personales';
        break;
      case '2':
        title = 'Profesionales';
        break;
      case '3':
        title = 'Direcciones';
        break;
    }
    this.analyticsClickEvent('select_content', {
      content__type: 'profile/' + String(title).toLowerCase(),
      content_title: title,
      click_text: title
    });
  }

  segmentChanged2(ev: any) {
    this.formsRM = ev.target.value;
    this.analyticsClickEvent('select_content', {
      content__type: 'profile/' + this.formsRM,
      content_title: this.formsRM,
      click_text: this.formsRM
    });
  }

  async reload() {
    this.getAvatar();
    this.profile.update(this.image);
  }

  async getUserDetails() {
    this.user = await this.fireService.getUserData(this.credService.credentials.uid);
  }

  async presentAlertResp() {
    this.analyticsClickEvent('update_info', {});
    this.utilities.toast('Datos actualizados correctamente.', 'Correcto');
    if (this.credService.credentials.type === 'medico' && this.credService.credentials.active === false) {
      this.email.userUpdatedTheirProfile();
    }
    try {
      const currentUser = await this.authServe.getUser();
      await this.app.setUserDataAndCredentials(currentUser);
      await this.initializeApp();
      await this.getAvatar();
    } catch (error) {
      log.error(error);
    }
  }

  private async getAvatar() {
    this.mainSubscription = this.fireService.afs
      .collection('users')
      .doc(this.credService.credentials.uid)
      .valueChanges()
      .subscribe((snap: any) => {
        if (snap && snap.avatar && snap.avatar.main && snap.avatar.main.url) {
          this.image = snap.avatar.main.url;
          const str: string = String(this.image ? this.image : '');
          const n = str.search('www.gravatar.com');
          if (n > -1) {
            this.image = undefined;
          }
        }
      });
  }

  deleteAccountAlert() {
    this.deleteMyAccount.deleteAccountAlert();
  }
}
