import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-img-modal',
  templateUrl: './img-modal.component.html',
  styleUrls: ['./img-modal.component.scss'],
})
export class ImgModalComponent implements OnInit {
  @Input() img: string;

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() { }
}
