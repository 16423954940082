import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { Observable } from 'rxjs';
const log = new Logger('MedicoGuard');

@Injectable({
  providedIn: 'root'
})
export class MedicoGuard {
  constructor(
    private router: Router,
    public credService: CredentialsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      this.credService &&
      this.credService.credentials &&
      this.credService.credentials.type &&
      this.credService.credentials.type === 'medico'
    ) {
      return true;
    } else {
      log.warn(`%c${state.url} *** route not permitted ***`, 'color: red', route.paramMap);
      this.router.navigate(['/home']);
      return false;
    }
  }
}
