<app-address-form *ngIf="user" [submit]="submit" [target]="1" [user]="user" (valid)="form1 = $event"
  [title]="user?.type === 'medico' ? 'Dirección 1' : 'Dirección'" (response)="onResponse1()">
</app-address-form>
<app-address-form *ngIf="user && user.type === 'medico'" [target]="2" [user]="user" (valid)="form2 = $event"
  [required]="false" [collapsed]="true" title="Dirección 2" [submit]="submit" (response)="onResponse2()">
</app-address-form>
<div class="ion-padding">
  <ion-button *ngIf="user && user.type === 'medico'" type="button" color="tertiary" expand="block" [disabled]="!(form1 == true && form2 == true)"
    (click)="emitEventToChild()">
    {{ button }}
  </ion-button>
  <ion-button *ngIf="user && user.type === 'profesional-de-la-salud'" type="button" color="tertiary" expand="block" [disabled]="!(form1 == true)"
    (click)="emitEventToChild()">
    {{ button }}
  </ion-button>
</div>