<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center"> <ion-icon name="person-outline"></ion-icon> Contactos </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      type="text"
      debounce="1000"
      (ionChange)="doSearch($event)"
      (ionCancel)="clearSearch()"
      (ionClear)="clearSearch()"
      enterkeyhint="send"
      placeholder="Buscar"
      animated
      [disabled]="isLoading"
    >
    </ion-searchbar>
    <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list *ngIf="users && users[0]">
    <app-user-card
      *ngFor="let item of users; let i = index"
      [index]="i + 1"
      [item]="item"
      (click)="goAction(item.id)"
      [doNotRouting]="true"
    >
    </app-user-card>
  </ion-list>
  <ion-grid class="ion-no-margin ion-no-padding ion-margin-top">
    <ion-row>
      <ion-col size="6" class="ion-no-margin ion-no-padding">
        <ion-button
          (click)="paginate('back')"
          expand="block"
          color="light"
          [disabled]="!cursorBack || isLoading == true"
        >
          <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size="6  " class="ion-no-margin ion-no-padding">
        <ion-button
          (click)="paginate('next')"
          expand="block"
          color="light"
          [disabled]="!cursorNext || isLoading == true"
        >
          <ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
