import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { IndustryRoutesService } from '@app/industry/services/industry-routes.service';
import { SampleRequestService } from '@app/industry/services/sample-request.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';


@Component({
  selector: 'app-i-item',
  templateUrl: './i-item.component.html',
  styleUrls: ['./i-item.component.scss'],
})
export class IItemComponent implements OnInit {
  @Input() item: any;

  constructor(
    private router: Router,
    private credService: CredentialsService,
    private analyticsFB: AnalyticsService,
    public sampleRequestService: SampleRequestService,
    private industryRoutesService: IndustryRoutesService
  ) { }

  ngOnInit() {
    this.item.routing = this.industryRoutesService.mapRoute(this.item.type, this.item.id);
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

}
