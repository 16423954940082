import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '../core/authentication/credentials.service';
import { Subscription } from 'rxjs';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { GroupDetailComponent } from '@app/chat-list/group-detail/group-detail.component';
import { RedConectimedComponent } from '@app/red-conectimed/red-conectimed.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { GroupOptionsComponent } from './group-options/group-options.component';
import * as moment from 'moment';
import { Logger } from '@app/core/logger.service';
import { DocumentData, AngularFirestoreCollection, QueryDocumentSnapshot, QuerySnapshot, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { FiltersComponent } from './filters/filters.component';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
const log = new Logger('Chat List');
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnInit {
  public isLoading = false;
  public chatsInbox: any[] = [];
  public chatsSentbox: any[] = [];
  public groups: any[] = [];
  public usersId: number[] = [];
  public segment = 'chat-list';
  public userType = '';
  public item: any;
  public cursorNext: QueryDocumentSnapshot<any>;
  public cursorBack: QueryDocumentSnapshot<any>;
  private message = 'masivo';
  private currentPage: number;
  private totalPages: number;
  private mainSubscribe: Subscription;
  public chats: any[];
  public me: string;
  private relations: any;
  private chatsCollection: AngularFirestoreCollection<any>;
  private mainSubscription: Subscription;
  private perPage: number = 20;
  canShowAlert: boolean = true;

  constructor(
    private fireService: FirebaseService,
    public credService: CredentialsService,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private utilities: UtilitiesService,
    private analyticsFB: AnalyticsService,
    private router: Router
  ) {
    moment.locale('es');
    this.userType = this.credService.credentials.type;
    this.me = String(this.credService.credentials.uid);
  }

  public paginate(direction?: 'next' | 'back'): void {
    const uid: string = String(this.credService.credentials.uid);
    if (this.cursorNext && direction === 'next') {
      this.chatsCollection = this.fireService.afs.collection('chats', ref => {
        if (this.item && this.item.id === 'received') {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.receiver', '==', uid)
            .orderBy('last_message.date', 'desc')
            .startAfter(this.cursorNext)
            .limit(this.perPage);
        } else if (this.item && this.item.id === 'sended') {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.user', '==', uid)
            .orderBy('last_message.date', 'desc')
            .startAfter(this.cursorNext)
            .limit(this.perPage);
        } else if (this.item && this.item.id === 'date') {
          let start: any = `${this.item.data.inicio.date} ${this.item.data.inicio.time}`;
          let end: any = `${this.item.data.fin.date} ${this.item.data.fin.time}`;
          start = moment(start).toDate();
          end = moment(end).toDate();
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.date', '>=', start)
            .where('last_message.date', '<=', end)
            .orderBy('last_message.date', 'desc')
            .startAfter(this.cursorNext)
            .limit(this.perPage);
        } else {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .orderBy('last_message.date', 'desc')
            .startAfter(this.cursorNext)
            .limit(this.perPage);
        }
      });
    } else if (this.cursorBack && direction === 'back') {
      this.chatsCollection = this.fireService.afs.collection('chats', ref => {
        if (this.item && this.item.id === 'received') {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.receiver', '==', uid)
            .orderBy('last_message.date', 'desc')
            .startAt(this.cursorBack)
            .limit(this.perPage);
        } else if (this.item && this.item.id === 'sended') {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.user', '==', uid)
            .orderBy('last_message.date', 'desc')
            .startAt(this.cursorBack)
            .limit(this.perPage);
        } else if (this.item && this.item.id === 'date') {
          let start: any = `${this.item.data.inicio.date} ${this.item.data.inicio.time}`;
          let end: any = `${this.item.data.fin.date} ${this.item.data.fin.time}`;
          start = moment(start).toDate();
          end = moment(end).toDate();
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.date', '>=', start)
            .where('last_message.date', '<=', end)
            .orderBy('last_message.date', 'desc')
            .startAt(this.cursorBack)
            .limit(this.perPage);
        } else {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .orderBy('last_message.date', 'desc')
            .startAt(this.cursorBack)
            .limit(this.perPage);
        }
      });
    } else {
      this.chatsCollection = this.fireService.afs.collection('chats', ref => {
        if (this.item && this.item.id === 'received') {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.receiver', '==', uid)
            .orderBy('last_message.date', 'desc')
            .limit(this.perPage);
        } else if (this.item && this.item.id === 'sended') {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.user', '==', uid)
            .orderBy('last_message.date', 'desc')
            .limit(this.perPage);
        } else if (this.item && this.item.id === 'date') {
          let start: any = `${this.item.data.inicio.date} ${this.item.data.inicio.time}`;
          let end: any = `${this.item.data.fin.date} ${this.item.data.fin.time}`;
          start = moment(start).toDate();
          end = moment(end).toDate();
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .where('last_message.date', '>=', start)
            .where('last_message.date', '<=', end)
            .orderBy('last_message.date', 'desc')
            .limit(this.perPage);
        } else {
          return ref
            .where('participants', 'array-contains', uid)
            .where('initialized', '==', true)
            .orderBy('last_message.date', 'desc')
            .limit(this.perPage);
        }
      });
    }
    try {
      this.mainSubscription = this.chatsCollection.snapshotChanges().subscribe(async snap => {
        this.isLoading = true;
        this.cursorNext = snap.length > 0 ? snap[snap.length - 1].payload.doc : undefined;
        let snap2: QuerySnapshot<DocumentData>;
        if (snap && snap[0] && snap[0].payload && snap[0].payload.doc) {
          if (this.item && this.item.id === 'received') {
            snap2 = await this.fireService.afs
              .collection('chats')
              .ref.where('participants', 'array-contains', uid)
              .where('initialized', '==', true)
              .where('last_message.receiver', '==', uid)
              .orderBy('last_message.date', 'asc')
              .startAfter(snap[0].payload.doc)
              .limit(this.perPage)
              .get();
          } else if (this.item && this.item.id === 'sended') {
            snap2 = await this.fireService.afs
              .collection('chats')
              .ref.where('participants', 'array-contains', uid)
              .where('initialized', '==', true)
              .where('last_message.user', '==', uid)
              .orderBy('last_message.date', 'asc')
              .startAfter(snap[0].payload.doc)
              .limit(this.perPage)
              .get();
          } else if (this.item && this.item.id === 'date') {
            let start: any = `${this.item.data.inicio.date} ${this.item.data.inicio.time}`;
            let end: any = `${this.item.data.fin.date} ${this.item.data.fin.time}`;
            start = moment(start).toDate();
            end = moment(end).toDate();
            snap2 = await this.fireService.afs
              .collection('chats')
              .ref.where('participants', 'array-contains', uid)
              .where('initialized', '==', true)
              .where('last_message.date', '>=', start)
              .where('last_message.date', '<=', end)
              .orderBy('last_message.date', 'asc')
              .startAfter(snap[0].payload.doc)
              .limit(this.perPage)
              .get();
          } else {
            snap2 = await this.fireService.afs
              .collection('chats')
              .ref.where('participants', 'array-contains', uid)
              .where('initialized', '==', true)
              .orderBy('last_message.date', 'asc')
              .startAfter(snap[0].payload.doc)
              .limit(this.perPage)
              .get();
          }
        } else {
          const alert = await this.alertCtrl.create({
            header: 'Sin resultados',
            message: 'El filtro aplicado no generó resultados.',
            buttons: [
              {
                text: 'Aceptar',
                role: 'cancel',
                handler: () => {
                  this.item = undefined;
                  this.paginate();
                }
              }
            ]
          });

          alert.onDidDismiss().then(() => {
            this.canShowAlert = true;
          });

          if (this.canShowAlert === true) {
            this.canShowAlert = false;
            alert.present();
          }

          this.isLoading = false;
          return;
        }
        this.cursorBack = snap2 && snap2.docs && snap2.docs.length > 0 ? snap2.docs[snap2.docs.length - 1] : undefined;
        this.chats = snap.map(element => {
          const data = element.payload.doc.data();
          let contactUid;
          if (data.participants[0] === this.credService.credentials.uid) {
            contactUid = data.participants[1];
          } else {
            contactUid = data.participants[0];
          }
          data.accepted = this.haveRelation(contactUid, 'accepted');
          const StrippedString = String(data.last_message.message).replace(/(<([^>]+)>)/gi, '');
          data.last_message.message = StrippedString;
          const dateStr = moment(data.last_message.date.toDate()).format('DD-MM-YYYY h:mm A');
          data.dateStr = dateStr;
          const id = element.payload.doc.id;
          return { id, ...data };
        });
        this.chats.map(async element => {
          const data = element;
          let fullName = '';
          let avatar = '';
          let type = '';
          let flagParticipant;
          if (data.participants[0] !== this.credService.credentials.uid) {
            flagParticipant = data.participants[0];
          } else {
            flagParticipant = data.participants[1];
          }
          const user = await this.fireService.getUserData(flagParticipant);
          fullName =
            (user && user.name ? user.name : '') +
            ' ' +
            (user && user.lastName1 ? user.lastName1 : '') +
            ' ' +
            (user && user.lastName2 ? user.lastName2 : '');
          avatar = user && user.avatar && user.avatar.list && user.avatar.list.url ? user.avatar.list.url : '';
          type = user && user.type ? user.type : '';
          var str: string = String(avatar ? avatar : '');
          var n = str.search('www.gravatar.com');
          if (n > -1) {
            avatar = undefined;
          }
          data.userDetected = { nombre: fullName, avatar, type };
          return { nombre: fullName, avatar, type };
        });
        this.isLoading = false;
      });
    } catch (error) {
      log.error(error);
    }
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  haveRelation(contactUid: string, type: 'accepted' | 'pendings') {
    let have: boolean = false;
    for (const relation of this.relations[type]) {
      if (contactUid === relation.contactUid) {
        have = true;
        return have;
      }
    }
    return have;
  }

  public myStatus(item: any) {
    return item.last_message.viewers[this.me].news;
  }

  async options(ev: any, id: number) {
    const popover = await this.popoverCtrl.create({
      component: GroupOptionsComponent,
      event: ev,
      translucent: true,
      componentProps: {
        idGroup: id
      }
    });
    popover.present();
  }

  async filter(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: FiltersComponent,
      event: ev,
      translucent: true,
      componentProps: {
        item: this.item
      }
    });
    popover.onDidDismiss().then(data => {
      if (data && data.role === 'filter' && data.data && data.data.item && data.data.item.checked === true) {
        this.item = data.data.item;
        this.paginate();
      } else if (data.role === 'clear') {
        this.item = undefined;
        this.paginate();
      }
    });
    popover.present();
  }

  public member(item: any): any {
    const uid: string = this.credService.credentials.uid;
    const members: any[] = Array.from(item.members);
    const index: number = Array.from(item.members)
      .map((element: any) => element.id)
      .indexOf(uid);
    members.splice(index, 1);
    if (index > -1) {
      return members[0];
    }
  }

  async ngOnInit() {
    this.init();
  }

  async ngOnDestroy() {
    if (this.mainSubscription) {
      this.mainSubscription.unsubscribe();
    }
  }

  async init() {
    this.isLoading = true;
    try {
      this.relations = await this.fireService.getRelations();
      await this.getGroups();
      this.paginate();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  ionViewWillLeave() {
    try {
      if (this.mainSubscribe) {
        this.mainSubscribe.unsubscribe();
      }
    } catch (error) {
      log.error(error);
    }
  }

  async confirmDeleteGroup(id: string) {
    this.analyticsClickEvent('delete_group', {});
    const alert = await this.alertCtrl.create({
      header: 'Borrar grupo',
      message: '¿Estás seguro de borrar este grupo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.deleteGroup(id);
          }
        }
      ]
    });
    alert.present();
  }

  async confirmDelete(id: string) {
    const alert = await this.alertCtrl.create({
      header: 'Borrar mensaje',
      message: '¿Estás seguro de borrar este mensaje?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.delete(id);
          }
        }
      ]
    });
    alert.present();
  }

  async deleteGroup(id: string) {
    this.isLoading = true;
    try {
      await this.fireService.afs
        .collection('broadcast-list')
        .doc(id)
        .delete();
      this.utilities.toast('¡Grupo eliminado correctamente!');
      await this.getGroups();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async delete(id: string) {
    this.analyticsClickEvent('delete_chat', {});
    try {
      this.fireService.deleteChat(id);
    } catch (error) {
      log.error(error);
    }
  }

  segmentButtonClicked(ev: any) {
    this.segment = String(ev.target.value);
    this.analyticsClickEvent('select_content', {
      content_type: this.segment,
      content_title: this.segment,
      click_text: this.segment
    });
  }

  addChat() {
    this.selectUser('chat');
    this.analyticsClickEvent('create_chat', {});
  }

  public async loadGroups(event: any): Promise<void> {
    this.isLoading = true;
    if (this.currentPage < this.totalPages) {
      try {
        await this.getGroups();
      } catch (error) {
        log.error(error);
      }
    }
    try {
      event.target.complete();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  public async getGroups(): Promise<void> {
    try {
      const response = await this.fireService.afs
        .collection('broadcast-list')
        .ref.where('uid', '==', this.credService.credentials.uid)
        .orderBy('nameStr', 'asc')
        .get();
      this.groups = response.docs.map(element => {
        const data: any = element.data();
        const id = element.id;
        return { id, ...data };
      });
    } catch (error) {
      log.error(error);
    }
  }

  async selectUser(view: string) {
    const modal = await this.modalCtrl.create({
      component: RedConectimedComponent,
      componentProps: { viewType: 'chat' }
    });
    modal.onDidDismiss().then(users => {
      if (users.data && view === 'chat') {
        this.chat(users.data.users[0]);
      }
    });
    modal.present();
  }

  async addGroup(id?: string) {
    this.analyticsClickEvent('add_member', {});
    const modal = await this.modalCtrl.create({
      component: AddGroupComponent,
      componentProps: { idGroup: id }
    });
    modal.onDidDismiss().then(() => {
      this.getGroups();
    });
    modal.present();
  }

  public async chat(userid: string, sendHere?: boolean): Promise<void> {
    this.fireService.createChat(userid, ChatComponent, sendHere, [
      { message: this.message, isFile: false, url: '', convert: false }
    ]);
  }

  async goChat(id: string, accepted: boolean = true): Promise<void> {
    this.analyticsClickEvent('click', {
      content_type: 'chat'
    });
    try {
      this.fireService.goChat(id, ChatComponent, accepted);
    } catch (error) {
      log.error(error);
    }
  }

  public async detail(id: string) {
    this.analyticsClickEvent('click', {
      content_type: 'Detalles Chat'
    });
    try {
      const modal = await this.modalCtrl.create({
        component: GroupDetailComponent,
        componentProps: { idGroup: id }
      });
      modal.present();
    } catch (error) {
      log.error(error);
    }
  }
}
