import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { ActivatedUserGuard } from '@app/core/authentication/guards/activated-user.guard';
import { AuthenticationGuard } from '@app/core/authentication/guards/authentication.guard';
import { NoAuthenticatedGuard } from '@app/core/authentication/guards/no-authenticated.guard';

const routes: Routes = [{
  path: 'home',
  loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
},
{
  path: 'register',
  loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
},
{
  path: 'surveys',
  loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule),
},
{
  path: 'reset-password',
  loadChildren: () => import('./profile/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  canActivate: [NoAuthenticatedGuard]
},
{
  canActivate: [AuthenticationGuard],
  path: 'about',
  loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
},
{
  canActivate: [AuthenticationGuard],
  path: 'my-profile',
  loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
},
{
  canActivate: [AuthenticationGuard],
  path: 'frequent-questions',
  loadChildren: () => import('./frequent-questions/frequent-questions.module').then(m => m.FrequentQuestionsModule)
},
{
  canActivate: [AuthenticationGuard],
  path: 'privacy',
  loadChildren: () => import('./notice-of-privacy/notice-of-privacy.module').then(m => m.NoticeOfPrivacyModule)
},
{
  canActivate: [AuthenticationGuard],
  path: 'terms',
  loadChildren: () => import('./terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule)
},
{
  path: 'multimedia',
  loadChildren: () => import('./multimedia/multimedia.module').then(m => m.MultimediaModule)
},
{
  canActivate: [ActivatedUserGuard, AuthenticationGuard],
  path: 'industry',
  loadChildren: () => import('./industry/industry.module').then(m => m.IndustryModule)
},
{
  canActivate: [ActivatedUserGuard, AuthenticationGuard],
  path: 'user',
  loadChildren: () => import('./user-details/user-details.module').then(m => m.UserDetailsModule)
},
{
  canActivate: [ActivatedUserGuard, AuthenticationGuard],
  path: 'red-conectimed',
  loadChildren: () => import('./red-conectimed/red-conectimed.module').then(m => m.RedConectimedModule)
},
{
  canActivate: [ActivatedUserGuard, AuthenticationGuard],
  path: 'chat-list',
  loadChildren: () => import('./chat-list/chat-list.module').then(m => m.ChatListModule)
},
{
  canActivate: [ActivatedUserGuard, AuthenticationGuard],
  path: 'certificates',
  loadChildren: () => import('./certificates/certificates.module').then(m => m.CertificatesModule)
},
{
  canActivate: [ActivatedUserGuard, AuthenticationGuard],
  path: 'delete-account',
  loadChildren: () => import('./delete-account/delete-account.module').then(m => m.DeleteAccountModule)
},
{
  // canActivate: [ActivatedUserGuard, AuthenticationGuard],
  path: 'donations',
  loadChildren: () => import('./donations/donations.module').then(m => m.DonationsModule)
},
{
  path: 'notifications',
  canActivate: [ActivatedUserGuard, AuthenticationGuard],
  loadChildren: () => import('./notification-center/notification-center.module').then(m => m.NotificationCenterModule)
},
{
  path: 'sharing',
  canActivate: [ActivatedUserGuard, AuthenticationGuard],
  loadChildren: () => import('./sharing/sharing-routing.module').then(m => m.SharingRoutingModule)
},
{
  path: '',
  redirectTo: 'home',
  pathMatch: 'full'
},
{
  path: '**',
  redirectTo: 'home',
  pathMatch: 'full'
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
