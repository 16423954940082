import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { from } from 'rxjs';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { LadaService, Phone } from '@app/shared/services/lada/lada.service';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('Personal data form');

export interface PersonalData {
  name: string;
  lastName1: string;
  lastName2: string;
  mobile: string;
  phone: string;
}

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrls: ['./personal-data-form.component.scss'],
})
export class PersonalDataFormComponent implements OnInit {
  @Input() user: UserData;
  @Input() modeData: string;
  @Input() userType: string;
  @Output() response = new EventEmitter<void>();
  @Input() button = 'Siguiente';
  public dataForm: FormGroup;
  public isLoading: boolean;

  phone: Phone = {
    number: '',
    lada: '52',
    country: 'MEXICO',
    color: 'light',
    error: false
  };

  mobile: Phone = {
    number: '',
    lada: '52',
    country: 'MEXICO',
    color: 'light',
    error: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private fireService: FirebaseService,
    private credService: CredentialsService,
    private utilities: UtilitiesService,
    private analyticsFB: AnalyticsService,
    private router: Router,
    public lada: LadaService
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async ngOnInit() {
    await this.initForm();
    await this.initData();
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', Validators.required],
      lastName1: ['', Validators.required],
      lastName2: [''],
      mobile: ['', [Validators.required]],
      mobileLada: ['52', [Validators.required]],
      phone: ['', [Validators.required]],
      phoneLada: ['', [Validators.required]]
    });
  }

  private refillForm(data: any) {
    this.dataForm.patchValue({
      name: data && data.name ? data.name : '',
      lastName1: data && data.lastName1 ? data.lastName1 : '',
      lastName2: data && data.lastName2 ? data.lastName2 : '',
      mobile: data && data.mobileLada && data.mobileLada.number ? data.mobileLada.number : '',
      mobileLada: data && data.mobileLada && data.mobileLada.lada ? data.mobileLada.lada : '',
      phone: data && data.phoneLada && data.phoneLada.number ? data.phoneLada.number : '',
      phoneLada: data && data.phoneLada && data.phoneLada.lada ? data.phoneLada.lada : '',
    });
  }

  public async initData(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      let data: any = (await this.fireService.getUserData(this.user.uid)) || {};

      const mobile = this.utilities.getPhone(
        data && data.mobileLada && data.mobileLada.number ? data.mobileLada.number : (data && data.mobile ? data.mobile : ''),
        data && data.mobileLada && data.mobileLada.lada ? data.mobileLada.lada : ''
      );

      const phone = this.utilities.getPhone(
        data && data.phoneLada && data.phoneLada.number ? data.phoneLada.number : (data && data.phone ? data.phone : ''),
        data && data.phoneLada && data.phoneLada.lada ? data.phoneLada.lada : ''
      );

      data.mobile = mobile;
      data.phone = phone;

      if (data.mobileLada) {
        this.mobile.lada = data.mobileLada.lada;
        this.mobile.country = data.mobileLada.country;
        data.mobileLada.number = mobile;
      }
      if (data.phoneLada) {
        this.phone.lada = data.phoneLada.lada;
        this.phone.country = data.phoneLada.country;
        data.phoneLada.number = phone;
      }
      this.refillForm(data);
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async submit(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const nameStr = this.utilities.stringSearch(
        `${this.dataForm.get('lastName1').value} ${this.dataForm.get('lastName2').value} ${this.dataForm.get('name').value
        }`,
        true
      );

      let name: string = String(this.dataForm.get('name').value)
        .trim()
        .replace(/\s{2,}/g, ' ');

      let lastName1: string = String(this.dataForm.get('lastName1').value)
        .trim()
        .replace(/\s{2,}/g, ' ');

      let lastName2: string = String(this.dataForm.get('lastName2').value)
        .trim()
        .replace(/\s{2,}/g, ' ');

      name = String(name)
        .toLocaleLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
      lastName1 = String(lastName1)
        .toLocaleLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
      lastName2 = String(lastName2)
        .toLocaleLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));

      // const mobile: string = String(`${this.mobile.lada}${String(this.dataForm.get('mobile').value).trim()}`);
      //const phone: string = String(`${this.phone.lada}${String(this.dataForm.get('phone').value).trim()}`);

      const mobile: string = String(`${String(this.dataForm.get('mobileLada').value).trim()}${String(this.dataForm.get('mobile').value).trim()}`);
      const phone: string = String(`${String(this.dataForm.get('phoneLada').value).trim()}${String(this.dataForm.get('phone').value).trim()}`);

      let data = {
        name: name,
        lastName1: lastName1,
        lastName2: lastName2,
        nameStr: nameStr,
        firstCharacter: nameStr.charAt(0) || '',
        mobile: mobile,
        mobileLada: {
          number: String(this.dataForm.get('mobile').value).trim(),
          lada: String(this.dataForm.get('mobileLada').value).trim(),
          country: this.phone.country
        },
        phone: phone,
        phoneLada: {
          number: String(this.dataForm.get('phone').value).trim(),
          lada: String(this.dataForm.get('phoneLada').value).trim(),
          country: this.phone.country
        }
      };

      await this.fireService.updateUser(this.user.uid, data);
      this.response.emit();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  phoneHasError(ev: boolean) {
    if (ev) {
      this.phone.color = 'light';
      this.phone.error = false;
    } else {
      this.phone.color = 'danger';
      this.phone.error = true;
    }
  }

  phoneCountryChange(ev: any) {
    log.debug('=================================== phoneCountryChange ===================================', ev);
    this.phone.lada = ev.dialCode;
    this.phone.country = ev.iso2;
  }

  mobilHasError(ev: boolean) {
    if (ev) {
      this.mobile.color = 'light';
      this.mobile.error = false;
    } else {
      this.mobile.color = 'danger';
      this.mobile.error = true;
    }
  }

  mobilCountryChange(ev: any) {
    this.mobile.lada = ev.dialCode;
    this.mobile.country = ev.iso2;
  }
}
