import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSelect, ModalController } from '@ionic/angular';
import { FormTermOfUseComponent } from '@app/shared/forms/account-data-form/form-term-of-use/form-term-of-use.component';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { TypesOfHealthProfessionalsService } from '@app/shared/services/types-of-health-professionals/types-of-health-professionals.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailNotExistService } from '@app/shared/services/email-not-exist/email-not-exist.service';
import { EmailNotExist } from '@app/shared/validators/emailNotExist.validator';
import { Logger } from '@app/core/logger.service';
import { AddLocationComponent } from '@app/shared/forms/add-location/add-location.component';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { LadaService, Phone } from '@app/shared/services/lada/lada.service';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { LoginComponent } from '../login/login.component';
import { SpecilatySelectComponent } from './specilaty-select/specilaty-select.component';
const log = new Logger('RegisterComponent');

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  @ViewChild('typesOfHealthProfessionalsSelect', { static: false }) selectRef: IonSelect;
  public dataForm: FormGroup;
  public isLoading: boolean = false;
  public specialties: any[] = [];
  public phone: Phone = {
    number: '',
    lada: '52',
    color: 'light',
    country: 'MEXICO',
    error: false
  };
  public formType: 'register' | 'complete-registration' = 'register';
  public typesOfHealthProfessionals: any[] = [];
  public specialtyLabel: string = '';

  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private loader: IonLoaderService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private specialtiesService: SpecialtiesService,
    private typesOfHealthProfessionalsService: TypesOfHealthProfessionalsService,
    private alertCtrl: AlertController,
    private utilities: UtilitiesService,
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    private authServe: AuthenticationService,
    public lada: LadaService,
    private emailNotExistService: EmailNotExistService
  ) {
    this.formType =
      this.aRoute &&
        this.aRoute.snapshot &&
        this.aRoute.snapshot.routeConfig &&
        this.aRoute.snapshot.routeConfig.path &&
        this.aRoute.snapshot.routeConfig.path === 'complete-registration'
        ? 'complete-registration'
        : 'register';
    this.initForm();
  }

  analyticsClickEvent(eventName: string, params: {}, cred: any) {
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  ngOnInit() {
    this.inializeApp();
  }

  async inializeApp() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      if (!(this.formType === 'register')) {
        const user = await this.authServe.getUser();
        const complete: boolean = await this.authServe.registrationComplete(user.uid);
        if (!!complete) {
          this.router.navigate(['/register/account-activation']);
        }
        const userData = await this.fireService.getUserData(user.uid);
        const userDoctorData = await this.fireService.getDoctorData(user.uid);
        await this.refillForm({ ...userData, ...userDoctorData });
      }
      await this.initSpecialties();
      await this.initTypesOfHealthProfessionals();
      this.postalCode();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async termOfUseComponent(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: FormTermOfUseComponent
    });
    modal.present();
  }

  private async refillForm(data: any) {
    const specialty = data && data.specialty1 && data.specialty1.id ? Number(data.specialty1.id) : '';
    this.dataForm.patchValue({
      name: data && data.name ? data.name : '',
      lastName1: data && data.lastName1 ? data.lastName1 : '',
      lastName2: data && data.lastName2 ? data.lastName2 : '',
      cedula: data && data.specialty1 && data.specialty1.cedula ? data.specialty1.cedula : '',
      mobile: data && data.mobileLada && data.mobileLada.number ? data.mobileLada.number : '',
      mobileLada: data && data.mobileLada && data.mobileLada.lada ? data.mobileLada.lada : '',
      postal_code: data && data.address1 && data.address1.postalCode ? data.address1.postalCode : '',
      state: data && data.address1 && data.address1.state ? data.address1.state : '',
      delegation: data && data.address1 && data.address1.delegation ? data.address1.delegation : '',
      colony: data && data.address1 && data.address1.colony ? data.address1.colony : '',
      city: data && data.address1 && data.address1.city ? data.address1.city : ''
    });
    setTimeout(() => {
      this.dataForm.patchValue({
        specialty: specialty
      });
    }, 1500);
  }

  private initForm() {
    if (this.formType === 'complete-registration') {
      this.dataForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        lastName1: ['', [Validators.required]],
        lastName2: ['', [Validators.required]],
        cedula: ['', [Validators.required]],
        specialty: ['', [Validators.required]],
        healthProfessionalType: [''],
        mobile: ['', [Validators.required]],
        mobileLada: ['52', [Validators.required]],
        postal_code: ['', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
        state: ['', [Validators.required]],
        delegation: ['', [Validators.required]],
        colony: ['', [Validators.required]],
        city: ['', [Validators.required]]
      });
    } else {
      this.dataForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        lastName1: ['', [Validators.required]],
        lastName2: ['', [Validators.required]],
        cedula: ['', [Validators.required]],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ],
          [EmailNotExist.createValidator(this.emailNotExistService)]
        ],
        password: ['', [Validators.required, Validators.minLength(6)]],
        specialty: ['', [Validators.required]],
        healthProfessionalType: [''],
        mobile: ['', [Validators.required]],
        mobileLada: ['52', [Validators.required]],
        termsAndConditions: [false, Validators.required],
        postal_code: ['', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]],
        state: ['', [Validators.required]],
        delegation: ['', [Validators.required]],
        colony: ['', [Validators.required]],
        city: ['', [Validators.required]]
      });
    }
  }

  public async beforeRegister() {
    const alert = await this.alertCtrl.create({
      backdropDismiss: false,
      header: 'Registro Conectimed',
      message: `Antes de continuar, se le enviará un mensaje de texto a su número telefónico con el código de activación de su cuenta. Si no esta seguro de su información revise sus datos.`,
      buttons: [
        {
          text: 'Corregir mis datos',
          role: 'cancel'
        },
        {
          text: 'Continuar',
          handler: () => {
            if (this.formType === 'complete-registration') {
              this.completeRegistration();
            } else {
              this.register();
            }
          }
        }
      ]
    });
    alert.present();
  }

  public async completeRegistration() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const nameStr = this.utilities.stringSearch(
        `${this.dataForm.get('lastName1').value} ${this.dataForm.get('lastName2').value} ${this.dataForm.get('name').value
        }`,
        true
      );
      let name: string = String(this.dataForm.get('name').value)
        .trim()
        .replace(/\s{2,}/g, ' ');
      let lastName1: string = String(this.dataForm.get('lastName1').value)
        .trim()
        .replace(/\s{2,}/g, ' ');
      let lastName2: string = String(this.dataForm.get('lastName2').value)
        .trim()
        .replace(/\s{2,}/g, ' ');
      name = String(name)
        .toLocaleLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
      lastName1 = String(lastName1)
        .toLocaleLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
      lastName2 = String(lastName2)
        .toLocaleLowerCase()
        .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
      const mobile: string = String(`${String(this.dataForm.get('mobileLada').value).trim()}${String(this.dataForm.get('mobile').value).trim()}`);
      let data: any = {
        name: name,
        lastName1: lastName1,
        lastName2: lastName2,
        nameStr: nameStr,
        firstCharacter: nameStr.charAt(0) || '',
        mobile: mobile,
        mobileLada: {
          number: String(this.dataForm.get('mobile').value).trim(),
          lada: String(this.dataForm.get('mobileLada').value).trim(),
          country: this.phone.country
        }
      };
      const type: string = String(this.dataForm.get('specialty').value) === 'profesional-de-la-salud' ? 'profesional-de-la-salud' : 'medico';
      data.type = type;
      const response: any = await this.authServe.getUser();
      if (response && response.uid && response.isAnonymous === false) {
        await this.fireService.updateUser(response.uid, data);
        await this.fireService.updateDoctor(response.uid, {
          specialty1: {
            id: Number(this.dataForm.get('specialty').value),
            cedula: this.dataForm.get('cedula').value
          },
          address1: {
            postalCode: this.dataForm.get('postal_code').value,
            state: this.dataForm.get('state').value,
            delegation: this.dataForm.get('delegation').value,
            colony: this.dataForm.get('colony').value,
            city: this.dataForm.get('city').value
          }
        });
        this.analyticsClickEvent(
          'click',
          {
            content_type: 'Información personal',
            section_name: 'Registro de usuario',
            click_text: 'Siguiente',
            user_type: 'medico'
          },
          { uid: response.uid, email: response.email, type: 'medico' }
        );
        await this.fireService.sendMobileVerification({
          number: mobile,
          uid: response.uid
        });
        this.router.navigate(['/register/account-activation']);
      } else {
        this.utilities.presentAlert(
          'Ha ocurrido un error al intentar registrarse, por favor contacte al soporte técnico de Conectimed, código de error: ' +
          response['code'] +
          '.',
          'Contacte a soporte técnico'
        );
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async register() {
    this.isLoading = true;
    try {
      const nameStr = this.utilities.stringSearch(`${this.dataForm.get('lastName1').value} ${this.dataForm.get('lastName2').value} ${this.dataForm.get('name').value}`, true);
      let name: string = String(this.dataForm.get('name').value).trim().replace(/\s{2,}/g, ' ');
      let lastName1: string = String(this.dataForm.get('lastName1').value).trim().replace(/\s{2,}/g, ' ');
      let lastName2: string = String(this.dataForm.get('lastName2').value).trim().replace(/\s{2,}/g, ' ');
      const email: string = String(this.dataForm.get('email').value).toLocaleLowerCase().trim();
      const password: string = String(this.dataForm.get('password').value);
      name = String(name).toLocaleLowerCase().replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
      lastName1 = String(lastName1).toLocaleLowerCase().replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
      lastName2 = String(lastName2).toLocaleLowerCase().replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
      const mobile: string = String(`${String(this.dataForm.get('mobileLada').value).trim()}${String(this.dataForm.get('mobile').value).trim()}`);
      let data = {
        email,
        name: name,
        lastName1: lastName1,
        lastName2: lastName2,
        nameStr: nameStr,
        firstCharacter: nameStr.charAt(0) || '',
        mobile: mobile,
        mobileLada: {
          number: String(this.dataForm.get('mobile').value).trim(),
          lada: String(this.dataForm.get('mobileLada').value).trim(),
          country: this.phone.country
        }
      };
      const type: string = String(this.dataForm.get('specialty').value) === 'profesional-de-la-salud' ? 'profesional-de-la-salud' : 'medico';
      const response = await this.fireService.createUser(email, password, type);
      if (response && response.user && response.user.uid) {
        await this.fireService.updateUser(response.user.uid, data);
        if (type === 'medico') {
          await this.fireService.updateDoctor(response.user.uid, {
            specialty1: {
              id: Number(this.dataForm.get('specialty').value),
              cedula: this.dataForm.get('cedula').value
            },
            address1: {
              postalCode: this.dataForm.get('postal_code').value,
              state: this.dataForm.get('state').value,
              delegation: this.dataForm.get('delegation').value,
              colony: this.dataForm.get('colony').value,
              city: this.dataForm.get('city').value
            }
          });
        } else {
          await this.fireService.updateProfesionalSalud(response.user.uid, {
            healthProfessionalType: {
              id: String(this.dataForm.get('healthProfessionalType').value),
              cedula: this.dataForm.get('cedula').value
            },
            address1: {
              postalCode: this.dataForm.get('postal_code').value,
              state: this.dataForm.get('state').value,
              delegation: this.dataForm.get('delegation').value,
              colony: this.dataForm.get('colony').value,
              city: this.dataForm.get('city').value
            }
          });
        }
        this.analyticsClickEvent(
          'click',
          {
            content_type: 'Información personal',
            section_name: 'Registro de usuario',
            click_text: 'Siguiente',
            user_type: 'medico'
          },
          { uid: response.user.uid, email: email, type: 'medico' }
        );
        await this.fireService.sendMobileVerification({
          number: mobile,
          uid: response.user.uid
        });

        this.router.navigate(['/register/account-activation']);
      } else {
        if (response && response['code']) {
          const code = response['code'];
          switch (code) {
            case 'auth/invalid-email': {
              this.utilities.presentAlert(
                "El Email que intenta registrar no cumple con el formato: 'micorreo@dominio.com', por favor revise ésta información.",
                'Email inválido'
              );
              break;
            }
            case 'auth/weak-password': {
              this.utilities.presentAlert(
                'La contraseña que intenta ingresar es débil, debe contar con un mínimo de 6 caracteres.',
                'Contraseña débil'
              );
              break;
            }
            case 'auth/operation-not-allowed': {
              this.utilities.presentAlert(
                'Por el momento el método de autenticación esta desactivado, por favor contacte al soporte técnico de Conectimed.',
                'Contacte a soporte técnico'
              );
              break;
            }
            case 'auth/email-already-in-use': {
              this.utilities.presentAlert(
                'Ya existe una cuenta registrada con el correo (<strong>' +
                String(this.dataForm.get('email').value).toLowerCase() +
                "</strong>), si está seguro de que se trata de su correo o su proceso de registro no pudo ser completado, por favor, dirígase al login para completar su registro, si no recuerda su contraseña, favor de dar click en el botón '¿Olvidó su contraseña?'.",
                'La cuenta ya existe'
              );
              this.router.navigate([
                '/home/actions/open-login/' + String(this.dataForm.get('email').value).toLowerCase()
              ]);
              break;
            }
          }
        } else {
          this.utilities.presentAlert(
            'Ha ocurrido un error al intentar registrarse, por favor contacte al soporte técnico de Conectimed, código de error: ' +
            response +
            '.',
            'Contacte a soporte técnico'
          );
        }
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  async initSpecialties(): Promise<void> {
    try {
      const specialties: any[] = await this.specialtiesService.getSpecialties();
      this.specialties = specialties.map(e => {
        return {
          id: Number(e.id),
          name: e.name
        };
      });
    } catch (error) {
      log.error(error);
    }
  }

  async initTypesOfHealthProfessionals(): Promise<void> {
    try {
      await this.typesOfHealthProfessionalsService.getHealthProfessionals();
      this.typesOfHealthProfessionals = await this.typesOfHealthProfessionalsService.getHealthProfessionals();
    } catch (error) {
      log.error(error);
    }
  }

  async postalCode() {
    try {
      await this.dataForm.get('postal_code').valueChanges.subscribe(async (value: string) => {
        this.isLoading = true;
        try {
          if (this.dataForm.value.state !== null) {
            this.dataForm.get('state').reset();
          }
          if (this.dataForm.value.delegation !== null) {
            this.dataForm.get('delegation').reset();
          }
          if (this.dataForm.value.colony !== null) {
            this.dataForm.get('colony').reset();
          }
          if (this.dataForm.value.city !== null) {
            this.dataForm.get('city').reset();
          }
          if (value && value.toString().length > 4) {
            const resp = await this.fireService.afs
              .collection('zipcodes')
              .doc(value)
              .ref.get();
            if (resp.exists === true) {
              const response = await this.fireService.afs
                .collection('zipcodes')
                .doc(value)
                .collection('colonies')
                .ref.get();
              const filteredColonies: any[] = Array.from(response.docs.map(e => e.data()));
              if (filteredColonies.length == 0) {
                this.addLocation();
              }
              if (filteredColonies.length > 0) {
                this.dataForm.get('state').setValue(filteredColonies[0].state);
                this.dataForm.get('delegation').setValue(filteredColonies[0].city);
                this.dataForm.get('colony').setValue(filteredColonies[0].colony);
                if (filteredColonies[0].state === 'Ciudad de México') {
                  this.dataForm.get('city').setValue('Ciudad de México');
                } else {
                  this.dataForm.get('city').setValue(filteredColonies[0].city);
                }
              }
              // if (filteredColonies.length > 1) {
              //   const inputs: any[] = [];
              //   this.dataForm.get('state').setValue(filteredColonies[0].state);
              //   this.dataForm.get('delegation').setValue(filteredColonies[0].city);

              //   filteredColonies.forEach((colony, index) => {
              //     inputs.push({
              //       value: index,
              //       label: colony.colony,
              //       type: 'radio'
              //     });
              //   });

              //   const alert = await this.alertCtrl.create({
              //     backdropDismiss: false,
              //     header: 'Seleciona una colonia',
              //     subHeader: `Hay ${filteredColonies.length} colonias que comparten el mismo código postal selecciona una por favor.`,
              //     inputs,
              //     buttons: [
              //       {
              //         text: 'Cancelar',
              //         cssClass: 'ion-text-danger',
              //         handler: () => {
              //           this.dataForm.get('postal_code').setValue(null);
              //           this.dataForm.get('state').reset();
              //           this.dataForm.get('delegation').reset();
              //           this.dataForm.get('colony').reset();
              //           this.dataForm.get('city').reset();
              //         }
              //       },
              //       {
              //         text: 'Aceptar',
              //         handler: (data: any) => {
              //           if (data !== undefined) {
              //             const selectedColony = filteredColonies[data];
              //             this.dataForm.get('colony').setValue(selectedColony.colony);
              //             if (selectedColony.state === 'Ciudad de México') {
              //               this.dataForm.get('city').setValue('Ciudad de México');
              //             } else {
              //               this.dataForm.get('city').setValue(selectedColony.city);
              //             }
              //             return true;
              //           } else {
              //             this.utilities.presentAlert("", "Seleccione una opción por favor")
              //             return false;
              //           }
              //         }
              //       }
              //     ]
              //   });
              //   alert.present();
              // }
            } else {
              this.addLocation();
            }
          }
        } catch (error) {
          log.error(error);
        }
        this.isLoading = false;
      });
    } catch (error) {
      log.error(error);
    }
  }

  async addLocation(): Promise<any> {
    const alert = await this.alertCtrl.create({
      backdropDismiss: false,
      header: 'No se encontró código postal',
      message: '¿Desea agregar la locación manualmente?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: async () => {
            this.dataForm.get('postal_code').setValue(null);
            this.dataForm.get('state').reset();
            this.dataForm.get('delegation').reset();
            this.dataForm.get('colony').reset();
            this.dataForm.get('city').reset();
          }
        },
        {
          text: 'Agregar',
          handler: async () => {
            const modal = await this.modalCtrl.create({
              component: AddLocationComponent,
              componentProps: {
                zipcode: String(this.dataForm.get('postal_code').value),
                uid: '' //this.user.uid
              }
            });
            modal.onDidDismiss().then(resp => {
              if (resp && resp.data && resp.data.location) {
                this.dataForm.get('state').setValue(resp.data.location.state);
                this.dataForm.get('delegation').setValue(resp.data.location.delegation);
                this.dataForm.get('colony').setValue(resp.data.location.colony);
                this.dataForm.get('city').setValue(resp.data.location.city);
              }
            });
            modal.present();
          }
        }
      ]
    });
    alert.present();
  }

  public async checkSpecialties(): Promise<void> {
    this.isLoading = true;
    try {
      if (!(this.specialties && this.specialties.length > 0)) {
        await this.initSpecialties();
      }
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  changeSpecialty(event: any) {
    if (event === 'profesional-de-la-salud') {
      this.dataForm.get('healthProfessionalType').clearValidators();
      this.dataForm.get('healthProfessionalType').setValidators([Validators.required]);
      this.dataForm.get('healthProfessionalType').updateValueAndValidity();
    } else {
      this.dataForm.get('healthProfessionalType').clearValidators();
      this.dataForm.get('healthProfessionalType').updateValueAndValidity();
    }
  }

  emailChange(ev: any) {
    let value: string = ev.detail.value;
    value = value.replace(/\s/g, '');
    this.dataForm.get('email').patchValue(value);
  }

  public async modalLogin() {
    const modal = await this.modalCtrl.create({
      component: LoginComponent
    });
    modal.present();
  }

  public async presentModalSpecialty() {
    const modal = await this.modalCtrl.create({
      component: SpecilatySelectComponent,
      backdropDismiss: false,
      componentProps: { value: this.dataForm.get('specialty').value }
    });
    modal.onDidDismiss().then((ret: any) => {
      if (ret && ret.data && ret.role && ret.role === 'data') {
        this.dataForm.get('specialty').patchValue(ret.data.id);
        this.specialtyLabel = ret.data.name;
        if (ret.data.id === 'profesional-de-la-salud') {
          setTimeout(() => this.selectRef.open(), 500);
        }
      }
    });
    modal.present();
  }
}
