import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
import { SearchCompanyComponent } from '@app/shared/search-company/search-company.component';
import { AddCompanyComponent } from '@app/shared/search-company/add-company/add-company.component';
import { DocumentReference, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { CredentialsService } from '../../../core/authentication/credentials.service';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('Login');

export interface Company {
  uid: string;
  name: string;
  businessType: string;
  companyWebsite: string;
}

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-company-data-form',
  templateUrl: './company-data-form.component.html',
  styleUrls: ['./company-data-form.component.scss'],
})
export class CompanyDataFormComponent implements OnInit {
  @Input() user: UserData;
  @Output() response = new EventEmitter<void>();
  @Input() button: string = 'Siguiente';
  @Input() isProfile: boolean = true;
  public isLoading: boolean;
  public company: any;
  public verButton: boolean;
  public haveWebsite: boolean = false;

  constructor(
    private loader: IonLoaderService,
    private fireService: FirebaseService,
    private modalCtrl: ModalController,

    private analyticsFB: AnalyticsService,
    private utilities: UtilitiesService,
    public credService: CredentialsService,
    private router: Router
  ) {
    this.initData();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async selectCompany() {
    this.analyticsClickEvent('click', {
      content_type: 'Buscar y seleccionar company',
      section_name: 'Company',
      click_text: 'Buscar y seleccionar'
    });

    const modal = await this.modalCtrl.create({
      component: SearchCompanyComponent
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      await this.loader.ionLoaderPresent();
      try {
        if (data && data.data && data.data.arrayData && data.data.arrayData.length > 0) {
          const id: string = data.data.arrayData[0].id;
          const ref: DocumentReference = this.fireService.afs.doc('companies/' + id).ref;
          await this.fireService.updateUser(this.user.uid, {
            'filter-meta-data': [id]
          });
          await this.fireService.setRep(this.user.uid, {
            company: ref
          });
          this.initData();
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
      await this.loader.ionLoaderDismiss();
    });
    modal.present();
  }

  async addCompany() {
    this.analyticsClickEvent('click', {
      content_type: 'Agregar company',
      section_name: 'Company',
      click_text: 'Agregar nueva empresa'
    });
    const modal = await this.modalCtrl.create({
      component: AddCompanyComponent
    });
    modal.onDidDismiss().then(async data => {
      this.isLoading = true;
      await this.loader.ionLoaderPresent();
      try {
        if (data && data.data && data.data.id) {
          const ref: DocumentReference = this.fireService.afs.doc('companies/' + data.data.id).ref;
          await this.fireService.updateUser(this.user.uid, {
            'filter-meta-data': [data.data.id]
          });
          await this.fireService.setRep(this.user.uid, {
            company: ref
          });
          this.initData();
        }
      } catch (error) {
        log.error(error);
      }
      this.isLoading = false;
      await this.loader.ionLoaderDismiss();
    });
    modal.present();
  }

  ngOnInit() { }

  public async initData(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const data = await this.fireService.getRepData(this.user.uid);
      this.company = data.company;
      if (!(this.company && this.company.logo)) {
        const name: string = this.company && this.company.name ? this.company.name : '';
        const split = name.split(' ');
        if (split.length > 1) {
          this.company.nameLogo = split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase();
        } else {
          this.company.nameLogo = name.charAt(0).toUpperCase();
        }
      }
      if (this.company && this.company.companyWebsite) {
        const website: string = this.company.companyWebsite;
        if (website.split('https://').length === 1 && website.split('http://').length === 1) {
          this.haveWebsite = this.utilities.validateUrl('http://' + website);
          if (this.haveWebsite) {
            this.company.companyWebsite = 'http://' + this.company.companyWebsite;
          }
        } else {
          this.haveWebsite = this.utilities.validateUrl(this.company.companyWebsite);
        }
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  navToPage() {
    window.open(this.company.companyWebsite, '_blank');
    this.analyticsClickEvent('company_open_website', {
      content_type: 'Abrir pagina web de empresa',
      click_URL: this.company.companyWebsite,

      section_name: 'Empresas'
    });
  }

  emit() {
    this.response.emit();
  }
}
