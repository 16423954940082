<ion-content>
  <ion-grid [fixed]="false">
    <ion-row>
      <ion-col size="12">
        <!-- Company Card -->
        <ion-card class="animate__animated animate__fadeIn">
          <ion-grid [fixed]="false">
            <ion-row class="ion-align-items-center ion-justify-content-center">
              <ion-col size="12">
                <app-i-rep-card [user]="rep"></app-i-rep-card>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-card-content>
            <div class="separador-h"></div>
          </ion-card-content>
          <ion-card-content>
            <ion-grid [fixed]="false">
              <ion-row>
                <!-- Elements -->
                <ion-col *ngFor="let item of products; let i = index" sizeXs="12" sizeMd="6">
                  <app-i-item [item]="item"></app-i-item>
                </ion-col>
                <ion-col size="12" *ngIf="!isLoading === true && !products[0]">
                  <app-i-no-results></app-i-no-results>
                </ion-col>
                <!-- Skeleton loading -->
                <ion-col size="12" *ngIf="isLoading === true">
                  <app-i-skeleton></app-i-skeleton>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
          <!-- Products -->
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- Infinite scroll (get next elements) -->
  <ion-infinite-scroll threshold="100px" (ionInfinite)="getMoreItems($event)" [disabled]="isLoading === true">
    <ion-infinite-scroll-content loadingSpinner="circular" loadingText="Cargando información...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>