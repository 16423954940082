import { Component, OnInit } from '@angular/core';
import {  ModalController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { ChatGroupComponent } from '../chat-group/chat-group.component';
import { ChatComponent } from '@app/shared/chat/chat.component';
const log = new Logger('GROUP DETAIL');
import { Message, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import * as moment from 'moment';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { UserSelectorComponent } from '@app/shared/components/user-selector/user-selector.component';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss']
})
export class GroupDetailComponent implements OnInit {
  public isLoading: boolean = false;
  public members: any[] = [];
  idGroup: any;
  data: {
    name: string;
    description: string;
  } = {
      name: '',
      description: ''
    };
  messages: Message[];

  constructor(
    private loader: IonLoaderService,
    private modalCtrl: ModalController,
    private utilities: UtilitiesService,
    private fireService: FirebaseService,
    private analyticsFB: AnalyticsService,
    public credService: CredentialsService,
    private router: Router
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response1: any = await this.fireService.afs
        .collection('broadcast-list')
        .doc(this.idGroup)
        .ref.get();
      this.data.name = response1.data().name;
      this.data.description = response1.data().description;
      const response2 = await this.fireService.afs
        .collection('broadcast-list')
        .doc(this.idGroup)
        .collection('contacts')
        .ref.orderBy('nameStr', 'asc')
        .get();
      this.members = response2.docs.map(item => {
        const data = item.data();
        const id = item.id;
        return { id, ...data };
      });
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  public detail() { }

  async chatGroup() {
    this.analyticsClickEvent('open_chat', {});
    const modal = await this.modalCtrl.create({
      component: ChatGroupComponent,
      componentProps: { idGroup: this.idGroup }
    });
    modal.onDidDismiss().then(message => {
      if (message.data && message.data.messages) {
        this.messages = Array.from(message.data.messages);
        if (
          message.data &&
          message.data.analytics &&
          message.data.analytics.label &&
          message.data.analytics.send === true
        ) {
          this.messages.map(element => {
            const data: any = element;
            data.label = String(message.data.analytics.label);
            return data;
          });
        }
        this.messageText();
      }
    });
    modal.present();
  }

  public async chat(userid: string, sendHere?: boolean): Promise<void> {
    this.fireService.createChat(userid, ChatComponent, sendHere, this.messages);
  }

  async messageText() {
    await this.loader.ionLoaderPresent();
    this.isLoading = true;
    try {
      const contacts = (
        await this.fireService.afs
          .collection('broadcast-list')
          .doc(this.idGroup)
          .collection('contacts')
          .ref.get()
      ).docs.map(element => {
        const id = element.id;
        return id;
      });
      let pormises: Promise<any>[] = [];
      contacts.forEach(element => {
        pormises.push(this.chat(element, true));
      });
      await Promise.all(pormises);
      for (let i = 0; i < this.messages.length; i++) {
        await this.fireService.afs
          .collection('broadcast-list')
          .doc(this.idGroup)
          .collection('messages')
          .add({
            message: this.messages[i].message,
            date: moment().toDate()
          });
      }
      this.utilities.toast('Mensaje enviado correctamente.', 'Correcto');
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  async membersGo() {
    try {
      this.analyticsClickEvent('edit_members', {});
      const response1 = await this.fireService.afs
        .collection('broadcast-list')
        .doc(this.idGroup)
        .collection('contacts')
        .ref.orderBy('nameStr', 'asc')
        .get();
      const initialArray = response1.docs.map(item => {
        let element = item.data();
        const id = item.id;
        return { id, ...element };
      });
      const modal = await this.modalCtrl.create({
        component: UserSelectorComponent,
        componentProps: { selectedUsers: initialArray, idGroup: this.idGroup }
      });

      modal.onDidDismiss().then(async users => {
        if (users.data && users.data.users) {
          await this.loader.ionLoaderPresent();
          try {
            const response = await this.fireService.afs
              .collection('broadcast-list')
              .doc(this.idGroup)
              .collection('contacts')
              .ref.get();
            const collection = response.docs.map(element => {
              const data = element.data();
              const id = element.id;
              return { id, ...data };
            });
            const batch1 = this.fireService.afs.firestore.batch();
            collection.forEach(element => {
              const ref = this.fireService.afs
                .collection('broadcast-list')
                .doc(this.idGroup)
                .collection('contacts')
                .doc(element.id).ref;
              batch1.delete(ref);
            });
            await batch1.commit();
            const mapped = Array.from(users.data.users).map((element: any) => {
              let dataUSER = {
                name: element.name,
                lastName1: element.lastName1,
                lastName2: element.lastName2,
                nameStr: '',
                firstCharacter: '',
                id: element.id
              };
              const nameStr = this.utilities.stringSearch(
                dataUSER.lastName1 + ' ' + dataUSER.lastName2 + ' ' + dataUSER.name,
                true
              );
              dataUSER.nameStr = nameStr;
              dataUSER.firstCharacter = nameStr.charAt(0) || '';
              return dataUSER;
            });
            const batch2 = this.fireService.afs.firestore.batch();
            mapped.forEach(element => {
              const ref = this.fireService.afs
                .collection('broadcast-list')
                .doc(this.idGroup)
                .collection('contacts')
                .doc(element.id).ref;
              batch2.set(ref, element);
            });
            await batch2.commit();
            this.init();
          } catch (error) {
            log.error(error);
          }
          this.utilities.toast('Grupo modificado correctamente.', 'Correcto');
          await this.loader.ionLoaderDismiss();
        }
      });
      modal.present();
    } catch (error) {
      log.error(error);
    }
  }
}
