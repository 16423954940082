import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@app/core/logger.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { from } from 'rxjs';
const log = new Logger('Add Location');

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss'],
})
export class AddLocationComponent implements OnInit {
  zipcode: string;
  uid: string;
  states: any[] = [];
  cities: any[] = [];
  public dataForm: FormGroup;

  public isLoading: boolean = false;
  constructor(
    private fireService: FirebaseService,
    private loader: IonLoaderService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController
  ) {
    this.initForm();
    this.initLocations();
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      state: ['', Validators.required],
      city: ['', Validators.required],
      colony: ['', Validators.required]
    });
  }

  ngOnInit() { }

  private async initLocations(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      let statesObj: any = {};
      const resp = await this.fireService.afs
        .collection('zipcodes')
        .ref.orderBy('state')
        .get();
      const docs: any[] = resp.docs.map(item => {
        const data: any = item.data();
        const id: string = item.id;
        return { id, ...data };
      });
      for (const item of docs) {
        if (statesObj && statesObj[item.state]) {
          const index: number = statesObj[item.state].indexOf(item.city);
          if (!(index > -1)) {
            statesObj[item.state].push(item.city);
          }
        } else {
          statesObj[item.state] = [];
          statesObj[item.state].push(item.city);
        }
      }
      for (const property in statesObj) {
        this.states.push({ label: property, value: statesObj[property] });
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
    return;
  }

  public selectEdo(item: any) {
    log.debug(item);
  }

  changeEdo(ev: any) {
    this.dataForm.controls['city'].setValue('');
    this.dataForm.controls['city'].updateValueAndValidity();
    if (ev && ev.target && ev.target.value) {
      const state: string = String(ev.target.value);
      const index: number = this.states.map(e => e.label).indexOf(state);
      if (index > -1) {
        this.cities = Array.from(this.states[index].value).sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });
      }
    }
  }

  async save(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    this.uid = this.uid ? this.uid : String(moment().valueOf());
    let address = {
      city: String(this.dataForm.controls['city'].value),
      colony: String(this.dataForm.controls['colony'].value),
      delegation: String(this.dataForm.controls['city'].value),
      state: String(this.dataForm.controls['state'].value)
    };
    if (String(this.dataForm.controls['state'].value) === 'Ciudad de México') {
      address.city = 'Ciudad de México';
    } else {
      address.city = String(this.dataForm.controls['city'].value);
    }
    try {
      const doc = this.fireService.afs.collection('zipcodes-suggestion').doc(this.zipcode);
      const resp = await doc.ref.get();
      const data = {
        city: address.city,
        colony: address.colony,
        postal_code: this.zipcode,
        state: address.state
      };
      if (resp.exists === true) {
        const docs = await doc
          .collection('colonies')
          .ref.where('colony', '==', address.colony)
          .get();
        if (docs.empty === true) {
          const colony = await doc.collection('colonies').add(data);
          const users = await colony
            .collection('users')
            .where('uid', '==', this.uid)
            .get();
          if (users.empty === true) {
            await colony
              .collection('users')
              .doc(this.uid)
              .set({
                uid: this.uid
              });
          }
        }
      } else {
        await doc.set({
          city: address.city,
          state: address.state
        });
        const colony = await doc.collection('colonies').add(data);
        const users = await colony
          .collection('users')
          .where('uid', '==', this.uid)
          .get();
        if (users.empty === true) {
          await colony
            .collection('users')
            .doc(this.uid)
            .set({
              uid: this.uid
            });
        }
      }
    } catch (error) {
      log.error(error);
    }
    this.modalCtrl.dismiss({ location: address }, 'save');
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
    return;
  }

  closeModal() {
    this.modalCtrl.dismiss(undefined, 'close');
  }
}
