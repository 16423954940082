import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('NoRepGuard');
@Injectable({
  providedIn: 'root'
})
export class NoRepGuard {
  constructor(
    public credService: CredentialsService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const type = this.credService.credentials.type;
    if (type === 'representante-medico') {
      log.warn(`%c${state.url} *** route not permitted ***`, 'color: red', route.paramMap);
      this.router.navigateByUrl('home');
      return false;
    } else {
      return true;
    }
  }
}
