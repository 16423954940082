import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlToRedirectService {
  private url: string;
  private id: string;
  private item: any;

  setData(url: string, id: string, item: any) {
    this.url = url;
    this.id = id;
    this.item = item;
  }

  getData() {
    return {
      url: this.url,
      id: this.id,
      item: this.item
    };
  }
}
