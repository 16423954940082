import { Injectable } from '@angular/core';
import { HttpService, HttpHeaders } from '@app/shared/services/http/http.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class SpecialtiesService {
  constructor(
    private http: HttpService
  ) { }

  public async getSpecialties(): Promise<any> {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json',
      };
      const resp: any = await this.http.get(`${environment.FIREBASE_API}getSpecialties`, { headers: headers });
      if (resp && resp.specialties) {
        return resp.specialties;
      } else {
        return [];
      }
    } catch (error) {
      return {};
    }
  }

  public async getSpecialty(id: number): Promise<any> {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json',
      };
      const resp: any = await this.http
        .post(
          `${environment.FIREBASE_API}getSpecialty`,
          {
            id: id,
          },
          { headers: headers }
        );
      return resp;
    } catch (error) {
      return {};
    }
  }
}
