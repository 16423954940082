import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LoginComponent } from './login.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule, IonicModule, RouterModule],
  declarations: [LoginComponent, ChangepasswordComponent],
})
export class LoginModule { }
