import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { Logger } from '@app/core/logger.service';
const log = new Logger('ReportcommentComponent');
import { EmailService } from '@app/shared/services/email/email.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { Comments } from '../models/comments';
import { FirebaseService } from '../services/firebase/firebase.service';

@Component({
  selector: 'app-reportcomment',
  templateUrl: './reportcomment.component.html',
  styleUrls: ['./reportcomment.component.scss']
})
export class ReportcommentComponent implements OnInit {
  @Input() commentData: Comments;
  @Input() idPost: string;
  @Input() titlePost: string;
  @Input() postUrl: string;
  dataForm: FormGroup;
  isLoading: boolean;

  constructor(
    private email: EmailService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    public alertCtrl: AlertController,
    private loader: IonLoaderService,
    private fireService: FirebaseService,
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    await this.createForm();
    this.isLoading = false;
  }

  createForm() {
    this.dataForm = this.formBuilder.group({
      comments: ['']
    });
  }

  async sendEmail() {
    await this.loader.ionLoaderPresent();
    const alertSuccess = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Éxito',
      message: 'El reporte fue enviado exitosamente, ¡gracias por tu colaboración!',
      buttons: [
        {
          text: 'Ok'
        }
      ]
    });
    let username = '';
    try {
      if (this.commentData && this.commentData.userUid) {
        try {
          const resp = await this.fireService.afs
            .collection('users')
            .doc(this.commentData.userUid)
            .ref.get();
          const data: any = resp.data();
          username = `${data && data.name ? String(data.name) : ''} ${data && data.lastName1 ? String(data.lastName1) : ''
            } ${data && data.lastName2 ? String(data.lastName2) : ''}`;
        } catch (e) {
          log.error(e);
        }
      }
      await this.email.reportComments(
        username,
        this.commentData.userEmail,
        this.titlePost,
        this.postUrl,
        this.commentData.comment,
        this.dataForm.value.comments
      );
      
      this.modalCtrl.dismiss();
      alertSuccess.present();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }
}
