import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, DocumentData, DocumentReference, QueryDocumentSnapshot, QuerySnapshot, Query, AngularFirestore, CollectionReference } from '@angular/fire/compat/firestore';
export { DocumentData, DocumentReference, Query, AngularFirestoreCollection, QueryDocumentSnapshot, QuerySnapshot, AngularFirestoreDocument, CollectionReference };
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { SpecialtiesService } from '../specialties/specialties.service';
import { AlertController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Device } from '@capacitor/device';
import { Logger } from '@app/core/logger.service';
const log = new Logger('FirebaseService');
import * as moment from 'moment';
import { Router } from '@angular/router';
export interface Message {
  message: string;
  isFile: boolean;
  url: string;
  convert: boolean;
  user?: string;
  sendMail?: boolean;
  label?: string;
}
export interface sendRequestInterface {
  initiator: string;
  requested: string;
  notifications: boolean;
}

export interface Plan {
  id: string;
  max_contacts: number | null;
  max_contacts_limit: boolean;
  max_products: number | null;
  max_products_limit: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private router: Router,
    private loader: IonLoaderService,
    private modalCtrl: ModalController,
    private specialtiesService: SpecialtiesService,
    private utilities: UtilitiesService,
    public credService: CredentialsService,
    public afs: AngularFirestore,
    private alertCtrl: AlertController,
    public fireAuth: AngularFireAuth,
    private fns: AngularFireFunctions,
    private fireStorage: AngularFireStorage
  ) { }

  async getUserData(uid: string): Promise<any> {
    try {
      const response: any = await this.afs.collection('users').doc(uid).ref.get();
      if (response.exists) {
        return { uid: response.id, ...response.data() };
      } else {
        return undefined;
      }
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async getDoctorData(uid: string): Promise<any> {
    try {
      const response = await this.afs.collection('medico-meta').doc(uid).ref.get();
      const data: any = response.data();
      if (data && data.specialty1 && data.specialty1.id && Number(data.specialty1.id) > 0) {
        const specialty = await this.specialtiesService.getSpecialty(Number(data.specialty1.id));
        data.specialty1.name = specialty.name;
      }
      if (data && data.specialty2 && data.specialty2.id && Number(data.specialty2.id) > 0) {
        const specialty = await this.specialtiesService.getSpecialty(Number(data.specialty2.id));
        data.specialty2.name = specialty.name;
      }
      return { uid: response.id, ...data };
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async getProfessionalHealthData(uid: string): Promise<any> {
    try {
      const response = await this.afs.collection('profesional-de-la-salud-meta').doc(uid).ref.get();
      const data: any = response.data();
      if (data && data.healthProfessionalType && data.healthProfessionalType.id) {
        const healthProfessional = await this.getHealthProfessional(data.healthProfessionalType.id);
        data.healthProfessionalType.name = healthProfessional.name;
      }
      return { uid: response.id, ...data };
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  public async getHealthProfessional(id: string): Promise<any> {
    try {
      const response = await this.afs.doc(`types-of-health-professionals/${id}`).ref.get();
      const data: any = response.data() || {};
      const idDoc: string = response.id || "";
      return { ...data, id: idDoc }
    } catch (error) {
      return {};
    }
  }

  async getRepData(uid: string): Promise<any> {
    try {
      const response: any = (await this.afs.collection('representante-meta').doc(uid).ref.get());
      let response2: any = {};
      let company: any = {};
      if (response && response.exists === true) {
        response2 = await (response.data().company as DocumentReference).get();
        company = { company: { ...response2.data() } };
        company.company.uid = response2.id;
      }
      return { uid: response.id, ...response.data(), ...company };
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async getUsersByIDS(ids: string[]): Promise<any[]> {
    if (ids && ids.length > 0) {
      const response = await this.afs
        .collection('users')
        .ref.where('uid', 'in', ids)
        .get();
      return response.docs.map(element => {
        const data: any = element.data();
        const id: string = element.id;
        if (data.type === 'medico') {
          data.metaData = this.getDoctorData(id);
        } else {
          data.metaData = this.getRepData(id);
        }
        return { id, ...data };
      });
    }
    return [];
  }

  public async createChat(
    userId: string,
    component: any,
    sendHere?: boolean,
    messages?: Message[],
    placeholder?: string,
    force?: boolean
  ): Promise<void> {
    const ids: string[] = [
      String(this.credService.credentials.uid) + String(userId),
      String(userId) + String(this.credService.credentials.uid)
    ];
    log.debug(force);
    const response = await this.ifChatExists(ids);
    if (response.empty === false) {
      if (sendHere && sendHere == true) {
        messages.forEach(element => {
          this.sendChatMessage(
            response.docs[0].id,
            element.message,
            element.convert,
            element.isFile,
            element.url,
            element.user,
            element.sendMail,
            element.label
          );
        });
      } else {
        this.goChat(response.docs[0].id, component, undefined, placeholder);
      }
    } else {
      const viewers: any = {};
      viewers[this.credService.credentials.uid] = {
        seen: true,
        news: 0
      };
      viewers[userId] = {
        seen: false,
        news: 0
      };
      const dataSimple1 = await this.getUserData(this.credService.credentials.uid);
      const dataSimple2 = await this.getUserData(userId);
      const member1 = dataSimple1;
      const member2 = dataSimple2;
      const currentDate = moment().toDate();
      const data: any = {
        identifier: ids[0],
        date: currentDate,
        last_message_date: currentDate,
        last_message_user_id: String(this.credService.credentials.uid),
        last_message: {
          message: '',
          date: currentDate,
          user: userId,
          viewers
        },
        initialized: false,
        participants: [String(this.credService.credentials.uid), String(userId)],
        members: [member1, member2]
      };
      const newChat = await this.createNewChat(data);
      if (sendHere && sendHere == true) {
        messages.forEach(element => {
          this.sendChatMessage(
            newChat.id,
            element.message,
            element.convert,
            element.isFile,
            element.url,
            element.user,
            element.sendMail,
            element.label
          );
        });
      } else {
        this.goChat(newChat.id, component, undefined, placeholder);
      }
    }
  }

  async ifChatExists(ids: string[]) {
    return await this.afs
      .collection('chats')
      .ref.where('identifier', 'in', ids)
      .get();
  }

  private async userPlan(uid: string): Promise<any> {
    try {
      const USER: any = (await this.afs.doc(`users/${uid}`).ref.get()).data();
      let plan: any;
      if (USER && USER.type === 'representante-medico') {
        if (USER && USER.plan) {
          plan = (await this.afs.doc(`plans/${USER.plan}`).ref.get()).data();
        } else {
          plan = (
            await this.afs
              .collection('plans')
              .ref.where('default', '==', true)
              .get()
          ).docs.map(e => e.data())[0];
        }
      }
      return plan;
    } catch (e) {
      log.error(e);
    }
    return;
  }

  private async numberOfChats(uid: string, limit?: number): Promise<any> {
    try {
      let query: Query = this.afs
        .collection('chats')
        .ref.where('initialized', '==', true)
        .where('participants', 'array-contains', uid)
        .orderBy('last_message.date', 'desc');
      if (limit) {
        query = query.limit(100);
      }
      const RESP = await query.get();
      return RESP.size;
    } catch (e) {
      log.error(e);
    }
    return 0;
  }

  async alertCanNotSendChat(toOtherUser?: boolean) {
    const alert = await this.alertCtrl.create({
      header: toOtherUser === true ? '' : 'Límite de plan alcanzado',
      message:
        toOtherUser === true
          ? 'No es posible entrar en contacto con éste representante.'
          : 'Ha alcanzado el límite de mensajes de chat.',
      buttons: [
        {
          text: 'De acuerdo',
          role: 'cancel'
        }
      ]
    });
    alert.present();
  }

  public async sendChatMessage(
    chatId: string,
    message: string,
    convert?: boolean,
    isFile?: boolean,
    url?: string,
    user?: string,
    sendMail?: boolean,
    label?: string
  ): Promise<any> {
    if (message && message !== '') {
      try {
        if (convert === true) {
          message = this.utilities.urlify(message, true);
        }
        const chatData: any = (
          await this.afs
            .collection('chats')
            .doc(chatId)
            .ref.get()
        ).data();
        const participants: string[] = Array.from(chatData.members).map((item: any) => String(item.uid));
        const viewers: any =
          chatData && chatData.last_message && chatData.last_message.viewers ? chatData.last_message.viewers : {};
        const newViewers: any = {};
        const ArrayViewers: string[] = Object.keys(viewers);
        let otherUser: string;
        ArrayViewers.forEach(item => {
          let data = viewers[item];
          if (String(item) === String(user ? user : this.credService.credentials.uid)) {
            data = {
              news: 0,
              seen: true
            };
          } else {
            const news: number = Number(data.news) + 1;
            data = {
              news,
              seen: false
            };
            otherUser = item;
          }
          newViewers[item] = data;
        });
        const CURRENT_DATE = moment().toDate();
        const last_message: any = {
          date: CURRENT_DATE,
          message,
          user: String(user ? user : this.credService.credentials.uid),
          receiver: otherUser,
          viewers: newViewers,
          sendMail: sendMail ? sendMail : false
        };
        // Check send Email
        const data = await this.afs
          .collection('chats')
          .doc(chatId)
          .ref.get();
        const lastMessage = data.get('last_message');
        if (lastMessage && lastMessage.sendMail === true && lastMessage.user && !user) {
          const dataUser = await this.getUserData(lastMessage.user);
          // send mail handler
          this.productInfo(
            dataUser.email,
            `${dataUser && dataUser.name ? dataUser.name : ''} ${dataUser && dataUser.lastName1 ? dataUser.lastName1 : ''
            } ${dataUser && dataUser.lastName2 ? dataUser.lastName2 : ''}`,
            chatId
          );
        }
        // !=
        this.afs
          .collection('chats')
          .doc(chatId)
          .update({
            initialized: true,
            last_message_date: CURRENT_DATE,
            last_message_user_id: String(user ? user : this.credService.credentials.uid),
            last_message,
            participants
          });
        let dataMessage: any = {
          date: moment().toDate(),
          message,
          isFile: isFile ? isFile : false,
          url: url ? url : '',
          sender: String(user ? user : this.credService.credentials.uid)
        };
        if (label !== undefined && label !== null && label !== '') {
          let myParticipants = participants;
          const index: number = myParticipants.indexOf(String(user ? user : this.credService.credentials.uid));
          const userID = myParticipants[0];
          if (index > -1) {
            myParticipants.splice(index, 1);
          }
          let docData: any = {
            chatID: chatId,
            label: label,
            sender: String(user ? user : this.credService.credentials.uid),
            to: userID,
            viewed: false,
            date: moment().toDate()
          };
          const resp = await this.afs.collection('chat-metrics').add(docData);
          dataMessage.chat_metric_doc_id = resp.id;
          dataMessage.metric_label = label;
          this.logEvent('chat_campaign_message_send', {
            chat_id: chatId,
            label: label,
            to: userID,
            sender: String(user ? user : this.credService.credentials.uid)
          });
        }
        await this.afs
          .collection('chats')
          .doc(chatId)
          .collection('messages')
          .add(dataMessage);
        return chatData;
      } catch (error) {
        log.error(error);
      }
    }
    return undefined;
  }

  async goChat(chatId: string, component: any, accepted: boolean = true, placeholder?: string): Promise<void> {
    await this.loader.ionLoaderPresent();
    try {
      var participantsArrayInfo: any = [];
      const userid: string = String(this.credService.credentials.uid);
      let participants: string[];
      const refDoc: any = await this.afs
        .collection('chats')
        .doc(chatId)
        .ref.get();
      participants = Array.from(refDoc.data().participants);
      const index: number = participants.indexOf(userid);
      if (!(index > -1)) {
        participants.push(userid);
        await this.afs
          .collection('chats')
          .doc(chatId)
          .update({ participants });
      }
      participants.forEach(async i => {
        var fullName, avatar;
        const user = await this.getUserData(i);
        fullName = user.name + ' ' + user.lastName1 + ' ' + user.lastName1;
        avatar = user.avatar && user.avatar.list && user.avatar.list.url ? user.avatar.list.url : '';
        participantsArrayInfo[i] = { nombre: fullName, avatar };
      });
      const modal = await this.modalCtrl.create({
        component,
        componentProps: { id: chatId, participants: participantsArrayInfo, accepted, placeholder: placeholder }
      });

      modal.present();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
  }

  async createNewChat(data: any) {
    return this.afs.collection('chats').add(data);
  }

  async productInfo(email: string, name: string, chatID: string) {
    try {
      let otherName: string = '';
      const urlButton: string = this.share(`/home/chat/${chatID}`);
      const data = await this.getUserData(this.credService.credentials.uid);
      otherName = `${data && data.name ? data.name : ''} ${data && data.lastName1 ? data.lastName1 : ''} ${data && data.lastName2 ? data.lastName2 : ''
        }`;
      const text = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html>

    <head>
        <title>¡Solicitud de información de productos!</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
        <style type="text/css">
            * {
                font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            }

            body {
                background-color: #f2f2f2;
            }

            table {
                font-size: 24px;
            }

            #tdtoshowinmobile {
                display: none;
            }

            @media screen and (max-device-width: 767px),
            screen and (max-width: 767px) {
                table {
                    font-size: 32px !important;
                }

                #tdtoshowinmobile {
                    display: block !important;
                }
            }
        </style>
    </head>

    <body>

        <div
            style="display: block;position: relative;margin: 0 auto;max-width: 600px;background: #ffffff;border-radius: 10px;padding: 20px;box-shadow: 1px 1px 5px #b0b0b0;margin-top: 20px;">
            <div><img style="display: block; position: relative;margin: 0 auto; width: 250px;"
                    src="https://app.conectimed.com/assets/img/logo-horizontal.png" /></div>
            <hr style="border: 1px ridge;" />
            <h2><strong>Estimado(a) ${name}:</strong></h2>
            <p style="text-align: left;">El medico <strong>${otherName}</strong> est&aacute; en l&iacute;nea en el chat para solicitarle
                informaci&oacute;n de sus productos.</p>
            <p style="text-align: center;"><span style="background-color: #236fa1; color: #ffffff;"><strong><a
                            style="background-color: #2c8ecf;color: #ffffff;display: inline-block;padding: 10px;text-decoration: none;border-radius: 5px;border: 2px solid #1773b1;"
                            title="Responda a este Chat" href="${urlButton}" rel="noopener">Responda este
                            chat</a></strong></span></p>
            <p style="text-align: center;">Para cualquier duda o comentario escribanos a <a
                    href="mailto:contacto@conectimed.com">contacto@conectimed.com</a> donde con gusto lo
                atenderemos.<br />En
                <strong>Conectimed </strong>trabajamos todos los d&iacute;as buscando la satisfacci&oacute;n de nuestros
                usuarios.<br /><br />Descarga la App
            </p>
            <table style="border-collapse: collapse; width: 100%;" border="0">
                <tbody>
                    <tr>
                        <td style="width: 48.0441%;" align="center">
                            <a href="https://apps.apple.com/mx/app/conectimed-app/id1488809696">
                                <img width="153"
                                    src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-alternate_2x.png" />
                            </a>
                        </td>
                        <td style="width: 48.1543%;" align="center">
                            <a href="https://play.google.com/store/apps/details?id=com.enacment.conectimed&hl=es_MX&gl=US">
                                <img width="153"
                                    src="https://lh3.googleusercontent.com/cjsqrWQKJQp9RFO7-hJ9AfpKzbUb_Y84vXfjlP0iRHBvladwAfXih984olktDhPnFqyZ0nu9A5jvFwOEQPXzv7hr3ce3QVsLN8kQ2Ao=s0" />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style="text-align: center;">&nbsp;</p>
        </div>

    </body>

    </html>`;

      await this.email(email, '¡Solicitud de información de productos!', text, name);
    } catch (error) {
      log.error(error);
    }
  }

  async email(
    recipient: string,
    subject: string,
    text: string,
    name: string,
    cc?: Array<string> | null,
    bcc?: Array<string> | null
  ) {
    try {
      if (!environment.production) {
        text += '<h1><b>NOTA: ESTE CORREO ES GENERADO DESDE EL AMBIENTE DE PRUEBAS.</b></h1>';
      }

      if (environment.sendEmails) {
        await this.callFunctionFB('sendMail', {
          recipient,
          subject,
          text,
          name,
          cc,
          bcc
        });
      }
    } catch (err) {
      log.error(err);
    }
  }

  async logEvent(_nameEvent: string, _paramsEvent: {}, cred?: any) {
    let userData: any = {
      user_company: cred && cred.company ? cred.company : undefined,
      user_specialty: cred && cred.speciality ? cred.speciality : undefined,
      user_state: cred && cred.state ? cred.state : undefined,
      user_type: cred && cred.type ? cred.type : undefined,
      user_id: cred && cred.uid ? cred.uid : undefined,
      user_uid: cred && cred.uid ? cred.uid : undefined
    };

    if (cred && cred.type === 'medico') {
      if (!!!userData.user_specialty) {
        try {
          const resp = await this.getDoctorData(cred.uid);
          const specialityId = resp && resp.specialty1 && resp.specialty1.id ? resp.specialty1.id : undefined;
          const resp2 = await this.specialtiesService.getSpecialty(specialityId);
          const specialty = resp2 && resp2.name ? resp2.name : undefined;
          userData.user_specialty = specialty;
        } catch (error) {
          log.error(error);
        }
      }
    }

    const data: any = {
      name: _nameEvent,
      params: { ...userData, ..._paramsEvent }
    };

    FirebaseAnalytics.logEvent(data);
  }

  public share(route: string): string {
    let parameter = `${environment.DEEPLINK_URL}${route}`;
    parameter = btoa(parameter);
    return `${environment.APP_URL}/sharing/${parameter}`;
  }

  async callFunctionFB(name: string, data: object) {
    return this.fns
      .httpsCallable(name)(data)
      .toPromise();
  }

  public async getProductData(productId: string) {
    return await new Promise(resolve => {
      const sub = this.afs
        .doc(`products/${productId}`)
        .valueChanges()
        .subscribe(value => {
          resolve(value);
          sub.unsubscribe();
        });
    });
  }

  async getUserBages(userId?: string): Promise<number> {
    let count: number = 0;
    let badges;
    if (!userId) {
      userId = this.credService.credentials.uid;
    }
    try {
      const resp = await this.afs
        .collection('users')
        .doc(userId)
        .ref.get();
      badges = resp.get('badges');
    } catch (error) {
      log.error(error);
    }
    if (badges) {
      count = Number(badges);
    }
    return count;
  }

  async updateUserBages(userId?: string, count?: number) {
    if (
      !userId &&
      this.credService &&
      this.credService.credentials &&
      this.credService.credentials.uid
    ) {
      userId = this.credService.credentials.uid;
    }
    if (!count) {
      count = 0;
    }
    if (userId) {
      this.afs
        .collection('users')
        .doc(userId)
        .update({ badges: count });
    }
  }

  async updateAvatar(uid: string, avatar: any): Promise<void> {
    try {
      return this.afs
        .collection('users')
        .doc(uid)
        .update({
          avatar
        });
    } catch (error) {
      return;
    }
  }

  async updateUser(uid: string, body: any): Promise<void> {
    try {
      await this.afs
        .collection('users')
        .doc(uid)
        .update(body);
      await this.updateUserSearch(uid);
      return;
    } catch (error) {
      return;
    }
  }

  async updateUserSearch(uid: string) {
    try {
      return await this.callFunctionFB('updateSearchArray', { user_id: uid });
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async updateDoctor(uid: string, body: any): Promise<void> {
    try {
      const resp = await this.afs
        .collection('medico-meta')
        .doc(uid)
        .ref.get();
      // const filter
      if (resp.exists === true) {
        await this.afs
          .collection('medico-meta')
          .doc(uid)
          .update(body);
      } else {
        log.debug('here');
        await this.afs
          .collection('medico-meta')
          .doc(uid)
          .set(body);
      }
      //CF start here!
      const unique = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };
      const data = await this.afs
        .collection('medico-meta')
        .doc(uid)
        .ref.get();
      const user: any = data.data();
      let filterMetaData: any[] = [];
      const metaData = {
        state1: '',
        state2: '',
        specialty1: '',
        specialty2: ''
      };
      if (user && user.address1 && user.address1.state && user.address1.state !== '') {
        filterMetaData.push(user.address1.state);
        metaData.state1 = user.address1.state;
      }
      if (user && user.address2 && user.address2.state && user.address2.state !== '') {
        filterMetaData.push(user.address2.state);
        metaData.state2 = user.address2.state;
      }
      if (user && user.specialty1) {
        const num: number = Number(user.specialty1.id);
        if (num > 0) {
          filterMetaData.push('' + num);
          metaData.specialty1 = String(num);
        }
      }
      if (user && user.specialty2) {
        const num: number = Number(user.specialty2.id);
        if (num > 0) {
          filterMetaData.push('' + num);
          metaData.specialty2 = String(num);
        }
      }
      filterMetaData.push('' + metaData.state1 + metaData.specialty1);
      filterMetaData.push('' + metaData.state1 + metaData.specialty2);
      filterMetaData.push('' + metaData.state2 + metaData.specialty1);
      filterMetaData.push('' + metaData.state2 + metaData.specialty2);
      filterMetaData = filterMetaData.filter(unique);
      await this.updateUserSearch(uid);
      return this.afs
        .collection('users')
        .doc(uid)
        .update({
          'filter-meta-data': filterMetaData
        });
    } catch (error) {
      return;
    }
  }

  async updateProfesionalSalud(uid: string, body: any): Promise<void> {
    try {
      const resp = await this.afs
        .collection('profesional-de-la-salud-meta')
        .doc(uid)
        .ref.get();
      // const filter
      if (resp.exists === true) {
        await this.afs
          .collection('profesional-de-la-salud-meta')
          .doc(uid)
          .update(body);
      } else {
        log.debug('here');
        await this.afs
          .collection('profesional-de-la-salud-meta')
          .doc(uid)
          .set(body);
      }
      //CF start here!
      const unique = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };
      const data = await this.afs
        .collection('profesional-de-la-salud-meta')
        .doc(uid)
        .ref.get();
      const user: any = data.data();
      let filterMetaData: any[] = [];
      const metaData = {
        state1: '',
        state2: '',
        healthProfessionalType: ''
      };
      if (user && user.address1 && user.address1.state && user.address1.state !== '') {
        filterMetaData.push(user.address1.state);
        metaData.state1 = user.address1.state;
      }
      if (user && user.address2 && user.address2.state && user.address2.state !== '') {
        filterMetaData.push(user.address2.state);
        metaData.state2 = user.address2.state;
      }
      if (user && user.healthProfessionalType) {
        const _id: string = String(user.healthProfessionalType.id);
        if (_id) {
          filterMetaData.push(_id);
          metaData.healthProfessionalType = String(_id);
        }
      }
      filterMetaData.push('' + metaData.state1 + metaData.healthProfessionalType);
      await this.updateUserSearch(uid);
      return this.afs
        .collection('users')
        .doc(uid)
        .update({
          'filter-meta-data': filterMetaData
        });
    } catch (error) {
      return;
    }
  }

  async createCompany(body: any): Promise<any> {
    try {
      const response = await this.afs
        .collection('companies')
        .ref.where('name', '==', body.name)
        .get();
      if (response.empty === true) {
        // Uploading the company
        const id = await this.afs.createId();
        const info = await Device.getInfo();
        if (info && (info.platform === 'ios' || info.platform === 'android')) {
          // Mobil
          return await new Promise(async resolve => {
            if (body.logo) {
              let format = 'png';
              if (body.logo.search('image/jpeg') > -1) {
                format = 'jpg';
              }
              if (body.logo.search('image/png') > -1) {
                format = 'png';
              }
              const storageRef = await this.fireStorage.ref(`companies/${id}`);
              await storageRef.putString(body.logo, 'data_url', { contentType: `image/${format}` });
              body.logo = await storageRef.getDownloadURL().toPromise();
              await this.afs.doc(`companies/${id}`).set(body);
              const resp: any = await this.afs.doc(`companies/${id}`).ref.get();
              resolve({ data: { ...resp.data(), id }, id });
            } else {
              await this.afs.doc(`companies/${id}`).set(body);
              const resp: any = await this.afs.doc(`companies/${id}`).ref.get();
              resolve({ data: { ...resp.data(), id }, id });
            }
          });
        } else {
          // Web
          return await new Promise(async resolve => {
            if (body.logo) {
              this.fireStorage.upload(`companies/${id}`, body.logo).then(async () => {
                body.logo = await this.fireStorage
                  .ref(`companies/${id}`)
                  .getDownloadURL()
                  .toPromise();
                await this.afs.doc(`companies/${id}`).set(body);
                const resp: any = await this.afs.doc(`companies/${id}`).ref.get();
                resolve({ data: { ...resp.data(), id }, id });
              });
            } else {
              await this.afs.doc(`companies/${id}`).set(body);
              const resp: any = await this.afs.doc(`companies/${id}`).ref.get();
              resolve({ data: { ...resp.data(), id }, id });
            }
          });
        }
      } else {
        return { id: response.docs[0].id };
      }
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async setRep(uid: string, body: any): Promise<void> {
    try {
      await this.afs
        .collection('representante-meta')
        .doc(uid)
        .set(body);
      await this.updateUserSearch(uid);
      return;
    } catch (error) {
      return;
    }
  }

  async getConstants() {
    return this.afs.doc('constants/constants').valueChanges();
  }

  async getRelationShipByPaticipants(user: string): Promise<string> {
    try {
      let idsRelationShips: string[] = [];
      const response1 = await this.afs
        .collection('contacts')
        .ref.where('initiator', '==', this.credService.credentials.uid)
        .where('requested', '==', user)
        .get();
      const response2 = await this.afs
        .collection('contacts')
        .ref.where('initiator', '==', user)
        .where('requested', '==', this.credService.credentials.uid)
        .get();
      idsRelationShips = idsRelationShips.concat(response1.docs.map(e => e.id));
      idsRelationShips = idsRelationShips.concat(response2.docs.map(e => e.id));
      if (idsRelationShips.length === 1) {
        return idsRelationShips[0];
      } else if (idsRelationShips.length > 1) {
        const correctDoc: string = idsRelationShips[0];
        idsRelationShips.shift();
        for (const idsRelation of idsRelationShips) {
          const ref = this.afs.collection('contacts').doc(idsRelation);
          await ref.set({ attribute: 'duplicated' });
          await ref.delete();
        }
        return correctDoc;
      }
      return '';
    } catch (error) {
      log.error(error);
    }
    return '';
  }

  async sendFriendShip(params: sendRequestInterface): Promise<DocumentData | void> {
    try {
      if (params && params.initiator && params.requested) {
        const USER: any = (await this.afs.doc(`users/${params.initiator}`).ref.get()).data();

        if (USER && USER.type === 'representante-medico') {
          const PLAN: any = await this.userPlan(params.initiator);
          const NUM_CONTACTS = await this.numberOfContacts(params.initiator);

          if (PLAN && PLAN.max_contacts_limit === true && NUM_CONTACTS >= PLAN.max_contacts) {
            this.alertCanNotSendFriendship();
            return;
          }
        } else {
          const PLAN: any = await this.userPlan(params.requested);
          const NUM_CONTACTS = await this.numberOfContacts(params.requested);

          if (PLAN && PLAN.max_contacts_limit === true && NUM_CONTACTS >= PLAN.max_contacts) {
            this.alertCanNotSendFriendship(true);
            return;
          }
        }
      }

      const response1 = await this.afs
        .collection('contacts')
        .ref.where('initiator', '==', params.initiator)
        .where('requested', '==', params.requested)
        .get();
      const response2 = await this.afs
        .collection('contacts')
        .ref.where('initiator', '==', params.requested)
        .where('requested', '==', params.initiator)
        .get();
      if (response1.empty === true && response2.empty === true) {
        const data = await this.afs.collection('contacts').add({
          dateSent: moment().toDate(),
          initiator: params.initiator,
          requested: params.requested,
          status: 'pending',
          notifications: params.notifications,
          participants: [params.initiator, params.requested]
        });
        return data;
      } else if (response1.empty === false && response2.empty === true) {
        const doc = response1.docs[0];
        await doc.ref.update({ status: 'pending' });
        return await doc.ref.get();
      } else if (response1.empty === true && response2.empty === false) {
        const doc = response2.docs[0];
        await doc.ref.update({ status: 'pending' });
        return await doc.ref.get();
      } else {
        return;
      }
    } catch (e) {
      log.error(e);
      return;
    }
  }

  private async numberOfContacts(uid: string): Promise<any> {
    try {
      const RESP = await this.afs
        .collection(`friends/${uid}/friends`)
        .ref.where('status', '==', 'active')
        .get();
      return RESP.size;
    } catch (e) {
      log.error(e);
    }
    return 0;
  }

  async alertCanNotSendFriendship(toOtherUser?: boolean) {
    const alert = await this.alertCtrl.create({
      header: toOtherUser === true ? '' : 'Límite de plan alcanzado',
      message:
        toOtherUser === true
          ? 'No es posible enviar una solicitud de contacto a éste representante.'
          : 'Ha alcanzado el límite de contactos.',
      buttons: [
        {
          text: 'De acuerdo',
          role: 'cancel'
        }
      ]
    });
    alert.present();
  }

  async acceptFriendships(id: string) {
    try {
      const RESP: any = (
        await this.afs
          .collection('contacts')
          .doc(id)
          .ref.get()
      ).data();

      const PARTICIPANTS: string[] = Array.from(RESP.participants);

      let other: string = PARTICIPANTS[0];
      if (other === this.credService.credentials.uid) {
        other = PARTICIPANTS[1];
      }

      if (this.credService.credentials.type === 'representante-medico') {
        const PLAN: any = await this.userPlan(this.credService.credentials.uid);
        const NUM_CONTACTS = await this.numberOfContacts(this.credService.credentials.uid);

        if (PLAN && PLAN.max_contacts_limit === true) {
          if (NUM_CONTACTS >= PLAN.max_contacts) {
            this.alertCanNotAceptFriendship();
            return { success: true, code: '002' };
          }
        }
      } else {
        const PLAN: any = await this.userPlan(other);
        const NUM_CONTACTS = await this.numberOfContacts(other);

        if (PLAN && PLAN.max_contacts_limit === true) {
          if (NUM_CONTACTS >= PLAN.max_contacts) {
            this.alertCanNotAceptFriendship(true);
            return { success: true, code: '002' };
          }
        }
      }

      await this.afs
        .collection('contacts')
        .doc(id)
        .update({
          status: 'accepted',
          dateAccepted: moment().toDate()
        });
      return { success: true, code: '001' };
    } catch (error) {
      return { success: true, code: '003' };
    }
  }

  async alertCanNotAceptFriendship(toOtherUser?: boolean) {
    const alert = await this.alertCtrl.create({
      header: toOtherUser === true ? '' : 'Límite de plan alcanzado',
      message:
        toOtherUser === true
          ? 'No es posible aceptar la solicitud de contacto de éste representante.'
          : 'Ha alcanzado el límite de contactos.',
      buttons: [
        {
          text: 'De acuerdo',
          role: 'cancel'
        }
      ]
    });
    alert.present();
  }


  async updateEvent(eventId: string, address: string) {
    return await this.afs
      .collection('events')
      .doc(eventId)
      .update({
        location: address,
        status_event: 2
      });
  }

  async deleteSingle(id: string, collection: string) {
    return await this.afs
      .collection(collection)
      .doc(id)
      .delete();
  }

  async createEvent(event: any) {
    return await this.afs.collection('events').add(event);
  }

  // <confirmation alerts>
  async confirmCancel(relation: string) {
    const alert = await this.alertCtrl.create({
      header: 'Cancelar solicitud',
      message: '¿Estás seguro de cancelar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.cancelRequest(relation);
          }
        }
      ]
    });
    alert.present();
  }

  async cancelRequest(id: string) {
    try {
      const resp: DocumentData = (await this.afs.doc('constants/constants').ref.get()).data();
      const payments: boolean = resp && resp.payments ? Boolean(resp.payments) : false;
      if (!payments === true) {
        const response = await this.deleteFriendShip(id);
        if (response && response.success && response.success === true) {
          this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      } else {
        if (this.credService.credentials.type === 'representante-medico' && (this.credService && this.credService.credentials && this.credService.credentials.defaultUser && this.credService.credentials.defaultUser === true)) {
          const response = await this.deleteFriendShip(id);
          if (response && response.success && response.success === true) {
            this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
          }
        } else if (this.credService.credentials.type === 'representante-medico' && (this.credService && this.credService.credentials && this.credService.credentials.defaultUser && this.credService.credentials.defaultUser === true)) {
          const pointsObject = {
            points: 232,
            note: 'Reembolso por cancelar solitud conexión',
            user_id: this.credService.credentials.uid
          };
          const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
          if (sendPoints && sendPoints.success && sendPoints.success === true) {
            const response = await this.deleteFriendShip(id);
            if (response && response.success && response.success === true) {
              this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
            } else {
              this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
            }
          } else {
            this.utilities.toast('Error al reembolsar saldo a la cuenta.', 'Reembolso por cancelar solitud conexión');
          }
        } else {
          const pointsObject = {
            points: -50,
            note: 'Descuento por cancelar solitud conexión',
            user_id: this.credService.credentials.uid
          };
          const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
          if (sendPoints && sendPoints.success && sendPoints.success === true) {
            const response = await this.deleteFriendShip(id);
            if (response && response.success && response.success === true) {
              this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
            } else {
              this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
            }
          } else {
            this.utilities.toast('Error al descontar puntos de la cuenta.', 'Descuento por cancelar solitud conexión');
          }
        }
      }
      return true;
    } catch (error) {
      log.error(error);
      return false;
    }
  }

  async deleteFriendShip(id: string, status: 'inactive' | 'rejected' = 'inactive') {
    await this.afs
      .collection('contacts')
      .doc(id)
      .update({ status });
    return { success: true };
  }

  async confirmReject(relation: string, userId: string) {
    const alert = await this.alertCtrl.create({
      header: 'Rechazar solicitud',
      message: '¿Estás seguro de rechazar esta solicitud?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.rejectRequest(relation, userId);
          }
        }
      ]
    });
    alert.present();
  }

  async rejectRequest(relation: string, userId: string) {
    try {
      const resp: DocumentData = (await this.afs.doc('constants/constants').ref.get()).data();
      const payments: boolean = resp && resp.payments ? Boolean(resp.payments) : false;
      if (!payments === true) {
        const response = await this.deleteFriendShip(relation, 'rejected');
        if (response && response.success && response.success === true) {
          this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      } else {
        if (this.credService.credentials.type === 'representante-medico' && (this.credService && this.credService.credentials && this.credService.credentials.defaultUser && this.credService.credentials.defaultUser === true)) {
          const response = await this.deleteFriendShip(relation, 'rejected');
          if (response && response.success && response.success === true) {
            this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
          } else {
            this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
          }
        } else if (this.credService.credentials.type === 'representante-medico' && (this.credService && this.credService.credentials && this.credService.credentials.defaultUser && this.credService.credentials.defaultUser === true)) {
          const pointsObject = {
            points: -50,
            note: 'Descuento por cancelación de solitud conexión',
            user_id: userId
          };
          const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
          if (sendPoints && sendPoints.success && sendPoints.success === true) {
            const response = await this.deleteFriendShip(relation, 'rejected');
            if (response && response.success && response.success === true) {
              this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
            } else {
              this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
            }
          } else {
            this.utilities.toast(
              'Error al reembolsar saldo a la cuenta.',
              'Descuento por cancelación de solitud conexión'
            );
          }
        } else {
          const pointsObject = {
            points: 232,
            note: 'Reembolso por cancelación de solitud conexión',
            user_id: userId
          };
          const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
          if (sendPoints && sendPoints.success && sendPoints.success === true) {
            const response = await this.deleteFriendShip(relation, 'rejected');
            if (response && response.success && response.success === true) {
              this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
            } else {
              this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
            }
          } else {
            this.utilities.toast(
              'Error al descontar puntos de la cuenta.',
              'Reembolso por cancelación de solitud conexión'
            );
          }
        }
      }
      return;
    } catch (error) {
      log.error(error);
      log.error(error);
    }
  }

  async checkUserPoints(userid: string, notifications: boolean) {
    try {
      const resp: DocumentData = (await this.afs.doc('constants/constants').ref.get()).data();
      const payments: boolean = resp && resp.payments ? Boolean(resp.payments) : false;
      if (!payments === true) {
        await this.sendRequest(userid, notifications);
      } else {
        if (this.credService.credentials.type === 'representante-medico' && (this.credService && this.credService.credentials && this.credService.credentials.defaultUser && this.credService.credentials.defaultUser === true)) {
          await this.sendRequest(userid, notifications);
        } else if (this.credService.credentials.type === 'representante-medico' && (this.credService && this.credService.credentials && this.credService.credentials.defaultUser && this.credService.credentials.defaultUser === true)) {
          const user = await this.getUserData(this.credService.credentials.uid);
          const points: number = user.points ? user.points : 0;
          if (points < 232) {
            this.confirmGoPayments();
          } else {
            const pointsObject = {
              points: -232,
              note: 'Cargo por conexión',
              user_id: this.credService.credentials.uid
            };
            const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
            if (sendPoints && sendPoints.success && sendPoints.success === true) {
              await this.sendRequest(userid, notifications);
            } else {
              this.utilities.toast('Error al descontar saldo de la cuenta.', 'Cargo por conexión');
            }
          }
        } else {
          const otherUser = await this.getUserData(userid);
          if (otherUser.type !== 'medico') {
            const pointsObject = {
              points: 50,
              note: 'Abono por conexión',
              user_id: this.credService.credentials.uid
            };
            const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
            if (sendPoints && sendPoints.success && sendPoints.success === true) {
              await this.sendRequest(userid, notifications);
            } else {
              this.utilities.toast('Error al abonar puntos a la cuenta.', 'Cargo por conexión');
            }
          } else {
            await this.sendRequest(userid, notifications);
          }
        }
      }
      return;
    } catch (error) {
      log.error(error);
    }
  }

  async sendRequest(userid: string, notifications: boolean) {
    const response: any = await this.sendFriendShip({
      initiator: this.credService.credentials.uid,
      requested: userid,
      notifications: notifications
    });
    if (response.id) {
      this.utilities.toast('Solicitud enviada correctamente.', 'Correcto');
    } else {
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
  }

  async confirmGoPayments() {
    const alert = await this.alertCtrl.create({
      header: 'Saldo insuficiente',
      message: '¿Ir a agregar saldo?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Ir',
          handler: () => {
            this.router.navigateByUrl('/invoice/cards');
          }
        }
      ]
    });
    alert.present();
  }

  public async getRelations(userData: boolean = false) {
    const uid = this.credService.credentials.uid;
    const snap = await this.afs
      .collection(`contacts`)
      .ref.where('participants', 'array-contains', uid)
      .where('status', 'in', ['accepted', 'pending'])
      .get();

    let pendings: any[] = [];
    let accepted: any[] = [];
    for (const doc of snap.docs) {
      let contactUid: string; //uid
      if ((doc.data() as any).initiator === uid) {
        contactUid = (doc.data() as any).requested;
      } else {
        contactUid = (doc.data() as any).initiator;
      }

      let contact;
      if (userData) {
        contact = await this.getUserData(contact);
      }

      const relation = {
        contact,
        contactUid,
        id: doc.id,
        status: (doc.data() as any).status
      };

      if ((doc.data() as any).status === 'pending') {
        pendings.push(relation);
      } else {
        accepted.push(relation);
      }
    }
    return { pendings, accepted };
  }

  async deleteChat(chatId: string) {
    await this.loader.ionLoaderPresent();
    try {
      const userid: string = String(this.credService.credentials.uid);
      let participants: string[];
      const refDoc = await this.afs
        .collection('chats')
        .doc(chatId)
        .ref.get();
      participants = Array.from((refDoc.data() as any).participants);
      const index: number = participants.indexOf(userid);
      if (index > -1) {
        participants.splice(index, 1);
        if (participants.length > 0) {
          await this.afs
            .collection('chats')
            .doc(chatId)
            .update({ participants });
        } else {
          await this.afs
            .collection('chats')
            .doc(chatId)
            .delete();
        }
      }

    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
  }

  async myPlan(uid: string): Promise<Plan | undefined> {
    try {
      const RESP = await this.afs
        .collection('plans')
        .ref.where('default', '==', true)
        .get();

      const PLANS: any[] = RESP.docs.map((element: any) => {
        return { id: element.id, ...element.data() };
      });

      const PLAN_ID = (await this.afs.doc(`users/${uid}`).ref.get()).get('plan');

      return PLAN_ID
        ? { id: PLAN_ID, ...(await this.afs.doc(`plans/${PLAN_ID}`).ref.get()).data() as any }
        : PLANS && PLANS[0]
          ? PLANS[0]
          : undefined;
    } catch (e) {
      log.error(e);
      return undefined;
    }
  }


  getProductSubcription(idProduct: string): Observable<any> {
    return this.afs
      .collection('products')
      .doc(idProduct)
      .snapshotChanges();
  }

  async getFullCompanyById(id: string) {
    try {
      const snaps: any = await this.afs.doc(`companies/${id}`).ref.get();
      if (snaps.exists) {
        const data = { ...snaps.data(), id: snaps.id };
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return { error: error };
    }
  }

  async isMyFriend(myuid: string, frienduid: string) {
    return this.afs
      .collection('friends')
      .ref.doc(myuid)
      .collection('friends')
      .doc(frienduid)
      .get();
  }

  async getRelation(id: string) {
    return this.afs
      .collection('contacts')
      .ref.doc(id)
      .get();
  }

  async getConstant(constant: 'openConections' | 'pageVersion' | 'payments') {
    return await new Promise((resolve, reject) => {
      const sub = this.afs
        .doc('constants/constants')
        .valueChanges()
        .subscribe(
          (data: any) => {
            if (data[constant] || data[constant] === true || data[constant] === false) {
              resolve(data[constant]);
            } else {
              if (constant === 'openConections') {
                resolve(false);
              } else if (constant === 'payments') {
                resolve(true);
              } else if (constant === 'pageVersion') {
                resolve('0.0.0');
              }
            }
            sub.unsubscribe();
          },
          error => {
            reject(error);
          }
        );
    });
  }

  async getProduct(idProduct: string): Promise<any> {
    try {
      return await this.afs
        .collection('products')
        .ref.doc(idProduct)
        .get();
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  public async relatedLikeFriend(uid: string): Promise<number> {
    try {
      const proof1 = await this.afs
        .collection('contacts')
        .ref.where('initiator', '==', this.credService.credentials.uid)
        .where('requested', '==', uid)
        .get();
      const proof2 = await this.afs
        .collection('contacts')
        .ref.where('initiator', '==', uid)
        .where('requested', '==', this.credService.credentials.uid)
        .get();
      if (!proof1.empty === true) {
        const data: any[] = proof1.docs.map(e => e.get('status'));
        const index: number = data.indexOf('accepted');
        if (index > -1) {
          return 1;
        } else {
          return 2;
        }
      } else if (!proof2.empty === true) {
        const data: any[] = proof2.docs.map(e => e.get('status'));
        const index: number = data.indexOf('accepted');
        if (index > -1) {
          return 1;
        } else {
          return 2;
        }
      }
      return 0;
    } catch (error) {
      return undefined;
    }
  }

  async createUser(email: string, password: string, type: string): Promise<firebase.default.auth.UserCredential | any> {
    var result;
    try {
      const response = await this.fireAuth.createUserWithEmailAndPassword(email, password);
      result = response;
      if (response && response.user && response.user.uid) {
        await this.afs
          .collection('users')
          .ref.doc(response.user.uid)
          .set({
            uid: response.user.uid,
            uuid: response.user.uid,
            avatar: {},
            createdAt: moment().toDate(),
            email,
            lastName1: '',
            lastName2: '',
            mobile: '',
            name: '',
            phone: '',
            search: [],
            status: 'new',
            type,
            updatedAt: moment().toDate(),
            newConditionsOfUseAccepted: true
          });
      }
    } catch (error) {
      result = error;
    }
    return result;
  }

  async getOutstandings() {
    let products = [];
    const snapshots = await this.afs
      .collection('advertising')
      .ref.orderBy('order', 'asc')
      .get();
    let docs = snapshots.docs;
    docs = docs.filter(e => {
      if (
        e.get('productId') &&
        e.get('productId') !== '' &&
        e.get('productId') !== undefined &&
        e.get('productId') !== null
      ) {
        return e;
      } else {
        return false;
      }
    });
    for (const doc of docs) {
      let product: any = {
        id: doc.get('productId'),
        destacado: true,
        representant: await this.getUserData(doc.get('repreId')),
        ...(await this.afs.doc(`products/${doc.get('productId')}`).ref.get()).data() as any
      };
      product.company = (await product.company.get()).data();
      products.push({ ...product });
    }
    return products;
  }

  async getProductsByCompanyId(id: string, search: string = null) {
    if (search) {
      search = this.utilities.stringSearch(search);
    }
    const companyRef = this.afs.doc(`companies/${id}`).ref;
    const companySnap = await companyRef.get();
    if (!companySnap.exists) {
      return null;
    } else {
      let productsSnaps: any;
      if (search) {
        productsSnaps = await this.afs
          .collection('products')
          .ref.where('status', '==', 'active')
          .where('company', '==', companyRef)
          .where('search', 'array-contains', search)
          .orderBy('nameStr', 'asc')
          .get();
      } else {
        productsSnaps = await this.afs
          .collection('products')
          .ref.where('status', '==', 'active')
          .where('company', '==', companyRef)
          .orderBy('nameStr', 'asc')
          .get();
      }
      const products = [];
      for (const doc of productsSnaps.docs) {
        let data: any = doc.data();
        data.kompany = data.company
          .get()
          .then((data: any) => {
            if (data && data.exists && data.exists === true) {
              return data && data.get('name') ? String(data.get('name')) : undefined;
            } else {
              return false;
            }
          })
          .catch(() => {
            return;
          });
        data.rep = this.getUserData(data.uid);
        products.push({ ...data, id: doc.id });
      }
      return products;
    }
  }

  async getUsersFromCampanyId(id: string) {
    const snap = await this.afs
      .collection('users')
      .ref.where('status', '==', 'active')
      .where('filter-meta-data', 'array-contains', id)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get();
    if (snap.empty) {
      return null;
    } else {
      const users = [];
      for (const doc of snap.docs) {
        users.push({ ...doc.data() as any, uid: doc.id });
      }
      return users;
    }
  }

  async getMemberHistory(uid: string): Promise<any> {
    return await this.afs
      .collection('transactions')
      .ref.where('uuid', '==', uid)
      .orderBy('date', 'desc')
      .get();
  }

  async updateRep(uid: string, body: any): Promise<void> {
    try {
      return this.afs
        .collection('representante-meta')
        .doc(uid)
        .update(body);
    } catch (error) {
      return;
    }
  }

  async searchZip(value: string) {
    const resp = await this.afs
      .collection('zipcodes')
      .doc(value)
      .ref.get();
    if (resp.exists === true) {
      return await this.afs
        .collection('zipcodes')
        .doc(value)
        .collection('colonies')
        .ref.get();
    } else {
      return false;
    }
  }

  public async beforeLike(likes: any, id: any) {
    likes.count = likes.count > 0 ? likes.count : 0;
    this.like(id, !(likes && likes.me && likes.me.like === true ? true : false));
    if (likes && likes.me && likes.me.like && likes.me.like === true) {
      likes.count = Number(likes.count) - 1;
      likes.me = { like: false };
    } else {
      likes.count = Number(likes.count) + 1;
      likes.me = { like: true };
    }
  }

  public async like(id: any, like: boolean) {
    try {
      const data = await this.afs
        .collection('likes')
        .doc(String(id))
        .ref.get();
      if (data.exists === true) {
        let count: number = 0;
        const likes: any = data.data();
        count = likes && likes.count && likes.count > 0 ? likes.count : 0;
        if (like === true) {
          count = count + 1;
        } else {
          count = count - 1;
        }
        await this.afs
          .collection('likes')
          .doc(String(id))
          .update({
            count: count,
            updatedDate: moment().toDate()
          });
        const data2 = await this.afs
          .collection('likes')
          .doc(String(id))
          .collection('users')
          .doc(this.credService.credentials.uid)
          .ref.get();
        if (data2.exists === true) {
          await this.afs
            .collection('likes')
            .doc(String(id))
            .collection('users')
            .doc(this.credService.credentials.uid)
            .update({
              like: like,
              updatedDate: moment().toDate()
            });
        } else {
          await this.afs
            .collection('likes')
            .doc(String(id))
            .collection('users')
            .doc(this.credService.credentials.uid)
            .set({
              like: like,
              createdDate: moment().toDate(),
              updatedDate: moment().toDate()
            });
        }
      } else {
        await this.afs
          .collection('likes')
          .doc(String(id))
          .set({
            type: 'post',
            count: 1,
            createdDate: moment().toDate(),
            updatedDate: moment().toDate()
          });
        await this.afs
          .collection('likes')
          .doc(String(id))
          .collection('users')
          .doc(this.credService.credentials.uid)
          .set({
            like: true,
            createdDate: moment().toDate(),
            updatedDate: moment().toDate()
          });
      }
    } catch (error) {
      log.error(error);
    }
  }

  async sendMobileVerification(sms: { number: string; uid: string }) {
    try {
      const code: string = await this.code({ type: 'sms', uid: sms.uid, number: sms.number });
      this.callFunctionFB('sendSms', {
        sender: 'Conectimed',
        recipient: sms.number,
        content: `Éste es su código de verificación telefónico: ${code}. Por favor ingréselo en el apartado de "Activar mi cuenta". Atentamente: Equipo Conectimed.`
      });
    } catch (error) {
      log.error(error);
    }
  }

  async code(params: { uid: string, number?: string, email?: string, type: 'email' | 'sms' }): Promise<string> {
    try {
      const ref = this.afs.collection('activation-codes').doc(params.uid).ref;
      const resp = await ref.get();
      let code: string;
      if (resp.exists === true) {
        const data: any = resp.data();
        if (data && data.code && String(data.code).length == 6) {
          code = String(data.code);
        } else {
          code = String(Math.floor(100000 + Math.random() * 900000));
        }
      } else {
        code = String(Math.floor(100000 + Math.random() * 900000));
      }
      await this.afs
        .collection('activation-codes')
        .doc(params.uid)
        .set(
          {
            uid: params.uid,
            number: params && params.number ? params.number : null,
            email: params && params.email ? params.email : null,
            date: moment().toDate(),
            code: code,
            type: params.type,
            status: 'pending'
          },
          { merge: true }
        );
      return code;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async activateUser(
    uid: string,
    sendEmail?: boolean,
    sms?: { send: boolean; number: string; uid: string }
  ): Promise<any> {
    try {
      const data: any = await this.afs
        .collection('users')
        .doc(uid)
        .ref.get();
      const type: string = data.data().type;
      let resp: Promise<void>;
      if (type === 'medico' || type === 'profesional-de-la-salud') {
        if (type === 'medico') {
          await this.updateDoctor(uid, { status: 'active' });
        } else {
          await this.updateProfesionalSalud(uid, { status: 'active' });
        }
        resp = this.afs
          .collection('users')
          .doc(uid)
          .update({
            status: 'active'
          });
      } else {
        await this.updateRep(uid, { status: 'activation-pending' });
        resp = this.afs
          .collection('users')
          .doc(uid)
          .update({
            status: 'activation-pending'
          });
      }
      if (sendEmail === true) {
        const currentUser = await this.fireAuth.currentUser;
        await currentUser.sendEmailVerification();
      }
      if (sms && sms.send === true && sms.number && sms.uid) {
        this.sendMobileVerification({ number: sms.number, uid: sms.uid });
      }
      return resp;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  public async getAdvertisingFromTags(tags: any[]) {
    if (tags.length > 10) {
      tags = tags.slice(0, 10);
    }

    let _tags: any[] = [];

    for (const tag of tags) {
      const ref = tag as DocumentReference;
      _tags.push(ref.id);
    }

    tags = [];
    tags = _tags;

    let popup = null;
    let banner = null;

    const resp = await this.afs
      .collection('advertising')
      .ref.where('tags', 'array-contains-any', tags)
      .orderBy('order', 'asc')
      .get();
    for (const doc of resp.docs) {
      const data: any = doc.data();
      if (data.type === 'popup' && !popup) {
        popup = data;
      } else if (data.type === 'banner' && !banner) {
        banner = data;
      }
    }
    return {
      popup,
      banner
    };
  }

  public async sendComment(chatId: string, message: string): Promise<any> {
    const member = this.afs.collection('users').doc(String(this.credService.credentials.uid)).ref;
    const liveMessage: any = {
      date: moment().toDate(),
      message,
      member
    };
    await this.afs
      .collection('live-chats')
      .doc(chatId)
      .collection('messages')
      .add(liveMessage);
  }

  public async like2(id: any) {
    try {
      const data: any = await this.afs
        .collection('likes')
        .doc(String(id))
        .ref.get();
      const dataMe = await this.afs
        .collection('likes')
        .doc(String(id))
        .collection('users')
        .doc(this.credService.credentials.uid)
        .ref.get();
      if (data.exists === true) {
        if (dataMe.exists === true) {
          let like: boolean = Boolean(dataMe.data().like);
          let count: number = data.data().count;
          if (like === true) {
            count--;
          } else {
            count++;
          }
          like = !like;
          await this.afs
            .collection('likes')
            .doc(String(id))
            .update({
              count: count,
              updatedDate: moment().toDate()
            });
          await this.afs
            .collection('likes')
            .doc(String(id))
            .collection('users')
            .doc(this.credService.credentials.uid)
            .update({
              like: like,
              updatedDate: moment().toDate()
            });
        } else {
          let count: number = data.data().count;
          count++;
          await this.afs
            .collection('likes')
            .doc(String(id))
            .update({
              count: count,
              updatedDate: moment().toDate()
            });
          await this.afs
            .collection('likes')
            .doc(String(id))
            .collection('users')
            .doc(this.credService.credentials.uid)
            .set({
              like: true,
              createdDate: moment().toDate(),
              updatedDate: moment().toDate()
            });
        }
      } else {
        await this.afs
          .collection('likes')
          .doc(String(id))
          .set({
            type: 'post',
            count: 1,
            createdDate: moment().toDate(),
            updatedDate: moment().toDate()
          });
        await this.afs
          .collection('likes')
          .doc(String(id))
          .collection('users')
          .doc(this.credService.credentials.uid)
          .set({
            like: true,
            createdDate: moment().toDate(),
            updatedDate: moment().toDate()
          });
      }
    } catch (error) {
      log.error(error);
    }
  }

  getCompany(id: string) {
    try {
      const result = this.afs
        .collection('companies')
        .ref.doc(id)
        .get();
      return result;
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async validateBeforeDelete(id: string) {
    let response: any;
    try {
      if (this.credService.credentials.type === 'medico') {
        response = await this.validateAntiquityFriendship(id);
        if (response) {
          this.deleteFirendShip(id);
        } else {
          this.utilities.toast(
            'Para eliminar este contacto debe esperar 30 días después de que acepto la solicitud de contacto.',
            '¡No se ha podido eliminar contacto!'
          );
        }
      } else {
        this.deleteFirendShip(id);
      }
    } catch (error) {
      log.error(error);
    }
  }

  async validateAntiquityFriendship(id: string) {
    const relation: any = (await this.getRelation(id)).data();
    let dateAfter;
    if (relation.dateAccepted) {
      dateAfter = moment(relation.dateAccepted.toDate());
    } else {
      dateAfter = moment(relation.dateSent.toDate());
    }
    const today = moment();
    dateAfter.add(30, 'days');
    return today.isAfter(dateAfter);
  }

  async deleteFirendShip(id: string) {
    try {
      let response1: any;
      response1 = await this.deleteFriendShip(id);
      if (response1.success === true) {
        this.utilities.toast('Solicitud eliminada correctamente.', 'Correcto');
      } else {
        this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
      }
    } catch (error) {
      log.error(error);
    }
  }

  async acceptRequest(id: string, userid: string) {
    try {
      const resp: DocumentData = (await this.afs.doc('constants/constants').ref.get()).data();
      const payments: boolean = resp && resp.payments ? Boolean(resp.payments) : false;
      if (!payments === true) {
        const response = await this.acceptFriendships(id);
        if (response.success === true && response.code === '001') {
          this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
        } else if (response.success === true && response.code === '002') {
          return;
        } else {
          this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
        }
      } else {
        if (this.credService.credentials.type === 'representante-medico' && (this.credService && this.credService.credentials && this.credService.credentials.defaultUser && this.credService.credentials.defaultUser === true)) {
          const response = await this.acceptFriendships(id);
          if (response.success === true && response.code === '001') {
            this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
          } else if (response.success === true && response.code === '002') {
            return;
          } else {
            this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
          }
        } else if (this.credService.credentials.type === 'representante-medico' && (this.credService && this.credService.credentials && this.credService.credentials.defaultUser && this.credService.credentials.defaultUser === true)) {
          const user = await this.getUserData(this.credService.credentials.uid);
          const points: number = user.points ? user.points : 0;
          if (points < 232) {
            this.confirmGoPayments();
          } else {
            const pointsObject = {
              points: -232,
              note: 'Cargo por conexión',
              user_id: this.credService.credentials.uid
            };
            const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
            if (sendPoints && sendPoints.success && sendPoints.success === true) {
              const response = await this.acceptFriendships(id);
              if (response.success === true && response.code === '001') {
                this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
              } else if (response.success === true && response.code === '002') {
                return;
              } else {
                this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
              }
            } else {
              this.utilities.toast('Error al descontar saldo de la cuenta.', 'Cargo por conexión');
            }
          }
        } else {
          const otherUser = await this.getUserData(userid);
          if (otherUser.type !== 'medico') {
            const pointsObject = {
              points: 50,
              note: 'Abono por conexión',
              user_id: this.credService.credentials.uid
            };
            const sendPoints = await this.callFunctionFB('setPoints', pointsObject);
            if (sendPoints && sendPoints.success && sendPoints.success === true) {
              const response = await this.acceptFriendships(id);
              if (response.success === true && response.code === '001') {
                this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
              } else if (response.success === true && response.code === '002') {
                return;
              } else {
                this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
              }
            } else {
              this.utilities.toast('Error al abonar puntos a la cuenta.', 'Cargo por conexión');
            }
          } else {
            const response = await this.acceptFriendships(id);
            if (response.success === true && response.code === '001') {
              this.utilities.toast('Solicitud aceptada correctamente.', 'Correcto');
            } else if (response.success === true && response.code === '002') {
              return;
            } else {
              this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
            }
          }
        }
      }
      return;
    } catch (error) {
      log.error(error);
    }
  }
}
