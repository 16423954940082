<ion-content [scrollEvents]="true">
  <ion-grid [fixed]="false">
    <ion-col size="12">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Información del dispositivo y versión de la aplicación</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-item no-lines>Versión de la aplicación <span slot="end">{{ versionCode }}</span></ion-item>
          <ion-item no-lines>{{ _device === 'web' ? 'Navegador' : 'Sistema' }}
            <span slot="end">{{ devicePlatform }} {{ deviceVersion }} </span>
          </ion-item>
          <ion-item no-lines>{{ _device === 'web' ? 'Sistema' : 'Dispositivo' }} <span slot="end">{{ deviceModel
              }}</span></ion-item>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-card-header>
            <ion-card-title>Permisos de la aplicación</ion-card-title>
          </ion-card-header>
          <ion-list>
            <ion-item no-lines *ngFor="let x of permissions">
              <ion-toggle item-end [(ngModel)]="x.status"
                (ionChange)="_device === 'web' ? requestPermissionsWeb(x.name) : requestPermissions(x.name)"
                [disabled]="x.status == true ? true : false">{{ x.usedFor }}</ion-toggle>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-grid>
</ion-content>