<ion-text style="width: 100%;" class="ion-text-wrap">
  <p>
    <strong>
      {{ (item?.lastName1 + ' ' + item?.lastName2 + ' ' + item?.name).toUpperCase() }}
    </strong>
  </p>
  <div *ngIf="item?.metaData | async | any as meta" class="ion-text-wrap">
    <div *ngIf="item.type === 'medico'">
      <small *ngIf="meta?.specialty1 as data"> {{ data?.name }} </small>
      <!-- <small *ngIf="meta?.specialty2 as data"> <ion-icon name="school-outline"></ion-icon> {{ data?.name }} </small> -->
      <br />
      <span *ngIf="meta?.address1 as address">
        <span *ngIf="address?.state && address?.city">
          <small *ngIf="address.state === 'Ciudad de México'; else otherAddress1">
            {{ address?.state + ', ' + address?.colony }}
          </small>
          <ng-template #otherAddress1>
            <small>
              {{ address?.state + ', ' + address?.city }}
            </small>
          </ng-template>
        </span>
      </span>
      <!-- <span *ngIf="meta?.address2 as address">
        &bull;
        <span *ngIf="address?.state && address?.city">
          <small *ngIf="address.state === 'Ciudad de México'; else otherAddress2">
            {{ address?.state + ', ' + address?.colony }}
          </small>
          <ng-template #otherAddress2>
            <small>
              {{ address?.state + ', ' + address?.city }}
            </small>
          </ng-template>
        </span>
      </span> -->
    </div>
    <div *ngIf="item.type === 'representante-medico'">
      <div *ngIf="meta?.company as company">
        <p>
          <strong>{{ company?.name }}</strong>
        </p>
        <p>{{ company?.businessType }}</p>
        <p></p>
      </div>
    </div>
  </div>
</ion-text>