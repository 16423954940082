<form [formGroup]="dataForm" (ngSubmit)="submit()">
  <ion-list>
    <ion-list-header>
      <ion-label color="secondary">Información personal</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-label position="stacked">Nombre (s) <ion-text color="danger">*</ion-text> </ion-label>
      <ion-input type="text" formControlName="name" autofocus="true"></ion-input>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('name').touched && dataForm.get('name').getError('required')"
    >
      <span class="ion-padding" >El nombre es requerido</span>
    </ion-text>
    <ion-item>
      <ion-label position="stacked">Apellido paterno <ion-text color="danger">*</ion-text> </ion-label>
      <ion-input type="text" formControlName="lastName1"></ion-input>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('lastName1').touched && dataForm.get('lastName1').getError('required')"
    >
      <span class="ion-padding" >El apellido paterno es requerido</span>
    </ion-text>
    <ion-item>
      <ion-label position="stacked">Apellido materno </ion-label>
      <ion-input type="text" formControlName="lastName2"></ion-input>
    </ion-item>

    <!-- Mobile Phone -->
    <div class="tel-lada-div">
      <ion-label style="font-size: 12px; margin-bottom: 2px;"
        >Teléfono móvil <ion-text color="danger">*</ion-text> </ion-label
      ><br />
      <div>
        <ion-label color="medium">+{{ phone.lada }}</ion-label>
        <input
          type="text"
          oninput="value = value.replace(/[^0-9.]/g, ''); value = value.replace(/(\..*)\./g, '$1');"
          pattern="[0-9]{10}"
          class="country_lada"
          [maxLength]="10"
          [max]="10"
        />
      </div>
    </div>

    <ion-item-divider [color]="phone.color" style="min-height: 1px;"></ion-item-divider>
    <ion-text class="item-with-error" color="danger" *ngIf="phone.error">
      <span class="ion-padding" >El teléfono móvil no es valido</span>
    </ion-text>
    <ion-list-header>
      <ion-label color="secondary">Dirección</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-progress-bar *ngIf="isLoading === true" type="indeterminate"></ion-progress-bar>
      <ion-label position="stacked">Código postal <ion-text color="danger">*</ion-text> </ion-label>
      <ion-input type="tel" formControlName="postalCode" maxlength="5" minlength="5"></ion-input>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('postalCode').touched && dataForm.get('postalCode').getError('required')"
    >
      <span class="ion-padding" >El código postal es requerido</span>
    </ion-text>
    <ion-text color="medium" class="ion-padding">
      <small> {{ address?.state }}, {{ address?.delegation }}, {{ address?.colony }} </small>
    </ion-text>
    <ion-list-header>
      <ion-label color="secondary">Información profesional</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-label>Especialidad <ion-text color="danger">*</ion-text> </ion-label>
      <ion-select placeholder="Seleciona especialidad" formControlName="specialty1" autofocus="true">
        <ion-select-option *ngFor="let item of specialties" [value]="item.id">{{ item.name }} </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('specialty1').touched && dataForm.get('specialty1').getError('required')"
    >
      <span class="ion-padding" >Especialidad es requerida</span>
    </ion-text>
    <ion-item>
      <ion-label position="stacked">Cédula profesional <ion-text color="danger">*</ion-text> </ion-label>
      <ion-input type="text" formControlName="professionalID1"></ion-input>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('professionalID1').touched && dataForm.get('professionalID1').getError('required')"
    >
      <span class="ion-padding" >Cédula profesional es requerida</span>
    </ion-text>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('professionalID1').touched && dataForm.get('professionalID1').getError('whitespace')"
    >
      <span class="ion-padding" >Cédula profesional inválida, no usar espacios en blanco</span>
    </ion-text>
  </ion-list>
  <ion-text color="danger">
    <p>* Datos obligatorios</p>
  </ion-text>
  <div class="ion-padding">
    <ion-button
      type="submit"
      color="tertiary"
      expand="block"
      [disabled]="
        !dataForm.valid || dataForm.value.termsAndConditions == false || !address?.colony || isLoading || phone.error
      "
    >
      {{ button }}
    </ion-button>
  </div>
</form>
