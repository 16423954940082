import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { Logger } from '@app/core/logger.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { AddLocationComponent } from '../add-location/add-location.component';
const log = new Logger('Login');

export interface PersonalData {
  name: string;
  lastName1: string;
  lastName2: string;
  cellPhone: string;
  phone: string;
}

export interface UserData {
  uid: string;
  email: string;
  type?: string
}

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @Input() user: UserData;
  @Input() target: number = 1;
  @Input() button: string = 'Siguiente';
  @Input() required: boolean = true;
  @Input() collapsed: boolean = false;
  @Input() title: string = 'Dirección';
  @Output() response = new EventEmitter<void>();
  @Output() valid = new EventEmitter<boolean>();
  @Input() submit: Subject<void>;
  public dataForm: FormGroup;
  public isLoading: boolean;
  past: string;
  colonies: string[];
  public states: any[] = [];
  intialData: any;

  constructor(
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private alertCtrl: AlertController,
    private utilities: UtilitiesService,
    private fireService: FirebaseService,

    private modalCtrl: ModalController
  ) {
    this.utilities.initEqualsObject();
  }

  async addLocation(): Promise<any> {
    const alert = await this.alertCtrl.create({
      header: 'No se encontró código postal',
      message: '¿Desea agregar la locación manualmente?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Agregar',
          handler: async () => {
            const modal = await this.modalCtrl.create({
              component: AddLocationComponent,
              componentProps: {
                zipcode: String(this.dataForm.get('postalCode').value),
                uid: this.user.uid
              }
            });
            modal.onDidDismiss().then(resp => {
              if (resp && resp.data && resp.data.location) {
                this.dataForm.get('state').setValue(resp.data.location.state);
                this.dataForm.get('delegation').setValue(resp.data.location.delegation);
                this.dataForm.get('colony').setValue(resp.data.location.colony);
                this.dataForm.get('city').setValue(resp.data.location.city);
              }
            });
            modal.present();
          }
        }
      ]
    });
    alert.present();
  }

  ngOnInit() {
    this.initForm();
    this.valid.emit(this.formValiation());
    this.formChanges();
    this.initData();
    this.submit.subscribe(() => {
      if (this.dataForm.valid) {
        this.onSubmit();
      } else {
        this.response.emit();
      }
    });
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      street: [''],
      outsideNumber: [''],
      interiorNumber: [''],
      postalCode: ['', [Validators.required]],
      colony: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      delegation: ['', Validators.required],
      hospital: [''],
      tower: ['']
    });
  }

  refillForm(data: any) {
    this.dataForm.setValue({
      street:
        this.target == 1
          ? data && data.address1 && data.address1.street
            ? data.address1.street
            : ''
          : data && data.address2 && data.address2.street
            ? data.address2.street
            : '',
      outsideNumber:
        this.target == 1
          ? data && data.address1 && data.address1.outsideNumber
            ? data.address1.outsideNumber
            : ''
          : data && data.address2 && data.address2.outsideNumber
            ? data.address2.outsideNumber
            : '',
      interiorNumber:
        this.target == 1
          ? data && data.address1 && data.address1.interiorNumber
            ? data.address1.interiorNumber
            : ''
          : data && data.address2 && data.address2.interiorNumber
            ? data.address2.interiorNumber
            : '',
      postalCode:
        this.target == 1
          ? data && data.address1 && data.address1.postalCode
            ? data.address1.postalCode
            : ''
          : data && data.address2 && data.address2.postalCode
            ? data.address2.postalCode
            : '',
      colony:
        this.target == 1
          ? data && data.address1 && data.address1.colony
            ? data.address1.colony
            : ''
          : data && data.address2 && data.address2.colony
            ? data.address2.colony
            : '',
      city:
        this.target == 1
          ? data && data.address1 && data.address1.city
            ? data.address1.city
            : ''
          : data && data.address2 && data.address2.city
            ? data.address2.city
            : '',
      state:
        this.target == 1
          ? data && data.address1 && data.address1.state
            ? data.address1.state
            : ''
          : data && data.address2 && data.address2.state
            ? data.address2.state
            : '',
      delegation:
        this.target == 1
          ? data && data.address1 && data.address1.delegation
            ? data.address1.delegation
            : ''
          : data && data.address2 && data.address2.delegation
            ? data.address2.delegation
            : '',
      hospital:
        this.target == 1
          ? data && data.address1 && data.address1.hospital
            ? data.address1.hospital
            : ''
          : data && data.address2 && data.address2.hospital
            ? data.address2.hospital
            : '',
      tower:
        this.target == 1
          ? data && data.address1 && data.address1.tower
            ? data.address1.tower
            : ''
          : data && data.address2 && data.address2.tower
            ? data.address2.tower
            : ''
    });
  }

  public async initData(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      let data: any = {};
      if (this.user && this.user.type && this.user.type === 'profesional-de-la-salud') {
        data = await this.fireService.getProfessionalHealthData(this.user.uid);
      } else {
        data = await this.fireService.getDoctorData(this.user.uid);
      }
      this.refillForm(data);
      this.postalCode();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async onSubmit(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const strState: string = this.utilities.stringSearch(this.dataForm.get('state').value);
      let address: any = {
        street: this.dataForm.get('street').value,
        outsideNumber: this.dataForm.get('outsideNumber').value,
        interiorNumber: this.dataForm.get('interiorNumber').value,
        postalCode: this.dataForm.get('postalCode').value,
        colony: this.dataForm.get('colony').value,
        city: this.dataForm.get('city').value,
        state: this.dataForm.get('state').value,
        strState: strState,
        delegation: this.dataForm.get('delegation').value,
        hospital: this.dataForm.get('hospital').value,
        tower: this.dataForm.get('tower').value
      };
      let data: any = {};
      if (this.target == 1) {
        data.address1 = address;
      } else {
        data.address2 = address;
      }
      if (this.user && this.user.type && this.user.type === 'profesional-de-la-salud') {
        data = await this.fireService.updateProfesionalSalud(this.user.uid, data);
      } else {
        data = await this.fireService.updateDoctor(this.user.uid, data);
      }
      this.response.emit();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async postalCode() {
    try {
      await this.dataForm.get('postalCode').valueChanges.subscribe(async (value: string) => {
        this.isLoading = true;
        if (this.dataForm.value.state !== null) {
          this.dataForm.get('state').reset();
        }
        if (this.dataForm.value.delegation !== null) {
          this.dataForm.get('delegation').reset();
        }
        if (this.dataForm.value.colony !== null) {
          this.dataForm.get('colony').reset();
        }
        if (this.dataForm.value.city !== null) {
          this.dataForm.get('city').reset();
        }
        if (value && value.toString().length > 4) {
          const resp = await this.fireService.afs
            .collection('zipcodes')
            .doc(value)
            .ref.get();
          if (resp.exists === true) {
            const response = await this.fireService.afs
              .collection('zipcodes')
              .doc(value)
              .collection('colonies')
              .ref.get();
            const filteredColonies: any[] = Array.from(response.docs.map(e => e.data()));
            if (filteredColonies.length == 0) {
              this.addLocation();
            }
            if (filteredColonies.length > 0 && filteredColonies.length < 2) {
              this.dataForm.get('state').setValue(filteredColonies[0].state);
              this.dataForm.get('delegation').setValue(filteredColonies[0].city);
              this.dataForm.get('colony').setValue(filteredColonies[0].colony);
              if (filteredColonies[0].state === 'Ciudad de México') {
                this.dataForm.get('city').setValue('Ciudad de México');
              } else {
                this.dataForm.get('city').setValue(filteredColonies[0].city);
              }
            }
            if (filteredColonies.length > 1) {
              const inputs: any[] = [];
              this.dataForm.get('state').setValue(filteredColonies[0].state);
              this.dataForm.get('delegation').setValue(filteredColonies[0].city);

              filteredColonies.forEach((colony, index) => {
                inputs.push({
                  value: index,
                  label: colony.colony,
                  type: 'radio'
                });
              });

              const alert = await this.alertCtrl.create({
                header: 'Seleciona una colonia',
                subHeader: `Hay ${filteredColonies.length} colonias que comparten el mismo código postal selecciona una por favor.`,
                inputs,
                buttons: [
                  {
                    text: 'Cancelar',
                    cssClass: 'ion-text-danger',
                    handler: () => {
                      this.dataForm.get('state').reset();
                      this.dataForm.get('delegation').reset();
                      this.dataForm.get('colony').reset();
                      this.dataForm.get('city').reset();
                    }
                  },
                  {
                    text: 'Aceptar',
                    handler: (data: any) => {
                      if (data !== undefined) {
                        const selectedColony = filteredColonies[data];
                        this.dataForm.get('colony').setValue(selectedColony.colony);
                        if (selectedColony.state === 'Ciudad de México') {
                          this.dataForm.get('city').setValue('Ciudad de México');
                        } else {
                          this.dataForm.get('city').setValue(selectedColony.city);
                        }
                      } else {
                        this.dataForm.get('state').reset();
                        this.dataForm.get('delegation').reset();
                        this.dataForm.get('colony').reset();
                        this.dataForm.get('city').reset();
                      }
                    }
                  }
                ]
              });
              alert.present();
            }
          } else {
            this.addLocation();
          }
        }
        this.isLoading = false;
      });
    } catch (error) {
      log.error(error);
    }
  }

  formValiation(): boolean {
    if (this.required === true) {
      return this.dataForm.valid;
    } else {
      Object.keys(this.dataForm.value).forEach(key => {
        if (this.dataForm.value[key] === null) {
          this.dataForm.get(key).setValue('');
        }
      });
      if (this.dataForm.dirty === true) {
        // const initialValue = Object['equals'](this.intialData, this.dataForm.value);
        // if (initialValue === true) {
        //   return true;
        // } else {
        //   return this.dataForm.valid;
        // }
        return false;
      } else {
        return true;
      }
    }
  }

  formChanges(): void {
    this.dataForm.valueChanges.subscribe(() => {
      this.valid.emit(this.formValiation());
    });
  }
}
