<ion-card color="light" class="animate__animated animate__fadeIn">
  <ion-toolbar [color]="item?.destacado === true ? 'tertiary' : 'secondary'">
    <h5 style="margin-left: 10px;" class="ion-text-center">{{ item?.title }}</h5>
  </ion-toolbar>
  <ion-row class="ion-align-items-center ion-justify-content-center">
    <ion-col size="auto" style="padding: 0px;" class="ion-text-center">
      <img *ngIf="item?.image" [src]="item?.image" class="img-product" />
      <img *ngIf="!item?.image" src="/assets/img/no-product.PNG" class="img-product" />
    </ion-col>
    <ion-col size="" style="padding: 0px;">
      <div *ngIf="item?.type === 'Medicamento'">
        <div class="read-content" *ngIf="item?.destacado !== true">
          <p *ngIf="item?.producto_sustancia_activa_1 || item?.producto_sustancia_activa_2">
            <b>{{
              item?.producto_sustancia_activa_1 && item?.producto_sustancia_activa_2
              ? 'Sustancias activas:'
              : 'Sustancia activa:'
              }}
            </b>
            <span *ngIf="item?.producto_sustancia_activa_1 as active">{{ active }}</span>
            <span *ngIf="item?.producto_sustancia_activa_2 as active">, {{ active }}</span>
          </p>
          <p *ngIf="item?.linea_terapeutica_1 || item?.linea_terapeutica_2">
            <b>Línea: </b>
            <span *ngIf="item?.linea_terapeutica_1">{{ item?.linea_terapeutica_1 }}</span>
            <span *ngIf="item?.linea_terapeutica_2">, {{ item?.linea_terapeutica_2 }}</span>
          </p>
          <p *ngIf="item?.company | async as resp">
            <b>Marca: </b>
            <span>{{ resp }}</span>
          </p>
          <p *ngIf="item?.rep | async | any as rep">
            <b>Representante: </b>
            <span>{{ rep?.name }} {{ rep?.lastName1 }} {{ rep?.lastName2 }}</span>
          </p>
        </div>
        <div class="read-content" *ngIf="item?.destacado === true">
          <p *ngIf="item?.producto_sustancia_activa_1 || item?.producto_sustancia_activa_2">
            <b>{{
              item?.producto_sustancia_activa_1 && item?.producto_sustancia_activa_2
              ? 'Sustancias activas:'
              : 'Sustancia activa:'
              }}
            </b>
            <span *ngIf="item?.producto_sustancia_activa_1 as active">{{ active }}</span>
            <span *ngIf="item?.producto_sustancia_activa_2 as active">, {{ active }}</span>
          </p>
          <p *ngIf="item?.company">
            <b>Marca: </b>
            <span>{{ item?.company.name }}</span>
          </p>
          <p *ngIf="item?.representant">
            <b>Representante: </b>
            <span>{{ item?.representant?.name }} {{ item?.representant?.lastName1 }}
              {{ item?.representant?.lastName2 }}</span>
          </p>
        </div>
      </div>
      <div *ngIf="item?.type === 'Dispositivo'">
        <div class="read-content">
          <p *ngIf="item?.producto_sustancia_activa_1">
            <b>Producto: </b>
            <span *ngIf="item?.producto_sustancia_activa_1 as active"> {{ active }}</span>
          </p>
          <p *ngIf="item?.producto_sustancia_activa_2">
            <b>Uso:</b>
            <span *ngIf="item?.producto_sustancia_activa_2 as active">, {{ active }}</span>
          </p>
          <p *ngIf="item?.linea_terapeutica_1 || item?.linea_terapeutica_2">
            <b>Línea: </b>
            <span *ngIf="item?.linea_terapeutica_1"> {{ item?.linea_terapeutica_1 }}</span>
            <span *ngIf="item?.linea_terapeutica_2">, {{ item?.linea_terapeutica_2 }}</span>
          </p>
          <p *ngIf="item?.company | async as resp">
            <b>Marca: </b>
            <span>{{ resp }}</span>
          </p>
          <p *ngIf="item?.rep | async | any as rep">
            <b>Representante: </b>
            <span>{{ rep?.name }} {{ rep?.lastName1 }} {{ rep?.lastName2 }}</span>
          </p>
        </div>
      </div>
      <div *ngIf="item?.type === 'Servicio'">
        <div class="read-content">
          <p *ngIf="item?.producto_sustancia_activa_1">
            <b>Descripción:</b>
            <span *ngIf="item?.producto_sustancia_activa_1 as active"> {{ active | slice: 0:30 }}....</span>
            <span *ngIf="item?.producto_sustancia_activa_2 as active"> {{ active | slice: 0:30 }}...</span>
            <span *ngIf="item?.producto_sustancia_activa_3 as active"> {{ active | slice: 0:30 }}...</span>
          </p>
          <p *ngIf="item?.company | async as resp">
            <b>Marca: </b>
            <span>{{ resp }}</span>
          </p>
          <p *ngIf="item?.rep | async | any as rep">
            <b>Representante: </b>
            <span>{{ rep?.name }} {{ rep?.lastName1 }} {{ rep?.lastName2 }}</span>
          </p>
        </div>
      </div>
    </ion-col>
  </ion-row>
  <div class="read-content ion-text-center" style="padding-bottom: 10px;">
    <ion-button (click)="sampleRequestService.request(item?.id)"
      *ngIf="item?.allowMedicalSample === true && item?.se_cuenta_con_muestra" size="small" style="min-width: 170px;">
      <ion-icon slot="start" src="/assets/icons/hospital-box.svg"></ion-icon>
      Solicite muestra
    </ion-button>
    <ion-button [routerLink]="[item?.routing?.route]" size="small" style="min-width: 170px;" (click)="analyticsClickEvent('select_content', {
      content_type: item?.marca,
      product_brand: item?.marca,
     product_brand_type: item?.type,
     section_name: 'Productos'
   })">
      <ion-icon slot="start" *ngIf="item?.routing?.icon?.src as src" [src]="src"></ion-icon>
      <ion-icon slot="start" *ngIf="item?.routing?.icon?.name as name" [name]="name"></ion-icon>
      {{ item?.type === 'Servicio' ? 'Ver servicio' : 'Ver producto' }}
    </ion-button>
  </div>
</ion-card>