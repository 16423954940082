import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { ModalController } from '@ionic/angular';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { NotificationService } from '@app/shared/services/notification/notification.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('Sample request');
import * as moment from 'moment';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';

@Component({
  selector: 'app-sample-request',
  templateUrl: './sample-request.component.html',
  styleUrls: ['./sample-request.component.scss']
})
export class SampleRequestComponent implements OnInit {
  public dataForm: FormGroup;
  isLoading = false;
  productId: string;
  userId: number;
  company: string;
  product: string;
  username: string;
  adress: any[];
  message: string;
  productOwner: any;
  userData: any;
  productOwnerId: string;

  constructor(
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private utilities: UtilitiesService,
    private modalCtrl: ModalController,
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private notificationService: NotificationService,
    private analyticsFB: AnalyticsService,
    private router: Router
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async initializeApp() {
    this.initForm();
    await this.getDetail();
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.initializeApp();
    this.isLoading = false;
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      useTo: [1, Validators.required],
      adress: [4, Validators.required],
      comments: ['']
    });
  }

  async getDetail() {
    try {
      // get product info
      const productData = (await this.fireService.getProduct(this.productId)).data();
      this.product = productData.title;
      this.productOwner = await this.fireService.getUserData(productData.uid);
      this.productOwnerId = productData.uid;
      this.userData = await this.fireService.getUserData(this.credService.credentials.uid);
      this.username = `${this.userData.name} ${this.userData.lastName1} ${this.userData.lastName2}`;
      const repData = await this.fireService.getRepData(productData.uid);
      const myData = await this.fireService.getDoctorData(this.credService.credentials.uid);
      this.adress = this.utilities.mapAdress(myData.address1, myData.address2);
      this.company = repData.company.name;
    } catch (error) {
      log.error(error);
    }
  }

  public async save(): Promise<void> {
    try {
      await this.loader.ionLoaderPresent();
      let adressObj: any;
      const useToRaw = this.dataForm.get('useTo').value;
      const adressRaw = this.dataForm.get('adress').value;
      const comments = this.dataForm.get('comments').value;
      const index: number = this.adress.map(e => e.id).indexOf(adressRaw);
      if (index > -1) {
        adressObj = this.adress[index];
      }
      const data = {
        productId: this.productId,
        product: this.product,
        company: this.company,
        userId: this.credService.credentials.uid,
        user: this.username,
        useTo: useToRaw === 1 ? 'Terapéutico' : useToRaw === 2 ? 'Personal' : 'Promocional',
        adress: adressObj && adressObj.adress ? adressObj.adress : '',
        comments: comments ? comments : ''
      };

      this.message = `    
      <h2><strong>Solicitud para:</strong></h2>
      <p>${data.user}</p>
      <p> <small>${data.company}</small> </p>
      <p><strong>Producto: </strong>${data.product}</p>   
      <hr>   
      <p><strong>Uso que le dará a la muestra:</strong></p>
      <p> ${data.useTo}</p>
      <p><strong>Dirección de envío:</strong></p>
      <p> ${data.adress}</p>
      <p><strong>Comentarios:</strong></p>
      <p> ${data.comments}</p>`;
      // await this.email.sampleRequest(this.message);
      this.analyticsClickEvent('send_request_sample', {
        content_type: data.product,
        request_to: data.user,
        use_to: useToRaw === 1 ? 'Terapéutico' : useToRaw === 2 ? 'Personal' : 'Promocional',
        section_name: 'Producto'
      });
      await this.sendMessage(this.message);
      await this.loader.ionLoaderDismiss();
      this.closeModal();
    } catch (error) {
      log.error(error);
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }

  public async sendMessage(message: string): Promise<void> {
    this.isLoading = true;
    const userId = this.productOwnerId;
    try {
      const ids: string[] = [
        String(this.credService.credentials.uid) + String(userId),
        String(userId) + String(this.credService.credentials.uid)
      ];
      const response = await this.fireService.ifChatExists(ids);
      if (!response.empty) {
        await this.fireService.sendChatMessage(response.docs[0].id, message);
        this.notificationService.sendNotification(this.productOwnerId, 'sample-request', {
          chatId: response.docs[0].id,
          productId: this.productId
        });
      } else {
        // create chat
        const viewers: any = {};
        viewers[this.credService.credentials.uid] = {
          seen: true,
          news: 0
        };
        viewers[userId] = {
          seen: false,
          news: 0
        };
        const member1 = this.userData;
        const member2 = this.productOwner;
        const currentDate = moment().toDate();
        const data: any = {
          identifier: ids[0],
          date: currentDate,
          last_message_date: currentDate,
          last_message_user_id: String(this.credService.credentials.uid),
          last_message: {
            message: '',
            date: currentDate,
            user: String(this.credService.credentials.uid),
            receiver: userId,
            viewers
          },
          initialized: false,
          participants: [String(this.credService.credentials.uid), String(userId)],
          members: [member1, member2]
        };
        const newChat = await this.fireService.createNewChat(data);
        await this.fireService.sendChatMessage(newChat.id, message, false, false);
        this.notificationService.sendNotification(this.productOwnerId, 'sample-request', {
          chatId: newChat.id,
          productId: this.productId
        });
      }
      this.utilities.toast('Solicitud de muestra enviada.', 'Correcto');
    } catch (error) {
      log.error(error);
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
    this.isLoading = false;
  }
}
