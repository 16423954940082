import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DocumentData, QuerySnapshot, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
const log = new Logger('SpecialitesComponent');

@Component({
  selector: 'app-professional-type',
  templateUrl: './professional-type.component.html',
  styleUrls: ['./professional-type.component.scss'],
})
export class ProfessionalTypeComponent implements OnInit {
  public myArray: any[] = [];
  arrayData: any[] = [];
  isLoading: boolean;
  myContacts: boolean;
  filter: string = '';
  specialties: any[];

  constructor(
    private modalCtrl: ModalController,
    private credService: CredentialsService,
    private fireService: FirebaseService,
    private specialtiesService: SpecialtiesService
  ) { }

  ngOnInit() {
    this.init();
  }

  private async init(): Promise<void> {
    this.isLoading = true;
    try {
      try {
        const resp = await this.fireService.afs.collection('types-of-health-professionals-in-use').ref.where('count', '>', 0).get();
        this.specialties = resp.docs.map(e => e.data());

      } catch (error) {
        log.error(error);
      }
      let resp: QuerySnapshot<DocumentData>;
      if (this.myContacts === true) {
        resp = await this.fireService.afs
          .collection(`friends/${this.credService.credentials.uid}/information/counters/filters/type/profesional-de-la-salud`)
          .ref.where('count', '>', 0)
          .get();
      } else {
        resp = await this.fireService.afs
          .collection(`information/counters/filters/type/profesional-de-la-salud`)
          .ref.where('count', '>', 0)
          .get();
      }
      let myArray = resp.docs.map(e => e.id);
      let filterArray: { id: string; filter: string }[] = [];
      if (this.filter && this.filter !== '') {
        for (const element of myArray) {
          filterArray.push({ id: element, filter: `${this.filter}${element}` });
        }
      }
      if (!(this.filter && this.filter !== '')) {
        this.specialties = this.specialties
          // .filter(element =>
          //   const index: number = myArray.indexOf(String(element.id));
          //   if (index > -1) {
          //     return element;
          //   }
          // })
          .map(element => {
            let data = element;
            data.filter = data.id;
            return data;
          });        
      } else {
        filterArray = filterArray.filter(element => {
          const index: number = myArray.indexOf(String(element.filter));
          if (index > -1) {
            return element;
          }
          return null;
        });
        this.specialties = this.specialties
          // .filter(element => {
          //   const index: number = filterArray.map(e => String(e.id)).indexOf(String(element.id));
          //   if (index > -1) {
          //     return element;
          //   }
          // })
          .map(element => {
            let data = element;
            const index: number = filterArray.map(e => String(e.id)).indexOf(String(element.id));
            if (index > -1) {
              data.filter = filterArray[index].filter;
            }
            return data;
          });
      }
      this.specialties = this.specialties.map(element => {
        let promise: Promise<any>;
        if (this.myContacts === true) {
          promise = this.fireService.afs
            .doc(
              `friends/${this.credService.credentials.uid}/information/counters/filters/type/profesional-de-la-salud/${element.filter}`
            )
            .ref.get()
            .then(e => e.get('count'))
            .catch(() => {
              return 0;
            });
        } else {
          promise = this.fireService.afs
            .doc(`information/counters/filters/type/profesional-de-la-salud/${element.filter}`)
            .ref.get()
            .then(e => e.get('count'))
            .catch(() => {
              return 0;
            });
        }
        return { id: element.id, label: element.name, isChecked: false, count: promise };
      });
      this.myArray = this.specialties;
      this.refillData();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }

  selectItem(item: any) {
    item.isChecked = true;
    const element = [item];
    this.modalCtrl.dismiss({ arrayData: element }, 'data');
  }

  refillData() {
    if (this.arrayData && this.arrayData[0]) {
      this.arrayData.forEach(element => {
        const index: number = this.myArray.map(e => e.id).indexOf(element.id);
        if (index > -1) {
          this.myArray[index].isChecked = element.isChecked;
        }
      });
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({ arrayData: this.arrayData }, 'close');
  }

  clearFilter() {
    this.modalCtrl.dismiss({ arrayData: [] }, 'clear');
  }
}
