import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { SpecialtiesService } from '../specialties/specialties.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import moment from 'moment';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Device } from '@capacitor/device';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('HomeComponent');

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  scrollDepthTriggered = false;

  constructor(
    private aRoute: ActivatedRoute,
    private fireService: FirebaseService,
    private specialtiesService: SpecialtiesService,
    public fireAuth: AngularFireAuth,
  ) {
    this.setScreenName(String(this.aRoute.snapshot.url[0]));
  }

  setScreenName(screenName: string) {
    FirebaseAnalytics.setScreenName({
      screenName,
    });
  }

  async initFb() {
    if ((await Device.getInfo()).platform === 'web') {
      FirebaseAnalytics.initializeFirebase(environment.FIREBASE_CONFIG);
    }
  }

  reset() {
    FirebaseAnalytics.reset();
  }

  async setUser(userIdValue: string) {
    FirebaseAnalytics.setUserId({
      userId: userIdValue,
    });
  }

  async setUserProperties(userIdValue: string, staff: boolean) {
    try {
      const data = await this.getUserData(userIdValue);
      let analytics = {
        typeUser: ' ',
        staff: ' ',
        user_registration_date: ' ',
        user_name: ' ',
        user_city: ' ',
        user_state: ' ',
        specialty: ' ',
        company: ' ',
      };
      if (staff === true) {
        analytics.staff = 'true';
      } else {
        analytics.staff = 'false';
      }
      const _fireAuth = await this.fireAuth.currentUser;
      if (_fireAuth.metadata && _fireAuth.metadata.creationTime) {
        analytics.user_registration_date = moment(_fireAuth.metadata.creationTime).format();
      }
      analytics.user_name = `${data && data.name ? data.name : ''} ${data && data.lastName1 ? data.lastName1 : ''} ${data && data.lastName2 ? data.lastName2 : ''
        }`;
      if (data && data.type === 'medico') {
        const additionalData = await this.getDoctorData(userIdValue);
        if (
          additionalData &&
          additionalData.address1 &&
          additionalData.address1.city &&
          additionalData.address1.state
        ) {
          if (additionalData.address1.city === 'Ciudad de México') {
            analytics.user_city = additionalData.address1.delegation;
          } else {
            analytics.user_city = additionalData.address1.city;
          }
          analytics.user_state = additionalData.address1.state;
        }

        if (additionalData && additionalData.specialty1 && additionalData.specialty1.name) {
          analytics.specialty = additionalData.specialty1.name;
        }
      } else {
        {
          const additionalData: any = await this.fireService.afs.collection('representante-meta').doc(userIdValue).ref.get();
          if (additionalData && additionalData.data() && additionalData.data().company) {
            const companyData: any = await additionalData.data().company.get();
            if (companyData.data().name && companyData.data().name !== '') {
              analytics.company = companyData.data().name;
            }
          }
        }
      }
      this.setProperty('typeUser', data.type);
      this.setProperty('staff', analytics.staff);
      this.setProperty('user_registration_date', analytics.user_registration_date);
      this.setProperty('user_name', analytics.user_name);
      this.setProperty('user_city', analytics.user_city);
      this.setProperty('user_state', analytics.user_state);
      this.setProperty('specialty', analytics.specialty);
      this.setProperty('company', analytics.company);
    } catch (error) {
      log.error(error);
    }
  }

  setProperty(nameProperty: string, valueProperty: any) {
    FirebaseAnalytics.setUserProperty({
      name: nameProperty,
      value: valueProperty,
    });
  }

  async logEvent(_nameEvent: string, _paramsEvent: {}, additional?: any) {
    const resp = await FirebaseAnalytics.getAppInstanceId();
    this._logEvent(_nameEvent, _paramsEvent, additional);
  }

  async _logEvent(_nameEvent: string, _paramsEvent: {}, additional?: any) {
    const cred = additional && additional.credentials ? additional.credentials : {};
    const routeInfo =
      additional && additional.page_data
        ? { app_page_route: additional.page_data.route, app_page_title: additional.page_data.title }
        : {};
    let userData: any = {
      user_company: cred && cred.company ? cred.company : undefined,
      user_specialty: cred && cred.speciality ? cred.speciality : undefined,
      user_state: cred && cred.state ? cred.state : undefined,
      user_type: cred && cred.type ? cred.type : undefined,
      user_id: cred && cred.uid ? cred.uid : undefined,
      user_uid: cred && cred.uid ? cred.uid : undefined,
    };
    if (cred && cred.type === 'medico') {
      if (!!!userData.user_specialty) {
        try {
          const resp = await this.getDoctorData(cred.uid);
          const specialityId = resp && resp.specialty1 && resp.specialty1.id ? resp.specialty1.id : undefined;
          const resp2 = await this.specialtiesService.getSpecialty(specialityId);
          const specialty = resp2 && resp2.name ? resp2.name : undefined;
          userData.user_specialty = specialty;
        } catch (error) {
          log.error(error);
        }
      }
    }
    const data: any = {
      name: _nameEvent,
      params: { ...userData, ..._paramsEvent, ...routeInfo },
    };
    FirebaseAnalytics.logEvent(data);
  }

  async logScrolling($event: any, item: any, data: any) {
    if (this.scrollDepthTriggered) {
      return;
    }
    const scrollElement = await $event.target.getScrollElement();
    const scrollHeight = scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = $event.detail.scrollTop;
    const targetPercent = 90;
    const triggerDepth = (scrollHeight / 100) * targetPercent;
    if (currentScrollDepth > triggerDepth) {
      this.scrollDepthTriggered = true;
      this.logEvent('scroll', item, data);
    }
  }

  async getDoctorData(uid: string): Promise<any> {
    try {
      const response = await this.fireService.afs.collection('medico-meta').doc(uid).ref.get();
      const data: any = response.data();
      if (data && data.specialty1 && data.specialty1.id && Number(data.specialty1.id) > 0) {
        const specialty = await this.specialtiesService.getSpecialty(Number(data.specialty1.id));
        data.specialty1.name = specialty.name;
      }
      if (data && data.specialty2 && data.specialty2.id && Number(data.specialty2.id) > 0) {
        const specialty = await this.specialtiesService.getSpecialty(Number(data.specialty2.id));
        data.specialty2.name = specialty.name;
      }
      return { uid: response.id, ...data };
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }

  async getUserData(uid: string): Promise<any> {
    try {
      const response = await this.fireService.afs.collection('users').doc(uid).ref.get();
      return response.data();
    } catch (error) {
      log.error(error);
      return undefined;
    }
  }
}
