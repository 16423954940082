<ion-header>
  <ion-toolbar color="primary">
    <ion-back-button defaultHref="home" color="light" slot="start" mode="md"></ion-back-button>
    <ion-title class="ion-text-center">¡Bienvenido!</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content no-bounce>
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-justify-content-center ion-no-padding">
      <ion-col size-sm="8" size-lg="6" size-xl="4" class="ion-align-self-center ion-no-padding">
        <h3 ion-text class="ion-text-center">Cambiar contraseña</h3>
        <div class="login-container">
          <form [formGroup]="verificationCodeForm" (ngSubmit)="changePassword()">
            <ion-list>
              <ion-item lines="inset">
                <ion-label position="stacked">Email <ion-text color="danger">*</ion-text> </ion-label>
                <ion-input type="text" formControlName="username" autocomplete="username"></ion-input>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('username').touched &&
                  verificationCodeForm.get('username').getError('required')
                "
              >
                <span class="ion-padding" translate>El email es requerido</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('username').touched &&
                  verificationCodeForm.get('username').getError('pattern') &&
                  !verificationCodeForm.get('username').getError('required')
                "
              >
                <span class="ion-padding" translate>El correo electrónico es incorrecto</span>
              </ion-text>
              <ion-item lines="inset">
                <ion-label position="stacked">Código de verificación <ion-text color="danger">*</ion-text> </ion-label>
                <ion-input type="text" formControlName="code"></ion-input>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('code').touched && verificationCodeForm.get('code').getError('required')
                "
              >
                <span class="ion-padding" translate>El código de verificación es requerido</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  (verificationCodeForm.get('code').touched &&
                    verificationCodeForm.get('code').getError('minlength')) ||
                  (verificationCodeForm.get('code').touched && verificationCodeForm.get('code').getError('maxlength'))
                "
              >
                <span class="ion-padding" translate>El código de verificación debe tener 6 carácteres</span>
              </ion-text>
              <ion-item>
                <ion-label position="stacked">Nueva contraseña <ion-text color="danger">*</ion-text> </ion-label>
                <ion-input
                  #passwordEyeRegister1
                  [type]="passwordTypeInput1"
                  clearOnEdit="false"
                  formControlName="password1"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode1()">
                  <ion-icon [name]="passwordTypeInput1 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password1').touched &&
                  verificationCodeForm.get('password1').getError('required')
                "
              >
                <span class="ion-padding" translate>La nueva contraseña es requerida</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password1').touched &&
                  verificationCodeForm.get('password1').getError('minlength')
                "
              >
                <span class="ion-padding" translate>La nueva contraseña debe tener al menos 6 carácteres</span>
              </ion-text>
              <ion-item>
                <ion-label position="stacked">Repetir contraseña <ion-text color="danger">*</ion-text> </ion-label>
                <ion-input
                  #passwordEyeRegister2
                  [type]="passwordTypeInput2"
                  clearOnEdit="false"
                  formControlName="password2"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode2()">
                  <ion-icon [name]="passwordTypeInput2 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password2').touched &&
                  verificationCodeForm.get('password2').getError('required')
                "
              >
                <span class="ion-padding" translate>Debes repetir la contraseña</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password2').touched &&
                  verificationCodeForm.get('password2').getError('mustMatch')
                "
              >
                <span class="ion-padding" translate>La contraseñas deben de coincidir</span>
              </ion-text>
            </ion-list>
            <div class="ion-padding-horizontal">
              <ion-button
                class="ion-margin-top"
                type="submit"
                shape="round"
                [disabled]="
                  verificationCodeForm.invalid ||
                  isLoading ||
                  verificationCodeForm.value.password1 !== verificationCodeForm.value.password2
                "
                color="tertiary"
                expand="block"
                translate
              >
                Cambiar
              </ion-button>
            </div>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
