<!-- Company Card -->
<ion-card *ngIf="company" class="animate__animated animate__fadeIn" color="light">
  <ion-toolbar color="primary">
    <ion-title>Empresa</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="company = undefined">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-row class="ion-align-items-center ion-justify-content-center">
    <ion-col sizeXs="12" sizeMd="auto" style="padding: 10px;">
      <img *ngIf="company?.logo" [src]="company.logo" class="logo-img" />
      <div class="name-logo" *ngIf="!company?.logo">
        <h1 class="ion-text-center">{{ company.nameLogo }}</h1>
      </div>
    </ion-col>
    <ion-col sizeXs="12" sizeMd="" class="ion-text-center">
      <h1 style="margin-bottom: 7px; padding-left: 10px; padding-right: 10px;" class="ion-text-center">
        {{ company?.name }}
      </h1>
      <h4 style="margin-top: 0px; padding-left: 10px; padding-right: 10px;" class="ion-text-center">
        {{ company?.businessType }}
      </h4>
      <ion-button (click)="navToPage()" *ngIf="haveWebsite" size="small">
        <ion-icon slot="end" name="globe-outline"></ion-icon>
        Página Web
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-card-content *ngIf="company?.description">
    <div class="separador-h"></div>
    <p style="margin-top: 10px; font-size: 18px;">{{ company?.description }}</p>
  </ion-card-content>
</ion-card>

<ion-card color="light" *ngIf="!company?.uid">
  <ion-card-content>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap"
        >Busca y selecciona una empresa ó en caso de no encontrarla, puedes agregar una empresa nueva.</ion-label
      >
    </ion-item>
    <ion-button (click)="selectCompany()" expand="block" size="small" [disabled]="isLoading">
      <ion-icon slot="start" name="briefcase-outline"></ion-icon>
      Buscar y seleccionar
    </ion-button>
    <ion-button (click)="addCompany()" expand="block" size="small" [disabled]="isLoading">
      <ion-icon slot="start" name="add-outline"></ion-icon>
      Agregar nueva empresa
    </ion-button>
  </ion-card-content>
</ion-card>
<div class="ion-padding" *ngIf="isProfile === false">
  <ion-button type="submit" (click)="emit()" color="tertiary" expand="block" [disabled]="!company?.uid || isLoading">
    Siguiente
  </ion-button>
</div>
