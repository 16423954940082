<ion-content color="light">
  <ion-grid fixed class="ion-no-padding white">
    <ion-toolbar>
      <ion-buttons slot="end" class="buttons-end">
        <ion-button (click)="closeModal()">
          <ion-icon name="close-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-row>
      <ion-col size="12">
        <img src="/assets/img/logo-horizontal.png" alt="Conectimed" class="logo-centered" />
        <form (ngSubmit)="login()" [formGroup]="loginForm">
          <ion-text color="primary" class="ion-text-center">
            <h2>Iniciar sesión en Conectimed</h2>
            <p>Para iniciar sesión, ingrese su dirección de correo electrónico a continuación:</p>
          </ion-text>
          <ion-list>
            <ion-item lines="none">
              <ion-input type="text" formControlName="email" autocomplete="email"
                placeholder="Dirección de correo electrónico" (ionChange)="noWhiteSpaces($event, 'email')"
                class="input-lines"></ion-input>
            </ion-item>
            <ion-text class="item-with-error" color="danger"
              *ngIf="loginForm.get('email').touched && loginForm.get('email').getError('required')">
            </ion-text>
            <ion-text class="item-with-error" color="danger" *ngIf="
                loginForm.get('email').touched &&
                loginForm.get('email').getError('pattern') &&
                !loginForm.get('email').getError('required')
              ">
              <span class="ion-padding">El correo electrónico es incorrecto</span>
            </ion-text>
            <ion-item lines="none">
              <ion-input [type]="showPassword === true ? 'text': 'password'" clearOnEdit="false"
                formControlName="password" autocomplete="current-password" placeholder="Contraseña"
                class="input-lines"></ion-input>
                <div class="float-eye">
                  <ion-button color="secondary" (click)="showPassword = !showPassword" fill="clear" size="large">                    
                    <ion-icon slot="end" [name]="showPassword === true ? 'eye-off-outline': 'eye-outline'"></ion-icon>
                  </ion-button>
                </div>
            </ion-item>
            <ion-text class="item-with-error" color="danger"
              *ngIf="loginForm.get('password').touched && loginForm.get('password').getError('required')">
              <span class="ion-padding">La contraseña es requerida</span>
            </ion-text>
            <ion-text class="item-with-error" color="danger"
              *ngIf="loginForm.get('password').touched && loginForm.get('password').getError('minlength')">
              <span class="ion-padding">La contraseña debe tener al menos 6 carácteres</span>
            </ion-text>            
          </ion-list>
          <div class="ion-padding-horizontal">
            <ion-button class="ion-margin-top" type="submit" [disabled]="loginForm.invalid || isLoading"
              color="secondary" expand="block" fill="outline">
              Iniciar sesión
            </ion-button>
          </div>
        </form>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button color="primary" class="ion-margin" size="small" type="button" fill="clear"
          [routerDirection]="'forward'" [routerLink]="['/reset-password']" (click)="closeModal()">
          <small>¿Olvidó su contraseña?</small>
        </ion-button>
      </ion-col>
      <ion-col class="ion-text-end">
        <ion-button color="primary" class="ion-margin" size="small" type="button" fill="clear"
          [routerDirection]="'forward'" [routerLink]="['/register']" (click)="closeModal()">
          <small>¿No tiene una cuenta? Regístrese</small>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>