<ion-header>
  <ion-toolbar color="primary">
    <ion-title> </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid fixed class="post-detail">
    <ion-row>
      <ion-col size="12">
        <img [src]="url" />
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
