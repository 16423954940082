import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { ModalController } from '@ionic/angular';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { Title } from '@angular/platform-browser';
import { IndustryService } from '@app/industry/services/industry.service';
import { Logger } from '@app/core/logger.service';
import moment from 'moment';
import { IndustryRoutesService } from '@app/industry/services/industry-routes.service';
const log = new Logger('ICompanyComponent');

@Component({
  selector: 'app-i-company',
  templateUrl: './i-company.component.html',
  styleUrls: ['./i-company.component.scss'],
})
export class ICompanyComponent implements OnInit {
  company: any = null;
  products: any[] = [];
  haveWebsite: boolean = false;
  isLoading: boolean = false;
  public rep: any = {};
  id: string;

  constructor(
    public router: Router,
    public analyticsFB: AnalyticsService,
    public credService: CredentialsService,
    public modalCtrl: ModalController,
    private fireService: FirebaseService,
    private aRoute: ActivatedRoute,
    private utilities: UtilitiesService,
    private titleService: Title,
    private industryService: IndustryService,
    private industryRoutesService: IndustryRoutesService
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  back() {
    window.history.back();
  }

  public share() {
    const route = `/industry/companies/detail/${this.company.id}`;
    this.utilities.share(route);
    this.analyticsClickEvent('share', {
      content_type: 'empresas',
      company_id: this.company && this.company.id ? this.company.id : ''
    });
  }

  async ngOnInit() {
    if (!this.company) {
      this.id = this.aRoute.snapshot.paramMap.get('id');
      if (!this.id) {
        this.router.navigateByUrl('home');
        return;
      } else {
        this.company = await this.fireService.getFullCompanyById(this.id);
        this.company.routing = this.industryRoutesService.mapRoute('Company', this.id);
        if (!this.company) {
          this.router.navigateByUrl('home');
          return;
        } else if (this.company.error) {
          this.utilities.toast('Ocurrio un error inesperado');
          this.router.navigateByUrl('home');
          return;
        } else {
          this.getDetail();
        }
      }
    } else {
      this.getDetail();
    }
  }

  async getDetail() {
    this.initializeItems();
    let representants = await this.fireService.getUsersFromCampanyId(this.company.id);
    if (representants) {
      representants = representants.map(e => {
        const data: any = e;
        if (e.createdAt instanceof Object) {
          data.createdAt = moment(e.createdAt.toDate()).toDate();
        } else {
          data.createdAt = moment(e.createdAt).toDate();
        }
        return data;
      });
      representants.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()).reverse().map(e => {
        return {
          createdAt: moment(e.createdAt).format('LLL'),
          uid: e.uid,
          email: e.email
        }
      });
      for (const rep of representants) {
        let first = rep.name ? rep.name.charAt(0) : '';
        let last = rep.lastName1 ? rep.lastName1.charAt(0) : '';
        rep.nameProfile = (first + last).toUpperCase();
      }
      this.rep = representants && representants[0] ? representants[0] : {};
    }
    this.titleService.setTitle(`Empresa | ${this.company.name}`);
    if (this.company.companyWebsite) {
      const website: string = this.company.companyWebsite;
      if (website.split('https://').length === 1 && website.split('http://').length === 1) {
        this.haveWebsite = this.utilities.validateUrl('http://' + website);
        if (this.haveWebsite) {
          this.company.companyWebsite = 'http://' + this.company.companyWebsite;
        }
      } else {
        this.haveWebsite = this.utilities.validateUrl(this.company.companyWebsite);
      }
    }
    if (!this.company.logo) {
      const name: string = this.company.name;
      const split = name.split(' ');
      if (split.length > 1) {
        this.company.nameLogo = split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase();
      } else {
        this.company.nameLogo = name.charAt(0).toUpperCase();
      }
    }
  }

  detalleRepresentante(id: string) {
    this.analyticsClickEvent('click', {
      content_type: 'Ir a perfil representante',
      click_text: 'Ver representante',
      click_URL: '/user/details/' + id,

      section_name: 'Productos'
    });
    this.router.navigate(['/user/details/' + id]);
  }

  navToPage() {
    window.open(this.company.companyWebsite, '_blank');
    this.analyticsClickEvent('company_open_website', {
      content_type: 'Abrir pagina web de empresa',
      click_URL: this.company.companyWebsite,

      section_name: 'Empresas'
    });
  }

  private initializeItems() {
    this.industryService.where = [
      { fieldPath: 'company', opStr: '==', value: this.fireService.afs.doc(`companies/${this.id}`).ref },
      { fieldPath: 'status', opStr: '==', value: 'active' }
    ];
    this.industryService.limit = 4;
    this.industryService.clearItems();
    this.paginationPosts();
  }

  async paginationPosts(direction?: "next" | "back") {
    this.isLoading = true;
    try {
      await this.industryService.pagination(direction);
      this.products = this.industryService.items;
    } catch (e) {
      log.error(e);
    }
    this.isLoading = false;
  }

  public async getMoreItems(event: any): Promise<void> {
    await this.paginationPosts('next');
    if (event && event.target && event.target.complete) {
      event.target.complete();
    }
  }
}
