import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
const log = new Logger('FavoriteService');

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  constructor(
    private fireService: FirebaseService,
    private utilities: UtilitiesService,
    public credService: CredentialsService
  ) { }

  public async favorite(id: string): Promise<boolean> {
    const mainRef = this.fireService.afs.doc(`users/${this.credService.credentials.uid}/favorites/${id}`).ref;
    try {
      return await this.fireService.afs.firestore.runTransaction(async (transaction) => {
        const mainDoc = await transaction.get(mainRef);
        if (!mainDoc.exists === true) {
          transaction.set(mainRef, { date: firebase.firestore.FieldValue.serverTimestamp(), id: id });
          this.utilities.toast(`Agregado a 'Mis Favoritos'`, undefined, "bottom");
          return true;
        } else {
          transaction.delete(mainRef);
          this.utilities.toast(`Removido de 'Mis Favoritos'`, undefined, "bottom");
          return false;
        }
      });
    } catch (error) {
      log.error('Transaction failed: ', error);
      return false;
    }
  }
}
