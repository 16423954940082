import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveysComponent } from './surveys.component';
import { NoAuthenticatedGuard } from '@app/core/authentication/guards/no-authenticated.guard';

const routes: Routes = [
  {
    path: ':id',
    component: SurveysComponent,
    data: {
      title: 'Encuestas Conectimed',
      icon: {
        name: "clipboard-outline"
      }
    }
  },
  {
    path: ':id/:email',
    component: SurveysComponent,
    data: {
      title: 'Encuestas Conectimed',
      icon: {
        name: "clipboard-outline"
      }
    },
    canActivate: [NoAuthenticatedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SurveysRoutingModule { }
