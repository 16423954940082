import { Injectable } from '@angular/core';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class TypesOfHealthProfessionalsService {
  constructor(private firebase: FirebaseService) { }

  public async getHealthProfessionals(): Promise<any> {
    try {
      const response = await this.firebase.afs.collection('types-of-health-professionals').ref.where('status', '==', 'active').get();
      return response.docs.map(item => {
        const data: any = item.data();
        const id: string = item.id;
        return { ...data, id }
      });
    } catch (error) {
      return [];
    }
  }

  public async getHealthProfessional(id: string): Promise<any> {
    try {
      const response = await this.firebase.afs.doc(`types-of-health-professionals/${id}`).ref.get();
      const data: any = response.data() || {};
      const idDoc: string = response.id || "";
      return { ...data, id: idDoc }
    } catch (error) {
      return {};
    }
  }
}
