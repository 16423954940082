<ion-header *ngIf="isDeleteAccountForm === true">
  <ion-toolbar color="primary">
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid fixed>
    <ion-row *ngIf="isLoading === true">
      <ion-col size="12">
        <div>
          <h3>
            <ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 30%;"></ion-skeleton-text>
          </p>

          <h3>
            <ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 30%;"></ion-skeleton-text>
          </p>

          <h3>
            <ion-skeleton-text [animated]="true" style="width: 80%;"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 60%;"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text [animated]="true" style="width: 30%;"></ion-skeleton-text>
          </p>
        </div>
      </ion-col>
    </ion-row>
    <div *ngIf="!isLoading === true">
      <ion-row *ngIf="exist === true && active === true">
        <ion-col size="12" *ngIf="!surveyAnswered?.completed === true">
          <div *ngIf="!isDeleteAccountForm === true" class="ion-text-center primary-bg">
            <p><strong>{{ survey?.title }}</strong></p>
          </div>
          <div *ngIf="isDeleteAccountForm === true" class="ion-text-center">
            <h2>{{ survey?.title }}</h2>
          </div>
          <div [innerHTML]="survey?.html" class="feat-text"></div>
          <hr>
          <form *ngIf="myForm" [formGroup]="myForm" (ngSubmit)="isDeleteAccountForm === true ? alertConfirm(): send()">
            <div *ngFor="let item of questions; let i = index">
              <div [ngSwitch]="item?.type">
                <!-- OPEN -->
                <ng-template [ngSwitchCase]="'open'">
                  <ion-item color="stable">
                    <h2>{{ i + 1 }}.- {{ item?.text }} <ion-text color="danger"
                        *ngIf="submitted === true && myForm?.controls[i]?.errors?.required == true"><small> (Campo
                          Requerido)</small></ion-text></h2>
                  </ion-item>
                  <div class="border-round ion-margin-vertical">
                    <ion-item lines="none" class="ion-margin-bottom ion-margin-top">
                      <ion-textarea [formControlName]="i" [id]="i" placeholder="Respuesta..." rows="5"></ion-textarea>
                    </ion-item>
                  </div>
                </ng-template>
                <!-- MULTIPLE -->
                <ng-template [ngSwitchCase]="'multiple'">
                  <div *ngIf="item?.singleAnswer === true; else multipleAnswer">
                    <ion-item color="stable">
                      <h2>{{ i + 1 }}.- {{ item?.text }} <ion-text color="danger"
                          *ngIf="submitted === true && ((myForm?.controls[i]?.controls['answer'].errors?.required == true) || (myForm?.controls[i]?.controls['openAnswer'].errors?.required == true))"><small>
                            (Campo
                            Requerido)</small></ion-text></h2>
                    </ion-item>
                    <div [formGroupName]="i" [id]="i">
                      <ion-radio-group formControlName="answer" mode="md"
                        (ionChange)="changeMultipleOption($event,item?.options,i)">
                        <div *ngFor="let option of item?.options; let ii = index">
                          <ion-radio class="ctm-radio" [value]="ii" labelPlacement="end" justify="start">
                            <p class="ion-text-wrap">
                              {{ option?.text }}
                            </p>
                          </ion-radio><br />
                        </div>
                      </ion-radio-group>
                      <div class="border-round  ion-margin-vertical"
                        *ngIf="item?.options[myForm?.controls[i]?.value?.answer]?.isOpen === true">
                        <ion-item lines="none" class="ion-margin-bottom ion-margin-top">
                          <ion-textarea formControlName="openAnswer" rows="5" placeholder="Respuesta..."></ion-textarea>
                        </ion-item>
                      </div>
                      <ion-text color="danger">
                        <p>{{ submitted === true && myForm?.controls[i]?.controls['openAnswer'].errors?.required == true
                          ?
                          'Ingrese una respuesta':'' }}</p>
                      </ion-text>
                    </div>
                  </div>
                  <ng-template #multipleAnswer>
                    <ion-item color="stable">
                      <h2>{{ i + 1 }}.- {{ item?.text }} <ion-text color="danger"
                          *ngIf="submitted === true && customValidator(myForm?.controls[i]?.controls['answers']) === true"><small>
                            (Campo
                            Requerido)</small></ion-text></h2>
                    </ion-item>
                    <div [formGroupName]="i" [id]="i">
                      <div formArrayName="answers" style="padding-top: 5px;">
                        <div *ngFor="let option of item?.options; let ii = index"
                          [formGroupName]="ii">
                          <ion-checkbox labelPlacement="end" formControlName="answer"
                            (ionChange)="option?.isOpen === true ? checkboxChange($event,i,ii):''">{{
                            option?.text }}</ion-checkbox><br>
                          <div *ngIf="myForm?.controls[i]?.controls['answers']?.value[ii]  as data">
                            <div class="border-round  ion-margin-vertical"
                              *ngIf="data?.answer === true && option?.isOpen === true">
                              <ion-item lines="none" class="ion-margin-bottom ion-margin-top">
                                <ion-textarea formControlName="openAnswer" rows="5"
                                  placeholder="Respuesta..."></ion-textarea>
                              </ion-item>
                            </div>
                            <ion-text color="danger">
                              <p>{{ submitted === true && data?.answer === true && option?.isOpen === true &&
                                myForm?.controls[i]?.controls['answers']?.controls[ii]?.controls?.openAnswer?.errors?.required
                                === true
                                ?
                                'Ingrese una respuesta':'' }}</p>
                            </ion-text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
                <!-- RATE -->
                <ng-template [ngSwitchCase]="'rate'">
                  <ion-item color="stable">
                    <h2>{{ i + 1 }}.- {{ item?.text }} <ion-text color="danger"
                        *ngIf="submitted === true && myForm?.controls[i]?.errors?.required == true"><small> (Campo
                          Requerido)</small></ion-text></h2>
                  </ion-item>
                  <div class="ion-no-padding ion-margin-vertical">
                    <div class="custom-table">
                      <div class="custom-row">
                        <div class="custom-col" *ngIf="toArray(item?.max)[0] as rateValue">
                          <ion-button class="ion-no-margin ion-no-padding custom-star"
                            (click)="rate(i , rateValue?.value)" fill="clear"
                            [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                            <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                          </ion-button>
                          <br>
                          <span class="small-font"><small>Muy mala</small></span>
                        </div>
                        <div class="custom-col" *ngIf="toArray(item?.max)[1] as rateValue">
                          <ion-button class="ion-no-margin ion-no-padding custom-star"
                            (click)="rate(i , rateValue?.value)" fill="clear"
                            [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                            <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                          </ion-button>
                          <br>
                          <span class="small-font"><small>Mala</small></span>
                        </div>
                        <div class="custom-col" *ngIf="toArray(item?.max)[2] as rateValue">
                          <ion-button class="ion-no-margin ion-no-padding custom-star"
                            (click)="rate(i , rateValue?.value)" fill="clear"
                            [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                            <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                          </ion-button>
                          <br>
                          <span class="small-font"><small>Regular</small></span>
                        </div>
                        <div class="custom-col" *ngIf="toArray(item?.max)[3] as rateValue">
                          <ion-button class="ion-no-margin ion-no-padding custom-star"
                            (click)="rate(i , rateValue?.value)" fill="clear"
                            [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                            <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                          </ion-button>
                          <br>
                          <span class="small-font"><small>Buena</small></span>
                        </div>
                        <div class="custom-col" *ngIf="toArray(item?.max)[4] as rateValue">
                          <ion-button class="ion-no-margin ion-no-padding custom-star"
                            (click)="rate(i , rateValue?.value)" fill="clear"
                            [color]=" myForm?.controls[i]?.value < rateValue?.value ? 'medium' : 'warning'">
                            <ion-icon slot="icon-only" name="star-sharp"></ion-icon>
                          </ion-button>
                          <br>
                          <span class="small-font"><small>Muy buena</small></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <div *ngIf="type === 'anonymous'">
              <ion-item color="stable">
                <h2>Para darle un mejor servicio, por favor bríndenos los siguientes datos:</h2>
              </ion-item>
              <p>Nombre</p>
              <div class="border-round">
                <ion-item lines="none" class="ion-margin-bottom">
                  <ion-input aria-label="name" type="text" formControlName="name" id="name"
                    placeholder="Nombre..."></ion-input>
                </ion-item>
              </div>
              <p>Email</p>
              <div class="border-round">
                <ion-item lines="none" class="ion-margin-bottom">
                  <ion-input aria-label="email" type="email" formControlName="email" id="email"
                    placeholder="Email..."></ion-input>
                </ion-item>
              </div>
              <ion-text color="danger"
                *ngIf="myForm?.controls?.email?.errors?.invalidEmail === true && myForm?.controls?.email?.touched === true">
                <small>Por favor ingrese un email válido.</small>
              </ion-text>
            </div>
            <div class="ion-text-center">
              <ion-button type="submit" size="large" *ngIf="!(isDeleteAccountForm === true)">
                <ion-label class="ion-padding-start ion-padding-end">Enviar</ion-label>
              </ion-button>
              <ion-grid *ngIf="isDeleteAccountForm === true">
                <ion-row>
                  <ion-col size="6">
                    <ion-button type="button" fill="clear" (click)="closeModal()">
                      <ion-label class="ion-padding-start ion-padding-end">Quiero pensarlo un poco</ion-label>
                    </ion-button>
                  </ion-col>
                  <ion-col size="6">
                    <ion-button type="submit">
                      <ion-label class="ion-padding-start ion-padding-end">Eliminar mi cuenta</ion-label>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </form>
        </ion-col>
        <ion-col size="12" *ngIf="surveyAnswered?.completed === true">
          <div class="ion-text-center ion-padding" *ngIf="surveyAnswered?.previouslyCompleted === true; else second">
            <ion-icon name="calendar-outline" size="large" color="danger"></ion-icon>
            <div>
              <h1>¡Usted ya ha completado esta encuesta!</h1>
              <p>{{ survey?.title }}</p>
            </div>
            <p>
              <strong>
                Ya hemos recibido sus datos, por favor esté atento a más contenido en Conectimed.
              </strong>
            </p>
            <ion-text color="medium">
              <p>En breve será redirigido al home.</p>
            </ion-text>
          </div>
          <ng-template #second>
            <div class="ion-text-center ion-padding">
              <img src="../../assets/icons/check.gif" class="ctm-gif" alt="">
              <div>
                <h1>¡Gracias por completar la encuesta!</h1>
                <p>{{ survey?.title }}</p>
              </div>
              <p>
                <strong>
                  Ya hemos recibido sus datos, por favor esté atento a más contenido en Conectimed.
                </strong>
              </p>
              <ion-text color="medium">
                <p>En breve será redirigido al home.</p>
              </ion-text>
            </div>
          </ng-template>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!(exist === true && active === true)">
        <ion-col size="12">
          <div class="ion-text-center ion-padding">
            <ion-icon name="close-circle-outline" size="large" color="danger"></ion-icon>
            <div>
              <h1><strong>¡La encuesta no existe!</strong></h1>
            </div>
            <ion-text color="medium">
              <p>
                Lo sentimos la encuesta no existe o ha sido dada de baja, por favor solicite un nuevo link.
              </p>
            </ion-text>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</ion-content>