import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { MustMatch } from '@app/shared/helpers/must-match.validator';
import { NoWhiteSpaceValidator } from '@app/shared/validators/noWhiteSpace.validator';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Logger } from '@app/core/logger.service';
import { HiddenDataService } from '@app/shared/services/hidden-data/hidden-data.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('PreregistroComponent');

export interface UserData {
  uid: string;
  email: string;
  password: string;
}

@Component({
  selector: 'app-preregistro',
  templateUrl: './preregistro.component.html',
  styleUrls: ['./preregistro.component.scss']
})
export class PreregistroComponent implements OnInit {
  @ViewChild('passwordEyeRegister1', { static: false, read: ElementRef }) passwordEye1: ElementRef;
  @ViewChild('passwordEyeRegister2', { static: false, read: ElementRef }) passwordEye2: ElementRef;
  passwordTypeInput1 = 'password';
  passwordTypeInput2 = 'password';
  error: string | undefined;
  public myForm1!: FormGroup;
  public myForm2!: FormGroup;
  isLoading = false;
  public step: number = 1;
  private mainData: any = {};
  public userData: UserData;
  specialties: any[];
  preregistroFB: any;
  user: any;

  constructor(
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private utilities: UtilitiesService,
    private authServe: AuthenticationService,
    private hiddenData: HiddenDataService,

    private fireService: FirebaseService,
    private specialtiesService: SpecialtiesService
  ) {
    this.createForm1();
    this.createForm2();
  }

  togglePasswordMode1() {
    //cambiar tipo input
    this.passwordTypeInput1 = this.passwordTypeInput1 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye1.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  togglePasswordMode2() {
    //cambiar tipo input
    this.passwordTypeInput2 = this.passwordTypeInput2 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye2.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  ngOnInit() { }

  public createForm2() {
    this.myForm2 = this.formBuilder.group(
      {
        type: ['medico'],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
          ]
        ],
        password1: ['', [Validators.required, Validators.minLength(6), NoWhiteSpaceValidator.isValid]],
        password2: ['', Validators.required],
        termsAndConditions: [false, Validators.required]
      },
      {
        validator: MustMatch('password1', 'password2')
      }
    );
  }

  private createForm1() {
    this.myForm1 = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      tel: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]]
    });
  }

  public async createAccount(): Promise<void> {
    try {
      const email: string = String(this.myForm2.get('email').value);
      const password1: string = String(this.myForm2.get('password1').value);
      const userRegister = await this.fireService.createUser(email, password1, 'medico');
      if (userRegister.user && userRegister.user.uid) {
        this.userData = {
          uid: userRegister.user.uid,
          email: userRegister.user.email,
          password: password1
        };
        this.user = userRegister.user;
      } else {
        this.utilities.toast(
          'Lo sentimos,el usraio ya existe o ha ocurrido un error, inténtalo mas tarde.',
          'Error al registrar'
        );
      }
    } catch (error) {
      this.utilities.toast('Lo sentimos, ha ocurrido un error, inténtelo mas tarde.', 'Error al registrar');
    }
  }

  async saveSpecialtyData() {
    var fullName = this.preregistroFB.apaterno + ' ' + this.preregistroFB.apaterno + ' ' + this.preregistroFB.nombre;
    const search = (await this.utilities.arraySearchRequest(fullName)) || [];
    const nameStr = this.utilities.stringSearch(fullName, true);
    var body = {
      name: this.preregistroFB.nombre,
      lastName1: this.preregistroFB.apaterno,
      lastName2: this.preregistroFB.amaterno,
      email: this.preregistroFB.email,
      nameStr: nameStr,
      firstCharacter: nameStr.charAt(0) || '',
      mobile: this.preregistroFB.telmovil,
      phone: this.preregistroFB.telfijo,
      search: search,
      status: 'active',
      type: this.preregistroFB.tipo,
      uid: this.user.uid,
      createdAt: new Date()
    };
    await this.fireService.updateUser(this.user.uid, body);
    let data: any = {};
    try {
      if (this.preregistroFB && this.preregistroFB.especialidad1 && this.preregistroFB.cedula1) {
        data.specialty1 = { id: Number(this.preregistroFB.especialidad1), cedula: this.preregistroFB.cedula1 };
      }
      if (this.preregistroFB && this.preregistroFB.especialidad2 && this.preregistroFB.cedula2) {
        data.specialty2 = { id: Number(this.preregistroFB.especialidad2), cedula: this.preregistroFB.cedula2 };
      }
      await this.fireService.updateDoctor(this.user.uid, data);
    } catch (error) {
      log.error(error);
    }
  }

  async saveAddrresInfo() {
    try {
      const inUseState = await this.fireService.afs.collection('states-in-use').ref.get();
      var str1 = '',
        estado1 = '',
        ciudad1 = '',
        colonia1 = '',
        delmun1 = '',
        calle1 = '',
        codipostal1 = '',
        numeroext1 = '',
        numeroint1 = '',
        hospital1 = '',
        torre1 = '';
      let existe4;
      let existe3;

      inUseState.docs.forEach((item: any) => {
        if (this.preregistroFB.estado1 && this.preregistroFB.estado1 !== '' && this.preregistroFB.estado1 !== null) {
          var noTildes = this.utilities.stringSearch(this.preregistroFB.estado1);
          var noTildes2 = this.utilities.stringSearch(item.data().name);
          if (String(noTildes) == String(noTildes2)) {
            existe3 = item.data().name;
          } else {
            existe3 = this.preregistroFB.estado1;
          }
        }

        if (this.preregistroFB.estado2 && this.preregistroFB.estado2 !== '' && this.preregistroFB.estado2 !== null) {
          var noTildes3 = this.utilities.stringSearch(this.preregistroFB.estado2);
          if (String(noTildes) == String(noTildes3)) {
            existe4 = item.data().name;
          } else {
            existe4 = this.preregistroFB.estado2;
          }
        }
      });

      estado1 = existe3;
      if (estado1 && estado1 !== '' && estado1 !== null) {
        str1 = this.utilities.stringSearch(estado1);
      }
      if (this.preregistroFB.ciudad1 && this.preregistroFB.ciudad1 !== '' && this.preregistroFB.ciudad1 !== null) {
        ciudad1 = this.preregistroFB.ciudad1;
      }
      if (this.preregistroFB.colonia1 && this.preregistroFB.colonia1 !== '' && this.preregistroFB.colonia1 !== null) {
        colonia1 = this.preregistroFB.colonia1;
      }
      if (this.preregistroFB.delmun1 && this.preregistroFB.delmun1 !== '' && this.preregistroFB.delmun1 !== null) {
        delmun1 = this.preregistroFB.delmun1;
      }
      if (this.preregistroFB.calle1 && this.preregistroFB.calle1 !== '' && this.preregistroFB.calle1 !== null) {
        calle1 = this.preregistroFB.calle1;
      }
      if (
        this.preregistroFB.codipostal1 &&
        this.preregistroFB.codipostal1 !== '' &&
        this.preregistroFB.codipostal1 !== null
      ) {
        codipostal1 = this.preregistroFB.codipostal1;
      }
      if (
        this.preregistroFB.numeroext1 &&
        this.preregistroFB.numeroext1 !== '' &&
        this.preregistroFB.numeroext1 !== null
      ) {
        numeroext1 = this.preregistroFB.numeroext1;
      }
      if (
        this.preregistroFB.numeroint1 &&
        this.preregistroFB.numeroint1 !== '' &&
        this.preregistroFB.numeroint1 !== null
      ) {
        numeroint1 = this.preregistroFB.numeroint1;
      }
      if (
        this.preregistroFB.hospital1 &&
        this.preregistroFB.hospital1 !== '' &&
        this.preregistroFB.hospital1 !== null
      ) {
        hospital1 = this.preregistroFB.hospital1;
      }
      if (this.preregistroFB.torre1 && this.preregistroFB.torre1 !== '' && this.preregistroFB.torre1 !== null) {
        torre1 = this.preregistroFB.torre1;
      }

      const address1Obj = {
        city: ciudad1,
        colony: colonia1,
        delegation: delmun1,
        hospital: hospital1,
        interiorNumber: String(numeroint1),
        outsideNumber: String(numeroext1),
        postalCode: codipostal1,
        state: estado1,
        strState: str1,
        street: calle1,
        tower: torre1
      };

      var str2 = '',
        estado2 = '',
        ciudad2 = '',
        colonia2 = '',
        delmun2 = '',
        calle2 = '',
        codipostal2 = '',
        numeroext2 = '',
        numeroint2 = '',
        hospital2 = '',
        torre2 = '';
      estado2 = existe4;
      if (estado2 && estado2 !== '' && estado2 !== null) {
        str2 = this.utilities.stringSearch(estado2);
      }
      if (this.preregistroFB.ciudad2 && this.preregistroFB.ciudad2 !== '' && this.preregistroFB.ciudad2 !== null) {
        ciudad2 = this.preregistroFB.ciudad2;
      }
      if (this.preregistroFB.colonia2 && this.preregistroFB.colonia2 !== '' && this.preregistroFB.colonia2 !== null) {
        colonia2 = this.preregistroFB.colonia2;
      }
      if (this.preregistroFB.delmun2 && this.preregistroFB.delmun2 !== '' && this.preregistroFB.delmun2 !== null) {
        delmun2 = this.preregistroFB.delmun2;
      }
      if (this.preregistroFB.calle2 && this.preregistroFB.calle2 !== '' && this.preregistroFB.calle2 !== null) {
        calle2 = this.preregistroFB.calle2;
      }
      if (
        this.preregistroFB.codipostal2 &&
        this.preregistroFB.codipostal2 !== '' &&
        this.preregistroFB.codipostal2 !== null
      ) {
        codipostal2 = this.preregistroFB.codipostal2;
      }
      if (
        this.preregistroFB.numeroext2 &&
        this.preregistroFB.numeroext2 !== '' &&
        this.preregistroFB.numeroext2 !== null
      ) {
        numeroext2 = this.preregistroFB.numeroext2;
      }
      if (
        this.preregistroFB.numeroint2 &&
        this.preregistroFB.numeroint2 !== '' &&
        this.preregistroFB.numeroint2 !== null
      ) {
        numeroint2 = this.preregistroFB.numeroint2;
      }
      if (
        this.preregistroFB.hospital2 &&
        this.preregistroFB.hospital2 !== '' &&
        this.preregistroFB.hospital2 !== null
      ) {
        hospital2 = this.preregistroFB.hospital2;
      }
      if (this.preregistroFB.torre2 && this.preregistroFB.torre2 !== '' && this.preregistroFB.torre2 !== null) {
        torre2 = this.preregistroFB.torre2;
      }

      const address2Obj = {
        city: ciudad2,
        colony: colonia2,
        delegation: delmun2,
        hospital: hospital2,
        interiorNumber: String(numeroext2),
        outsideNumber: String(numeroint2),
        postalCode: codipostal2,
        state: estado2,
        strState: str2,
        street: calle2,
        tower: torre2
      };

      let data: any = {};
      data.address1 = address1Obj;
      if (address2Obj.state && address2Obj.city && address2Obj.street && address2Obj.postalCode) {
        data.address2 = address2Obj;
      }

      await this.fireService.updateDoctor(this.user.uid, data);
      await this.fireService.activateUser(this.user.uid);
    } catch (error) {
      log.error(error);
    }
  }

  async login() {
    this.isLoading = true;
    try {
      await this.loader.ionLoaderPresent();
      const login$ = await this.authServe.signInWithEmailAndPassword(
        String(this.myForm2.get('email').value),
        String(this.myForm2.get('password1').value)
      );
      this.hiddenData.setData('pending', {});
      this.myForm1.markAsPristine();
      this.myForm2.markAsPristine();
      this.isLoading = false;
    } catch (error: any) {
      log.debug(`Login error: ${error}`);
      this.error = error;
    }
    await this.loader.ionLoaderDismiss();
  }

  async initSpecialties(): Promise<void> {
    try {
      this.specialties = await this.specialtiesService.getSpecialties();
    } catch (error) {
      log.error(error);
    }
  }

  async submit() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.createAccount();
      await this.saveSpecialtyData();
      await this.saveAddrresInfo();
      await this.fireService.afs
        .collection('preregistro')
        .doc(this.mainData.id)
        .update({
          inWP: true
        });
      await this.authServe.logout();
      await this.authServe.logoutUser();
      await this.login();
      this.step = 3;
    } catch (error) {
      log.debug(error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }
}
