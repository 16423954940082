<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">Cambiar contraseña</ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <div class="login-container">
          <form [formGroup]="verificationCodeForm" (ngSubmit)="changePassword()">
            <ion-list>
              <ion-item>
                <ion-input
                  label="Contraseña anterior *"
                  #passwordEyeRegister1
                  [type]="passwordTypeInput1"
                  clearOnEdit="false"
                  formControlName="password"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode1()">
                  <ion-icon [name]="passwordTypeInput1 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password').touched &&
                  verificationCodeForm.get('password').getError('required')
                "
              >
                <span class="ion-padding" >La contraseña anterior es requerida</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password').touched &&
                  verificationCodeForm.get('password').getError('minlength')
                "
              >
                <span class="ion-padding" >La contraseña anterior debe tener al menos 6 carácteres</span>
              </ion-text>
              <ion-item>
                <ion-input
                  label="Nueva contraseña *"
                  #passwordEyeRegister1
                  [type]="passwordTypeInput1"
                  clearOnEdit="false"
                  formControlName="password1"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode1()">
                  <ion-icon [name]="passwordTypeInput1 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password1').touched &&
                  verificationCodeForm.get('password1').getError('required')
                "
              >
                <span class="ion-padding" >La nueva contraseña es requerida</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password1').touched &&
                  verificationCodeForm.get('password1').getError('minlength')
                "
              >
                <span class="ion-padding" >La nueva contraseña debe tener al menos 6 carácteres</span>
              </ion-text>
              <ion-item>
                <ion-input
                  label="Repetir contraseña *"
                  #passwordEyeRegister2
                  [type]="passwordTypeInput2"
                  clearOnEdit="false"
                  formControlName="password2"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode2()">
                  <ion-icon [name]="passwordTypeInput2 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password2').touched &&
                  verificationCodeForm.get('password2').getError('required')
                "
              >
                <span class="ion-padding" >Debes repetir la contraseña</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="
                  verificationCodeForm.get('password2').touched &&
                  verificationCodeForm.get('password2').getError('mustMatch')
                "
              >
                <span class="ion-padding" >La contraseñas deben de coincidir</span>
              </ion-text>
            </ion-list>
            <div class="ion-padding-horizontal">
              <ion-button
                class="ion-margin-top"
                type="submit"
                shape="round"
                [disabled]="
                  verificationCodeForm.invalid ||
                  isLoading ||
                  verificationCodeForm.value.password1 !== verificationCodeForm.value.password2
                "
                color="tertiary"
                expand="block"
                
              >
                Cambiar
              </ion-button>
            </div>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
