<ion-content color="light">
  <ion-grid [fixed]="true" class="white">
    <ion-row>
      <ion-col size="12">
        <div class="max-550">
          <img src="/assets/img/logo-horizontal.png" alt="Conectimed" class="logo-centered" />

          <ion-text class="ion-text-center">
            <h2><strong>Recuperación de Contraseña</strong></h2>

            <p *ngIf="step === 1">
              <ion-chip color="warning">
                <ion-icon name="warning-outline"></ion-icon>
              </ion-chip>
              <span>
                Para recuperar su contraseña es necesario que ingrese el correo electrónico con el que se registro.
              </span>
            </p>

            <p *ngIf="step === 3 && contactForm.value.type === 'email'">
              Ingrese el código de 6 dígitos que se le envió por <strong>correo</strong>.
            </p>

            <p *ngIf="step === 3 && contactForm.value.type === 'sms'">
              Ingrese el código de 6 dígitos que se le envió por <strong>SMS</strong>.
            </p>

            <p *ngIf="step === 4">
              Por favor, escriba una nueva contraseña para completar el proceso de la cuenta <strong>{{
                displayEmail }}</strong>.
            </p>
          </ion-text>

          <div [ngSwitch]="step">
            <div *ngSwitchCase="1">
              <form [formGroup]="emailForm" (ngSubmit)="continue()">
                <ion-item lines="none">
                  <ion-input color="dark" type="text" formControlName="email"
                    placeholder="Ingrese su correo electrónico" oncopy="return false;" onpaste="return false;"
                    ondrop="return false;" autocomplete="off" (ionChange)="noWhiteSpaces($event)"
                    class="input-lines"></ion-input>
                </ion-item>

                <ion-text class="ion-padding" color="danger"
                  *ngIf="emailForm.get('email').touched && emailForm.get('email').getError('required')">
                  <span class="ion-padding">El email es requerido</span>
                </ion-text>

                <ion-text class="ion-padding" color="danger" *ngIf="
                    emailForm.get('email').touched &&
                    emailForm.get('email').getError('pattern') &&
                    !emailForm.get('email').getError('required')
                  ">
                  <span class="ion-padding">El correo electrónico es incorrecto</span>
                </ion-text>

                <div class="ion-padding ion-text-center">
                  <ion-button class="ion-margin-top" type="submit" expand="block"
                    [disabled]="!emailForm.valid === true || isLoading === true" color="secondary">
                    Continuar
                  </ion-button>
                </div>
              </form>
            </div>

            <div *ngSwitchCase="2">
              <h1>{{ displayName }}</h1>
              <form [formGroup]="contactForm" (ngSubmit)="send()">
                <p>
                  <strong>
                    Seleccione a través de qué medio desea recibir el <strong>código</strong> para recuperar su
                    contraseña.
                  </strong>
                </p>
                <ion-list>
                  <ion-radio-group formControlName="type">
                    <ion-item lines="none">
                      <ion-radio slot="start" value="email" [disabled]="!(counter <= 0)" mode="md"
                        labelPlacement="end">{{
                        displayEmail }}</ion-radio>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-radio slot="start" value="sms" [disabled]="!(counter <= 0)" mode="md"
                        labelPlacement="end">Mensaje de texto (SMS) {{ displayPhone }}</ion-radio>
                    </ion-item>
                  </ion-radio-group>
                </ion-list>
                <p *ngIf="contactForm.value.type === 'email'">
                  Se le enviará un <strong>correo</strong> con las instrucciones para reestablecer la contraseña.
                </p>
                <p *ngIf="contactForm.value.type === 'sms'">
                  Se le enviará un <strong>SMS</strong> con las instrucciones para reestablecer la contraseña.
                </p>

                <ion-button *ngIf="counter <= 0; else noButton" type="submit" expand="block" color="primary"
                  [disabled]="!contactForm.valid === true || isLoading === true">
                  Continuar
                </ion-button>

                <ion-button type="button" expand="block" color="secondary" (click)="lastStep()">
                  Ya tengo un código
                </ion-button>

                <ng-template #noButton>
                  <ion-text color="medium">
                    <p>Reintentar en {{ counter * 1000 | date: 'mm:ss' }}</p>
                  </ion-text>
                </ng-template>

                <ion-button type="button" expand="block" color="primary" fill="clear" (click)="reset()">
                  <span class="underline-text">Intentar con otra cuenta</span>
                </ion-button>
              </form>
            </div>

            <div *ngSwitchCase="3">
              <form [formGroup]="codeForm" (ngSubmit)="checkUserCode()">
                <ion-item lines="none">                  
                  <ion-input type="text" formControlName="code" placeholder="Código de verificación"
                  (ionChange)="noWhiteSpaces($event)" class="input-lines"></ion-input>
                </ion-item>
                <ion-text class="item-with-error" color="danger"
                  *ngIf="codeForm.get('code').touched && codeForm.get('code').getError('required')">
                  <span class="ion-padding">El código de verificación es requerido</span>
                </ion-text>

                <ion-button type="submit" expand="block" color="primary"
                  [disabled]="!codeForm.valid === true || isLoading === true">
                  Continuar
                </ion-button>

                <ion-button type="button" expand="block" color="secondary" (click)="step2()">
                  No recibí el código, intentar con otro método
                </ion-button>

                <ion-button type="button" expand="block" color="primary" fill="clear" (click)="reset()">
                  <span class="underline-text">Intentar con otra cuenta</span>
                </ion-button>
              </form>
            </div>

            <div *ngSwitchCase="4">
              <form [formGroup]="resetForm" (ngSubmit)="finalize()">
                <ion-item lines="none" class="item-round" color="light">
                  <ion-input type="password" formControlName="password" placeholder="Ingrese contraseña nueva"
                    oncopy="return false;" onpaste="return false;" ondrop="return false;" autocomplete="off"
                    (ionChange)="noWhiteSpaces($event, true)" class="input-lines"></ion-input>
                </ion-item>

                <ion-text class="item-with-error" color="danger"
                  *ngIf="resetForm.get('password').touched && resetForm.get('password').getError('required')">
                  <span class="ion-padding">La contraseña es requerida</span>
                </ion-text>

                <ion-text class="item-with-password" color="danger"
                  *ngIf="resetForm.get('password').touched && resetForm.get('password').getError('minlength')">
                  <span class="ion-padding">La contraseña debe tener al menos 6 carácteres</span>
                </ion-text>

                <ion-item lines="none" class="item-round" color="light">
                  <ion-input type="password" formControlName="repeatPassword" placeholder="Confirme contraseña"
                    oncopy="return false;" onpaste="return false;" ondrop="return false;" autocomplete="off"
                    (ionChange)="noWhiteSpaces($event, true)" class="input-lines"></ion-input>
                </ion-item>

                <ion-text class="item-with-error" color="danger"
                  *ngIf="resetForm.get('repeatPassword').touched && resetForm.get('repeatPassword').getError('required')">
                  <span class="ion-padding">Debe repetir la contraseña</span>
                </ion-text>

                <ion-text class="item-with-error" color="danger"
                  *ngIf="resetForm.get('repeatPassword').touched && resetForm.get('repeatPassword').getError('mustMatch')">
                  <span class="ion-padding">La contraseñas deben de coincidir</span>
                </ion-text>

                <ion-button type="submit" expand="block" color="primary"
                  [disabled]="!resetForm.valid === true || isLoading === true">
                  Actualizar contraseña
                </ion-button>

                <ion-button type="button" expand="block" color="primary" fill="clear" (click)="reset()">
                  <span class="underline-text">Intentar con otra cuenta</span>
                </ion-button>
              </form>
            </div>
          </div>









        </div>


      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>