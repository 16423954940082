import { Injectable } from '@angular/core';
import { DocumentData, Query, QuerySnapshot, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('FirebasePaginationService');

export interface WhereInterface {
  opStr: firebase.default.firestore.WhereFilterOp;
  fieldPath: string | firebase.default.firestore.FieldPath;
  value: any;
}
export interface DocsCollection {
  path: string;
  where?: WhereInterface[];
  orderBy?: { fieldPath: string | firebase.default.firestore.FieldPath; directionStr?: firebase.default.firestore.OrderByDirection };
  limit?: number;
  startAfter?: any;
  endBefore?: any;
  startAt?: any;
  endAt?: any;
}

@Injectable({
  providedIn: 'root'
})
export class FirebasePaginationService {
  constructor(
    public fireService: FirebaseService
  ) { }

  async getDocsCollection(params: DocsCollection): Promise<QuerySnapshot<DocumentData> | any> {
    try {
      let query: Query = this.fireService.afs.collection(params.path).ref;

      if (params.where) {
        for (let item of params.where) {
          if (item.value) {
            query = query.where(item.fieldPath, item.opStr, item.value);
          }
        }
      }

      if (params.orderBy) {
        query = query.orderBy(params.orderBy.fieldPath, params.orderBy.directionStr);
      }

      if (params.limit) {
        query = query.limit(params.limit);
      }

      if (params.startAt) {
        query = query.startAt(params.startAt);
      }

      if (params.endBefore) {
        query = query.endBefore(params.endBefore);
      }

      if (params.startAfter) {
        query = query.startAfter(params.startAfter);
      }

      if (params.endAt) {
        query = query.endAt(params.endAt);
      }

      return await query.get();
    } catch (e) {
      log.error(e);
    }
  }

  public async getDocData(path: string): Promise<firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData> | any> {
    try {
      return this.fireService.afs.doc(path).ref.get();
    } catch (e) {
      log.error(e);
    }
  }
}
