import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { ModalController } from '@ionic/angular';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { DocumentData, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { UserSelectorComponent } from '@app/shared/components/user-selector/user-selector.component';
import { Logger } from '@app/core/logger.service';
const log = new Logger('Add Group');
import * as moment from 'moment';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {
  public dataForm: FormGroup;
  item: any;
  isLoading: boolean;
  users: any[] = [];
  names: string[];
  idGroup: string;

  constructor(
    private formBuilder: FormBuilder,
    private fireService: FirebaseService,
    private utilities: UtilitiesService,
    private loader: IonLoaderService,
    public credService: CredentialsService,
    private modalCtrl: ModalController,
    private analyticsFB: AnalyticsService,
    private router: Router
  ) {
    this.init();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  init() {
    this.initForm();
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    if (this.idGroup) {
      try {
        const DOC: DocumentData = await this.fireService.afs
          .collection('broadcast-list')
          .doc(this.idGroup)
          .ref.get();
        const DATA: any = DOC.data();
        this.dataForm.get('name').setValue(DATA.name);
        this.dataForm.get('description').setValue(DATA.description);
      } catch (error) {
        log.error(error);
      }
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  async selectUser() {
    this.analyticsClickEvent('select_item', {
      items: 'Seleccione contacto'
    });
    let users: any[] = [];
    if (this.users) {
      users = this.users;
    }
    const modal = await this.modalCtrl.create({
      component: UserSelectorComponent,
      componentProps: { selectedUsers: users }
    });
    modal.onDidDismiss().then(users => {
      if (users.data) {
        this.users = users.data.users;
        this.names = users.data.names;
      }
    });
    modal.present();
  }

  public async updateDoc(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    let data = {
      description: this.dataForm.get('description').value,
      name: this.dataForm.get('name').value
    };
    try {
      await this.fireService.afs
        .collection('broadcast-list')
        .doc(this.idGroup)
        .update(data);
      this.closeModal();
      await this.utilities.toast('Grupo actualizado correctamente.', 'Correcto');
    } catch (error) {
      log.error(error);
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async addDoc(): Promise<void> {
    this.analyticsClickEvent('save_group', {
      type: 'Chat en grupo'
    });
    this.isLoading = true;
    const date = moment().toDate();
    let data = {
      createdAt: date,
      updatedAt: date,
      description: this.dataForm.get('description').value,
      name: this.dataForm.get('name').value,
      uid: this.credService.credentials.uid,
      nameStr: ''
    };
    data.nameStr = this.utilities.stringSearch(data.name);
    await this.loader.ionLoaderPresent();
    try {
      const response = await this.fireService.afs.collection('broadcast-list').add(data);
      const path = response.path;
      if (this.users) {
        const mapped = this.users.map((element: any) => {
          let dataUSER = {
            name: element.name,
            lastName1: element.lastName1,
            lastName2: element.lastName2,
            nameStr: '',
            firstCharacter: '',
            id: element.id
          };
          const nameStr = this.utilities.stringSearch(
            dataUSER.lastName1 + ' ' + dataUSER.lastName2 + ' ' + dataUSER.name,
            true
          );
          dataUSER.nameStr = nameStr;
          dataUSER.firstCharacter = nameStr.charAt(0) || '';
          return dataUSER;
        });
        const batch = this.fireService.afs.firestore.batch();
        mapped.forEach(element => {
          const ref = this.fireService.afs
            .doc(path)
            .collection('contacts')
            .doc(element.id).ref;
          batch.set(ref, element);
        });
        await batch.commit();
      }
      this.closeModal();
      await this.utilities.toast('Grupo creado correctamente.', 'Correcto');
    } catch (error) {
      log.error(error);
      this.utilities.toast('Ha ocurrido un error, por favor inténtalo mas tarde.', 'Error');
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async save(): Promise<void> {
    if (this.idGroup) {
      this.updateDoc();
    } else {
      this.addDoc();
    }
  }

  public async updateUsers() {
    const modal = await this.modalCtrl.create({
      component: UserSelectorComponent,
      componentProps: { idGroup: this.idGroup }
    });
    modal.present();
  }

  closeModal() {
    this.modalCtrl.dismiss({ modification: false });
  }
}
