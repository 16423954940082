import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SurveysRoutingModule } from './surveys-routing.module';
import { SurveysComponent } from './surveys.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule, IonicModule, SurveysRoutingModule],
  declarations: [SurveysComponent]
})
export class SurveysModule { }
