<ion-content>
  <!-- Edit buttons -->
  <ion-fab slot="fixed" vertical="top" horizontal="end" *ngIf="isMyProduct === true">
    <ion-fab-button color="light">
      <ion-icon name="ellipsis-vertical-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="bottom">
      <ion-fab-button color="tertiary" (click)="update()" [disabled]="isLoading === true">
        <ion-icon name="create-outline"></ion-icon>
      </ion-fab-button>
      <ion-fab-button color="danger" (click)="confirmDeleteProduct(id)" [disabled]="isLoading === true">
        <ion-icon name="trash-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button (click)="share()" color="secondary">
      <ion-icon name="share-social-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col size="12" offset="0" size-xl="8" offset-xl="2" size-lg="8" offset-lg="2" size-md="10" offset-md="1">
        <!-- **************** Medicamento **************** -->
        <ion-card *ngIf="item?.type === 'Medicamento'">
          <img *ngIf="image" [src]="image" class="img-product" />
          <img *ngIf="!image" src="/assets/img/no-product.PNG" class="img-product" />
          <ion-card-header>
            <ion-card-subtitle>Marca</ion-card-subtitle>
            <ion-card-title>{{ item?.marca }}</ion-card-title>
          </ion-card-header>
          <ion-card-header>
            <ion-card-subtitle>Tipo</ion-card-subtitle>
            <ion-card-title>{{ item?.type }} </ion-card-title>
          </ion-card-header>
          <ion-card-header *ngIf="item?.producto_sustancia_activa_1">
            <ion-card-subtitle>Sustancia activa principal</ion-card-subtitle>
            <ion-card-title>{{ item?.producto_sustancia_activa_1 }} </ion-card-title>
          </ion-card-header>
          <ion-card-header *ngIf="item?.producto_sustancia_activa_2">
            <ion-card-subtitle>Sustancia activa secundaria</ion-card-subtitle>
            <ion-card-title>{{ item?.producto_sustancia_activa_2 }} </ion-card-title>
          </ion-card-header>
          <ion-card-header>
            <ion-card-title>Línea </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            {{ item?.linea_terapeutica_1 }}
            <br />
            {{ item?.linea_terapeutica_2 }}
          </ion-card-content>
          <ion-card-header>
            <ion-card-title>Presentación </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            {{ item?.presentacion_1 }}
            <br />
            {{ item?.presentacion_2 }}
          </ion-card-content>
          <ion-card-content class="ion-text-center">
            <ion-chip color="secondary" [disabled]="isLoading == true" *ngIf="!(!fileURL || fileURL == '')"
              (click)="open();">
              <ion-icon name="list-circle-outline"></ion-icon>
              <ion-label>IPP</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" [disabled]="isLoading === true"
              *ngIf="availability === true && item?.allowMedicalSample === true" (click)="sampleRequest()">
              <ion-icon name="cube-outline"></ion-icon>
              <ion-label>Solicitar muestra</ion-label>
            </ion-chip>
          </ion-card-content>
          <app-i-rep-card [user]="user"></app-i-rep-card>
        </ion-card>
        <!-- **************** Dispositivo **************** -->
        <ion-card *ngIf="item?.type === 'Dispositivo'">
          <img *ngIf="image" [src]="image" class="img-product" />
          <img *ngIf="!image" src="/assets/img/no-product.PNG" class="img-product" />
          <ion-card-header>
            <ion-card-subtitle>Marca</ion-card-subtitle>
            <ion-card-title>{{ item?.marca }}</ion-card-title>
          </ion-card-header>
          <ion-card-header>
            <ion-card-subtitle>Tipo</ion-card-subtitle>
            <ion-card-title>{{ item?.type }} </ion-card-title>
          </ion-card-header>
          <ion-card-header *ngIf="item?.producto_sustancia_activa_1">
            <ion-card-subtitle>Producto</ion-card-subtitle>
            <ion-card-title>{{ item?.producto_sustancia_activa_1 }} </ion-card-title>
          </ion-card-header>
          <ion-card-header *ngIf="item?.producto_sustancia_activa_2">
            <ion-card-subtitle>Uso</ion-card-subtitle>
            <ion-card-title>{{ item?.producto_sustancia_activa_2 }} </ion-card-title>
          </ion-card-header>
          <ion-card-header>
            <ion-card-title>Línea </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            {{ item?.linea_terapeutica_1 }}
            <br />
            {{ item?.linea_terapeutica_2 }}
          </ion-card-content>
          <ion-card-header>
            <ion-card-title>Presentación </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            {{ item?.presentacion_1 }}
            <br />
            {{ item?.presentacion_2 }}
          </ion-card-content>
          <ion-card-content class="ion-text-center">
            <ion-chip color="secondary" [disabled]="isLoading == true" *ngIf="!(!fileURL || fileURL == '')"
              (click)="open();">
              <ion-icon name="list-circle-outline"></ion-icon>
              <ion-label>Ficha técnica</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" [disabled]="isLoading === true"
              *ngIf="availability === true && item?.allowMedicalSample === true" (click)="sampleRequest()">
              <ion-icon name="cube-outline"></ion-icon>
              <ion-label>Solicitar muestra</ion-label>
            </ion-chip>
          </ion-card-content>
          <app-i-rep-card [user]="user"></app-i-rep-card>
        </ion-card>
        <!-- **************** Servicio **************** -->
        <ion-card *ngIf="item?.type === 'Servicio'">
          <img *ngIf="image" [src]="image" class="img-product" />
          <img *ngIf="!image" src="/assets/img/no-product.PNG" class="img-product" />
          <ion-card-header>
            <ion-card-subtitle>Tipo de Servicio</ion-card-subtitle>
            <ion-card-title>{{ item?.marca }}</ion-card-title>
          </ion-card-header>
          <ion-card-header>
            <ion-card-subtitle>Tipo</ion-card-subtitle>
            <ion-card-title>{{ item?.type }} </ion-card-title>
          </ion-card-header>
          <ion-card-header>
            <ion-card-title>Descripción </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            {{ item?.producto_sustancia_activa_1 }}
            <br />
            {{ item?.producto_sustancia_activa_2 }}
            <br />
            {{ item?.linea_terapeutica_1 }}
          </ion-card-content>
          <ion-card-content class="ion-text-center">
            <ion-chip color="secondary" [disabled]="isLoading == true" *ngIf="!(!fileURL || fileURL == '')"
              (click)="open();">
              <ion-icon name="list-circle-outline"></ion-icon>
              <ion-label>Documentación detallada</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" [disabled]="isLoading === true"
              *ngIf="availability === true && item?.allowMedicalSample === true" (click)="sampleRequest()">
              <ion-icon name="cube-outline"></ion-icon>
              <ion-label>Solicitar muestra</ion-label>
            </ion-chip>
          </ion-card-content>
          <app-i-rep-card [user]="user"></app-i-rep-card>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>