import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CredentialsService } from '../../core/authentication/credentials.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { IonContent, ModalController, NavParams, AlertController, Platform } from '@ionic/angular';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { FilesService } from '@app/shared/services/files/files.service';
import { Device } from '@capacitor/device';
import { CameraSource } from '@capacitor/camera';
import { Browser } from '@capacitor/browser';
import { Logger } from '@app/core/logger.service';
import {
  AngularFirestoreCollection,
  QueryDocumentSnapshot,
  DocumentData,
  FirebaseService
} from '@app/shared/services/firebase/firebase.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { TextToLinkService } from '@app/shared/services/text-to-link/text-to-link.service';
import { EmailService } from '../services/email/email.service';
import { Router } from '@angular/router';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
const log = new Logger('ChatComponent');

interface Message {
  msg: string;
  convert?: boolean;
  isFile?: boolean;
  url?: string;
  notUrlFy?: boolean;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @ViewChild(IonContent, { read: IonContent, static: true }) content: IonContent;
  @ViewChild('file1', { read: ElementRef, static: true }) file1: ElementRef;
  @ViewChild('file2', { read: ElementRef, static: true }) file2: ElementRef;
  @Input() accepted: boolean;
  public message: string;
  public me: string;
  mainSubscribe: Subscription;
  fileToUpload1: any;
  fileToUpload2: any;
  messagesCollection: AngularFirestoreCollection;
  public messages: any[] = [];
  initialCursor: QueryDocumentSnapshot<DocumentData>;
  cursor: QueryDocumentSnapshot<DocumentData>;
  perPage = 10;
  isLoading: boolean;
  public chatId = this.navParams.get('id');
  public chatParticipants: any[] = this.navParams.get('participants');
  public other: any;
  private id: string;
  private chatData: any;
  path = 'chats';
  placeholder: string;
  htmlContent: string;
  resetEditor: number = 0;
  isLoad: boolean = false;

  constructor(
    private credService: CredentialsService,
    private modalCtrl: ModalController,
    private loader: IonLoaderService,
    private utilities: UtilitiesService,
    private fireService: FirebaseService,
    private navParams: NavParams,
    private filesService: FilesService,
    private analyticsFB: AnalyticsService,
    private alertCtrl: AlertController,
    private email: EmailService,
    private router: Router,
    private textToLink: TextToLinkService
  ) {
    moment.locale('es');
    this.me = this.credService.credentials.uid;
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  triggerClick1() {
    this.file1.nativeElement.click();
  }

  triggerClick2() {
    this.analyticsClickEvent('button_document', {});
    this.file2.nativeElement.click();
  }

  async selectFile1(data: any) {
    const file = data.files as FileList;
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response: any = await this.filesService.selectImageFile(file, `${this.path}/${this.chatId}`, 'chat');
      const url = response.main.url;
      const message = `<img alt="${url}" src="${url}" class="iab-disabled"/>`;
      await this.sendMessage({ msg: message, notUrlFy: true });
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async selectFile2(data: any) {
    const file = data.files as FileList;
    this.analyticsClickEvent('button_selectfile', {});
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const response = await this.filesService.selectAnyFile(file, `${this.path}/${this.chatId}`, [
        'doc',
        'docx',
        'ppt',
        'pptx',
        'xls',
        'xlsx',
        'pdf'
      ]);
      if (response && response.main && response.main.url) {
        const url = response.main.url;
        const nameMedia = response.main.path;
        const fullName = nameMedia.split('/')[2];
        await this.sendMessage({ msg: fullName, isFile: true, url: url });
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async takePicture(image?: CameraSource) {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      this.analyticsClickEvent('button_takePicture', {});
      const response = await this.filesService.takePicture(image, this.path, 'chat');
      const url = response.main.url;
      const message = `<img alt="${url}" src="${url}" class="iab-disabled"/>`;
      await this.sendMessage({ msg: message, notUrlFy: true });
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async imageFromGallery() {
    try {
      this.analyticsClickEvent('button_imagegallery', {});
      const info = await Device.getInfo();
      if (info && info.platform == 'web') {
        this.triggerClick1();
      } else {
        this.takePicture(CameraSource.Photos);
      }
    } catch (error) {
      this.utilities.toast('Ha ocurrido un error, intentalo mas tarde.', '¡Ups!');
      log.error(error);
    }
  }

  public member(id: string): any {
    const index: number = Array.from(this.chatData.members)
      .map((element: any) => String(element.id))
      .indexOf(id);
    if (index > -1) {
      return this.chatData.members[index];
    }
  }

  async loadData() {
    await this.loader.ionLoaderPresent();
    try {
      this.analyticsClickEvent('load_pastmessages', {});
      if (this.cursor && this.cursor.data() && this.cursor.data().date) {
        this.pagination(this.cursor.data().date);
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
  }

  async pagination(cursor: any) {
    const snapshot = await this.fireService.afs
      .collection('chats')
      .doc(this.id)
      .collection('messages')
      .ref.orderBy('date', 'desc')
      .startAfter(cursor)
      .limit(this.perPage)
      .get();
    if (snapshot.docs.length > 0) {
      this.cursor = snapshot.docs[snapshot.docs.length - 1];
      const messages: any[] = snapshot.docs.map(element => {
        const data: any = element.data();
        const dateStr = moment(data.date.toDate()).format('DD-MM-YYYY h:mm A');
        data.dateStr = dateStr;
        const id: string = element.id;
        return { id, ...data };
      });
      messages.forEach(item => {
        const index: number = this.messages.map(e => e.id).indexOf(item.id);
        if (!(index > -1)) {
          this.messages.unshift(item);
        }
      });
      this.setChatMetric(this.messages);
    }
  }

  closeModal() {
    this.analyticsClickEvent('close_chat', {});
    this.modalCtrl.dismiss();
  }

  public async sendMessage(m: Message): Promise<void> {
    this.analyticsClickEvent('send_message', {});
    if ((this.message && this.message !== '') || (m && m.msg && m.msg !== '')) {
      this.isLoading = true;
      await this.loader.ionLoaderPresent();
      try {
        const _message = m && m.msg && m.msg !== '' ? m.msg : this.message;
        await this.fireService.sendChatMessage(
          this.id,
          m && m.notUrlFy === true ? _message : this.textToLink.replace(_message),
          m.convert,
          m.isFile,
          m.url
        );
        this.resetEditor = this.resetEditor + 1;
      } catch (error) {
        log.error(error);
      }
      await this.loader.ionLoaderDismiss();
    }
  }

  ngOnInit() {
    this.init();
  }

  updateStatus() {
    const user: any = {};
    user[`last_message.viewers.${this.credService.credentials.uid}`] = {
      news: 0,
      seen: true
    };
    const doc = this.fireService.afs.collection('chats').doc(this.id);
    doc.update(user);
  }

  ionViewWillLeave() {
    try {
      if (this.mainSubscribe) {
        this.mainSubscribe.unsubscribe();
      }
      this.updateStatus();
    } catch (error) {
      log.error(error);
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      this.content.scrollToBottom(100);
    }, 800);
  }

  private async init(): Promise<void> {
    log.debug("=============== CHAT ID ===============",this.id);
    this.message = this.placeholder && this.placeholder !== '' ? String(this.placeholder) : undefined;
    this.updateStatus();
    this.chatData = (
      await this.fireService.afs
        .collection('chats')
        .doc(this.id)
        .ref.get()
    ).data();
    this.messagesCollection = this.fireService.afs
      .collection('chats')
      .doc(this.id)
      .collection('messages', ref => ref.orderBy('date', 'desc').limit(1));

    this.chatParticipants = this.chatData.participants;
    for (const elem of this.chatData.members) {
      const userData = await this.fireService.getUserData(elem.uid);
      const elemData = {
        avatar:
          userData.avatar && userData.avatar.thumbnail && userData.avatar.thumbnail.url
            ? userData.avatar.thumbnail.url
            : null,
        nombre: `${userData.name} ${userData.lastName1}${userData.lastName2 ? ' ' + userData.lastName2 : ''}`,
        email: userData.email,
        type: userData.type,
        uid: userData.uid
      };
      this.chatParticipants[elem.uid] = elemData;
    }
    const otherId = this.chatParticipants.filter(elem => {
      if (elem !== this.me) {
        return elem;
      }
    });
    this.other = await this.fireService.getUserData(otherId[0]);
    this.mainSubscribe = this.messagesCollection.snapshotChanges().subscribe(async snapshot => {
      if (snapshot.length > 0) {
        if (this.initialCursor === undefined) {
          this.initialCursor = snapshot[snapshot.length - 1].payload.doc;
          await this.pagination(this.initialCursor.data().date);
          this.scrollToBottom();
        }
      }
      snapshot.forEach(element => {
        const data: any = element.payload.doc.data();
        const dateStr = moment(data.date.toDate()).format('DD-MM-YYYY h:mm A');
        data.dateStr = dateStr;
        const id: string = element.payload.doc.id;
        const index: number = this.messages.map(e => e.id).indexOf(id);
        if (!(index > -1)) {
          this.messages.push({ id, ...data });
        }
      });
      this.setChatMetric(this.messages);
      this.scrollToBottom();
    });
    this.isLoad = true;
  }

  setChatMetric(array: any[]) {
    for (const element of array) {
      if (
        element &&
        element.chat_metric_doc_id !== '' &&
        element.chat_metric_doc_id !== null &&
        element.chat_metric_doc_id !== undefined
      ) {
        //Chat Metric Collection
        try {
          this.fireService.afs
            .collection('chat-metrics')
            .doc(element.chat_metric_doc_id)
            .update({
              viewed: true,
              dateViewed: moment().toDate()
            });
          const analytics = {
            chat_id: this.id,
            message_id: element.id,
            label: element.metric_label,
            to: this.credService.credentials.uid,
            sender: element.sender
          };
          this.analyticsClickEvent('chat_campaign_message_open', analytics);
        } catch (error) {
          log.error(error);
        }
        //Chat Message Collection
        try {
          this.fireService.afs
            .collection('chats')
            .doc(this.id)
            .collection('messages')
            .doc(element.id)
            .update({
              chat_metric_doc_id: null
            });
        } catch (error) {
          log.error(error);
        }
        element.chat_metric_doc_id = null;
      }
    }
    return;
  }

  public async openExternal(url: string, isFile: any) {
    this.analyticsClickEvent('file_download', {
      file_name: isFile.message,
      link_text: isFile.message,
      link_url: url
    });
    await Browser.open({ url: url });
  }

  async reportUser() {
    const alert = await this.alertCtrl.create({
      header: 'Reportar',
      message: 'Lamentamos que estes pasando una mala experiencia, por favor especificanos el motivo de tu reporte:',
      inputs: [
        {
          name: 'comment',
          type: 'text',
          placeholder: 'Razón del reporte'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Reportar',
          handler: async (data: any) => {
            this.analyticsClickEvent('report_user', {
              reported_user: `usuario: ${this.other.nombre}, uid: ${this.other.uid}`
            });
            await this.email.reportUser(this.other.nombre, this.other.email, data.comment);
          }
        }
      ]
    });
    alert.present();
  }

  goToContactDetail() {
    this.analyticsClickEvent('user_detail', {
      content_type: this.other.nombre
    });
    this.router.navigateByUrl('user/details/' + this.other.uid);
    this.modalCtrl.dismiss();
  }

  keyupHandler(event: any) {
    if (event == '' || event == undefined || event == null) {
      this.message = '';
    } else {
      this.message = event;
    }
  }

}
