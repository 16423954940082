import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { from } from 'rxjs';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { NoWhiteSpaceValidator } from '../../validators/noWhiteSpace.validator';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { Phone } from '@app/shared/services/lada/lada.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { AddLocationComponent } from '../add-location/add-location.component';
import { Router } from '@angular/router';
const log = new Logger('Doctor form');

export interface PersonalData {
  name: string;
  lastName1: string;
  lastName2: string;
  mobile: string;
  phone: string;
}

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-doctor-form',
  templateUrl: './doctor-form.component.html',
  styleUrls: ['./doctor-form.component.scss']
})
export class DoctorFormComponent implements OnInit {
  @Input() user: UserData;
  @Input() modeData: string;
  @Input() userType: string;
  @Output() response = new EventEmitter<void>();
  @Input() button = 'Siguiente';
  specialties: any[];
  public dataForm: FormGroup;
  public isLoading: boolean;
  address = {
    street: '',
    outsideNumber: '',
    interiorNumber: '',
    postalCode: '',
    colony: '',
    city: '',
    state: '',
    delegation: '',
    hospital: '',
    tower: '',
    strState: ''
  };

  phone: Phone = {
    number: null,
    lada: '52',
    color: 'light',
    country: 'MEXICO',
    error: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private fireService: FirebaseService,
    private utilities: UtilitiesService,
    private alertCtrl: AlertController,

    private modalCtrl: ModalController,
    private analyticsFB: AnalyticsService,
    public credService: CredentialsService,
    private router: Router,
    private specialtiesService: SpecialtiesService
  ) {
    this.initSpecialties();
  }

  async addLocation(): Promise<any> {
    const alert = await this.alertCtrl.create({
      header: 'No se encontró código postal',
      message: '¿Desea agregar la locación manualmente?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.dataForm.get('postalCode').setValue('');
            this.dataForm.get('postalCode').reset();
          }
        },
        {
          text: 'Agregar',
          handler: async () => {
            const modal = await this.modalCtrl.create({
              component: AddLocationComponent,
              componentProps: {
                zipcode: String(this.dataForm.get('postalCode').value),
                uid: this.user.uid
              }
            });
            modal.onDidDismiss().then(resp => {
              if (resp && resp.data && resp.data.location) {
                this.address.state = resp.data.location.state;
                this.address.delegation = resp.data.location.delegation;
                this.address.colony = resp.data.location.colony;
                this.address.city = resp.data.location.city;
              }
            });
            modal.present();
          }
        }
      ]
    });
    alert.present();
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  ngOnInit() {
    this.initForm();
    this.postalCode();
    this.initData();
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', Validators.required],
      lastName1: ['', Validators.required],
      lastName2: [''],
      mobile: [
        '',
        [Validators.required, Validators.maxLength(12), Validators.minLength(10), Validators.pattern(/^[1-9]+[0-9]*$/)]
      ],
      specialty1: ['', Validators.required],
      postalCode: ['', [Validators.required]],
      professionalID1: ['', [Validators.required, NoWhiteSpaceValidator.isValid]]
    });
  }

  refillForm(data: any) {
    this.dataForm.setValue({
      name: data && data.name ? data.name : '',
      lastName1: data && data.lastName1 ? data.lastName1 : '',
      lastName2: data && data.lastName2 ? data.lastName2 : '',
      mobile: data && data.mobile ? data.mobile : '',
      postalCode: data && data.address1 && data.address1.postalCode ? data.address1.postalCode : '',
      specialty1: data && data.specialty1 && data.specialty1.id ? Number(data.specialty1.id) : '',
      professionalID1: data && data.specialty1 && data.specialty1.cedula ? data.specialty1.cedula : ''
    });
  }

  public async initData(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      let data1 = await this.fireService.getUserData(this.user.uid);
      let data2 = await this.fireService.getDoctorData(this.user.uid);
      const data = { ...data1, ...data2 };
      this.refillForm(data);
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  public async submit(): Promise<void> {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const name = this.dataForm.get('name').value;
      const lastName1 = this.dataForm.get('lastName1').value;
      const lastName2 = this.dataForm.get('lastName2').value;
      const fullName = name + ' ' + lastName1 + ' ' + lastName2;
      const search = (await this.utilities.arraySearchRequest(fullName)) || [];
      const nameStr = this.utilities.stringSearch(
        this.dataForm.get('lastName1').value +
        ' ' +
        this.dataForm.get('lastName2').value +
        ' ' +
        this.dataForm.get('name').value,
        true
      );
      await this.fireService.updateUser(this.user.uid, {
        name: this.dataForm.get('name').value,
        lastName1: this.dataForm.get('lastName1').value,
        lastName2: this.dataForm.get('lastName2').value,
        nameStr: nameStr,
        firstCharacter: nameStr.charAt(0) || '',
        mobile: this.dataForm.get('mobile').value,
        mobileLada: {
          lada: this.phone.lada,
          country: this.phone.country
        },
        search: search
      });
      const strState: string = this.utilities.stringSearch(this.address.state);
      this.address.strState = strState;
      this.address.postalCode = this.dataForm.get('postalCode').value;
      await this.fireService.updateDoctor(this.user.uid, {
        specialty1: {
          id: Number(this.dataForm.get('specialty1').value),
          cedula: this.dataForm.get('professionalID1').value
        },
        address1: this.address
      });
      if (this.modeData === 'register' && this.userType === 'medico') {
        // this.email.userRegistrationWelcome(this.user.email, fullName);
        this.fireService.callFunctionFB('sendSms', {
          sender: 'Conectimed',
          recipient: this.dataForm.get('mobile').value,
          content:
            'Bienvenido a la red de medicos Conectimed, muchas gracias por registrarse.' +
            ' Recibira un email que confirma su registro.' +
            ' Invite a sus colegas compartiendo el siguiente mensaje.',
          tag: 'Welcome',
          webUrl: 'https://app.conectimed.com'
        });
        this.fireService.callFunctionFB('sendSms', {
          sender: 'Conectimed',
          recipient: this.dataForm.get('mobile').value,
          content:
            'Te recomiendo la plataforma Conectimed exclusiva para medicos. Ve al link\n' +
            'https://bit.ly/2U8j41p\n' +
            'y regístrese sin costo',
          tag: 'Share',
          webUrl: 'https://app.conectimed.com'
        });
      }
      if (this.modeData === 'register') {
        this.analyticsClickEvent('click', {
          content_type: 'Información personal',
          section_name: 'Registro de usuario',
          user_type: this.userType
        });
      }
      this.response.emit();
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async initSpecialties(): Promise<void> {
    try {
      this.specialties = await this.specialtiesService.getSpecialties();
    } catch (error) {
      log.error(error);
    }
  }

  async postalCode() {
    try {
      await this.dataForm.get('postalCode').valueChanges.subscribe(async (value: string) => {
        this.isLoading = true;
        if (this.address.state !== null) {
          this.address.state = '';
        }
        if (this.address.delegation !== null) {
          this.address.delegation = '';
        }
        if (this.address.colony !== null) {
          this.address.colony = '';
        }
        if (this.address.city !== null) {
          this.address.city = '';
        }
        if (value && value.toString().length > 4) {
          const resp = await this.fireService.afs
            .collection('zipcodes')
            .doc(value)
            .ref.get();
          if (resp.exists === true) {
            const response = await this.fireService.afs
              .collection('zipcodes')
              .doc(value)
              .collection('colonies')
              .ref.get();
            const filteredColonies: any[] = Array.from(response.docs.map(e => e.data()));
            if (filteredColonies.length == 0) {
              this.addLocation();
            }
            if (filteredColonies.length > 0 && filteredColonies.length < 2) {
              this.address.state = filteredColonies[0].state;
              this.address.delegation = filteredColonies[0].city;
              this.address.colony = filteredColonies[0].colony;
              if (filteredColonies[0].state === 'Ciudad de México') {
                this.address.city = 'Ciudad de México';
              } else {
                this.address.city = filteredColonies[0].city;
              }
            }
            if (filteredColonies.length > 1) {
              const inputs: any[] = [];
              this.address.state = filteredColonies[0].state;
              this.address.delegation = filteredColonies[0].city;

              filteredColonies.forEach((colony, index) => {
                inputs.push({
                  value: index,
                  label: colony.colony,
                  type: 'radio'
                });
              });

              const alert = await this.alertCtrl.create({
                header: 'Seleciona una colonia',
                subHeader: `Hay ${filteredColonies.length} colonias que comparten el mismo código postal selecciona una por favor.`,
                inputs,
                buttons: [
                  {
                    text: 'Cancelar',
                    cssClass: 'ion-text-danger',
                    role: 'cancel',
                    handler: () => {
                      this.address.state = '';
                      this.address.delegation = '';
                      this.address.colony = '';
                      this.address.city = '';
                      this.dataForm.get('postalCode').setValue('');
                      this.dataForm.get('postalCode').reset();
                    }
                  },
                  {
                    text: 'Aceptar',
                    handler: (data: any) => {
                      if (data !== undefined) {
                        const selectedColony = filteredColonies[data];
                        this.address.colony = selectedColony.colony;
                        if (selectedColony.state === 'Ciudad de México') {
                          this.address.city = 'Ciudad de México';
                        } else {
                          this.address.city = selectedColony.city;
                        }
                      } else {
                        this.address.state = '';
                        this.address.delegation = '';
                        this.address.colony = '';
                        this.address.city = '';
                      }
                    }
                  }
                ]
              });
              alert.present();
            }
          } else {
            this.addLocation();
          }
        }
        this.isLoading = false;
      });
    } catch (error) {
      log.error(error);
    }
  }

  phoneHasError(ev: boolean) {
    if (ev) {
      this.phone.color = 'light';
      this.phone.error = false;
    } else {
      this.phone.color = 'danger';
      this.phone.error = true;
    }
  }
}
