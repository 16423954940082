import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ChatComponent } from '@app/shared/chat/chat.component';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {
  type: string;
  data: any;

  constructor(
    private router: Router,
    private ARoute: ActivatedRoute,
    private modalCtrl: ModalController,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.ARoute.params.subscribe(params => {
      this.type = String(params.type);
      this.data = params.id;
      this.presentModal(this.type, this.data);
    });
  }

  async presentModal(type: string, base64: any) {
    let component: any;
    let componentProps: any;
    let isModal: boolean = true;
    const data = atob(String(base64));
    switch (type) {
      case 'chat':
        component = ChatComponent;
        componentProps = { id: String(data) };
        isModal = true;
        break;
      case 'sample-request':
        component = ChatComponent;
        componentProps = { id: String(data) };
        isModal = true;
        break;
      case 'add-click-repre':
        this.navCtrl.navigateForward(`/user/details/${data}`);
        isModal = false;
        break;
      case 'add-click-product':
        this.navCtrl.navigateForward(`/user/details/${data}`);
        isModal = false;
        break;
    }
    if (isModal == true) {
      const modal = await this.modalCtrl.create({
        component: component,
        componentProps: componentProps,
        backdropDismiss: false
      });
      modal.present();
      this.router.navigate(['/home']);
      return;
    }
  }
}
