import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
})
export class RichTextComponent implements OnInit {
  content = '';
  @Input() elementId: string;
  @Input() contentHtml: string;
  @Output() onEditorContentChange = new EventEmitter();
  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;
  isReady: boolean = false;

  quillModules = {
    toolbar: {
      container: [['undo', 'redo'], ['bold', 'italic', 'underline']],
      handlers: {
        'undo': () => this.editor.quillEditor.history.undo(),
        'redo': () => this.editor.quillEditor.history.redo(),
      }
    },
    history: {
      userOnly: true
    }
  };

  constructor() { }

  ngOnInit() { }

  @Input()
  set resetEditor(value: number) {
    if (value) {
      this.content = '';
      this.onEditorContentChange.emit('');
    }
  }

  contentChange(ev: any) {
    this.onEditorContentChange.emit(ev.html);
  }
}
