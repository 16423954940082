import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpService, HttpHeaders } from '@app/shared/services/http/http.service';
import { environment } from '@env/environment';
import { Logger } from '@app/core/logger.service';
const log = new Logger('EmailNotExistService');
@Injectable({
  providedIn: 'root'
})
export class EmailNotExistService {

  constructor(private http: HttpService) { }

  checkIfEmailExists(email: string): Observable<boolean> {
    const resp = this.getUserByEmail(email);
    const observable = from(resp.then((user: any) => {
      if (user && user.email && user.uid) {
        return true;
      } else {
        return false;
      }
    }).catch(() => false))
    return observable;
  }

  public async getUserByEmail(email: string): Promise<any> {
    try {
      const headers: HttpHeaders = {
        'Content-Type': 'application/json',
        'private-key': environment.CUSTOM_SECURITY.private_key,
        'public-key': environment.CUSTOM_SECURITY.public_key
      };
      const resp: any = await this.http
        .post(
          `${environment.FIREBASE_API}getAuthUserByEmailRequest`,
          {
            email: email,
            info: false
          },
          { headers: headers }
        );
      if (resp) {
        return resp;
      } else {
        return undefined;
      }
    } catch (error) {
      log.error(error);
      return {};
    }
  }
}
