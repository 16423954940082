import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-modal-image',
  templateUrl: './info-modal-image.component.html',
  styleUrls: ['./info-modal-image.component.scss'],
})
export class InfoModalImageComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
