<ion-content no-bounce color="light">
  <ion-text color="primary" class="ion-text-center">
    <h1>¡Bienvenido!</h1>
  </ion-text>
  <form [formGroup]="dataForm" (ngSubmit)="beforeRegister()" class="ion-text-center">
    <ion-grid [fixed]="true">
      <ion-row>
        <ion-col sizeXs="12" sizeMd="6" *ngIf="formType === 'register'">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="mail-outline"></ion-icon>
            <ion-input type="text" formControlName="email" placeholder="Correo electrónico"
              (ionInput)="emailChange($event)"></ion-input>
          </ion-item>
          <ion-text class="item-with-error" color="danger"
            *ngIf="dataForm.get('email').touched && dataForm.get('email').getError('required')">
            <span class="ion-padding">El email es requerido</span>
          </ion-text>
          <ion-text class="item-with-error" color="danger" *ngIf="
                        dataForm.get('email').touched &&
                        dataForm.get('email').getError('pattern') &&
                        !dataForm.get('email').getError('required')
                      ">
            <span class="ion-padding">El correo electrónico es incorrecto</span>
          </ion-text>
          <ion-text class="item-with-error" color="danger" *ngIf="dataForm.get('email').hasError('emailAlreadyExists')">
            <p>El email ya esta registrado a una cuenta.</p>
            <ion-text color="secondary">
              <p> ¿Desea ingresar a su cuenta?</p>
            </ion-text>
            <ion-button (click)="modalLogin()" color="secondary" fill="outline">
              <ion-icon slot="start" name="log-in-outline"></ion-icon>
              Ingresar
            </ion-button>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="person-outline"></ion-icon>
            <ion-input type="text" formControlName="name" placeholder="Nombre"></ion-input>
          </ion-item>
          <ion-text color="danger" *ngIf="dataForm.get('name').touched && dataForm.get('name').getError('required')">
            <span class="ion-padding">El nombre es requerido</span>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="person-outline"></ion-icon>
            <ion-input type="text" formControlName="lastName1" placeholder="Apellido paterno"></ion-input>
          </ion-item>
          <ion-text color="danger"
            *ngIf="dataForm.get('lastName1').touched && dataForm.get('lastName1').getError('required')">
            <span class="ion-padding">El Apellido paterno es requerido</span>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="person-outline"></ion-icon>
            <ion-input type="text" formControlName="lastName2" placeholder="Apellido materno"></ion-input>
          </ion-item>
          <ion-text color="danger"
            *ngIf="dataForm.get('lastName2').touched && dataForm.get('lastName2').getError('required')">
            <span class="ion-padding">El Apellido materno es requerido</span>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6">
          <ion-progress-bar *ngIf="isLoading === true" type="indeterminate"></ion-progress-bar>
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="navigate-outline"></ion-icon>
            <ion-input placeholder="Código postal" type="tel" formControlName="postal_code">
            </ion-input>
          </ion-item>
          <ion-text class="item-with-error" color="danger"
            *ngIf="dataForm.get('postal_code').touched && dataForm.get('postal_code').getError('required')">
            <span class="ion-padding">El código postal es requerido</span>
          </ion-text>
          <ion-text class="item-with-error" color="danger"
            *ngIf="dataForm.get('postal_code').touched && dataForm.get('postal_code').getError('pattern')">
            <span class="ion-padding">Ingrese un código postal válido</span>
          </ion-text>
          <ion-text color="medium">
            <!-- <small *ngIf="dataForm?.get('state')?.value === 'Ciudad de México'; else provincia">
              {{
              (dataForm?.get('colony')?.value !== null ? dataForm.get('colony').value + ', ' : '') +
              (dataForm?.get('delegation')?.value !== null ? dataForm.get('delegation').value + ', ' : '') +
              (dataForm?.get('state')?.value !== null ? dataForm.get('state').value : '')
              }}
            </small> -->
            <small *ngIf="dataForm?.get('state')?.value === 'Ciudad de México'; else provincia">
              {{
              (dataForm?.get('delegation')?.value !== null ? dataForm.get('delegation').value + ', ' : '') +
              (dataForm?.get('state')?.value !== null ? dataForm.get('state').value : '')
              }}
            </small>
            <ng-template #provincia>
              <!-- <small>
                {{
                (dataForm?.get('colony')?.value !== null ? dataForm.get('colony').value + ', ' : '') +
                (dataForm?.get('city')?.value !== null ? dataForm.get('city').value + ', ' : '') +
                (dataForm?.get('state')?.value !== null ? dataForm.get('state').value : '')
                }}
              </small> -->
              <small>
                {{
                (dataForm?.get('city')?.value !== null ? dataForm.get('city').value + ', ' : '') +
                (dataForm?.get('state')?.value !== null ? dataForm.get('state').value : '')
                }}
              </small>
            </ng-template>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="medical-outline"></ion-icon>
            <ion-input placeholder="Especialidad" type="text" [value]="specialtyLabel" [readonly]="true"
              (click)="presentModalSpecialty()">
            </ion-input>
            <!-- <ion-select label="Especialidad" formControlName="specialty" placeholder="Especialidad" okText="Ok"
              cancelText="Cancelar" autofocus="true" (click)="checkSpecialties()"
              (ngModelChange)="changeSpecialty($event)">
              <ion-select-option *ngFor="let item of specialties" [value]="item?.id">{{ item?.name }}
              </ion-select-option>
              <ion-select-option value="profesional-de-la-salud">Otro</ion-select-option>
            </ion-select> -->
          </ion-item>
          <ion-text color="danger"
            *ngIf="dataForm.get('specialty').touched && dataForm.get('specialty').getError('required')">
            <span class="ion-padding">La especialidad profesional es requerida</span>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6" *ngIf="dataForm?.controls?.specialty?.value === 'profesional-de-la-salud'">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="pulse-outline"></ion-icon>
            <ion-select [interfaceOptions]="{backdropDismiss: false}" #typesOfHealthProfessionalsSelect
              label="Profesional de la salud" formControlName="healthProfessionalType"
              placeholder="Tipo de profesional de salud" okText="Ok" cancelText="Cancelar" autofocus="true">
              <ion-select-option *ngFor="let item of typesOfHealthProfessionals" [value]="item?.id">{{ item?.name
                }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-text color="danger"
            *ngIf="dataForm.get('healthProfessionalType').touched && dataForm.get('healthProfessionalType').getError('required')">
            <span class="ion-padding">El tipo de profesional de salud es requerido</span>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="lock-closed-outline"></ion-icon>
            <ion-input type="text" formControlName="cedula" placeholder="Cédula profesional"></ion-input>
          </ion-item>
          <ion-text color="danger"
            *ngIf="dataForm.get('cedula').touched && dataForm.get('cedula').getError('required')">
            <span class="ion-padding">La cédula profesional es requerida</span>
          </ion-text>
        </ion-col>

        <ion-col sizeXs="12" sizeMd="6">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="phone-portrait-outline"></ion-icon>
            <!-- <ion-select label="País" slot="start" formControlName="mobileLada" placeholder="Selecione el país">
              <ion-select-option *ngFor="let item of lada?.countries" [value]="item?.lada">
                {{ item?.country }} ({{ item?.lada }})
              </ion-select-option>
            </ion-select> -->
            <ion-input formControlName="mobile" placeholder="Teléfono móvil" type="tel" inputmode="tel"></ion-input>
          </ion-item>
          <ion-text class="item-with-error" color="danger" *ngIf="phone?.error">
            <span class="ion-padding">El teléfono móvil no es válido</span>
          </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6" *ngIf="formType === 'register'">
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="key-outline"></ion-icon>
            <ion-input type="password" formControlName="password" placeholder="Crear contraseña"></ion-input>
          </ion-item>
          <ion-text class="item-with-error" color="danger"
            *ngIf="dataForm.get('password').touched && dataForm.get('password').getError('required')">
            <span class="ion-padding">La contraseña es requerida</span>
          </ion-text>
          <ion-text class="item-with-password" color="danger"
            *ngIf="dataForm.get('password').touched && dataForm.get('password').getError('minlength')">
            <span class="ion-padding">La contraseña debe tener al menos 6 carácteres</span>
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="formType === 'register'">
        <ion-col size="12">
          <div class="custom-flex">
            <ion-checkbox formControlName="termsAndConditions" style="top: 5px;"></ion-checkbox>
          </div>
          <div class="custom-flex">
            <p>Acepto <a (click)="termOfUseComponent()">Términos y Condiciones</a></p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-button type="submit" color="primary" size="large" [disabled]="
        (formType === 'register' &&
          (!dataForm.valid === true || !dataForm.value.termsAndConditions === true || isLoading === true)) ||
        (formType === 'complete-registration' && (!dataForm.valid === true || isLoading === true))
      ">
      {{ formType === 'register' ? 'Enviar' : 'Continuar' }}
    </ion-button>
  </form>
</ion-content>