<ion-header>
  <ion-toolbar>
    <!-- <ion-title class="ion-text-center"> {{ footer === true ? 'Por favor acepte los nuevos términos y condiciones':'Términos y Condiciones'}}</ion-title> -->
    <ion-buttons *ngIf="!footer === true" slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed class="post-detail">
    <ion-row>
      <ion-col size="12">
        <div [innerHTML]="item?.content"></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer *ngIf="footer === true">
  <ion-toolbar>
    <ion-title></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="accept()" color="primary" fill="solid">
       <strong>&nbsp;&nbsp;&nbsp;Acepto&nbsp;&nbsp;&nbsp;</strong> 
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>