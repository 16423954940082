import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MustMatch } from '@app/shared/helpers/must-match.validator';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('Change password component');

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('passwordEyeRegister1', { static: false, read: ElementRef }) passwordEye1: ElementRef;
  @ViewChild('passwordEyeRegister2', { static: false, read: ElementRef }) passwordEye2: ElementRef;
  passwordTypeInput1 = 'password';
  passwordTypeInput2 = 'password';
  verificationCodeForm!: FormGroup;
  isLoading = false;

  constructor(
    private loader: IonLoaderService,
    private utilities: UtilitiesService,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    public credService: CredentialsService
  ) {
    this.createForm();
  }

  togglePasswordMode1() {
    //cambiar tipo input
    this.passwordTypeInput1 = this.passwordTypeInput1 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye1.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  togglePasswordMode2() {
    //cambiar tipo input
    this.passwordTypeInput2 = this.passwordTypeInput2 === 'text' ? 'password' : 'text';
    //obtener el input
    const nativeEl = this.passwordEye2.nativeElement.querySelector('input');
    //obtener el indice de la posición del texto actual en el input
    const inputSelection = nativeEl.selectionStart;
    //ejecuto el focus al input
    nativeEl.focus();
    //espero un milisegundo y actualizo la posición del indice del texto
    setTimeout(() => {
      nativeEl.setSelectionRange(inputSelection, inputSelection);
    }, 1);
  }

  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  reauthenticate(currentPassword: string) {
    // var user = firebase.auth().currentUser;
    // var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    // return user.reauthenticateWithCredential(cred);
    log.debug(currentPassword);
  }

  async changePasswordHandler(currentPassword: string, newPassword: string) {
    // await this.reauthenticate(currentPassword);
    // var user = firebase.auth().currentUser;
    // await user.updatePassword(newPassword);
    log.debug(currentPassword);
    log.debug(newPassword);
  }

  async changePassword() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      const email = this.credService.credentials.email;
      if (email) {
        try {
          await this.changePasswordHandler(
            this.verificationCodeForm.get('password').value,
            this.verificationCodeForm.get('password1').value
          );
          this.utilities.toast('Contraseña modificada correctamente.', 'Correcto');
        } catch (error) {
          this.utilities.toast(
            'Lo sentimos, ha ocurrido un error, revisa que la contraseña actual sea la correcta.',
            'Error'
          );
          log.error(error);
        }
      } else {
        this.utilities.toast('Lo sentimos, ha ocurrido un error, inténtelo mas tarde.', 'Error');
      }
    } catch (error) {
      this.utilities.toast(
        'Lo sentimos, ha ocurrido un error, revisa que la contraseña actual sea la correcta.',
        'Error'
      );
      log.error(error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  private createForm() {
    this.verificationCodeForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(6)]],
        password1: ['', [Validators.required, Validators.minLength(6)]],
        password2: ['', Validators.required]
      },
      {
        validator: MustMatch('password1', 'password2')
      }
    );
  }
}
