<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> Reporte </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="dataForm" (ngSubmit)="sendEmail()">
          <ion-item lines="none">
            <ion-text>
              <p><strong>Lamentamos la mala experiencia que esto haya provocado</strong></p>
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap"
              >Por favor específica la razón del reporte, queremos mejor tu experiencia, es importante para
              nosotros</ion-label
            >
          </ion-item>
          <ion-item>
            <ion-textarea rows="6" formControlName="comments" placeholder="Comentarios (opcional)..."></ion-textarea>
          </ion-item>
          <ion-button type="submit" color="tertiary" expand="block">
            Enviar reporte
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
