import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LightboxComponent } from './lightbox.component';

@NgModule({
  declarations: [LightboxComponent],
  exports: [LightboxComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule, IonicModule, RouterModule]
})
export class LightboxModule { }
