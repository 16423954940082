<ion-grid [fixed]="false" class="ion-text-center">
  <ion-row class="ion-align-items-center ion-justify-content-center">
    <ion-col size="auto" class="ion-text-center">
      <ion-avatar>
        <img *ngIf="user?.avatar && user?.avatar?.thumbnail" [src]="user?.avatar?.thumbnail?.url" />
        <img *ngIf="!user?.avatar || !user?.avatar?.thumbnail" src="/assets/img/default-representante-medico.jpg" />
      </ion-avatar>
    </ion-col>
    <ion-col size="auto" class="ion-text-center">
      <ion-text color="dark">
        <h2> {{ user?.name }} {{ user?.lastName1 ? user?.lastName1 : '' }} {{ user?.lastName2 ? user?.lastName2 : '' }}
        </h2>
      </ion-text>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button [disabled]="isMyProduct" [routerLink]="['/user/details/' + user?.uid]" (click)="analyticsClickEvent('click', {
        content_type: 'Ir a perfil representante',
        click_text: 'Ver representante',
        click_URL: '/user/details/' + user?.uid,
        section_name: 'Productos'
      });" shape="round">
        <ion-icon slot="end" name="briefcase-outline"></ion-icon>
        Representante
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>