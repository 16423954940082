import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { UserDetailViewComponent } from './user-detail-view.component';
import { PullToRefreshModule } from './../components/pull-to-refresh/pull-to-refresh.module';

@NgModule({
  declarations: [UserDetailViewComponent],
  exports: [UserDetailViewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    IonicModule,
    RouterModule,
    PullToRefreshModule
  ]
})
export class UserDetailViewModule { }
