<ion-content [scrollEvents]="true">
  <ion-grid [fixed]="false">
    <ion-row>
      <ion-col size="12">
        <ion-card class="ion-no-padding ion-no-margin">
          <ion-card-content>
            <!-- <ion-segment *ngIf="userType === 'representante-medico'">
              <ion-segment-button checked value="chat-list" (click)="segmentButtonClicked($event)"
                [disabled]="isLoading == true">
                <ion-label>Chats</ion-label>
              </ion-segment-button>
              <ion-segment-button value="group" (click)="segmentButtonClicked($event)" [disabled]="isLoading == true">
                <ion-label>Grupos</ion-label>
              </ion-segment-button>
            </ion-segment> -->

            <ion-toolbar *ngIf="segment === 'chat-list'">
              <ion-buttons slot="start">
                <ion-button (click)="filter($event)" [color]="item ? 'secondary' : 'dark'">
                  Filtrar
                  <ion-icon slot="start" name="filter-outline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>

            <ion-list *ngIf="segment === 'chat-list' || userType === 'medico'">
              <ion-item *ngIf="!(chats && chats[0])">
                <ion-label>
                  Sin mensajes
                </ion-label>
              </ion-item>
              <ion-item-sliding *ngFor="let item of chats">
                <ion-item button (click)="goChat(item?.id, item?.accepted)" ion-button [disabled]="isLoading == true"
                  [color]="myStatus(item) > 0 ? 'stablegreen' : 'light'" [detail]="false">
                  <ion-avatar slot="start" class="responsive">
                    <img *ngIf="item?.userDetected?.type !== 'medico'" [src]="
                          item?.userDetected &&
                          item?.userDetected &&
                          item?.userDetected.avatar &&
                          item?.userDetected.avatar !== ''
                            ? item?.userDetected.avatar
                            : '../../assets/img/default-representante-medico.jpg'
                        " />
                    <img *ngIf="item?.userDetected?.type === 'medico'" [src]="
                          item?.userDetected &&
                          item?.userDetected &&
                          item?.userDetected.avatar &&
                          item?.userDetected.avatar !== ''
                            ? item?.userDetected.avatar
                            : '../../assets/img/default-medico.jpg'
                        " />
                  </ion-avatar>
                  <ion-label>
                    <p class="ion-text-wrap">
                      <strong>
                        {{ item?.userDetected && item?.userDetected?.nombre }}
                        <small *ngIf="(member | any )?.type === 'representante-medico'"><i>{{ (member | any )?.empresa
                            }}</i></small>
                      </strong>
                    </p>
                    <p>
                      <ion-icon *ngIf="item?.last_message?.user == me" slot="start" color="primary"
                        [name]="'checkmark'">
                      </ion-icon>
                      {{ item?.last_message?.message }}
                      <ion-text color="dark" class="ion-hide-md-up">
                        <p>{{ item?.last_message.date.toDate() | amTimeAgo }}
                          <ion-badge color="danger" *ngIf="!(item?.last_message?.user == me) && myStatus(item) > 0"
                            style="margin-top: -2px; position: absolute; margin-left: 10px;">
                            {{ myStatus(item) }}
                          </ion-badge>
                        </p>
                      </ion-text>
                    </p>
                  </ion-label>
                  <ion-badge color="danger" *ngIf="!(item?.last_message?.user == me) && myStatus(item) > 0" slot="end"
                    class="ion-hide-md-down">
                    {{ myStatus(item) }}
                  </ion-badge>
                  <ion-note slot="end" class="ion-hide-md-down">
                    <ion-text color="dark">
                      <p>{{ item?.last_message.date.toDate() | amTimeAgo }}</p>
                    </ion-text>
                  </ion-note>
                </ion-item>
                <ion-item-options>
                  <ion-item-option (click)="confirmDelete(item?.id)" color="danger">
                    <ion-icon name="trash-outline"></ion-icon>
                  </ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            </ion-list>

            <ion-grid class="ion-no-margin ion-no-padding ion-margin-top"
              *ngIf="segment === 'chat-list' || userType === 'medico'">
              <ion-row>
                <ion-col size="6" class="ion-no-margin ion-no-padding">
                  <ion-button (click)="paginate('back')" expand="block" color="light"
                    [disabled]="!cursorBack || isLoading == true">
                    <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col size="6  " class="ion-no-margin ion-no-padding">
                  <ion-button (click)="paginate('next')" expand="block" color="light"
                    [disabled]="!cursorNext || isLoading == true">
                    <ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>

            <ion-list *ngIf="segment === 'group' && userType === 'representante-medico'">
              <ion-item *ngIf="!(groups && groups[0])">
                <ion-label>
                  Sin grupos
                </ion-label>
              </ion-item>
              <ion-item *ngFor="let item of groups" ion-button [disabled]="isLoading == true">
                <ion-label (click)="detail(item?.id)">
                  <h2>
                    <strong>
                      {{ item?.name }}
                    </strong>
                  </h2>
                  <p>{{ item?.description }}</p>
                </ion-label>
                <ion-buttons slot="end">
                  <ion-button (click)="addGroup(item?.id)" color="medium">
                    <ion-icon slot="icon-only" name="create-outline"></ion-icon>
                  </ion-button>
                  <ion-button (click)="confirmDeleteGroup(item?.id)" color="medium">
                    <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="userType === 'representante-medico'">
    <ion-fab-button color="tertiary" [disabled]="isLoading == true">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button (click)="addChat()">
        <ion-icon name="chatbox-outline"></ion-icon>
      </ion-fab-button>
      <ion-fab-button (click)="addGroup()">
        <ion-icon name="people-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="userType === 'medico'">
    <ion-fab-button color="tertiary" (click)="addChat()" [disabled]="isLoading == true">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab> 
  -->

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="tertiary" (click)="addChat()" [disabled]="isLoading == true">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-infinite-scroll threshold="100px" (ionInfinite)="loadGroups($event)"
    *ngIf="segment === 'group' && isLoading === false">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Cargando ..."> </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>