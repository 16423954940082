<ion-header>
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center"> <ion-icon name="options-outline"></ion-icon> Segmentar por empresa </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      type="text"
      debounce="1000"
      (ionChange)="doSearch($event)"
      (ionCancel)="clearSearch()"
      (ionClear)="clearSearch()"
      enterkeyhint="send"
      placeholder="Buscar"
      animated
    >
    </ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <ion-grid fixed>
    <ion-row *ngIf="arrayDocs && arrayDocs[0]">
      <ion-col size="12">
        <div *ngFor="let item of arrayDocs">
          <ion-card (click)="selectItem(item)">
            <ion-item
              [color]="item.isChecked == true ? 'tertiary' : 'secondary'"
              lines="none"
              detail="true"
              [button]="true"
            >
              <ion-avatar slot="start" style="max-width: 50px; max-height: 50px;" *ngIf="item.logo">
                <img [src]="item.logo" style="object-fit: contain;" />
              </ion-avatar>
              <ion-avatar class="company_text_logo" *ngIf="!item?.logo">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xml:space="preserve"
                  style="
                    shape-rendering: geometricPrecision;
                    text-rendering: geometricPrecision;
                    image-rendering: optimizeQuality;
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                  "
                  viewBox="0 0 500 500"
                >
                  <g id="UrTavla">
                    <circle style="fill: #d9d9d9" cx="250" cy="250" r="245"></circle>
                    <text x="50%" y="50%" text-anchor="middle" dy=".3em" style="font-size: 180px; font-family: Arial">
                      {{ item.nameLogo }}
                    </text>
                  </g>
                </svg>
              </ion-avatar>
              <ion-label>
                <h2>{{ item.name | uppercase }}</h2>
                <h6>{{ item.businessType }}</h6>
              </ion-label>
              <!-- <ion-badge slot="end">{{ item?.count }}</ion-badge> -->
            </ion-item>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="arrayDocs && arrayDocs[0]">
      <ion-col size="12">
        <ion-grid class="ion-no-margin ion-no-padding ion-margin-top">
          <ion-row>
            <ion-col size="6" class="ion-no-margin ion-no-padding">
              <ion-button
                (click)="paginate('back')"
                expand="block"
                color="light"
                [disabled]="!cursorBack || isLoading == true"
              >
                <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
              </ion-button>
            </ion-col>
            <ion-col size="6  " class="ion-no-margin ion-no-padding">
              <ion-button
                (click)="paginate('next')"
                expand="block"
                color="light"
                [disabled]="!cursorNext || isLoading == true"
              >
                <ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-button fill="outline" expand="block" color="danger" (click)="clearFilter()">
          <ion-icon name="trash-outline" slot="start"></ion-icon>
          Borrar filtro
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
