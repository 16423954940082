import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refresh-web',
  templateUrl: './refresh-web.component.html',
  styleUrls: ['./refresh-web.component.scss'],
})
export class RefreshWebComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
