import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';

@Component({
  selector: 'app-i-company-card',
  templateUrl: './i-company-card.component.html',
  styleUrls: ['./i-company-card.component.scss'],
})
export class ICompanyCardComponent implements OnInit {
  @Input() company: any;
  @Input() outstanding: boolean;

  constructor(
    private router: Router,
    private credService: CredentialsService,
    private analyticsFB: AnalyticsService
  ) { }

  ngOnInit() {
    
  }

  companyDetail() {
    this.router.navigateByUrl(this.company.routing.route);
    this.analyticsClickEvent('view_company', {
      section_name: 'Company',
      company_name: this.company.name
    });
  }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  navToPage() {
    window.open(this.company.companyWebsite, '_blank');
    this.analyticsClickEvent('company_open_website', {
      content_type: 'Abrir pagina web de empresa',
      click_URL: this.company.companyWebsite,
      section_name: 'Empresas'
    });
  }

}
