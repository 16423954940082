<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center"> Solicitar muestra </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-progress-bar type="indeterminate" *ngIf="isLoading == true"></ion-progress-bar>
  <ion-grid fixed class="ion-no-padding">
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="dataForm" (ngSubmit)="save()">
          <ion-item>
            <ion-text>
              <h2><strong>Solicitud para:</strong></h2>
              <p>{{ username }}</p>
              <p>
                <small>{{ company }}</small>
              </p>
              <p><strong>Producto: </strong> {{ product }}</p>
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Uso que le dará a la muestra</ion-label>
          </ion-item>
          <ion-radio-group formControlName="useTo">
            <ion-item>
              <ion-label>Terapéutico</ion-label>
              <ion-radio slot="start" [value]="1" checked></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>Personal</ion-label>
              <ion-radio slot="start" [value]="2"></ion-radio>
            </ion-item>
            <ion-item>
              <ion-label>Promocional</ion-label>
              <ion-radio slot="start" [value]="3"></ion-radio>
            </ion-item>
          </ion-radio-group>
          <ion-item>
            <ion-label position="stacked">Dirección de envío</ion-label>
          </ion-item>
          <ion-radio-group formControlName="adress">
            <ion-item *ngFor="let item of adress">
              <ion-label>{{ item.adress }}</ion-label>
              <ion-radio slot="start" [value]="item.id" checked></ion-radio>
            </ion-item>
          </ion-radio-group>
          <ion-item>
            <ion-label position="stacked">Comentarios</ion-label>
            <ion-textarea rows="6" formControlName="comments" placeholder="Comentarios..."></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-text>
              <p>
                El solicitante se compromete a hacer buen uso de las muestras médicas del producto antes mencionado y se
                compromete a salvaguardarlo bajo los estándares que detalla el uso y consevación del producto. Negándose
                a hacer cualquier mal uso del mismo.
              </p>
            </ion-text>
          </ion-item>
          <ion-button type="submit" color="tertiary" expand="block" [disabled]="!dataForm.valid || isLoading">
            Enviar solicitud
          </ion-button>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
