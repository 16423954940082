import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { VerificationCodeRoutingModule } from './verification-code-routing.module';
import { VerificationCodeComponent } from './verification-code.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    IonicModule,
    VerificationCodeRoutingModule
  ],
  declarations: [VerificationCodeComponent]
})
export class VerificationCodeModule {}
