import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RichTextComponent } from './rich-text.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [RichTextComponent],
  exports: [RichTextComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    QuillModule.forRoot({
      modules: {
        syntax: true
      }
    }),
  ]
})
export class RichTextModule { }
