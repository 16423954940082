<ion-header>
  <ion-toolbar color="primary">
    <ion-item
      *ngIf="other"
      color="primary"
      [button]="true"
      (click)="goToContactDetail()"
      [disabled]="other.status !== 'active'"
    >
      <ion-avatar slot="start">
        <img
          *ngIf="other?.type !== 'medico'"
          [src]="
            other && other.avatar && other.avatar.thumbnail
              ? other.avatar.thumbnail.url
              : '../../assets/img/default-representante-medico.jpg'
          "
        />
        <img
          *ngIf="other?.type === 'medico'"
          [src]="
            other && other?.avatar && other?.avatar.thumbnail
              ? other.avatar.thumbnail.url
              : '../../assets/img/default-medico.jpg'
          "
        />
      </ion-avatar>
      <ion-label *ngIf="other.name">
        <h2>
          {{ other.name }}
          <span *ngIf="other.lastName1 !== '' && other.lastName1"> {{ other.lastName1 }}</span>
          <span *ngIf="other.lastName2 !== '' && other.lastName2"> {{ other.lastName2 }}</span>
        </h2>
      </ion-label>
    </ion-item>
    <ion-item *ngIf="!other" color="primary">
      <ion-label>Este usuario actualmente ya no existe</ion-label>
    </ion-item>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()" color="light">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar> 
  <div *ngIf="other && other.status !== 'active'" class="ion-text-center">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size="12" style="padding: 0px;">
        <span>Este usuario se encuentra inactivo</span>
      </ion-col>
    </ion-row>
  </div>
</ion-header>
<ion-content #chatBox id="chatBox" [scrollEvents]="true">
  <ion-button (click)="loadData()" expand="block" fill="clear">
    <ion-icon slot="start" name="refresh-outline"></ion-icon>
    Cargar anteriores
  </ion-button>
  <ion-grid fixed class="ion-no-padding">
    <ion-row *ngFor="let item of messages">
      <!-- <ion-col size="10" [offset]=" 2 : 0"> -->
      <ion-col size="12">
        <ion-card
          [color]="item && item.sender === me ? 'stablegreen' : 'light'"
          [class]="item && item.sender === me ? 'sender' : 'receiver'"
          style="padding-top: 5px;"
        >
          <ion-item class="ion-no-padding" lines="none" [color]="item && item.sender === me ? 'stablegreen' : 'light'">
            <ion-avatar slot="start">
              <img
                [src]="
                  chatParticipants[item.sender] && chatParticipants[item.sender].avatar
                    ? chatParticipants[item.sender].avatar
                    : 'assets/img/default-representante-medico.jpg'
                "
              />
            </ion-avatar>
            <ion-label>
              <h2>
                <strong>
                  {{ chatParticipants[item.sender] && chatParticipants[item.sender]?.nombre }}
                </strong>
              </h2>
            </ion-label>
            <ion-note slot="end">
              <p>{{ item.date.toDate() | amTimeAgo }}</p>
            </ion-note>
          </ion-item>
          <ion-card-content>
            <ion-button
              class="ion-text-wrap"
              (click)="openExternal(item.url, item)"
              expand="block"
              fill="clear"
              shape="round"
              *ngIf="item.isFile"
            >
              <ion-icon name="document-outline"></ion-icon>
              {{ item.message }}
            </ion-button>
            <div *ngIf="!item.isFile" [innerHTML]="item.message | sanitizeHtml"></div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <input
    id="file1"
    class="hide"
    #file1
    name="file1"
    accept="image/*"
    type="file"
    name="file"
    (change)="selectFile1($event?.target)"
  />
  <input
    id="file2"
    class="hide"
    #file2
    name="file2"
    type="file"
    name="file"
    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
    (change)="selectFile2($event?.target)"
  />
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="tertiary" [disabled]="!other || other.status !== 'active'">
      <ion-icon name="attach-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-label>Imagen</ion-label>
      <ion-fab-button (click)="imageFromGallery(); analyticsClickEvent('upload_image', {})" title="Imagen">
        <ion-icon name="images-outline"></ion-icon>
      </ion-fab-button>
      <ion-label>Cámara</ion-label>
      <ion-fab-button (click)="takePicture(); analyticsClickEvent('upload_photo', {})" title="Cámara">
        <ion-icon name="camera-outline"></ion-icon>
      </ion-fab-button>
      <ion-label>Doc</ion-label>
      <ion-fab-button (click)="triggerClick2(); analyticsClickEvent('upload_doc', {})" title="Documento">
        <ion-icon name="document-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
</ion-content>
<ion-footer *ngIf="isLoad === true">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="10">
        <rich-text
          [elementId]="'my-editor-chat'"
          (onEditorContentChange)="keyupHandler($event)"
          [contentHtml]="htmlContent"
          [resetEditor]="resetEditor"
        ></rich-text>
      </ion-col>
      <ion-col size="2">
        <ion-button
          fill="clear"
          expand="block"
          (click)="sendMessage({ msg:'', convert:false })"
          [disabled]="!other || other.status !== 'active'"
          style="vertical-align: middle; display: contents;"
        >
          <ion-icon slot="icon-only" name="send-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
