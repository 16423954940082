import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { Platform, PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { CredentialsService } from '../../../core/authentication/credentials.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { NotificationService } from '../../services/notification/notification.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { EmailService } from '../../services/email/email.service';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { environment } from '@env/environment';
import { Browser } from '@capacitor/browser';
import { Logger } from '@app/core/logger.service';
import moment from 'moment';
import { Storage } from '@ionic/storage-angular';
import { IndustryRoutesService } from '@app/industry/services/industry-routes.service';
const log = new Logger('PostAdvertisingComponent');


@Component({
  selector: 'app-advertising',
  templateUrl: './advertising.component.html',
  styleUrls: ['./advertising.component.scss'],
})
export class AdvertisingComponent implements OnInit {
  @Output() closeAdd = new EventEmitter<null>();
  @Input() advertising: any;
  @Input() inHome: boolean = false;
  route: string;
  img: any;
  appUrl = environment.APP_URL;
  show: boolean = true;
  userType: string;
  isLoading: boolean = false;
  private outOfInappBrowser: string[] = [];

  constructor(
    private analyticsFB: AnalyticsService,
    public popoverCtrl: PopoverController,
    private router: Router,
    private utilities: UtilitiesService,
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private notificationService: NotificationService,
    private emailS: EmailService,
    private loader: IonLoaderService,
    public platform: Platform,
    private storage: Storage,
    private industryRoutesService: IndustryRoutesService
  ) {
    this.getOutOfInappBrowser();
  }

  analyticsClickEvent(eventName: string) {
    let params: any = {
      item_id: this.advertising.id,
      click_text: this.advertising.content,
      click_URL: this.route,
      content_type: this.advertising.type,
      label: this.advertising && this.advertising.analyticsLabel ? this.advertising.analyticsLabel : undefined,
      rel_type: this.advertising.relType,
      user_id: this.credService.credentials.uid
    };
    if (params.content_type === 'banner' && eventName === 'advertising_banner_click') {
      params.content_type = `${params.content_type}-${this.advertising.relType}`;
      if (params.rel_type === 'link') {
        let includes: boolean[] = [];
        for (const DOMAIN of environment.APP_DOMAINS) {
          if (params.click_URL.includes(DOMAIN) === true) {
            includes.push(true);
          }
        }
        const INDEX: number = includes.indexOf(true);
        const LINK_TYPE: string = INDEX > -1 ? 'interno' : 'externo';
        params.content_type = `${params.content_type}-${LINK_TYPE}`;
        params.event_main_value =
          INDEX > -1
            ? this.advertising.link
            : `${this.advertising.link}?param1=${this.credService.credentials.uid}`;
      } else if (params.rel_type === 'repre') {
        params.event_main_value = this.advertising.repreId;
      } else if (params.rel_type === 'product') {
        params.event_main_value = this.advertising.productId;
      }
    }
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async ngOnInit() {
    this.img = this.utilities.trustHtml(this.advertising.img);
    await this.generateRoute();
    this.userType =
      this.credService && this.credService.credentials && this.credService.credentials.type
        ? this.credService.credentials.type
        : undefined;
    if (this.userType === 'medico') {
      this.analyticsClickEvent('ad_activeview');
    }
  }

  public async generateRoute() {
    if (this.advertising.repreId) {
      if (this.advertising.productId) {
        try {
          const product: any = (await this.fireService.afs.doc(`products/${this.advertising.productId}`).ref.get()).data();
          const routing: any = this.industryRoutesService.mapRoute(product.type, this.advertising.productId);
          this.route = routing.route;
        } catch (error) {
          log.error(error);
        }
      } else {
        this.route = '/user/details/' + this.advertising.repreId;
      }
    } else {
      if (this.advertising.relType === 'link') {
        let includes: boolean[] = [];
        for (const DOMAIN of environment.APP_DOMAINS) {
          if (this.advertising.link.includes(DOMAIN) === true) {
            includes.push(true);
          }
        }
        const INDEX: number = includes.indexOf(true);
        const LINK_TYPE: string = INDEX > -1 ? 'interno' : 'externo';
        if (LINK_TYPE === 'externo') {
          const _URL: string = `${this.advertising.link}?param1=${this.credService.credentials.uid}`;
          this.route = _URL;
        } else {
          this.route = this.advertising.link;
        }
      }
    }
  }

  async redirectCall() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.generateRoute();
      if (this.advertising.repreId && (this.advertising.productId === null || this.advertising.productId === 'null')) {
        // Representante
        let repData = await this.fireService.getRepData(this.advertising.repreId);
        let userData = await this.fireService.getUserData(this.advertising.repreId);
        let otherName: string = '';
        otherName = `${userData && userData.name ? userData.name : ''} ${userData && userData.lastName1 ? userData.lastName1 : ''
          } ${userData && userData.lastName2 ? userData.lastName2 : ''}`;
        // My data
        let myName: string = '';
        const data = await this.fireService.getUserData(this.credService.credentials.uid);
        myName = `${data && data.name ? data.name : ''} ${data && data.lastName1 ? data.lastName1 : ''} ${data && data.lastName2 ? data.lastName2 : ''
          }`;
        let message: string = `Buen día Dr(a). ${myName}, soy ${otherName} de ${repData && repData.company && repData.company.name ? repData.company.name : ''
          }, en que le puedo servir?`;
        await this.fireService.createChat(this.advertising.repreId, ChatComponent, true, [
          {
            convert: false,
            isFile: false,
            message: message,
            url: undefined,
            user: this.advertising.repreId,
            sendMail: true
          }
        ]);
        await this.fireService.createChat(this.advertising.repreId, ChatComponent);
        this.notificationService.sendNotification(this.advertising.repreId, 'add-click-repre');
      } else if (
        this.advertising.repreId &&
        !(this.advertising.productId === null || this.advertising.productId === 'null')
      ) {
        // //Send the notification
        const productData: any = await this.fireService.getProductData(this.advertising.productId);
        this.notificationService.sendNotification(this.advertising.repreId, 'add-click-product', {
          name: productData.title,
          id: this.advertising.productId
        });
        this.router.navigateByUrl(this.route);
      } else {
        let url: string = this.route;
        const tmpUrl = url.toLowerCase();
        let booleanArray: boolean[] = [];
        for (const domain of environment.APP_DOMAINS) {
          if (tmpUrl.includes(domain) === true) {
            booleanArray.push(true);
          } else {
            booleanArray.push(false);
          }
        }
        const index: number = booleanArray.indexOf(true);
        if (index > -1) {
          url = url.replace(environment.APP_DOMAINS[index], '');
          this.router.navigate([String(url)]);
        } else {
          this.openUrlFile(url);
          //window.open(String(url), '_blank');
        }
      }
      // Sending the mail
      const key = `add-${moment().format('YYYY-MM-DD')}-${this.advertising.id}`;
      const exist = await this.storage.get(key);
      if (!exist === true) {
        await this.emailS.addClickDataMail({
          advertising: this.advertising,
          repreId: this.advertising.repreId,
          productId: this.advertising.productId,
          link: this.advertising.link
        });
        await this.storage.set(`add-${moment().format('YYYY-MM-DD')}-${this.advertising.id}`, true);
      }
    } catch (error) {
      //log.error(error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  private async getOutOfInappBrowser() {
    try {
      const RESP = await this.fireService.afs.doc('constants/constants').ref.get();
      this.outOfInappBrowser = RESP.get('out-of-inappbrowser') || [];
    } catch (e) {
      log.error(e);
    }
  }

  async openUrlFile(url: string) {
    const ext = String(String(url).substr(String(url).lastIndexOf('.') + 1)).toLowerCase();
    let coincidence: boolean = false;
    for (let item of this.outOfInappBrowser) {
      var index: number = url.indexOf(item);
      if (index >= 0) {
        coincidence = true;
        break;
      }
    }
    if (
      ext === 'pdf' ||
      ext === 'doc' ||
      ext === 'docx' ||
      ext === 'ppt' ||
      ext === 'pptx' ||
      ext === 'xls' ||
      ext === 'xlsx' ||
      coincidence === true
    ) {
      try {
        Browser.open({ url: url });
      } catch (error) {
        log.error(error);
      }
    } else {
      if (
        (this.platform.is('android') && this.platform.is('capacitor')) ||
        (this.platform.is('ios') && this.platform.is('capacitor'))
      ) {
        Browser.open({ url: url });
      } else {
        Browser.open({ url: url });
      }
    }
  }

  redirect() {
    if (!this.isLoading === true) {
      this.redirectCall();
    }
  }

  close() {
    this.analyticsClickEvent('close_advertising');
    if (this.inHome) {
      this.closeAdd.emit(null);
    } else {
      this.show = false;
    }
  }
}
