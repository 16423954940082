import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController } from '@ionic/angular';
import { ChatGroupComponent } from '@app/chat-list/chat-group/chat-group.component';
import { MyContactsComponent } from '@app/shared/my-contacts/my-contacts.component';
import { RedConectimedComponent } from '@app/red-conectimed/red-conectimed.component';
import { GroupDetailComponent } from '@app/chat-list/group-detail/group-detail.component';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { Logger } from '@app/core/logger.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Message, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { environment } from '@env/environment';
const log = new Logger('Group Options');
import * as moment from 'moment';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';

@Component({
  selector: 'app-group-options',
  templateUrl: './group-options.component.html',
  styleUrls: ['./group-options.component.scss']
})
export class GroupOptionsComponent implements OnInit {
  idGroup: any;
  messages: Message[];
  public isLoading: boolean = false;

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private loader: IonLoaderService,
    private fireService: FirebaseService,
    private utilities: UtilitiesService,

    public credService: CredentialsService
  ) { }

  ngOnInit() { }

  async members() {
    this.close();
    try {
      const response1 = await this.fireService.afs
        .collection('broadcast-list')
        .doc(this.idGroup)
        .collection('contacts')
        .ref.orderBy('nameStr', 'asc')
        .get();
      const initialArray = response1.docs.map(item => {
        let element = item.data();
        const id = item.id;
        return { id, ...element };
      });
      const modal = await this.modalCtrl.create({
        component: this.credService.credentials.defaultUser === true ? RedConectimedComponent : MyContactsComponent,
        componentProps: { viewType: 'modal', selectedUsers: initialArray }
      });
      modal.onDidDismiss().then(async users => {
        if (users.data && users.data.users) {
          await this.loader.ionLoaderPresent();
          try {
            const response = await this.fireService.afs
              .collection('broadcast-list')
              .doc(this.idGroup)
              .collection('contacts')
              .ref.get();
            const collection = response.docs.map(element => {
              const data = element.data();
              const id = element.id;
              return { id, ...data };
            });
            const batch1 = this.fireService.afs.firestore.batch();
            collection.forEach(element => {
              const ref = this.fireService.afs
                .collection('broadcast-list')
                .doc(this.idGroup)
                .collection('contacts')
                .doc(element.id).ref;
              batch1.delete(ref);
            });
            await batch1.commit();
            const mapped = Array.from(users.data.users).map((element: any) => {
              let dataUSER = {
                name: element.name,
                lastName1: element.lastName1,
                lastName2: element.lastName2,
                nameStr: '',
                firstCharacter: '',
                id: element.id
              };
              const nameStr = this.utilities.stringSearch(
                dataUSER.lastName1 + ' ' + dataUSER.lastName2 + ' ' + dataUSER.name,
                true
              );
              dataUSER.nameStr = nameStr;
              dataUSER.firstCharacter = nameStr.charAt(0) || '';
              return dataUSER;
            });
            const batch2 = this.fireService.afs.firestore.batch();
            mapped.forEach(element => {
              const ref = this.fireService.afs
                .collection('broadcast-list')
                .doc(this.idGroup)
                .collection('contacts')
                .doc(element.id).ref;
              batch2.set(ref, element);
            });
            await batch2.commit();
          } catch (error) {
            log.error(error);
          }
          this.utilities.toast('Grupo modificado correctamente.', 'Correcto');
          await this.loader.ionLoaderDismiss();
        }
      });
      modal.present();
    } catch (error) {
      log.error(error);
    }
  }

  async close() {
    await this.popoverCtrl.dismiss();
  }

  async chatGroup() {
    this.close();
    const modal = await this.modalCtrl.create({
      component: ChatGroupComponent,
      componentProps: { idGroup: this.idGroup }
    });
    modal.onDidDismiss().then(message => {
      if (message.data && message.data.messages) {
        this.messages = Array.from(message.data.messages);
        if (
          message.data &&
          message.data.analytics &&
          message.data.analytics.label &&
          message.data.analytics.send === true
        ) {
          this.messages.map(element => {
            const data: any = element;
            data.label = String(message.data.analytics.label);
            return data;
          });
        }
        this.messageText();
      }
    });
    modal.present();
  }

  public async chat(userid: string, sendHere?: boolean): Promise<void> {
    this.fireService.createChat(userid, ChatComponent, sendHere, this.messages);
  }

  async messageText() {
    await this.loader.ionLoaderPresent();
    this.isLoading = true;
    try {
      const contacts = (
        await this.fireService.afs
          .collection('broadcast-list')
          .doc(this.idGroup)
          .collection('contacts')
          .ref.get()
      ).docs.map(element => {
        const id = element.id;
        return id;
      });
      let pormises: Promise<any>[] = [];
      contacts.forEach(element => {
        pormises.push(this.chat(element, true));
      });
      await Promise.all(pormises);
      for (let i = 0; i < this.messages.length; i++) {
        await this.fireService.afs
          .collection('broadcast-list')
          .doc(this.idGroup)
          .collection('messages')
          .add({
            message: this.messages[i].message,
            date: moment().toDate()
          });
      }
      this.utilities.toast('Mensaje enviado correctamente.', 'Correcto');
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
    await this.loader.ionLoaderDismiss();
  }

  public async detail() {
    this.isLoading = true;
    this.close();
    try {
      const modal = await this.modalCtrl.create({
        component: GroupDetailComponent,
        componentProps: { idGroup: this.idGroup }
      });
      modal.present();
    } catch (error) {
      log.error(error);
    }
    this.isLoading = false;
  }
}
