import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  item: any;
  currentItem: any;
  items: any[] = [
    {
      label: 'Enviados',
      id: 'sended',
      icon: 'paper-plane',
      checked: false
    },
    {
      label: 'Recibidos',
      id: 'received',
      icon: 'download',
      checked: false
    },
    {
      label: 'Fecha',
      id: 'date',
      icon: 'calendar',
      checked: false
    }
  ];
  isDate: boolean;
  inicio = {
    date: '',
    time: ''
  };
  fin = {
    date: '',
    time: ''
  };

  constructor(
    private popoverCtrl: PopoverController
    ) {

    const TODAY = moment();
    this.fin.date = TODAY.format('YYYY-MM-DD');
    this.fin.time = TODAY.format('HH:mm:00');
    const YESTERDAY = TODAY.subtract(1, 'd');
    this.inicio.date = YESTERDAY.format('YYYY-MM-DD');
    this.inicio.time = '00:00:00';
  }

  ngOnInit() {
    if (this.item) {
      const index: number = this.items.map(e => e.id).indexOf(this.item.id);
      if (index > -1) {
        this.items[index].checked = this.item.checked;
        if (this.item.id === 'date') {
          this.isDate = true;
          this.items[index].data = this.item.data;
          this.inicio = this.items[index].data.inicio;
          this.fin = this.items[index].data.fin;
          this.currentItem = this.item;
        }
      }
    }
  }

  public filter(item: any): Promise<boolean> | boolean {
    if (item.id !== 'date') {
      this.isDate = false;
      item.checked = !item.checked;
      return this.popoverCtrl.dismiss({ item: item }, 'filter');
    } else {
      this.items.map(e => {
        let data = e;
        data.checked = false;
        return e;
      });

      this.isDate = true;
      item.checked = !item.checked;
      this.currentItem = item;
      return false
    }
  }

  public filterDate() {
    this.currentItem.data = {
      inicio: {
        date: moment(this.inicio.date).format('YYYY-MM-DD'),
        time: this.inicio.time
      },
      fin: {
        date: moment(this.fin.date).format('YYYY-MM-DD'),
        time: this.fin.time
      }
    };
    return this.popoverCtrl.dismiss({ item: this.currentItem }, 'filter');
  }

  clear() {
    return this.popoverCtrl.dismiss({}, 'clear');
  }
}
