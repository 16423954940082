import { Component, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { environment } from '@env/environment';
import { Logger } from '@app/core/logger.service';
const log = new Logger('AboutComponent');

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  versionCode: any;
  versionNumber: any;
  deviceModel: any;
  devicePlatform: any;
  deviceVersion: any;
  permissions: any[] = [];
  _device: string;
  data: any = {};

  constructor(
    private platform: Platform,
    private diagnostic: Diagnostic,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() { }

  ionViewWillEnter() {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        if (this.platform.is('android')) {
          this._device = 'android';
          this.initializeAppMovil();
        } else {
          this._device = 'ios';
          this.initializeAppMovil();
        }
      } else {
        this._device = 'web';
        this.initializeAppWeb();
      }
    });
  }

  initializeAppMovil() {
    this.getInfo();
    this.getPermision();
  }

  initializeAppWeb() {
    this.getPermisionWeb();
    this.getInfoWeb();
  }

  getInfoWeb() {
    var objAgent = navigator.userAgent;
    var objbrowserName = navigator.appName;
    var objfullVersion = '' + parseFloat(navigator.appVersion);
    var objBrMajorVersion = parseInt(navigator.appVersion, 10);
    var objOffsetName, objOffsetVersion, ix;
    // In Chrome
    if ((objOffsetVersion = objAgent.indexOf('Chrome')) != -1) {
      objbrowserName = 'Chrome';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
    }
    // In Microsoft internet explorer
    else if ((objOffsetVersion = objAgent.indexOf('MSIE')) != -1) {
      objbrowserName = 'Microsoft Internet Explorer';
      objfullVersion = objAgent.substring(objOffsetVersion + 5);
    }
    // In Firefox
    else if ((objOffsetVersion = objAgent.indexOf('Firefox')) != -1) {
      objbrowserName = 'Firefox';
    }
    // In Safari
    else if ((objOffsetVersion = objAgent.indexOf('Safari')) != -1) {
      objbrowserName = 'Safari';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf('Version')) != -1)
        objfullVersion = objAgent.substring(objOffsetVersion + 8);
    }
    // For other browser "name/version" is at the end of userAgent
    else if ((objOffsetName = objAgent.lastIndexOf(' ') + 1) < (objOffsetVersion = objAgent.lastIndexOf('/'))) {
      objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion);
      objfullVersion = objAgent.substring(objOffsetVersion + 1);
      if (objbrowserName.toLowerCase() == objbrowserName.toUpperCase()) {
        objbrowserName = navigator.appName;
      }
    }
    // trimming the fullVersion string at semicolon/space if present
    if ((ix = objfullVersion.indexOf(';')) != -1) objfullVersion = objfullVersion.substring(0, ix);
    if ((ix = objfullVersion.indexOf(' ')) != -1) objfullVersion = objfullVersion.substring(0, ix);
    objBrMajorVersion = parseInt('' + objfullVersion, 10);
    if (isNaN(objBrMajorVersion)) {
      objfullVersion = '' + parseFloat(navigator.appVersion);
      objBrMajorVersion = parseInt(navigator.appVersion, 10);
    }
    var Name = 'Unknown OS';
    if (navigator.userAgent.indexOf('Win') != -1) Name = 'Windows';
    if (navigator.userAgent.indexOf('Mac') != -1) Name = 'Macintosh';
    if (navigator.userAgent.indexOf('Linux') != -1) Name = 'Linux';
    if (navigator.userAgent.indexOf('Android') != -1) Name = 'Android';
    if (navigator.userAgent.indexOf('like Mac') != -1) Name = 'iOS/iPadOS';
    this.devicePlatform = objbrowserName;
    this.deviceVersion = objfullVersion;
    this.versionCode = environment.WEB_VERSION;
    this.deviceModel = Name;
  }

  async getInfo() {
    try {
      const appInfo = await App.getInfo();
      const deviceInfo = await Device.getInfo();
      this.data = { appInfo: appInfo, deviceInfo: deviceInfo }
      this.versionCode = appInfo.version;
      this.versionNumber = appInfo.version;
      this.deviceModel = deviceInfo.model;
      this.devicePlatform = deviceInfo.platform;
      this.deviceVersion = deviceInfo.osVersion;
    } catch (error) {
      log.error("%%%%%%%%%%%%%%% ERROR %%%%%%%%%%%%%%%", error);
    }
  }

  getPermisionWeb() {
    this.permissions = [
      {
        name: 'notification',
        usedFor: 'Permisos para recibir notificaciones: ',
        status: false
      },
      {
        name: 'camera',
        usedFor: 'Acceso a cámara del dispositivo: ',
        status: false
      }
    ];
    if (Notification.permission === 'granted') {
      this.permissions[this.permissions.findIndex(x => x.name === 'notification')].status = true;
    }

    const nav: any = navigator;
    nav.permissions.query({ name: 'camera' }).then((permission: any) => {
      if (permission.state === 'granted') {
        this.permissions[this.permissions.findIndex(x => x.name === 'camera')].status = true;
      }
    });
  }

  getPermision() {
    if (this._device === 'ios') {
      this.permissions = [
        {
          name: 'calendar',
          usedFor: 'Acceso a calendario del dispositivo:',
          status: false
        },
        {
          name: 'storage',
          usedFor: 'Acceso y almacenamiento de distintos tipos de archivo: ',
          status: false
        },
        {
          name: 'camera',
          usedFor: 'Acceso a cámara del dispositivo: ',
          status: false
        },
        {
          name: 'microphone',
          usedFor: 'Acceso a micrófono del dispositivo: ',
          status: false
        },
        {
          name: 'camera-roll',
          usedFor: 'Acceso a fotografías del dispositivo: ',
          status: false
        },
        {
          name: 'notification',
          usedFor: 'Permisos para recibir notificaciones: ',
          status: false
        }
      ];
    } else {
      this.permissions = [
        {
          name: 'calendar',
          usedFor: 'Acceso a calendario del dispositivo:',
          status: false
        },
        {
          name: 'storage',
          usedFor: 'Acceso y almacenamiento de distintos tipos de archivo: ',
          status: false
        },
        {
          name: 'camera',
          usedFor: 'Acceso a cámara del dispositivo: ',
          status: false
        },
        {
          name: 'microphone',
          usedFor: 'Acceso a micrófono del dispositivo: ',
          status: false
        },
        {
          name: 'notification',
          usedFor: 'Permisos para recibir notificaciones: ',
          status: false
        }
      ];
    }

    if (this._device === 'ios') {
      this.diagnostic.getExternalStorageAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'storage')].status = true;
        }
      });

      this.diagnostic.getCameraAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'camera')].status = true;
        }
      });

      this.diagnostic.getMicrophoneAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'microphone')].status = true;
        }
      });

      this.diagnostic.getCameraRollAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'camera-roll')].status = true;
        }
      });

      this.diagnostic.getRemoteNotificationsAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'notification')].status = true;
        }
      });
    } else {
      this.diagnostic.getCalendarAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'calendar')].status = true;
        }
      });

      this.diagnostic.getExternalStorageAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'storage')].status = true;
        }
      });

      this.diagnostic.getCameraAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'camera')].status = true;
        }
      });

      this.diagnostic.getMicrophoneAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'microphone')].status = true;
        }
      });

      this.diagnostic.getRemoteNotificationsAuthorizationStatus().then(status => {
        if (status === this.diagnostic.permissionStatus.GRANTED || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
          this.permissions[this.permissions.findIndex(x => x.name === 'notification')].status = true;
        }
      });
    }
  }

  requestPermissionsWeb(permission: string) {
    let msgRequired = 'Permiso requerido';
    let msgProperly = 'Es necesario que otorgues el permiso para el correcto funcionamiento de la aplicación.';
    let msgDeniedAlways =
      'Seleccionaste "Bloquear". Ingresa a Menú>Configuracion>Privacidad y seguridad>Configuración del sitio>Permisos>(Cámara, notificaciones)>Bloquear>https://app.conectimed.com>Permisos>(Cámara, notificaciones)>Permitir.';
    let permissionIndex = this.permissions.findIndex(x => x.name == permission);
    switch (permission) {
      case 'notification':
        Notification.requestPermission().then(status => {
          switch (status) {
            case 'granted':
              this.permissions[permissionIndex].status = true;
              break;
            case 'denied':
              this.showAlert(msgRequired, msgDeniedAlways, permission);
              break;
            case 'default':
              this.showAlert(msgRequired, msgProperly, permission);
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
        });
        break;
      case 'camera':
        var constraints = { audio: true, video: { width: 1280, height: 720 } };
        navigator.mediaDevices.getUserMedia(constraints).then(
          media => {
            if (media.active === true) {
              this.permissions[permissionIndex].status = true;
            } else {
              this.showAlert(msgRequired, msgDeniedAlways, permission);
              this.permissions[permissionIndex].status = false;
            }
          },
          error => {
            this.showAlert(msgRequired, msgDeniedAlways, permission);
            log.error(error);
          }
        );
        break;
      default:
        this.permissions[permissionIndex].status = false;
        break;
    }
  }

  requestPermissions(permission: string) {
    let msgRequired = 'Permiso requerido';

    let msgProperly = 'Es necesario que otorgues el permiso para el correcto funcionamiento de la aplicación.';
    let msgDeniedAlways =
      'Seleccionaste "No preguntar de nuevo". Ingresa a Configuracion>Aplicaciones>Conectimed>Permisos y otorga el permiso.';
    let permissionIndex = this.permissions.findIndex(x => x.name == permission);
    switch (permission) {
      case 'calendar':
        this.diagnostic.requestCalendarAuthorization().then(status => {
          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.DENIED_ONCE:
              this.showAlert(msgRequired, msgProperly, permission);
              break;
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.showAlert(msgRequired, msgDeniedAlways, permission);
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
        });
        break;
      case 'storage':
        this.diagnostic.requestExternalStorageAuthorization().then(status => {
          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.DENIED_ONCE:
              this.showAlert(msgRequired, msgProperly, permission);
              break;
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.showAlert(msgRequired, msgDeniedAlways, permission);
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
        });
        break;
      case 'camera':
        this.diagnostic.requestCameraAuthorization().then(status => {
          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.DENIED_ONCE:
              this.showAlert(msgRequired, msgProperly, permission);
              break;
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.showAlert(msgRequired, msgDeniedAlways, permission);
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
        });
        break;
      case 'microphone':
        this.diagnostic.requestMicrophoneAuthorization().then(status => {
          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.DENIED_ONCE:
              this.showAlert(msgRequired, msgProperly, permission);
              break;
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.showAlert(msgRequired, msgDeniedAlways, permission);
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
        });
        break;
      case 'camera-roll':
        this.diagnostic.requestCameraRollAuthorization().then(status => {
          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.DENIED_ONCE:
              this.showAlert(msgRequired, msgProperly, permission);
              break;
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.showAlert(msgRequired, msgDeniedAlways, permission);
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
        });
        break;
      case 'notification':
        this.diagnostic.requestRemoteNotificationsAuthorization().then(status => {
          switch (status) {
            case this.diagnostic.permissionStatus.GRANTED:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
              this.permissions[permissionIndex].status = true;
              break;
            case this.diagnostic.permissionStatus.DENIED_ONCE:
              this.showAlert(msgRequired, msgProperly, permission);
              break;
            case this.diagnostic.permissionStatus.DENIED_ALWAYS:
              this.showAlert(msgRequired, msgDeniedAlways, permission);
              break;
            default:
              this.permissions[permissionIndex].status = false;
              break;
          }
        });
        break;
      default:
        this.permissions[permissionIndex].status = false;
        break;
    }
  }

  async showAlert(title: string, msg: string, permission: string) {
    let permissionIndex = this.permissions.findIndex(x => x.name == permission);
    const alert = await this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.permissions[permissionIndex].status = false;
          }
        }
      ]
    });
    alert.present();
  }
}
