<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start" class="buttons-start">
      <ion-button [routerDirection]="'forward'" [routerLink]="['/login']" routerLinkActive="active" *ngIf="paso <= 1">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
      <ion-button *ngIf="paso > 1" (click)="slidePrev()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center">¡Bienvenido!</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content no-bounce>
  <ion-grid class="ion-no-padding" fixed>
    <ion-row class="ion-justify-content-center ion-no-padding">
      <ion-col size-sm="8" size-lg="6" size-xl="6" class="ion-align-self-center ion-no-padding">
        <ion-card color="success" *ngIf="completeYourRegister === true">
          <ion-card-header>
            <ion-card-title>Registro incompleto</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            Detectamos que el proceso de registro de su cuenta esta incompleto, para que usted pueda tener la mejor
            experiencia en la plataforma de <strong>Conectimed</strong>, lo invitamos a completar sus datos, gracias.
          </ion-card-content>
        </ion-card>
        <h1 class="ion-text-center">
          Registro perfil representante
        </h1>
        <div class="block" *ngIf="paso == 0">
          <app-pages [numberPages]="numberPages" [current]="0"></app-pages>
          <app-account-data-form (response)="step1($event)" defaultUserSelection="representante-medico">
          </app-account-data-form>
        </div>
        <div class="block" *ngIf="paso == 1">
          <app-pages [numberPages]="numberPages" [current]="1"></app-pages>
          <app-personal-data-form [user]="credService.credentials" (response)="slideNext()" modeData="register"
            userType="representante-medico">
          </app-personal-data-form>
        </div>
        <div class="block" *ngIf="paso == 2">
          <app-pages [numberPages]="numberPages" [current]="2"></app-pages>
          <app-company-data-form *ngIf="credService.credentials" [isProfile]="false" [user]="credService.credentials" (response)="userActivation()"
            button="Siguiente">
          </app-company-data-form>
        </div>
        <div class="block" *ngIf="paso == 3">
          <app-pages [numberPages]="numberPages" [current]="3"></app-pages>
          <app-profile-image *ngIf="credService.credentials" [user]="credService.credentials"></app-profile-image>
          <div class="ion-padding">
            <ion-button (click)="finalize()" expand="block">Finalizar</ion-button>
          </div>
        </div>
        <p class="ion-padding">
          Esta plataforma está dirigida a profesionales de la salud facultados para prescribir y/o representantes de la
          industria farmacéutica.
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>