<ion-content>
  <ion-grid fixed>
    <ion-row>
      <!-- <ion-col size="12" class="ion-text-center " offset="0" size-xl="8" offset-xl="2" size-lg="8" offset-lg="2"
        size-md="10" offset-md="1"> -->
      <ion-col class="ion-text-center" size="12">
        <ion-card>
          <div class="profilehero">
            <ion-avatar>
              <img [src]="user?.avatar ? user.avatar : 'assets/img/default-avatar.svg'" class="iab-disabled" />
            </ion-avatar>
          </div>
          <ion-card-header>
            <ion-card-title> {{ user?.data?.name | titlecase }} {{ user?.data?.lastName1 | titlecase }}
              {{ user?.data?.lastName2 | titlecase }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-chip color="secondary">
              <ion-icon [name]="
                  user?.data?.type === 'representante-medico' ? 'briefcase' : user?.data?.type === 'medico' ? 'medkit' : 'pulse-outline'
                "></ion-icon>
              <ion-label>{{
                user?.data?.type === 'medico'
                ? 'Medico'
                : user?.data?.type === 'representante-medico'
                ? 'Representante'
                : 'Profesional de la salud'
                }}</ion-label>
            </ion-chip>
          </ion-card-content>

          <ion-card-content *ngIf="user?.data?.type === 'medico'">
            <ion-item>
              <ion-label>
                <span *ngIf="user && user.address1 && user.address1.state && user.address1.city">
                  <p><b>Zona geográfica 1</b></p>
                  <h2 *ngIf="user.address1.state === 'Ciudad de México'">
                    {{ user.address1.state + ', ' + user.address1.colony }}
                  </h2>
                  <h2 *ngIf="user.address1.state !== 'Ciudad de México'">
                    {{ user.address1.state + ', ' + user.address1.city }}
                  </h2>
                </span>
                <div *ngIf="user && user.address2 && user.address2.state && user.address2 && user.address2.city">
                  <p><b>Zona geográfica 2</b></p>
                  <h2 *ngIf="user.address2.state === 'Ciudad de México'">
                    {{ user.address2.state + ', ' + user.address2.colony }}
                  </h2>
                  <h2 *ngIf="user.address2.state !== 'Ciudad de México'">
                    {{ user.address2.state + ', ' + user.address2.city }}
                  </h2>
                </div>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                <span *ngIf="user && user.specialty1 && user.specialty1.id !== 0">
                  <p><b>Especialidad 1</b></p>
                  <h2>
                    {{ user?.specialty1?.object?.name }}
                  </h2>
                </span>
                <div *ngIf="user && user.specialty2 && user.specialty2.id !== 0">
                  <p><b>Especialidad 2</b></p>
                  <h2>
                    {{ user?.specialty2?.object?.name }}
                  </h2>
                </div>
              </ion-label>
            </ion-item>
          </ion-card-content>

          <ion-card-content *ngIf="user?.data?.type === 'profesional-de-la-salud'">
            <ion-item>
              <ion-label>
                <span *ngIf="user && user.address1 && user.address1.state && user.address1.city">
                  <p><b>Zona geográfica</b></p>
                  <h2 *ngIf="user.address1.state === 'Ciudad de México'">
                    {{ user.address1.state + ', ' + user.address1.colony }}
                  </h2>
                  <h2 *ngIf="user.address1.state !== 'Ciudad de México'">
                    {{ user.address1.state + ', ' + user.address1.city }}
                  </h2>
                </span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                <span *ngIf="user && user.healthProfessionalType && user.healthProfessionalType.id">
                  <p><b>Tipo de profesional de la salud</b></p>
                  <h2>
                    {{ user?.healthProfessionalType?.name }}
                  </h2>
                </span>
              </ion-label>
            </ion-item>
          </ion-card-content>

          <ion-card-content *ngIf="user?.data?.type === 'representante-medico'">
            <app-i-company-card [company]="company"></app-i-company-card>
          </ion-card-content>

          <ion-card-content>
            <ion-button fill="solid" expand="block" [routerDirection]="'forward'"
              [routerLink]="['/industry/products/user/' + user?.data?.uid]" [disabled]="isLoading === true"
              *ngIf="user?.data?.type === 'representante-medico'" (click)="
              closeModal();
              analyticsClickEvent('view_products', {
                section_name: 'Perfil',
                company_owner: user?.name,
                product_owner: user?.data.name + ' ' + user?.data.lastName1 + ' ' + user?.data.lastName2
              })
            ">
              <ion-icon slot="start" name="cube-outline"></ion-icon>
              Productos
            </ion-button>
            <ion-button fill="solid" expand="block" (click)="chat(); analyticsClickEvent('open_chat', {})"
              [disabled]="isLoading === true">
              <ion-icon slot="start" name="chatbox-outline"></ion-icon>
              Chat
            </ion-button>
            <ion-button fill="solid" expand="block" [disabled]="isLoading === true"
              *ngIf="user?.data?.type === 'representante-medico'" (click)="
              mailto();
            ">
              <ion-icon slot="start" name="mail-outline"></ion-icon>
              Correo electrónico
            </ion-button>
          </ion-card-content>

        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>