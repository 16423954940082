<ion-content color="light">
  <ion-grid [fixed]="true">
    <ion-row>
      <ion-col size="12">
        <div *ngIf="!(completed === true); else completedComp" class="main-wrapper ion-text-center">
          <h2><strong>Validación de datos</strong></h2>
          <p>Por favor ingrese el código de verificación enviado a <strong>{{ _validatePerEmail === true ? email:
              displayPhone
              }}</strong></p>
          <code-input [initialFocusField]="0" [isCodeHidden]="false" [codeLength]="6"
            (codeChanged)="onCodeChanged($event)">
          </code-input>
          <br>
          <br>
          <br>
          <ion-button (click)="codeVerification()" color="secondary" expand="block"
            [disabled]="!(code && code.length == 6) || isLoading === true">
            Continuar
          </ion-button>
          <ion-button class="ion-text-wrap ion-no-padding" (click)="optionsAlert()" color="medium" fill="clear"
            shape="round" expand="block" *ngIf="counter <= 0; else noButton1">
            <p class="ion-text-left"> ¿No recibió el código? <ion-text color="primary"><strong>&nbsp;Cambiar de
                  método&nbsp;</strong></ion-text> de
              verificación o
              <ion-text color="primary"><strong>&nbsp;Reenviar
                  código</strong></ion-text>
            </p>
          </ion-button>
          <ng-template #noButton1>
            <ion-text color="medium">
              <p>Reintentar en {{ counter * 1000 | date: 'mm:ss' }}</p>
            </ion-text>
          </ng-template>
          <!-- <button (click)="deleteV()">Delete</button> -->
        </div>
        <ng-template #completedComp>
          <div class="main-wrapper ion-text-center">
            <ion-text color="secondary">
              <h2>¡Cuenta verificada correctamente!</h2>
              <br>
              <ion-icon name="checkmark-circle-outline" color="success" size="large"></ion-icon>
            </ion-text>
          </div>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>