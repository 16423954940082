<ion-grid [fixed]="false">
  <ion-row>
    <ion-col *ngFor="let o of [0, 0, 0, 0]" sizeXs="12" sizeMd="6">
      <ion-card>
        <ion-toolbar color="secondary">
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </ion-toolbar>
        <ion-row>
          <ion-col class="ion-text-center">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-col>
          <ion-col sizeXs="12" sizeMd="" style="padding: 0px;">
            <div class="read-content">
              <p>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
              </p>
              <p>
                <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 8ch"></ion-skeleton-text>
              </p>
            </div>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>