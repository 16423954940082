import { Injectable } from '@angular/core';

export interface Phone {
  number: string,
  lada: string,
  country: string,
  color?: string,
  error?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class LadaService {

  public countries: Phone[] = [
    {
      "country": "México",
      "lada": "52",
      "number": ""
    },
    {
      "country": "Estados Unidos",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Afganistán",
      "lada": "93",
      "number": ""
    },
    {
      "country": "Albania",
      "lada": "355",
      "number": ""
    },
    {
      "country": "Argelia",
      "lada": "213",
      "number": ""
    },
    {
      "country": "Andorra",
      "lada": "376",
      "number": ""
    },
    {
      "country": "Angola",
      "lada": "244",
      "number": ""
    },
    {
      "country": "Antártida",
      "lada": "672",
      "number": ""
    },
    {
      "country": "Antigua y Barbuda",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Argentina",
      "lada": "54",
      "number": ""
    },
    {
      "country": "Armenia",
      "lada": "374",
      "number": ""
    },
    {
      "country": "Aruba",
      "lada": "297",
      "number": ""
    },
    {
      "country": "Isla Ascensión",
      "lada": "247",
      "number": ""
    },
    {
      "country": "Australia",
      "lada": "61",
      "number": ""
    },
    {
      "country": "Austria",
      "lada": "43",
      "number": ""
    },
    {
      "country": "Azerbaiyán",
      "lada": "994",
      "number": ""
    },
    {
      "country": "Las Bahamas",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Reino de Baréin",
      "lada": "973",
      "number": ""
    },
    {
      "country": "Bangladesh",
      "lada": "880",
      "number": ""
    },
    {
      "country": "Barbados",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Belarús",
      "lada": "375",
      "number": ""
    },
    {
      "country": "Bélgica",
      "lada": "32",
      "number": ""
    },
    {
      "country": "Belice",
      "lada": "501",
      "number": ""
    },
    {
      "country": "Benín",
      "lada": "229",
      "number": ""
    },
    {
      "country": "Bermudas",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Bután",
      "lada": "975",
      "number": ""
    },
    {
      "country": "Bolivia",
      "lada": "591",
      "number": ""
    },
    {
      "country": "Bonaire",
      "lada": "599",
      "number": ""
    },
    {
      "country": "Bosnia y Herzegovina",
      "lada": "387",
      "number": ""
    },
    {
      "country": "Botsuana",
      "lada": "267",
      "number": ""
    },
    {
      "country": "Isla Bouvet",
      "lada": "47",
      "number": ""
    },
    {
      "country": "Brasil",
      "lada": "55",
      "number": ""
    },
    {
      "country": "Territorio Británico del Océano Índico",
      "lada": "44",
      "number": ""
    },
    {
      "country": "Islas Vírgenes Británicas",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Brunéi",
      "lada": "673",
      "number": ""
    },
    {
      "country": "Bulgaria",
      "lada": "359",
      "number": ""
    },
    {
      "country": "Burkina Faso",
      "lada": "226",
      "number": ""
    },
    {
      "country": "Burundi",
      "lada": "257",
      "number": ""
    },
    {
      "country": "Cabo Verde",
      "lada": "238",
      "number": ""
    },
    {
      "country": "Camboya",
      "lada": "855",
      "number": ""
    },
    {
      "country": "Camerún",
      "lada": "237",
      "number": ""
    },
    {
      "country": "Canadá",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Islas Caimán",
      "lada": "1",
      "number": ""
    },
    {
      "country": "República Centroafricana",
      "lada": "236",
      "number": ""
    },
    {
      "country": "Chad",
      "lada": "235",
      "number": ""
    },
    {
      "country": "Chile",
      "lada": "56",
      "number": ""
    },
    {
      "country": "China",
      "lada": "86",
      "number": ""
    },
    {
      "country": "Isla Christmas",
      "lada": "61",
      "number": ""
    },
    {
      "country": "Islas Cocos",
      "lada": "61",
      "number": ""
    },
    {
      "country": "Colombia",
      "lada": "57",
      "number": ""
    },
    {
      "country": "Comoras",
      "lada": "269",
      "number": ""
    },
    {
      "country": "República del Congo",
      "lada": "242",
      "number": ""
    },
    {
      "country": "Congo (RDC)",
      "lada": "243",
      "number": ""
    },
    {
      "country": "Islas Cook",
      "lada": "682",
      "number": ""
    },
    {
      "country": "Costa Rica",
      "lada": "506",
      "number": ""
    },
    {
      "country": "Côte d’Ivoire",
      "lada": "225",
      "number": ""
    },
    {
      "country": "Croacia (Hrvatska)",
      "lada": "385",
      "number": ""
    },
    {
      "country": "Cuba",
      "lada": "53",
      "number": ""
    },
    {
      "country": "Curazao",
      "lada": "599",
      "number": ""
    },
    {
      "country": "Chipre",
      "lada": "357",
      "number": ""
    },
    {
      "country": "Chequia",
      "lada": "420",
      "number": ""
    },
    {
      "country": "Dinamarca",
      "lada": "45",
      "number": ""
    },
    {
      "country": "Yibuti",
      "lada": "253",
      "number": ""
    },
    {
      "country": "Dominica",
      "lada": "1",
      "number": ""
    },
    {
      "country": "República Dominicana",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Ecuador",
      "lada": "593",
      "number": ""
    },
    {
      "country": "Egipto",
      "lada": "20",
      "number": ""
    },
    {
      "country": "El Salvador",
      "lada": "503",
      "number": ""
    },
    {
      "country": "Guinea Ecuatorial",
      "lada": "240",
      "number": ""
    },
    {
      "country": "Eritrea",
      "lada": "291",
      "number": ""
    },
    {
      "country": "Estonia",
      "lada": "372",
      "number": ""
    },
    {
      "country": "Etiopía",
      "lada": "251",
      "number": ""
    },
    {
      "country": "Islas Malvinas",
      "lada": "500",
      "number": ""
    },
    {
      "country": "Islas Feroe",
      "lada": "298",
      "number": ""
    },
    {
      "country": "Fiyi",
      "lada": "679",
      "number": ""
    },
    {
      "country": "Finlandia",
      "lada": "358",
      "number": ""
    },
    {
      "country": "Francia",
      "lada": "33",
      "number": ""
    },
    {
      "country": "Guayana Francesa",
      "lada": "594",
      "number": ""
    },
    {
      "country": "Polinesia Francesa",
      "lada": "689",
      "number": ""
    },
    {
      "country": "Gabón",
      "lada": "241",
      "number": ""
    },
    {
      "country": "Gambia",
      "lada": "220",
      "number": ""
    },
    {
      "country": "Georgia",
      "lada": "995",
      "number": ""
    },
    {
      "country": "Alemania",
      "lada": "49",
      "number": ""
    },
    {
      "country": "Ghana",
      "lada": "233",
      "number": ""
    },
    {
      "country": "Gibraltar",
      "lada": "350",
      "number": ""
    },
    {
      "country": "Grecia",
      "lada": "30",
      "number": ""
    },
    {
      "country": "Groenlandia",
      "lada": "299",
      "number": ""
    },
    {
      "country": "Granada",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Guadalupe",
      "lada": "590",
      "number": ""
    },
    {
      "country": "Guam",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Guatemala",
      "lada": "502",
      "number": ""
    },
    {
      "country": "Guernsey",
      "lada": "44",
      "number": ""
    },
    {
      "country": "Guinea",
      "lada": "224",
      "number": ""
    },
    {
      "country": "Guinea-Bissau",
      "lada": "245",
      "number": ""
    },
    {
      "country": "Guyana",
      "lada": "592",
      "number": ""
    },
    {
      "country": "Haití",
      "lada": "509",
      "number": ""
    },
    {
      "country": "Honduras",
      "lada": "504",
      "number": ""
    },
    {
      "country": "Hong Kong RAE",
      "lada": "852",
      "number": ""
    },
    {
      "country": "Hungría",
      "lada": "36",
      "number": ""
    },
    {
      "country": "Islandia",
      "lada": "354",
      "number": ""
    },
    {
      "country": "India",
      "lada": "91",
      "number": ""
    },
    {
      "country": "Indonesia",
      "lada": "62",
      "number": ""
    },
    {
      "country": "Irán",
      "lada": "98",
      "number": ""
    },
    {
      "country": "Irak",
      "lada": "964",
      "number": ""
    },
    {
      "country": "Irlanda",
      "lada": "353",
      "number": ""
    },
    {
      "country": "Isla de Man",
      "lada": "44",
      "number": ""
    },
    {
      "country": "Israel",
      "lada": "972",
      "number": ""
    },
    {
      "country": "Italia",
      "lada": "39",
      "number": ""
    },
    {
      "country": "Jamaica",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Jan Mayen",
      "lada": "47",
      "number": ""
    },
    {
      "country": "Japón",
      "lada": "81",
      "number": ""
    },
    {
      "country": "Jersey",
      "lada": "44",
      "number": ""
    },
    {
      "country": "Jordania",
      "lada": "962",
      "number": ""
    },
    {
      "country": "Kazajistán",
      "lada": "7",
      "number": ""
    },
    {
      "country": "Kenia",
      "lada": "254",
      "number": ""
    },
    {
      "country": "Kiribati",
      "lada": "686",
      "number": ""
    },
    {
      "country": "Corea del Sur",
      "lada": "82",
      "number": ""
    },
    {
      "country": "Kosovo",
      "lada": "383",
      "number": ""
    },
    {
      "country": "Kuwait",
      "lada": "965",
      "number": ""
    },
    {
      "country": "Kirguistán",
      "lada": "996",
      "number": ""
    },
    {
      "country": "Laos",
      "lada": "856",
      "number": ""
    },
    {
      "country": "Letonia",
      "lada": "371",
      "number": ""
    },
    {
      "country": "Líbano",
      "lada": "961",
      "number": ""
    },
    {
      "country": "Lesoto",
      "lada": "266",
      "number": ""
    },
    {
      "country": "Liberia",
      "lada": "231",
      "number": ""
    },
    {
      "country": "Libia",
      "lada": "218",
      "number": ""
    },
    {
      "country": "Liechtenstein",
      "lada": "423",
      "number": ""
    },
    {
      "country": "Lituania",
      "lada": "370",
      "number": ""
    },
    {
      "country": "Luxemburgo",
      "lada": "352",
      "number": ""
    },
    {
      "country": "Macao RAE",
      "lada": "853",
      "number": ""
    },
    {
      "country": "Macedonia del Norte",
      "lada": "389",
      "number": ""
    },
    {
      "country": "Madagascar",
      "lada": "261",
      "number": ""
    },
    {
      "country": "Malawi",
      "lada": "265",
      "number": ""
    },
    {
      "country": "Malasia",
      "lada": "60",
      "number": ""
    },
    {
      "country": "Maldivas",
      "lada": "960",
      "number": ""
    },
    {
      "country": "Malí",
      "lada": "223",
      "number": ""
    },
    {
      "country": "Malta",
      "lada": "356",
      "number": ""
    },
    {
      "country": "Islas Marshall",
      "lada": "692",
      "number": ""
    },
    {
      "country": "Martinica",
      "lada": "596",
      "number": ""
    },
    {
      "country": "Mauritania",
      "lada": "222",
      "number": ""
    },
    {
      "country": "Mauricio",
      "lada": "230",
      "number": ""
    },
    {
      "country": "Mayotte",
      "lada": "262",
      "number": ""
    },
    {
      "country": "Micronesia",
      "lada": "691",
      "number": ""
    },
    {
      "country": "Moldova",
      "lada": "373",
      "number": ""
    },
    {
      "country": "Mónaco",
      "lada": "377",
      "number": ""
    },
    {
      "country": "Mongolia",
      "lada": "976",
      "number": ""
    },
    {
      "country": "Montenegro",
      "lada": "382",
      "number": ""
    },
    {
      "country": "Montserrat",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Marruecos",
      "lada": "212",
      "number": ""
    },
    {
      "country": "Mozambique",
      "lada": "258",
      "number": ""
    },
    {
      "country": "Myanmar",
      "lada": "95",
      "number": ""
    },
    {
      "country": "Namibia",
      "lada": "264",
      "number": ""
    },
    {
      "country": "Nauru",
      "lada": "674",
      "number": ""
    },
    {
      "country": "Nepal",
      "lada": "977",
      "number": ""
    },
    {
      "country": "Países Bajos",
      "lada": "31",
      "number": ""
    },
    {
      "country": "Antiguas Antillas Holandesas",
      "lada": "599",
      "number": ""
    },
    {
      "country": "Nueva Caledonia",
      "lada": "687",
      "number": ""
    },
    {
      "country": "Nueva Zelanda",
      "lada": "64",
      "number": ""
    },
    {
      "country": "Nicaragua",
      "lada": "505",
      "number": ""
    },
    {
      "country": "Níger",
      "lada": "227",
      "number": ""
    },
    {
      "country": "Nigeria",
      "lada": "234",
      "number": ""
    },
    {
      "country": "Niue",
      "lada": "683",
      "number": ""
    },
    {
      "country": "Islas Marianas del Norte",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Noruega",
      "lada": "47",
      "number": ""
    },
    {
      "country": "Omán",
      "lada": "968",
      "number": ""
    },
    {
      "country": "Pakistán",
      "lada": "92",
      "number": ""
    },
    {
      "country": "Palaos",
      "lada": "680",
      "number": ""
    },
    {
      "country": "Autoridad Palestina",
      "lada": "970",
      "number": ""
    },
    {
      "country": "Panamá",
      "lada": "507",
      "number": ""
    },
    {
      "country": "Papúa Nueva Guinea",
      "lada": "675",
      "number": ""
    },
    {
      "country": "Paraguay",
      "lada": "595",
      "number": ""
    },
    {
      "country": "Perú",
      "lada": "51",
      "number": ""
    },
    {
      "country": "Filipinas",
      "lada": "63",
      "number": ""
    },
    {
      "country": "Polonia",
      "lada": "48",
      "number": ""
    },
    {
      "country": "Portugal",
      "lada": "351",
      "number": ""
    },
    {
      "country": "Qatar",
      "lada": "974",
      "number": ""
    },
    {
      "country": "Reunión",
      "lada": "262",
      "number": ""
    },
    {
      "country": "Rumania",
      "lada": "40",
      "number": ""
    },
    {
      "country": "Rusia",
      "lada": "7",
      "number": ""
    },
    {
      "country": "Ruanda",
      "lada": "250",
      "number": ""
    },
    {
      "country": "Saba",
      "lada": "599",
      "number": ""
    },
    {
      "country": "San Cristóbal y Nieves",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Santa Lucía",
      "lada": "1",
      "number": ""
    },
    {
      "country": "San Pedro y Miquelón",
      "lada": "508",
      "number": ""
    },
    {
      "country": "San Vicente y las Granadinas",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Samoa",
      "lada": "685",
      "number": ""
    },
    {
      "country": "San Marino",
      "lada": "378",
      "number": ""
    },
    {
      "country": "Santo Tomé y Príncipe",
      "lada": "239",
      "number": ""
    },
    {
      "country": "Arabia Saudí",
      "lada": "966",
      "number": ""
    },
    {
      "country": "Senegal",
      "lada": "221",
      "number": ""
    },
    {
      "country": "Serbia",
      "lada": "381",
      "number": ""
    },
    {
      "country": "Seychelles",
      "lada": "248",
      "number": ""
    },
    {
      "country": "Sierra Leona",
      "lada": "232",
      "number": ""
    },
    {
      "country": "Singapur",
      "lada": "65",
      "number": ""
    },
    {
      "country": "San Eustaquio",
      "lada": "599",
      "number": ""
    },
    {
      "country": "Eslovaquia",
      "lada": "421",
      "number": ""
    },
    {
      "country": "Eslovenia",
      "lada": "386",
      "number": ""
    },
    {
      "country": "Islas Salomón",
      "lada": "677",
      "number": ""
    },
    {
      "country": "Somalia",
      "lada": "252",
      "number": ""
    },
    {
      "country": "Sudáfrica",
      "lada": "27",
      "number": ""
    },
    {
      "country": "Sudán del Sur",
      "lada": "211",
      "number": ""
    },
    {
      "country": "España",
      "lada": "34",
      "number": ""
    },
    {
      "country": "Sri Lanka",
      "lada": "94",
      "number": ""
    },
    {
      "country": "Santa Elena, Ascensión y Tristán de Acuña",
      "lada": "290",
      "number": ""
    },
    {
      "country": "Sudán",
      "lada": "249",
      "number": ""
    },
    {
      "country": "Surinam",
      "lada": "597",
      "number": ""
    },
    {
      "country": "Svalbard",
      "lada": "47",
      "number": ""
    },
    {
      "country": "Suazilandia",
      "lada": "268",
      "number": ""
    },
    {
      "country": "Suecia",
      "lada": "46",
      "number": ""
    },
    {
      "country": "Suiza",
      "lada": "41",
      "number": ""
    },
    {
      "country": "Siria",
      "lada": "963",
      "number": ""
    },
    {
      "country": "Taiwán",
      "lada": "886",
      "number": ""
    },
    {
      "country": "Tayikistán",
      "lada": "992",
      "number": ""
    },
    {
      "country": "Tanzania",
      "lada": "255",
      "number": ""
    },
    {
      "country": "Tailandia",
      "lada": "66",
      "number": ""
    },
    {
      "country": "Timor-Leste",
      "lada": "670",
      "number": ""
    },
    {
      "country": "Togo",
      "lada": "228",
      "number": ""
    },
    {
      "country": "Tokelau",
      "lada": "690",
      "number": ""
    },
    {
      "country": "Tonga",
      "lada": "676",
      "number": ""
    },
    {
      "country": "Trinidad y Tobago",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Tristán de Acuña",
      "lada": "290",
      "number": ""
    },
    {
      "country": "Túnez",
      "lada": "216",
      "number": ""
    },
    {
      "country": "Turquía",
      "lada": "90",
      "number": ""
    },
    {
      "country": "Turkmenistán",
      "lada": "993",
      "number": ""
    },
    {
      "country": "Islas Turcas y Caicos",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Tuvalu",
      "lada": "688",
      "number": ""
    },
    {
      "country": "Islas menores alejadas de los EE.UU.",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Islas Vírgenes de los Estados Unidos",
      "lada": "1",
      "number": ""
    },
    {
      "country": "Uganda",
      "lada": "256",
      "number": ""
    },
    {
      "country": "Ucrania",
      "lada": "380",
      "number": ""
    },
    {
      "country": "Emiratos Árabes Unidos",
      "lada": "971",
      "number": ""
    },
    {
      "country": "Reino Unido",
      "lada": "44",
      "number": ""
    },
    {
      "country": "Uruguay",
      "lada": "598",
      "number": ""
    },
    {
      "country": "Uzbekistán",
      "lada": "998",
      "number": ""
    },
    {
      "country": "Vanuatu",
      "lada": "678",
      "number": ""
    },
    {
      "country": "Ciudad del Vaticano",
      "lada": "379",
      "number": ""
    },
    {
      "country": "Venezuela",
      "lada": "58",
      "number": ""
    },
    {
      "country": "Vietnam",
      "lada": "84",
      "number": ""
    },
    {
      "country": "Wallis y Futuna",
      "lada": "681",
      "number": ""
    },
    {
      "country": "Yemen",
      "lada": "967",
      "number": ""
    },
    {
      "country": "Zambia",
      "lada": "260",
      "number": ""
    },
    {
      "country": "Zimbabue",
      "lada": "263",
      "number": ""
    }
  ];

  constructor() { }
}
