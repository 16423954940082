import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@app/core/logger.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { SearchService } from '@app/shared/services/search/search.service';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { ModalController } from '@ionic/angular';
const log = new Logger('SpecilatySelectComponent');

@Component({
  selector: 'app-specilaty-select',
  templateUrl: './specilaty-select.component.html',
  styleUrls: ['./specilaty-select.component.scss'],
})
export class SpecilatySelectComponent implements OnInit {
  public specialties: any[] = [];
  public arrayFiltered: any[] = [];
  public isLoading: boolean = false;
  public dataForm: FormGroup;
  public value: string = '';

  constructor(
    private modalCtrl: ModalController,
    private specialtiesService: SpecialtiesService,
    private loader: IonLoaderService,
    private searchService: SearchService,
    private formBuilder: FormBuilder
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.inializeApp();
  }

  private initForm() {
    this.dataForm = this.formBuilder.group({
      specialty: ['', [Validators.required]]
    });
  }

  select() {
    const index: number = this.arrayFiltered.map(e => e.id).indexOf(this.dataForm.get('specialty').value);
    if (index > -1) {
      this.modalCtrl.dismiss(this.arrayFiltered[index], 'data');
    }
  }

  other() {
    this.modalCtrl.dismiss({ id: 'profesional-de-la-salud', name: 'Otro' }, 'data');
  }

  async inializeApp() {
    this.isLoading = true;
    await this.loader.ionLoaderPresent();
    try {
      await this.initSpecialties();
      if (this.value) {
        this.dataForm.get('specialty').patchValue(this.value);
      }
    } catch (error) {
      log.error(error);
    }
    await this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  closeModal() {
    this.modalCtrl.dismiss({}, 'close');
  }

  async initSpecialties(): Promise<void> {
    try {
      const specialties: any[] = await this.specialtiesService.getSpecialties();
      this.specialties = specialties.map(e => {
        return {
          id: Number(e.id),
          name: e.name
        };
      });
      this.arrayFiltered = [...this.specialties];
    } catch (error) {
      log.error(error);
    }
  }

  search(event: any) {
    if (event && event.detail && event.detail.value && String(event.detail.value).length >= 3) {
      const searchStr: string = this.searchService.clearSearch(String(event.detail.value), true);
      this.arrayFiltered = this.specialties.filter((item) => {
        const name: string = this.searchService.clearSearch(String(item && item.name ? item.name : ''), true)
        return (name.includes(searchStr));
      })
    }
  }

  public ionCancel() {
    this.arrayFiltered = [...this.specialties];
  }

  public ionClear() {
    this.arrayFiltered = [...this.specialties];
  }
}
