<ion-content color="light">
  <ion-grid fixed class="ion-no-padding ion-text-center">
    <ion-toolbar>
      <ion-buttons slot="end" class="buttons-end">
        <ion-button (click)="closeModal()">
          <ion-icon name="close-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-row>
      <ion-col size="12">
        <img src="assets/img/logo-horizontal.png" alt="Logo" />
        <div *ngIf="credService?.credentials?.uid && credService?.credentials?.active === false; else activateAccount">
          <p>
            Hemos detectado que usted ya tiene una cuenta, por favor actívela para poder usar la plataforma de
            Conectimed.
          </p>
          <ion-row>
            <ion-col size="12" class="ion-text-center">
              <ion-button (click)="closeModal()" [routerLink]="['/register/account-activation']" color="primary"
                fill="outline">
                Activar mi cuenta
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
        <ng-template #activateAccount>
          <p>Para continuar por favor ingrese con su cuenta o regístrese a la plataforma.</p>
          <ion-grid fixed>
            <ion-row>
              <ion-col size="12" class="ion-text-center">
                <ion-button (click)="openLogin()" color="secondary" fill="outline"> Estoy registrado </ion-button>
                <ion-button (click)="closeModal()" [routerLink]="['/register']" color="secondary" fill="outline">
                  Registrarme
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>