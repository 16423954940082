import { Injectable } from '@angular/core';
import { SampleRequestComponent } from '@app/industry/components/sample-request/sample-request.component';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SampleRequestService {

  constructor(private modalCtrl: ModalController) { }

  async request(id: string) {
    const modal = await this.modalCtrl.create({
      component: SampleRequestComponent,
      componentProps: { productId: id }
    });
    modal.present();
  }
}
