<ion-input type="text" [(ngModel)]="advertising.id" class="ion-hide" (ionChange)="generateRoute()"></ion-input>
<div class="add no-fluid" *ngIf="show && userType === 'medico' && inHome=== true"
  [ngClass]="{ 'fluid': inHome === true ? true :false}">
  <div class="white">
    <ion-grid [fixed]="false">
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col class="ion-hide-lg-up" size-xl="12" size-lg="12" size-md="6" size-sm="6" size-xs="6">
          <p>{{ advertising?.content }}</p>
        </ion-col>
        <ion-col size-xl="12" size-lg="12" size-md="6" size-sm="6" size-xs="6">
          <button class="close-button" (click)="close()" *ngIf="advertising?.type === 'popup' || inHome">
            <ion-icon name="close-outline"></ion-icon>
          </button>
          <img [src]="advertising?.img" (click)="analyticsClickEvent('advertising_banner_click'); redirect()" />
        </ion-col>
        <ion-col class="ion-hide-lg-down" size="12">
          <p>{{ advertising?.content }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>

<div class="add" *ngIf="show && userType === 'medico' && inHome !== true">
  <ion-row>
    <ion-col size="auto" style="max-height: 150px;">
      <img [ngClass]="{ border: advertising?.type === 'popup' }" [src]="advertising?.img" class="put-img"
        style="opacity: 0.7;" (click)="analyticsClickEvent('advertising_banner_click'); redirect()" />
    </ion-col>
    <ion-col size="" style="max-height: 150px;">
      <ion-app>
        <ion-content [scrollY]="false">
          <button class="close-button" (click)="close()" *ngIf="advertising?.type === 'popup' || inHome">
            <ion-icon name="close-outline"></ion-icon>
          </button>
          <div style="height: 100%; padding-right: 20px;"
            (click)="analyticsClickEvent('advertising_banner_click'); redirect()">
            <ion-row class="ion-align-items-center" style="height: 100%;">
              <ion-col size="12" style="padding: 10px;">
                <p>{{ advertising?.content }}</p>
              </ion-col>
            </ion-row>
          </div>
        </ion-content>
      </ion-app>
    </ion-col>
  </ion-row>
</div>