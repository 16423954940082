<ion-header *ngIf="viewType === 'modal'">
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center"> Seleccione a los contactos </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal(); analyticsClickEvent('close', {})">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-header *ngIf="viewType === 'chat'">
  <ion-toolbar color="tertiary">
    <ion-title class="ion-text-center"> Seleccione contacto </ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal(); analyticsClickEvent('close', {})">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="viewType === 'modal' || viewType === 'chat'">
  <div>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-searchbar class="ion-margin-top" type="text" debounce="1000" (ionChange)="doSearch($event)"
            (ionCancel)="clearSearch()" (ionClear)="clearSearch()" enterkeyhint="send" placeholder="Buscar" animated
            [disabled]="isLoading">
          </ion-searchbar>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col sizeXs="12" sizeMd="6">
          <ion-button fill="solid" *ngIf="newMembers !== true" expand="block" color="tertiary"
            (click)="specialtiesFilter()" [disabled]="isLoading" class="ion-text-wrap">
            <ion-icon name="school-outline" slot="start"></ion-icon>
            Segmentar por especialidad
          </ion-button>
        </ion-col>
        <ion-col sizeXs="12" sizeMd="6">
          <ion-button fill="solid" *ngIf="newMembers !== true" expand="block" color="tertiary" (click)="statesFilter()"
            [disabled]="isLoading" class="ion-text-wrap">
            <ion-icon name="location-outline" slot="start"></ion-icon>
            Segmentar por zona geográfica
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button shape="round" color="primary" size="small" *ngFor="let item of filterData2; let i = index"
            (click)="removeEspecialtiesFilter()" [disabled]="isLoading">
            {{ item.label }}
            <ion-icon slot="end" name="close-outline"></ion-icon>
          </ion-button>
          <ion-button shape="round" color="primary" size="small" *ngFor="let item of filterData1; let i = index"
            (click)="removeStatesFilter()" [disabled]="isLoading">
            {{ item.label }}
            <ion-icon slot="end" name="close-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div *ngIf="arrayDocs && arrayDocs[0]">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-list>
              <ion-item *ngFor="let item of arrayDocs; let i = index"
                (click)="goChat(item.id); analyticsClickEvent('open_chat', {})">
                <ion-avatar slot="start">
                  <img [src]="
                        item?.avatar?.list?.url ? item.avatar.list.url : 'assets/img/default-' + item.type + '.jpg'
                      " />
                </ion-avatar>
                <ion-label>
                  <app-select-user-card [item]="item"> </app-select-user-card>
                </ion-label>
                <ion-checkbox *ngIf="viewType === 'modal'" slot="end" [(ngModel)]="item.isChecked"
                  (click)="selectUser(item); analyticsClickEvent(nameEvent, {})"></ion-checkbox>
              </ion-item>
            </ion-list>
            <ion-button *ngIf="forward === true && isLoading === false"
              (click)="nextPage(); analyticsClickEvent('next_page', {})" expand="block" fill="clear" shape="round">
              Cargar mas resultados
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <ng-template #medicoBlock>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-searchbar class="ion-margin-top" type="text" debounce="1000" (ionChange)="doSearch($event)"
              (ionCancel)="clearSearch()" (ionClear)="clearSearch()" enterkeyhint="send" placeholder="Buscar" animated
              [disabled]="isLoading" *ngIf="searchMemberType">
            </ion-searchbar>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div *ngIf="!(arrayDocs && arrayDocs[0]) && !searchMemberType">
        <ion-grid>
          <ion-row>
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="12">
              <ion-card button="true" (click)="
                        searchMemberType = 'medico';
                        analyticsClickEvent('click', {
                          content_type: 'Buscar médicos'
                        })
                      " [disabled]="isLoading" class="menu-item menu-item-big card-button-1">
                <ion-icon slot="start" name="medkit-outline"></ion-icon>
                <h2>Buscar médicos</h2>
              </ion-card>
            </ion-col>
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="12">
              <ion-card button="true" (click)="
                        searchMemberType = 'representante-medico';
                        analyticsClickEvent('click', {
                          content_type: 'Buscar representante médicos'
                        })
                      " [disabled]="isLoading" class="menu-item menu-item-big card-button-1">
                <ion-icon slot="start" name="briefcase-outline"></ion-icon>
                <h2>Buscar representante médicos</h2>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div *ngIf="arrayDocs && arrayDocs[0]">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-list>
                <ion-item *ngFor="let item of arrayDocs; let i = index" lines="none"
                  (click)="goChat(item.id); analyticsClickEvent('open_chat', {})">
                  <ion-avatar slot="start">
                    <img [src]="
                              item?.avatar?.list?.url ? item.avatar.list.url : 'assets/img/default-' + item.type + '.jpg'
                            " />
                  </ion-avatar>
                  <ion-label slot="start">
                    <app-select-user-card [item]="item"> </app-select-user-card>
                  </ion-label>
                  <ion-checkbox *ngIf="viewType === 'modal'" slot="end" [(ngModel)]="item.isChecked"
                    (click)="selectUser(item); analyticsClickEvent(nameEvent, {})"></ion-checkbox>
                </ion-item>
              </ion-list>
              <ion-button *ngIf="forward === true && isLoading === false"
                (click)="nextPage(); analyticsClickEvent('next_page', {})" expand="block" fill="clear" shape="round">
                Cargar mas resultados
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ng-template>
  </div>
</ion-content>

<ion-content *ngIf="viewType === 'component'">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-progress-bar type="indeterminate" *ngIf="isLoading === true"></ion-progress-bar>
        <ion-grid class="ion-no-margin ion-no-padding ion-margin-bottom">
          <ion-row *ngIf="credService.credentials.defaultUser === true">
            <ion-col size="12">
              <ion-buttons class="switch">
                <ion-button expand="block" [ngClass]="{ 'btn-switch': true, active: searchMemberType === 'medico' }"
                  (click)="
                    changeMemberType('medico');
                    analyticsClickEvent('change_member', {
                      type: 'Medico'
                    })
                  ">
                  Médicos
                </ion-button>
                <ion-button expand="block" [ngClass]="{
                    'btn-switch': true,
                    'btn-representante': true,
                    active: searchMemberType === 'representante-medico'
                  }" (click)="
                    changeMemberType('representante-medico');
                    analyticsClickEvent('change_member', {
                      type: 'Representante-Medico'
                    })
                  ">
                  Representantes
                </ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
          <ion-row class="ion-no-margin ion-no-padding" *ngIf="searchMemberType === 'medico'">
            <ion-col sizeXs="12" sizeMd="6">
              <ion-grid class="ion-no-margin ion-no-padding">
                <ion-row class="ion-no-margin ion-no-padding">
                  <ion-col class="ion-no-margin ion-no-padding" [size]="filterData2[0]?.label ? 10 : 12">
                    <ion-button (click)="specialtiesFilter()" [color]="filterData2[0]?.label ? 'tertiary' : 'secondary'"
                      *ngIf="newMembers !== true" expand="block" shape="round" size="small"
                      [disabled]="isLoading === true">
                      <ion-icon slot="start" name="school-outline"></ion-icon>
                      {{ filterData2[0]?.label ? filterData2[0]?.label : 'Segmentar por especialidad' }}
                    </ion-button>
                  </ion-col>
                  <ion-col *ngIf="filterData2[0]?.label" size="2" class="ion-no-margin ion-no-padding">
                    <ion-button (click)="removeEspecialtiesFilter(true)" color="medium" fill="clear" expand="block"
                      shape="round" size="small">
                      <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
            <ion-col sizeXs="12" sizeMd="6">
              <ion-grid class="ion-no-margin ion-no-padding">
                <ion-row class="ion-no-margin ion-no-padding">
                  <ion-col class="ion-no-margin ion-no-padding" [size]="filterData1[0]?.label ? 10 : 12">
                    <ion-button (click)="statesFilter()" [color]="filterData1[0]?.label ? 'tertiary' : 'secondary'"
                      *ngIf="newMembers !== true" expand="block" shape="round" size="small"
                      [disabled]="isLoading === true">
                      <ion-icon slot="start" name="location-outline"></ion-icon>
                      {{ filterData1[0]?.label ? filterData1[0]?.label : 'Segmentar por zona geográfica' }}
                    </ion-button>
                  </ion-col>
                  <ion-col *ngIf="filterData1[0]?.label" size="2" class="ion-no-margin ion-no-padding">
                    <ion-button (click)="removeStatesFilter(true)" color="medium" fill="clear" expand="block"
                      shape="round" size="small">
                      <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center" *ngIf="searchMemberType === 'representante-medico'">
            <ion-col size-xl="6" size-lg="6" size-md="6" size-sm="6" size-xs="12">
              <ion-grid class="ion-no-margin ion-no-padding">
                <ion-row class="ion-no-margin ion-no-padding">
                  <ion-col class="ion-no-margin ion-no-padding" [size]="filterData3[0]?.label ? 10 : 12">
                    <ion-button (click)="companiesFilter()" [color]="filterData3[0]?.label ? 'tertiary' : 'secondary'"
                      *ngIf="newMembers !== true && credService?.credentials?.type !== 'profesional-de-la-salud'"
                      expand="block" shape="round" size="small" [disabled]="isLoading === true">
                      <ion-icon slot="start" name="briefcase-outline"></ion-icon>
                      {{ filterData3[0]?.label ? filterData3[0]?.label : 'Segmentar por Empresas' }}
                    </ion-button>
                  </ion-col>
                  <ion-col *ngIf="filterData3[0]?.label" size="2" class="ion-no-margin ion-no-padding">
                    <ion-button (click)="removeCompaniesFilter(true)" color="medium" fill="clear" expand="block"
                      shape="round" size="small">
                      <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-grid class="ion-no-margin ion-no-padding">
          <ion-row *ngIf="isLoading">
            <ion-col class="ion-no-margin ion-no-padding">
              <ion-list>
                <ion-item *ngFor="let i of [0, 1, 2, 3, 4]">
                  <ion-thumbnail slot="start">
                    <ion-skeleton-text></ion-skeleton-text>
                  </ion-thumbnail>
                  <ion-label>
                    <h3>
                      <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                    </h3>
                    <p>
                      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
                    </p>
                    <p>
                      <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
                    </p>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="arrayDocs && arrayDocs[0]">
            <ion-col class="ion-no-margin ion-no-padding">
              <ion-list>
                <app-user-card *ngFor="let item of arrayDocs; let i = index" [index]="i + 1" [item]="item"
                  [viewOptionsButtons]="true" (click)="
                        analyticsClickEvent('view_user_detail', {
                          user_filter_type: filter_type
                        })
                      ">
                </app-user-card>
              </ion-list>
              <ion-button class="ion-margin-top" *ngIf="forward === true && isLoading === false" (click)="nextPage()"
                expand="block" fill="clear" shape="round">
                Cargar mas resultados
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer *ngIf="viewType === 'modal'">
  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <ion-button (click)="clear(); analyticsClickEvent('clear', {})" expand="block" color="danger" shape="outline"
          [disabled]="isLoading">
          <ion-icon slot="start" name="refresh-outline"></ion-icon>
          Limpiar
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button (click)="goChatGroup(); analyticsClickEvent('open_chatgroup', {})" expand="block" color="secondary"
          [disabled]="isLoading || !(selectedUsers.length > 0)">
          <ion-icon slot="start" name="add-outline"></ion-icon>
          Seleccionar ({{ selectedUsers.length }})
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>