import { Injectable } from '@angular/core';
import { Logger } from '@app/core/logger.service';
import { LoadingController } from '@ionic/angular';
const log = new Logger('IonLoaderService');

@Injectable({
  providedIn: 'root'
})
export class IonLoaderService {

  loading: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController) { }

  async ionLoaderPresent() {
    this.loading = await this.loadingCtrl.create({
      message: 'Cargando información, por favor espere...',
      duration: 5000,
    });
    this.loading.present();
  }

  async ionLoaderDismiss() {
    try {
      this.loading.dismiss();
    } catch (error) {
      log.error(error);
    }
  }
}
