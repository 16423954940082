import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { IndustryRoutingModule } from './industry-routing.module';
import { ICompaniesComponent } from './sections/i-companies/i-companies.component';
import { IDevicesComponent } from './sections/i-devices/i-devices.component';
import { IMedicinesComponent } from './sections/i-medicines/i-medicines.component';
import { IServicesComponent } from './sections/i-services/i-services.component';
import { IMyProductsComponent } from './sections/i-my-products/i-my-products.component';
import { IRepProductsComponent } from './sections/i-rep-products/i-rep-products.component';
import { IProductComponent } from './components/i-product/i-product.component';
import { ICompanyComponent } from './components/i-company/i-company.component';
import { SampleRequestComponent } from './components/sample-request/sample-request.component';
import { INoResultsComponent } from './components/i-no-results/i-no-results.component';
import { ISkeletonComponent } from './components/i-skeleton/i-skeleton.component';
import { IItemComponent } from './components/i-item/i-item.component';
import { IRepCardComponent } from './components/i-rep-card/i-rep-card.component';
import { ICompanyCardModule } from './modules/i-company-card/i-company-card.module';
import { IAddProductComponent } from './components/i-add-product/i-add-product.component';
import { IPdfProductsComponent } from './components/i-pdf-products/i-pdf-products.component';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '@app/pipes/pipes.module';
@NgModule({
  declarations: [
    ICompaniesComponent,
    ICompanyComponent,
    IDevicesComponent,
    IMedicinesComponent,
    IProductComponent,
    IServicesComponent,
    SampleRequestComponent,
    INoResultsComponent,
    ISkeletonComponent,
    IItemComponent,
    IRepCardComponent,
    IMyProductsComponent,
    IRepProductsComponent,
    IAddProductComponent,
    IPdfProductsComponent
  ],
  imports: [
    CommonModule,
    IndustryRoutingModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PipesModule,
    ICompanyCardModule
  ]
})
export class IndustryModule { }
