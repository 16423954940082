<div class="ion-padding bg">
    <ion-button type="submit" color="secondary" fill="outline" expand="block" (click)="collapsed = !collapsed">
      {{ title }}
      <ion-icon slot="end" name="add-outline" *ngIf="collapsed === true"></ion-icon>
      <ion-icon slot="end" name="remove-outline" *ngIf="!collapsed === true"></ion-icon>
    </ion-button>
</div>
<form [formGroup]="dataForm" (ngSubmit)="submit" *ngIf="!collapsed === true">
  <ion-list>
    <ion-item [lines]="dataForm.get('street').value === '' ? 'true' : 'none'">
      <ion-input label="Calle" type="text" formControlName="street" autofocus="true"></ion-input>
    </ion-item>
    <ion-item [lines]="dataForm.get('outsideNumber').value === '' ? 'true' : 'none'">
      <ion-input label="Número exterior" type="text" formControlName="outsideNumber"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Número interior" type="text" formControlName="interiorNumber"></ion-input>
    </ion-item>
    <ion-item [lines]="dataForm.get('postalCode').value === '' ? 'true' : 'none'">
      <ion-progress-bar *ngIf="isLoading === true" type="indeterminate"></ion-progress-bar>
      <ion-input label="Código postal *" type="tel" formControlName="postalCode" maxlength="5" minlength="5"></ion-input>
    </ion-item>
    <ion-text
      class="item-with-error"
      color="danger"
      *ngIf="dataForm.get('postalCode').touched && dataForm.get('postalCode').getError('required')"
    >
      <span class="ion-padding" >El código postal es requerido</span>
    </ion-text>
    <ion-item [lines]="dataForm.get('colony').value === '' ? 'true' : 'none'">
      <ion-input label="Colonia *" type="text" formControlName="colony" readonly="true"></ion-input>
    </ion-item>
    <ion-item [lines]="dataForm.get('state').value === '' ? 'true' : 'none'">
      <ion-input label="Estado *" type="text" formControlName="state" readonly="true"></ion-input>
    </ion-item>
    <ion-item [lines]="dataForm.get('city').value === '' ? 'true' : 'none'">
      <ion-input label="Ciudad *" type="text" formControlName="city" readonly="true"></ion-input>
    </ion-item>
    <ion-item [lines]="dataForm.get('delegation').value === '' ? 'true' : 'none'">
      <ion-input label="Delegación o municipio *" type="text" formControlName="delegation" readonly="true"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Hospital" type="text" formControlName="hospital"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input label="Torre" type="text" formControlName="tower"></ion-input>
    </ion-item>
  </ion-list>
  <ion-text color="danger">
    <p>* Datos obligatorios</p>
  </ion-text>
</form>
