<ion-header>
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center">Ingresa la nueva contraseña</ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content no-bounce>
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-justify-content-center ion-no-padding">
      <ion-col size-sm="8" size-lg="6" size-xl="10" class="ion-align-self-center ion-no-padding">
        <h3 ion-text class="ion-text-center">Cambio de contraseña</h3>
        <div class="login-container">
          <form [formGroup]="dataForm" (ngSubmit)="submit()">
            <ion-list>
              <ion-list-header>
                <ion-label color="secondary">Ingrese la nueva contraseña</ion-label>
              </ion-list-header>
              <ion-item>
                <ion-label position="stacked">Crear contraseña <ion-text color="danger">*</ion-text> </ion-label>
                <ion-input
                  #passwordEyeRegister1
                  [type]="passwordTypeInput1"
                  clearOnEdit="false"
                  formControlName="password1"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode1()">
                  <ion-icon [name]="passwordTypeInput1 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="dataForm.get('password1').touched && dataForm.get('password1').getError('required')"
              >
                <span class="ion-padding" translate>La contraseña es requerida</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="dataForm.get('password1').touched && dataForm.get('password1').getError('minlength')"
              >
                <span class="ion-padding" translate>La contraseña debe tener al menos 6 carácteres</span>
              </ion-text>
              <ion-item>
                <ion-label position="stacked">Repetir contraseña <ion-text color="danger">*</ion-text> </ion-label>
                <ion-input
                  #passwordEyeRegister2
                  [type]="passwordTypeInput2"
                  clearOnEdit="false"
                  formControlName="password2"
                ></ion-input>
                <button type="button" item-end class="btn_eye_icon" (click)="togglePasswordMode2()">
                  <ion-icon [name]="passwordTypeInput2 === 'text' ? 'eye-off' : 'eye'"></ion-icon>
                </button>
              </ion-item>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="dataForm.get('password2').touched && dataForm.get('password2').getError('required')"
              >
                <span class="ion-padding" translate>Debes repetir la contraseña</span>
              </ion-text>
              <ion-text
                class="item-with-error"
                color="danger"
                *ngIf="dataForm.get('password2').touched && dataForm.get('password2').getError('mustMatch')"
              >
                <span class="ion-padding" translate>La contraseñas deben de coincidir</span>
              </ion-text>
            </ion-list>
            <div class="ion-padding">
              <ion-button
                type="submit"
                color="tertiary"
                expand="block"
                [disabled]="
                  !dataForm.valid ||
                  dataForm.value.termsAndConditions == false ||
                  dataForm.value.password1 !== dataForm.value.password2 ||
                  isLoading
                "
              >
                Aceptar
              </ion-button>
            </div>
          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
