import { Injectable } from '@angular/core';
import { Platform, ModalController, NavController, ToastController } from '@ionic/angular';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { PushNotifications, ActionPerformed } from '@capacitor/push-notifications';
import { LocalNotifications, ActionPerformed as LocalActionPerformed } from '@capacitor/local-notifications';

@Injectable({
  providedIn: 'root'
})
export class CatchNotificationService {

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private afm: AngularFireMessaging
  ) { }

  public catchNotificationInit(): void {
    if (this.platform.is('capacitor')) {
      this.notificationsMovil();
    } else {
      this.notificationsOnWeb();
    }
  }

  async presentToastWithOptions(
    data: any,
    body: string,
    title: string,
    type:
      | 'chat'
      | 'sample-request'
  ) {
    LocalNotifications.schedule({
      notifications: [
        {
          smallIcon: '@drawable/ic_stat_name',
          title: title,
          body: body,
          id: 1,
          sound: null,
          attachments: null,
          actionTypeId: '',
          extra: { data: data, type: type }
        }
      ]
    });
    const toast = await this.toastCtrl.create({
      header: title,
      message: body,
      position: 'top',
      buttons: [
        {
          text: 'Ver',
          handler: () => {
            this.presentModal(type, data);
          }
        },
        {
          icon: 'close',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  async presentModal(type: string, data: any) {
    let component: any;
    let componentProps: any;
    let isModal = true;
    let link: string;
    switch (type) {
      case 'chat':
        component = ChatComponent;
        componentProps = { id: String(data) };
        isModal = true;
        break;
      case 'sample-request':
        component = ChatComponent;
        componentProps = { id: String(data) };
        isModal = true;
        break;
      case 'add-click-repre':
        try {
          link = String(JSON.parse(data).link);
        } catch (error) {
          link = String(data);
        }
        this.navCtrl.navigateForward(`/user/details/${link}`);
        isModal = false;
        break;
      case 'add-click-product':
        try {
          link = String(JSON.parse(data).link);
        } catch (error) {
          link = String(data);
        }
        this.navCtrl.navigateForward(`/user/details/${link}`);
        isModal = false;
        break;
      case 'segmented-notification':
        try {
          link = String(JSON.parse(data).link);
        } catch (error) {
          link = String(data);
        }
        this.navCtrl.navigateForward(link);
        isModal = false;
        break;
    }
    if (isModal === true) {
      const modal = await this.modalCtrl.create({
        component,
        componentProps,
        backdropDismiss: false
      });
      modal.present();
    }
  }

  private notificationsOnWeb(): void {
    this.afm.messages.subscribe((message: any) => {
      const data: any = message.data.data;
      const type = message.data.type;
      const body: string = message && message.notification.body ? message.notification.body : '';
      const title: string = message && message.notification.title ? message.notification.title : '';
      this.presentToastWithOptions(data, body, title, type);
    });
  }

  private notificationsMovil(): void {
    PushNotifications.addListener('registrationError', (error: any) => {
      alert('Error on registration: ' + JSON.stringify(error));
    });
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification: any) => {
      const data: any = notification.data.data;
      const body: string = notification && notification.body ? notification.body : '';
      const title: string = notification.title;
      const type = notification.data.type;
      this.presentToastWithOptions(data, body, title, type);
    });
    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        const data: any = notification.notification.data.data;
        const type: string = notification.notification.data.type;
        this.presentModal(type, data);
      }
    );
    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (notification: LocalActionPerformed) => {
        const data: any = notification.notification.extra.data;
        const type: string = notification.notification.extra.type;
        this.presentModal(type, data);
      }
    );
  }
}
