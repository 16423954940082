import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('NoAuthenticatedGuard');

@Injectable({
  providedIn: 'root'
})
export class NoAuthenticatedGuard {
  constructor(
    private router: Router,
    private authServe: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authServe.isNotAuthenticated().pipe(take(1), tap(e => {
      if (e === false) {
        log.warn(`%c${state.url} *** route not permitted ***`, 'color: red', route.paramMap);
        this.router.navigate(['/home']);
      }
    }));
  }
}
