<ion-content>
  <ion-list>
    <ion-header>
      <ion-toolbar>
        <ion-title> Notificaciones </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-item
      [disabled]="isLoading !== false"
      *ngFor="let item of notificationsArray; let i = index"
      [color]="item?.viewed === true ? '' : 'secondary'"
    >
      <ion-icon *ngIf="item.icon" slot="start" [name]="item?.icon" class="icon-notifications"></ion-icon>
      <ion-icon *ngIf="item.imageIcon" name="people-outline" slot="start" class="icon-notifications"></ion-icon>
      <ion-label (click)="notification(item)">
        <div class="ion-text-wrap">
          <h3>{{ item?.title }}</h3>
          <p>
            <small>{{ item?.date }}</small>
          </p>
        </div>
      </ion-label>
      <ion-buttons slot="end" class="ion-no-padding ion-no-margin">
        <ion-button (click)="delete(item?.id)" class="ion-no-padding ion-no-margin" size="small">
          <ion-icon slot="icon-only" name="close-outline" class="ion-no-padding ion-no-margin" size="small"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
    <ion-button
      (click)="notificationSubs()"
      expand="block"
      fill="clear"
      [disabled]="canPaginate === false"
      *ngIf="notificationsArray && notificationsArray[0]"
    >
      Cargar más
      <ion-icon slot="end" name="refresh-outline"></ion-icon>
    </ion-button>
    <ion-item lines="none" *ngIf="!(notificationsArray && notificationsArray[0])">
      <ion-label color="medium" class="ion-text-center">No se encontraron notificaciones</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
