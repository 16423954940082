import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Logger } from '@app/core/logger.service';
import { NoWhiteSpaceValidator } from '../../validators/noWhiteSpace.validator';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { IonLoaderService } from '@app/shared/services/ion-loader/ion-loader.service';
import { TypesOfHealthProfessionalsService } from '@app/shared/services/types-of-health-professionals/types-of-health-professionals.service';
const log = new Logger('ProfessionalHealthDataFormComponent');

export interface PersonalData {
  name: string;
  lastName1: string;
  lastName2: string;
  cellPhone: string;
  phone: string;
}

export interface UserData {
  uid: string;
  email: string;
}

@Component({
  selector: 'app-professional-health-data-form',
  templateUrl: './professional-health-data-form.component.html',
  styleUrls: ['./professional-health-data-form.component.scss'],
})
export class ProfessionalHealthDataFormComponent implements OnInit {
  @Input() user: UserData;
  @Output() response = new EventEmitter<void>();
  @Input() button = 'Siguiente';
  public dataForm: FormGroup;
  public isLoading: boolean;
  typesOfHealthProfessionals: any[];

  constructor(
    private formBuilder: FormBuilder,
    private loader: IonLoaderService,
    private typesOfHealthProfessionalsService: TypesOfHealthProfessionalsService,
    private fireService: FirebaseService
  ) { }

  ngOnInit() { this.initializeApp(); }

  async initializeApp() {
    this.isLoading = true;
    this.loader.ionLoaderPresent();
    try {
      this.initForm();
      await this.initData();
      await this.initSpecialties();
    } catch (error) {
      log.error(error);
    }
    this.loader.ionLoaderDismiss();
    this.isLoading = false;
  }

  async initSpecialties(): Promise<void> {
    try {
      this.typesOfHealthProfessionals = await this.typesOfHealthProfessionalsService.getHealthProfessionals();
    } catch (error) {
      log.error(error);
    }
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      healthProfessionalType: ['', Validators.required],
      professionalID1: ['', [Validators.required, NoWhiteSpaceValidator.isValid]]
    });
  }

  refillForm(data: any) {
    this.dataForm.setValue({
      healthProfessionalType: data && data.healthProfessionalType && data.healthProfessionalType.id ? data.healthProfessionalType.id : 0,
      professionalID1: data && data.healthProfessionalType && data.healthProfessionalType.cedula ? data.healthProfessionalType.cedula : ''
    });
  }

  public async initData(): Promise<void> {
    try {
      const data = await this.fireService.getProfessionalHealthData(this.user.uid);
      this.refillForm(data);
    } catch (error) {
      log.error(error);
    }
  }

  public async submit(): Promise<void> {
    try {
      await this.fireService.updateProfesionalSalud(this.user.uid, {
        healthProfessionalType: {
          id: this.dataForm.get('healthProfessionalType').value,
          cedula: this.dataForm.get('professionalID1').value
        }
      });
      this.response.emit();
    } catch (error) {
      log.error(error);
    }
  }
}
