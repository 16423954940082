import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharingRoutingModule } from './sharing-routing.module';
import { SharingComponent } from './sharing.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule, IonicModule, SharingRoutingModule],
  declarations: [SharingComponent]
})
export class SharingModule {}
