<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Actualizar número telefónico</ion-title>
    <ion-buttons slot="end" class="buttons-end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="dataForm" (ngSubmit)="submit()">
    <ion-grid fixed>
      <ion-row>
        <ion-col>
          <ion-item lines="none" class="item-round" color="light">
            <ion-icon color="primary" slot="start" name="phone-portrait-outline"></ion-icon>
            <!-- <ion-select label="País" slot="start" formControlName="mobileLada" placeholder="Selecione el país">
              <ion-select-option *ngFor="let item of lada?.countries" [value]="item?.lada">
                {{ item?.country }} ({{ item?.lada }})
              </ion-select-option>
            </ion-select> -->
            <ion-input formControlName="mobile" placeholder="Teléfono móvil" type="tel" inputmode="tel"></ion-input>
          </ion-item>
          <ion-text class="item-with-error" color="danger" *ngIf="phone?.error">
            <span class="ion-padding">El teléfono móvil no es valido</span>
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-button type="submit" color="primary" [disabled]="isLoading === true" expand="block">
      Actualizar
    </ion-button>
  </form>
</ion-content>