import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { RedConectimedRoutingModule } from './red-conectimed-routing.module';
import { RedConectimedComponent } from './red-conectimed.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { CardsModule } from '@app/shared/cards/cards.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IonicModule,
    RedConectimedRoutingModule,
    SharedModule,
    CardsModule,
    ReactiveFormsModule
  ],
  declarations: [RedConectimedComponent],
  exports: [RedConectimedComponent]
})
export class RedConectimedModule { }
