import { Injectable } from '@angular/core';
import { DocumentData, FirebaseService, QueryDocumentSnapshot, QuerySnapshot } from '@app/shared/services/firebase/firebase.service';
import { FirebasePaginationService, WhereInterface } from '@app/multimedia/services/firebase-pagination.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('MultimediaComponent');

@Injectable({
  providedIn: 'root'
})
export class IndustryService {
  private next: QueryDocumentSnapshot<any>;
  private back: QueryDocumentSnapshot<any>;
  private _limit: number = 20;
  private _items: any[] = [];
  private _where: WhereInterface[] = [];

  constructor(
    private firePage: FirebasePaginationService,
    private fireService: FirebaseService
  ) { }

  set where(where: WhereInterface[]) {
    this._where = where;
  }

  set limit(limit: number) {
    this._limit = limit;
  }

  get items() {
    return this._items;
  }

  public clearItems() {
    this._items = [];
  }

  private mapDocs(docs: QueryDocumentSnapshot<any>[]) {
    if (docs.length > 0) {
      this.next = docs[docs.length - 1];
      this.back = docs[0];
      let DOCS = docs.map(e => {
        let data: any = e.data();
        data.rep = this.fireService.getUserData(data.uid);

        if (data && data.company) {
          try {
            data.company = data.company
              .get()
              .then((data: any) => {
                if (data && data.exists && data.exists === true) {
                  return data && data.get('name') ? String(data.get('name')) : undefined;
                } else {
                  return false;
                }
              })
              .catch(() => {
                return;
              });
          } catch (error) {
            log.error(error);
          }
        }
        return { id: e.id, ...data };
      });
      this._items = this._items.concat(DOCS);
    }
  }

  public async pagination(direction?: 'back' | 'next') {
    try {
      let docs: any[] = [];
      let resp: QuerySnapshot<DocumentData>;
      switch (direction) {
        case 'next':
          resp = await this.firePage.getDocsCollection({
            path: 'products',
            where: this._where,
            orderBy: { fieldPath: 'nameStr', directionStr: 'asc' },
            limit: this._limit,
            startAfter: this.next
          });
          docs = resp.docs;
          break;

        case 'back':
          resp = await this.firePage.getDocsCollection({
            path: 'products',
            where: this._where,
            orderBy: { fieldPath: 'nameStr', directionStr: 'desc' },
            limit: this._limit,
            startAfter: this.back
          });
          docs = resp.docs.reverse();
          break;

        default:
          resp = await this.firePage.getDocsCollection({
            path: 'products',
            where: this._where,
            orderBy: { fieldPath: 'nameStr', directionStr: 'asc' },
            limit: this._limit
          });
          docs = resp.docs;
          break;
      }
      this.mapDocs(docs);
    } catch (e) {
      log.error(e);
    }
  }
}
