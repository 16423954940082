<ion-header>
  <ion-toolbar>
    <ion-title>Especialidad</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar placeholder="Buscar Especialidad" debounce="100" (ionInput)="search($event)"
      (ionCancel)="ionCancel()" (ionClear)="ionClear()"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="dataForm">
          <ion-list>
            <ion-radio-group formControlName="specialty">
              <ion-item *ngFor="let item of arrayFiltered">
                <ion-radio labelPlacement="end" justify="start" [value]="item?.id">{{ item?.name }}</ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <!-- <ion-buttons slot="start">
      <ion-button fill="outline" shape="round" color="secondary" (click)="other()">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OTRO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </ion-button>
    </ion-buttons> -->
    <ion-buttons slot="end">
      <ion-button style="text-transform: uppercase;" (click)="closeModal()">
        Cancelar
      </ion-button>
      <ion-button style="text-transform: uppercase;" (click)="select()" [disabled]="!!!dataForm?.valid">
        Ok
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>