import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';
import { NotificationsInterface } from '../../models/notifications';
import { ChatComponent } from '@app/shared/chat/chat.component';
import { AngularFirestoreCollection, DocumentReference, FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { Subscription } from 'rxjs';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import * as moment from 'moment';
import { AnalyticsService } from '@app/shared/services/analytics/analytics.service';
import { Logger } from '@app/core/logger.service';
const log = new Logger('Notification List');

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit, OnDestroy {
  subs: Subscription;
  collection: AngularFirestoreCollection<NotificationsInterface>;
  limit: number = 10;
  currentLimit: number = 10;
  canPaginate: boolean;
  notificationsArray: NotificationsInterface[] = [];
  isLoading: boolean = false;

  constructor(
    public credService: CredentialsService,
    private fireService: FirebaseService,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private router: Router,
    private analyticsFB: AnalyticsService
  ) { }

  analyticsClickEvent(eventName: string, params: {}) {
    const cred: any = this.credService.credentials; // Credentials App
    const route_page_url: any = this.router.routerState.snapshot.url; // Page route
    this.analyticsFB.logEvent(eventName, params, {
      credentials: cred,
      page_data: { route: route_page_url, title: document.title }
    }); // Analytics LOGS
  }

  async notification(data: NotificationsInterface) {
    this.analyticsClickEvent('show_notification', {
      content_type: data.title,
      notification_type: data.type
    });
    try {
      await this.popoverCtrl.dismiss();
    } catch (e) {
      log.error(e);
    }
    this.fireService.afs
      .collection('notifications')
      .doc(data.id)
      .update({ viewed: true });
    if (data && data.route && data.route != '') {
      this.router.navigate([data.route]);
    } else if (data && data.elementId && data.elementId != '') {
      let component: any;
      let componentProps: any;
      if (data && data.type && data.type === 'sample-request') {
        component = ChatComponent;
        componentProps = { id: String(data.elementId) };
      } else if (data && data.type && data.type === 'chat') {
        component = ChatComponent;
        componentProps = { id: String(data.elementId) };
        this.isLoading = true;
        try {
          const resp = await this.fireService.afs
            .collection('notifications')
            .ref.where('elementId', '==', data.elementId)
            .get();
          const batch = this.fireService.afs.firestore.batch();
          for (const ID of resp.docs.map(e => e.id)) {
            const ref: DocumentReference = this.fireService.afs.collection('notifications').doc(ID).ref;
            batch.update(ref, { viewed: true });
          }
          batch.commit();
        } catch (error) {
          log.error(error);
        }
        this.isLoading = false;
      }
      const modal = await this.modalCtrl.create({
        component,
        componentProps,
        backdropDismiss: false
      });
      modal.present();
    }
  }

  public async delete(id: string): Promise<void> {
    try {
      await this.fireService.afs
        .collection('notifications')
        .doc(id)
        .delete();
    } catch (error) {
      log.error(error);
    }
    return;
  }

  ngOnInit() {
    this.notificationSubs();
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  private mapData(element: any): any {
    const elementData: any = element.payload.doc.data();
    const id: string = element.payload.doc.id;
    const data: NotificationsInterface = { id, ...elementData } as NotificationsInterface;
    data.date = moment(data.date.toDate()).format('DD-MM-YYYY h:mm A');
    switch (data.type) {
      case 'chat':
        data.icon = 'chatbubbles';
        break;
      case 'event-acepted':
      case 'event-deleted':
      case 'event-send':
      case 'event-updated':
        data.icon = 'calendar';
        break;
      case 'friend-request-acepted':
      case 'friend-request-send':
        data.icon = "people-outline";
        break;
      default:
        data.icon = 'notifications-outline';
        break;
    }
    return data;
  }

  public async notificationSubs() {
    if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
      this.collection = this.fireService.afs.collection('notifications', ref =>
        ref
          .where('uid', '==', this.credService.credentials.uid)
          .orderBy('date', 'desc')
          .limit(this.currentLimit)
      );
      this.subs = this.collection.snapshotChanges().subscribe(snap => {
        this.notificationsArray = snap.map(element => this.mapData(element));
      });
      this.currentLimit = this.currentLimit + this.limit;
    }
  }
}
