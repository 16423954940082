import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedConectimedComponent } from './red-conectimed.component';

const routes: Routes = [
  {
    path: '',
    component: RedConectimedComponent,
    data:
    {
      title: 'Red Conectimed',
      icon: {
        src: "/assets/icons/red_conectimed.svg"
      },
      searchRoute: '/red-conectimed',
      root: '/red-conectimed',
      menuItem: '/red-conectimed'
    }
  },
  {
    path: ':search',
    component: RedConectimedComponent,
    data:
    {
      title: 'Búsqueda en Red Conectimed',
      icon: {
        src: "/assets/icons/red_conectimed.svg"
      },
      searchRoute: '/red-conectimed',
      root: '/red-conectimed',
      menuItem: '/red-conectimed'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RedConectimedRoutingModule { }
