import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  public toDate(str?: string): Date {
    return str ? moment(str).toDate() : moment().toDate();
  }

  public moment(str?: any): moment.Moment {
    return str ? moment(str) : moment();
  }
}
