<ion-app>
  <ion-content style="--background: transparent" [scrollY]="false">
    <ion-fab vertical="top" horizontal="end" slot="fixed">
      <ion-fab-button size="small" color="dark" (click)="modalCtrl.dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-card style="margin: 0px">
      <img [src]="img" alt="" />
    </ion-card>
  </ion-content>
</ion-app>