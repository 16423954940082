import { Component, OnInit, ViewChild } from '@angular/core';
import { serverTimestamp } from 'firebase/firestore';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailValidator } from '../shared/validators/email.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { Logger } from '@app/core/logger.service';
import { AlertController, Platform, IonContent, LoadingController, ModalController } from '@ionic/angular';
import { UtilitiesService } from '@app/shared/services/utilities/utilities.service';
import { FirebaseService } from '@app/shared/services/firebase/firebase.service';
import { SpecialtiesService } from '@app/shared/services/specialties/specialties.service';
import { Browser } from '@capacitor/browser';
const log = new Logger('SurveysComponent');
@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss'],
})
export class SurveysComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  private uid: string;
  public isDeleteAccountForm: boolean = false;
  private id: string;
  public isLoading: boolean = false;
  public questions: any[] = [];
  public survey: any;
  public myForm!: any;
  public submitted: boolean = false;
  public surveyAnswered: any = {};
  public exist: boolean = true;
  public type: 'uid' | 'anonymous' = 'anonymous';
  public active: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private fireService: FirebaseService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private credService: CredentialsService,
    private utilities: UtilitiesService,
    private specialtiesService: SpecialtiesService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private platform: Platform,
  ) { }

  ngOnInit() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is('capacitor')) {
          Browser.close();
        }
      });
    } catch (error) {
      log.error(error);
    }
  }

  ionViewDidEnter() {
    if (this.isDeleteAccountForm === true) {
      this.initializeAppInModal();
    } else {
      this.initializeApp();
    }
  }

  initializeAppInModal() {
    if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
      this.uid = this.credService.credentials.uid;
      this.type = 'uid';
    } else {
      this.type = 'anonymous';
    }
    this.initData(this.id);
  }

  initializeApp() {
    if (this.aRoute && this.aRoute.snapshot && this.aRoute.snapshot.params && this.aRoute.snapshot.params.id) {
      this.id = atob(this.aRoute.snapshot.params.id);
      if (this.credService && this.credService.credentials && this.credService.credentials.uid) {
        this.uid = this.credService.credentials.uid;
        this.type = 'uid';
      } else {
        this.type = 'anonymous';
      }
      this.initData(this.id);
    }
  }

  async initData(id: string) {
    this.isLoading = true;
    const loadingOverlay = await this.loadingController.create({});
    loadingOverlay.present();
    try {
      const resp = await this.fireService.afs.collection(`surveys-and-tests/${this.id}/tests-results`).doc(this.uid).ref.get();
      this.surveyAnswered = { previouslyCompleted: true, completed: resp.exists === true ? true : false };
      const session = localStorage.getItem(`surveysAndTests-${this.id}`);
      if (session) {
        this.surveyAnswered = { previouslyCompleted: true, completed: true };
      }
      const data = await this.fireService.afs.doc(`surveys-and-tests/${id}`).ref.get();
      log.debug(`%cID: ${data.id}`, 'background-color: blue; font-size: larger; color: white');
      this.exist = data.exists;
      this.active = data.get('status') === 'active' ? true : false;

      if (this.exist === true && this.active === true) {
        this.survey = data.data();
        this.survey.html = this.utilities.trustHtml(this.survey.description);
        this.questions = Array.from(this.survey.questions);
        let questions: any = {};
        for (let [index, item] of this.questions.entries()) {
          switch (item.type) {
            case 'open':
              questions[index] = [''];
              break;
            case 'multiple':
              if (item.singleAnswer === true) {
                questions[index] = this.newMultipleQuestion();
              } else {
                questions[index] = this.newMultipleQuestionNoSingleAnswer(item.options);
              }
              break;
            default:
              questions[index] = ['', Validators.required];
              break;
          }
        }
        if (this.type === 'anonymous') {
          questions['email'] = ['', EmailValidator.isValid];
          questions['name'] = [''];
        }
        this.createForm(questions);
      }
      if (this.surveyAnswered && this.surveyAnswered.completed && this.surveyAnswered.completed === true) {
        if (!(this.isDeleteAccountForm === true)) {
          this.later(2000).then(() => {
            this.router.navigate(['/home']);
          });
        }
      }
    } catch (error) {
      log.error(error);
    }
    loadingOverlay.dismiss();
    this.isLoading = false;
  }

  newMultipleQuestion(): FormGroup {
    return this.formBuilder.group({
      openAnswer: [''],
      answer: [null, Validators.required]
    });
  }

  newMultipleNoSingleAnswer(): FormGroup {
    return this.formBuilder.group({
      openAnswer: [''],
      answer: [false, Validators.required]
    });
  }

  newMultipleQuestionNoSingleAnswer(arr: any[]): FormGroup {
    let array: any[] = [];
    for (let item of arr) {
      log.debug(item);
      array.push(this.newMultipleNoSingleAnswer());
    }
    return this.formBuilder.group({
      openAnswer: [''],
      answers: this.formBuilder.array(array)
    });
  }

  scrollTo(key: string) {
    const element = document.getElementById(`${key}`);
    this.content.scrollToPoint(0, (Number(element.offsetTop) - 50), 500);
  }

  private createForm(questions: any) {
    this.myForm = this.formBuilder.group(questions);
  }

  addQuestion() {
    const array: FormArray<any> = this.myForm.get('questions') as FormArray;
    array.push(this.newQuestion());
  }

  newQuestion(): FormGroup {
    return this.formBuilder.group({
      response: ['', Validators.required]
    });
  }

  changeMultipleOption(event: any, options: any[], index: number) {
    if ((event && event.detail && event.detail.value) || Number(event.detail.value) === 0) {
      const i: number = Number(event.detail.value);
      const group: FormGroup = this.myForm.controls[index] as FormGroup;
      if (options && options[i] && options[i].isOpen && options[i].isOpen === true) {
        group.controls['openAnswer'].clearValidators();
        group.controls['openAnswer'].setValidators([Validators.required]);
        group.controls['openAnswer'].updateValueAndValidity();
      } else {
        group.controls['openAnswer'].clearValidators();
        group.controls['openAnswer'].updateValueAndValidity();
      }
    }
  }

  getUsername(profile: any): string {
    try {
      let nickname: string = profile && profile.name ? `${profile.name} ${profile.lastName1 || ''} ${profile.lastName2 || ''}` : 'Conectimed User';
      nickname = nickname.trim();
      nickname = nickname.replace(/\s+/g, ' ');
      return nickname;
    } catch (error) {
      log.error(error);
      return 'Conectimed User';
    }
  }

  rate(i: number, rate: number) {
    const control: FormControl = this.myForm.controls[i] as FormControl;
    control.patchValue(rate);
  }

  toArray(length: number): any[] {
    let array: any[] = [];
    for (let i = 1; i <= length; i++) {
      array.push({ value: i });
    }
    return array;
  }

  later(delay: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });
  }

  private async alertPresent() {
    const alert = await this.alertCtrl.create({
      header: '¡Encuesta ya Completada!',
      message: `Hemos identificado que la dirección de correo electrónico proporcionada ya ha completado esta encuesta. Agradecemos su participación y atención. Si ha recibido este mensaje por error o tiene alguna pregunta, por favor contáctenos.`,
      buttons: [
        {
          text: 'De acuerdo',
          role: 'cancel'
        }
      ]
    });
    alert.present();
  }

  checkboxChange(ev: any, i: number, ii: number) {
    const group: FormGroup = ((this.myForm.controls[i] as FormGroup).get('answers') as FormArray).at(ii) as FormGroup;
    if (ev && ev.detail && ev.detail.checked && ev.detail.checked === true) {
      group.controls['openAnswer'].clearValidators();
      group.controls['openAnswer'].setValidators([Validators.required]);
      group.controls['openAnswer'].updateValueAndValidity();
    } else {
      group.controls['openAnswer'].clearValidators();
      group.controls['openAnswer'].updateValueAndValidity();
    }
  }

  customValidator(array: FormArray): boolean {
    const validity: boolean[] = array.controls.map(e => e.valid);
    const answers: boolean[] = array.controls.map(e => e && e.value && e.value.answer === true ? true : false);
    return validity.includes(false) === false && answers.includes(true) === true ? false : true;
  }

  isValidForm(): boolean {
    let _array: boolean[] = [];
    for (const key of Object.keys(this.myForm.controls)) {
      const group: FormGroup = this.myForm.controls[key];
      if (group && group.controls && group.controls.answers) {
        _array.push(this.customValidator(group.controls.answers as FormArray));
      }
    }
    return _array.includes(true) === true ? false : true;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  public async send() {
    this.submitted = true;
    if ((this.myForm.valid === true && this.isValidForm() === true && !(this.isDeleteAccountForm === true)) || this.isDeleteAccountForm === true) {
      this.isLoading = true;
      const loadingOverlay = await this.loadingController.create({});
      loadingOverlay.present();
      try {
        let data: any = { date: serverTimestamp(), results: this.myForm.value, info: {} }
        let previouslyCompleted: boolean = false;
        if (this.uid) {
          data.uid = this.uid;
          data.type = 'uid';
          data.info = {
            name: this.getUsername(this.credService.credentials.profile),
            email: this.credService.credentials.email,
            uid: this.credService.credentials.uid,
            type: this.credService.credentials.type,
            speciality: this.credService && this.credService.credentials && this.credService.credentials.speciality ? this.credService.credentials.speciality : null
          }
          if (data.info.type === 'medico') {
            const userMeta = await this.fireService.afs.collection('medico-meta').doc<any>(data.info.uid).ref.get();
            const specialtyId =
              userMeta && userMeta.data() && userMeta.data().specialty1 && userMeta.data().specialty1.id
                ? userMeta.data().specialty1.id
                : undefined;
            let specialtyData: any;
            try {
              if (specialtyId) {
                specialtyData = await this.specialtiesService.getSpecialty(Number(specialtyId));
              }
            } catch (error) {
              log.warn(error);
            }
            data.info.state = userMeta.get("address1") && userMeta.get("address1").state ? userMeta.get("address1").state : '';
            data.info.speciality = specialtyData && specialtyData.name ? specialtyData.name : data.info.speciality;
          }
          await this.fireService.afs.collection(`surveys-and-tests/${this.id}/tests-results`).doc(this.uid).set(data);
        } else {
          data.type = 'anonymous';
          const email: string = data && data.results && data.results.email ? String(String(data.results.email).trim()).toLocaleLowerCase() : null;
          data.info = {
            name: data && data.results && data.results.name ? data.results.name : null,
            email: email,
            uid: null,
            type: null,
            speciality: null
          }
          if (email) {
            const respE = await this.fireService.afs.collection(`surveys-and-tests/${this.id}/tests-results`).ref.where("info.email", "==", email).get();
            if (respE.empty === true) {
              const resp = await this.fireService.afs.collection('users').ref.where('email', '==', email).get();
              let doc: any;
              if (resp.empty === false) {
                const _data: any = resp.docs[0].data();
                const id: string = resp.docs[0].id;
                doc = { ..._data, uid: id };
                data.info.name = this.getUsername(doc).toLocaleUpperCase();
                data.info.email = email;
                data.info.uid = doc.uid;
                data.info.type = doc.type;
                data.type = 'uid';
                data.uid = data.info.uid;
                if (data.info.type === 'medico') {
                  const userMeta = await this.fireService.afs.collection('medico-meta').doc<any>(data.info.uid).ref.get();
                  const specialtyId =
                    userMeta && userMeta.data() && userMeta.data().specialty1 && userMeta.data().specialty1.id
                      ? userMeta.data().specialty1.id
                      : undefined;
                  let specialtyData: any;
                  try {
                    if (specialtyId) {
                      specialtyData = await this.specialtiesService.getSpecialty(Number(specialtyId));
                    }
                  } catch (error) {
                    log.warn(error);
                  }
                  data.info.state = userMeta.get("address1") && userMeta.get("address1").state ? userMeta.get("address1").state : '';
                  data.info.speciality = specialtyData && specialtyData.name ? specialtyData.name : null;
                }
                await this.fireService.afs.collection(`surveys-and-tests/${this.id}/tests-results`).doc(data.uid).set(data);
              } else {
                await this.fireService.afs.collection(`surveys-and-tests/${this.id}/tests-results`).add(data);
                localStorage.setItem(`surveysAndTests-${this.id}`, 'yes');
              }
            } else {
              this.alertPresent();
              previouslyCompleted = true;
            }
          } else {
            await this.fireService.afs.collection(`surveys-and-tests/${this.id}/tests-results`).add(data);
            localStorage.setItem(`surveysAndTests-${this.id}`, 'yes');
          }
        }
        this.surveyAnswered = { previouslyCompleted, completed: true };
        if (!(this.isDeleteAccountForm === true)) {
          this.later(2000).then(() => {
            this.router.navigate(['/home']);
          });
        } else {
          this.modalCtrl.dismiss({ deleteAccount: true });
        }
      } catch (error) {
        log.error(error);
      }
      loadingOverlay.dismiss();
      this.isLoading = false;
    } else {
      const controls: any = this.myForm.controls;
      const keys: string[] = Object.keys(controls);
      let fError: any;
      let fKey: any;
      for (let key of keys) {
        if (
          (controls && controls[key] && controls[key].errors) ||
          (controls && controls[key] && controls[key].controls && controls[key].controls['answer'] && controls[key].controls['answer'].errors) ||
          (controls && controls[key] && controls[key].controls && controls[key].controls['openAnswer'] && controls[key].controls['openAnswer'].errors) ||
          (controls && controls[key] && controls[key].controls && controls[key].controls['answers'] && this.customValidator(controls[key].controls['answers']) === true)
        ) {
          fKey = key;
          fError = controls[key];
          break;
        }
      }
      if (fKey) {
        this.scrollTo(fKey);
      }
    }
  }

  public async alertConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Eliminación de cuenta',
      message: `Su cuenta se eliminará de forma permanente, no podrá recuperar su información ya que se perderá de forma permanente. ¿Está de acuerdo con iniciar el proceso de eliminación de su cuenta?`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Quiero pensarlo un poco',
          role: 'cancel'
        },
        {
          text: 'Eliminar mi cuenta',
          handler: () => {
            this.send();
          }
        }
      ]
    });
    alert.present();
  }
}
