import { Injectable } from '@angular/core';
import { Logger } from '@app/core/logger.service';
const log = new Logger('IndustryRoutesService');

export interface IndustryRoute {
  /**
   * @param icon The main icon
   */
  icon: string;
  route: string;
  category: string;
  categoryPath: string;
  subCategory: string;
  catRoute: string;
  parent: string;
  type: string;
  searchRoute: string;
  catId: string;
}

@Injectable({
  providedIn: 'root'
})
export class IndustryRoutesService {
  private routes: any = {
    'Dispositivo': {
      icon: {
        src: '/assets/icons/farma-dis.svg'
      },
      route: 'devices'
    },
    'Medicamento': {
      icon: {
        src: '/assets/icons/farma-med.svg'
      },
      route: 'medicines'
    },
    'Servicio': {
      icon: {
        name: 'ribbon-outline'
      },
      route: 'services'
    },
    'Company': {
      icon: {
        src: "/assets/icons/farma-empresas.svg"
      },
      route: 'companies'
    }
  };

  constructor() { }

  mapRoute(type: string, documentID: string): IndustryRoute | void {
    try {
      return this.mapping(type, documentID);
    } catch (e) {
      log.error(e);
    }
  }

  private mapping(type: string, documentID?: string): IndustryRoute | void {
    try {
      let routing: IndustryRoute = undefined;
      switch (type) {
        case 'Dispositivo':
          routing = Object.assign({}, this.routes['Dispositivo']);
          break;

        case 'Medicamento':
          routing = Object.assign({}, this.routes['Medicamento']);
          break;

        case 'Servicio':
          routing = Object.assign({}, this.routes['Servicio']);
          break;

        case 'Company':
          routing = Object.assign({}, this.routes['Company']);
          break;
      }
      routing.route = `/industry/${routing.route}/detail/${documentID}`;
      return Object.assign({}, routing);
    } catch (e) {
      log.error(e);
    }
  }

  mapFromRoot(categoryName: string): IndustryRoute {
    return this.routes[categoryName];
  }
}
